import React from 'react'
import { getIconColor } from './utils'

export function ReportsLogoSvg({ active, customActiveColor, customColor, disabled }) {
    return (
        <svg
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.2954 10.2954H16.1136"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.2954 13.2046H16.1136"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.0227 3.75H4.47727C4.07561 3.75 3.75 4.07561 3.75 4.47727V19.0227C3.75 19.4244 4.07561 19.75 4.47727 19.75H19.0227C19.4244 19.75 19.75 19.4244 19.75 19.0227V4.47727C19.75 4.07561 19.4244 3.75 19.0227 3.75Z"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.38636 3.75V19.75"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
