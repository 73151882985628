import { InfoIcon } from '@octup/core/assets/icons'
import type { DashboardTemplateGroupType } from '@octup/core/models'
import { Stack, SvgIcon, Typography, Tooltip, Icon } from '@octup/core/ui-kit'
import { titleStyle } from './styles'

type GroupTitlePropsType = Pick<
    DashboardTemplateGroupType,
    'group' | 'icon' | 'description' | 'previewImg'
>

export const GroupTitle = ({
    group,
    icon,
    description,
    previewImg,
}: GroupTitlePropsType): JSX.Element => (
    <Stack direction="row" alignItems="center" spacing={1}>
        {icon && (
            <Icon display="flex">
                <img src={icon} alt={group} width="100%" />
            </Icon>
        )}
        <Typography sx={titleStyle}>{group}:</Typography>
        <Tooltip
            placement="right"
            title={
                <Stack spacing={4}>
                    <Typography variant="body2">{description}</Typography>
                    {previewImg && <img src={previewImg} alt={group} />}
                </Stack>
            }
        >
            <SvgIcon component={InfoIcon} color="text.primary" />
        </Tooltip>
    </Stack>
)
