import React from 'react'
import { getIconColor } from './utils'

export function AutoStudioLogoSvg({ active, customActiveColor, customColor, disabled }) {
    return (
        <svg width="2.4rem" height="2.4rem" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0053 6.95984C12.298 6.95984 12.5639 7.13006 12.6864 7.39582L14.6428 11.6391C14.6483 11.6503 14.6535 11.6616 14.6584 11.6731L16.9417 16.6254C16.9871 16.7239 17.0106 16.831 17.0106 16.9394V17.6418L17.0106 17.6494L17.0106 17.6539V18.1958C17.0106 18.61 16.6748 18.9458 16.2606 18.9458C15.8464 18.9458 15.5106 18.61 15.5106 18.1958V17.6452C15.5095 17.4688 15.439 17.3 15.3146 17.1754C15.1892 17.0499 15.0192 16.9795 14.8422 16.9795C14.6652 16.9795 14.4952 17.0499 14.3698 17.1754C14.2444 17.301 14.1738 17.4715 14.1738 17.6494L14.1737 17.6569V19.2167C14.1737 19.6309 13.8379 19.9667 13.4237 19.9667C13.0095 19.9667 12.6737 19.6309 12.6737 19.2167V17.6494C12.6737 17.4715 12.6031 17.301 12.4777 17.1754C12.3523 17.0499 12.1823 16.9795 12.0053 16.9795C11.8283 16.9795 11.6583 17.0499 11.5329 17.1754C11.4086 17.2998 11.3382 17.4683 11.3369 17.6444L11.3369 17.6494L11.3369 17.6554V19.2167C11.3369 19.6309 11.0011 19.9667 10.5869 19.9667C10.1726 19.9667 9.83685 19.6309 9.83685 19.2167V17.6494L9.83686 17.6445C9.83556 17.4683 9.7651 17.2998 9.64082 17.1754C9.5154 17.0499 9.34546 16.9795 9.16844 16.9795C8.99141 16.9795 8.82148 17.0499 8.69606 17.1754C8.57062 17.301 8.5 17.4715 8.5 17.6494V18.196C8.5 18.6103 8.16421 18.946 7.75 18.946C7.33579 18.946 7 18.6103 7 18.196V17.6494V16.9394C7 16.831 7.02351 16.7239 7.0689 16.6254L9.35425 11.6686C9.35796 11.6601 9.36182 11.6518 9.36583 11.6435L11.3242 7.39582C11.4467 7.13006 11.7127 6.95984 12.0053 6.95984ZM10.5214 12.7197L9.24832 15.4809C9.74223 15.4991 10.2143 15.6857 10.5869 16.0081C10.9795 15.6683 11.4826 15.4795 12.0053 15.4795C12.528 15.4795 13.0312 15.6683 13.4237 16.0081C13.7963 15.6857 14.2684 15.4991 14.7623 15.4809L13.4892 12.7197H10.5214ZM12.7976 11.2197H11.213L12.0053 9.50113L12.7976 11.2197Z"
                fill={getIconColor(active, customColor, customActiveColor, disabled)}
            />
        </svg>
    )
}
