import PageLayout from '../../components/PageLayout/PageLayout'
import { InsightsTopSection } from './components/InsightsTopSection'
import { InsightsDetailedTable } from './components/InsightsDetailedTable'

const InsightsPage = () => {
    return (
        <PageLayout customStyle={{ overflowY: 'hidden' }} showDatePicker={false}>
            <InsightsTopSection />
            <InsightsDetailedTable />
        </PageLayout>
    )
}

export default InsightsPage
