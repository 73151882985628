import { InfoIcon } from '@octup/core/assets/icons'
import {
    ClickAwayListener,
    Paper,
    Popper,
    Stack,
    SvgIcon,
    Typography,
    Button,
} from '@octup/core/ui-kit'
import { SettingsLogoSvg } from 'shared/assets/icons'
import { ButtonIcon } from 'shared/ui/Buttons/ButtonIcon'
import { OctupTooltip } from 'shared/ui/MUIWrappers/OctupTooltip'
import CustomizedOctupSlider from 'shared/ui/Sliders/RangeSlider'
import { containerStyle, popperStyle, titleStyle } from './SLADaysRange.styles'

export const SLADaysRange = ({ data, anchorEl, onClick, onClose, onSave, onRangeChange }) => (
    <>
        <ButtonIcon
            type="button"
            onClick={onClick}
            mainIcon={SettingsLogoSvg}
            tooltipPosition="top"
            tooltipContent="Set your preferred SLA days range"
        />
        <Popper anchorEl={anchorEl} open={!!anchorEl} placement="bottom-start" sx={popperStyle}>
            <ClickAwayListener onClickAway={onClose}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={containerStyle}
                    component={Paper}
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body2" sx={titleStyle}>
                            Customize Days Range
                        </Typography>
                        <OctupTooltip
                            position="top"
                            title="Set your view of warehouse's committed delivery time by adjusting the range of days"
                        >
                            <SvgIcon component={InfoIcon} color="text.primary" fontSize="small" />
                        </OctupTooltip>
                    </Stack>
                    <CustomizedOctupSlider min={data.min} max={data.max} setRange={onRangeChange} />
                    <Button size="small" variant="contained" onClick={onSave}>
                        save
                    </Button>
                </Stack>
            </ClickAwayListener>
        </Popper>
    </>
)
