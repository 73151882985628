import axios from 'axios'
import { GLOBAL_CONFIG } from 'config'
import { abortSignal } from 'services/utils'
import type { SignupBySourceType } from '@/models/signup'

const API_URL = GLOBAL_CONFIG.BASE_URL + 'api'
const AUTH_API_URL = GLOBAL_CONFIG.BASE_URL + 'auth'
const DOMAIN_MANAGEMENT_API_URL = AUTH_API_URL + '/domain-management'

export const getAppSettings = async () => {
    const response = await axios.get(DOMAIN_MANAGEMENT_API_URL, { signal: abortSignal(3000) })
    return response.data
}

export const getAppExcludedFeatures = async () => {
    const response = await axios.get(`${DOMAIN_MANAGEMENT_API_URL}/excluded-features`)
    return response.data
}

export const signupBySource = async (data: SignupBySourceType) => {
    const { source, data: userData } = data
    const response = await axios.post(`${API_URL}/signup/register/${source}`, userData)
    return response.data
}
