import styled from 'styled-components'

const AlternativeHomeMainContainer = styled.div`
    width: 100%;
    height: 90%;
    display: grid;
    grid-template-rows: 1fr 0.5fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        'yesterdays_stats operations marketing'
        'yesterdays_stats objectives insights';
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    align-items: start;
    justify-content: start;
    margin-top: 2rem;

    .yesterdays_stats {
        grid-area: yesterdays_stats;
    }

    .operations {
        grid-area: operations;
    }

    .marketing {
        grid-area: marketing;
    }

    .insights {
        grid-area: insights;
    }

    .objectives {
        grid-area: objectives;
    }

    @media screen and (max-width: 1280px) {
        grid-template-rows: 1fr 0.7fr 0.7fr;
        grid-template-columns: repeat(2, calc(50% - 1rem));
        grid-template-areas:
            'operations marketing'
            'yesterdays_stats objectives'
            'yesterdays_stats insights';
    }

    @media screen and (max-width: 768px) {
        grid-template-rows: 1fr 1fr 1fr 0.7fr 0.7fr;
        grid-template-columns: 100%;
        grid-template-areas:
            'operations'
            'marketing'
            'yesterdays_stats'
            'objectives'
            'insights';
    }
`
const AlternativeHomeLeftContainer = styled.div`
    width: 100%;
    height: 100%;
    gap: 2rem;
    display: flex;
    align-items: center;
`

const TitleContainer = styled.div`
    width: 100%;
    height: 3rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
`

const Title = styled.div`
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
    color: #504b5a;
`

const ColumnOutterContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`

const ColumnInnerContainer = styled.div`
    width: 100%;
    min-height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`

const ColumnTopInnerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
`

const ColumnBottomInnerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    max-height: fit-content;
`

const DougnutMapContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    gap: 2.9rem;
`
const BreathingContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 2rem;
    width: 100%;
    justify-content: space-between;
`

export {
    AlternativeHomeMainContainer,
    AlternativeHomeLeftContainer,
    TitleContainer,
    Title,
    ColumnOutterContainer,
    ColumnInnerContainer,
    ColumnTopInnerContainer,
    ColumnBottomInnerContainer,
    DougnutMapContainer,
    BreathingContainer,
}
