import { useState } from 'react'
import { Stack } from '@octup/core/ui-kit'
import styled from 'styled-components'
import { RadioTabsSelectorItem } from './RadioTabsSelectorItem'

export const RadioTabsSelectors = ({ tabs, customStyle, tabsCustomStyle }) => {
    const [selectedItem, setSelectedItem] = useState(tabs.find((el) => el.selected))

    return (
        <Stack width="100%" direction="row" alignItems="center" spacing={3}>
            <RadioTabsSelectorsContainer style={customStyle}>
                {tabs.map((el) => (
                    <RadioTabsSelectorItem
                        {...el}
                        key={el.title}
                        customStyle={tabsCustomStyle}
                        tab={el}
                        selectedItem={selectedItem}
                        onClick={(event) => {
                            setSelectedItem(el)
                            el.onClick(event)
                        }}
                    />
                ))}
            </RadioTabsSelectorsContainer>
            <RadioTabsInfo>{selectedItem?.info}</RadioTabsInfo>
        </Stack>
    )
}

const RadioTabsSelectorsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0.2rem 0.2rem 0.4rem 0 rgba(80, 75, 90, 0.16);
    border-radius: 0.8rem;
    font-size: 1.3rem;
    height: 100%;
    width: 100%;
    flex: 1;
`

const RadioTabsInfo = styled.div`
    height: 100%;
    width: 100%;
    flex: 2;
    font-family: Poppins;
    font-weight: 400;
    line-height: 1.8rem;
`
