import { useCallback, useState } from 'react'
import { DoughnutChart } from 'shared/ui/Charts/ChartDoughnut'
import { DeepDiveCostModalContainer } from '../DeepDiveCostModalContainer'

export const DoughnutChartCardContainer = ({ legends, ...props }) => {
    const [deepDiveModal, setDeepDiveModal] = useState(null)
    const handleClose = useCallback(() => setDeepDiveModal(null), [])
    const handleSelect = useCallback(({ name }) => setDeepDiveModal({ selectedDDMetric: name }), [])

    return (
        <>
            <DoughnutChart legends={legends} onLegendClick={handleSelect} {...props} />
            {deepDiveModal && (
                <DeepDiveCostModalContainer
                    costs={legends}
                    onClose={handleClose}
                    onSelect={handleSelect}
                    deepDiveModal={deepDiveModal}
                />
            )}
        </>
    )
}
