import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import {
    ContentTextStyled,
    ContentTitleStyled,
    ContentListStyled,
    ContentTableStyled,
    ContentVideoContainerStyled,
} from './MarkdownWrapper.styles'
import { WhatsNewVideoComponent } from './WhatsNewVideoComponent'

const markdownElementsConfig = {
    h1({ children }) {
        return <ContentTitleStyled>{children}</ContentTitleStyled>
    },
    p({ children }) {
        return <ContentTextStyled>{children}</ContentTextStyled>
    },
    ul({ children }) {
        return <ContentListStyled>{children}</ContentListStyled>
    },
    table({ children }) {
        return <ContentTableStyled>{children}</ContentTableStyled>
    },
    video(props) {
        return (
            <ContentVideoContainerStyled>
                <WhatsNewVideoComponent source={props?.src} />
            </ContentVideoContainerStyled>
        )
    },
}
export const MarkdownWrapper = ({ text = '' }) => {
    return (
        <Markdown
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
            components={markdownElementsConfig}
            children={text}
        />
    )
}
