import { TrashSvg } from '../../shared/assets/icons'
import { OctupTableRowDotMenu } from '../../shared/ui/Tables/OctupTableRowDotMenu'
import { Column, DotMenuOptionsContainer } from '../../shared/styledComponents'
import styled from 'styled-components'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { useOctupDispatch } from '../../hooks'
import { useState } from 'react'
import { OctupBaseModal } from '../../shared/ui/Modal/OctupBaseModal'
import { useSelector } from 'react-redux'

export const UserExtraOptions = ({ userId }) => {
    const { userInfo } = useSelector((state) => state.auth)
    const octupDispatch = useOctupDispatch()
    const [showModal, setShowModal] = useState(false)
    const handleDeleteUser = () => {
        octupDispatch.users.deleteUser(userId)
        setShowModal(false)
    }

    return (
        <>
            {showModal && (
                <OctupBaseModal
                    title={'Delete a Member'}
                    buttons={[
                        {
                            title: 'Cancel',
                            variant: 'secondary',
                            onClick: () => setShowModal(false),
                        },
                        {
                            title: 'Delete Member',
                            onClick: handleDeleteUser,
                            variant: 'contained',
                        },
                    ]}
                    onDismissModal={() => setShowModal(false)}
                >
                    <Column>
                        <Title>Are you sure you wish to delete this member?</Title>
                        <Body>
                            {' '}
                            All their active objectives will be deleted and their data won’t be
                            restorable.
                        </Body>
                    </Column>
                </OctupBaseModal>
            )}
            {/*User Cannot Delete Himself*/}
            {userInfo.id !== userId && (
                <OctupTableRowDotMenu
                    options={
                        <DotMenuOptionsContainer>
                            <MainContainer
                                onClick={() => {
                                    setShowModal(true)
                                }}
                            >
                                <TrashSvg active={true} customActiveColor="black" />
                                Delete Member
                            </MainContainer>
                        </DotMenuOptionsContainer>
                    }
                />
            )}
        </>
    )
}

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    gap: 0.6rem;
    padding: 1rem 1.5rem;
    background-color: ${OctupColors.pageBackground};
    border-radius: 8px;
    cursor: pointer;

    position: fixed;
    margin-top: -50px !important;
    margin-left: -200px !important;

    box-shadow:
        -2px -2px 8px #ffffff,
        2px 2px 4px rgba(80, 75, 90, 0.16);

    :hover {
        background: #f1f0f5;
        border-radius: 0.8rem;
    }
`

const Title = styled.div`
    font-size: 12px;
    font-weight: 600;
`

const Body = styled.div`
    font-size: 10px;
    font-weight: 400;
`
