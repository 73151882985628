import styled from 'styled-components'
import { BaseOctupToast } from './BaseOctupToast'
import { useSelector } from 'react-redux'
import { useToastContext } from '../../../hooks/useToastContext'

export const BaseOctupToastsContainer = () => {
    const { isMainMenuCollapsed, isMainMenuCollapsedBtnActive } = useSelector(
        (state) => state.navigation
    )
    const { toasts } = useToastContext()

    return (
        <OctupToastsOutterContainer
            id="octup-Toasts-container"
            isMainMenuCollapsed={isMainMenuCollapsed}
            isMainMenuCollapsedBtnActive={isMainMenuCollapsedBtnActive}
        >
            <OctupToastsInnerContainer>
                {toasts.map((el) => (
                    <BaseOctupToast key={el.id} {...el} />
                ))}
            </OctupToastsInnerContainer>
        </OctupToastsOutterContainer>
    )
}

const OctupToastsOutterContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 0;
    z-index: 1800;
    top: 0;
    margin-top: 1.5rem;
    left: ${(props) => (props.isMainMenuCollapsedBtnActive ? '0%' : '0%')};
`

const OctupToastsInnerContainer = styled.ul`
    position: relative;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
`
