import { number, string } from 'yup'
import { ERROR_MESSAGES } from '@/constants/validation'

const transformNaN = (value) => (isNaN(value) ? null : value)

export const VALIDATION_RULES = {
    NUMBER: number().transform(transformNaN).nullable(),
    PASSWORD: string()
        .trim()
        .min(8, ERROR_MESSAGES.PASSWORD)
        .matches(/[A-Z]/, ERROR_MESSAGES.PASSWORD)
        .matches(/[a-z]/, ERROR_MESSAGES.PASSWORD)
        .matches(/\d/, ERROR_MESSAGES.PASSWORD)
        .matches(/[-=*!@#$%^&(){}[\]:;<>,.?/~+_|\\]/, ERROR_MESSAGES.PASSWORD),
}
