import React from 'react'
import { getIconColor } from './utils'

export function CopySvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.3865 11.3863H12.2501C13.3547 11.3863 14.2501 10.4909 14.2501 9.38632V5.75C14.2501 4.64543 13.3547 3.75 12.2501 3.75H8.61377C7.5092 3.75 6.61377 4.64543 6.61377 5.75V6.61362"
                fill="#F8F7FB"
            />
            <path
                d="M11.3865 11.3863H12.2501C13.3547 11.3863 14.2501 10.4909 14.2501 9.38632V5.75C14.2501 4.64543 13.3547 3.75 12.2501 3.75H8.61377C7.5092 3.75 6.61377 4.64543 6.61377 5.75V6.61362"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.38632 6.61371H5.75C4.64543 6.61371 3.75 7.50914 3.75 8.61371V12.25C3.75 13.3546 4.64543 14.25 5.75 14.25H9.38632C10.4909 14.25 11.3863 13.3546 11.3863 12.25V8.61371C11.3863 7.50914 10.4909 6.61371 9.38632 6.61371Z"
                fill="#F8F7FB"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
