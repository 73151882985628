import { useEffect } from 'react'

const containsSelector = (nodes, selector) =>
    Array.from(nodes).some((node) => {
        if (node.matches && node.matches(selector)) {
            return true
        }

        if (node.children && node.children.length > 0) {
            return containsSelector(node.children, selector)
        }

        return false
    })

export const useSelectorSpy = (selector, callback, parentNode = document.documentElement) => {
    useEffect(() => {
        const hasElement = document.querySelector(selector)

        if (hasElement) return callback()

        const observer = new MutationObserver((mutationsList) => {
            const isElementAdded = mutationsList.some((mutation) =>
                containsSelector(mutation.addedNodes, selector)
            )

            if (isElementAdded) {
                callback()
            }
        })

        const config = { childList: true, subtree: true }
        observer.observe(parentNode, config)

        return () => {
            observer.disconnect()
        }
    }, [selector, callback, parentNode])
}
