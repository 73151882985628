import { Theme } from '@mui/material'

export const menuItemCardStyle = (theme: Theme) => ({
    width: 'auto',
    height: theme.spacing(11),
    minHeight: theme.spacing(11),
    borderRadius: theme.spacing(2),
    padding: 0,
})

export const menuItemStyle = (theme: Theme) => ({
    padding: theme.spacing(3),
})
