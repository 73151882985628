import axios from 'axios'
import { GLOBAL_CONFIG } from '../../config'
import { removeHashFromString } from '../../shared/utils/strings-util'

const getMetricsBySegment = async ({ userToken, startDate, endDate, segment }) => {
    let url = `${GLOBAL_CONFIG.METRICS_SERVICE}/metrics/sections?ids=${segment}&slice=2&start_date=${startDate}`
    url += `&end_date=${endDate}`
    url += `&dataset=1`

    const res = await axios.get(url, {
        headers: {
            Authorization: `${userToken}`,
        },
    })

    return res?.data[segment]
}

const getMetricsBySegments = async (data) => {
    let urlSegments = ''
    for (const metricsSegment of data.segments) {
        if (urlSegments) {
            urlSegments += `,${metricsSegment}`
        } else {
            urlSegments = metricsSegment
        }
    }

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const { dataset, datePickerValues, comparisonDates, comparisonMetric, clientId } = data || {}

    const res = await axios(`${GLOBAL_CONFIG.METRICS_SERVICE}/metrics/sections`, {
        headers: { Authorization: userInfo?.token },
        params: {
            dataset,
            ids: urlSegments,
            start_date: datePickerValues?.startDate,
            end_date: datePickerValues?.endDate,
            client_id: clientId,
            compare_start_date: comparisonDates?.startDate,
            compare_end_date: comparisonDates?.endDate,
            compare_metrics: comparisonMetric?.metric_id,
        },
    })

    return res.data
}

const getOrdersSLAStatus = async ({ filter, datePickerValues, page }) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const res = await axios.get(
        // `${METRICS_API_URL}/sla/${userInfo?.customer?.name}`,
        `${GLOBAL_CONFIG.METRICS_SERVICE}/sla/overview`,
        {
            params: {
                filter: filter,
                start_date: datePickerValues.startDate,
                end_date: datePickerValues.endDate,
                page: page,
            },
            headers: { Authorization: userInfo?.token },
        }
    )
    return res.data
}

const getFulfilledOrdersSLA = async () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const res = await axios.get(`${GLOBAL_CONFIG.METRICS_SERVICE}/sla/get_sla`, {
        headers: { Authorization: userInfo?.token },
    })
    return res.data
}

const getSingleOrder = async (order) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const res = await axios.get(
        `${GLOBAL_CONFIG.METRICS_SERVICE}/metrics/order/${removeHashFromString(order.itemId)}`,
        {
            headers: { Authorization: userInfo?.token },
        }
    )
    return res.data
}

const setFulfilledOrdersSLA = async ({ min, max }) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const res = await axios.post(
        `${GLOBAL_CONFIG.METRICS_SERVICE}/sla/set_sla`,
        { fulfillment_min_sla: min, fulfillment_max_sla: max },
        {
            headers: { Authorization: userInfo?.token },
        }
    )
    return res.data
}

const getFulfilledOrdersSLAMetric = async ({ start_date, end_date }) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const res = await axios.get(
        `${GLOBAL_CONFIG.METRICS_SERVICE}/sla/get_metrics?start_date=${start_date}&end_date=${end_date}`,
        {
            headers: { Authorization: userInfo?.token },
        }
    )
    return res.data
}

export const metricsService = {
    getMetricsBySegment,
    getMetricsBySegments,
    getOrdersSLAStatus,
    getFulfilledOrdersSLA,
    setFulfilledOrdersSLA,
    getSingleOrder,
    getFulfilledOrdersSLAMetric,
}
