import { useRef, useEffect } from 'react'

// useEffect, but skipping the initial mount
// It only triggers when dependencies change

export const useUpdateEffect = (effect, dependencies = []) => {
    const isInitialMount = useRef(true)

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false
        } else {
            effect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies)
}
