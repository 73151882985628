import styled from 'styled-components'

const BADGE_THEMES = {
    indigo: {
        color: '#7737FF',
        background: 'rgba(119, 55, 255, 0.08)',
    },
    purple: {
        color: '#ffffff',
        background: 'rgba(119, 55, 255, 1)',
    },
    teal: {
        color: '#007f82',
        background: 'rgba(0, 168, 168, 0.08)',
    },
    orange: {
        color: ' #C4821F',
        background: 'rgba(255, 207, 114, 0.16)',
    },
    green: {
        color: '#078344',
        background: 'rgba(4, 206, 114, 0.08);',
    },
    pink: {
        color: '#FF3489',
        background: 'rgba(255, 52, 137, 0.08)',
    },
    blue: {
        color: '#0098D4',
        background: 'rgba(0, 152, 212, 0.08)',
    },
    red: {
        color: '#DB2059',
        background: 'rgba(252, 79, 109, 0.08)',
    },
    gray: {
        color: '#7F7B87',
        background: '#EBEAEE',
    },
    darkgray: {
        color: 'rgba(80, 75, 90, 1)',
        background: 'rgba(235, 234, 238, 1)',
    },
    error: {
        color: '#FFFFFF',
        background: '#DB2059',
    },
}

export const OctupBadge = ({ title = 'Octup Badge', theme = 'gray', icon = null }) => {
    const ICON = icon
    return (
        <OctupBadgeContainer theme={theme}>
            {icon && (
                <ICON customColor={BADGE_THEMES[theme] ? BADGE_THEMES[theme].color : '#7F7B87'} />
            )}
            {title}
        </OctupBadgeContainer>
    )
}

const OctupBadgeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    white-space: nowrap;
    padding: 0.2rem 1rem !important;
    letter-spacing: 0.08rem;
    color: ${(props) =>
        BADGE_THEMES[props.theme] && BADGE_THEMES[props.theme].color
            ? BADGE_THEMES[props.theme].color
            : '#7F7B87'};
    background: ${(props) =>
        BADGE_THEMES[props.theme] && BADGE_THEMES[props.theme].background
            ? BADGE_THEMES[props.theme].background
            : '#EBEAEE'};
    text-transform: uppercase;
    font-size: 1.1rem !important;
    border-radius: 1.6rem;
    font-weight: 600;

    svg {
        max-height: 2rem;
        max-width: 2rem;
        min-height: 1.3rem;
        min-width: 1.3rem;
        margin-right: 0.8rem;
    }
`
