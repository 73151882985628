import React from 'react'
import { getIconColor } from './utils'

export function ShareSvg({ active, customActiveColor = '#007F82', customColor = '#007F82' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.8rem"
            height="1.8rem"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path d="M18 0H0V18H18V0Z" fill="none" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3416 4.65506C11.5546 4.44831 11.9 4.44831 12.113 4.65506L14.8402 7.30212C15.0533 7.50887 15.0533 7.84407 14.8402 8.05082L12.113 10.6979C11.9 10.9046 11.5546 10.9046 11.3416 10.6979C11.1286 10.4911 11.1286 10.1559 11.3416 9.94918L13.6832 7.67647L11.3416 5.40376C11.1286 5.19701 11.1286 4.86181 11.3416 4.65506Z"
                fill={getIconColor(active, customColor, customActiveColor)}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 8.2058C7.69803 8.2058 6.44938 8.70779 5.52875 9.60135C4.60812 10.4949 4.09091 11.7068 4.09091 12.9705C4.09091 13.2629 3.8467 13.4999 3.54545 13.4999C3.24421 13.4999 3 13.2629 3 12.9705C3 11.426 3.63214 9.94477 4.75736 8.85265C5.88258 7.76052 7.4087 7.14697 9 7.14697H14.4545C14.7558 7.14697 15 7.384 15 7.67638C15 7.96877 14.7558 8.2058 14.4545 8.2058H9Z"
                fill={getIconColor(active, customColor, customActiveColor)}
            />
        </svg>
    )
}
