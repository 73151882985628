import { useToast } from '../../../hooks/useToastContext'
import { ButtonIcon } from '../../../shared/ui/Buttons/ButtonIcon'
import {
    CopySvg,
    SingleOrderCustomerAddressSvg,
    SingleOrderCustomerPhoneSvg,
    SingleOrderCustomerEmailSvg,
    SingleOrderCustomerNameSvg,
    SingleOrderFirstSessionInfoSvg,
} from '../../../shared/assets/icons'
import {
    CustomerDetailsStyled,
    CustomerDetailsItem,
    CustomerDetailsItemIcon,
    CustomerDetailsItemContent,
    CustomerDetailsItemButtonWrapper,
    HorizontalDivider,
    AddressDetails,
} from './CustomerDetails.styled'
import { safeSingleOrderValue, compareObjects } from './util'

const CustomerDetailsItemComponent = ({ icon, content, isCopyable, showDivider }) => {
    const toast = useToast()
    return (
        <>
            <CustomerDetailsItem>
                <CustomerDetailsItemIcon>{icon}</CustomerDetailsItemIcon>
                <CustomerDetailsItemContent>{content}</CustomerDetailsItemContent>
                <CustomerDetailsItemButtonWrapper>
                    {isCopyable && (
                        <ButtonIcon
                            mainIcon={CopySvg}
                            onClick={() => {
                                navigator.clipboard.writeText(content.toString())
                                toast.success(`'${content}' copied to clipboard`)
                            }}
                        />
                    )}
                </CustomerDetailsItemButtonWrapper>
            </CustomerDetailsItem>
            {showDivider && <HorizontalDivider />}
        </>
    )
}

export const CustomerDetails = ({ singleOrder }) => {
    const addressDetails =
            singleOrder?.data?.order?.customer?.defaultAddress ||
            singleOrder?.data?.order?.billingAddress,
        billingAddress = singleOrder?.data?.order?.billingAddress,
        customerPhone = singleOrder?.data?.order?.customer?.phone,
        isSameAddress = compareObjects(addressDetails, billingAddress),
        customerDetailsInfo = singleOrder?.data?.order?.customer,
        CustomerData = [
            {
                id: 1,
                icon: <SingleOrderCustomerNameSvg />,
                content: `${safeSingleOrderValue(
                    customerDetailsInfo?.firstName?.toLowerCase() || ''
                )} ${customerDetailsInfo?.lastName?.toLowerCase() || ''}`,
                isCopyable: false,
            },
            {
                id: 2,
                icon: <SingleOrderCustomerEmailSvg />,
                content: `${customerDetailsInfo?.email || '-'}`,
                isCopyable: true,
            },
            {
                id: 3,
                icon: <SingleOrderCustomerPhoneSvg />,
                content: `${singleOrder?.data?.order?.customer?.phone || '-'}`,
                isCopyable: true,
            },
            {
                id: 4,
                icon: <SingleOrderCustomerAddressSvg />,
                content: (
                    <>
                        <AddressDetails>
                            {addressDetails && (
                                <>
                                    <div>Shipping Address:</div>
                                    {addressDetails?.address1 && (
                                        <div> {addressDetails?.address1} </div>
                                    )}
                                    <div> {addressDetails?.city}</div>
                                    {addressDetails?.address2 && (
                                        <div>{addressDetails?.address2}</div>
                                    )}
                                    <div> {addressDetails?.country}</div>
                                </>
                            )}
                        </AddressDetails>
                        <AddressDetails>
                            {addressDetails && (
                                <>
                                    <div>
                                        Billing Address:
                                        {isSameAddress && ' Same as shipping address'}
                                    </div>
                                    {!isSameAddress && (
                                        <>
                                            <div> {billingAddress?.address1} </div>
                                            <div> {billingAddress?.city}</div>
                                            <div>
                                                {billingAddress?.address2 &&
                                                    billingAddress?.address2}
                                            </div>
                                            <div> {billingAddress?.country}</div>
                                        </>
                                    )}
                                </>
                            )}
                        </AddressDetails>
                    </>
                ),
                isCopyable: false,
            },
            {
                id: 5,
                icon: <SingleOrderFirstSessionInfoSvg />,
                content: singleOrder?.data?.order?.customerJourney?.firstVisit?.sourceDescription,
                isCopyable: false,
            },
        ]

    return (
        <CustomerDetailsStyled>
            {CustomerData.map((el, index) => (
                <CustomerDetailsItemComponent
                    {...el}
                    key={el.id}
                    showDivider={index < CustomerData.length - 1}
                />
            ))}
        </CustomerDetailsStyled>
    )
}
