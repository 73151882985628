import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getOrderCostsReport } from 'services'
import { downloadFile, getFileNameFromHeader } from 'utils/downloadFile'

const INITIAL_STATE = {
    isLoading: false,
    error: null,
}

export const fetchCostsReport = createAsyncThunk('fetchCostsReport', async (data, thunkAPI) => {
    const { data: file, headers } = await getOrderCostsReport(data, thunkAPI)
    const fileName = getFileNameFromHeader(headers['content-disposition'])
    downloadFile(file, fileName)
})

export const warehouseCostsReportSlice = createSlice({
    name: 'orderCostReport',
    initialState: INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(fetchCostsReport.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchCostsReport.fulfilled, (state) => {
                state.isLoading = false
            })
            .addCase(fetchCostsReport.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const warehouseCostsReportReducer = warehouseCostsReportSlice.reducer
