import { useEffect, useRef, useState } from 'react'
import { COMMON_PALETTE } from '@octup/core/theme'
import { Grid } from '@octup/core/ui-kit'
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip,
} from 'chart.js'
import { LoadableContent } from 'new_components/__common__'
import { Line } from 'react-chartjs-2'
import styled from 'styled-components'
import {
    DEFAULT_COLORS,
    DEFAULT_BACKGROUND_COLORS,
    DEFAULT_STYLES,
    LEGEND_CONTAINER_ID,
    octupChartsOptions,
} from './octupChartsUtils'
import { renderLegends } from './renderLegends'
import { getInputDate } from '../../utils/dates-util'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler, Tooltip, Legend)

export function createGradient(ctx, area, colorStart, colorEnd, colorMid) {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)
    colorStart && gradient.addColorStop(0, colorStart)
    colorMid && gradient.addColorStop(0.5, colorMid)
    colorEnd && gradient.addColorStop(1, colorEnd)

    return gradient
}

const BORDER_DASH = [6, 6]
const DEFAULT_COLOR = COMMON_PALETTE.grey[800]

const plugins = [
    {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
            renderLegends(chart, args, options)
        },
    },
]

//NEED TO IMPROVE: CURRENTLY ONLY SHOW POINTS THAT HAS PARRLEL INDEX ON THE LABELS ARRAY. NEED TO COME UP WITH A WAY TO INFORCE ALL THE POINTS TO BE SHOWN WITH OR W/O LABELS
export function ChartsArea({
    id,
    isLoading,
    showXAxis,
    showYAxis,
    showSecondYAxis = false,
    secondYAxisConfig,
    isFilled,
    chartsDataSets,
    labels,
    timeSlice = 1,
    showTarget,
    showLabels,
    valueUnits,
    style,
    roundChart = true,
    showLegend = false,
    numberOfShownDataSests = 2,
    backgroundColor = null,
    isVertical = true,
    borderColors = DEFAULT_COLORS,
    backgroundColors = DEFAULT_BACKGROUND_COLORS,
    showGradient = false,
    currency = '',
    isShopCurrency,
    isPercent = false,
    showSeriesNameInTooltip,
    getTooltipTitle,
    getTooltipLabel,
    generateLegendsLabels,
    onLegendClick,
    sx = DEFAULT_STYLES,
}) {
    const chartRef = useRef(null)
    const [chartData, setChartData] = useState({
        datasets: [],
    })
    const skipped = (ctx, value) => (ctx.p0.skip || ctx.p1.skip ? value : undefined)

    useEffect(() => {
        const chart = chartRef.current

        if (!chart) {
            return
        }

        setChartData({
            labels: labels?.map((l) => {
                return getInputDate(l, timeSlice)
            }),
            datasets: chartsDataSets
                ?.map((set, index) => {
                    return {
                        ...set,
                        borderWidth: 1,
                        lineTension: roundChart ? 0.4 : 0,
                        fill: isFilled,
                        label: set.label,
                        data: set.data?.map((value) => value || 0),
                        spanGaps: true,
                        borderDash: set.isDashed ? BORDER_DASH : [],
                        segment: {
                            borderDash: (ctx) => skipped(ctx, BORDER_DASH),
                        },
                        ...(showSecondYAxis && {
                            yAxisID: index === 0 ? 'y' : 'y1',
                        }),
                    }
                })
                .slice(0, numberOfShownDataSests)
                .map((dataset, index) => {
                    const colorIndex =
                        typeof dataset.colorIndex === 'number' ? dataset.colorIndex : index

                    const borderColor = dataset.hasDefaultColor
                        ? DEFAULT_COLOR
                        : borderColors[colorIndex]

                    return {
                        ...dataset,
                        pointBorderColor: borderColor,
                        pointBackgroundColor: borderColor,
                        borderColor: showGradient
                            ? createGradient(
                                  chart.ctx,
                                  chart.chartArea,
                                  '#7A00F3',
                                  '#00A8A8',
                                  '#00C8C0'
                              )
                            : borderColor ||
                              createGradient(chart.ctx, chart.chartArea, '#00C8C0', '#00A8A8'),
                        backgroundColor: createGradient(
                            chart.ctx,
                            chart.chartArea,
                            'rgba(245, 245, 245, 0)',
                            backgroundColors[colorIndex]
                        ),
                    }
                }),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        chartsDataSets,
        // labels - CUASING AN INFINIT LOOP,
        numberOfShownDataSests,
        isFilled,
        roundChart,
        showTarget,
    ])

    return (
        <Color isVertical={isVertical} backgroundColor={backgroundColor} style={style}>
            <LoadableContent isLoading={isLoading}>
                <Grid container height="100%" direction="column" flexWrap="nowrap">
                    {showLegend && (
                        <Grid item container id={`${LEGEND_CONTAINER_ID}-${id}`} sx={sx.legends} />
                    )}
                    <Grid item container xs height={showLegend ? '80%' : '100%'}>
                        <Line
                            ref={chartRef}
                            data={chartData}
                            options={octupChartsOptions({
                                id,
                                showLegend: false,
                                showXAxis,
                                showYAxis,
                                showSecondYAxis,
                                secondYAxisConfig,
                                showLabels,
                                showSeriesNameInTooltip,
                                isShopCurrency,
                                currency,
                                isPercent,
                                valueUnits,
                                timeSlice,
                                getTooltipTitle,
                                getTooltipLabel,
                                generateLegendsLabels,
                                onLegendClick,
                                sx,
                            })}
                            plugins={plugins}
                        />
                    </Grid>
                </Grid>
            </LoadableContent>
        </Color>
    )
}

const Color = styled.div`
    background-color: ${(props) => props.backgroundColor};
    margin-left: ${(p) => (p.isVertical ? '0' : '-52vw')};
    margin-top: ${(p) => (p.isVertical ? '0' : '30vh')};
    width: ${(p) => (p.isVertical ? '100%' : '92vh')};
    height: ${(p) => (p.isVertical ? '10rem' : '30vh')};
    rotate: ${(p) => (p.isVertical ? '0' : '90deg')};
`
