import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAbilities } from '@/hooks'
import { APP_ROUTES } from '@/models/appConfig'
import type { SettingsRouteTab } from '@/models/settings'
import { SettingsPage } from '@/new_pages'
import { resolveRoutePath } from '@/utils'
import { ROUTES_TABS } from './utils'

export const SettingsPageContainer = () => {
    const { section } = useParams()
    const navigate = useNavigate()
    const { isPageAvailable } = useAbilities()

    const availableRoutes = useMemo(
        () => ROUTES_TABS.filter((tab) => isPageAvailable(tab.route)),
        [isPageAvailable]
    )

    useEffect(() => {
        const defaultRoute = availableRoutes[0]?.route
        if (!section && defaultRoute) {
            navigate(`/${defaultRoute}`)
        }
    }, [section, navigate, availableRoutes])

    const currentRoute = useMemo(
        () => section && resolveRoutePath(APP_ROUTES.SETTINGS, { ':section?': section }),
        [section]
    )

    const { Component } = useMemo(
        () => availableRoutes.find((tab) => tab.route === currentRoute) || ({} as SettingsRouteTab),
        [currentRoute, availableRoutes]
    )

    if (!currentRoute) return null

    return (
        <SettingsPage tabs={availableRoutes} selectedTab={currentRoute}>
            {Component && <Component />}
        </SettingsPage>
    )
}
