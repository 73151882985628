import { Fragment } from 'react'
import { Box, SvgIcon } from '@mui/material'
import { PickersCalendarHeader } from '@mui/x-date-pickers'
import { WarningIcon } from '@/new_assets/icons'
import { InvoicesRequestText } from '@/new_components/__common__'
import { InvoicesRequestContainer } from '@/new_containers/components/__common__'
import { OctupTooltip } from '@/shared/ui/MUIWrappers/OctupTooltip'
import { intercom } from '@/utils'
import {
    containerStyle,
    warningContainerStyle,
    warningIconStyle,
    emailStyle,
} from './CalendarHeader.styles'

const emailProps = { variant: 'body2', ...emailStyle }
const invoicesRequestTextProps = {
    primaryText: 'Months marked in red are missing invoice, please',
    buttonText: 'contact us',
    onClick: intercom.showNewMessage,
}

export const CalendarHeader = (props) => (
    <Box sx={containerStyle}>
        <PickersCalendarHeader {...props} />
        <Box sx={warningContainerStyle}>
            <OctupTooltip
                position="top"
                isContainerized
                disableInteractive={false}
                title={
                    <InvoicesRequestContainer
                        size="small"
                        showOverflowedTooltip={false}
                        emailProps={emailProps}
                        fallback={<InvoicesRequestText {...invoicesRequestTextProps} />}
                        primaryText={
                            <InvoicesRequestText
                                {...invoicesRequestTextProps}
                                hasPeriod={false}
                                container={Fragment}
                                secondaryText="or send the invoice(s) to"
                            />
                        }
                    />
                }
            >
                <SvgIcon color="error" sx={warningIconStyle}>
                    <WarningIcon />
                </SvgIcon>
            </OctupTooltip>
        </Box>
    </Box>
)
