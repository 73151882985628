import { useEffect, useRef } from 'react'

type IntervalConfigType = {
    disabled?: boolean
    hasInitialCall?: boolean
}

/**
 * @param { () => void } callback - The function to be called at each interval.
 * @param { number | null } delay - The delay in milliseconds for the interval. If delay is null, the interval is stopped.
 * @param { IntervalConfigType } config - Configuration object to control the behavior of the hook.
 */

export const useInterval = (
    callback: () => void,
    delay: number | null,
    config?: IntervalConfigType
) => {
    const savedCallback = useRef<() => void>()
    const { disabled, hasInitialCall } = config || {}

    // Remember the latest callback
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval
    useEffect(() => {
        const intervalCallback = () => savedCallback.current?.()

        if (delay !== null && !disabled) {
            if (hasInitialCall) {
                intervalCallback() // Call the callback immediately if the hasInitialCall is set to true
            }

            const id = setInterval(intervalCallback, delay)
            return () => clearInterval(id)
        }
    }, [disabled, hasInitialCall, delay])
}
