import React, { useEffect } from 'react'
import { DoughnutChart } from '../../../../shared/ui/Charts/ChartDoughnut'
import {
    DougnutChartContainer,
    DougnutChartInnerContainer,
    ColumnContainer,
    MetadataTitle,
    RedText,
    MetadataTitleText,
    MetadataTitleValue,
} from './AlternativeHomeDoughnutChart.styles'

export const AlternativeHomeDoughnutChart = ({
    labels,
    subtitle,
    value,
    metrics,
    metadata,
    isLoading,
    colorSet,
    setSafeMetrics,
    labelsFontSize,
    numbersFontSize,
    lablesColored,
    titleFontSize,
    breakSubTitle,
    metaDataStyle,
    chartSize = '180px',
}) => {
    useEffect(() => {
        setSafeMetrics(metrics ? metrics : [1, 2, 3])
    }, [metrics])

    return (
        <DougnutChartContainer>
            <DougnutChartInnerContainer style={{ width: chartSize }}>
                <DoughnutChart
                    currency={''}
                    isLoading={isLoading}
                    metadata={metadata}
                    titleFontSize={titleFontSize}
                    title={value}
                    subtitle={subtitle}
                    labels={labels}
                    colorSet={colorSet}
                    alternateColorSet={!!colorSet}
                    breakSubTitle={breakSubTitle}
                    metaDataStyle={metaDataStyle}
                    chartData={
                        isLoading
                            ? []
                            : metadata
                              ? metadata.map((d) => {
                                    return d.value
                                })
                              : [0 ? 1 : 2, 0 ? 1 : 3, 0 ? 1 : 4]
                    }
                />
            </DougnutChartInnerContainer>
            <ColumnContainer style={{ ...metaDataStyle }}>
                {metadata &&
                    metadata.map((d, i) => {
                        return (
                            <MetadataTitle key={d.title}>
                                <MetadataTitleValue
                                    colorSet={colorSet}
                                    i={i}
                                    d={d}
                                    labelsFontSize={labelsFontSize}
                                    numbersFontSize={numbersFontSize}
                                >
                                    {d.value}
                                </MetadataTitleValue>
                                <MetadataTitleText
                                    lablesColored={lablesColored}
                                    i={i}
                                    d={d}
                                    labelsFontSize={labelsFontSize}
                                >
                                    {' '}
                                    {d.title}
                                </MetadataTitleText>
                                <RedText>
                                    {d.parentheses
                                        ? ` (${d.parenthesesValue} ${d.parentheses})`
                                        : ''}
                                </RedText>
                            </MetadataTitle>
                        )
                    })}
            </ColumnContainer>
        </DougnutChartContainer>
    )
}
