export function LinkIconSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.8rem"
            height="1.8rem"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M7.08911 10.9105L10.9108 7.08881"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.95528 11.8664L8.36289 13.4588C7.85607 13.9654 7.16876 14.25 6.45213 14.25C5.7355 14.2499 5.04824 13.9652 4.5415 13.4585C4.03477 12.9517 3.75006 12.2645 3.75 11.5478C3.74994 10.8312 4.03453 10.1439 4.54118 9.63706L6.13356 8.04468"
                fill="#F8F7FB"
            />
            <path
                d="M9.95528 11.8664L8.36289 13.4588C7.85607 13.9654 7.16876 14.25 6.45213 14.25C5.7355 14.2499 5.04824 13.9652 4.5415 13.4585C4.03477 12.9517 3.75006 12.2645 3.75 11.5478C3.74994 10.8312 4.03453 10.1439 4.54118 9.63706L6.13356 8.04468"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.8664 9.95527L13.4588 8.36288C13.9654 7.85606 14.25 7.16875 14.25 6.45211C14.2499 5.73548 13.9652 5.04821 13.4585 4.54148C12.9517 4.03474 12.2645 3.75003 11.5478 3.74997C10.8312 3.74991 10.1439 4.0345 9.63706 4.54115L8.04468 6.13354"
                fill="#F8F7FB"
            />
            <path
                d="M11.8664 9.95527L13.4588 8.36288C13.9654 7.85606 14.25 7.16875 14.25 6.45211C14.2499 5.73548 13.9652 5.04821 13.4585 4.54148C12.9517 4.03474 12.2645 3.75003 11.5478 3.74997C10.8312 3.74991 10.1439 4.0345 9.63706 4.54115L8.04468 6.13354"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
