import { LoadableContent, PoweredByOctup } from 'new_components/__common__'
import { useAppSelector } from 'state/hooks'
import type { FallbackImagePropsType } from '../FallbackImageContainer'
import { FallbackImageContainer } from '../FallbackImageContainer'

const DEFAULT_LOGO_WIDTH = '150px'
const FALLBACK_NAME_LOGO = './name-logo.svg'

type CompanyNameLogoContainerPropsType = Partial<FallbackImagePropsType>

export const CompanyNameLogoContainer = ({
    width = DEFAULT_LOGO_WIDTH,
    ...props
}: CompanyNameLogoContainerPropsType) => {
    const { settings } = useAppSelector((state) => state.appConfig)
    const { icons, palette, isDefault, company_name } = settings.data

    return (
        <LoadableContent
            isLoading={settings.isLoading || !icons}
            width={width}
            height="4rem"
            marginTop={0}
        >
            <FallbackImageContainer
                src={icons?.name_logo}
                fallbackSrc={FALLBACK_NAME_LOGO}
                alt={`${company_name} logo`}
                width={width}
                {...props}
            >
                {!isDefault && <PoweredByOctup color={palette.text?.primary} />}
            </FallbackImageContainer>
        </LoadableContent>
    )
}
