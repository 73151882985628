import { Fragment } from 'react'
import { Box, Divider, Grid, Stack } from '@mui/material'
import { COST_TITLES_BY_NAME } from 'constants'
import { ProductCost } from 'new_components/UnitEconomics/ProductCost'
import {
    EditCostButtonContainer,
    ProductCostFormContainer,
} from 'new_containers/components/UnitEconomics'
import { DeleteCostButtonContainer } from 'new_containers/components/__common__'
import { octupDictionary } from 'shared/dictionary/dictionary'
import { containerStyle, dividerContainerStyle, totalDividerStyle } from './AttributedCosts.styles'

export const CUSTOM_COST_SOURCES = [{ name: 'manual', info: 'Manual' }]

export const AttributedCosts = ({ selectedProduct, costs, totalCost, onUpdateData }) => (
    <>
        <Grid container sx={containerStyle}>
            {costs.map((cost, index, arr) => (
                <Fragment key={cost.id || cost.cost_name || cost}>
                    {cost.isNew || cost.isEdit ? (
                        <ProductCostFormContainer cost={cost} />
                    ) : cost.isCustom ? (
                        <ProductCost
                            title={cost.cost_name}
                            value={cost.value}
                            showActionsOnHover
                            sources={CUSTOM_COST_SOURCES}
                            actions={
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <EditCostButtonContainer {...cost} />
                                    <DeleteCostButtonContainer
                                        cost={cost}
                                        selectedProduct={selectedProduct}
                                        onSuccess={onUpdateData}
                                    />
                                </Stack>
                            }
                        />
                    ) : (
                        <ProductCost
                            title={COST_TITLES_BY_NAME[cost.cost_name]}
                            value={cost.value}
                            info={octupDictionary.unit_economics_metrics[cost.cost_name]}
                            showInfoOnHover
                            sources={cost.providers}
                        />
                    )}
                    <Box sx={dividerContainerStyle}>
                        {arr.length - 1 !== index && <Divider variant="middle" />}
                    </Box>
                </Fragment>
            ))}
        </Grid>
        <Box sx={dividerContainerStyle}>
            <Divider variant="middle" sx={totalDividerStyle} />
        </Box>
        <ProductCost title="Total Costs" highlightTitle value={totalCost} />
    </>
)
