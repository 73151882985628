import { forwardRef, useCallback } from 'react'
import type { SvgIconProps, SxProps, Theme } from '@mui/material'
import { SvgIcon as MuiSvgIcon } from '@mui/material'
import { svgIconStyle } from './styles'

type SvgIconPropsType = SvgIconProps & {
    rotate?: number
}

export const SvgIcon = forwardRef(
    ({ rotate, sx, ...props }: SvgIconPropsType, ref: React.Ref<SVGSVGElement>) => {
        const svgIconSx = useCallback(
            (theme: Theme) => ({
                ...(typeof sx === 'function' ? sx(theme) : sx),
                ...svgIconStyle(rotate),
            }),
            [rotate, sx]
        )

        return <MuiSvgIcon ref={ref} sx={svgIconSx as SxProps<Theme>} {...props} />
    }
)
