import { TableCell } from './TableRow.styles'

export const TableRow = ({ tableCells, row, parentRow, showNoData = false, ...props }) =>
    tableCells.map(({ id, field, renderCell, ...cell }) => (
        <TableCell key={id} className="cell" {...cell} {...props}>
            {!showNoData || (row?.[field] !== undefined && row?.[field] !== null)
                ? renderCell?.(row, parentRow) || row[field]
                : '-'}
        </TableCell>
    ))
