import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { CardTitle } from '../Marketing.styles'
import { chartMapIcons } from '../MarketingPage'
import { NoDataError } from '../../../components/NoDataError/NoDataError'
import { SEGMENTS } from '../../../features/metrics/metricsSlice'
import { DICTIONARY_SECTIONS, getDictionaryValue } from '../../../shared/dictionary/dictionary'
import { CardExpendable } from '../../../shared/ui/Card/CardExpendable'
import { DoughnutChart } from '../../../shared/ui/Charts/ChartDoughnut'
import { getFormattedVal, VALUE_TYPE_INDEX } from '../../../shared/utils/metrics-utils'

const getAdSpendByChannelData = (adSpendMetrics) => {
    const ICON_BY_TITLE = {
        facebook_ad_spend: chartMapIcons.facebook,
        google_ad_spend: chartMapIcons.googleAds,
    }

    const tmp_total_spend = adSpendMetrics.reduce(
        (accumulator, currentValue) => accumulator + currentValue.metric.value,
        0
    )

    const graphData = adSpendMetrics
        .filter((el) => el?.metric?.supported)
        .map((el) => {
            const alias = el?.metric?.metricTitle
            const percentage = getFormattedVal({
                val: tmp_total_spend && 100 * (el?.metric?.value / tmp_total_spend),
                valueType: VALUE_TYPE_INDEX.PERCENTAGE,
                customNumOfDigits: null,
                currency: el?.metric?.currency,
                isSupported: true,
                multiplyBy100: false,
                showSymbol: false,
            })

            return {
                id: uuidv4(),
                value: el?.metric?.value || 0,
                title: el?.metric?.metricTitle,
                currency: el?.metric?.currency,
                valueUnits: el?.metric?.valueUnits,
                icon: ICON_BY_TITLE[el?.metric?.metricTitle],
                label: `${alias} ${percentage}`,
            }
        })

    return {
        total: tmp_total_spend,
        currency: graphData.filter((el) => el.currency)[0]?.currency, // since sometimes the BE returns null in currency field while supported
        graphData,
        isSupported: graphData?.length > 0,
    }
}

export function AdSpendByChannelSection() {
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const [adSpendChartData, setAdSpendChartData] = useState([])
    const { metrics, isLoading } = useSelector((state) => state.metrics)

    useEffect(() => {
        if (metrics[SEGMENTS.MARKERING_AD_SPEND_BY_CHANNELS_GRAPH]) {
            setAdSpendChartData(
                getAdSpendByChannelData([...metrics[SEGMENTS.MARKERING_AD_SPEND_BY_CHANNELS_GRAPH]])
            )
        }
    }, [metrics, datePickerValues])

    return (
        <CardExpendable>
            <Grid container direction="column" padding={2}>
                <Grid item container alignSelf="flex-start" flexWrap="nowrap">
                    <CardTitle>
                        {getDictionaryValue('ad_spend_by_channel', DICTIONARY_SECTIONS.TITLES)}
                    </CardTitle>
                </Grid>
                <Grid item container xs>
                    {(adSpendChartData.isSupported || isLoading) && (
                        <DoughnutChart
                            titleFontSize="4.5rem"
                            isLoading={isLoading}
                            legends={adSpendChartData.graphData || []}
                            currency={adSpendChartData.currency}
                            title={
                                getFormattedVal({
                                    val: adSpendChartData?.total,
                                    valueType: VALUE_TYPE_INDEX.ABSOLUTE,
                                    customNumOfDigits: null,
                                    currency: adSpendChartData.currency,
                                    isSupported: true,
                                    multiplyBy100: false,
                                    showSymbol: true,
                                }) // since sometimes the BE returns null in currency field while supported
                            }
                            subtitle={getDictionaryValue(
                                'total_ad_spend',
                                DICTIONARY_SECTIONS.METRICS_TITLES
                            )}
                            labels={adSpendChartData?.graphData?.map((el) => el?.title)}
                            chartData={
                                isLoading
                                    ? []
                                    : adSpendChartData?.graphData?.map((el) =>
                                          !isNaN(el?.value) ? el?.value?.toFixed(0) : 0
                                      )
                            }
                        />
                    )}
                    {!isLoading && !adSpendChartData.isSupported && <NoDataError />}
                </Grid>
            </Grid>
        </CardExpendable>
    )
}
