import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { OctupBreathing } from '../../../shared/ui/Breathing/OctupBreathing'
import { OctupBaseModal } from '../../../shared/ui/Modal/OctupBaseModal'
import { CartIcon, CreditCardIcon } from '../../../shared/assets/icons'
import { CustomerDetails } from './CustomerDetails'
import {
    SingleOrderMainContainer,
    TopContainer,
    BottomContainer,
    PaymentsContainer,
} from './SingleOrderDetails.styles'
import { JourneyItem } from './JourneyItem'
import { OrderModalTitle } from './OrderModalTitle'
import { OrderItems } from './OrderItems'
import { PaymentsData } from './PaymentsData'
import { TopContainerItems } from './TopContainerItems'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'
import { getItemsLength } from './util'

const MAX_ITEMS = 3
const customStyle = { zIndex: 1600 }

export const SingleOrderDeepDive = ({ selectedItem, onDismissModal }) => {
    const octupDispatch = useOctupDispatch(),
        { singleOrder, singleOrderIsLoading: isLoading } = useSelector((state) => state.metrics),
        items = singleOrder?.data?.order?.lineItems?.nodes,
        singleOrderItems = items?.slice(0, MAX_ITEMS),
        singleOrderTpItems = items?.slice(
            MAX_ITEMS,
            singleOrder?.data?.order?.lineItems?.nodes?.length
        ),
        reversedNodes = singleOrder?.data?.order?.events?.nodes?.slice().reverse()
    let subgroupDate = '',
        showSubgroupDate = true

    useEffect(() => {
        octupDispatch.operations.getSingleOrder({ itemId: selectedItem?.order_id })
    }, [octupDispatch.operations, selectedItem?.order_id])

    const singleOrderTopItems = [
        {
            container: PaymentsContainer,
            title: {
                icon: CartIcon,
                text: `Items (${getItemsLength(items)})`,
            },
            content: (
                <OrderItems
                    items={items}
                    singleOrder={singleOrder}
                    singleOrderItems={singleOrderItems}
                    singleOrderTpItems={singleOrderTpItems}
                />
            ),
        },
        {
            container: PaymentsContainer,
            title: {
                icon: CreditCardIcon,
                text: `Payments`,
            },
            content: (
                <PaymentsData
                    getItemsLength={getItemsLength}
                    items={items}
                    singleOrder={singleOrder}
                />
            ),
        },
    ]

    return (
        <OctupBaseModal
            customStyle={customStyle}
            onDismissModal={onDismissModal}
            title={<OrderModalTitle selectedItem={selectedItem} singleOrder={singleOrder} />}
        >
            <SingleOrderMainContainer>
                {isLoading && <OctupBreathing width={'150rem'} height={'100rem'} />}

                {!isLoading && (
                    <>
                        <TopContainer>
                            {singleOrderTopItems.map((el, index) => (
                                <TopContainerItems
                                    {...el}
                                    key={el.title.text}
                                    showVerticalDivider={index === 0}
                                />
                            ))}
                        </TopContainer>
                        <BottomContainer>
                            <JourneyItem
                                reversedNodes={reversedNodes}
                                subgroupDate={subgroupDate}
                                showSubgroupDate={showSubgroupDate}
                            />
                            <CustomerDetails singleOrder={singleOrder} />
                        </BottomContainer>
                    </>
                )}
            </SingleOrderMainContainer>
        </OctupBaseModal>
    )
}
