import styled from 'styled-components'
import { getShortTableDate } from '../../../shared/utils/dates-util'
import { OctupAvatar } from '../../../shared/ui/Avatar/OctupAvatar'
import { SnoozeSvg } from '../../../shared/assets/icons/SnoozeSvg'
import { ShareSvg } from '../../../shared/assets/icons/ShareSvg'
import { LikeSvg } from '../../../shared/assets/icons/LikeSvg'
import { DislikeSvg } from '../../../shared/assets/icons/DislikeSvg'
import { TrashSvg } from '../../../shared/assets/icons/TrashSvg'
import { OctupTableRowDotMenu } from '../../../shared/ui/Tables/OctupTableRowDotMenu'
import { OctupBadge } from '../../../shared/ui/Badges/OctupBadge'
import {
    ACTIONABLE_ITEM_IMPORTANCE,
    compareStrings,
    DOT_INDICATOR_TYPES,
} from '../../../shared/utils/strings-util'
import { DotIndicator } from '../../../shared/ui/DotIndicator/DotIndicator'
import { useDispatch } from 'react-redux'
import { insightsActions } from '../../../features/insights/insightSlice'
import { ObjectivesLogoSvg } from '../../../shared/assets/icons/ObjectivesLogoSvg'
import { DropableMenu } from '../../../shared/ui/DropableMenu/DropableMenu'
import { ObjectiveDDItem } from './ObjectiveDDItem'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'
import { iconsMapping } from '../../../components/UnitEconomics/ProductPNLBreakdown/iconsMapping'
import { Button } from '@octup/core/ui-kit'

const insightStatusColorMapping = {
    new: 'purple',
    'at work': 'blue',
    resolved: 'green',
    snoozed: 'darkgray',
    dismissed: 'gray',
}

export const InsightsTableRow = ({
    rowData,
    setShowEditObjectiveModal,
    setShowDismissInsightModal,
    setSelectedRowData,
}) => {
    const dispatch = useDispatch()
    const isDismissed = rowData?.status === 'dismissed'
    const isNew = rowData?.status === 'new'
    const octupDispatch = useOctupDispatch()

    return (
        <>
            <TableDataStyled isDismissed={isDismissed}>
                <TableDataActionableItemContainer>
                    {rowData?.is_actionable === 1 && (
                        <BadgeContainer>
                            <OctupBadge title={'actionable'} theme={'indigo'} />
                        </BadgeContainer>
                    )}
                    <TableDataActionableItemFirstLine isNew={isNew}>
                        {rowData?.title ? rowData?.title : 'No Data'}
                    </TableDataActionableItemFirstLine>
                    <TableDataActionableItemSecondLine isNew={isNew}>
                        {rowData?.subtitle ? rowData?.subtitle : 'No Data'}
                    </TableDataActionableItemSecondLine>
                </TableDataActionableItemContainer>
            </TableDataStyled>
            <TableDataStyled isDismissed={isDismissed}>
                <OctupBadge
                    title={rowData?.vstatus}
                    theme={insightStatusColorMapping[rowData?.vstatus?.toLowerCase()]}
                />
            </TableDataStyled>
            <TableDataStyled isDismissed={isDismissed}>
                {rowData?.is_actionable === 1 && (
                    <>
                        {!rowData?.objective_id?.length && !isDismissed && (
                            <Button
                                size="small"
                                variant="contained"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setSelectedRowData(rowData)
                                    dispatch(insightsActions.setSelectedInsight(rowData?.id))
                                    setShowEditObjectiveModal({
                                        show: true,
                                        isEditState: true,
                                    })
                                }}
                            >
                                Create Objective
                            </Button>
                        )}

                        {!!rowData?.objective_id?.length && (
                            <div
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }}
                                style={{
                                    zIndex: 99,
                                    backgroundColor: '',
                                    height: '',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <DropableMenu
                                    header={
                                        <div
                                            style={{
                                                fontWeight: 600,
                                                fontSize: '1.5rem',
                                                width: '100%',
                                                lineHeight: 6,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: '.5rem',
                                            }}
                                        >
                                            {+rowData?.objective_id?.length} Objectives
                                        </div>
                                    }
                                    showChevron={true}
                                    disabled={isDismissed}
                                    options={
                                        <div
                                            style={{
                                                minWidth: '20rem',
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                gap: '1rem',
                                            }}
                                        >
                                            {rowData?.objective_id?.map((el, index) => (
                                                <ObjectiveDDItem
                                                    length={rowData?.objective_id?.length}
                                                    index={index}
                                                    objectiveId={el}
                                                    setShowEditObjectiveModal={
                                                        setShowEditObjectiveModal
                                                    }
                                                />
                                            ))}
                                        </div>
                                    }
                                />
                            </div>
                        )}
                    </>
                )}
            </TableDataStyled>

            <TableDataStyled isDismissed={isDismissed}>
                <TableDataImportancyInnerContainer
                    isBold={rowData?.importance.toLowerCase() === 'high'}
                >
                    {rowData?.importance === 'Medium' ? 'Med' : rowData?.importance}
                    {compareStrings(rowData?.importance, ACTIONABLE_ITEM_IMPORTANCE.HIGH) && (
                        <DotIndicator type={DOT_INDICATOR_TYPES.ALERT} />
                    )}
                </TableDataImportancyInnerContainer>
            </TableDataStyled>
            <TableDataStyled isDismissed={isDismissed}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '.5rem',
                        fontSize: 'inherit',
                    }}
                >
                    {iconsMapping[rowData?.segment?.replaceAll(' ', '')?.toLowerCase()]}
                    {rowData?.segment}
                </div>
            </TableDataStyled>
            <TableDataStyled isDismissed={isDismissed}>
                <OwnerContainer isDismissed={isDismissed}>
                    <OctupAvatar
                        title={rowData?.owner?.fullName}
                        imageSrc={rowData?.owner?.imageSrc}
                        size="large"
                    />
                    {rowData?.owner?.fullName}
                </OwnerContainer>
            </TableDataStyled>
            <TableDataStyled isDismissed={isDismissed}>
                {getShortTableDate(rowData?.created_at)?.primaryFormat}
            </TableDataStyled>
            <TableDataStyled>
                {!isDismissed && (
                    <TableButtonsContainer
                        onClick={(e) => {
                            e.stopPropagation()
                            dispatch(insightsActions.setSelectedInsight(rowData?.id))
                        }}
                    >
                        <OctupTableRowDotMenu
                            options={
                                <DotMenuOptionsContainer>
                                    {false && (
                                        <div>
                                            <SnoozeSvg customColor="#504B5A" />
                                            Snooze
                                        </div>
                                    )}
                                    {false && (
                                        <div>
                                            <ShareSvg customColor="#504B5A" />
                                            Share
                                        </div>
                                    )}
                                    {!isDismissed && (
                                        <div
                                            onClick={() => {
                                                octupDispatch.insights.updateInsightLike(
                                                    rowData?.id,
                                                    rowData?.isLike
                                                )
                                            }}
                                        >
                                            <LikeSvg
                                                active={rowData?.isLike}
                                                customColor="#504B5A"
                                            />
                                            Like
                                        </div>
                                    )}
                                    {false && (
                                        <div>
                                            <DislikeSvg customColor="#504B5A" />
                                            Dislike
                                        </div>
                                    )}
                                    {!isDismissed && (
                                        <div
                                            onClick={() => {
                                                setShowDismissInsightModal({
                                                    show: true,
                                                    isEditState: false,
                                                })
                                            }}
                                        >
                                            <TrashSvg customColor="#504B5A" />
                                            Dismiss
                                        </div>
                                    )}
                                    {!isDismissed &&
                                        rowData?.isActionable &&
                                        !rowData?.objectives.length > 0 && (
                                            <div
                                                onClick={() => {
                                                    setSelectedRowData(rowData)
                                                    setShowEditObjectiveModal({
                                                        show: true,
                                                        isEditState: true,
                                                    })
                                                }}
                                            >
                                                <ObjectivesLogoSvg customColor="#504B5A" />
                                                Create Objective
                                            </div>
                                        )}
                                    {isDismissed && (
                                        <div>
                                            <TrashSvg customColor="#504B5A" />
                                            Reopen
                                        </div>
                                    )}
                                </DotMenuOptionsContainer>
                            }
                        />
                    </TableButtonsContainer>
                )}
            </TableDataStyled>
        </>
    )
}

export const renderInsightsRows = (el, rowMenuActions) => {
    return (
        <InsightsTableRow
            rowData={el}
            setShowEditObjectiveModal={rowMenuActions.setShowEditObjectiveModal}
            setShowDismissInsightModal={rowMenuActions.setShowDismissInsightModal}
            setSelectedRowData={rowMenuActions.setSelectedRowData}
            handleSeeObjectiveBtnClick={(e, insightData) => {
                rowMenuActions.handleSeeObjectiveBtnClick(e, insightData.objectives[0])
            }}
        />
    )
}

const TableDataStyled = styled.td`
    height: 100%;
    max-width: fit-content;
    padding-right: 0.5rem;
    opacity: ${(props) => (props.isDismissed ? '.4' : '1')};
`

const OwnerContainer = styled.div`
    font-size: inherit;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 2rem;
    color: inherit;
`

const TableDataActionableItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 40vw;
    min-width: 10vw;
    width: 100%;
`

const TableDataActionableItemFirstLine = styled.div`
    font-size: 1.7rem !important;
    line-height: 3rem;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    text-overflow: ellipsis;
    color: inherit;
    font-weight: ${(props) => (props.isNew ? 600 : 400)};
`

const TableDataActionableItemSecondLine = styled.div`
    font-size: 1.5rem !important;
    line-height: 2.5rem;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    text-overflow: ellipsis;
    color: inherit;
    font-weight: ${(props) => (props.isNew ? 600 : 400)};
`

const TableDataImportancyInnerContainer = styled.div`
    font-weight: ${(props) => (props.isBold ? 600 : 400)};
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    color: inherit;
`

const DotMenuOptionsContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.8rem;

    div {
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        line-height: 1.8rem;
        justify-content: flex-start;
        width: 100%;
        gap: 0.6rem;
        padding: 1rem 1.5rem;

        :hover {
            background: #f1f0f5;
            border-radius: 0.8rem;
        }
    }

    svg {
        width: 2.4rem;
        height: 2.4rem;
    }
`

const TableButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    position: relative;
`

const BadgeContainer = styled.div`
    margin-bottom: 0.3rem;
`
