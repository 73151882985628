import { Grid, SvgIcon } from '@mui/material'
import { ORGANIC } from 'constants'
import { WarningIconSvg } from 'shared/assets/icons'
import { Text } from 'shared/styledComponents'
import { OctupTooltip } from 'shared/ui/MUIWrappers/OctupTooltip'
import { labelContainerStyle, warningIconStyle } from './CampaignLabel.styles'

export const CampaignLabel = ({ label, isUnmatched = false, integrationTitle }) => {
    if (label === ORGANIC) {
        return (
            <Text fontWeight="bold" fontSize="inherit">
                {ORGANIC}
            </Text>
        )
    }

    return (
        <Grid container flexDirection="row" wrap="nowrap" gap="0.5rem" sx={labelContainerStyle}>
            <Grid item>
                <Text fontSize="inherit">{label}</Text>
            </Grid>
            {isUnmatched && (
                <Grid item>
                    <OctupTooltip
                        position="top"
                        isContainerized
                        title={`This campaign couldn't be matched to any data on ${integrationTitle}, please check your UTMs.`}
                    >
                        <SvgIcon sx={warningIconStyle}>
                            <WarningIconSvg />
                        </SvgIcon>
                    </OctupTooltip>
                </Grid>
            )}
        </Grid>
    )
}
