import { forwardRef } from 'react'
import { Select as MuiSelect, FormControl, InputLabel } from '@mui/material'
import type { SelectProps as MuiSelectProps } from '@mui/material'

export type SelectPropsType = MuiSelectProps

export const Select = forwardRef<SelectPropsType['ref'], SelectPropsType>(
    ({ label, size, fullWidth, ...props }, ref) => {
        if (!label) return <MuiSelect ref={ref} size={size} fullWidth={fullWidth} {...props} />

        return (
            <FormControl size={size} fullWidth={fullWidth}>
                <InputLabel>{label}</InputLabel>
                <MuiSelect ref={ref} label={label} {...props} />
            </FormControl>
        )
    }
)
