import styled from 'styled-components'
import { OctupColors } from 'shared/theme/colors/OctupColors'

export const TableCell = styled.td`
    padding-right: 2rem;
    max-width: ${({ maxWidth = 'none' }) => maxWidth};
    color: ${({ color = OctupColors.primaryTextLight }) => color};
    font-weight: ${({ fontWeight = 'initial' }) => fontWeight};
    white-space: ${({ whiteSpace = 'initial' }) => whiteSpace};
    word-break: ${({ wordBreak = 'initial' }) => wordBreak};
`
