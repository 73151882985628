import axios from 'axios'
import { GLOBAL_CONFIG } from '../../config'
import { generateConfig } from '../../shared/utils/utils'

const Localhost = 'localhost:3500'
const API_URL = `${GLOBAL_CONFIG.BASE_URL}objectives`

/**
 * creates new Octup Objective
 * @token optional
 * @objectiveData mandatory
 */
export const createObjective = async (objectiveData, token) => {
    const config = generateConfig(token)
    const response = await axios.put(`${API_URL}`, objectiveData, config)
    return response.data
}

/**
 * fetchs all Octup Objective
 * @token optional
 */
export const getObjectives = async (token) => {
    const config = generateConfig(token)
    const response = await axios.get(`${API_URL}`, config)
    return response.data
}

/**
 * fetchs single Octup Objective by id
 * @token optional
 * @objectiveId mandatory
 */
export const getObjective = async (objectiveId, token) => {
    const config = generateConfig(token)
    const response = await axios.get(`${API_URL}/${objectiveId}`, config)
    return response.data
}

/**
 * updates single Octup Objective by id
 * @token optional
 * @objectiveId mandatory
 */
export const updateObjective = async (objectiveData, token) => {
    const config = generateConfig(token)
    const response = await axios.put(`${API_URL}`, objectiveData, config)
    return response.data
}

/**
 * delete single Octup Objective by id
 * @token optional
 * @objectiveId mandatory
 */
export const deleteObjective = async (objectiveId, token) => {
    const config = generateConfig(token)
    const response = await axios.delete(`${API_URL}/${objectiveId}`, config)
    return response.data
}

const objectiveService = {
    createObjective,
    getObjective,
    getObjectives,
    updateObjective,
    deleteObjective,
}

export default objectiveService
