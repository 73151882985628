export function PDQLogisticsIcon({ size = '2.4rem' }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.1301 2C8.19056 2 6.53047 3.39149 6.19164 5.30124L4.06261 17.3012C3.62786 19.7517 5.51241 22 8.00111 22H9.99741L8.02296 12.6302C7.99734 12.546 7.99306 12.4553 8.01062 12.3684C8.02818 12.2816 8.06685 12.2021 8.12223 12.1391C8.17761 12.0761 8.24746 12.0321 8.32379 12.0121C8.40011 11.9921 8.47984 11.997 8.55385 12.0261L17.4002 16.6709L19.1695 6.69876C19.6043 4.24834 17.7197 2 15.231 2H10.1301ZM17.2092 17.748L13.0451 17.806C12.98 17.8288 12.9208 17.8693 12.8727 17.9241C12.8246 17.9789 12.7889 18.0462 12.7689 18.1203L11.2025 22H13.102C15.0416 22 16.7016 20.6085 17.0405 18.6988L17.2092 17.748Z"
                fill="#925DFE"
            />
        </svg>
    )
}
