import { ShopCurrencyValueContainer } from '@/new_containers/components/__common__'
import { CampaignLabelContainer } from '@/new_containers/components/Attribution'
import { OctupColors } from '@/shared/theme/colors/OctupColors'
import { getShortTableDate } from '@/shared/utils/dates-util'
import { getProcessedCampaignsData } from '../CampaignsTableContainer'

const COMMON_SEARCH_FIELDS = {
    order_number: 'order_number',
    destination: 'destination',
    customer: 'customer',
    date_hour: 'date_hour',
    price: 'price',
}

const COMMON_SORT_HEADERS = {
    ordernumber: 'order_number',
    destination: 'destination',
    customer: 'customer',
    date: 'date_hour',
    value: 'price',
}

const COMMON_TABLE_HEADERS = ['Order Number', 'Date', 'Customer', 'Destination', 'Value']

const COMMON_TABLE_CELLS = [
    {
        id: 'order_number',
        field: 'order_number',
        fontWeight: 600,
        color: OctupColors.primaryActionableDark,
    },
    {
        id: 'date_hour',
        field: 'date_hour',
        whiteSpace: 'nowrap',
        renderCell: ({ date_hour }) => getShortTableDate(date_hour).primaryFormat,
    },
    {
        id: 'customer',
        field: 'customer',
    },
    {
        id: 'destination',
        field: 'destination',
        renderCell: ({ destination }) => destination?.split(',').join(', '),
    },
    {
        id: 'price',
        field: 'price',
        whiteSpace: 'nowrap',
        renderCell: ({ price }) => <ShopCurrencyValueContainer value={price} />,
    },
]

const SOURCE_TABLE_HEADERS = [...COMMON_TABLE_HEADERS, 'Campaign Name']
const SOURCE_SORT_HEADERS = { ...COMMON_SORT_HEADERS, campaignname: 'campaign_name' }
const SOURCE_SEARCH_FIELDS = { ...COMMON_SEARCH_FIELDS, campaign_name: 'campaign_name' }

const SOURCE_TABLE_CELLS = [
    ...COMMON_TABLE_CELLS,
    {
        id: 'campaign_name',
        field: 'campaign_name',
        maxWidth: '35rem',
        wordBreak: 'break-all',
        renderCell: ({ campaign_name }) => <CampaignLabelContainer label={campaign_name} />,
    },
]

export const getTableConfig = ({ source, campaignName, data, ...props }) => {
    const commonConfig = {
        data: getProcessedCampaignsData(data || []),
        title: `${campaignName || source} orders`,
        customCounter: `Total orders: ${data?.length || 0}`,
        tableCells: COMMON_TABLE_CELLS,
        tableHeaders: COMMON_TABLE_HEADERS,
        searchFields: COMMON_SEARCH_FIELDS,
        sortHeaders: COMMON_SORT_HEADERS,
        ...props,
    }

    if (typeof campaignName === 'string') {
        return commonConfig
    }

    return {
        ...commonConfig,
        tableCells: SOURCE_TABLE_CELLS,
        tableHeaders: SOURCE_TABLE_HEADERS,
        searchFields: SOURCE_SEARCH_FIELDS,
        sortHeaders: SOURCE_SORT_HEADERS,
    }
}
