import { Typography } from '@mui/material'
import { OctupBaseModal } from '../../../shared/ui/Modal/OctupBaseModal'
import styled from 'styled-components'
import { InvoicesRequestContent } from './InvoicesRequestContent'
import { OctupColors } from 'shared/theme/colors/OctupColors'
import { intercom } from 'utils'

const modal_content = [
    {
        id: 1,
        header: 'Why is this important?',
        content:
            'The most accurate source of truth regarding your product cost is your warehouse invoices. Valuable costs data can be found only there, including  manufacturing, handling, storage, picking costs and more.',
    },
    {
        id: 2,
        header: 'What is needed from me?',
        content:
            ' In order for us to be able to show you actual product costs, we’ll need you to send us your warehouse invoices.',
    },
    {
        id: 3,
        header: null,
        content: <InvoicesRequestContent />,
    },
]

export const WarehouseInvoicesIngestionModal = ({ dismissModalhandler }) => {
    const buttons = [
        { title: 'Close', variant: 'secondary', onClick: dismissModalhandler },
        { title: 'Upload Invoices', variant: 'contained', disabled: true },
    ]
    return (
        <OctupBaseModal
            title={'Warehouse Invoices Ingestion'}
            buttons={buttons}
            onDismissModal={dismissModalhandler}
            footer={
                <SupportLink onClick={intercom.showNewMessage}>
                    Need any help? Reach out to us
                </SupportLink>
            }
        >
            <WarehouseInvoicesIngestionModalContainer>
                {modal_content.map((el) => (
                    <Paragraph key={el.id}>
                        {el.header && <Typography fontWeight={600}>{el.header}</Typography>}
                        {typeof el.content === 'string' ? (
                            <Typography>{el.content}</Typography>
                        ) : (
                            el.content
                        )}
                    </Paragraph>
                ))}
            </WarehouseInvoicesIngestionModalContainer>
        </OctupBaseModal>
    )
}

const WarehouseInvoicesIngestionModalContainer = styled.div`
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    width: 72rem;
    height: 100%;
    line-height: 1.5;
`

const Paragraph = styled.div`
    font-size: 1.7rem;
`

const SupportLink = styled.a`
    color: ${OctupColors.primaryActionableDark};
    font-weight: 600;
    font-size: 1.5rem;
    cursor: pointer;
`
