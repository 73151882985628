import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { OrdersTable } from './OrdersTable'
import PageLayout from '../../components/PageLayout/PageLayout'
import { SLAConfig } from '../../components/SLAConfig/SLAConfig'
import { getOrdersSLAStatus } from '../../features/metrics/metricsSlice'
import { getFulfilledOrdersSLARange } from '../../features/sections/slaSectionSlice'
import { CardBase } from '../../shared/ui/Card/CardBase'
import { OctupTabs } from '../../shared/ui/Tabs/OctupTabs'

const TABS = [
    { title: 'Under 2 days', key: 'Under 2 days', isActive: true, filterKey: 1 },
    { title: '2-3 days', key: '2-3 days', filterKey: 2 },
    { title: 'Over 3 days', key: 'Over 3 days', filterKey: 3 },
]

const OperationsOverviewPage = () => {
    const dispatch = useDispatch()
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const { sla, slaPage, slaNumOfPages, isLoading } = useSelector((state) => state.metrics)
    const { slaRange } = useSelector((state) => state.sla)
    const [page, setPage] = useState(slaPage || TABS)
    const [slaState, setSlaState] = useState([])
    const [filter, setFilter] = useState(1)
    const [tabs, setTabs] = useState(TABS)
    const [activeTab, setActiveTab] = useState(0)

    useEffect(() => {
        dispatch(getOrdersSLAStatus({ filter, datePickerValues, page }))
    }, [filter, datePickerValues, page])

    useEffect(() => {
        dispatch(getFulfilledOrdersSLARange)
    }, [])

    useEffect(() => {
        dispatch(getOrdersSLAStatus({ filter: activeTab + 1, datePickerValues, page }))
    }, [slaRange])

    useEffect(() => {
        if (!sla) return
        setSlaState(sla)
    }, [sla])

    useEffect(() => {
        setTabs([
            {
                title: `Under ${slaRange?.fulfillment_min_sla} days`,
                key: `Under ${slaRange?.fulfillment_min_sla} days`,
                isActive: true,
                filterKey: 1,
            },
            {
                title: `${slaRange?.fulfillment_min_sla}-${slaRange?.fulfillment_max_sla} days`,
                key: `${slaRange?.fulfillment_min_sla}-${slaRange?.fulfillment_max_sla} days`,
                filterKey: 2,
            },
            {
                title: `Over ${slaRange?.fulfillment_max_sla} days`,
                key: `Over ${slaRange?.fulfillment_max_sla} days`,
                filterKey: 3,
            },
        ])
    }, [slaRange])

    return (
        <PageLayout
            customStyle={{ overflowY: 'hidden' }}
            customPathnames={['Operations', 'OperationsOverview']}
        >
            <div
                style={{
                    width: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2rem',
                    paddingBottom: '2rem',
                }}
            >
                <OctupTabs
                    tabsData={tabs.map((el) => {
                        return {
                            ...el,
                        }
                    })}
                    clickHandlerCallback={(el) => {
                        setFilter(el.filterKey)
                        setActiveTab(el.filterKey - 1)
                    }}
                    defaultActiveTab={tabs[activeTab]?.title}
                />
                <SLAConfig />
            </div>
            <CardBase>
                <TableContainer>
                    <OrdersTable
                        data={slaState}
                        showPagination
                        iaLoading={isLoading}
                        page={page}
                        setPage={setPage}
                        pagesCount={slaNumOfPages}
                        showFilters
                        customHeight={'94%'}
                        title="Fulfilled Orders SLA Status"
                    />
                </TableContainer>
            </CardBase>
        </PageLayout>
    )
}

const TableContainer = styled.div`
    width: 100%;
    height: 65vh;
    @media screen and (min-width: 1400px) {
        height: 80vh;
    }

    & .cell {
        font-size: 2rem;
    }
`

export default OperationsOverviewPage
