import styled from 'styled-components'
import { Button } from '@octup/core/ui-kit'
import { Flex, Row } from '../../shared/styledComponents'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { TOTAL_STEPS } from './OctupIntro.utils'

// nextStep & prevStep are only being injected from here to the keyboard navigation,
// add logic to the OctupTourStepContent for Next & Back buttons to work
export const OctupTourStepContent = ({
    title,
    textBeforeBold,
    boldText,
    textAfterBold,
    icon,
    nextStep,
    prevStep,
    stepNumber,
    isFirst = false,
    isLast = false,
    handleDismissIntro,
}) => {
    return (
        <TourGuideContainer>
            <TourTitle>{title}</TourTitle>
            <Row>
                <IconContainer>{icon}</IconContainer>
                <StyledRow>
                    <TourText>{textBeforeBold}</TourText>
                    <BoldTourText>{boldText}</BoldTourText>
                    <TourText>{textAfterBold}</TourText>
                </StyledRow>
            </Row>
            <AlignRight>
                <ButtonContainer>
                    <StepNumber>
                        {stepNumber} of {TOTAL_STEPS}
                    </StepNumber>
                    <ButtonPadding>
                        {isFirst ? (
                            <Button variant="secondary" onClick={handleDismissIntro}>
                                Skip For Now
                            </Button>
                        ) : (
                            <Button variant="secondary" onClick={prevStep}>
                                Back
                            </Button>
                        )}
                        {isLast ? (
                            <Button variant="contained" onClick={handleDismissIntro}>
                                Done
                            </Button>
                        ) : (
                            <Button variant="contained" onClick={nextStep}>
                                Next
                            </Button>
                        )}
                    </ButtonPadding>
                </ButtonContainer>
            </AlignRight>
        </TourGuideContainer>
    )
}

const TourGuideContainer = styled.div``

const StyledRow = styled.div`
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
`

const AlignRight = styled(Row)`
    justify-content: end;
    padding-top: 1rem;
    margin-bottom: -1rem;
`

const ButtonPadding = styled(Row)`
    gap: 2.5rem;

    & > .MuiButton-root {
        padding: 0.5rem 3rem;
    }
`

const TourTitle = styled.div`
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: ${OctupColors.accent1};
    padding-bottom: 0.5rem;
`

const TourText = styled.div`
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
`

const BoldTourText = styled(TourText)`
    font-weight: 600;
`
const IconContainer = styled.div`
    padding-right: 2rem;
`

const ButtonContainer = styled(Flex)`
    width: 100%;
    justify-content: space-between;
`

const StepNumber = styled.div`
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: ${OctupColors.inputLabels};
`
