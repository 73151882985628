import { TableRow } from 'shared/ui/Tables/TableRow'
import { DICTIONARY_SECTIONS, getDictionaryValue } from 'shared/dictionary/dictionary'
import { calculateChange } from 'features/sections/deepdivesSectionsSlice'
import { MetricTableCell } from 'new_components/Metrics'

const getTableCells = ({ configByTitle, comparedConfigByTitle, mainMetricTitles }) =>
    Object.entries(configByTitle).map(([title, config]) => ({
        id: title,
        field: title,
        renderCell: (row) => (
            <MetricTableCell
                row={row}
                title={title}
                config={config}
                comparedConfig={comparedConfigByTitle?.[title]}
                mainMetricTitles={mainMetricTitles}
            />
        ),
    }))

const getRowsByDate = ({ hash, dataset, title }) => ({
    ...hash.rowsByDate,
    ...dataset.reduce(
        (rows, [value, date]) => ({
            ...rows,
            [date]: { date, [title]: value, ...hash.rowsByDate[date] },
        }),
        {}
    ),
})

const getComparedMappedData = (data) =>
    data.reduce(
        (acc, { dataset, metric }) => ({
            configByTitle: { ...acc.configByTitle, [metric.metricTitle]: metric },
            rowsByDate: getRowsByDate({ hash: acc, dataset, title: metric.metricTitle }),
        }),
        { rowsByDate: {} }
    )

const getMappedData = (data) =>
    data.reduce(
        (acc, { dataset, metric }) => {
            const title = metric.metricTitle
            const dictionaryTitle = getDictionaryValue(title, DICTIONARY_SECTIONS.METRICS_TITLES)
            const headerTitle = dictionaryTitle || title
            const sortTitle = headerTitle.split(' ').join('').toLowerCase()

            return {
                headers: [...acc.headers, { title: headerTitle }],
                sortingFields: { ...acc.sortingFields, [sortTitle]: title },
                configByTitle: { ...acc.configByTitle, [title]: metric },
                rowsByDate: getRowsByDate({ hash: acc, dataset, title }),
            }
        },
        {
            headers: [{ title: 'date' }],
            sortingFields: { date: 'date' },
            rowsByDate: {},
            configByTitle: { date: { time_slice: data[0]?.metric.time_slice } },
        }
    )

const getTrends = ({ mainMetricTitles, index, rows }) =>
    mainMetricTitles.reduce(
        (acc, title) => ({
            ...acc,
            [title]: calculateChange(index, rows, title),
        }),
        {}
    )

const getTableData = ({ originalData, comparedData, mainMetricTitles }) => {
    const mappedData = getMappedData(originalData)
    const comparedMappedData = comparedData && getComparedMappedData(comparedData)

    const rows = Object.values(mappedData.rowsByDate).map((row, index, rows) => ({
        ...row,
        trends: getTrends({ mainMetricTitles, index, rows }),
        ...(comparedMappedData && {
            comparedData: Object.values(comparedMappedData.rowsByDate)[index],
        }),
    }))

    return { ...mappedData, comparedConfigByTitle: comparedMappedData?.configByTitle, rows }
}

const getDataValue = ({ row, field }) => `${row?.[field] || 0}\n${row.comparedData?.[field] || 0}`

export const getDefaultTableConfig = ({ data, mainMetricTitles }) => {
    const [originalData, comparedData] = Object.values(data)
    const tableData = getTableData({ originalData, comparedData, mainMetricTitles })
    const tableCells = getTableCells({ ...tableData, mainMetricTitles })
    const renderRows = (row) => <TableRow row={row} tableCells={tableCells} />

    return {
        rowKeyField: 'date',
        hiddenRowKey: 'date',
        tableData: { headers: tableData.headers, rows: tableData.rows },
        renderRows,
        defaultSortBy: { title: 'date' },
        showSearchFilter: false,
        showCounter: false,
        sortingHeadersToDataMapping: tableData.sortingFields,
        ...(comparedData && { customRowStyle: { height: '6rem' }, getDataValue }),
    }
}
