import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { CollectionDataPercentageType, IntegrationsStatusType } from '@/models/integrations'
import { getIntegrationsStatus } from '@/services/integration'
import type { ThunkAPIConfigType } from '@/services/utils'
import { getCollectionDataPercentage } from './utils'

type IntegrationsStatusStateType = {
    data?: IntegrationsStatusType
    collectionDataPercentage?: CollectionDataPercentageType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: IntegrationsStatusStateType = {
    data: undefined,
    collectionDataPercentage: undefined,
    isLoading: false,
    error: undefined,
}

export const fetchIntegrationsStatus = createAsyncThunk<
    IntegrationsStatusType,
    undefined,
    ThunkAPIConfigType
>('fetchIntegrationsStatus', (_, thunkAPI) => getIntegrationsStatus(thunkAPI))

export const integrationsStatusSlice = createSlice({
    name: 'integrationsStatus',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchIntegrationsStatus.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchIntegrationsStatus.fulfilled, (state, action) => {
                const data = action.payload
                state.data = data
                state.collectionDataPercentage = getCollectionDataPercentage(data)
                state.isLoading = false
            })
            .addCase(fetchIntegrationsStatus.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const integrationsStatusReducer = integrationsStatusSlice.reducer
