import { generateNumberArray } from '@octup/core/utils'
import { OctupBreathing } from '../Breathing/OctupBreathing'

const BREATHING_HEIGHT = '3rem'
const rows = generateNumberArray(5)

export const TableDataLoading = ({ rowStyle, columns = [] }) => {
    if (!columns.length) {
        return rows.map((_, index) => (
            <OctupBreathing key={index} width="100%" height={BREATHING_HEIGHT} marginTop="1rem" />
        ))
    }

    return rows.map((_, rowIndex) => (
        <tr key={rowIndex} style={rowStyle}>
            {columns.map((_, colIndex) => (
                <td key={colIndex}>
                    <OctupBreathing
                        height={BREATHING_HEIGHT}
                        customStyle={{ marginRight: '1rem' }}
                    />
                </td>
            ))}
        </tr>
    ))
}
