import type { Components, Theme } from '@mui/material'

type InputOverridesType = Pick<
    Components<Theme>,
    'MuiOutlinedInput' | 'MuiInputLabel' | 'MuiFormHelperText' | 'MuiInputAdornment'
>

export const INPUT_OVERRIDES: InputOverridesType = {
    MuiOutlinedInput: {
        styleOverrides: {
            root: ({ theme }) => ({
                borderRadius: theme.spacing(1),
                '&:not(.Mui-error):not(.Mui-focused)': {
                    boxShadow: theme.shadows[2],
                    '&:not(:hover) .MuiOutlinedInput-notchedOutline': {
                        borderColor:
                            theme.palette.mode === 'light'
                                ? 'transparent'
                                : theme.palette.grey[800],
                    },
                },
            }),
            sizeSmall: ({ theme }) => ({
                fontSize: theme.spacing(3),
                '& .MuiInputBase-input': {
                    padding: theme.spacing(2.4),
                },
                '&.MuiInputBase-adornedStart': {
                    paddingLeft: theme.spacing(2.4),
                },
                '&.MuiInputBase-adornedEnd': {
                    paddingRight: theme.spacing(2.4),
                },
                '& .MuiInputBase-inputAdornedStart': {
                    paddingLeft: theme.spacing(1.8),
                },
            }),
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            outlined: ({ theme }) => ({
                '& + .MuiInputBase-root .MuiOutlinedInput-notchedOutline span': {
                    fontSize: theme.spacing(2.55),
                },
                '&.MuiInputLabel-shrink:not(.Mui-error)': {
                    '& + .MuiInputBase-root': {
                        boxShadow: theme.shadows[0],
                    },
                    '&:not(.Mui-focused)': {
                        '& + .MuiInputBase-root:not(:hover) .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.grey[500],
                        },
                    },
                },
            }),
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: ({ theme }) => ({
                margin: 0,
                marginTop: theme.spacing(0.5),
                textTransform: 'none',
            }),
        },
    },
    MuiInputAdornment: {
        styleOverrides: {
            root: ({ theme }) => ({
                color: theme.palette.text.secondary,
            }),
            positionStart: {
                marginRight: 0,
            },
        },
    },
}
