import { createSlice } from '@reduxjs/toolkit'
import { SIMULATOR_OPTIONS } from '../../shared/utils/strings-util'

const initialState = {
    activeSimulator: SIMULATOR_OPTIONS.GENERAL,
    products: [],
    selectedProduct: null,
}

const simulatorSlice = createSlice({
    name: 'simulator',
    initialState,
    reducers: {
        setProducts(state, action) {
            state.products = action.payload
            state.selectedProduct = action.payload && action.payload[0]
        },
        setActiveSimulator(state, action) {
            state.activeSimulator = action.payload
        },
        setSelectedProduct(state, action) {
            state.selectedProduct = action.payload
        },
    },
})

export const simulatorSliceActions = simulatorSlice.actions
export const simulatorState = (state) => state.simulator
export const simulatorSliceReducer = simulatorSlice.reducer
