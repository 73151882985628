import styled from 'styled-components'

const DougnutChartContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    max-height: fit-content;
    align-items: center;
    position: relative;
`

const DougnutChartInnerContainer = styled.div`
    width: 180px;
`

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 1rem;
    padding-left: 2rem;
    flex: 1;
    width: 100%;
    min-width: fit-content;
`

const MetadataTitle = styled.div`
    display: flex;
    width: 100%;
    white-space: pre-wrap;
    gap: 1rem;
`
const MetadataTitleText = styled.span`
    white-space: nowrap;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    color: ${`${(props) =>
        props.lablesColored ? props?.colorSet[props.i] : props?.d?.textColor || 'inherit'}`};
    font-size: ${(props) => props?.labelsFontSize || 'inherit'};
`
const MetadataTitleValue = styled.span`
    height: 100%;
    width: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    color: ${(props) => props.d?.color || (props?.i && props?.colorSet[props?.i])};
    font-size: ${(props) => props?.numbersFontSize || props?.labelsFontSize || 'inherit'};
`

const RedText = styled(MetadataTitle)`
    color: red;
`

export {
    DougnutChartContainer,
    DougnutChartInnerContainer,
    ColumnContainer,
    MetadataTitle,
    RedText,
    MetadataTitleText,
    MetadataTitleValue,
}
