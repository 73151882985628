import { useCallback, useEffect, useMemo } from 'react'
import { OctupTabs } from 'shared/ui/Tabs/OctupTabs'
import { DEFAULT_ACTIVE_TAB, getTabsData } from './TabsContainer.utils'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { selectSegment } from 'features/integrations/integrationsSlice'

export const TabsContainer = () => {
    const dispatch = useDispatch()
    const { integrations, integrationsBySegment } = useSelector(
        (state) => state.integrations.integrationsList
    )
    const tabsData = useMemo(() => getTabsData(integrationsBySegment), [integrationsBySegment])
    const handleSelectSegment = useCallback((tab) => dispatch(selectSegment(tab)), [dispatch])

    useEffect(() => {
        handleSelectSegment(DEFAULT_ACTIVE_TAB)
    }, [handleSelectSegment, integrations])

    return (
        <OctupTabs
            showZero={false}
            tabsData={tabsData}
            clickHandlerCallback={handleSelectSegment}
            defaultActiveTab={DEFAULT_ACTIVE_TAB.title}
        />
    )
}
