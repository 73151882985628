import type { TypographyOptions } from '@mui/material/styles/createTypography'

export const TYPOGRAPHY: TypographyOptions = {
    fontFamily: '"Poppins", sans-serif',
    h1: {
        fontSize: '2.25rem',
        fontWeight: 'bold',
    },
    h2: {
        fontSize: '2rem',
        fontWeight: 'bold',
    },
    h3: {
        fontSize: '1.85rem',
        fontWeight: 'bold',
    },
    h4: {
        fontSize: '1.6rem',
        fontWeight: 'bold',
    },
    h5: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    h6: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
    },
    body1: {
        fontSize: '1rem',
    },
    body2: {
        fontSize: '0.85rem',
    },
    subtitle1: {
        fontSize: '0.75rem',
    },
    subtitle2: {
        fontSize: '0.65rem',
    },
    caption: {
        fontSize: '0.75rem',
    },
    overline: {
        fontSize: '0.65rem',
    },
    button: {
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        textWrap: 'nowrap',
    },
}
