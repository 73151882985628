import { TableContainer } from '../MetricsDetailsModal.styles'
import { OctupBaseTable } from '../../../../shared/ui/Tables/OctupBaseTable'
import {
    DICTIONARY_SECTIONS,
    getDictionaryValue,
    octupDictionary,
} from '../../../../shared/dictionary/dictionary'
import { renderDeepDiveRow, renderBreakdownRow, renderPredefinedRow } from './rowRenderUtil'
import { SingleOrderDeepDive } from '../../../../pages/operations/single-order/SingleOrderDeepDive'
import React, { useMemo, useState } from 'react'

//  TODO: use table config for table props instead of component props
export function DeepdiveTable({
    selectedMetricData,
    showSearchFilter = false,
    searchFilterFields,
    deepdives,
    isBreakdown,
    breakdownItemsData,
    isPredefined,
    sortingHeadersToDataMapping,
    customCounter,
    showCounter = false,
}) {
    const [ordersModal, setOrdersModal] = useState({ show: false, selectedItem: null })

    const tableConfig = useMemo(
        () => deepdives[`${selectedMetricData?.metric?.deep_dive}`],
        [deepdives, selectedMetricData?.metric?.deep_dive]
    )

    return (
        <TableContainer id={'table-container'}>
            {breakdownItemsData?.table?.headers || (!isBreakdown && tableConfig?.headers) ? (
                <OctupBaseTable
                    rowKeyField={tableConfig?.rowKeyField}
                    tableName={`${getDictionaryValue(
                        selectedMetricData?.metric?.metricTitle,
                        DICTIONARY_SECTIONS.METRICS_TITLES
                    )}_deepdive ${
                        isPredefined
                            ? new Date().toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                              })
                            : ''
                    }`}
                    hiddenRowKey={tableConfig.hiddenRowKey || 'date'}
                    showSearchFilter={showSearchFilter}
                    customRowStyle={{ height: '5rem' }}
                    searchFilterFields={searchFilterFields}
                    showFilters={false}
                    showCounter={showCounter || !!tableConfig?.showCounter}
                    customCounter={customCounter || tableConfig?.customCounter}
                    showSelectedRow={false}
                    showDownloadCSV={true}
                    showDatePicker={!isPredefined}
                    overrideRowKey={isPredefined}
                    sortingHeadersToDataMapping={
                        sortingHeadersToDataMapping ||
                        tableConfig?.sortingHeadersToDataMapping ||
                        (() => {
                            let tmp = []
                            if (!isBreakdown && !isPredefined) {
                                tmp = [
                                    ...tableConfig?.headers.map((el) => {
                                        if (el.title !== 'change' && el.title !== 'date')
                                            return el?.title?.toLowerCase()
                                    }),
                                ].reduce((a, v) => {
                                    let key =
                                        octupDictionary.titles.metricsTitles[v] &&
                                        octupDictionary.titles.metricsTitles[v]
                                            .toLowerCase()
                                            .toString()
                                            .replace(/\s/g, '')
                                    if (!octupDictionary.titles.metricsTitles[v]) return { ...a }
                                    return {
                                        ...a,
                                        [key]: v,
                                    }
                                })
                                return {
                                    ...tmp,
                                    date: 'date',
                                }
                            } else if (!isPredefined) {
                                let objtmp = {}
                                ;[...breakdownItemsData?.table?.headers].forEach((el) => {
                                    let key = getDictionaryValue(
                                        el.title,
                                        DICTIONARY_SECTIONS.METRICS_TITLES
                                    )
                                        ?.toLowerCase()
                                        ?.toString()
                                        ?.replace(/\s/g, '')
                                    objtmp[key] = el.title
                                })

                                return {
                                    ...objtmp,
                                    date: 'date',
                                }
                            }
                        })()
                    }
                    defaultSortBy={
                        tableConfig?.defaultSortBy ||
                        (sortingHeadersToDataMapping
                            ? { title: sortingHeadersToDataMapping['date'] }
                            : { title: 'date' })
                    }
                    customStyle={{ maxHeight: '20rem', minHeight: '20rem' }}
                    tableData={
                        isPredefined
                            ? {
                                  headers: tableConfig?.headers?.map((el) => el.title),
                                  rows: tableConfig?.rows,
                              }
                            : {
                                  headers: isBreakdown
                                      ? [...breakdownItemsData?.table?.headers].map((el) => {
                                            return el.title !== 'date'
                                                ? getDictionaryValue(
                                                      el.title,
                                                      DICTIONARY_SECTIONS.METRICS_TITLES
                                                  )
                                                : el.title
                                        })
                                      : [
                                            ...tableConfig?.headers.map((el) => {
                                                return el.title !== 'date'
                                                    ? getDictionaryValue(
                                                          el.title,
                                                          DICTIONARY_SECTIONS.METRICS_TITLES
                                                      )
                                                    : el.title
                                            }),
                                        ],
                                  rows: isBreakdown
                                      ? breakdownItemsData?.table?.rows
                                      : tableConfig.rows,
                              }
                    }
                    renderRows={(el) => {
                        return isPredefined
                            ? renderPredefinedRow(
                                  el,
                                  selectedMetricData,
                                  deepdives,
                                  sortingHeadersToDataMapping,
                                  setOrdersModal
                              )
                            : isBreakdown
                              ? renderBreakdownRow(el, selectedMetricData, breakdownItemsData)
                              : renderDeepDiveRow(el, selectedMetricData, deepdives)
                    }}
                />
            ) : (
                'No Data'
            )}
            {ordersModal.show && (
                <SingleOrderDeepDive
                    selectedItem={ordersModal.selectedItem}
                    onDismissModal={() => {
                        setOrdersModal((prev) => {
                            return {
                                ...prev,
                                show: false,
                            }
                        })
                    }}
                />
            )}
        </TableContainer>
    )
}
