import { useLocation, useNavigate } from 'react-router'
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { ModalsContext } from '../../contexts/ModalsContext'
import { OctupTourStepContent } from './OctupTourStepContent'
import { useOctupDispatch, useSelectorSpy } from 'hooks'
import { OctupTour } from './index'
import { OctupBaseModal } from '../../shared/ui/Modal/OctupBaseModal'
import { OctupTourInitialModal } from './OctupTourInitialModal'
import { TOUR_STEPS } from './OctupIntro.utils'
import { useSelector } from 'react-redux'

export function OctupIntro() {
    // TODO: remove context
    const { intro: showUserIntro } = useSelector((state) => state.userConfig)
    const { introModal, setIntroModal } = useContext(ModalsContext)
    const navigate = useNavigate()
    const location = useLocation()
    const octupDispatch = useOctupDispatch()
    const { userInfo } = useSelector((state) => state.auth)

    const [showTour, setShowTour] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [startTour, setStartTour] = useState(false)

    const handleStartTour = useCallback(() => setStartTour(true), [])
    const handleShowTour = useCallback(() => setShowTour(true), [])
    const isTourReady = useMemo(() => showTour && startTour, [showTour, startTour])

    useSelectorSpy(TOUR_STEPS[0].selector, handleShowTour)

    const handleDismissIntro = () => {
        setActiveStep(0)
        setStartTour(false)
        setIntroModal((prev) => {
            return {
                ...prev,
                show: false,
            }
        })
        octupDispatch.userConfig.setStartTour(false)
        octupDispatch.auth.introActivated({ intro: true })
    }
    const handleActivateIntro = useCallback(() => {
        setStartTour(true)
        setIntroModal((prev) => {
            return {
                ...prev,
                show: true,
            }
        })
    }, [setIntroModal])

    const handleNextStep = () => {
        if (activeStep + 1 < TOUR_STEPS.length) {
            navigate(TOUR_STEPS[activeStep + 1].path)
            setActiveStep((prev) => prev + 1)
        }
    }
    const handlePrevStep = () => {
        if (activeStep > 0) {
            navigate(TOUR_STEPS[activeStep - 1].path)
            setActiveStep((prev) => prev - 1)
        }
    }

    useEffect(() => {
        if (showUserIntro.startTour) {
            handleStartTour()
        }
        if (showUserIntro.showNewUserModal && !userInfo.intro) {
            handleActivateIntro()
        }
    }, [showUserIntro])

    return (
        <>
            {isTourReady && (
                <OctupTour
                    isLastStep={activeStep === TOUR_STEPS.length - 1}
                    activeStep={activeStep}
                    showTour={showTour}
                    setShowTour={setShowTour}
                    nextStep={handleNextStep}
                    prevStep={handlePrevStep}
                    update={location.key}
                    handleDismissIntro={handleDismissIntro}
                    steps={[
                        {
                            selector: TOUR_STEPS[activeStep].selector,
                            style: { minWidth: '70rem' },
                            content: (
                                <OctupTourStepContent
                                    {...TOUR_STEPS[activeStep].content}
                                    isFirst={activeStep === 0}
                                    isLast={activeStep === TOUR_STEPS.length - 1}
                                    setShowTour={setShowTour}
                                    stepNumber={activeStep + 1}
                                    nextStep={handleNextStep}
                                    prevStep={handlePrevStep}
                                    handleDismissIntro={handleDismissIntro}
                                />
                            ),
                        },
                    ]}
                />
            )}

            {introModal.show && (
                <OctupBaseModal onDismissModal={handleDismissIntro}>
                    <OctupTourInitialModal
                        handleDismissIntro={handleDismissIntro}
                        startTour={handleStartTour}
                    />
                </OctupBaseModal>
            )}
        </>
    )
}
