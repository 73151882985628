import { ColumnOutterContainer, Title, TitleContainer } from '../AlterativeHome.styles'
import { DICTIONARY_SECTIONS, getDictionaryValue } from '../../../shared/dictionary/dictionary'
import { ButtonIcon } from '../../../shared/ui/Buttons/ButtonIcon'
import { ChevronRightSvg } from '../../../shared/assets/icons'
import { AlternativeHomeTable, YESTERDAY_DATE } from './base-components/AlternativeHomeTable'
import React, { useEffect } from 'react'
import { SEGMENTS } from '../../../features/metrics/metricsSlice'
import { format } from 'date-fns'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'

const YESTERDAY_FORMATTED_DATE = format(YESTERDAY_DATE, 'yyyy-MM-dd')

const YESTERDAY_DATES = {
    startDate: YESTERDAY_FORMATTED_DATE,
    endDate: YESTERDAY_FORMATTED_DATE,
}

export function YesterdayStatsSection({ className, BtnStyle, setShowGMBoard }) {
    const octupDispatch = useOctupDispatch()

    useEffect(() => {
        octupDispatch.metrics.getMetricsBySegment(
            [SEGMENTS.GRAPH, SEGMENTS.ALTERNATIVE_HOME_YESTERDAY],
            YESTERDAY_DATES,
            1
        )
    }, [octupDispatch.metrics])

    return (
        <ColumnOutterContainer className={className}>
            <TitleContainer style={{ marginTop: '1.5rem' }}>
                <Title>{getDictionaryValue('yesterdays_stats', DICTIONARY_SECTIONS.TITLES)}</Title>
                <ButtonIcon
                    className={'first'}
                    mainIcon={ChevronRightSvg}
                    customStyle={BtnStyle}
                    onClick={() => {
                        setShowGMBoard(true)
                    }}
                />
            </TitleContainer>
            <AlternativeHomeTable />
        </ColumnOutterContainer>
    )
}
