import { BottomBottomGraphContainer, InnerAreaChartContainer } from '../../Marketing.styles'
import { BarChartWithImages } from '../../BarChartWithImages'
import React from 'react'

export function ROASChart({ roasChartData, isLoading }) {
    return (
        <BottomBottomGraphContainer>
            <InnerAreaChartContainer>
                {roasChartData.length > 0 && (
                    <BarChartWithImages
                        style={{ height: '50%' }}
                        supported={true}
                        isLoading={isLoading}
                        chartsDataSets={{
                            label: 'ROAS',
                            labels: [...roasChartData?.map((el) => el.label)],
                            datasets: [
                                {
                                    barThickness: 8,
                                    label: '',
                                    data: [...roasChartData?.map((el) => el.data)],
                                    image: [...roasChartData?.map((el) => el.image)],
                                },
                            ],
                        }}
                    />
                )}
            </InnerAreaChartContainer>
        </BottomBottomGraphContainer>
    )
}
