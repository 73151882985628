import {
    ActionableItemsTableContainer,
    DotMenuOptionsContainer,
    OriginStyled,
    OwnerContainer,
    StatusStyled,
    TableButtonsContainer,
    TableDataActionableItemContainer,
    TableDataActionableItemFirstLine,
    TableDataActionableItemSecondLine,
    TableDataStyled,
    TableWrapper,
} from '../objectivesPage.styles'
import { CardExpendable } from '../../../shared/ui/Card/CardExpendable'
import { OctupBaseTable } from '../../../shared/ui/Tables/OctupBaseTable'
import { ButtonIcon } from '../../../shared/ui/Buttons/ButtonIcon'
import { ChevronRightSvg, EditIconSvg, TrashSvg } from '../../../shared/assets/icons'
import { getShortTableDate } from '../../../shared/utils/dates-util'
import { OctupAvatar } from '../../../shared/ui/Avatar/OctupAvatar'
import { objectivesActions } from '../../../features/objectives/objectiveSlice'
import { OctupTableRowDotMenu } from '../../../shared/ui/Tables/OctupTableRowDotMenu'
import {
    DismissObjectiveModal,
    EditObjectiveModal,
} from '../../../components/Modals/ObjectivesModals/ObjectivesModal'
import { HOME_TABLES_TYPES } from '../../../shared/utils/strings-util'
import { DismissInsightModal, InsightDetailsModal } from '../../../components/Modals/InsightsModals'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { insightsActions } from '../../../features/insights/insightSlice'

const objectivesTableData = {
    headersData: [
        'objectives',
        'status',
        'segment',
        'creator',
        'origin',
        'start date',
        'due date',
        'owner',
        '',
    ],
    rowsData: [{}],
}

export function ObjectivesDetailedTable({ setShowEditObjectiveModal, showEditObjectiveModal }) {
    const dispatch = useDispatch()
    const { insight } = useSelector((state) => state.insights)
    const { objectives, objective } = useSelector((state) => state.objectives)
    const [showDismissObjectiveModal, setShowDismissObjectiveModal] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState(null)
    const [showInsightDetailsModal, setShowInsightDetailsModal] = useState({
        show: false,
        isEditState: false,
    })
    const [showDismissInsightModal, setShowDismissInsightModal] = useState({
        show: false,
        isEditState: false,
    })
    const dismissEditObjectiveModal = () => {
        setSelectedRowData(null)
        setShowEditObjectiveModal({ show: false, isEditState: false })
    }
    const dismissDismissObjectiveModal = () => {
        setSelectedRowData(null)
        setShowDismissObjectiveModal({ show: false, isEditState: false })
    }
    const dismissInsightDetailsModal = () => {
        setShowInsightDetailsModal({ show: false, isEditState: false })
    }
    const dismissDismissInsightModal = () => {
        setShowDismissInsightModal({ show: false, isEditState: false })
    }
    const handleSeeInsightBtnClick = (e, el) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch(insightsActions.setSelectedInsight(el.insight))
        setShowInsightDetailsModal((prevState) => {
            return { ...prevState, show: true }
        })
    }
    return (
        <>
            <ActionableItemsTableContainer className={'first'}>
                <CardExpendable>
                    {!objectivesTableData.rowsData || objectivesTableData.rowsData.length === 0 ? (
                        'No dataset'
                    ) : (
                        <TableWrapper>
                            <OctupBaseTable
                                showDownloadCSV={true}
                                showDatePicker={false}
                                tableName={'Objectives'}
                                isRowClickable={true}
                                customHeight={'calc(60vh - 2rem)'}
                                showSelectedRow={false}
                                showSearchFilter={true}
                                searchFilterPlaceholder={'Search...'}
                                searchFilterFields={{
                                    firstObjectiveLine: ['actionableItem', 'message'],
                                    secondObjectiveLine: ['actionableItem', 'summary'],
                                    status: 'viewStatus',
                                    owner: ['owner', 'username'],
                                    origin: 'origin',
                                    segment: 'segment',
                                }}
                                tableData={{
                                    headers: objectivesTableData.headersData,
                                    rows: objectives,
                                }}
                                tableRowClickHandler={() =>
                                    setShowEditObjectiveModal({
                                        isEditState: false,
                                        show: true,
                                    })
                                }
                                setSelectedRowData={setSelectedRowData}
                                selectedRowData={selectedRowData}
                                renderRows={(el) => {
                                    return (
                                        <>
                                            <TableDataStyled
                                                isDismissed={
                                                    el.status &&
                                                    el.status?.toLowerCase() === 'dismissed'
                                                }
                                            >
                                                <TableDataActionableItemContainer>
                                                    <TableDataActionableItemFirstLine>
                                                        {el.actionableItem && el.title && el.title}
                                                    </TableDataActionableItemFirstLine>
                                                    <TableDataActionableItemSecondLine>
                                                        {el.actionableItem &&
                                                            el.subtitle &&
                                                            el.subtitle}
                                                    </TableDataActionableItemSecondLine>
                                                </TableDataActionableItemContainer>
                                            </TableDataStyled>
                                            <TableDataStyled
                                                isDismissed={
                                                    el.status &&
                                                    el.status?.toLowerCase() === 'dismissed'
                                                }
                                            >
                                                <StatusStyled status={el.status}>
                                                    {el.viewStatus}
                                                </StatusStyled>
                                            </TableDataStyled>
                                            <TableDataStyled
                                                isDismissed={
                                                    el.status &&
                                                    el.status?.toLowerCase() === 'dismissed'
                                                }
                                            >
                                                {el.segment}
                                            </TableDataStyled>
                                            <TableDataStyled
                                                isDismissed={
                                                    el.status &&
                                                    el.status?.toLowerCase() === 'dismissed'
                                                }
                                            >
                                                {el.creator.username}
                                            </TableDataStyled>
                                            <TableDataStyled
                                                isDismissed={
                                                    el.status &&
                                                    el.status?.toLowerCase() === 'dismissed'
                                                }
                                            >
                                                <OriginStyled
                                                    isInsight={
                                                        el.origin?.toLowerCase() === 'insight'
                                                    }
                                                >
                                                    {el.type}
                                                    {
                                                        <div
                                                            style={{
                                                                visibility:
                                                                    el.origin &&
                                                                    el.origin.trim() !== '' &&
                                                                    el.origin.toLowerCase() ===
                                                                        'insight'
                                                                        ? 'visible'
                                                                        : 'hidden',
                                                            }}
                                                        >
                                                            <ButtonIcon
                                                                tooltipContent="Open insight Details"
                                                                mainIcon={ChevronRightSvg}
                                                                onClick={(e) => {
                                                                    handleSeeInsightBtnClick(e, el)
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </OriginStyled>
                                            </TableDataStyled>
                                            <TableDataStyled
                                                isDismissed={
                                                    el.status &&
                                                    el.status.toLowerCase() === 'dismissed'
                                                }
                                            >
                                                {el.startDate
                                                    ? `${
                                                          getShortTableDate(el.startDate)
                                                              .month_and_day +
                                                          '' +
                                                          getShortTableDate(el.startDate).year
                                                      }`
                                                    : ''}
                                            </TableDataStyled>
                                            <TableDataStyled
                                                isDismissed={
                                                    el.status &&
                                                    el.status.toLowerCase() === 'dismissed'
                                                }
                                            >
                                                {el.dueDate
                                                    ? `${
                                                          getShortTableDate(el.dueDate)
                                                              .month_and_day +
                                                          ',' +
                                                          getShortTableDate(el.dueDate).year
                                                      }`
                                                    : ''}
                                            </TableDataStyled>
                                            <TableDataStyled
                                                isDismissed={
                                                    el.status &&
                                                    el.status.toLowerCase() === 'dismissed'
                                                }
                                            >
                                                <OwnerContainer>
                                                    <OctupAvatar
                                                        title={el.owner.username}
                                                        imageSrc={el.owner.imageSrc}
                                                        size="large"
                                                    />
                                                    {el.owner && el.owner.username}
                                                </OwnerContainer>
                                            </TableDataStyled>
                                            <TableDataStyled
                                                isDismissed={
                                                    el.status &&
                                                    el.status.toLowerCase() === 'dismissed'
                                                }
                                            >
                                                <TableButtonsContainer
                                                    // className={"tbl-row-action-btns"}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setSelectedRowData(el)
                                                        dispatch(
                                                            objectivesActions.setSelectedObjective(
                                                                el.id
                                                            )
                                                        )
                                                    }}
                                                >
                                                    {el.status.toLowerCase() !== 'dismissed' && (
                                                        <OctupTableRowDotMenu
                                                            options={
                                                                <DotMenuOptionsContainer>
                                                                    <div
                                                                        onClick={() => {
                                                                            setShowEditObjectiveModal(
                                                                                {
                                                                                    isEditState: true,
                                                                                    show: true,
                                                                                }
                                                                            )
                                                                        }}
                                                                    >
                                                                        <EditIconSvg customColor="#504B5A" />
                                                                        Edit
                                                                    </div>
                                                                    <div
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                objectivesActions.setSelectedObjective(
                                                                                    el.id
                                                                                )
                                                                            )
                                                                            setShowDismissObjectiveModal(
                                                                                {
                                                                                    show: true,
                                                                                    isEditState: false,
                                                                                }
                                                                            )
                                                                        }}
                                                                    >
                                                                        <TrashSvg customColor="#504B5A" />
                                                                        Delete
                                                                    </div>
                                                                </DotMenuOptionsContainer>
                                                            }
                                                        />
                                                    )}
                                                </TableButtonsContainer>
                                            </TableDataStyled>
                                        </>
                                    )
                                }}
                                sortingHeadersToDataMapping={{
                                    objectives: ['actionableItem', 'message'],
                                    status: 'viewStatus',
                                    creator: ['creator', 'username'],
                                    duedate: 'dueDate',
                                    startdate: 'startDate',
                                    segment: 'segment',
                                    owner: ['owner', 'username'],
                                    origin: 'type',
                                }}
                                requestedFilters={[
                                    {
                                        title: 'status',
                                        dataPropsMapping: ['viewStatus'],
                                        dropdownConfig: { isMultiSelection: true },
                                    },
                                    {
                                        title: 'creator',
                                        dataPropsMapping: ['creator', 'username'],
                                        dropdownConfig: { isMultiSelection: true },
                                    },
                                    {
                                        title: 'owner',
                                        dataPropsMapping: ['owner', 'username'],
                                        dropdownConfig: { isMultiSelection: true },
                                    },

                                    {
                                        title: 'segment',
                                        dataPropsMapping: ['segment'],
                                        dropdownConfig: { isMultiSelection: true },
                                    },
                                    {
                                        title: 'origin',
                                        dataPropsMapping: ['type'],
                                        dropdownConfig: { isMultiSelection: true },
                                    },
                                ]}
                            />
                        </TableWrapper>
                    )}
                </CardExpendable>
            </ActionableItemsTableContainer>
            {showEditObjectiveModal.show && (
                <EditObjectiveModal
                    isEditState={showEditObjectiveModal.isEditState}
                    data={
                        selectedRowData
                            ? selectedRowData
                            : objective
                              ? objective
                              : {
                                    title: 'new',
                                    origin: 'Manual',
                                    segment: 'Manual',
                                    actionableItem: {
                                        message: '',
                                        requiredAction: '',
                                    },
                                }
                    }
                    dismissModalhandler={dismissEditObjectiveModal}
                    activeTable={selectedRowData || objective ? HOME_TABLES_TYPES.OBJECTIVES : null}
                />
            )}
            {showDismissObjectiveModal.show && (
                <DismissObjectiveModal dismissModalhandler={dismissDismissObjectiveModal} />
            )}
            {showInsightDetailsModal.show && insight && (
                <InsightDetailsModal
                    // userInfo={userInfo}
                    showButtons={false}
                    dismissModalhandler={dismissInsightDetailsModal}
                    // holdInsightDetailsModal={holdInsightDetailsModal}
                    selectedItem={insight}
                    // setShowSnoozeInsightModal={setShowSnoozeInsightModal}
                    setShowDismissInsightModal={setShowDismissInsightModal}
                    // setShowDislikeInsightModal={setShowDislikeInsightModal}
                    // setShowShareInsightModal={setShowShareInsightModal}
                    setShowInsightDetailsModal={setShowInsightDetailsModal}
                    setShowEditObjectiveModal={setShowEditObjectiveModal}
                    setShowDismissObjectiveModal={setShowDismissObjectiveModal}
                />
            )}
            {showDismissInsightModal.show && (
                <DismissInsightModal
                    dismissModalhandler={dismissDismissInsightModal}
                    showOnHoldModal={{
                        onCancel: true,
                        onSubmit: false,
                        actionFn: () => {
                            setShowInsightDetailsModal((prevState) => {
                                return { ...prevState, show: true }
                            })
                        },
                    }}
                />
            )}
        </>
    )
}
