import { useSelector } from 'react-redux'
import styled from 'styled-components'

const Main = ({ children }) => {
    const { isMainMenuCollapsed, isMainMenuCollapsedBtnActive } = useSelector(
        (state) => state.navigation
    )

    return (
        <MainOuterContainer
            isMainMenuCollapsed={isMainMenuCollapsed}
            isMainMenuCollapsedBtnActive={isMainMenuCollapsedBtnActive}
        >
            <MainInnerContainer>{children}</MainInnerContainer>
        </MainOuterContainer>
    )
}

const MainOuterContainer = styled.main`
    display: flex;
    flex-direction: column;
    width: calc(
        ${(props) =>
            props.isMainMenuCollapsed ||
            (props.isMainMenuCollapsedBtnActive && !props.isMainMenuCollapsed)
                ? '100% - 5rem'
                : '100% - 24.2rem'}
    );
    background-color: #f6f5f9;
    height: 100%;
    margin-left: ${(props) =>
        props.isMainMenuCollapsed ||
        (props.isMainMenuCollapsedBtnActive && !props.isMainMenuCollapsed)
            ? '5rem'
            : '24.2rem'};
`

const MainInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
`

export default Main
