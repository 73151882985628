import { useState, useEffect } from 'react'

export const useAxios = (configObj) => {
    const [response, setResponse] = useState([])
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [controller, setController] = useState(false)

    const axiosFetch = async (configObj) => {
        const { axiosInstance, method, url, requestConfig = {} } = configObj
        try {
            setIsLoading(true)
            // prevents memory leaks
            const ctrl = new AbortController()
            setController(ctrl)
            //axios works with lowercase methods
            const res = await axiosInstance[method.toLowerCase()](url, {
                ...requestConfig,
                signal: ctrl.signal,
            })
            // console.log(res.data); //for development env only, will be removed later
            setResponse(res.data)
        } catch (err) {
            // console.log(error.res); //for development env only, will be removed later
            setError(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        //cleanup
        return () => {
            // in case the component unmounts
            controller.abort()
        }
    }, [controller])
    return { response, error, isLoading, axiosFetch }
}
