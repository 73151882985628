import type { NumberValueUnitType } from '@octup/core/models'
import { VALUE_UNIT_TYPES } from '@octup/core/models'

const VALUE_UNITS_INDEX = {
    FRACTION: 1,
    PERCENTAGE: 2,
    ABSOLUTE: 3,
}

const VALUE_UNITS_TO_INDEX_MAP = {
    fraction: VALUE_UNITS_INDEX.FRACTION,
    percentage: VALUE_UNITS_INDEX.PERCENTAGE,
    absolute: VALUE_UNITS_INDEX.ABSOLUTE,
}

const getDigitCount = (value: number, valueUnitsIndex: number) => {
    if (valueUnitsIndex === VALUE_UNITS_INDEX.ABSOLUTE) {
        return 0
    }

    if (valueUnitsIndex === VALUE_UNITS_INDEX.PERCENTAGE) {
        return value > 10 ? 1 : 2
    }

    if (valueUnitsIndex === VALUE_UNITS_INDEX.FRACTION) {
        if (value > 1000) return 0
        return value > 10 ? 1 : 2
    }
}

export type FormatNumberParamsType = {
    value?: number | null
    valueUnits?: NumberValueUnitType
    digitCount?: number
    currency?: string | null
    isSupported?: boolean
}

export const formatNumber = ({
    value,
    valueUnits = VALUE_UNIT_TYPES.ABSOLUTE,
    digitCount: customDigitCount,
    currency,
    isSupported,
}: FormatNumberParamsType) => {
    if (typeof value !== 'number') {
        return isSupported ? 0 : '-'
    }

    const valueUnitsIndex =
        typeof valueUnits === 'number' ? valueUnits : VALUE_UNITS_TO_INDEX_MAP[valueUnits]

    const digitCount =
        typeof customDigitCount === 'number'
            ? customDigitCount
            : getDigitCount(value, valueUnitsIndex)

    if (valueUnitsIndex === VALUE_UNITS_INDEX.PERCENTAGE) {
        return (value * 100).toFixed(digitCount) + '%'
    }

    if (currency) {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
            minimumFractionDigits: digitCount,
            maximumFractionDigits: digitCount,
        })

        return currencyFormatter.format(value)
    }

    return value.toFixed(digitCount)
}
