import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UnitEconomicsPage } from 'new_pages'
import {
    fetchInvoicesDates,
    fetchProducts,
    removeProducts,
    selectProduct,
    setQuery,
} from 'state/features/unitEconomics'
import { useQueryString } from 'hooks'

export const UnitEconomicsPageContainer = () => {
    const dispatch = useDispatch()
    const { queryParams } = useQueryString()
    const { invoicesDates, products } = useSelector((state) => state.unitEconomics)
    const [showCostModal, setShowCostModal] = useState(false)
    const toggleCostModal = useCallback(() => setShowCostModal((open) => !open), [])

    useEffect(() => {
        dispatch(setQuery(queryParams))
        return () => {
            dispatch(setQuery())
            dispatch(selectProduct())
            dispatch(removeProducts())
        }
    }, [dispatch, queryParams])

    useEffect(() => {
        dispatch(fetchInvoicesDates())
    }, [dispatch])

    const handleUpdateData = useCallback(() => {
        if (products.query?.date) {
            return dispatch(fetchProducts(products.query))
        }
    }, [dispatch, products.query])

    useEffect(() => {
        handleUpdateData()
    }, [dispatch, handleUpdateData, products.query])

    return (
        <UnitEconomicsPage
            error={invoicesDates.error || products.error}
            showCostModal={showCostModal}
            onToggleCostModal={toggleCostModal}
            selectedProduct={products.selectedProduct}
            onUpdateData={handleUpdateData}
        />
    )
}
