import styled from 'styled-components'
import { Box, CircularProgress } from '@mui/material'
import { OctupColors } from '../../theme/colors/OctupColors'

export function OctupButton({
    id = '',
    type = 'button',
    tooltip = '',
    onClick,
    title = 'save',
    iconRight = null,
    iconLeft = null,
    disabled = false,
    extandableWidth = false,
    colorScheme = 'primary',
    heightScheme = 'big',
    textSize = '1.7rem',
    customStyle = null,
    variant = 'outlined',
    isLoading,
}) {
    const CustomRightIcon = iconRight
    const CustomLeftIcon = iconLeft
    return (
        <ButtonStyled
            id={id}
            type={type}
            disabled={disabled || isLoading}
            onClick={onClick}
            colorScheme={colorScheme}
            extandableWidth={extandableWidth}
            textSize={textSize}
            heightScheme={heightScheme}
            title={tooltip}
            style={customStyle}
            variant={variant}
        >
            {isLoading && (
                <Box display="flex">
                    <CircularProgress size={textSize} color="inherit" />
                </Box>
            )}
            {!isLoading && iconLeft && (
                <Icon>
                    <CustomLeftIcon
                        customColor={colorScheme === 'primary' ? `#FFFFFF` : '#007f82'}
                    />
                </Icon>
            )}
            {title && <ButtonText variant={variant}>{title}</ButtonText>}
            {iconRight && (
                <Icon>
                    <CustomRightIcon
                        customColor={colorScheme === 'primary' ? `#FFFFFF` : '#007f82'}
                    />
                </Icon>
            )}
        </ButtonStyled>
    )
}

const ButtonStyled = styled.button`
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
    height: ${(props) =>
        props.heightScheme === 'big'
            ? '5rem'
            : props.heightScheme === 'modernate'
              ? '4rem'
              : '3.2rem'};
    white-space: nowrap;
    width: ${(props) => props.extandableWidth && '100%'};
    font-size: ${(props) => props.textSize};
    font-style: normal;
    box-shadow: ${(props) =>
        props.variant === 'text'
            ? 'none'
            : '-0.2rem -0.2rem 0.8rem #ffffff, 0.2rem 0.2rem 0.4rem rgba(80, 75, 90, 0.16)'};
    border-radius: 0.8rem;
    background: ${(props) =>
        props.colorScheme === 'primary'
            ? 'linear-gradient(44.56deg, #007f82 -1.25%, #00a8a8 100.79%)'
            : `${OctupColors.cardsBackground}`};
    color: ${(props) => (props.colorScheme === 'primary' ? '#F8F7FB' : '#007F82')};
    padding: 1rem 2rem;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    &:active {
        box-shadow: ${(props) =>
            props.variant === 'text'
                ? 'none'
                : !props.disabled
                  ? 'inset -0.2rem -0.2rem 0.4rem #ffffff,inset 0.2rem 0.2rem 0.8rem rgba(80, 75, 90, 0.32)'
                  : '-0.2rem -0.2rem 0.8rem #ffffff, 0.2rem 0.2rem 0.4rem rgba(80, 75, 90, 0.16)'};
    }

    opacity: ${(props) => props.disabled && '0.2'};

    &:hover {
        ${(props) =>
            !props.disabled &&
            `
                opacity: 0.9;
                background: ${props.colorScheme === 'primary' ? '#00A8A8' : 'rgba(241,243,247,1)'};
            `};
    }
`

const ButtonText = styled.div`
    text-transform: ${(props) => (props.variant === 'text' ? 'none' : 'capitalize')};
    font-size: inherit;
    width: 100%;
    font-weight: 500;
    line-height: 2rem;
    padding: 0 1rem;
`

const Icon = styled.div`
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        padding: 0.1rem;
        height: 3rem;
    }
`
