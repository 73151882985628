// TODO: REUSABLE COMPONENT (move the folder into a right place)

import React, { useState, useMemo, useCallback } from 'react'
import { OctupBaseTable } from '../../../shared/ui/Tables/OctupBaseTable'
import { SingleOrderDeepDive } from '../single-order/SingleOrderDeepDive'
import {
    ORDERS_TABLE_CONFIG,
    ORDERS_TABLE_HEADERS,
    ORDERS_SEARCH_FIELDS,
    ORDERS_SORT_HEADERS,
    getTableName,
} from './OrdersTable.utils'
import { OrdersTableRow } from './OrdersTableRow'

export const OrdersTable = ({
    data,
    title,
    pagesCount,
    tableCells,
    tableHeaders = ORDERS_TABLE_HEADERS,
    searchFields = ORDERS_SEARCH_FIELDS,
    sortHeaders = ORDERS_SORT_HEADERS,
    ...props
}) => {
    const [showModal, setShowModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    const tableName = useMemo(() => getTableName(title), [title])
    const tableData = useMemo(() => ({ headers: tableHeaders, rows: data }), [data, tableHeaders])

    const renderRows = useCallback(
        (row) => <OrdersTableRow row={row} tableCells={tableCells} />,
        [tableCells]
    )

    const handleToggleShowModal = useCallback(() => setShowModal((prevState) => !prevState), [])

    const handleRowClick = useCallback(
        (row) => {
            setSelectedItem(row)
            handleToggleShowModal()
        },
        [handleToggleShowModal]
    )

    return (
        <>
            <OctupBaseTable
                count={pagesCount}
                tableName={tableName}
                tableData={tableData}
                rowOnClick={handleRowClick}
                renderRows={renderRows}
                sortingHeadersToDataMapping={sortHeaders}
                searchFilterFields={searchFields}
                {...ORDERS_TABLE_CONFIG}
                {...props}
            />

            {showModal && (
                <SingleOrderDeepDive
                    selectedItem={selectedItem}
                    onDismissModal={handleToggleShowModal}
                />
            )}
        </>
    )
}
