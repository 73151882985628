import { BreathingContainer } from '../MetricsDetailsModal.styles'
import { OctupBreathing } from '../../../../shared/ui/Breathing/OctupBreathing'

export function LoadingStateGraphShimmer() {
    return (
        <BreathingContainer>
            <OctupBreathing width="100%" height="24vh"></OctupBreathing>
        </BreathingContainer>
    )
}
