import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { OctupTabItem } from './OctupTabItem'

export const OctupTabs = ({
    tabsData,
    clickHandlerCallback,
    defaultActiveTab,
    showZero = true,
    style,
}) => {
    const [activeTab, setActiveTab] = useState(defaultActiveTab)
    useEffect(() => {
        setActiveTab(defaultActiveTab)
    }, [defaultActiveTab])

    return (
        <TabsContainer style={style}>
            {tabsData.map((el) => {
                if (!showZero && el.count === 0) return
                else
                    return (
                        <OctupTabItem
                            {...el}
                            key={el.title}
                            isActive={el.title === activeTab}
                            count={el.count && el.count}
                            clickHandler={() => {
                                setActiveTab(el.title)
                                clickHandlerCallback(el)
                            }}
                        />
                    )
            })}
        </TabsContainer>
    )
}

const TabsContainer = styled.div`
    width: 100%;
    min-height: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 3rem;
`
