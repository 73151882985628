import { OctupBreathing } from '../../../shared/ui/Breathing/OctupBreathing'
import { CardBase } from '../../../shared/ui/Card/CardBase'
import styled from 'styled-components'

export const MobileMetricLoader = () => {
    return (
        <MainContainer>
            <CardBase>
                <BreathingContainer>
                    <OctupBreathing width="25rem" height="3rem"></OctupBreathing>
                    <OctupBreathing width="20rem" height="4rem"></OctupBreathing>
                    <OctupBreathing width="38rem" height="8rem"></OctupBreathing>
                </BreathingContainer>
            </CardBase>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    width: 92%;
    margin-left: 5%;
`

const BreathingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2vh;
    padding-bottom: 3vh;
`
