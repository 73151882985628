import { Grid, InputAdornment, TextField, Autocomplete } from '@octup/core/ui-kit'
import { FormFieldContainer } from '@/new_containers/components/__common__'
import { OctupBaseModal } from '@/shared/ui/Modal/OctupBaseModal'
import { modalContainerStyle } from './CostModal.styles'
import { DividedRadioTabs } from './DividedRadioTabs'

const PRODUCTS_FIELD_PROPS = {
    getOptionKey: (option) => option.product_id,
    getOptionLabel: (option) => option.product_title,
    getOptionImage: (option) => option.product_image,
    inputProps: { label: 'Products', required: true },
}

export const CostModal = ({ isEditState, products, shop_currency, isLoading, ...props }) => (
    <OctupBaseModal title={isEditState ? 'Edit Cost' : 'Add Cost'} {...props}>
        <Grid container rowSpacing={5} sx={modalContainerStyle}>
            <Grid item container xs={8}>
                <FormFieldContainer
                    as={Autocomplete}
                    name="products"
                    multiple
                    fullWidth
                    disableCloseOnSelect
                    options={products}
                    loading={isLoading}
                    noOptionsText="No Products"
                    selectAllLabel="All Products"
                    helperText="By adding a cost to a product, it will be added to all its variants"
                    {...PRODUCTS_FIELD_PROPS}
                />
            </Grid>
            <Grid item container xs={8}>
                <FormFieldContainer
                    as={TextField}
                    required
                    fullWidth
                    name="cost_name"
                    label="Name"
                    placeholder="Enter Cost Name"
                />
            </Grid>
            <Grid item container xs={5}>
                <FormFieldContainer
                    as={TextField}
                    required
                    fullWidth
                    name="value"
                    type="number"
                    label="Value"
                    placeholder="Enter Cost Value"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">{shop_currency}</InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item container alignItems="center" gap="2rem" height="100%">
                <FormFieldContainer as={DividedRadioTabs} name="is_divided" fullWidth />
            </Grid>
        </Grid>
    </OctupBaseModal>
)
