import { forwardRef } from 'react'
import type { SelectPropsType } from '@octup/core/ui-kit'
import { MenuItem, Select } from '@octup/core/ui-kit'
import { WEEKDAYS_LABELS } from '@/models/settings'

export type WeekdaySelectPropsType = SelectPropsType

const WEEKDAYS_OPTIONS = Object.entries(WEEKDAYS_LABELS)

export const WeekdaySelect = forwardRef<SelectPropsType['ref'], SelectPropsType>(
    (props: WeekdaySelectPropsType, ref) => (
        <Select {...props} ref={ref}>
            {WEEKDAYS_OPTIONS.map(([value, label]) => (
                <MenuItem key={value} value={value}>
                    {label}
                </MenuItem>
            ))}
        </Select>
    )
)
