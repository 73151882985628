import React from 'react'
import { getIconColor } from './utils'

export function HelpLogoSvg({ active, customColor, customActiveColor }) {
    return (
        <svg
            width="1.8rem"
            height="1.8rem"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.33333 8.33325H9V12.9999H9.66667"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 6.33325C9.55228 6.33325 10 5.88554 10 5.33325C10 4.78097 9.55228 4.33325 9 4.33325C8.44772 4.33325 8 4.78097 8 5.33325C8 5.88554 8.44772 6.33325 9 6.33325Z"
                fill={getIconColor(active, customColor, customActiveColor)}
            />
        </svg>
    )
}
