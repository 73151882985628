import styled from 'styled-components'

export const TableContainer = styled.div`
    width: 100%;

    & .tableHeader {
        margin-bottom: 3rem;
    }

    & th {
        padding-right: 2rem;
        width: ${({ colsCount }) => `calc(100% / ${colsCount - 1})`};

        &:first-child {
            width: 25%;
        }
    }
`
