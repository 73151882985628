import React from 'react'
import { LOCAL_HOSTNAME } from 'config'
import { ErrorMessageFallback } from 'new_components'
import { logMessage } from 'services/logger'

export class ErrorBoundaryContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(error, info) {
        console.error('Failed to render: ', error)
        const errorMessage = this.generateErrorMessage(error, info)

        if (window.location.hostname !== LOCAL_HOSTNAME) {
            logMessage({ message: errorMessage })
        }
    }

    generateErrorMessage(error) {
        const userInfo = localStorage.getItem('userInfo')
        const datePicker = sessionStorage.getItem('datepicker')

        const errorMessage = `Error Message: ${error?.message}
            \nError Stack: ${error?.stack}
            \nUser: ${userInfo}
            \nDate Picker: ${datePicker}
            \nPage: ${window.location.href}
        `

        return errorMessage
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback || <ErrorMessageFallback />
        }

        return this.props.children
    }
}
