import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { extractErrMsg } from '../../shared/utils/utils'
import { metricsService } from '../metrics/metricService'
import { SEGMENTS } from '../metrics/metricsSlice'

export const getHomepageMetrics = createAsyncThunk(
    'getHomepageMetrics',
    async (props, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.userInfo.token
            return metricsService.getMetricsBySegment({
                startDate: props?.startDate,
                endDate: props?.endDate,
                userToken: token,
                segment: SEGMENTS.ALTERNATIVE_HOME_METRICS,
            })
        } catch (e) {
            return thunkAPI.rejectWithValue(extractErrMsg(e))
        }
    }
)

const initialState = {
    homepageMetrics: [1, 2, 3, 4, 5],
    isLoading: true,
    isRejected: false,
}

export const homePageMetricsSection = createSlice({
    name: 'homepageMetricsSection',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHomepageMetrics.pending, (state) => {
                state.isLoading = true
                state.isRejected = false
            })
            .addCase(getHomepageMetrics.fulfilled, (state, action) => {
                state.homepageMetrics = action.payload
                state.isLoading = false
            })
            .addCase(getHomepageMetrics.rejected, (state) => {
                state.isLoading = false
                state.isRejected = true
            })
    },
})

export const homePageMetricsActions = homePageMetricsSection.actions
export const homePageMetricsState = (state) => state.homePageMetricsSection
export const homePageMetricsSliceReducer = homePageMetricsSection.reducer
