import React from 'react'
import { getIconColor } from './utils'

export function TrendArrowDown({ active, customColor, customActiveColor }) {
    return (
        <svg
            width="1.6rem"
            height="1.6rem"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 9.00757L8 11.9999L12 9.00757"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 5L8 7.99236L12 5"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
