import { InfoIcon } from '@octup/core/assets/icons'
import type { MetricsSectionPropsType } from '@octup/core/models'
import { Grid, SvgIcon, Tooltip, OverflowTypography } from '@octup/core/ui-kit'
import { MetricSources } from '@/components/MetricSources/MetricSources'
import { LoadableContent } from '@/new_components/__common__/LoadableContent'

export type MetricsSectionTitlePropsType = Pick<
    MetricsSectionPropsType,
    'name' | 'description' | 'isLoading'
> & {
    sources?: string[]
}

export const MetricsSectionTitle = ({
    name,
    description,
    sources,
    isLoading,
}: MetricsSectionTitlePropsType) => (
    <LoadableContent isLoading={isLoading} height="2.5rem" padding={0}>
        <Grid item xs container wrap="nowrap" gap={1}>
            <OverflowTypography>{name}</OverflowTypography>
            {description && (
                <Grid item>
                    <Tooltip title={description} placement="top" className="show-on-hover">
                        <SvgIcon component={InfoIcon} />
                    </Tooltip>
                </Grid>
            )}
            <Grid item container xs justifyContent="flex-end">
                <MetricSources sources={sources} maxIcons={2} />
            </Grid>
        </Grid>
    </LoadableContent>
)
