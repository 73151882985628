import { DATA_COLLECTION_YEARS } from '@/constants/integrations'
import type { IntegrationNameType } from '@/models/integrations'
import { IntegrationStatus } from '@/new_components/__common__/Integrations'
import type { IntegrationStatusPropsType } from '@/new_components/__common__/Integrations'
import { useAppSelector } from '@/state/hooks'

const DESCRIPTION = `Data collection started ${DATA_COLLECTION_YEARS} years ago since integration`

type IntegrationStatusContainerPropsType = Omit<
    IntegrationStatusPropsType,
    'value' | 'description'
> & {
    integrationName: IntegrationNameType
}

export const IntegrationStatusContainer = ({
    integrationName,
    ...props
}: IntegrationStatusContainerPropsType) => {
    const { collectionDataPercentage } = useAppSelector((state) => state.integrations.status)
    const value = collectionDataPercentage?.integrations[integrationName]

    return <IntegrationStatus value={value} description={DESCRIPTION} {...props} />
}
