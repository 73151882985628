import React from 'react'
import { getIconColor } from './utils'

export function OutsourceSvg({ active, customActiveColor, customColor, scale = 1 }) {
    const scaleTmp = `scale(${scale})`
    return (
        <svg
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform={scaleTmp}
        >
            <path
                d="M10 5H5.63636C5.28491 5 5 5.28491 5 5.63636L5 18.3636C5 18.7151 5.28491 19 5.63636 19H18.3636C18.7151 19 19 18.7151 19 18.3636V14"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M14 5H19V10"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M12.7727 11.2273L18.2273 5.77274"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    )
}
