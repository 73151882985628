import { useOctupDispatch } from '../../../../hooks'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { WhatsNewNavItem } from '../../../../new_components'

export const WhatsNewNavItemContainer = ({ folder, version, index, slide }) => {
    const { activeItem } = useSelector((state) => state.whatsNew)
    const octupDispatch = useOctupDispatch()
    const className = useMemo(
        () =>
            `${
                index === activeItem.slide &&
                version === activeItem.version &&
                folder === activeItem.folder
                    ? 'active'
                    : ''
            }`,
        [activeItem, folder, version, index]
    )
    const handleNavItemClick = (index) => {
        octupDispatch.whatsNew.setActiveItem({
            folder,
            slide: index,
            version,
        })
    }
    return (
        <WhatsNewNavItem
            key={folder + slide + index}
            onClick={() => handleNavItemClick(index)}
            className={className}
            content={slide.replaceAll('_', ' ').replaceAll('-', ' ').replaceAll('.md', '')}
        />
    )
}
