export function KnowledgeIconSvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M12.125 8C12.125 7.33696 12.3884 6.70107 12.8572 6.23223C13.3261 5.76339 13.962 5.5 14.625 5.5H19.625C19.7908 5.5 19.9497 5.56585 20.0669 5.68306C20.1842 5.80027 20.25 5.95924 20.25 6.125V15.625C20.25 15.7908 20.1842 15.9497 20.0669 16.0669C19.9497 16.1842 19.7908 16.25 19.625 16.25H14.625C13.962 16.25 13.3261 16.5134 12.8572 16.9822C12.3884 17.4511 12.125 18.087 12.125 18.75"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 15.625C4 15.7908 4.06585 15.9497 4.18306 16.0669C4.30027 16.1842 4.45924 16.25 4.625 16.25H9.625C10.288 16.25 10.9239 16.5134 11.3928 16.9822C11.8616 17.4511 12.125 18.087 12.125 18.75V8C12.125 7.33696 11.8616 6.70107 11.3928 6.23223C10.9239 5.76339 10.288 5.5 9.625 5.5H4.625C4.45924 5.5 4.30027 5.56585 4.18306 5.68306C4.06585 5.80027 4 5.95924 4 6.125V15.625Z"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 9.5H9.5"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 9.5H17.5"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 12.5H9.5"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 12.5H17.5"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
