import { useEffect } from 'react'
import { useQueryString } from 'hooks'
import { LogisticsPage } from 'new_pages'
import { fetchLogistics } from 'state/features/logistics'
import { useAppSelector, useAppDispatch } from 'state/hooks'

export const LogisticsPageContainer = () => {
    const dispatch = useAppDispatch()
    const { queryParams } = useQueryString()
    const { userInfo } = useAppSelector((state) => state.auth)
    const { datePickerValues } = useAppSelector((state) => state.datePicker)
    const { logistics, warehouses, warehouseCountries } = useAppSelector((state) => state)
    const isLoading = warehouses.isLoading || logistics.isLoading || warehouseCountries.isLoading
    const error = warehouses.error || logistics.error
    const hasWarehouses = !!userInfo?.client?.hasInvoices

    useEffect(() => {
        dispatch(
            fetchLogistics({
                startDate: datePickerValues.startDate,
                endDate: datePickerValues.endDate,
                ...queryParams,
            })
        )
    }, [dispatch, datePickerValues.startDate, datePickerValues.endDate, queryParams])

    return (
        <LogisticsPage
            error={error}
            isLoading={isLoading}
            hasLogistics={!!logistics.data}
            hasWarehouses={hasWarehouses}
            warehouses={warehouses.data}
        />
    )
}
