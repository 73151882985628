export const getImportanceData = (insights) => ({
    data: insights,
    queryField: 'importance',
    includeZero: false,
    requestedLabels: [
        {
            field: 'high',
            title: 'High',
            color: 'rgba(219, 32, 89, 1)',
            textColor: 'inherit',
        },
        {
            field: 'med',
            title: 'Medium',
            color: 'rgba(196, 130, 31, 1)',
            textColor: 'inherit',
        },
        {
            field: 'low',
            title: 'Low',
            color: 'rgba(7, 131, 68, 1)',
            textColor: 'inherit',
        },
    ],
})

export const getStatusData = (insights) => ({
    data: insights,
    queryField: 'vstatus',
    includeZero: false,
    requestedLabels: [
        {
            field: 'new',
            title: 'New',
            color: '#504B5A',
            textColor: 'inherit',
        },
        {
            field: 'read',
            title: 'Read',
            color: 'rgba(0, 152, 212, 1)',
            textColor: 'inherit',
        },
        {
            field: 'at work',
            title: 'At work',
            color: '#7737FF',
            textColor: 'inherit',
        },
        {
            field: 'resolved',
            title: 'Resolved',
            color: '#078344',
            textColor: 'inherit',
        },
        {
            field: 'dismissed',
            title: 'Dismissed',
            color: '#A4A0AA',
            textColor: 'inherit',
        },
    ],
})
