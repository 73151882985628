import React from 'react'

export function GreenCheckmarkSvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 12.5L10 16.5L18 8.5"
                stroke="#00A8A8"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
