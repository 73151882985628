import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { updateCost } from 'services/unitEconomics'

const UPDATED_COST_INITIAL_STATE = {
    data: null,
    isLoading: false,
    error: null,
}

export const fetchUpdateCost = createAsyncThunk('fetchUpdateCost', async (data, thunkAPI) =>
    updateCost(data, thunkAPI)
)

export const updatedCostSlice = createSlice({
    name: 'updatedCost',
    initialState: UPDATED_COST_INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(fetchUpdateCost.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchUpdateCost.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchUpdateCost.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const updatedCostReducer = updatedCostSlice.reducer
