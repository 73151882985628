// TODO: re-write the AccountPage component

import React, { Fragment, useState, useMemo } from 'react'
import { Button, Card, CardContent, Stack, Typography } from '@octup/core/ui-kit'
import { useAbilities } from 'hooks'
import { APP_FEATURES } from 'models/appConfig'
import { EmailSettingsFormContainer } from 'new_containers/components/Account'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { MobileUserAvatar } from '../../components/MobileUserAvatar/MobileUserAvatar'
import PageLayout from '../../components/PageLayout/PageLayout'
import { PaymentFlow } from '../../components/Payment/PaymentFlow'
import { updateUserProfile } from '../../features/auth/authSlice'
import { EditIconSvg } from '../../shared/assets/icons'
import { Row } from '../../shared/styledComponents'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { ButtonIcon } from '../../shared/ui/Buttons/ButtonIcon'
import { CardBase } from '../../shared/ui/Card/CardBase'
import { isEmailValid, isNameValid } from '../../shared/ui/Inputs/inputUtils'
import { OctupInputField } from '../../shared/ui/Inputs/OctupInputField'
import { OctupBaseModal } from '../../shared/ui/Modal/OctupBaseModal'
import { OctupBaseTable } from '../../shared/ui/Tables/OctupBaseTable'
import { getInputDate } from '../../shared/utils/dates-util'

export const AccountPage = () => {
    const dispatch = useDispatch()
    const { isFeatureAvailable } = useAbilities()
    const { userInfo } = useSelector((state) => state.auth)
    const { settings } = useSelector((state) => state.appConfig)
    const [personalIsEdit, setPersonalIsEdit] = useState(false)
    const [name, setName] = useState(userInfo?.fullName ? userInfo?.fullName : '')
    const [nameEditMode, setNameEditMode] = useState(userInfo?.fullName ? userInfo?.fullName : '')
    const [nameRelatedError, setNameRelatedError] = React.useState('')
    const [email, setEmail] = useState(userInfo?.email ? userInfo?.email : '')
    const [emailEditMode, setEmailEditMode] = useState(userInfo?.email ? userInfo?.email : '')
    const [emailRelatedError, setEmailRelatedError] = React.useState('')
    const [phone, setPhone] = useState(userInfo?.phone_number ? userInfo?.phone_number : '')
    const [phoneEditMode, setPhoneEditMode] = useState('')
    const [store] = useState(userInfo?.client?.name ? userInfo?.client?.name : '')
    const [, setStoreEditMode] = useState('')

    // @TODO:Refactor this page + Extract Payment
    const [paymentModal, setPaymentModal] = useState(false)
    // const [paymentModal, setPaymentModal] = useState(showPaymentModal)
    const [paymentCurrentStep, setPaymentCurrentStep] = useState(1)

    const isEmailSettingsAvailable = useMemo(
        () => isFeatureAvailable(APP_FEATURES.EMAIL_SETTINGS),
        [isFeatureAvailable]
    )

    const handleSaveChanges = async () => {
        setPersonalIsEdit(false)
        if (name !== nameEditMode || email !== emailEditMode || phone !== phoneEditMode) {
            if (isNameValid(nameEditMode)) {
                setName(nameEditMode)
            } else if (nameEditMode?.length === 0) {
                setNameRelatedError('This is Required')
            } else {
                setNameRelatedError('Invalid Name')
            }
            if (isEmailValid(emailEditMode)) {
                setEmail(emailEditMode)
            } else if (emailEditMode?.length === 0) {
                setEmailRelatedError('This is Required')
            } else {
                setEmailRelatedError('Invalid Email')
            }
            setPhone(phoneEditMode)
            if (emailRelatedError === '' && nameRelatedError === '') {
                await dispatch(
                    updateUserProfile({
                        fullName: nameEditMode,
                        email: emailEditMode,
                        phone_number: phoneEditMode,
                        token: userInfo.token,
                    })
                )
            }
        }
    }

    const accountRows = {
        personalRows: [
            {
                title: 'Full Name',
                disabled: !personalIsEdit,
                hideLabel: true,
                type: 'account',
                placeholder: 'Name',
                width: '100%',
                value: personalIsEdit ? nameEditMode : name,
                onChange: setNameEditMode,
                readOnly: !personalIsEdit,
                relatedErrorMessage: nameRelatedError,
                setRelatedErrorMessage: setNameRelatedError,
            },
            {
                title: 'Email',
                disabled: !personalIsEdit,
                hideLabel: true,
                type: 'account',
                width: '100%',
                placeholder: 'Email',
                value: personalIsEdit ? emailEditMode : email,
                onChange: setEmailEditMode,
                readOnly: !personalIsEdit,
                relatedErrorMessage: emailRelatedError,
                setRelatedErrorMessage: setEmailRelatedError,
            },
            {
                title: 'Phone Number',
                disabled: !personalIsEdit,
                hideLabel: true,
                type: 'account',
                width: '100%',
                placeholder: 'Phone',
                value: personalIsEdit ? phoneEditMode : phone,
                onChange: setPhoneEditMode,
                readOnly: !personalIsEdit,
            },
        ],
        storeRows: [
            {
                title: 'My Store Name',
                disabled: true,
                hideLabel: true,
                type: 'account',
                width: '100%',
                placeholder: 'store name',
                value: store,
                readOnly: !personalIsEdit,
                onChange: (e) => setStoreEditMode(e.target.value),
            },
        ],
    }

    const renderInputRow = ({
        title,
        disabled,
        hideLabel,
        type,
        placeholder,
        value,
        onChange,
        readOnly,
        relatedErrorMessage,
        setRelatedErrorMessage,
    }) => {
        return (
            <Fragment key={title}>
                <StyledRow>
                    <ReadOnlyLabel>{title}</ReadOnlyLabel>
                    <OctupInputField
                        disabled={disabled}
                        hideLabel={hideLabel}
                        type={type}
                        placeholder={placeholder}
                        width={'100%'}
                        value={value}
                        onChange={(e) => {
                            onChange(e.target.value)
                            setRelatedErrorMessage && setRelatedErrorMessage('')
                        }}
                        readOnly={readOnly}
                    />
                </StyledRow>
                {relatedErrorMessage && (
                    <ErrorContainer>
                        <ErrorDiv>
                            <ErrorMessage>{relatedErrorMessage}</ErrorMessage>
                        </ErrorDiv>
                    </ErrorContainer>
                )}
            </Fragment>
        )
    }

    return (
        <PageLayout showDatePicker={false}>
            <InnerPageContainer>
                <Div>
                    <CardBase style={{ height: '100%', padding: '2rem' }}>
                        <TitleContainer>
                            <CardTitle>
                                <Typography variant="h6">Personal Details</Typography>
                                <EditButtonContainer>
                                    {!personalIsEdit ? (
                                        <ButtonIcon
                                            onClick={() => setPersonalIsEdit(true)}
                                            mainIcon={EditIconSvg}
                                        />
                                    ) : (
                                        <ButtonsContainer>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                onClick={() => {
                                                    personalIsEdit && handleSaveChanges()
                                                }}
                                            >
                                                save
                                            </Button>
                                            <Button
                                                size="small"
                                                variant="secondary"
                                                onClick={() => {
                                                    setPersonalIsEdit(false)
                                                }}
                                            >
                                                cancel
                                            </Button>
                                        </ButtonsContainer>
                                    )}
                                </EditButtonContainer>
                            </CardTitle>
                        </TitleContainer>
                        <PersonalDetailsContainer>
                            <PresonalDetailsContainer>
                                {accountRows.personalRows.map((personalRow) => {
                                    return renderInputRow(personalRow)
                                })}
                            </PresonalDetailsContainer>
                            <AvatarContainer>
                                <MobileUserAvatar
                                    name={userInfo?.fullName || ''}
                                    isFancy={false}
                                    imgSrc={userInfo?.imageSrc}
                                />
                            </AvatarContainer>
                        </PersonalDetailsContainer>
                    </CardBase>
                </Div>
                <Div>
                    <CardBase style={{ height: '100%', padding: '2rem' }}>
                        <TitleContainer>
                            <Typography variant="h6">My Stores</Typography>
                        </TitleContainer>
                        <StoreDetailsContainer>
                            <TableContainer>
                                <OctupBaseTable
                                    hiddenRowKey={'storeName'}
                                    showSelectedRow={false}
                                    showSearchFilter={false}
                                    showFilters={false}
                                    showCounter={false}
                                    showDotMenu={false}
                                    tableData={{
                                        headers: ['Store Name', 'Store URL', 'Connected on'],
                                        rows: [
                                            {
                                                id: uuidv4(),
                                                storeName: userInfo?.client?.name,
                                                storeUrl: `https://${userInfo?.client?.name}.myshopify.com`,
                                                createdAt: userInfo?.client?.created_at,
                                            },
                                        ],
                                    }}
                                    sortingHeadersToDataMapping={{
                                        storeName: 'Store Name',
                                        storeUrl: 'Store URL',
                                        createdAt: 'Connected on',
                                    }}
                                    renderRows={(e) => {
                                        return (
                                            <>
                                                <StyleTd>{e.storeName}</StyleTd>
                                                <StyleTd>
                                                    <StyledLink href={e.storeUrl}>
                                                        {e.storeUrl}
                                                    </StyledLink>
                                                </StyleTd>
                                                <StyleTd>{getInputDate(e.createdAt)}</StyleTd>
                                            </>
                                        )
                                    }}
                                ></OctupBaseTable>
                            </TableContainer>
                        </StoreDetailsContainer>
                    </CardBase>
                </Div>

                {isEmailSettingsAvailable && (
                    <Card sx={{ width: '100%' }}>
                        <CardContent>
                            <Stack spacing={4}>
                                <Typography variant="h6">Email Settings</Typography>
                                <EmailSettingsFormContainer />
                            </Stack>
                        </CardContent>
                    </Card>
                )}

                {settings.data.isDefault && (
                    <Div style={{ height: '30rem' }}>
                        <CardBase style={{ height: '100%', padding: '2rem' }}>
                            <Typography variant="h6">Billing & Plans</Typography>
                            <PlanDetailsContainer>
                                <PlanDetailsItemContainer>
                                    <ReadOnlyLabel>Current Plan</ReadOnlyLabel>
                                    <PlanInfo>{userInfo?.client?.plan}</PlanInfo>
                                </PlanDetailsItemContainer>
                                <PlanDetailsItemContainer>
                                    <ReadOnlyLabel>Subscription Ends</ReadOnlyLabel>
                                    <PlanInfo>
                                        {getInputDate(
                                            new Date(userInfo?.client?.expiredPaymentDate)
                                        )}
                                    </PlanInfo>
                                </PlanDetailsItemContainer>
                                <BillingBtnContainer>
                                    <Button
                                        variant="secondary"
                                        onClick={() => setPaymentModal(true)}
                                    >
                                        Update Plan
                                    </Button>
                                </BillingBtnContainer>
                            </PlanDetailsContainer>
                        </CardBase>
                    </Div>
                )}
            </InnerPageContainer>
            {paymentModal && (
                <OctupBaseModal
                    showCloseBtn={paymentCurrentStep > 0}
                    onDismissModal={() => {
                        setPaymentModal(false)
                    }}
                >
                    <PaymentModalContainer>
                        <PaymentFlow
                            currentStep={paymentCurrentStep}
                            setCurrentStep={setPaymentCurrentStep}
                            closeModal={() => {
                                setPaymentModal(false)
                            }}
                        />
                    </PaymentModalContainer>
                </OctupBaseModal>
            )}
        </PageLayout>
    )
}

export default AccountPage

const Div = styled.div`
    width: 100%;
`

const DetailsContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: row;
`

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const CardTitle = styled.div`
    font-size: 1.8rem;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    height: 100%;
    width: fit-content;
    gap: 1rem;
`

const RowsContainer = styled.div`
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const ReadOnlyLabel = styled.div`
    display: flex;
    align-items: center;
    color: ${OctupColors.inputLabels};
    font-size: 14px;
    width: 30rem;
    height: 6rem;
`

const PersonalDetailsContainer = styled(DetailsContainer)`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
`

const StoreDetailsContainer = styled(DetailsContainer)``

const AvatarContainer = styled.div`
    min-width: 50rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transform: translateY(-30%);
`

const EditButtonContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    gap: 1rem;
`

const StyledRow = styled(Row)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const TableContainer = styled.div`
    width: 100%;
`

const StyleTd = styled.td`
    text-transform: none !important;
    font-size: 12px !important;
`

const StyledLink = styled.a`
    color: ${OctupColors.primaryActionableLight};
    font-size: 12px;
`

const ErrorContainer = styled.div`
    position: relative;
    width: 100%;
`

const ErrorDiv = styled.div`
    position: absolute;
    width: 30rem;
    bottom: 0.5rem;
    font-weight: 400;
`

export const ErrorMessage = styled.div`
    color: #db2059;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 0;
    text-align: left;
    position: absolute;
`
const PaymentModalContainer = styled.div`
    max-height: 78vh;
`

const PresonalDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
`

const InnerPageContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
`

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
`

const BillingBtnContainer = styled.div`
    margin-bottom: 2rem;
`

const PlanDetailsContainer = styled(RowsContainer)`
    gap: 1rem;
`

const PlanInfo = styled.div`
    font-weight: 400;
    font-size: 2rem;
    line-height: 6rem;
    text-transform: capitalize;
    color: #504b5a;
`

const PlanDetailsItemContainer = styled.div`
    display: flex;
`
