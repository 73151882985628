import React from 'react'

export function DotsIconSvg({ active, customActiveColor = '#007F82', customColor = '#007F82' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M11.6 19.9099C12.4837 19.9099 13.2 19.1936 13.2 18.3099C13.2 17.4263 12.4837 16.7099 11.6 16.7099C10.7163 16.7099 10 17.4263 10 18.3099C10 19.1936 10.7163 19.9099 11.6 19.9099Z"
                fill="#504B5A"
            />
            <path
                d="M11.6 13.9099C12.4837 13.9099 13.2 13.1936 13.2 12.3099C13.2 11.4263 12.4837 10.7099 11.6 10.7099C10.7163 10.7099 10 11.4263 10 12.3099C10 13.1936 10.7163 13.9099 11.6 13.9099Z"
                fill="#504B5A"
            />
            <path
                d="M11.6 7.91353C12.4837 7.91353 13.2 7.19719 13.2 6.31353C13.2 5.42988 12.4837 4.71353 11.6 4.71353C10.7163 4.71353 10 5.42988 10 6.31353C10 7.19719 10.7163 7.91353 11.6 7.91353Z"
                fill="#504B5A"
            />
        </svg>
    )
}
