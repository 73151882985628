import { OctupColors } from '../../theme/colors/OctupColors'

export function ActionsBtnOnlineStoreIcon({
    active,
    customActiveColor = '#504B5A',
    customColor = '#504B5A',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2rem"
            height="1.7rem"
            viewBox="0 0 20 17"
            fill="none"
        >
            <path
                d="M2.5 9.08643V15.5C2.5 15.6989 2.57902 15.8897 2.71967 16.0304C2.86032 16.171 3.05109 16.25 3.25 16.25H16.75C16.9489 16.25 17.1397 16.171 17.2803 16.0304C17.421 15.8897 17.5 15.6989 17.5 15.5V9.08657"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.06573 1H16.9343C17.0973 1 17.2558 1.03748 17.3859 1.10676C17.516 1.17604 17.6106 1.27335 17.6554 1.38397L19 4.5H1L2.34458 1.38397C2.38936 1.27335 2.48396 1.17604 2.61408 1.10676C2.7442 1.03748 2.90274 1 3.06573 1Z"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 5V6.33333C7 7.04058 6.68393 7.71885 6.12132 8.21895C5.55871 8.71905 4.79565 9 4 9C3.20435 9 2.44129 8.71905 1.87868 8.21895C1.31607 7.71885 1 7.04058 1 6.33333V5"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13 5V6.33333C13 7.04058 12.6839 7.71885 12.1213 8.21895C11.5587 8.71905 10.7956 9 10 9C9.20435 9 8.44129 8.71905 7.87868 8.21895C7.31607 7.71885 7 7.04058 7 6.33333V5"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 5V6.33333C19 7.04058 18.6839 7.71885 18.1213 8.21895C17.5587 8.71905 16.7956 9 16 9C15.2044 9 14.4413 8.71905 13.8787 8.21895C13.3161 7.71885 13 7.04058 13 6.33333V5"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
