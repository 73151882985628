import styled from 'styled-components'

export const NavContentContainer = styled.div`
    max-height: 640px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 0;
`
export const WhatNewNavContainerStyled = styled.div`
    min-height: 100%;
    width: 100%;
    flex: 1.2;
    display: flex;
    background: white;
    border-radius: 1.6rem;
    flex-direction: column;
    gap: 2rem;
`

export const WhatNewNavInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    color: white;
`
