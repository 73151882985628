import axios from 'axios'
import { GLOBAL_CONFIG } from '../../config'

export const sendFeedback = async (feedback, token) => {
    const response = await axios.post(
        `${GLOBAL_CONFIG.BASE_URL}user/feedback`,
        {
            feedback,
        },
        {
            headers: {
                Authorization: `${token}`,
            },
        }
    )
    return response.data
}
