export function BreezeSvg() {
    return (
        <svg
            width="33"
            height="29"
            viewBox="0 0 33 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.6937 1.46425C20.263 1.96345 16.6278 3.81067 13.4161 8.40437C12.2823 8.22114 9.06076 8.18753 6.49623 8.50352C1.96965 9.06126 4.83201 11.3996 8.89043 12.2988C12.9488 13.1981 8.29667 13.6637 5.26496 18.7734C2.23325 23.8831 6.10198 19.639 11.3478 17.8086C16.5937 15.9782 14.2092 16.8102 17.2603 20.9552C20.3115 25.1001 20.9684 25.4497 20.4818 20.9888C19.9952 16.5279 19.1583 15.6622 20.7058 13.9646C22.2532 12.2669 25.8348 13.3326 28.156 10.7862C30.4772 8.23973 25.825 8.7053 22.3067 9.35409C18.7884 10.0029 18.4916 10.6853 20.0926 6.07476C21.6937 1.46424 23.6012 0.798648 21.6937 1.46425Z"
                fill="#F8F7FB"
                stroke="#504B5A"
                strokeWidth="1.5"
            />
            <path
                opacity="0.3"
                d="M22.6445 2.29965C21.5716 2.67401 18.8615 4.10494 16.5485 7.82386C15.6823 7.64079 13.2346 7.52425 11.2954 7.71555C7.87274 8.0532 10.1111 10.07 13.2184 10.9271C16.3256 11.7841 12.805 12.0419 10.643 16.1935C8.48096 20.3451 11.3025 16.934 15.2365 15.5613C19.1705 14.1887 17.3823 14.8126 19.8138 18.332C22.2453 21.8514 22.7539 22.1592 22.2616 18.4485C21.7692 14.7379 21.1097 13.9974 22.2383 12.6329C23.3669 11.2685 26.1165 12.2503 27.8094 10.2037C29.5024 8.15707 25.9817 8.41488 23.3273 8.85575C20.6729 9.29662 20.4663 9.85403 21.5554 6.07684C22.6445 2.29965 24.075 1.8005 22.6445 2.29965Z"
                fill="#FF3489"
            />
        </svg>
    )
}
