import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { getProducts } from '@/services'
import { getProductsData, getSelectedProduct } from './utils'

const PRODUCTS_INITIAL_STATE = {
    data: null,
    isLoading: false,
    error: null,
    query: null,
    selectedProduct: null,
}

export const fetchProducts = createAsyncThunk('fetchProducts', async (data, thunkAPI) =>
    getProducts(data, thunkAPI)
)

export const productsSlice = createSlice({
    name: 'productsSlice',
    initialState: PRODUCTS_INITIAL_STATE,
    reducers: {
        setQuery: (state, action) => {
            state.query = action.payload
        },
        removeProducts: (state) => {
            state.data = null
        },
        selectProduct: (state, action) => {
            const product = action.payload
            state.selectedProduct = product ? getSelectedProduct(product) : product
        },
        addNewCost(state) {
            const newCost = { id: uuidv4(), isNew: true }
            state.selectedProduct.costs = [newCost, ...state.selectedProduct.costs]
        },
        editCost(state, action) {
            const costId = action.payload
            state.selectedProduct.costs = state.selectedProduct.costs.map((cost) =>
                cost.id === costId ? { ...cost, isEdit: true } : cost
            )
        },
        deleteCost(state, action) {
            const costId = action.payload
            state.selectedProduct.costs = state.selectedProduct.costs.filter(
                (cost) => cost.id !== costId
            )
        },
        cancelEditCost(state, action) {
            const costId = action.payload
            state.selectedProduct.costs = state.selectedProduct.costs.map((cost) =>
                cost.id === costId ? { ...cost, isEdit: false } : cost
            )
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                if (!state.data) {
                    state.isLoading = true
                }
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.data = getProductsData(action.payload)

                if (state.selectedProduct) {
                    const newProducts = state.data.products
                    const newSelectedProduct = newProducts[state.selectedProduct.product_id]
                    const newSelectedVariant =
                        newSelectedProduct.variants[state.selectedProduct.variant_id]
                    const newSelected = newSelectedVariant || newSelectedProduct
                    state.selectedProduct = getSelectedProduct(newSelected)
                }

                state.isLoading = false
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const {
    setQuery,
    removeProducts,
    selectProduct,
    addNewCost,
    editCost,
    deleteCost,
    cancelEditCost,
} = productsSlice.actions
export const productsReducer = productsSlice.reducer
