import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { EmailSettingsType } from 'models/settings'
import { getEmailSettings } from 'services/settings'
import type { ThunkAPIConfigType } from 'services/utils'

type EmailSettingsStateType = {
    data?: EmailSettingsType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: EmailSettingsStateType = {
    data: undefined,
    isLoading: false,
    error: undefined,
}

export const fetchEmailSettings = createAsyncThunk<
    EmailSettingsType,
    undefined,
    ThunkAPIConfigType
>('fetchEmailSettings', (_, thunkAPI) => getEmailSettings(thunkAPI))

export const emailSettingsSlice = createSlice({
    name: 'emailSettings',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEmailSettings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchEmailSettings.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchEmailSettings.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const emailSettingsReducer = emailSettingsSlice.reducer
