import React from 'react'
import { getIconColor } from './utils'

export function ClearFieldsSvg({ active, customActiveColor, customColor = '#F8F7FB' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.6rem"
            height="1.6rem"
            viewBox="0 0 16 16"
            fill="none"
        >
            s
            <circle cx="8" cy="8" r="8" fill="#A4A0AA" />
            <path
                d="M5 11L11 5.00001"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5 5L11 11"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
