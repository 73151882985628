import { Stack, SvgIcon, Typography } from '@mui/material'
import { InfoIcon } from '@octup/core/assets/icons'
import { NoDataErrorTooltipInfo } from 'components/NoDataError/NoDataError'
import { OctupTooltip } from 'shared/ui/MUIWrappers/OctupTooltip'

export const NoDataTooltip = () => (
    <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body2" color="error">
            No data
        </Typography>
        <OctupTooltip
            position={'top'}
            title={<NoDataErrorTooltipInfo />}
            disableInteractive={false}
        >
            <SvgIcon component={InfoIcon} color="text.primary" fontSize="small" />
        </OctupTooltip>
    </Stack>
)
