import { OctupInputField } from '../../shared/ui/Inputs/OctupInputField'
import styled from 'styled-components'
import { useState } from 'react'
import { ErrorDiv, ErrorMessage, Row } from '../../shared/styledComponents'
import { sendInvites } from '../../features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from '../../hooks/useToastContext'
import { isEmailValid } from '../../shared/ui/Inputs/inputUtils'
import { OctupColors } from '../../shared/theme/colors/OctupColors'

export const InviteUserModal = ({ onCancel, email, setEmail, emailError, setEmailError }) => {
    const [hideLabel, setHideLabel] = useState(false)
    const dispatch = useDispatch()
    const toast = useToast()

    return (
        <MainContainer>
            <OctupInputField
                placeholder={'Enter an email address'}
                width={'40rem'}
                transformX={'-2.8rem'}
                transformY={'-0.4rem'}
                labelBIId={'Invite User Input'}
                hideLabel={hideLabel}
                value={email}
                padding={'3rem 0 3rem 2rem'}
                onChange={(e) => {
                    setEmail(e.target.value)
                    setEmailError('')
                    if (email?.length > 0) {
                        setHideLabel(true)
                    }
                }}
                onFocusCallback={() => setHideLabel(true)}
                onBlur={() => {
                    if (email?.length === 0) {
                        setHideLabel(false)
                    } else if (!isEmailValid(email)) {
                        setEmailError('Please enter a valid email address')
                    }
                }}
            />
            <ErrorContainer>
                {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
            </ErrorContainer>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    width: 67rem;
`

const ErrorContainer = styled.div`
    padding-top: 2rem;
    padding-bottom: 1rem;
`
