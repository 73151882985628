import { useMemo } from 'react'
import type { FormHelperTextProps } from '@mui/material'
import { FormHelperText as MuiFormHelperText } from '@mui/material'
import { getStyle } from './styles'
import type { FormHelperTextStyleType } from './styles'

type FormHelperTextPropsType = FormHelperTextProps &
    FormHelperTextStyleType & {
        error: boolean
        helperText: string
    }

export const FormHelperText = ({ error, helperText, isInline }: FormHelperTextPropsType) => {
    const style = useMemo(() => getStyle({ isInline }), [isInline])

    return (
        <MuiFormHelperText data-testid="helper-text" sx={style} error={error}>
            {helperText}
        </MuiFormHelperText>
    )
}
