import axios from 'axios'

export const checkLinkValidity = async (link: string): Promise<boolean> => {
    try {
        const response = await axios.head(link)
        return response.status >= 200 && response.status < 400
    } catch (error) {
        console.error(`Error fetching ${link}:`, error)
        return false
    }
}
