import { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIntegrations } from 'features/integrations/integrationsSlice'
import { getIntegrationTitle } from 'new_containers/components/Integrations/utils'
import { useIntegrationToast } from './hooks'
import { MODAL_TYPES, MODAL_BY_TYPE } from './IntegrationsController.utils'

export const IntegrationsController = ({ controlComponent: ControlComponent }) => {
    const dispatch = useDispatch()
    const { error } = useSelector((state) => state.integrations.integrationsList)
    const [modal, setModal] = useState()
    const [selectedIntegration, setSelectedIntegration] = useState()
    const ModalComponent = useMemo(() => MODAL_BY_TYPE[modal?.type], [modal?.type])
    const handleCloseModal = useCallback(() => setModal(null), [])

    useEffect(() => {
        dispatch(getIntegrations())
    }, [dispatch])

    const handleDisconnect = useCallback((disconnectedIntegration) => {
        setModal({ type: MODAL_TYPES.DISCONNECT })
        setSelectedIntegration(disconnectedIntegration)
    }, [])

    const handleAccountsSelect = useCallback((integration) => {
        setModal({ type: MODAL_TYPES.SELECT_ACCOUNTS })
        setSelectedIntegration(integration)
    }, [])

    useIntegrationToast({
        onDisconnect: handleDisconnect,
        onAccountsSelect: handleAccountsSelect,
    })

    const handleConnect = useCallback((e, el) => {
        if (el.isConnected) return

        if (el.isModal || !el.href) {
            setModal({ type: MODAL_TYPES.CONNECT })
            setSelectedIntegration(el)
        } else if (el.href) {
            window.location.href = el.href
        }
    }, [])

    const integrationTitle = useMemo(
        () => getIntegrationTitle(selectedIntegration?.title || selectedIntegration),
        [selectedIntegration]
    )

    return (
        <>
            <ControlComponent
                error={error}
                onConnect={handleConnect}
                onDisconnect={handleDisconnect}
            />
            {ModalComponent && (
                <ModalComponent
                    integration={selectedIntegration}
                    integrationTitle={integrationTitle}
                    onClose={handleCloseModal}
                />
            )}
        </>
    )
}
