import { createSlice } from '@reduxjs/toolkit'

export type SelectedDashboardSection = {
    id: string
    name: string
    group: string
}

type DashboardStateType = {
    selectedDashboardSection?: SelectedDashboardSection
}

const INITIAL_STATE: DashboardStateType = {
    selectedDashboardSection: undefined,
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: INITIAL_STATE,
    reducers: {
        setSelectedDashboardSection(state, actions) {
            state.selectedDashboardSection = {
                ...state.selectedDashboardSection,
                ...actions.payload,
            }
        },
        clearDashboard() {
            return INITIAL_STATE
        },
    },
})

export const { setSelectedDashboardSection, clearDashboard } = dashboardSlice.actions
export const dashboardReducer = dashboardSlice.reducer
