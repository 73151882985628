import { useMemo } from 'react'
import { Box, SvgIcon } from '@mui/material'
import { ImageIcon, ListIcon } from '@octup/core/assets/icons'
import type { ListItemImageStyleType } from './styles'
import { getStyleBySize } from './styles'

type ListItemImagePropsType = ListItemImageStyleType & {
    src?: string
    alt?: string
    type?: 'all'
}

const ICON_BY_TYPE = {
    all: ListIcon,
}

export const ListItemImage = ({ src, alt, type, size = 'medium' }: ListItemImagePropsType) => {
    const imageContainerStyle = useMemo(() => getStyleBySize(size), [size])

    return (
        <Box sx={imageContainerStyle}>
            {src ? (
                <img src={src} alt={alt} />
            ) : (
                <SvgIcon component={type ? ICON_BY_TYPE[type] : ImageIcon} />
            )}
        </Box>
    )
}
