export const labelContainerStyle = {
    padding: '0.5rem 0',
    '&, & > *': {
        fontSize: 'inherit',
    },
}

export const warningIconStyle = {
    fontSize: '2.3rem',
}
