import { CardExpendable } from '../../../../shared/ui/Card/CardExpendable'
import { ButtonIcon } from '../../../../shared/ui/Buttons/ButtonIcon'
import { ChevronRightSvg } from '../../../../shared/assets/icons'
import { useNavigate } from 'react-router-dom'
import React, { Fragment, useEffect, useState } from 'react'
import { OctupNoDataFound } from '../../../../shared/components/loaders/OctupNoDataFound'
import { wrapElementWithStyle } from '../utils/alternative-home-utils'
import NoData from '../no-data-components/NoData'
import {
    TitleContainer,
    Title,
    Icon,
    CardContentStyled,
    ChartContainer,
} from './AlternativeHomeCardItem.styles'
import { useOctupDispatch } from '../../../../hooks/useOctupDispatch'
import { AlternativeHomeDoughnutChart } from './AlternativeHomeDoughnutChart'

const BtnStyle = {
    padding: '.93rem',
    width: '2.4rem !important',
    height: '2.4rem !important',
    minWidth: '2.4rem !important',
    minHeight: '2.4rem !important',
}

export const AlternativeHomeCardItem = ({
    title,
    subtitle,
    value,
    labels,
    mainIcon,
    metrics,
    metadata,
    isMetricsLayout = true,
    isLoading,
    isRejected,
    colorSet,
    segment,
    style,
}) => {
    const navigate = useNavigate()
    const MainIcon = mainIcon
    const octupDispatch = useOctupDispatch()

    const [safeMetrics, setSafeMetrics] = useState(metrics)
    useEffect(() => {
        setSafeMetrics(metrics)
    }, [metrics])

    return (
        <CardExpendable height={'100%'} style={{ ...style }}>
            <CardContentStyled>
                <TitleContainer
                    onClick={() => {
                        octupDispatch.sidebar.setActivePage(`${title}`)
                        navigate(`/${title}`)
                    }}
                >
                    <Icon style={{ paddingTop: '.5rem' }}>
                        <MainIcon customColor="#A4A0AA" />
                    </Icon>
                    <Title>{title}</Title>
                    <ButtonIcon mainIcon={ChevronRightSvg} customStyle={BtnStyle} />
                </TitleContainer>
                {isRejected ? (
                    <OctupNoDataFound />
                ) : isMetricsLayout ? (
                    <ChartContainer>
                        {safeMetrics?.map((datum, i) => {
                            return (
                                <Fragment key={datum?.metric?.metricTitle || datum}>
                                    {wrapElementWithStyle(
                                        datum,
                                        isLoading,
                                        isRejected,
                                        false,
                                        false,
                                        true,
                                        segment
                                    )}
                                    {i + 1 < safeMetrics.length && (
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '.01rem',
                                                borderBottom: '.2rem solid #DDDBE1',
                                            }}
                                        ></div>
                                    )}
                                </Fragment>
                            )
                        })}{' '}
                    </ChartContainer>
                ) : !isMetricsLayout && (!metadata || value === 0) && !isLoading ? (
                    <NoData
                        type={title.toLowerCase()}
                        style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                    />
                ) : (
                    !isMetricsLayout && (
                        <AlternativeHomeDoughnutChart
                            labels={labels}
                            subtitle={subtitle}
                            value={value}
                            metrics={metrics}
                            metadata={metadata}
                            isLoading={isLoading}
                            colorSet={colorSet}
                            setSafeMetrics={setSafeMetrics}
                            labelsFontSize={'1.7rem'}
                            titleFontSize={'5rem'}
                            lablesColored={false}
                            breakSubTitle={true}
                        />
                    )
                )}
            </CardContentStyled>
        </CardExpendable>
    )
}
