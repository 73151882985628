import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { fetchAttribution } from 'state/features/attribution'
import { fetchOrders } from 'state/features/orders'
import { fetchPaymentSession, fetchPaymentsPlans } from 'state/features/payments'
import { setShowNewUserModal, setStartTour } from 'state/features/user-configurations'
import { whatsNewSlideActions } from 'state/features/whatsNew'
import {
    getClientInfo,
    otpGenerate,
    signup,
    updateUserProfile,
    validateEmail,
    logout,
    sessionActions,
    introActivated,
} from '../features/auth/authSlice'
import {
    getInsights,
    insightsActions,
    updateInsightLike,
    updateInsightStatus,
    dismissInsight,
} from '../features/insights/insightSlice'
import {
    getMetricsBySegment,
    getSingleOrder,
    metricsActions,
} from '../features/metrics/metricsSlice'
import {
    getAllNotifications,
    updateNotificationsStatus,
} from '../features/notifications/notificationsSlice'
import {
    createObjective,
    updateObjective,
    objectivesActions,
    getObjectives,
} from '../features/objectives/objectiveSlice'
import { getAlternativeMarketingMetrics } from '../features/sections/alternativeMarketingSlice'
import { getAlternativeOperationsMetrics } from '../features/sections/alternativeOperationsSlice'
import { getHomepageMetrics } from '../features/sections/homePageSectionsSlice'
import {
    getFulfilledOrdersSLARange,
    setFulfilledOrdersSLARange,
    getFulfilledOrdersSLAMetric,
} from '../features/sections/slaSectionSlice'
import { deleteUser } from '../features/users/deleteUserSlice'
import { getUsers } from '../features/users/usersSlice'
import { navigationSliceActions } from '../store/slices/navigation-slice'
import { simulatorSliceActions } from '../store/slices/simulator-slice'
import { useToast } from './useToastContext'
// TODO: discuss and decide how to use toasts
// Right now:
// 1. can't use process toast in a loading state, because u can't stop it on fulfilled step (no toast id)
// 2. difficult to control with several requests(failed and succeeded statuses on different steps)
export const useOctupDispatch = () => {
    const dispatch = useDispatch()
    const toasts = useToast()

    const data = useMemo(
        () => ({
            auth: {
                logout: async () => {
                    await dispatch(logout())
                    dispatch(sessionActions.resetSession())
                },
                getClientInfo: () => {
                    dispatch(getClientInfo())
                },
                otpGenerate: () => {
                    dispatch(otpGenerate())
                },
                signup: (data) => {
                    return dispatch(signup({ data, toasts }))
                },
                updateUserProfile: () => {
                    dispatch(updateUserProfile())
                },
                validateEmail: () => {
                    dispatch(validateEmail())
                },
                invalidateSession: () => {
                    dispatch(sessionActions.invalidateSession())
                },
                introActivated: (intro) => {
                    dispatch(introActivated(intro))
                },
                paymentRequired: () => {
                    dispatch(sessionActions.paymentRequired())
                },
            },
            userConfig: {
                setStartTour: (payload) => {
                    dispatch(setStartTour(payload))
                },
                setShowNewUserModal: (payload) => {
                    dispatch(setShowNewUserModal(payload))
                },
            },
            users: {
                getUsers: () => {
                    dispatch(getUsers())
                },
                deleteUser: (userId) => {
                    dispatch(deleteUser({ userId, toasts }))
                },
            },
            datePicker: {
                changeDates: () => {},
            },
            insights: {
                getInsights: () => {
                    dispatch(getInsights())
                },
                addUsersData: (users) => {
                    dispatch(insightsActions.addUsersData(users))
                },
                setSelectedInsight: (insightId) => {
                    dispatch(insightsActions.setSelectedInsight(insightId))
                },
                updateInsightLike: (insightId, isLike) => {
                    dispatch(updateInsightLike({ data: { insightId, isLike }, toasts }))
                },
                updateInsightStatus: (insightId, status) => {
                    dispatch(updateInsightStatus({ data: { insightId, status }, toasts }))
                },
                dismissInsight: (data) => {
                    dispatch(dismissInsight({ data, toasts }))
                },
            },
            objectives: {
                getObjectives: () => {
                    dispatch(getObjectives())
                },
                addUsersData: (users) => {
                    dispatch(objectivesActions.addUsersData(users))
                },
                createObjective: (obj) => {
                    dispatch(createObjective({ data: { objective: obj }, toasts }))
                },
                updateObjective: (obj) => {
                    dispatch(updateObjective({ data: { objective: obj }, toasts }))
                },
                deleteObjective: (obj) => {
                    dispatch(updateObjective({ data: { objective: obj }, toasts }))
                }, //delete is a change of status and not actual deletion
                setSelectedObjective: (objectivId) => {
                    dispatch(objectivesActions.setSelectedObjective(objectivId))
                },
            },
            operations: {
                getSingleOrder: (itemId) => {
                    dispatch(getSingleOrder(itemId))
                },
                getFulfilledOrdersSLARange: () => {
                    dispatch(getFulfilledOrdersSLARange())
                },
                setFulfilledOrdersSLARange: (slaRangeData) => {
                    dispatch(setFulfilledOrdersSLARange(slaRangeData))
                },
                getFulfilledOrdersSLAMetric: (start_date, end_date) => {
                    dispatch(getFulfilledOrdersSLAMetric({ start_date, end_date }))
                },
            },
            metrics: {
                getMetricsBySegment: (segments, datePickerValues, dataset) => {
                    dispatch(getMetricsBySegment({ segments, datePickerValues, dataset }))
                },
                getHomepageMetrics: (startDate, endDate) => {
                    dispatch(getHomepageMetrics({ startDate, endDate }))
                },
                getAlternativeOperationsMetrics: (startDate, endDate) => {
                    dispatch(getAlternativeOperationsMetrics({ startDate, endDate }))
                },
                getAlternativeMarketingMetrics: (startDate, endDate) => {
                    dispatch(getAlternativeMarketingMetrics({ startDate, endDate }))
                },
                setIsExpended(isExpended) {
                    dispatch(metricsActions.setIsExpended(isExpended))
                },
            },
            notifications: {
                updateNotificationsStatus: (notificationsIdsArray) => {
                    dispatch(updateNotificationsStatus(notificationsIdsArray))
                },
                getAllNotifications: () => {
                    dispatch(getAllNotifications())
                },
            },
            sidebar: {
                setActivePage: (activePage) => {
                    dispatch(navigationSliceActions.setActivePage({ activePage }))
                },
                setIsMainMenuCollapsed: (val) => {
                    dispatch(navigationSliceActions.setIsMainMenuCollapsed({ val }))
                },
                setActiveSubMenu: (activeSubMenu) => {
                    dispatch(navigationSliceActions.setActiveSubMenu({ activeSubMenu }))
                },
                setActiveSubMenuItem: (activeSubMenuItem) => {
                    dispatch(navigationSliceActions.setActiveSubMenuItem({ activeSubMenuItem }))
                },
                setIsMainMenuCollapsedBtnActive: () => {
                    dispatch(navigationSliceActions.setIsMainMenuCollapsedBtnActive())
                },
            },
            simulators: {
                setActiveSimulator: (activeSimulator) => {
                    dispatch(simulatorSliceActions.setActiveSimulator(activeSimulator))
                },
            },
            attribution: {
                getAttribution: (data) => {
                    dispatch(fetchAttribution(data))
                },
            },
            orders: {
                getOrders: (data) => {
                    dispatch(fetchOrders(data))
                },
            },
            payments: {
                getSuggestedPlans: () => dispatch(fetchPaymentsPlans()),
                createPaymentSession: (data) => dispatch(fetchPaymentSession(data)),
            },
            whatsNew: {
                setShowModal: (val) => dispatch(whatsNewSlideActions.setShow(val)),
                setActiveItem: (data) => dispatch(whatsNewSlideActions.setActiveItem(data)),
                resetActiveItem: () => dispatch(whatsNewSlideActions.resetActiveItem()),
            },
        }),
        [dispatch, toasts]
    )

    return data
}
