import type { TimeFieldPropsType } from '@octup/core/ui-kit'
import { TimeField, InputAdornment } from '@octup/core/ui-kit'
import { FormFieldContainer } from '@/new_containers/components/__common__'
import { inputStyle } from './styles'

export type WorkingTimeFieldPropsType = TimeFieldPropsType<Date> & {
    name: string
    label: string
}

export const WorkingTimeField = ({ label, ...props }: WorkingTimeFieldPropsType) => (
    <FormFieldContainer
        as={TimeField}
        size="small"
        fullWidth
        hasInlineError
        sx={inputStyle}
        InputProps={{
            startAdornment: <InputAdornment position="start">{label}</InputAdornment>,
        }}
        {...props}
    />
)
