import React from 'react'
import { OctupColors } from '../../theme/colors/OctupColors'

export function NoImageSvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            width="3.2rem"
            height="3.2rem"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.4848 6.66669H7.51514C7.04654 6.66669 6.66666 7.04657 6.66666 7.51517V24.4849C6.66666 24.9535 7.04654 25.3334 7.51514 25.3334H24.4848C24.9534 25.3334 25.3333 24.9535 25.3333 24.4849V7.51517C25.3333 7.04657 24.9534 6.66669 24.4848 6.66669Z"
                stroke={
                    active
                        ? customActiveColor
                            ? customActiveColor
                            : OctupColors.primaryActionableLight
                        : customColor
                          ? customColor
                          : OctupColors.cardsBackground
                }
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.3333 19.394L20.8424 14.9031C20.6833 14.7439 20.4674 14.6545 20.2424 14.6545C20.0174 14.6545 19.8016 14.7439 19.6424 14.9031L14.903 19.6425C14.7439 19.8016 14.528 19.891 14.303 19.891C14.078 19.891 13.8622 19.8016 13.703 19.6425L11.509 17.4485C11.3499 17.2894 11.1341 17.2 10.9091 17.2C10.684 17.2 10.4682 17.2894 10.3091 17.4485L6.66666 21.091"
                stroke={
                    active
                        ? customActiveColor
                            ? customActiveColor
                            : OctupColors.primaryActionableLight
                        : customColor
                          ? customColor
                          : OctupColors.cardsBackground
                }
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.0303 13.4546C13.7332 13.4546 14.303 12.8848 14.303 12.1818C14.303 11.4789 13.7332 10.9091 13.0303 10.9091C12.3274 10.9091 11.7576 11.4789 11.7576 12.1818C11.7576 12.8848 12.3274 13.4546 13.0303 13.4546Z"
                fill={
                    active
                        ? customActiveColor
                            ? customActiveColor
                            : OctupColors.primaryActionableLight
                        : customColor
                          ? customColor
                          : OctupColors.cardsBackground
                }
            />
        </svg>
    )
}
