import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getWarehouses } from 'services'

const INITIAL_STATE = {
    data: null,
    isLoading: false,
    error: null,
}

export const fetchWarehouses = createAsyncThunk('fetchWarehouses', async (data, thunkAPI) =>
    getWarehouses(data, thunkAPI)
)

export const warehousesSlice = createSlice({
    name: 'warehouses',
    initialState: INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(fetchWarehouses.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchWarehouses.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchWarehouses.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const warehousesReducer = warehousesSlice.reducer
