import { SourceTitleContainer } from '@/new_containers/components/Attribution'
import { OrdersTable } from '@/pages/operations/OrdersTable'
import { OctupBaseModal } from '@/shared/ui/Modal/OctupBaseModal'
import { OrderButton, TableContainer } from './CampaignsTableOrderCell.styles'

export const CampaignsTableOrderCell = ({
    tableConfig,
    ordersCount,
    showOrdersModal,
    onOpenModal,
    onCloseModal,
    source,
    campaignName,
    campaignsCount,
    isClickable,
}) => {
    if (!isClickable) {
        return ordersCount
    }

    return (
        <>
            <OrderButton onClick={onOpenModal}>{ordersCount}</OrderButton>
            {showOrdersModal && (
                <OctupBaseModal
                    onDismissModal={onCloseModal}
                    title={
                        <SourceTitleContainer
                            source={source}
                            campaignName={campaignName}
                            campaignsCount={campaignsCount}
                            fontSize="1.7rem"
                        />
                    }
                >
                    <TableContainer>
                        <OrdersTable {...tableConfig} />
                    </TableContainer>
                </OctupBaseModal>
            )}
        </>
    )
}
