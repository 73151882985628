import { createSlice } from '@reduxjs/toolkit'

const USER_CONFIG_INITIAL_STATE = {
    intro: { showNewUserModal: false, startTour: false },
}
export const userConfigSlice = createSlice({
    name: 'userConfig',
    initialState: USER_CONFIG_INITIAL_STATE,
    reducers: {
        setShowNewUserModal: (state, payload) => {
            state.intro.showNewUserModal = payload.payload
        },
        setStartTour: (state, payload) => {
            state.intro.startTour = payload.payload
        },
    },
})
export const { setShowNewUserModal, setStartTour } = userConfigSlice.actions
export const userConfigReducer = userConfigSlice.reducer
