import { useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import MetricTrend from 'components/Metrics/MetricItem/MetricItemComponents/MetricTrend'
import { getInputDate } from 'shared/utils/dates-util'
import { VALUE_TYPE_INDEX, getFormattedVal } from 'shared/utils/metrics-utils'
import { containerStyle } from './MetricValue.styles'

export const MetricValue = ({
    row,
    title,
    config,
    comparedConfig,
    mainMetricTitles,
    showTrend,
    ...props
}) => {
    const isMainMetric = useMemo(() => mainMetricTitles.includes(title), [mainMetricTitles, title])
    const valueConfig = useMemo(
        () => (config.currency || !comparedConfig ? config : comparedConfig),
        [comparedConfig, config]
    )

    if (title === 'date') {
        return (
            <Typography variant="body2" {...props}>
                {getInputDate(row.date, config.time_slice)}
            </Typography>
        )
    }

    return (
        <Grid container alignItems="center" flexWrap="nowrap" columnSpacing={2} sx={containerStyle}>
            <Grid item xs>
                <Typography variant="body2" fontWeight={isMainMetric ? 600 : 'normal'} {...props}>
                    {getFormattedVal({
                        val: row[title],
                        currency: valueConfig.currency,
                        valueType: valueConfig.valueUnits,
                        isShopCurrency: valueConfig.isShopCurrency,
                        isSupported: valueConfig.supported,
                        multiplyBy100: null,
                        showSymbol: true,
                        ...(+valueConfig.valueUnits === VALUE_TYPE_INDEX.PERCENTAGE && {
                            customNumOfDigits: 0,
                        }),
                    })}
                </Typography>
            </Grid>
            {showTrend && isMainMetric && (
                <Grid item xs={6}>
                    <MetricTrend
                        fontSize={'1.3rem'}
                        trendValue={row.trends[title]?.change_precentage * 100}
                        trendUnits={'percentage'}
                        isSupported={true}
                        isPositiveTrend={row.trends[title]?.change_precentage > 0}
                    />
                </Grid>
            )}
        </Grid>
    )
}
