import { getIconColor } from './utils'

export function ActionsBtnCustomersIcon({
    active,
    customActiveColor = '#504B5A',
    customColor = '#504B5A',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M12 7.33331V8.66665"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 15.3333V16.6666"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.99998 15.3334H13C13.442 15.3334 13.8659 15.1578 14.1785 14.8452C14.4911 14.5326 14.6666 14.1087 14.6666 13.6667C14.6666 13.2247 14.4911 12.8007 14.1785 12.4882C13.8659 12.1756 13.442 12 13 12H11C10.558 12 10.134 11.8244 9.82147 11.5119C9.50891 11.1993 9.33331 10.7754 9.33331 10.3334C9.33331 9.89133 9.50891 9.4674 9.82147 9.15484C10.134 8.84228 10.558 8.66669 11 8.66669H14"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
