import styled from 'styled-components'
import { OctupColors } from 'shared/theme/colors/OctupColors'

export const OrderButton = styled.button`
    font-size: inherit;
    font-weight: 600;
    color: ${OctupColors.primaryActionableDark};
    border: none;
    background: none;
    cursor: pointer;
`

export const TableContainer = styled.div`
    width: 120rem;
    height: 100%;
    padding-top: 1.5rem;

    & .tableHeader {
        padding-bottom: 1rem;
    }
`
