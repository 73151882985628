import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { GoodMorningBoard } from '@/components/GoodMorningBoard/GoodMorningBoard'
import { GMBContext } from '@/contexts/GMBContext'
import { useAbilities } from '@/hooks'
import { APP_FEATURES } from '@/models/appConfig'
import { AverageIntegrationsStatusContainer } from '@/new_containers/components/Integrations'
import { UserMenu } from '../../UserMenu/UserMenu'

const Header = () => {
    const { isFeatureAvailable } = useAbilities()
    const { isMainMenuCollapsed } = useSelector((state) => state.navigation)
    const { userInfo } = useSelector((state) => state.auth)
    const { showGMBoard, setShowGMBoard } = useContext(GMBContext)

    const isGoodMorningBoardAvailable = useMemo(
        () => isFeatureAvailable(APP_FEATURES.GOOD_MORNING_BOARD),
        [isFeatureAvailable]
    )

    return (
        <>
            <HeaderContainer isMainMenuCollapsed={isMainMenuCollapsed}>
                <ButtonPrimaryContainer>
                    <AverageIntegrationsStatusContainer width="20rem" />
                    {userInfo?.client?.name && (
                        <TempStoreName>{userInfo?.client?.name}</TempStoreName>
                    )}
                    <LoggedUserBtn>
                        <UserMenu />
                    </LoggedUserBtn>
                </ButtonPrimaryContainer>

                <RadiousCubeBase isMainMenuCollapsed={isMainMenuCollapsed} />
            </HeaderContainer>
            {isGoodMorningBoardAvailable && (
                <GoodMorningBoard showGMBoard={showGMBoard} setShowGMBoard={setShowGMBoard} />
            )}
        </>
    )
}

const HeaderContainer = styled.header`
    position: absolute;
    top: 0;
    left: ${(props) => (props.isMainMenuCollapsed ? '5rem' : '24.2rem')};
    height: 6rem;
    display: flex;
    background: #f6f5f9;
    justify-content: center;
    z-index: 100;
    width: ${(props) => (props.isMainMenuCollapsed ? 'calc(100% - 5rem)' : 'calc(100% - 24.2rem)')};
`

const ButtonPrimaryContainer = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    gap: 2rem;
`

const RadiousCubeBase = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => (props.isMainMenuCollapsed ? '2.4rem' : '2.4rem')};
    height: ${(props) => (props.isMainMenuCollapsed ? '7rem' : '2.4rem')};
    background-color: ${(props) => (props.isMainMenuCollapsed ? '#282831' : '#F6F5F9')};
    border-radius: ${(props) => (props.isMainMenuCollapsed ? '0' : '2rem')} 0
        ${(props) => (props.isMainMenuCollapsed ? '2.4rem' : '0')} 0;
    z-index: 2;
`

const LoggedUserBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 3rem;
    height: 6rem;
    cursor: pointer;
`

const TempStoreName = styled.div`
    text-transform: capitalize;
    background: #f8f7fb;
    box-shadow:
        -0.2rem -0.2rem 0.8rem #ffffff,
        0.2rem 0.2rem 0.4rem rgba(80, 75, 90, 0.16);
    border-radius: 0.8rem;
    width: fit-content;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0.8rem 0px 1.2rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.8rem;
    display: flex;
    align-items: center;
    color: #504b5a;
`

export default Header
