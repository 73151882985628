export function SignupCentralized({
    active,
    customActiveColor = '#DB2059',
    customColor = '#DB2059',
}) {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21 7H4C3.44772 7 3 7.44772 3 8V24C3 24.5523 3.44772 25 4 25H6M27 7H28C28.5523 7 29 7.44772 29 8V24C29 24.5523 28.5523 25 28 25H12"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.9993 21H25.9993"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.5 13H16"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.5 13H29"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.9993 21H18.9993"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect opacity="0.3" x="6" y="10" width="27" height="5" rx="2.5" fill="#00A8A8" />
            <path
                d="M5.9999 28.5L26.4999 4.00008"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
