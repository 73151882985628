import { useState, useCallback, useMemo, useEffect } from 'react'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import type { DatePickerType } from './utils'
import { getConfig, DATE_PICKER_TYPES } from './utils'

type DatePickerPropsType = DatePickerType & {
    isLoading?: boolean
    defaultValue?: string
}

export const DatePicker = ({
    type = DATE_PICKER_TYPES.TEXT_FIELD,
    views = ['day', 'month', 'year'],
    timezone = 'default',
    slots = {},
    slotProps = {},
    defaultValue,
    onAccept,
    isLoading,
    ...props
}: DatePickerPropsType) => {
    const [value, setValue] = useState<Date>(defaultValue ? new Date(defaultValue) : new Date())
    const [isOpen, setIsOpen] = useState(false)
    const toggleOpen = useCallback(() => setIsOpen((open) => !open), [])

    useEffect(() => {
        if (!isLoading && defaultValue) {
            setValue(new Date(defaultValue))
        }
    }, [defaultValue, isLoading])

    const config = useMemo(
        () =>
            getConfig({
                type,
                value,
                views,
                slots,
                slotProps,
                isOpen,
                toggleOpen,
            }),
        [isOpen, slotProps, slots, toggleOpen, type, value, views]
    )

    const handleChange = useCallback(
        (newValue: Date | null) => {
            setValue(newValue as Date)
        },
        [setValue]
    )

    return (
        <MuiDatePicker
            open={isOpen}
            value={value}
            views={views}
            disableFuture
            reduceAnimations
            timezone={timezone}
            monthsPerRow={4}
            onClose={toggleOpen}
            onOpen={toggleOpen}
            onChange={handleChange}
            onAccept={onAccept}
            loading={isLoading}
            {...config}
            {...props}
        />
    )
}
