import { DoughnutChart } from 'shared/ui/Charts/ChartDoughnut'
import { ChartsArea } from 'shared/ui/Charts/ChartsArea'

export const CHART_TYPES = {
    DOUGHNUT: 'Doughnut',
    LINE: 'Line',
}

export const CHART_BY_TYPE = {
    [CHART_TYPES.DOUGHNUT]: DoughnutChart,
    [CHART_TYPES.LINE]: ChartsArea,
}
