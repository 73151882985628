import { Grid } from '@mui/material'
import type { MetricsSectionPropsType } from '@octup/core/models'
import { LoadableContent } from '@/new_components'
import { BarChartWithImages } from '@/pages/marketing/BarChartWithImages'
import { MetricsSectionCard } from '../__common__'

type BarMetricsSectionPropsType = Omit<MetricsSectionPropsType, 'data'> & {
    // TODO: rewrite Bar Chart and reuse type for chartConfig here
    chartConfig?: object
}

export const BarMetricsSection = ({
    chartConfig,
    isLoading,
    ...props
}: BarMetricsSectionPropsType) => (
    <MetricsSectionCard height={60} minHeight={40} isLoading={isLoading} {...props}>
        <Grid container direction="column">
            <LoadableContent isLoading={isLoading}>
                <Grid item xs container alignItems="center">
                    {/* TODO: make bar chart responsive */}
                    {/* @ts-expect-error: BarChartWithImages is a JavaScript file */}
                    <BarChartWithImages
                        {...chartConfig}
                        // NOTE: 99% is to avoid cutting off the chart
                        // TODO: remove after re-writing Bar Chart
                        style={{ minHeight: 'unset', height: '99%' }}
                    />
                </Grid>
            </LoadableContent>
        </Grid>
    </MetricsSectionCard>
)
