import { OctupBaseModal } from 'shared/ui/Modal/OctupBaseModal'
import { iconsMapping } from 'components/UnitEconomics/ProductPNLBreakdown/iconsMapping'
import { ModalTitle } from 'new_components'
import { Box } from '@mui/material'
import { OctupBreathing } from 'shared/ui/Breathing/OctupBreathing'
import { SelectAccountsFormContainer } from 'new_containers/components/Integrations'
import { modalContainer } from './SelectAccountModal.styles'

export const SelectAccountsModal = ({
    integration,
    integrationTitle,
    isLoading,
    accounts,
    ...props
}) => (
    <OctupBaseModal
        {...props}
        title={
            <ModalTitle
                icon={iconsMapping[integration]}
                title={`Connect ${integrationTitle} Accounts`}
            />
        }
    >
        <Box sx={modalContainer}>
            {isLoading ? (
                <OctupBreathing width="100%" height="40rem" />
            ) : (
                <SelectAccountsFormContainer accounts={accounts} />
            )}
        </Box>
    </OctupBaseModal>
)
