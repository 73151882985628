import styled from 'styled-components'

export const MessageCardContainer = styled.div`
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(219, 32, 89, 1);
    border-radius: 0.8rem;
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 98%;
    padding: 0.8rem;
`

export const MessageCardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
`
export const IconContainer = styled.div`
    align-self: center;
    width: 2.5rem;
    height: auto;
`

export const HeaderMsg = styled.div`
    display: flex;
    align-self: center;
    font-size: 1.5rem;
    font-weight: 600;
    width: 100%;
    height: 100%;
`

export const MessageCardBody = styled(HeaderMsg)`
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
    padding: 0rem 1.7rem;

    div {
        font-size: 1.5rem;
    }

    a {
        color: #007f82;
        font-size: 1.5rem;
        font-weight: 600;
        cursor: pointer;
    }
`
