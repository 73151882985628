import React from 'react'
import { getIconColor } from './utils'

export function OperationsLogoSvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.3333 7.75H4.66667C4.29848 7.75 4 8.04848 4 8.41667V19.0833C4 19.4515 4.29848 19.75 4.66667 19.75H19.3333C19.7015 19.75 20 19.4515 20 19.0833V8.41667C20 8.04848 19.7015 7.75 19.3333 7.75Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.75 7.75V6.25C15.75 5.85218 15.592 5.47064 15.3107 5.18934C15.0294 4.90804 14.6478 4.75 14.25 4.75H9.75C9.35218 4.75 8.97064 4.90804 8.68934 5.18934C8.40804 5.47064 8.25 5.85218 8.25 6.25V7.75"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
