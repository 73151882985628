import React from 'react'

export function SlidersIconSvg({ active, customActiveColor = '#007F82', customColor = '#007F82' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M13.5909 12L5.00003 12"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 12L16.7727 12"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.7727 11.9783C13.7727 12.8067 14.4443 13.4783 15.2727 13.4783C16.1011 13.4783 16.7727 12.8067 16.7727 11.9783C16.7727 11.1499 16.1011 10.4783 15.2727 10.4783C14.4443 10.4783 13.7727 11.1499 13.7727 11.9783Z"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.22729 17.4783L5.00002 17.4784"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 17.4784L9.40909 17.4783"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.40906 17.4565C6.40906 18.2849 7.08063 18.9565 7.90906 18.9565C8.73748 18.9565 9.40906 18.2849 9.40906 17.4565C9.40906 16.6281 8.73748 15.9565 7.90906 15.9565C7.08063 15.9565 6.40906 16.6281 6.40906 17.4565Z"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.77271 6.52177L4.99998 6.52171"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 6.52171L11.9545 6.52177"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.95459 6.5C8.95459 7.32843 9.62616 8 10.4546 8C11.283 8 11.9546 7.32843 11.9546 6.5C11.9546 5.67157 11.283 5 10.4546 5C9.62616 5 8.95459 5.67157 8.95459 6.5Z"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
