import { WhatsNewContainer } from './WhatsNewMain.styles'
import { WhatsNewNavContainer } from '../../../new_containers/components/WhatsNew'
import { WhatsNewSlidesContainer } from '../../../new_containers/components/WhatsNew'

export const WhatsNewMain = () => {
    return (
        <WhatsNewContainer>
            <WhatsNewNavContainer />
            <WhatsNewSlidesContainer />
        </WhatsNewContainer>
    )
}
