export const getShortTableDate = (
    isoDate,
    dateOptions = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    },
    timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }
) => {
    if (!isoDate) {
        return {
            weekday: null,
            month_and_day: null,
            year: null,
            time: null,
        }
    }
    const date =
        new Date(isoDate).toLocaleDateString('en-us', dateOptions) +
        ' ,' +
        new Date(isoDate).toLocaleTimeString('en-us', timeOptions)
    const splitedDate = date.split(',')
    return {
        weekday: splitedDate[0],
        month_and_day: splitedDate[1],
        month_day_year_comma_seperated: `${splitedDate[1]},${splitedDate[2]}`,
        year: splitedDate[2],
        time: splitedDate[3] && splitedDate[3].replace(/\s/g, ''),
        primaryFormat: `${splitedDate[0]}. ${splitedDate[1]}, ${splitedDate[2]}`, // Wed. Aug 30, 2023
    }
}

function formatAMPM(date) {
    let hours = date.getHours()
    let amPm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12 || 12
    let minutes = date.getMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes} ${amPm}`
}

const TIME_SLICE_INDEX = {
    HOUR: '1',
    DAY: '2',
    MONTH: '3',
    YEAR: '4',
}

const TIME_SLICE_TO_INDEX_MAP = {
    hour: TIME_SLICE_INDEX.HOUR,
    day: TIME_SLICE_INDEX.DAY,
    month: TIME_SLICE_INDEX.MONTH,
    year: TIME_SLICE_INDEX.YEAR,
}

export const getInputDate = (date, timeSlice) => {
    const timeSliceIndex = TIME_SLICE_TO_INDEX_MAP[timeSlice] || timeSlice

    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]

    const myDate = new Date(date)
    if (myDate?.toString() === 'Invalid Date') {
        return date
    }

    const time = formatAMPM(myDate)
    const day = ('0' + myDate.getDate()).slice(-2)
    const monthName = monthNames[myDate.getMonth()]
    const weekDay = weekDays[myDate.getDay()]
    const year = myDate.getFullYear()

    switch (timeSliceIndex?.toString()) {
        case TIME_SLICE_INDEX.HOUR:
            return `${weekDay}. ${time}`
        case TIME_SLICE_INDEX.DAY:
            return `${weekDay}. ${monthName} ${day}, ${year}`
        case TIME_SLICE_INDEX.MONTH:
            return `${monthName}, ${year}`
        case TIME_SLICE_INDEX.YEAR:
            return `${monthName} ${day}, ${year} @ ${time}`
        case 'objective':
            return `${monthName} ${day}, ${year}`
        case 'time':
            return time
        default:
            return `${weekDay}. ${monthName} ${day}, ${year}`
    }
}

export function getTimeAgo(dateString, showTime) {
    const currentDate = new Date()
    const inputDate = new Date(dateString)
    const timeDifference = currentDate - inputDate
    const millisecondsInDay = 1000 * 60 * 60 * 24

    if (timeDifference < millisecondsInDay) {
        return showTime
            ? inputDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            : `Today`
    } else if (timeDifference < 2 * millisecondsInDay) {
        return `Yesterday`
    } else if (timeDifference < 7 * millisecondsInDay) {
        const daysAgo = Math.floor(timeDifference / millisecondsInDay)
        return `${daysAgo} Days Ago`
        // } else if (timeDifference < 14 * millisecondsInDay) {
        //     return "A Week Ago";
    } else {
        return showTime
            ? getShortTableDate(inputDate).weekday +
                  '.' +
                  getShortTableDate(inputDate).month_day_year_comma_seperated
            : `${inputDate.toLocaleDateString()}`
    }
}
