import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { OctupBaseTable } from 'shared/ui/Tables/OctupBaseTable'
import { getDataValue, getTableConfig } from './CostsTableContainer.utils'

export const CostsTableContainer = () => {
    const { data, isLoading } = useSelector((state) => state.costManager.costs)
    const tableConfig = useMemo(() => getTableConfig(data), [data])

    return (
        <OctupBaseTable
            tableName="Cost Manager"
            showDownloadCSV
            isRowsLoading={isLoading}
            showDatePicker={false}
            rowKeyField="cost_id"
            hiddenRowKey="cost_id"
            showSelectedRow={false}
            customHeight="77vh"
            showSearchFilter={true}
            searchFilterPlaceholder="Search by cost"
            getDataValue={getDataValue}
            {...tableConfig}
        />
    )
}
