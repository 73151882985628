import React from 'react'
import { getIconColor } from './utils'

export function CheckmarkSvg({ active, customActiveColor, customColor, scale = 1 }) {
    const transform = `scale(${scale})`
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.1rem"
            height="0.8rem"
            viewBox="0 0 11 8"
            fill="none"
            transform={transform}
        >
            <path
                d="M10 1L4 7L1 4.00013"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
