import { CardExpendable } from 'shared/ui/Card/CardExpendable'
import { NoSelectedProductFallback } from '../NoSelectedProductFallback'
import { ProductDetailsContainer } from 'new_containers/components/UnitEconomics'

export const ProductDetailsPanel = ({ selectedProduct }) => (
    <CardExpendable>
        {selectedProduct ? (
            <ProductDetailsContainer selectedProduct={selectedProduct} />
        ) : (
            <NoSelectedProductFallback />
        )}
    </CardExpendable>
)
