import styled from 'styled-components'
import InitialsAvatar from 'react-initials-avatar'

export const MobileUserAvatar = ({ imgSrc, name, isFancy = true }) => {
    if (!imgSrc) {
        return (
            <UserInitialsContainer>
                <InitialsAvatar name={name} />
            </UserInitialsContainer>
        )
    } else if (isFancy) {
        return (
            <Div>
                <StyledSvg
                    xlinkHref="http://www.w3.org/1999/xlink"
                    width="89"
                    height="88"
                    viewBox="0 0 89 88"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="46.5" cy="48" r="32" fill="url(#pattern0)" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M46.3126 84.2935C66.5133 84.2935 82.8891 67.9177 82.8891 47.7171C82.8891 27.5165 66.5133 11.1406 46.3126 11.1406C26.112 11.1406 9.73618 27.5165 9.73618 47.7171C9.73618 67.9177 26.112 84.2935 46.3126 84.2935ZM46.3126 80.7766C64.5709 80.7766 79.3721 65.9753 79.3721 47.7171C79.3721 29.4588 64.5709 14.6576 46.3126 14.6576C28.0544 14.6576 13.2531 29.4588 13.2531 47.7171C13.2531 65.9753 28.0544 80.7766 46.3126 80.7766Z"
                        fill="url(#paint0_linear_741_8993)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M47.926 84.7115C70.4137 84.7115 88.6436 66.4816 88.6436 43.9939C88.6436 21.5062 70.4137 3.27637 47.926 3.27637C25.4384 3.27637 7.2085 21.5062 7.2085 43.9939C7.2085 66.4816 25.4384 84.7115 47.926 84.7115ZM47.9319 81.0721C68.4064 81.0721 85.0042 64.4743 85.0042 43.9998C85.0042 23.5253 68.4064 6.92743 47.9319 6.92743C27.4574 6.92743 10.8596 23.5253 10.8596 43.9998C10.8596 64.4743 27.4574 81.0721 47.9319 81.0721Z"
                        fill="url(#paint1_linear_741_8993)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M44.7138 83.1553C67.4956 83.1553 85.9638 64.687 85.9638 41.9053C85.9638 19.1235 67.4956 0.655273 44.7138 0.655273C21.9321 0.655273 3.46384 19.1235 3.46384 41.9053C3.46384 64.687 21.9321 83.1553 44.7138 83.1553ZM44.7489 81.3531C66.516 81.3531 84.1617 63.7075 84.1617 41.9404C84.1617 20.1733 66.516 2.52761 44.7489 2.52761C22.9819 2.52761 5.33618 20.1733 5.33618 41.9404C5.33618 63.7075 22.9819 81.3531 44.7489 81.3531Z"
                        fill="url(#paint2_linear_741_8993)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M44 88C68.3005 88 88 68.3005 88 44C88 19.6995 68.3005 0 44 0C19.6995 0 0 19.6995 0 44C0 68.3005 19.6995 88 44 88ZM44 84.2553C66.2324 84.2553 84.2553 66.2324 84.2553 44C84.2553 21.7676 66.2324 3.74468 44 3.74468C21.7676 3.74468 3.74468 21.7676 3.74468 44C3.74468 66.2324 21.7676 84.2553 44 84.2553Z"
                        fill="url(#paint3_linear_741_8993)"
                    />
                    <defs>
                        <pattern
                            id="pattern0"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                        >
                            <use
                                xlinkHref="#image0_741_8993"
                                transform="translate(0 -0.125) scale(0.0025)"
                            />
                        </pattern>
                        <linearGradient
                            id="paint0_linear_741_8993"
                            x1="31.522"
                            y1="14.6228"
                            x2="61.6792"
                            y2="66.7649"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#7737FF" />
                            <stop offset="1" stopColor="#00A0A0" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_741_8993"
                            x1="29.5632"
                            y1="6.4699"
                            x2="63.06"
                            y2="67.0552"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF3489" />
                            <stop offset="1" stopColor="#8627FF" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_741_8993"
                            x1="25.3021"
                            y1="6.20149"
                            x2="61.3527"
                            y2="68.943"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FFCF72" />
                            <stop offset="1" stopColor="#FF3489" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_741_8993"
                            x1="42.6609"
                            y1="74.9375"
                            x2="54.7712"
                            y2="31.6572"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#00C8C0" />
                            <stop offset="1" stopColor="#00A0A0" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </StyledSvg>
                <StyledImage src={imgSrc} />
            </Div>
        )
    } else {
        return (
            <Div>
                <StyledSvg
                    width="136"
                    height="136"
                    viewBox="0 0 136 136"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_dd_749_10856)">
                        <circle cx="70" cy="70" r="60" fill="url(#pattern0)" />
                        <circle cx="70" cy="70" r="58" stroke="#F6F5F9" strokeWidth="4" />
                    </g>
                    <defs>
                        <filter
                            id="filter0_dd_749_10856"
                            x="0"
                            y="0"
                            width="136"
                            height="136"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dx="2" dy="2" />
                            <feGaussianBlur stdDeviation="2" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.313726 0 0 0 0 0.294118 0 0 0 0 0.352941 0 0 0 0.16 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_749_10856"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dx="-2" dy="-2" />
                            <feGaussianBlur stdDeviation="4" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="effect1_dropShadow_749_10856"
                                result="effect2_dropShadow_749_10856"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect2_dropShadow_749_10856"
                                result="shape"
                            />
                        </filter>
                        <pattern
                            id="pattern0"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                        >
                            <use transform="translate(0 -0.125) scale(0.0025)" />
                        </pattern>
                    </defs>
                </StyledSvg>
                <StyledImage src={imgSrc} />
            </Div>
        )
    }
}

const Div = styled.div`
    width: 40%;
    overflow: hidden;
    position: relative;
`

const StyledSvg = styled.svg`
    width: 100%;
    height: auto;
`

const StyledImage = styled.img`
    position: absolute;
    left: 53%;
    top: 52%;
    transform: translate(-50%, -50%);
    width: 75%;
    border-radius: 50%;
`

const UserInitialsContainer = styled.div`
    .initials-avatar {
        width: 16rem !important;
        height: 16rem !important;
        /*cursor: pointer;*/
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        background-color: #504b5a;
        display: inline-block;
        box-sizing: border-box;
    }

    .initials-avatar div {
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: inherit;
        font-weight: inherit;
        font-size: 4rem;
    }
`
