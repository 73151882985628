import { WhatsNewModalTitle } from './WhatsNewTitle.styled'
import { WhatsNewIcon } from '../../../new_assets/icons'

export const WhatsNewTitle = () => {
    return (
        <WhatsNewModalTitle>
            <WhatsNewIcon /> What's New{' '}
        </WhatsNewModalTitle>
    )
}
