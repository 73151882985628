import { forwardRef, useMemo } from 'react'
import type { DialogProps } from '@mui/material'
import { Dialog as MuiDialog, DialogTitle } from '@mui/material'
import { CloseIcon } from '@octup/core/assets/icons'
import { Typography, SvgIcon, Grid, IconButton } from '@octup/core/ui-kit'

export { DialogActions, DialogContent, DialogContentText } from '@mui/material'

export type DialogPropsType = Omit<DialogProps, 'title'> & {
    title?: React.ReactNode
    onClose?: (event: React.MouseEvent) => void
    action?: React.ReactNode
}

export const Dialog = forwardRef(
    (
        { title, children, onClose, PaperProps, action, ...props }: DialogPropsType,
        ref: React.Ref<HTMLDivElement>
    ) => {
        const overriddenProps = useMemo(
            () => ({ PaperProps: { ...PaperProps, elevation: 5 } }),
            [PaperProps]
        )

        return (
            <MuiDialog ref={ref} onClose={onClose} {...props} {...overriddenProps}>
                {title && (
                    <DialogTitle
                        component={Grid}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Typography variant="h6" fontWeight={400}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs container justifyContent="flex-end" gap={1}>
                            {action}
                            <IconButton onClick={onClose} color="secondary">
                                <SvgIcon component={CloseIcon} />
                            </IconButton>
                        </Grid>
                    </DialogTitle>
                )}
                {children}
            </MuiDialog>
        )
    }
)
