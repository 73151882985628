import { CardsContainer } from '../Marketing.styles'
import { renderStyledCardElement } from '../../operations/operations-page-utils/operationUtils'
import { getMetricsBySegment, SEGMENTS } from '../../../features/metrics/metricsSlice'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const NUMBER_OF_SHOWN_METRICS = 8

export function MarketingMetrics() {
    const dispatch = useDispatch()
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const { metrics, isLoading } = useSelector((state) => state.metrics)

    const metricsMarketing = metrics[SEGMENTS.MARKETING]
        ? metrics[SEGMENTS.MARKETING]
        : [1, 2, 3, 4, 5, 6, 7, 8]

    useEffect(() => {
        if (!datePickerValues || isLoading) return
        dispatch(
            getMetricsBySegment({
                segments: [SEGMENTS.MARKETING],
                datePickerValues,
                dataset: 1,
            })
        )
    }, [datePickerValues])

    return (
        <CardsContainer>
            {metricsMarketing.slice(0, NUMBER_OF_SHOWN_METRICS).map((datum, i) => {
                return renderStyledCardElement(datum, isLoading, i, SEGMENTS.MARKETING)
            })}{' '}
        </CardsContainer>
    )
}
