import { getIconColor } from './utils'

export function LightningIconSvg({ active, customActiveColor, customColor, disabled }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M8.7 19.4993L10.1 13.0707L4.5 11.1421L14.3 1.49927L12.9 7.92784L18.5 9.85641L8.7 19.4993Z"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
