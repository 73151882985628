import { useCallback, useMemo } from 'react'
import { useToast } from 'hooks'
import { ConnectDialog, SupportContactDialog } from 'new_components/Integrations'
import { getModalContent, getResolvedTemplate } from './ConnectDialogContainer.utils'

export const ConnectDialogContainer = ({ integration, integrationTitle, onClose }) => {
    const toast = useToast()
    const modalContent = useMemo(() => getModalContent(integration), [integration])
    const { defaultValues, validationSchema } = modalContent

    const showSupportModal = useMemo(
        () => !integration.isModal && !integration.href,
        [integration.href, integration.isModal]
    )

    const handleConnect = useCallback(
        (data) => {
            onClose()
            toast.process(`Hold on, we’re connecting ${integrationTitle}`)
            window.location.href = getResolvedTemplate(integration.href, data)
        },
        [integration.href, integrationTitle, onClose, toast]
    )

    if (showSupportModal) {
        return <SupportContactDialog onClose={onClose} />
    }

    return (
        <ConnectDialog
            modalContent={modalContent}
            integration={integration}
            integrationTitle={integrationTitle}
            onClose={onClose}
            onSubmit={handleConnect}
            defaultValues={defaultValues}
            validationSchema={validationSchema}
        />
    )
}
