import { useEffect, useCallback } from 'react'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { UseFormReturn } from 'react-hook-form'
import { ERROR_MESSAGES } from '@/constants/validation'
import { useToast } from '@/hooks'
import type { EmailSettingsType } from '@/models/settings'
import { ErrorMessageFallback, LoadingView } from '@/new_components/__common__'
import { FormContainer } from '@/new_containers/components/__common__'
import { fetchEditEmailSettings, fetchEmailSettings } from '@/state/features/settings'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { EmailSettingsFormStateContainer } from '../EmailSettingsFormStateContainer'
import { VALIDATION_SCHEMA } from './utils'

export const EmailSettingsFormContainer = () => {
    const toast = useToast()
    const dispatch = useAppDispatch()
    const { email } = useAppSelector((state) => state.settings)

    useEffect(() => {
        dispatch(fetchEmailSettings())
    }, [dispatch])

    const handleSubmit = useCallback(
        (data: EmailSettingsType) => dispatch(fetchEditEmailSettings(data)),
        [dispatch]
    )

    const handleSuccess = useCallback(
        (
            data: PayloadAction<EmailSettingsType, string, { arg: EmailSettingsType }>,
            formContext: UseFormReturn
        ) => {
            formContext.reset(data.meta.arg)
            toast.success(`Email settings have been updated successfully`)
        },
        [toast]
    )

    const handleFail = useCallback(() => {
        toast.error(ERROR_MESSAGES.SERVER_ERROR)
    }, [toast])

    if (email.error) return <ErrorMessageFallback />
    if (email.isLoading) return <LoadingView size={30} />

    return (
        // @ts-expect-error: FormContainer is a JavaScript file
        <FormContainer
            defaultValues={email.data}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
            onFail={handleFail}
        >
            <EmailSettingsFormStateContainer />
        </FormContainer>
    )
}
