import styled from 'styled-components'

export const WhatsNewModalContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 4rem 0;

    a {
        text-decoration: underline;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }
`
