import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PAYMENTS_API_URL, createPaymentSession } from 'services'

const INITIAL_STATE = {
    url: null,
    isLoading: false,
    error: null,
}

export const fetchPaymentSession = createAsyncThunk(`${PAYMENTS_API_URL}`, async (data, thunkAPI) =>
    createPaymentSession(data, thunkAPI)
)

export const paymentSessionSlice = createSlice({
    name: 'paymentSession',
    initialState: INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentSession.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchPaymentSession.fulfilled, (state, action) => {
                state.url = action.payload
                state.isLoading = false
            })
            .addCase(fetchPaymentSession.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const paymentSessionSliceReducer = paymentSessionSlice.reducer
