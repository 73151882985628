import { BreathingContainer } from './MetricsDetailsModal.styles'
import { LoadingStateTableShimmer } from './MetricsDetailsTables/LoadingStateTableShimmer'
import { NoDeepdiveTable } from './MetricsDetailsTables/NoDeepdiveTable'
import { DeepdiveTable } from './MetricsDetailsTables/DeepdiveTable'
import { DICTIONARY_SECTIONS, getDictionaryValue } from '../../../shared/dictionary/dictionary'
import { Text } from 'shared/styledComponents'

// TODO: re-write
// 1. make more readable
// 2. remove unnecessary props
export function MetricsDetailsTable({
    selectedMetricData,
    isLoadingDeepdives,
    deepdives,
    rearrangedMetricArray,
    isBreakdown,
    breakdownItemsData,
    originalReferenceType = false,
}) {
    const deepdiveReference = selectedMetricData?.metric?.deep_dive
    const resultCount = deepdives[`${deepdiveReference}`]?.rows?.length
    const reference = originalReferenceType ? deepdiveReference : +deepdiveReference
    const isSlaReference = deepdiveReference?.toString()?.includes('sla')

    return (
        <>
            {isLoadingDeepdives && !reference ? (
                <BreathingContainer>
                    <LoadingStateTableShimmer />
                </BreathingContainer>
            ) : !reference && !isBreakdown && !isSlaReference ? (
                <NoDeepdiveTable
                    selectedMetricData={selectedMetricData}
                    rearrangedMetricArray={rearrangedMetricArray}
                />
            ) : !reference && isBreakdown ? (
                <DeepdiveTable
                    selectedMetricData={selectedMetricData}
                    deepdives={deepdives}
                    isBreakdown={isBreakdown}
                    isPredefined={false}
                    breakdownItemsData={breakdownItemsData}
                    sortingHeadersToDataMapping={null}
                />
            ) : (
                isSlaReference && (
                    <DeepdiveTable
                        showCounter
                        selectedMetricData={selectedMetricData}
                        customCounter={
                            <Text fontWeight="bold">
                                {`Current ${getDictionaryValue(
                                    selectedMetricData?.metric?.metricTitle,
                                    DICTIONARY_SECTIONS.METRICS_TITLES
                                )?.toLowerCase()}: ${
                                    deepdives[`${deepdiveReference}`] &&
                                    (resultCount > 1000 ? '+1000' : resultCount)
                                }`}
                            </Text>
                        }
                        deepdives={deepdives}
                        isBreakdown={isBreakdown}
                        showSearchFilter={deepdiveReference?.toString()?.includes('sla')}
                        isPredefined={deepdiveReference && deepdiveReference?.includes('sla')}
                        breakdownItemsData={breakdownItemsData}
                        searchFilterFields={{
                            order_name: 'order_name',
                            customer_full_name: 'customer_full_name',
                        }}
                        sortingHeadersToDataMapping={
                            deepdiveReference
                                ? deepdives[`${selectedMetricData.metric?.deep_dive}`]
                                      ?.sortingHeadersToDataMapping
                                : null
                        }
                    />
                )
            )}
            {isLoadingDeepdives && !!reference ? (
                <LoadingStateTableShimmer />
            ) : (
                selectedMetricData &&
                deepdives[reference] &&
                !!reference &&
                !isSlaReference && (
                    <DeepdiveTable
                        selectedMetricData={selectedMetricData}
                        deepdives={deepdives}
                        isBreakdown={isBreakdown}
                        breakdownItemsData={breakdownItemsData}
                    />
                )
            )}
        </>
    )
}
