import { Grid } from '@mui/material'
import { Button } from '@octup/core/ui-kit'
import { ReactComponent as ExpiredSessionImg } from 'shared/assets/images/Expired_Session.svg'
import { octupDictionary } from 'shared/dictionary/dictionary'
import { OctupBaseModal } from 'shared/ui/Modal/OctupBaseModal'
import { Text } from 'shared/styledComponents'
import { buttonStyle, containerStyle, textContainerStyle } from './ExpiredModal.styles'

const { title, text } = octupDictionary.messages.invalidSession

export const ExpiredModal = ({ onLogout }) => (
    <OctupBaseModal showButtons={false} showCloseBtn={false}>
        <Grid container flexDirection="column" alignItems="center" gap="4rem" sx={containerStyle}>
            <Grid item>
                <ExpiredSessionImg />
            </Grid>
            <Grid
                item
                container
                flexDirection="column"
                alignItems="center"
                gap="2rem"
                sx={textContainerStyle}
            >
                <Grid item>
                    <Text textAlign="center" fontSize="2rem" fontWeight="bold">
                        {title}
                    </Text>
                </Grid>
                <Grid item>
                    <Text textAlign="center">{text}</Text>
                </Grid>
            </Grid>
            <Grid item>
                <Button variant="contained" onClick={onLogout} sx={buttonStyle}>
                    Go To Login
                </Button>
            </Grid>
        </Grid>
    </OctupBaseModal>
)
