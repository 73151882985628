import { useSelector } from 'react-redux'
import { useOctupDispatch } from '../../../../hooks/useOctupDispatch'
import { OctupBaseModal } from '../../../../shared/ui/Modal/OctupBaseModal'
import { Text } from '../../../../shared/styledComponents'
import { DismissInsightModalContainer, NoteText } from './DismissInsightModal.styles'
import { useCallback, useMemo, useState } from 'react'
import { getActionButtons } from './DismissInsightModal.utils'
import { DismissPeriod, getDismissDate, getDismissPeriodValidation } from './DismissPeriod'
import { Grid } from '@mui/material'

export const DismissInsightModal = ({ dismissModalhandler }) => {
    const octupDispatch = useOctupDispatch()
    const { insight } = useSelector((state) => state.insights)
    const [dismissData, setDismissData] = useState(null)
    const [errors, setErrors] = useState(false)

    const handleDismiss = useCallback(() => {
        const formErrors = {
            ...getDismissPeriodValidation(dismissData),
            // ...other validations (OD-1888)
        }

        if (Object.keys(formErrors).length) {
            setErrors(formErrors)

            return
        }

        dismissModalhandler()

        octupDispatch.insights.dismissInsight({
            insightId: insight.id,
            dismissDate: getDismissDate(dismissData),
        })
    }, [dismissModalhandler, dismissData, insight.id, octupDispatch.insights])

    const modalActionButtons = useMemo(
        () =>
            getActionButtons({
                onCancel: dismissModalhandler,
                onDismiss: handleDismiss,
            }),
        [dismissModalhandler, handleDismiss]
    )

    return (
        <OctupBaseModal
            onDismissModal={dismissModalhandler}
            buttons={modalActionButtons}
            title="Dismiss Insight"
        >
            <DismissInsightModalContainer>
                <Grid container flexDirection="column" spacing={2} mt={-1}>
                    <DismissPeriod
                        dismissData={dismissData}
                        onDismissDataChange={setDismissData}
                        errors={errors}
                        onErrorsChange={setErrors}
                    />
                    <Grid item>
                        <Text fontSize="1.5rem">At the moment, dismissing cannot be undone.</Text>
                    </Grid>
                </Grid>
            </DismissInsightModalContainer>
        </OctupBaseModal>
    )
}
