export const isEmailValid = (email) => {
    return !!String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}

export const isNameValid = (name) => {
    // Regular expression pattern to match valid names
    const nameRegex = /^[A-Za-z][A-Za-z\s']*$/
    return nameRegex.test(name)
}

export const onChangeWrapper = (e, value, onChange, setCenterPlaceholder, isFocused) => {
    if (e.target.value.length === 0 && !isFocused) {
        setCenterPlaceholder(true)
    } else if (!isFocused) {
        setCenterPlaceholder(false)
    } else if (value.length > 0) {
        setCenterPlaceholder(false)
    }
    onChange(e)
}

export const onfocus = (setIsFocused, setCenterPlaceholder) => {
    setIsFocused(true)
    setCenterPlaceholder(false)
}
