import styled from 'styled-components'
import { addHeapEvent } from 'utils'
import { CardMenuItem } from './OctupCardMenuItem'

export const CardsMenu = ({
    cardsData = [],
    generalBadgeObj = { title: 'coming soon', theme: 'orange' },
    onClickHandler,
    customCardStyle = () => {},
    style,
    ...props
}) => {
    return (
        <CardsMenuContainer style={style}>
            {cardsData.map((el) => (
                <div
                    key={el.title}
                    onClick={(e) => {
                        onClickHandler(e, el)
                        addHeapEvent('Open integration', { integration: el.title })
                    }}
                >
                    <CardMenuItem
                        title={el.title}
                        label={el.label && el.label}
                        description={el.description}
                        icon={el.icon}
                        isActive={el.isActive}
                        isConnected={el.isConnected}
                        customCardStyle={customCardStyle(el)}
                        badgeObj={el.badgeObj ? el.badgeObj : !el.isActive ? generalBadgeObj : null}
                        {...props}
                    />
                </div>
            ))}
        </CardsMenuContainer>
    )
}

const CardsMenuContainer = styled.li`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(44rem, 1fr));
    grid-gap: 2rem;
    padding: 1rem 0.2rem;
    margin-top: 1rem;
    width: 100%;
    height: min-content;
`
