import type { Components, Theme } from '@mui/material'

type MenuListOverridesType = Pick<Components<Theme>, 'MuiMenuItem'>

export const MENU_LIST_OVERRIDES: MenuListOverridesType = {
    MuiMenuItem: {
        styleOverrides: {
            root: ({ theme }) => ({
                '& .MuiListItemIcon-root': {
                    minWidth: theme.spacing(7),
                },

                '&.Mui-selected': {
                    opacity: 1,
                    background: 'none',
                    '& .MuiSvgIcon-root, & .MuiTypography-root': {
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                    },
                },
            }),
        },
    },
}
