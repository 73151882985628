import { Stack, Typography } from '@octup/core/ui-kit'
import { AuthLayout } from '@/layouts'
import { APP_ROUTES } from '@/models/appConfig'
import { RouterLink } from '@/new_components/__common__'
import { AppBenefitsInfoContainer } from '@/new_containers/components/Signup'

type SignupLayoutPropsType = {
    title: string
    children: React.ReactNode
}

export const SignupLayout = ({ title, children }: SignupLayoutPropsType) => (
    <AuthLayout sideContent={<AppBenefitsInfoContainer />}>
        <Stack width="100%" spacing={5}>
            <Typography variant="h5">{title}</Typography>
            {children}
            <Typography variant="body2">
                Already have an account? <RouterLink route={APP_ROUTES.LOGIN}>Log in</RouterLink>
            </Typography>
        </Stack>
    </AuthLayout>
)
