import type { CSSInterpolation, Theme } from '@mui/material'
import type { PickersMonthClassKey, PickersYearClassKey } from '@mui/x-date-pickers'
import type { PickerComponents } from '@mui/x-date-pickers/themeAugmentation'

type DatePickerOverridesType = Pick<
    PickerComponents<Theme>,
    | 'MuiPickersPopper'
    | 'MuiDateCalendar'
    | 'MuiPickersCalendarHeader'
    | 'MuiPickersMonth'
    | 'MuiPickersYear'
>

type ThemePropType = { theme: Theme }
type ButtonStyleType = PickersMonthClassKey | PickersYearClassKey
type ButtonStyleReturnType = Partial<
    Record<ButtonStyleType, ({ theme }: ThemePropType) => CSSInterpolation>
>

const getButtonStyle = (button: ButtonStyleType): ButtonStyleReturnType => ({
    [button]: ({ theme }: ThemePropType) => ({
        fontSize: theme.spacing(3),
        color: theme.palette.text.primary,
        borderRadius: theme.spacing(2),
    }),
    selected: ({ theme }) => ({
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        fontWeight: 600,
        border: `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(2),
        '&:focus, &:hover': {
            backgroundColor: 'transparent',
        },
    }),
    disabled: ({ theme }) => ({
        color: theme.palette.grey[500],
    }),
})

export const DATE_PICKER_OVERRIDES: DatePickerOverridesType = {
    MuiPickersPopper: {
        styleOverrides: {
            paper: ({ theme }) => ({
                marginTop: theme.spacing(2),
            }),
        },
    },
    MuiDateCalendar: {
        styleOverrides: {
            root: {
                height: 'auto',
            },
        },
    },
    MuiPickersCalendarHeader: {
        styleOverrides: {
            label: ({ theme }) => ({
                fontSize: theme.spacing(3.5),
            }),
        },
    },
    MuiPickersMonth: {
        styleOverrides: getButtonStyle('monthButton'),
    },
    MuiPickersYear: {
        styleOverrides: getButtonStyle('yearButton'),
    },
}
