import React from 'react'
import { getIconColor } from './utils'

export function ChevronDownSvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            width="1.3rem"
            height="0.7rem"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 1L6 6L1 1"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
