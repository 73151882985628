import { Card, CardContent } from '@octup/core/ui-kit'
import { WorkingHoursSettingsFormContainer } from '@/new_containers/components/Clients'
import { cardStyle } from './styles'

export const WorkingHoursSettings = () => (
    <Card sx={cardStyle}>
        <CardContent>
            <WorkingHoursSettingsFormContainer />
        </CardContent>
    </Card>
)
