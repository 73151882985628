import type { ThemeType } from '@octup/core/theme'
import { circlesImg } from '@/new_assets/images'

export const containerStyle = (theme: ThemeType) => ({
    height: '100vh',
    backgroundColor: theme.palette.background.contrast,
})

export const contentContainerStyle = (theme: ThemeType) => ({
    height: '100%',
    overflow: 'auto',
    padding: { xs: theme.spacing(10), sm: theme.spacing(10, 14) },
})

export const sideContentContainerStyle = (theme: ThemeType) => ({
    ...contentContainerStyle(theme),
    display: { xs: 'none', md: 'flex' },
    borderRadius: theme.spacing(10, 0, 0, 10),
    background: `${theme.palette.background.paper} url(${circlesImg}) top right / ${theme.spacing(160)} ${theme.spacing(120)} no-repeat`,
})

export const contentStyle = (theme: ThemeType) => ({
    maxWidth: { xs: '100%', sm: theme.spacing(100) },
})
