import type { APP_ROUTES } from 'models/appConfig'
import type { DashboardsListType } from 'models/dashboards'

export enum PAGE_VIEWS {
    LIST = 'list',
    FULL_PAGE = 'full-page',
}

export type PageSettingsType = {
    dashboards: DashboardsListType
    view: PAGE_VIEWS
}

export type PageSettingsByNameType = Partial<Record<APP_ROUTES, PageSettingsType>>
