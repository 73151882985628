import styled from 'styled-components'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { OctupBreathing } from '../../shared/ui/Breathing/OctupBreathing'
import React, { useEffect, useMemo, useState } from 'react'
import { generateGraphMeta } from '../AlternativeHome/AlternativeHomeComponents/utils/alternative-home-graph-util'
import { AlternativeHomeDoughnutChart } from '../AlternativeHome/AlternativeHomeComponents/base-components/AlternativeHomeDoughnutChart'

export const METRIC_ITEM_TYPE = {
    Doughnut: 'Doughnut',
    Text: 'Text',
}

export const setGridLayout = (rows, cols) => {
    return {
        display: 'grid',
        gridTemplateRows: `repeat(${rows},1fr)`,
        gridTemplateColumns: `repeat(${cols},1fr)`,
        alignItems: 'center',
        height: '100%',
        alignSelf: 'center',
        gridColumnGap: '1rem',
        gridRowGap: '.2rem',
    }
}

export const ObjectivesAndInsightsTopMetricItem = ({
    mainTitle,
    isLoading,
    baseData,
    metaDataStyle,
    type,
    numbersFontSize,
    labelsFontSize,
    titleFontSize,
    showSeperator,
}) => {
    const [graphData, setGraphData] = useState(null)
    useEffect(() => {
        if (type === METRIC_ITEM_TYPE.Doughnut && baseData)
            setGraphData(
                generateGraphMeta(
                    baseData?.data,
                    baseData?.queryField,
                    baseData?.requestedLabels,
                    baseData?.includeZero
                )
            )
    }, [baseData])

    const memoizedObjectivesLabels = useMemo(
        () => graphData && graphData?.map((el) => el.title),
        [graphData]
    )

    return (
        <>
            <MetricContainer>
                {isLoading ? (
                    <>
                        <MetricCardTitle>
                            <OctupBreathing
                                width={'24em'}
                                height={'2.1rem'}
                                borderRadius={'40px'}
                                customStyle={{
                                    padding: 0,
                                    margin: 0,
                                    maxHeight: '2.1rem',
                                }}
                            />
                        </MetricCardTitle>
                        {type === METRIC_ITEM_TYPE.Doughnut && (
                            <MetricCardChart style={{ gap: 0, maxHeight: '9rem' }}>
                                <div>
                                    <OctupBreathing
                                        width={'9rem'}
                                        height={'9rem'}
                                        padding={'.2rem 2rem'}
                                    />
                                </div>
                                <div style={{ height: '9rem', marginLeft: '2rem' }}>
                                    <OctupBreathing
                                        width={'10rem'}
                                        height={'2.2rem'}
                                        customStyle={{ padding: '.3rem' }}
                                    />
                                    <OctupBreathing
                                        width={'10rem'}
                                        height={'2.2rem'}
                                        customStyle={{ padding: '.3rem' }}
                                    />
                                    <OctupBreathing
                                        width={'10rem'}
                                        height={'2.2rem'}
                                        customStyle={{ padding: '.3rem' }}
                                    />
                                </div>
                            </MetricCardChart>
                        )}
                        {type === METRIC_ITEM_TYPE.Text && (
                            <TextMetricContainer>
                                <Value>
                                    <OctupBreathing width={'8rem'} height={'5rem'} padding={'0'} />
                                </Value>
                                <Text>
                                    <OctupBreathing
                                        width={'15rem'}
                                        height={'2rem'}
                                        customStyle={{ padding: '0' }}
                                    />
                                </Text>
                            </TextMetricContainer>
                        )}
                    </>
                ) : (
                    <>
                        <MetricCardTitle>{mainTitle}</MetricCardTitle>
                        <MetricCardChart
                            style={{
                                minWidth:
                                    type === METRIC_ITEM_TYPE.Doughnut ? '200px' : 'fit-content',
                            }}
                        >
                            {type === METRIC_ITEM_TYPE.Doughnut && (
                                <ChartInnerContainer>
                                    {graphData && (
                                        <AlternativeHomeDoughnutChart
                                            numbersFontSize={numbersFontSize || '1.7rem'}
                                            labels={memoizedObjectivesLabels}
                                            chartSize={'80px'}
                                            metadata={graphData}
                                            isLoading={isLoading}
                                            colorSet={graphData?.map((el) => el.color)}
                                            labelsFontSize={labelsFontSize || '1.5rem'}
                                            titleFontSize={titleFontSize || '3rem'}
                                            metaDataStyle={
                                                metaDataStyle ||
                                                memoizedObjectivesLabels.length === 1
                                                    ? null
                                                    : memoizedObjectivesLabels.length < 4
                                                      ? setGridLayout(3, 1)
                                                      : setGridLayout(3, 2)
                                            }
                                            lablesColored={false}
                                            metrics={[]}
                                            setSafeMetrics={() => {}}
                                            breakSubTitle={true}
                                            isMetricsLayout={false}
                                        />
                                    )}
                                </ChartInnerContainer>
                            )}
                            {type === METRIC_ITEM_TYPE.Text && (
                                <TextMetricContainer>
                                    <Value>{baseData.value}</Value>
                                    <Text>{baseData.text}</Text>
                                </TextMetricContainer>
                            )}
                        </MetricCardChart>
                    </>
                )}
            </MetricContainer>
            {showSeperator && <Seperator></Seperator>}
        </>
    )
}

const ChartInnerContainer = styled.div`
    width: 12rem;
`

const MetricContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 2rem;
    gap: 1rem;
    //min-width: 300px;
`

const MetricCardTitle = styled.div`
    align-self: flex-start;
    justify-self: flex-start;
    height: 100%;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 1.1rem;
    color: ${OctupColors.primaryTextLight};
`

const MetricCardChart = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
`
const TextMetricContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: flex-start;
`
const Value = styled.div`
    font-weight: 275;
    font-size: 4rem;
    line-height: 7.2rem;
    color: ${OctupColors.primaryTextLight};
    padding-bottom: 0rem;
`

const Text = styled.div`
    font-weight: 400;
    font-size: 1.5rem;
    min-height: 20%;
    line-height: 2rem;
    white-space: nowrap;
    color: #a4a0aa;
`

const Seperator = styled.div`
    border: 0.01rem solid rgba(235, 234, 238, 1);
    align-self: center;
    height: 85%;
`
