import {
    FormattedVal,
    TableContainer,
    TdChangePrecentageStyled,
} from '../MetricsDetailsModal.styles'
import { OctupBaseTable } from '../../../../shared/ui/Tables/OctupBaseTable'
import { octupDictionary } from '../../../../shared/dictionary/dictionary'
import { getInputDate } from '../../../../shared/utils/dates-util'
import { getFormattedVal } from '../../../../shared/utils/metrics-utils'
import MetricTrend from '../../../Metrics/MetricItem/MetricItemComponents/MetricTrend'

export function NoDeepdiveTable({ selectedMetricData, rearrangedMetricArray }) {
    return (
        <TableContainer id={'table-container'}>
            <OctupBaseTable
                hiddenRowKey={'title'}
                showSearchFilter={false}
                customRowStyle={{ height: '5rem' }}
                showFilters={false}
                showCounter={false}
                showSelectedRow={false}
                showDownloadCSV={true}
                tableName={selectedMetricData?.metric?.metricTitle + '_table'}
                sortingHeadersToDataMapping={{
                    date: 'labels',
                    [octupDictionary.titles.metricsTitles[selectedMetricData?.metric?.metricTitle]
                        ?.replace(/\s/g, '')
                        .toLowerCase()]: 'data',
                }}
                defaultSortBy={{ title: 'date' }}
                tableData={{
                    headers: selectedMetricData
                        ? [
                              'date',
                              octupDictionary.titles.metricsTitles[
                                  selectedMetricData?.metric?.metricTitle
                              ],
                          ]
                        : [],
                    rows: rearrangedMetricArray,
                }}
                renderRows={(element) => {
                    return (
                        <>
                            <td>
                                {getInputDate(
                                    element.labels,
                                    selectedMetricData?.metric?.time_slice
                                )}
                            </td>
                            <td>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: '.5rem',
                                        textAlign: 'left',
                                        width: '12rem',
                                    }}
                                >
                                    <FormattedVal>
                                        {getFormattedVal({
                                            val: element.data,
                                            valueType: +selectedMetricData?.metric?.valueUnits,
                                            customNumOfDigits: null,
                                            currency: selectedMetricData?.metric?.currency,
                                            isSupported: selectedMetricData?.metric?.supported,
                                            multiplyBy100: null,
                                            showSymbol: true,
                                        })}
                                    </FormattedVal>
                                    {element.title === selectedMetricData?.metric?.metricTitle && (
                                        <TdChangePrecentageStyled>
                                            <MetricTrend
                                                fontSize={'1.3rem'}
                                                trendValue={parseFloat(element.change)}
                                                trendUnits={'percentage'}
                                                isSupported={true}
                                                isPositiveTrend={parseFloat(element.change) > 0}
                                            />
                                        </TdChangePrecentageStyled>
                                    )}
                                </div>
                            </td>
                        </>
                    )
                }}
            />
        </TableContainer>
    )
}
