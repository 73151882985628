import React from 'react'
import styled from 'styled-components'
import { NoDataInsightsSvg, NoDataObjectivesSvg } from '../../../../shared/assets/icons'
import { CardExpendable } from '../../../../shared/ui/Card/CardExpendable'

export const TYPES = {
    INSIGHTS: 'insights',
    OBJECTIVES: 'objectives',
}

const TYPE = {
    [TYPES.INSIGHTS]: {
        icon: NoDataInsightsSvg,
        text: `There are no insights at the moment,
  We’ll have some meaningful insights for you soon.`,
    },
    [TYPES.OBJECTIVES]: {
        icon: NoDataObjectivesSvg,
        text: `You didn’t create any objectives yet.`,
    },
}

function NoData({ type, style }) {
    let Icon = TYPE[type].icon
    return (
        <CardExpendable height="100%" customStyle={{ ...style }}>
            <NoDataContainer>
                <Icon />
                <Text>{TYPE[type].text}</Text>
            </NoDataContainer>
        </CardExpendable>
    )
}

export default NoData

const NoDataContainer = styled.div`
    width: 100%;
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Text = styled.div`
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 2rem;
`
