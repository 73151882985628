// TODO: remove this component after adding a new Tabs Component
// which supports value and onChange props

// NOTE: forwardRef doesn't need here, will use it in a new tabs component

import { forwardRef, useCallback, useMemo } from 'react'
import { RadioTabsSelectors } from 'shared/ui/RadioTabsSelector/RadioTabsSelectors'
import { getTabs } from './DividedRadioTabs.utils'

const customStyle = { fontSize: '1.5rem', height: '3.5rem' }

export const DividedRadioTabs = forwardRef(({ value: isDivided, onChange }, _) => {
    const handleChange = useCallback(
        (event) => {
            const newValue = +event.target.getAttribute('value')
            onChange(!!newValue)
        },
        [onChange]
    )

    const tabs = useMemo(
        () => getTabs({ isDivided, onChange: handleChange }),
        [handleChange, isDivided]
    )

    return <RadioTabsSelectors customStyle={customStyle} tabs={tabs} />
})
