import { getShortTableDate } from 'shared/utils/dates-util'
import { OctupColors } from 'shared/theme/colors/OctupColors'
import { ShopCurrencyValueContainer } from 'new_containers/components/__common__'

export const ORDERS_TABLE_CELLS = [
    {
        id: 'order_number',
        field: 'order_number',
        fontWeight: 600,
        color: OctupColors.primaryActionableDark,
    },
    {
        id: 'date',
        renderCell: ({ date }) => getShortTableDate(date).primaryFormat,
    },
    {
        id: 'full_name',
        field: 'full_name',
    },
    {
        id: 'destination',
        field: 'destination',
    },
    {
        id: 'value',
        renderCell: ({ value }) => <ShopCurrencyValueContainer value={value} />,
    },
]
