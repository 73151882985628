export function GoogleAdsIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g clipPath="url(#clip0_146_2463)">
                <path
                    d="M8.80598 5.08964C8.99929 4.5822 9.26509 4.11504 9.65977 3.73648C11.2385 2.19806 13.8482 2.58468 14.9194 4.51777C15.7249 5.98369 16.5787 7.4174 17.4083 8.86722L21.5645 16.1002C22.7163 18.1219 21.4678 20.6752 19.1803 21.0215C17.7788 21.2309 16.4659 20.5866 15.741 19.3301L12.0842 12.9831C12.06 12.9348 12.0278 12.8945 11.9956 12.8542C11.8667 12.7495 11.8104 12.5965 11.7298 12.4595L9.03956 7.79596C8.72543 7.24825 8.58045 6.65222 8.59656 6.02397C8.62072 5.70178 8.661 5.3796 8.80598 5.08964Z"
                    fill="#3C8BD9"
                />
                <path
                    d="M8.80598 5.0896C8.73349 5.37956 8.66905 5.66953 8.65294 5.9756C8.62878 6.65218 8.79792 7.28043 9.13622 7.86841L11.7862 12.4514C11.8667 12.5884 11.9312 12.7253 12.0117 12.8542L10.5538 15.3591L8.51601 18.8709C8.48379 18.8709 8.47574 18.8548 8.46768 18.8306C8.45963 18.7662 8.48379 18.7098 8.4999 18.6454C8.83014 17.4372 8.55628 16.3659 7.72666 15.4477C7.21922 14.892 6.57485 14.5778 5.83382 14.4731C4.86727 14.3362 4.01348 14.5859 3.24829 15.19C3.11136 15.2947 3.02276 15.4477 2.86167 15.5283C2.82945 15.5283 2.81334 15.5122 2.80528 15.488L3.95709 13.4824L8.7496 5.1782C8.76571 5.14598 8.78987 5.12182 8.80598 5.0896Z"
                    fill="#FABC04"
                />
                <path
                    d="M2.8375 15.5122L3.29662 15.1014C5.25389 13.555 8.19383 14.6745 8.62072 17.1231C8.72543 17.7111 8.66905 18.2749 8.49185 18.8387C8.48379 18.8871 8.47574 18.9273 8.45963 18.9757C8.38714 19.1045 8.3227 19.2415 8.24216 19.3703C7.52529 20.5544 6.47014 21.1423 5.08474 21.0537C3.49798 20.941 2.24952 19.7489 2.03204 18.1702C1.92733 17.405 2.08037 16.6882 2.47504 16.0277C2.55559 15.8827 2.65225 15.7539 2.74085 15.6089C2.78112 15.5767 2.76501 15.5122 2.8375 15.5122Z"
                    fill="#34A852"
                />
                <path
                    d="M2.8375 15.5122C2.80528 15.5444 2.80528 15.6008 2.7489 15.6089C2.74085 15.5525 2.77307 15.5203 2.80528 15.48L2.8375 15.5122Z"
                    fill="#FABC04"
                />
                <path
                    d="M8.45963 18.9757C8.42742 18.9193 8.45963 18.879 8.49185 18.8387L8.52407 18.871L8.45963 18.9757Z"
                    fill="#E1C025"
                />
            </g>
            <defs>
                <clipPath id="clip0_146_2463">
                    <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>
    )
}
