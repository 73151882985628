import { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TrashSvg } from 'shared/assets/icons'
import { ButtonIcon } from 'shared/ui/Buttons/ButtonIcon'
import { deleteCost } from 'state/features/unitEconomics'
import { RemoveCostModal } from 'components/Modals/UnitEconomicsModals/RemoveCostModal'
import { fetchDeleteCost } from 'state/features/costManager/deletedCostSlice'
import { useToast } from 'hooks'

export const DeleteCostButtonContainer = ({ cost, onSuccess, selectedProduct, ...props }) => {
    const toast = useToast()
    const loadingToastIdRef = useRef()
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false)
    const toggleShowModal = useCallback(() => setShowModal((show) => !show), [])

    const handleClick = useCallback(
        () => (cost.isNew ? dispatch(deleteCost(cost.id)) : toggleShowModal()),
        [dispatch, cost, toggleShowModal]
    )

    const handleSuccess = useCallback(
        async (data) => {
            await onSuccess()
            const { cost_name } = data.meta.arg
            toast.success(`'${cost_name}' cost has been deleted successfully`)
        },
        [onSuccess, toast]
    )

    const handleFail = useCallback(
        (data) => {
            const { cost_name } = data.meta.arg
            const errorMessage =
                data.payload.status === 403
                    ? 'This cost can be deleted from the Cost Manager page only'
                    : `Failed to remove '${cost_name}' cost, please try again`

            toast.error(errorMessage)
        },
        [toast]
    )

    const handleDeleteCost = useCallback(async () => {
        const loadingMessage = `Hold on, we’re deleting '${cost.cost_name}' cost`
        loadingToastIdRef.current = toast.process(loadingMessage)

        const isVariant = !selectedProduct?.variants
        const data = await dispatch(
            fetchDeleteCost({
                cost_id: cost.id || cost.cost_id,
                cost_name: cost.cost_name,
                is_from_variant: !!selectedProduct && isVariant,
                is_from_product: !!selectedProduct && !isVariant,
            })
        )

        const onFinish = data.error ? handleFail : handleSuccess
        await onFinish(data)
        loadingToastIdRef.current && toast.removeToast(loadingToastIdRef.current)
    }, [cost, dispatch, handleFail, handleSuccess, selectedProduct, toast])

    return (
        <>
            <ButtonIcon
                tooltipContent="Delete"
                mainIcon={TrashSvg}
                onClick={handleClick}
                {...props}
            />
            {showModal && (
                <RemoveCostModal
                    costName={cost.cost_name}
                    dismissModalHandler={toggleShowModal}
                    removeProductHandler={handleDeleteCost}
                    onSuccess={handleSuccess}
                />
            )}
        </>
    )
}
