import { NavContent } from './WhatsNewNavContentItems.styles'
import { WhatsNewNavItems } from '../../index'

export const WhatsNewNavContentItems = ({ showPastUpdates, slidesData }) => {
    return Object.keys(slidesData).map(
        (folder, index) =>
            (showPastUpdates || index === 0) && (
                <NavContent>
                    {slidesData[folder].map((item, index) => {
                        return <WhatsNewNavItems {...item} folder={folder} key={index} />
                    })}
                </NavContent>
            )
    )
}
