import styled from 'styled-components'

export const WhatsNewModalTitle = styled.div`
    color: rgba(119, 55, 255, 1);
    font-size: 2rem;
    line-height: 2.7rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 1rem;
`
