import { CardExpendable } from '../../../shared/ui/Card/CardExpendable'
import {
    METRIC_ITEM_TYPE,
    ObjectivesAndInsightsTopMetricItem,
} from '../../../components/ObjectivesAndInsightsTopMetrics/ObjectivesAndInsightsTopMetricItem'
import { octupDictionary } from '../../../shared/dictionary/dictionary'
import { MetricsPaneContainer, PageTopSide } from '../InsightsPage.styles'
import { useSelector } from 'react-redux'
import { getImportanceData, getStatusData } from './InsightsTopSection.utils'
import { useMemo } from 'react'

export function InsightsTopSection() {
    const { insights, isLoading } = useSelector((state) => state.insights)
    const actionablesCount = insights.filter((insight) => insight.is_actionable === 1).length
    const insightsTurned = insights.filter((insight) => insight.objective_id.length > 0).length
    const insightsLength = insights.length
    const turnedToObjectives = Math.floor((insightsTurned * 100) / insightsLength)

    const importanceData = useMemo(() => getImportanceData(insights), [insights])
    const statusData = useMemo(() => getStatusData(insights), [insights])

    return (
        <PageTopSide>
            <CardExpendable customStyle={{ minHeight: '14rem' }}>
                <MetricsPaneContainer>
                    <ObjectivesAndInsightsTopMetricItem
                        mainTitle={octupDictionary.titles.insightsRecived}
                        showSeperator={true}
                        type={METRIC_ITEM_TYPE.Text}
                        isLoading={isLoading}
                        baseData={{
                            text: `${actionablesCount} ${octupDictionary.subtitles.actionable} | ${
                                insightsLength - actionablesCount
                            } Informative`,
                            value: insightsLength,
                        }}
                    />
                    <ObjectivesAndInsightsTopMetricItem
                        mainTitle={octupDictionary.titles.insightsToObjectives}
                        showSeperator={true}
                        type={METRIC_ITEM_TYPE.Text}
                        isLoading={isLoading}
                        baseData={{
                            text: `${insightsTurned} of ${insightsLength} ${octupDictionary.subtitles.insightsToObjectives}`,
                            value: !isNaN(turnedToObjectives) ? turnedToObjectives + '%' : '-',
                        }}
                    />
                    <ObjectivesAndInsightsTopMetricItem
                        mainTitle={'Severity Breakdown'}
                        showSeperator={true}
                        type={METRIC_ITEM_TYPE.Doughnut}
                        isLoading={isLoading}
                        baseData={importanceData}
                    />
                    <ObjectivesAndInsightsTopMetricItem
                        mainTitle={'Status Breakdown'}
                        showSeperator={false}
                        isLoading={isLoading}
                        type={METRIC_ITEM_TYPE.Doughnut}
                        baseData={statusData}
                    />
                </MetricsPaneContainer>
            </CardExpendable>
        </PageTopSide>
    )
}
