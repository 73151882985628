import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { DashboardFormType, DashboardMetadataType, EditDashboardType } from 'models/dashboards'
import { createDashboard, editDashboard } from 'services/dashboards'
import type { ThunkAPIConfigType } from 'services/utils'

type UpdateDashboardStateType = {
    data?: DashboardMetadataType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: UpdateDashboardStateType = {
    data: undefined,
    isLoading: false,
    error: undefined,
}

export const fetchUpdateDashboard = createAsyncThunk<
    DashboardMetadataType,
    DashboardFormType,
    ThunkAPIConfigType
>('fetchUpdateDashboard', async (data, thunkAPI) => {
    const editDashboardData = data as EditDashboardType
    if (editDashboardData.id) return editDashboard(editDashboardData, thunkAPI)
    return createDashboard(data, thunkAPI)
})

export const updateDashboardSlice = createSlice({
    name: 'updateDashboardSlice',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUpdateDashboard.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchUpdateDashboard.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchUpdateDashboard.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const updateDashboardReducer = updateDashboardSlice.reducer
