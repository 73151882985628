import React from 'react'

export function NoDataInsightsSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
        >
            <path
                d="M37.2284 10H11.7738C11.0709 10 10.5011 10.5698 10.5011 11.2727V36.7273C10.5011 37.4302 11.0709 38 11.7738 38H37.2284C37.9313 38 38.5011 37.4302 38.5011 36.7273V11.2727C38.5011 10.5698 37.9313 10 37.2284 10Z"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5 29.0908H16.3365C16.5036 29.0908 16.6691 29.1237 16.8235 29.1877C16.9779 29.2517 17.1182 29.3454 17.2364 29.4636L20.309 32.5362C20.4272 32.6544 20.5675 32.7482 20.7219 32.8121C20.8764 32.8761 21.0419 32.909 21.209 32.909H27.791C27.9581 32.909 28.1236 32.8761 28.2781 32.8121C28.4325 32.7482 28.5728 32.6544 28.691 32.5362L31.7636 29.4636C31.8818 29.3454 32.0221 29.2517 32.1765 29.1877C32.3309 29.1237 32.4964 29.0908 32.6635 29.0908H38.5"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
