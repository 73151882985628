import axios from 'axios'
import { GLOBAL_CONFIG } from 'config'
import { generateAPIConfig } from 'services/utils'

export const ATTRIBUTION_API_URL = GLOBAL_CONFIG.BASE_URL + 'metrics/attribution'

/**
 * get attribution data
 * @start_date mandatory
 * @end_date mandatory
 */

export const getAttribution = async (data, thunkAPI) => {
    const config = generateAPIConfig({
        thunkAPI,
        params: {
            cost_name: data.costName,
            start_date: data.startDate,
        },
    })

    const response = await axios.get(ATTRIBUTION_API_URL, config)
    return response.data
}
