import styled from 'styled-components'
import { MetricItemWithChart } from '../../../components/Metrics/MetricItem/MetricItemWithChart'
import { OctupColors } from '../../theme/colors/OctupColors'
import { CardBase } from '../../ui/Card/CardBase'
import { ChartsArea } from '../../ui/Charts/ChartsArea'

const renderChart = ({
    showChart,
    chartData,
    valueUnits,
    chartLabels,
    isFilled = false,
    currency = '',
    isPercent = false,
    timeSlice,
}) => {
    if (showChart) {
        return (
            <ChartContainer>
                <Wrapper width={window.innerWidth / 52}>
                    <ChartsArea
                        showGradient
                        showXAxis={true}
                        showYAxis={false}
                        showSecondYAxis={false}
                        isFilled={isFilled}
                        chartsDataSets={[{ data: chartData, label: '' }]}
                        labels={chartLabels}
                        timeSlice={timeSlice}
                        showLabels={false}
                        showTarget={true}
                        showLegend={false}
                        currency={currency}
                        isPercent={isPercent}
                        valueUnits={valueUnits}
                    />
                </Wrapper>
            </ChartContainer>
        )
    }
}

export const CardValueWithCharts = ({
    sources,
    title,
    description,
    value,
    trend,
    showChart,
    chartData,
    chartLabels,
    timeSlice,
    isLoading,
    isRejected,
    currency,
    valueUnits,
    direction,
    isDensed,
    showCard = true,
    isFilled = false,
    showSeperator = false,
    metricTitleKey,
    trendUnits,
    trendValue,
    isPositiveTrend,
    setShowModalDetails,
    setSelectedMetric,
    segment,
    isSupported,
    isStale,
    lastUpdatedAt,
    enableDeepdive,
}) => {
    const renderMainContainerContent = () => {
        return (
            <MainContainer>
                <SeperatorContainer showSeperator={showSeperator}>
                    <InfoContainer>
                        {!isRejected && (
                            <MetricItemWithChart
                                id={metricTitleKey}
                                trendUnits={trendUnits}
                                trendValue={trendValue}
                                hasDataset={chartData && chartData && chartData.length > 0}
                                isPositiveTrend={isPositiveTrend}
                                isLoading={isLoading}
                                key={title}
                                title={title}
                                description={description}
                                value={value}
                                trend={trend}
                                sources={sources}
                                currency={currency}
                                valueUnits={valueUnits}
                                direction={direction}
                                showSeperator={false}
                                isDensed={isDensed}
                                setSelectedMetric={setSelectedMetric}
                                setShowModalDetails={setShowModalDetails}
                                segment={segment}
                                isSupported={isSupported}
                                isStale={isStale}
                                lastUpdatedAt={lastUpdatedAt}
                                enableDeepdive={enableDeepdive}
                                renderChart={() =>
                                    chartData &&
                                    chartData.length > 0 &&
                                    renderChart({
                                        showChart,
                                        chartData,
                                        chartLabels,
                                        valueUnits,
                                        isFilled,
                                        currency,
                                        isPercent: `${valueUnits}` === '2',
                                        timeSlice,
                                    })
                                }
                            />
                        )}
                    </InfoContainer>
                </SeperatorContainer>
            </MainContainer>
        )
    }

    return (
        <>
            {showCard ? (
                <CardBase style={{ maxHeight: '20rem' }} height={'100%'}>
                    {renderMainContainerContent()}
                </CardBase>
            ) : (
                <>{renderMainContainerContent()}</>
            )}
        </>
    )
}

const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    min-width: 20rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const SeperatorContainer = styled.div`
    border-bottom: ${(p) => p.showSeperator && `0.15rem solid ${OctupColors.seperator}`};
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    display: flex;
    min-height: 14rem;
    padding-bottom: 2rem;
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const ChartContainer = styled.div``

const Wrapper = styled.div`
    //margin-bottom: 9rem;
    padding-right: 2rem;
    height: 100%;
`
