//  TODO: separate on dumb and smart component

import { Stack } from '@mui/material'
import { CostModalContainer, DeleteCostButtonContainer } from 'new_containers/components/__common__'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { EditIconSvg } from 'shared/assets/icons'
import { ButtonIcon } from 'shared/ui/Buttons/ButtonIcon'
import { fetchCosts } from 'state/features/costManager'

export const RowActionsContainer = ({ row }) => {
    const dispatch = useDispatch()
    const [showCostModal, setShowCostModal] = useState(false)
    const [selectedCost, setSelectedCostItem] = useState(null)
    const toggleCostModal = useCallback(() => setShowCostModal((open) => !open), [])
    const handleUpdateData = useCallback(() => dispatch(fetchCosts()), [dispatch])

    const handleEditClick = useCallback(() => {
        setSelectedCostItem(row)
        toggleCostModal()
    }, [row, toggleCostModal])

    return (
        <>
            <Stack
                direction="row"
                flexWrap="nowrap"
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
                className="tbl-row-action-btns"
            >
                <ButtonIcon
                    mainIcon={EditIconSvg}
                    tooltipContent={'Edit'}
                    disabled={row.variants?.length > 0}
                    onClick={handleEditClick}
                />
                <DeleteCostButtonContainer
                    cost={row}
                    disabled={row.source?.toLowerCase() === 'file'}
                    onSuccess={handleUpdateData}
                />
            </Stack>
            {showCostModal && (
                <CostModalContainer
                    showCostModal={showCostModal}
                    selectedEl={selectedCost}
                    isEditState
                    onClose={toggleCostModal}
                    onSuccess={handleUpdateData}
                />
            )}
        </>
    )
}
