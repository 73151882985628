// TODO: REUSABLE, move into the right place

import { ShopifyIcon } from '../../../shared/assets/images/third-party-icons/ShopiftIcon'
import { FacebookIcon } from '../../../shared/assets/images/third-party-icons/FacebookIcon'
import { GoogleAdsIcon } from '../../../shared/assets/images/third-party-icons/GoogleAdsIcon'
import { TikTokIcon } from '../../../shared/assets/images/third-party-icons/TikTokIcon'
import { PDQLogisticsIcon } from '../../../shared/assets/images/third-party-icons/PDQLogisticsIcon'
import {
    ActionsBtnInventoryIcon,
    ActionsBtnLastMileIcon,
    ActionsBtnOnlineStoreIcon,
    ActionsBtnOperationIcon,
    ActionsBtnProductIcon,
    ActionsBtnShippingIcon,
    ActionsBtnUnitEconomicsIcon,
} from '../../../shared/assets/icons'
// import { RakutenIcon } from "../../../shared/assets/images/third-party-icons/ShipNetworkIcon.js";
import { ShipNetworkIcon } from '../../../shared/assets/images/third-party-icons/ShipNetworkIcon.js'
import { UserIconSvg } from '../../../shared/assets/icons/UserIconSvg'
import { GoogleAnalyticsIcon } from '../../../shared/assets/images/third-party-icons/GoogleAnalyticsIcon'
import { GeneralIcon } from '../../../shared/assets/images/third-party-icons/GeneralIcon'
import { AdrollIcon } from '../../../shared/assets/images/third-party-icons/AdrollIcon'
import { MailChimpIcon } from '../../../shared/assets/images/third-party-icons/MailChimpIcon'
import { ShipstationIcon } from '../../../shared/assets/images/third-party-icons/ShipstationIcon'
import { AftershipIcon } from '../../../shared/assets/images/third-party-icons/AftershipIcon'
import { UPSIcon } from '../../../shared/assets/images/third-party-icons/UPSIcon'
import { InstagramIcon } from '../../../shared/assets/images/third-party-icons/InstagramIcon'
import { JIRAIcon } from '../../../shared/assets/images/third-party-icons/JIRAIcon'
import { GorgiasIcon } from '../../../shared/assets/images/third-party-icons/GorgiasIcon'
import { EmailIcon } from '../../../shared/assets/images/third-party-icons/EmailIcon'
import { KlaviyoIcon } from '../../../shared/assets/images/third-party-icons/KlaviyoIcon'
import { GoogleIcon } from '../../../shared/assets/images/third-party-icons/GoogleIcon'
import { GoogleMarketingIcon } from '../../../shared/assets/images/third-party-icons/GoogleMarketingIcon'
import { AmazonIcon } from '../../../shared/assets/images/third-party-icons/AmazonIcon'
import { LogiwaIcon } from '../../../shared/assets/images/third-party-icons/LogiwaIconSvg'
import { DefaultIntegraionIcon } from '../../../shared/assets/icons/DefaultIntegrationIcon'
import { AmazonAdsIcon } from '../../../shared/assets/images/third-party-icons/AmazonAdsIcon.js'
import { ZendeskIcon } from '../../../shared/assets/images/third-party-icons/ZendeskIcon.js'
import { ReturnGoIcon } from '../../../shared/assets/images/third-party-icons/ReturnGoIcon.js'

export const iconMappingWithArgs = (integrationName, args) => {
    switch (integrationName) {
        case 'googleanalytics':
            return <GoogleAnalyticsIcon size={args.size} />
        case 'googleads':
            return <GoogleAdsIcon size={args.size} />
        case 'facebook':
            return <FacebookIcon size={args.size} />
        case 'shipnetwork' || 'rakuten':
            return <ShipNetworkIcon size={args.size} />
        case 'adroll':
            return <AdrollIcon size={args.size} />
        case 'tiktok':
            return <TikTokIcon size={args.size} />
        case 'mailchimp':
            return <MailChimpIcon size={args.size} />
        case 'shipstation':
            return <ShipstationIcon size={args.size} />
        case 'aftership':
            return <AftershipIcon size={args.size} />
        case 'general':
            return <GeneralIcon size={args.size} />
        case 'ups':
            return <UPSIcon size={args.size} />
        case 'instagram':
            return <InstagramIcon size={args.size} />
        case 'jira':
            return <JIRAIcon size={args.size} />
        case 'gorgias':
            return <GorgiasIcon size={args.size} />
        case 'email':
            return <EmailIcon size={args.size} />
        case 'klaviyo':
            return <KlaviyoIcon size={args.size} />
        case 'google':
            return <GoogleIcon size={args.size} />
        case 'shopify':
            return <ShopifyIcon size={args.size} />
        case 'amazon':
            return <AmazonIcon size={args.size} />
        case 'pdq_logistics':
            return <PDQLogisticsIcon size={args.size} />
        case 'manual':
            return <UserIconSvg size={args.size} customColor="red" />
        default:
            return null // Return null or a default icon if the integrationName is not recognized
    }
}

export const iconsMapping = {
    googleanalytics: <GoogleAnalyticsIcon />,
    googleads: <GoogleAdsIcon />,
    facebook: <FacebookIcon />,
    shipnetwork: <ShipNetworkIcon />,
    adroll: <AdrollIcon />,
    tiktok: <TikTokIcon />,
    mailchimp: <MailChimpIcon />,
    shipstation: <ShipstationIcon />,
    aftership: <AftershipIcon />,
    general: <GeneralIcon />,
    ups: <UPSIcon />,
    instagram: <InstagramIcon />,
    jira: <JIRAIcon />,
    gorgias: <GorgiasIcon />,
    email: <EmailIcon />,
    klaviyo: <KlaviyoIcon />,
    google: <GoogleIcon />,
    shopify: <ShopifyIcon />,
    rakuten: <ShipNetworkIcon />,
    amazon: <AmazonIcon />,
    logiwa: <LogiwaIcon />,
    default: <DefaultIntegraionIcon />,
    shipping: <ActionsBtnShippingIcon />,
    inventory: <ActionsBtnInventoryIcon />,
    product: <ActionsBtnProductIcon />,
    manual: <UserIconSvg />,
    lastmile: <ActionsBtnLastMileIcon />,
    onlinestore: <ActionsBtnOnlineStoreIcon />,
    uniteconomics: <ActionsBtnUnitEconomicsIcon />,
    operations: <ActionsBtnOperationIcon />,
    pdq_logistics: <PDQLogisticsIcon />,
}

export const iconsMappingRaw = {
    googleanalytics: GoogleAnalyticsIcon,
    googleads: GoogleAdsIcon,
    facebook: FacebookIcon,
    shipnetwork: ShipNetworkIcon,
    adroll: AdrollIcon,
    tiktok: TikTokIcon,
    mailchimp: MailChimpIcon,
    shipstation: ShipstationIcon,
    rakuten: ShipNetworkIcon,
    aftership: AftershipIcon,
    after_ship: AftershipIcon,
    general: GeneralIcon,
    ups: UPSIcon,
    instagram: InstagramIcon,
    jira: JIRAIcon,
    gorgias: GorgiasIcon,
    email: EmailIcon,
    klaviyo: KlaviyoIcon,
    google: GoogleIcon,
    shopify: ShopifyIcon,
    amazon: AmazonIcon,
    amazonads: AmazonAdsIcon,
    googlemarketing: GoogleMarketingIcon,
    logiwa: LogiwaIcon,
    default: DefaultIntegraionIcon,
    shipping: ActionsBtnShippingIcon,
    manual: UserIconSvg,
    lastmile: ActionsBtnLastMileIcon,
    onlinestore: ActionsBtnOnlineStoreIcon,
    uniteconomics: ActionsBtnUnitEconomicsIcon,
    operations: ActionsBtnOperationIcon,
    returngo: ReturnGoIcon,
    zendesk: ZendeskIcon,
    pdq_logistics: PDQLogisticsIcon,
}

export const SVGIcon = (source, size) => {
    let Icon = iconsMapping[`${source}`] || iconsMapping['default']
    return <Icon size={size} />
}
