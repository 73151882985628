export function EmailIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <circle cx="12" cy="12" r="10" fill="url(#paint0_linear_254_9705)" />
            <path
                d="M7 15.75H17V12V8.25H12H7V12V15.75Z"
                fill="white"
                stroke="white"
                strokeLinejoin="round"
            />
            <path
                d="M7 8.25L12 12L17 8.25"
                stroke="#0E84E9"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12 8.25H7V12" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17 12V8.25H12" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient
                    id="paint0_linear_254_9705"
                    x1="7.5"
                    y1="3"
                    x2="12"
                    y2="22"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0069E3" />
                    <stop offset="1" stopColor="#47F4FF" />
                </linearGradient>
            </defs>
        </svg>
    )
}
