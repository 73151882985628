import type { Theme } from '@mui/material'

export const buttonStyle = (theme: Theme) => ({
    fontWeight: 400,
    '&.opened': {
        boxShadow: theme.shadows[4],
        '& .MuiSvgIcon-root': {
            transform: 'rotate(180deg)',
        },
    },
})
