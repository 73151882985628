import { OctupBreathing } from '../../shared/ui/Breathing/OctupBreathing'
import { BreathingContainer } from '../AlternativeHome/AlterativeHome.styles'

export function CardsShimmer({ quantity, width, height, style }) {
    let shimmersArray = []
    while (shimmersArray.length < quantity) {
        shimmersArray.push(1)
    }

    return (
        <BreathingContainer style={{ ...style }}>
            {shimmersArray.map((_, index) => (
                <OctupBreathing key={index} width={width} height={height}></OctupBreathing>
            ))}
        </BreathingContainer>
    )
}
