import { intercom } from 'utils'
import {
    InvoicesRequestContainer,
    NoDataFallbackContainer,
} from 'new_containers/components/__common__'
import { octupDictionary } from 'shared/dictionary/dictionary'
import { Text } from 'shared/styledComponents'
import { PreviewSvg } from 'shared/assets/images/logistics'
import { Grid } from '@mui/material'
import { imageContainerStyle } from './NoWarehousesFallback.styles'

export const NoWarehousesFallback = () => (
    <NoDataFallbackContainer
        showIcon={false}
        title={octupDictionary.titles.logistics.noWarehouses}
        text={octupDictionary.messages.logistics.warehouseSetup}
        buttonTitle={octupDictionary.titles.logistics.warehouseSetupRequest}
        onClick={intercom.showNewMessage}
        subText={
            <InvoicesRequestContainer
                size="small"
                showEmail
                spacing={1}
                textAlign="center"
                justifyContent="center"
                primaryText="Get us going on setting up your warehouse by sending us your warehouse invoices to"
            />
        }
    >
        <Grid container justifyContent="center" rowSpacing="3rem">
            <Grid item xs={12}>
                <Text textAlign="center" fontSize="1.5rem">
                    {octupDictionary.messages.logistics.preview}
                </Text>
            </Grid>
            <Grid item md={12} lg={11} xl={9.5} sx={imageContainerStyle}>
                <PreviewSvg />
            </Grid>
        </Grid>
    </NoDataFallbackContainer>
)
