import { GLOBAL_CONFIG } from 'config'

export const DISCONNECT_PATH = `${GLOBAL_CONFIG.BASE_URL}auth/disconnect`

export const DEFAULT_VALUES = {
    isKeepData: true,
}

export const getActionButtons = ({ onCancel }) => [
    {
        title: 'Cancel',
        type: 'button',
        variant: 'secondary',
        disabled: false,
        onClick: onCancel,
    },
    {
        title: 'Disconnect',
        type: 'submit',
        variant: 'contained',
        disabled: false,
    },
]
