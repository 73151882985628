import React from 'react'
import { getIconColor } from './utils'

export function FullfilledSvg({ active, customActiveColor = '#007F82', customColor = '#007F82' }) {
    return (
        <svg
            width="91"
            height="20"
            viewBox="0 0 91 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="91" height="20" rx="10" fill="#04CE72" fillOpacity="0.08" />
            <path
                d="M19.9644 6.66667L13.9325 13.3333L11.3333 10.0002"
                stroke="#078344"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.9644 6.66667L18.9326 13.3333L18 12.0833"
                stroke="#078344"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M37 7.02V8.15H34.09V9.94H36.32V11.05H34.09V14H32.69V7.02H37ZM39.8727 7.02V11.34C39.8727 11.8133 39.9961 12.1767 40.2427 12.43C40.4894 12.6767 40.8361 12.8 41.2827 12.8C41.7361 12.8 42.0861 12.6767 42.3327 12.43C42.5794 12.1767 42.7027 11.8133 42.7027 11.34V7.02H44.1127V11.33C44.1127 11.9233 43.9827 12.4267 43.7227 12.84C43.4694 13.2467 43.1261 13.5533 42.6927 13.76C42.2661 13.9667 41.7894 14.07 41.2627 14.07C40.7427 14.07 40.2694 13.9667 39.8427 13.76C39.4227 13.5533 39.0894 13.2467 38.8427 12.84C38.5961 12.4267 38.4727 11.9233 38.4727 11.33V7.02H39.8727ZM47.3654 12.89H49.6654V14H45.9654V7.02H47.3654V12.89ZM55.3652 7.02V8.15H52.4552V9.94H54.6852V11.05H52.4552V14H51.0552V7.02H55.3652ZM58.258 7.02V14H56.858V7.02H58.258ZM61.5412 12.89H63.8412V14H60.1412V7.02H61.5412V12.89ZM66.631 12.89H68.931V14H65.231V7.02H66.631V12.89ZM71.7209 8.15V9.9H74.0709V11.01H71.7209V12.86H74.3709V14H70.3209V7.01H74.3709V8.15H71.7209ZM78.5831 7.02C79.3165 7.02 79.9598 7.16333 80.5131 7.45C81.0731 7.73667 81.5031 8.14667 81.8031 8.68C82.1098 9.20667 82.2631 9.82 82.2631 10.52C82.2631 11.22 82.1098 11.8333 81.8031 12.36C81.5031 12.88 81.0731 13.2833 80.5131 13.57C79.9598 13.8567 79.3165 14 78.5831 14H76.1431V7.02H78.5831ZM78.5331 12.81C79.2665 12.81 79.8331 12.61 80.2331 12.21C80.6331 11.81 80.8331 11.2467 80.8331 10.52C80.8331 9.79333 80.6331 9.22667 80.2331 8.82C79.8331 8.40667 79.2665 8.2 78.5331 8.2H77.5431V12.81H78.5331Z"
                fill="#078344"
            />
        </svg>
    )
}
