import styled from 'styled-components'
import { DownTriangleArrowSvg } from '../../assets/icons/DownTriangleArrowSvg'

export const OctupTabItem = ({ title, isActive, count, clickHandler }) => {
    return (
        <OctupTabItemContainer
            onClick={() => {
                clickHandler()
            }}
        >
            <TabTitle isActive={isActive} style={{ textDecoration: isActive ? 'underline' : '' }}>
                {title} {(count || count === 0) && `(${count})`}
            </TabTitle>
            {/*<TabArrow>*/}
            {/*  {isActive && <DownTriangleArrowSvg customColor={"#007f82"} />}*/}
            {/*</TabArrow>*/}
        </OctupTabItemContainer>
    )
}

const OctupTabItemContainer = styled.div`
    text-align: center;
    position: relative;
    min-height: 5rem;
    cursor: pointer;
    padding-top: 2rem;
`

const TabArrow = styled.div`
    position: absolute;
    top: 4.5rem;
    left: 50%;
    transform: translateX(-50%);
`

const TabTitle = styled.div`
    color: ${(props) => (props.isActive ? '#007f82' : '#504B5A')};
    font-size: 1.7rem;
    line-height: 2.2rem;
    font-weight: ${(props) => (props.isActive ? '600' : '500')};
    text-transform: capitalize;
    padding-bottom: 1rem;
    text-underline-offset: 0.8rem;
    text-decoration-thickness: 0.3rem !important;
    width: 100%;
    white-space: nowrap;
`
