import { OctupBaseModal } from '../../../shared/ui/Modal/OctupBaseModal'
import styled from 'styled-components'
import Dropdown from '../../../shared/ui/Dropdown/Dropdown'
import { useEffect, useState } from 'react'
import { compareStrings, HOME_TABLES_TYPES } from '../../../shared/utils/strings-util'
import { getShortTableDate } from '../../../shared/utils/dates-util'
import { HomeActionableItems } from '../../HomeActionableButtons/HomeActionableButtons'
import { ChartsArea } from '../../../shared/ui/Charts/ChartsArea'
import { AutoStudioLogoSvg } from '../../../shared/assets/icons/AutoStudioLogoSvg'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'

function createObjectArray(dataArray, labelsArray) {
    // create an empty array to hold the objects
    let objectArray = []

    // loop through the data array and create an object for each index
    for (let i = 0; i < dataArray.length && i < labelsArray.length; i++) {
        // create an object with data and label properties for this index
        let object = {
            data: dataArray[i],
            label: labelsArray[i],
        }

        // add the object to the object array
        objectArray.push(object)
    }

    // return the object array
    return objectArray
}

export function DislikeInsightModal({ dismissModalhandler, showOnHoldModal }) {
    const buttons = [
        {
            title: 'Cancel',
            type: 'button',
            variant: 'secondary',
            disabled: true,
            onClick: () => {
                dismissModalhandler()
                showOnHoldModal && showOnHoldModal.onCancel && showOnHoldModal.actionFn()
            },
        },
        {
            title: 'Submit ',
            type: 'button',
            variant: 'contained',
            disabled: true,
            onClick: () => {
                alert('save click')
                dismissModalhandler()
                showOnHoldModal && showOnHoldModal.onSubmit && showOnHoldModal.actionFn()
            },
        },
    ]
    const [showTextarea, setShowTextarea] = useState(false)
    useEffect(() => {
        showTextarea && document.getElementById('myInput').focus()
    }, [showTextarea])
    return (
        <form>
            <OctupBaseModal
                onDismissModal={dismissModalhandler}
                buttons={buttons}
                title={"Didn't like this insight?"}
            >
                <DislikeInsightModalContainerStyled showTextarea={showTextarea}>
                    <ModalBodyContainer>
                        <InsightsNotLiked>
                            Why is this insight not relevant to you?
                            <Dropdown
                                title={'Select the most accurate reason'}
                                titlePostfix=""
                                titlePrefix=""
                                isMultiSelection={false}
                                showClearFilters={false}
                                onClickCallback={(data) => {
                                    setShowTextarea(
                                        data.payload.length > 0 &&
                                            compareStrings(data.payload[0], 'Other reason')
                                    )
                                }}
                                options={[
                                    { name: 'You do not talk about fight club' },
                                    { name: 'You DO NOT talk about fight club!' },
                                    {
                                        name: 'if someone yells “stop!”, goes limp, or taps out, the fight is over',
                                    },
                                    { name: 'only two guys to a fight' },
                                    { name: 'one fight at a time, fellas' },
                                    {
                                        name: 'the fights are bare knuckle. No shirt, no shoes, no weapons.',
                                    },
                                    { name: 'fights will go on as long as they have to.' },
                                    {
                                        name: 'if this is your first time at Fight Club, you have to fight.',
                                    },
                                    {
                                        name: 'Other reason',
                                    },
                                ]}
                            />
                            {showTextarea && <input id="myInput" placeholder="Type your own" />}
                            <span style={{ paddingTop: '1rem', fontSize: 'inherit' }}>
                                Who is the person(s) better suited for this insight?
                            </span>
                            <Dropdown
                                title={'Select team memebers'}
                                titlePostfix=""
                                titlePrefix=""
                                isMultiSelection={true}
                                options={[
                                    { name: 'Tyler Durden' },
                                    { name: 'Marla Signer' },
                                    { name: 'Robert Polson' },
                                ]}
                            />
                        </InsightsNotLiked>
                    </ModalBodyContainer>
                </DislikeInsightModalContainerStyled>
            </OctupBaseModal>
        </form>
    )
}

export function SnoozeInsightModal({ dismissModalhandler, showOnHoldModal }) {
    const buttons = [
        {
            title: 'cancel',
            type: 'button',
            variant: 'secondary',
            disabled: true,
            onClick: () => {
                dismissModalhandler()
                showOnHoldModal && showOnHoldModal.onCancel && showOnHoldModal.actionFn()
            },
        },
        {
            title: 'Snooze',
            type: 'button',
            variant: 'contained',
            disabled: true,
            onClick: () => {
                alert('Snoozed')
                dismissModalhandler()
                showOnHoldModal && showOnHoldModal.onSubmit && showOnHoldModal.actionFn()
            },
        },
    ]
    return (
        <form>
            <OctupBaseModal
                onDismissModal={dismissModalhandler}
                buttons={buttons}
                title={'Snooze this Insight'}
            >
                <SnoozeInsightModalContainerStyled>
                    <ModalBodyContainer>
                        <FixedElementsStyled>
                            Reminder me in{' '}
                            <Dropdown
                                isMultiSelection={false}
                                showClearFilters={false}
                                deafultInitialValue={{ name: '1 day' }}
                                options={[
                                    { name: '1 day' },
                                    { name: '3 days' },
                                    { name: '1 week' },
                                ]}
                            />
                        </FixedElementsStyled>
                        <InfoStyled>
                            It will be removed from the insights list and will show up again
                            according to your selection.{' '}
                        </InfoStyled>
                    </ModalBodyContainer>
                </SnoozeInsightModalContainerStyled>
            </OctupBaseModal>
        </form>
    )
}

export function ShareInsightModal({ dismissModalhandler, showOnHoldModal }) {
    const buttons = [
        {
            title: 'cancel',
            type: 'button',
            variant: 'secondary',
            disabled: true,
            onClick: () => {
                dismissModalhandler()
                showOnHoldModal && showOnHoldModal.onCancel && showOnHoldModal.actionFn()
            },
        },
        {
            title: 'Share',
            type: 'button',
            variant: 'contained',
            disabled: true,
            onClick: () => {
                alert('Shared')
                dismissModalhandler()
                showOnHoldModal && showOnHoldModal.onSubmit && showOnHoldModal.actionFn()
            },
        },
    ]
    return (
        <form>
            <OctupBaseModal
                onDismissModal={dismissModalhandler}
                buttons={buttons}
                title={'Share this insight'}
            >
                <ShareInsightModalContainerStyled>
                    <ModalBodyContainer>
                        <FixedElementsStyled>
                            <Dropdown
                                title="Select Team members"
                                titlePostfix=""
                                isMultiSelection={true}
                                options={[
                                    { name: 'Tyler Durden' },
                                    { name: 'Marla Signer' },
                                    { name: 'Robert Polson' },
                                    { name: 'The Naarator' },
                                ]}
                            />
                        </FixedElementsStyled>
                        <InfoStyled>
                            Shared members wil receive a notification about this insight.
                        </InfoStyled>
                    </ModalBodyContainer>
                </ShareInsightModalContainerStyled>
            </OctupBaseModal>
        </form>
    )
}

export function InsightDetailsModal({
    userInfo,
    dismissModalhandler,
    selectedItem,
    setShowSnoozeInsightModal,
    setShowDismissInsightModal,
    setShowDislikeInsightModal,
    setShowShareInsightModal,
    setShowInsightDetailsModal,
    setShowEditObjectiveModal,
    setShowDismissObjectiveModal,
    showButtons,
}) {
    const buttons = [
        {
            title: 'Automate This',
            type: 'button',
            variant: 'secondary',
            disabled: true,
            iconLeft: AutoStudioLogoSvg,
            onClick: () => {
                dismissModalhandler()
            },
        },
        {
            title: 'Create Objective',
            type: 'button',
            variant: 'contained',
            disabled:
                selectedItem &&
                (!selectedItem?.is_actionable === 1 ||
                    selectedItem?.vstatus?.toLowerCase() === 'dismissed'),
            iconLeft: AutoStudioLogoSvg,
            onClick: () => {
                setShowEditObjectiveModal({ show: true, isEditState: true })
                setShowInsightDetailsModal({ show: false, isEditState: false })
            },
        },
    ]
    const [isLike, setIsLike] = useState(!!selectedItem.isLike)
    const [graphLabels, setGraphLables] = useState()
    const [graphData, setGraphData] = useState()
    const octupDispatch = useOctupDispatch()

    useEffect(() => {
        octupDispatch.insights.setSelectedInsight(selectedItem?.id)
    }, [])

    const dismissModalWrapper = async (dismissModalhandler) => {
        dismissModalhandler()
    }

    useEffect(() => {
        setGraphLables([...selectedItem?.dataset?.map((el) => el[0])])
        setGraphData([...selectedItem?.dataset?.map((el) => el[1])])
    }, [selectedItem.dataset])

    return (
        <OctupBaseModal
            onDismissModal={() => dismissModalWrapper(dismissModalhandler)}
            buttons={buttons}
            showButtons={
                selectedItem?.isActionable &&
                selectedItem?.vstatus?.toLowerCase() !== 'dismissed' &&
                selectedItem?.objectives &&
                selectedItem?.objectives.length !== 0 &&
                showButtons
            }
            title={'Insight Details'}
        >
            <InsightsDetailsModalContainer>
                <ModalBodyContainer>
                    <InsightDetailsModalHeaderContainer>
                        <InsightDetailsModalDateStyled>
                            {getShortTableDate(selectedItem?.created_at)?.month_and_day},{' '}
                            {getShortTableDate(selectedItem?.created_at)?.year}
                        </InsightDetailsModalDateStyled>
                        <InsightDetailsModalSegmentStyled>
                            {selectedItem.segment}
                        </InsightDetailsModalSegmentStyled>

                        {selectedItem?.vstatus?.toLowerCase() !== 'dismissed' && (
                            <ActionableButtonsContainer>
                                <HomeActionableItems
                                    isLike={isLike}
                                    data={selectedItem}
                                    setIsLike={setIsLike}
                                    activeTable={HOME_TABLES_TYPES.INSIGHTS}
                                    setShowSnoozeInsightModal={setShowSnoozeInsightModal}
                                    setShowDismissInsightModal={setShowDismissInsightModal}
                                    setShowDislikeInsightModal={setShowDislikeInsightModal}
                                    setShowShareInsightModal={setShowShareInsightModal}
                                    setShowInsightDetailsModal={setShowInsightDetailsModal}
                                    setShowEditObjectiveModal={setShowEditObjectiveModal}
                                    setShowDismissObjectiveModal={setShowDismissObjectiveModal}
                                    dismissModalhandler={dismissModalhandler}
                                />
                            </ActionableButtonsContainer>
                        )}
                    </InsightDetailsModalHeaderContainer>
                    <InsightDetailsModalBodyContainer>
                        <InsightDetailsModalItemTitle>
                            {selectedItem.title}
                        </InsightDetailsModalItemTitle>
                        <InsightDetailsModalItemDetails>
                            {selectedItem?.right_pane || selectedItem.subtitle}
                        </InsightDetailsModalItemDetails>
                        <div>
                            <InnerContentTitle>How did we get to this insight?</InnerContentTitle>
                            <InnerContentBox>
                                {graphData && graphLabels && (
                                    <ChartsArea
                                        showGradient
                                        style={{
                                            minHeight: '20vh',
                                            minWidth: '100%',
                                            maxWidth: '100%',
                                        }}
                                        showXAxis={true}
                                        showYAxis={true}
                                        isFilled={false}
                                        showSeriesNameInTooltip={false}
                                        chartsDataSets={[
                                            {
                                                data: graphData,
                                                label: graphLabels,
                                            },
                                        ]}
                                        labels={graphLabels}
                                        showTarget={false}
                                        showLegend={false}
                                    />
                                )}
                            </InnerContentBox>
                        </div>
                        {/*
                        @TODO: waiting for BE
                             <InsightDetailsModalRecomendations>
                                 <InsightDetailsModalItemTitle>
                                     Octup recommendation
                                 </InsightDetailsModalItemTitle>
                                 <InsightDetailsModalItemDetails>
                                     {selectedItem.right_pane ? selectedItem.right_pane : ''}
                                 </InsightDetailsModalItemDetails>
                             </InsightDetailsModalRecomendations>
                         */}
                    </InsightDetailsModalBodyContainer>
                </ModalBodyContainer>
            </InsightsDetailsModalContainer>
        </OctupBaseModal>
    )
}

const ModalBodyContainer = styled.div`
    padding: 3rem 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    height: fit-content;
    width: fit-content;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.4rem;
    overflow: hidden;

    p {
        font-size: inherit;
        line-height: inherit;
    }
`

const SnoozeInsightModalContainerStyled = styled.div`
    width: 64rem;
    max-width: 64rem;
`

const DislikeInsightModalContainerStyled = styled.div`
    width: 64rem;
    max-width: 64rem;
    /* -webkit-transform: translateZ(0); */
    height: ${(props) => (props.showTextarea ? '25rem' : '20rem')};

    input {
        width: 95.6%;
        height: 100%;
        border: 0.5rem solid rgba(255, 255, 255, 0.4);
        box-shadow: inset 0.2rem 0.2rem 0.8rem rgba(80, 75, 90, 0.32);
        padding: 1.5rem;
        font-size: 1.5rem;
        border-radius: 4rem;
        background: #f6f5f9;
    }

    input:focus {
        outline: none;
    }
`

const ShareInsightModalContainerStyled = styled.div`
    width: 64rem;
    max-width: 64rem;
`

const FixedElementsStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: inherit;
    line-height: inherit;
    top: 30%;
    width: 100%;
    position: fixed;
`

const InfoStyled = styled.div`
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: #7f7b87;
    padding-top: 3.5rem;
`

const InsightsNotLiked = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 2rem;
    font-size: inherit;
    line-height: inherit;
    top: 20%;
    width: 100%;
    position: fixed;
`
const InsightDetailsModalHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: inherit;
    line-height: inherit;
    gap: 2em;
`
const InsightDetailsModalDateStyled = styled.div`
    font-size: inherit;
    line-height: inherit;
    color: #a4a0aa;
`

const ActionableButtonsContainer = styled.div`
    position: absolute;
    right: 2rem;
`

const InsightDetailsModalSegmentStyled = styled.div`
    font-size: inherit;
    line-height: inherit;
`

const InsightDetailsModalBodyContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    padding: 2rem 0;
`

const InsightDetailsModalItemTitle = styled.div`
    text-align: left;
    width: 100%;
    color: #504b5a;
    font-size: 1.7rem;
    line-height: 2.2rem;
    font-weight: 500;
`

const InsightDetailsModalItemDetails = styled.div`
    font-size: 1.5rem;
    line-height: 2.5rem;
    padding: 1rem 0 2rem 0;
`

const InnerContentTitle = styled.div`
    padding: 2rem 0;
    color: #504b5a;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 2.2rem;
`

const InnerContentBox = styled.div`
    height: 100%;
    width: 45vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1rem;
    min-height: 100%;
    min-width: 60rem;
    max-width: 100%;
`

const InsightsDetailsModalContainer = styled.div`
    width: 100%;
    height: 100%;
    min-width: 70rem;
    min-height: 65rem;
    max-height: 90vh;
    max-width: 90vw;
`

const InsightDetailsModalRecomendations = styled.div`
    padding: 3rem 0;
`
