import type { MetricsSectionDataType } from '@octup/core/models'

export const getChartDataConfig = (rawData?: MetricsSectionDataType) => {
    const metricsData = rawData?.metrics || []
    const { time_slice, currency, value_units } = metricsData[0] || {}
    const labels = Object.keys(metricsData[0]?.data_by_dates || {})

    const chartData = metricsData.map((metric) => ({
        label: metric.name,
        data: Object.values(metric.data_by_dates || {}),
        currency: metric.currency,
        valueUnits: metric.value_units,
    }))

    const hasMultipleMetrics = chartData.length > 1
    const secondMetricConfig = chartData.find((data) => data.valueUnits !== chartData[0].valueUnits)

    return {
        labels,
        chartsDataSets: chartData,
        timeSlice: time_slice,
        currency,
        valueUnits: value_units,
        isFilled: true,
        showLegend: hasMultipleMetrics,
        showSeriesNameInTooltip: hasMultipleMetrics,
        showSecondYAxis: !!secondMetricConfig,
        secondYAxisConfig: secondMetricConfig,
        numberOfShownDataSests: chartData.length,
        style: { height: '100%' },
    }
}
