import type { MetricsSectionType } from '@octup/core/models'
import {
    ListItemText,
    ListItemIcon,
    SvgIcon,
    MenuItem,
    Icon,
    OverflowTypography,
} from '@octup/core/ui-kit'
import { CheckIcon } from '@/new_assets/icons'
import { DashboardCard } from '@/new_components/Dashboards'
import { OctupTooltip } from '@/shared/ui/MUIWrappers/OctupTooltip'
import { menuItemCardStyle, menuItemStyle } from './MetricsSectionsListItem.styles'

type MetricsSectionsListItemPropsType = MetricsSectionType & {
    onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void
    isDisabled: boolean
    isSelected: boolean
}

export const MetricsSectionsListItem = ({
    id,
    name,
    icon,
    description,
    onClick,
    isDisabled,
    isSelected,
}: MetricsSectionsListItemPropsType) => (
    // @ts-expect-error: OctupTooltip is a JavaScript file
    <OctupTooltip title={description} position="top">
        <DashboardCard
            data-testid="metrics-section-list-item"
            sx={menuItemCardStyle}
            isSelected={isSelected}
        >
            {/* TODO: Add MenuItem in the ui-kit and support checked icon there + override MUI styles */}
            <MenuItem
                value={id}
                sx={menuItemStyle}
                onClick={onClick}
                disabled={isDisabled}
                selected={isSelected}
            >
                {icon && (
                    <ListItemIcon>
                        <Icon display="flex">
                            <img src={icon} alt={name} width="100%" />
                        </Icon>
                    </ListItemIcon>
                )}
                <ListItemText>
                    <OverflowTypography>{name}</OverflowTypography>
                </ListItemText>
                {isSelected && <SvgIcon component={CheckIcon} />}
            </MenuItem>
        </DashboardCard>
    </OctupTooltip>
)
