import { octupDictionary } from 'shared/dictionary/dictionary'
import { useDispatch } from 'react-redux'
import { useToast, useUpdateEffect } from 'hooks'
import { useLocation } from 'react-router-dom'
import { useCallback, useRef } from 'react'
import { sendDataConnectionRequest } from 'state/features/attribution'

export const useAttributionToast = ({ isDataMissing }) => {
    const toast = useToast()
    const toastIdRef = useRef()
    const location = useLocation()
    const dispatch = useDispatch()

    const handleConnectionRequest = useCallback(
        () => dispatch(sendDataConnectionRequest()),
        [dispatch]
    )

    useUpdateEffect(() => {
        if (isDataMissing) {
            const disableTimer = true
            const pages = [location.pathname]
            const title = octupDictionary.titles.attribution.dataIsNotTracked
            const actionText = octupDictionary.titles.attribution.connectionRequest

            toastIdRef.current = toast.error(
                title,
                null,
                null,
                null,
                null,
                null,
                handleConnectionRequest,
                null,
                actionText,
                disableTimer,
                pages
            )

            return
        }

        if (toastIdRef.current) {
            toast.removeToast(toastIdRef.current)
        }
    }, [isDataMissing])
}
