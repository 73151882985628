import type {
    MetricsSectionDeepDiveType,
    MetricsSectionConfigType,
    MetricsSectionPropsType,
} from '@octup/core/models'
import { useDeepDiveProps } from './hooks'

export type MetricsSectionContainerPropsType = Pick<MetricsSectionDeepDiveType, 'onDeepDiveShow'> &
    MetricsSectionPropsType & {
        config: MetricsSectionConfigType
    }

export const MetricsSectionContainer = ({
    data,
    config,
    isLoading,
    onDeepDiveShow,
    ...props
}: MetricsSectionContainerPropsType) => {
    const { name, description } = data || {}
    const isDataLoading = !data && isLoading
    const { Component: MetricsSection, hasDeepDive } = config
    const deepDiveProps = useDeepDiveProps({ data, onDeepDiveShow, isEnabled: hasDeepDive })

    if (!MetricsSection) return null

    return (
        <MetricsSection
            data={data}
            name={name}
            description={description}
            isLoading={isDataLoading}
            {...props}
            {...deepDiveProps}
        />
    )
}
