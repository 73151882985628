import type { AnimationEventHandler, ChangeEvent } from 'react'
import { forwardRef, useCallback, useMemo, useState } from 'react'
import type { TextFieldProps, InputBaseComponentProps, InputLabelProps } from '@mui/material'
import { TextField as MuiTextField } from '@mui/material'
import { PasswordTextField } from './PasswordTextField'

const AUTOFILL_NAME = '*:-webkit-autofill'
const ANIMATION_NAMES = ['mui-auto-fill', 'mui-auto-fill-cancel']

export type TextFieldPropsType = TextFieldProps & {
    type?: string
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    inputProps?: InputBaseComponentProps
    InputLabelProps?: InputLabelProps
}

export const TextField = forwardRef<HTMLDivElement, TextFieldPropsType>(
    ({ type, onChange, inputProps, InputLabelProps, ...props }, ref) => {
        const [hasValue, setHasValue] = useState(false)

        const handleAnimationStart: AnimationEventHandler<HTMLInputElement> = useCallback(
            (event) => {
                if (ANIMATION_NAMES.includes(event.animationName)) {
                    const target = event.target as Element
                    setHasValue(!!target?.matches(AUTOFILL_NAME))
                }
            },
            []
        )

        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                onChange?.(event)
                setHasValue(event.target.value !== '')
            },
            [onChange]
        )

        const commonProps = useMemo(
            () => ({
                onChange: handleChange,
                InputLabelProps: { ...(hasValue && { shrink: true }), ...InputLabelProps },
                inputProps: {
                    onAnimationStart: handleAnimationStart,
                    ...inputProps,
                },
            }),
            [handleChange, hasValue, InputLabelProps, handleAnimationStart, inputProps]
        )

        if (type === 'password') {
            return <PasswordTextField ref={ref} {...commonProps} {...props} />
        }

        return <MuiTextField ref={ref} type={type} {...commonProps} {...props} />
    }
)
