// TODO: re-write

import React, { useEffect, useState, useMemo } from 'react'
import { Button, TextField } from '@octup/core/ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAbilities } from '@/hooks'
import { APP_ROUTES } from '@/models/appConfig'
import { CompanyNameLogoContainer } from '@/new_containers/components/__common__'
import Locked from '@/shared/assets/images/Login_locked.svg'
import Login from '@/shared/assets/images/LoginLock.svg'
import ConfirmPassword from '@/shared/assets/images/ResetPassword.svg'
import { bgLogin } from '@/shared/assets/images/sign-in-sign-up'
import { OctupIcons } from '@/shared/assets/OctupIcons'
import { octupDictionary } from '@/shared/dictionary/dictionary'
import { ErrorDiv, ErrorMessage } from '@/shared/styledComponents'
import { isEmailValid } from '@/shared/ui/Inputs/inputUtils'
import { isPasswordStrong } from '@/shared/utils/utils'
import {
    TitleDiv,
    PaddedOctupLogo,
    RightSideSvgInner,
    LinkText,
    LoginText,
    Line,
    SignupLingDiv,
    MessageText,
    StyledTitle,
    LoginContainer,
    LeftPageContainer,
    BackToLoginDiv,
    LeftPageBody,
    RightPageContainer,
    PageContainer,
    textFieldStyle,
} from './LoginPage.styles'
import { emailFieldOnBlur, handleSubmit, resetPasswordViaEmail } from './loginUtils'

const SIGN_UP_ROUTE = APP_ROUTES.REGISTRATION

function LogInPage() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { isPageAvailable } = useAbilities()
    const searchParams = new URLSearchParams(location.search)
    const passwordResetToken = searchParams.get('pw_rs_tk')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setconformPassword] = useState('')
    const [currStep, setCurrStep] = useState(passwordResetToken ? 3 : 0)
    const [, setInvalidEmail] = useState('')
    const [emailRelatedError, setEmailRelatedError] = React.useState('')
    const [passwordRelatedError, setPasswordRelatedError] = React.useState('')
    const { isLoading } = useSelector((state) => state.auth)
    const [confirmPasswordRelatedError, setConfirmPasswordRelatedError] = React.useState('')
    const { settings } = useSelector((state) => state.appConfig)
    const { palette, palette_mode } = settings.data
    const textFieldSx = textFieldStyle(palette)

    const handleEnter = (event) => {
        if (event.key === 'Enter' && !event.repeat && !isLoading) {
            handleSubmit({
                email,
                password,
                setPasswordRelatedError,
                setEmailRelatedError,
                setConfirmPasswordRelatedError,
                currStep,
                setCurrStep,
                confirmPassword,
                passwordResetToken,
                dispatch,
            })
        }
    }

    useEffect(() => {
        const userInfoJson = localStorage.getItem('userInfo')
        const userInfo = JSON.parse(userInfoJson)
        if (userInfo?.forgotPassword) {
            resetPasswordViaEmail({
                dispatch,
                email: userInfo?.email,
                setEmailRelatedError,
                setCurrStep,
                currStep: 1,
            })
            localStorage.setItem(
                'userInfo',
                JSON.stringify({
                    ...userInfo,
                    forgotPassword: false,
                })
            )
        }
    })

    useEffect(() => {
        const inputElement = document
        inputElement.addEventListener('keydown', handleEnter)
        return () => {
            inputElement.removeEventListener('keydown', handleEnter)
        }
    })

    const steps = [
        {
            title: octupDictionary.titles.login,
            buttonTitle: 'Login',
            renderLeft: () => {
                return (
                    <>
                        <TextField
                            id="1"
                            sx={textFieldSx}
                            type={'email'}
                            value={email}
                            error={!!emailRelatedError}
                            onBlur={() =>
                                emailFieldOnBlur({
                                    email,
                                    setEmailRelatedError,
                                    setInvalidEmail,
                                    dispatch,
                                })
                            }
                            label="Work Email"
                            onChange={(e) => {
                                setEmail(e.target.value)
                                setPasswordRelatedError('')
                                if (isEmailValid(email)) {
                                    setEmailRelatedError(null)
                                }
                            }}
                        />
                        <ErrorDiv>
                            {emailRelatedError && <ErrorMessage>{emailRelatedError}</ErrorMessage>}
                        </ErrorDiv>
                        <TextField
                            id="2"
                            sx={textFieldSx}
                            type={'password'}
                            label="Password"
                            value={password}
                            error={passwordRelatedError !== ''}
                            onKeyDown={(e) => handleEnter(e)}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                setPasswordRelatedError('')
                                if (isPasswordStrong(password)) {
                                    setPasswordRelatedError(null)
                                }
                            }}
                        />
                        <ErrorDiv>
                            {passwordRelatedError && (
                                <ErrorMessage>{passwordRelatedError}</ErrorMessage>
                            )}
                        </ErrorDiv>
                        <SignupLingDiv onClick={() => setCurrStep(1)}>
                            Forgot Password?
                        </SignupLingDiv>
                    </>
                )
            },
            renderRight: () => {
                return <RightSideSvgInner src={Login}></RightSideSvgInner>
            },
            renderSvg: () => {
                return bgLogin
            },
        },
        {
            title: octupDictionary.titles.forgetPassword,
            buttonTitle: 'Send link to my email',
            renderLeft: () => {
                return (
                    <>
                        <MessageText>{octupDictionary.messages.forgetPassword}</MessageText>
                        <TextField
                            id="1"
                            sx={textFieldSx}
                            type={'email'}
                            value={email}
                            label={'Work Email'}
                            error={emailRelatedError !== ''}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                        <ErrorDiv>
                            {emailRelatedError && <ErrorMessage>{emailRelatedError}</ErrorMessage>}
                        </ErrorDiv>
                    </>
                )
            },
            renderRight: () => {
                return <></>
            },
            renderSvg: () => {
                return Locked
            },
        },
        {
            title: 'Check your email to reset your password',
            icon: () => {
                return <OctupIcons.CheckmarkSvg customColor={'rgba(0, 168, 168, 1)'} scale={1.8} />
            },
            renderLeft: () => {
                return <BackToLoginDiv onClick={() => setCurrStep(0)}>Back to login</BackToLoginDiv>
            },
            renderRight: () => {
                return <></>
            },
            renderSvg: () => {
                return ConfirmPassword
            },
        },
        {
            title: octupDictionary.titles.resetPassword,
            buttonTitle: 'Reset Password',
            renderLeft: () => {
                return (
                    <>
                        <MessageText>{octupDictionary.messages.resetPassword}</MessageText>
                        <TextField
                            id="5"
                            sx={textFieldSx}
                            transformX={'-2rem'}
                            type={'password'}
                            value={password}
                            label={'Create Password'}
                            error={passwordRelatedError !== ''}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                        />
                        <ErrorDiv>
                            {passwordRelatedError && (
                                <>
                                    <ErrorMessage>{passwordRelatedError}</ErrorMessage>
                                </>
                            )}
                        </ErrorDiv>
                        {password?.length > 0 && (
                            <>
                                <TextField
                                    id="6"
                                    sx={textFieldSx}
                                    transformX={'-2rem'}
                                    type={'password'}
                                    label={'Confirm Password'}
                                    value={confirmPassword}
                                    error={confirmPasswordRelatedError !== ''}
                                    onChange={(e) => {
                                        setconformPassword(e.target.value)
                                    }}
                                />
                                {
                                    <ErrorDiv>
                                        {confirmPasswordRelatedError && (
                                            <>
                                                <ErrorMessage>
                                                    {confirmPasswordRelatedError}
                                                </ErrorMessage>
                                            </>
                                        )}
                                    </ErrorDiv>
                                }
                            </>
                        )}
                    </>
                )
            },
            renderRight: () => {
                return <></>
            },
            renderSvg: () => {
                return Locked
            },
        },

        {
            icon: () => {
                return <OctupIcons.GreenCheckmarkSvg />
            },
            title: octupDictionary.titles.resetPasswordDone,
            showCheckmark: true,
            renderLeft: () => {
                return <BackToLoginDiv onClick={() => setCurrStep(0)}>Back to login</BackToLoginDiv>
            },
            renderRight: () => {
                return <></>
            },
            renderSvg: () => {
                return ConfirmPassword
            },
        },
    ]

    const isSignupAvailable = useMemo(() => isPageAvailable(SIGN_UP_ROUTE), [isPageAvailable])

    return (
        <PageContainer palette={palette} paletteMode={palette_mode}>
            <LeftPageContainer>
                <LeftPageBody>
                    <LoginContainer>
                        <PaddedOctupLogo
                            onClick={() =>
                                process.env.REACT_APP_ENV.includes('local') &&
                                currStep > 0 &&
                                setCurrStep(currStep - 1)
                            }
                        >
                            <CompanyNameLogoContainer />
                        </PaddedOctupLogo>

                        <TitleDiv>
                            {steps[currStep].icon && steps[currStep].icon()}
                            <StyledTitle variant="h5" color={palette.color}>
                                {steps[currStep].title}
                            </StyledTitle>
                        </TitleDiv>
                        {steps[currStep].renderLeft()}
                        {steps[currStep].buttonTitle && (
                            <Button
                                variant={'contained'}
                                sx={{
                                    width: '50rem',
                                    height: '5rem',
                                    padding: '3rem',
                                    fontSize: '2rem',
                                    lineHeight: '2rem',
                                    fontWeight: 500,
                                }}
                                onClick={() => {
                                    handleSubmit({
                                        email,
                                        password,
                                        setPasswordRelatedError,
                                        setEmailRelatedError,
                                        setConfirmPasswordRelatedError,
                                        currStep,
                                        setCurrStep,
                                        confirmPassword,
                                        passwordResetToken,
                                        dispatch,
                                    })
                                }}
                            >
                                {steps[currStep].buttonTitle}
                            </Button>
                        )}
                        {isSignupAvailable && currStep === 0 && (
                            <Line>
                                <LoginText>
                                    {octupDictionary.messages.noAccount}{' '}
                                    <LinkText onClick={() => navigate(`/${SIGN_UP_ROUTE}`)}>
                                        Sign up
                                    </LinkText>
                                </LoginText>
                            </Line>
                        )}
                        {currStep === 1 && (
                            <Line>
                                <LinkText onClick={() => setCurrStep(0)}>Back to Login</LinkText>
                            </Line>
                        )}
                    </LoginContainer>
                </LeftPageBody>
            </LeftPageContainer>
            <RightPageContainer
                style={{
                    backgroundImage: `url(${steps[currStep].renderSvg()})`,
                    backgroundSize: 'cover',
                    height: '100%',
                }}
            />
        </PageContainer>
    )
}

export default LogInPage
