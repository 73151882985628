import { PastUpdateToggle, ShowPastUpdatesStyled } from './PastUpdateToggleBtn.styles'
import { Switch } from '@mui/material'
import { HorizontalDivider } from '../../../pages/operations/single-order/CustomerDetails.styled'

export const PastUpdateToggleBtn = ({
    handleShowPastUpdatesClick,
    showPastUpdates,
    handleShowPastUpdatesChange,
}) => {
    return (
        <ShowPastUpdatesStyled>
            <HorizontalDivider />
            <PastUpdateToggle onClick={handleShowPastUpdatesClick}>
                <Switch
                    checked={showPastUpdates}
                    onChange={(event, checked) => {
                        handleShowPastUpdatesChange(checked)
                    }}
                />{' '}
                Show Past Updates
            </PastUpdateToggle>
        </ShowPastUpdatesStyled>
    )
}
