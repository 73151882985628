import { Stack, Typography, ListItemImage } from '@octup/core/ui-kit'
import { ShopCurrencyValueContainer } from '@/new_containers/components/__common__'
import { TableRow } from '@/shared/ui/Tables/TableRow'
import { VALUE_TYPE_INDEX, getFormattedVal } from '@/shared/utils/metrics-utils'
import { TitleContainer } from '../TitleContainer'

const TABLE_HEADERS = [
    'Product / Variant',
    'cost',
    { title: 'Price', tooltip: 'price' },
    'in stock',
    'item sold (this month)',
    'profit',
]

const SORT_HEADERS = {
    'product/variant': 'title',
    cost: 'total_cost',
    price: 'price',
    instock: 'items_in_stock',
    'itemsold(thismonth)': 'items_sold',
    profit: 'profit_percentage',
}

const SEARCH_FIELDS = {
    title: 'title',
    sku: 'sku',
}

const NO_SEARCHED_DATA_FALLBACK_PROPS = {
    title: 'No products found',
    text: 'Try changing the search terms.',
    showIcon: false,
}

const TABLE_CELLS = [
    {
        id: 'title',
        field: 'title',
        gap: '3rem',
        height: '100%',
        renderCell: (row, parentRow) => (
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                fontSize="inherit"
                width={parentRow ? '100%' : '85%'}
            >
                <ListItemImage src={row.product_image} alt="product" size="small" />
                <TitleContainer title={row.title} sku={row.sku} fontSize="inherit" />
            </Stack>
        ),
    },
    {
        id: 'total_cost',
        field: 'total_cost',
        renderCell: (row) => <ShopCurrencyValueContainer value={row.total_cost} />,
    },
    {
        id: 'price',
        field: 'price',
        renderCell: (row) => <ShopCurrencyValueContainer value={row.price} />,
    },
    {
        id: 'items_in_stock',
        field: 'items_in_stock',
        renderCell: (row) =>
            getFormattedVal({
                val: row.items_in_stock,
                customNumOfDigits: 0,
                isSupported: true,
                valueType: VALUE_TYPE_INDEX.NUMBER,
            }),
    },
    {
        id: 'items_sold',
        field: 'items_sold',
        width: '100rem',
        renderCell: (row) =>
            getFormattedVal({
                val: row.items_sold,
                customNumOfDigits: 0,
                isSupported: true,
                valueType: VALUE_TYPE_INDEX.NUMBER,
            }),
    },
    {
        id: 'profit_percentage',
        field: 'profit_percentage',
        renderCell: ({ profit_percentage }) => (
            <Typography fontSize="inherit" color={profit_percentage > 0 ? 'success.main' : 'error'}>
                {profit_percentage > 0 && '+'}
                {profit_percentage.toFixed(2) + '%'}
            </Typography>
        ),
    },
]

const renderRows = (row) => <TableRow row={row} tableCells={TABLE_CELLS} />
const renderSubRow = (row, _, parentRow) => (
    <TableRow row={row} parentRow={parentRow} tableCells={TABLE_CELLS} />
)

const getTableData = (data) => ({
    headers: TABLE_HEADERS,
    rows: data?.products,
})

export const getTableConfig = (data) => ({
    renderRows,
    renderSubRow,
    tableData: getTableData(data),
    customRowStyle: { height: '6rem' },
    defaultSortBy: { title: 'itemsold(thismonth)', direction: -1 },
    searchFilterFields: SEARCH_FIELDS,
    sortingHeadersToDataMapping: SORT_HEADERS,
    noSearchedDataFallbackProps: NO_SEARCHED_DATA_FALLBACK_PROPS,
    requestedFilters: [],
    searchFilterPlaceholder: 'Search by name or SKU',
})
