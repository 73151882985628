import React from 'react'
import { getIconColor } from './utils'

export function MarketingLogoSvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.25 7.5V19.0986C14.25 19.2221 14.2805 19.3436 14.3387 19.4525C14.397 19.5614 14.4812 19.6542 14.584 19.7226L15.6145 20.4097C15.7145 20.4763 15.829 20.518 15.9485 20.5311C16.068 20.5443 16.1889 20.5285 16.301 20.4852C16.4131 20.4418 16.5131 20.3722 16.5927 20.2821C16.6722 20.192 16.729 20.0841 16.7581 19.9675L18 15"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18 15C18.9946 15 19.9484 14.6049 20.6517 13.9016C21.3549 13.1984 21.75 12.2445 21.75 11.25C21.75 10.2554 21.3549 9.30159 20.6517 8.59833C19.9484 7.89507 18.9946 7.49998 18 7.49998H14.25C14.25 7.49998 9.14515 7.49998 4.23271 3.37989C4.12346 3.28794 3.99022 3.22909 3.84866 3.21027C3.70711 3.19146 3.56312 3.21345 3.43364 3.27367C3.30416 3.33389 3.19456 3.42983 3.11774 3.55021C3.04092 3.67059 3.00007 3.8104 3 3.9532V18.5468C3.00007 18.6896 3.04092 18.8294 3.11774 18.9498C3.19456 19.0701 3.30416 19.1661 3.43364 19.2263C3.56312 19.2865 3.70711 19.3085 3.84866 19.2897C3.99022 19.2709 4.12346 19.212 4.23271 19.1201C9.14515 15 14.25 15 14.25 15H18Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
