import { Box } from '@mui/material'
import MetricTitle from 'components/Metrics/MetricItem/MetricItemComponents/MetricTitle'
import { VALUE_TYPE } from 'features/metrics'
import { NoDataTooltip } from 'new_components'
import { getFormattedVal } from 'shared/utils/metrics-utils'
import styled from 'styled-components'

export const GMBTableRow = ({ el: rowData }) => {
    const title = rowData.metricTitle

    return (
        <>
            <Box component="td" sx={showOnHoverStyle}>
                <MetricTitle
                    title={title}
                    description={rowData.description}
                    variant="body2"
                    fontWeight={600}
                />
            </Box>
            {rowData.values.map((el) => (
                <ValueStyled key={el.id}>
                    {typeof el?.value === 'number' || rowData.supported ? (
                        getFormattedVal({
                            val: el?.value || 0,
                            valueType: +rowData.valueUnits,
                            customNumOfDigits:
                                title?.toLowerCase() === 'orders' ||
                                title?.toLowerCase() === 'number of returns' ||
                                title?.toLowerCase().includes('email')
                                    ? 0
                                    : null,
                            currency: rowData.currency,
                            isSupported: rowData.supported,
                            multiplyBy100: +rowData.valueUnits === VALUE_TYPE.PERCENTAGE,
                            showSymbol: true,
                        })
                    ) : (
                        <NoDataTooltip />
                    )}
                </ValueStyled>
            ))}
        </>
    )
}

// TODO: create a re-usable approach to use showOnHover style
const showOnHoverStyle = {
    '& .metrics-details-btn': {
        opacity: 0,
    },
    '&:hover': {
        '& .metrics-details-btn': {
            opacity: 1,
            transition: '0.1s ease-in-out',
        },
    },
}

const ValueStyled = styled.td`
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #504b5a;

    span {
        font-weight: 400;
        font-size: 1.3rem;
        color: rgba(219, 32, 89, 1);
    }
`
