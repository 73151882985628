import styled from 'styled-components'
import { OctupTooltip } from '../MUIWrappers/OctupTooltip'
import { CircularProgress } from '@mui/material'

export const ButtonIcon = ({
    id = '',
    type,
    onClick,
    mainIcon,
    active,
    tooltipContent = '',
    tooltipPosition,
    colorScheme = 'secondary',
    customIconColor = null,
    customStyle = null,
    isValid = true,
    showBadge = false,
    badgeValue = 0,
    disabled = false,
    showButtonCard = true,
    isLoading,
}) => {
    const CustomMainIcon = mainIcon

    const renderInside = () => {
        const color = customIconColor
            ? customIconColor
            : active
              ? '#007f82'
              : colorScheme === 'secondary'
                ? '#007f82'
                : '#F8F7FB'

        if (isLoading) {
            return <CircularProgress size="1.8rem" sx={{ color }} />
        }

        return (
            <>
                {mainIcon && (
                    <CustomMainIcon active={active} onClick={onClick} customColor={color} />
                )}
                {showBadge && <BadgeContainer>{badgeValue > 9 ? '9+' : badgeValue}</BadgeContainer>}
            </>
        )
    }

    return (
        <OctupTooltip isContainerized title={tooltipContent} position={tooltipPosition}>
            {showButtonCard ? (
                <ButtonStyled
                    id={id}
                    customStyle={customStyle}
                    type={type}
                    onClick={onClick}
                    isValid={isValid}
                    colorScheme={colorScheme}
                    customIconColor={customIconColor}
                    disabled={disabled || isLoading}
                >
                    {renderInside()}
                </ButtonStyled>
            ) : (
                <>{renderInside()}</>
            )}
        </OctupTooltip>
    )
}

const ButtonStyled = styled.button`
    position: relative;
    background: ${(props) => (props.colorScheme === 'primary' ? '#007f82' : '#f8f7fb')};
    box-shadow:
        -0.2rem -0.2rem 0.8rem #ffffff,
        0.2rem 0.2rem 0.4rem rgba(80, 75, 90, 0.16);
    border-radius: ${(props) =>
        props.customStyle && props.customStyle.borderRadius
            ? props.customStyle.borderRadius
            : '0.8rem'};
    padding: ${(props) =>
        props.customStyle && props.customStyle.padding ? props.customStyle.padding : '0.3rem'};
    width: ${(props) =>
        props.customStyle && props.customStyle.width ? props.customStyle.width : '3.4rem'};
    height: ${(props) =>
        props.customStyle && props.customStyle.height ? props.customStyle.height : '3.4rem'};
    min-width: ${(props) =>
        props.customStyle && props.customStyle.minWidth ? props.customStyle.minWidth : '3.4rem'};
    min-height: ${(props) =>
        props.customStyle && props.customStyle.minHeight ? props.customStyle.minHeight : '3.4rem'};
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
    &:active {
        box-shadow: ${(props) =>
            props.disabled
                ? '-0.2rem -0.2rem 0.8rem #ffffff,0.2rem 0.2rem 0.4rem rgba(80, 75, 90, 0.16)'
                : 'inset -0.2rem -0.2rem 0.4rem #ffffff,inset 0.2rem 0.2rem 0.8rem rgba(80, 75, 90, 0.32)'};
    }
    &:hover {
        background: ${(props) =>
            props.colorScheme === 'primary' ? '#00A8A8' : 'rgba(241,243,247,1)'};
    }

    &,
    &:hover {
        cursor: ${(props) => (props.isValid && !props.disabled ? 'pointer' : 'not-allowed')};
    }
`

const BadgeContainer = styled.div`
    height: 0.8rem;
    width: 0.8rem;
    /* border-radius: 0.4rem; */
    border-radius: 50%;
    background-color: #007f82;
    color: white;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.05rem;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 0.9rem;
`
