import { APP_ROUTES } from '@/models/appConfig'
import type { SettingsRouteTab } from '@/models/settings'
import {
    ClientsSettingsContainer,
    WorkingHoursSettingsContainer,
} from '@/new_containers/components/Clients'

export const ROUTES_TABS: SettingsRouteTab[] = [
    {
        id: 1,
        label: 'Clients Display Name',
        route: APP_ROUTES.SETTINGS_CLIENTS,
        Component: ClientsSettingsContainer,
    },
    {
        id: 2,
        label: 'Working Hours',
        route: APP_ROUTES.SETTINGS_WORKING_HOURS,
        Component: WorkingHoursSettingsContainer,
    },
]
