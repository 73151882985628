import { MetricGraphStyled, Pad } from '../MetricsDetailsModal.styles'
import { ChartsArea } from '../../../../shared/ui/Charts/ChartsArea'
import { DICTIONARY_SECTIONS, getDictionaryValue } from '../../../../shared/dictionary/dictionary'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export function MetricsGraph({ selectedMetricData, isBreakdown, breakdownItemsData, data }) {
    const { metric, dataset } = selectedMetricData || {}
    const [graphData, setGraphData] = useState(dataset)
    const { isExpended } = useSelector((state) => state.metrics)

    useEffect(() => {
        isBreakdown
            ? setGraphData(
                  breakdownItemsData?.graph?.filter(
                      (el) => el.metric.supported && el.dataset.length > 0
                  )
              )
            : setGraphData(dataset)
    }, [breakdownItemsData?.graph, isBreakdown, dataset])

    const chartsDataSets = useMemo(
        () =>
            isBreakdown && graphData?.[0]?.dataset
                ? graphData.map((el) => {
                      return {
                          label:
                              getDictionaryValue(
                                  el?.metric?.metricTitle,
                                  DICTIONARY_SECTIONS.METRICS_TITLES
                              ) + ' ',
                          data: el?.dataset?.map((e) => e[0]),
                      }
                  })
                : [
                      {
                          label: getDictionaryValue(
                              metric?.graphLabel || metric?.metricTitle,
                              DICTIONARY_SECTIONS.METRICS_TITLES
                          ),
                          data: dataset?.map((e) => e[0]),
                      },
                  ],
        [graphData, isBreakdown, dataset, metric?.graphLabel, metric?.metricTitle]
    )

    return (
        <MetricGraphStyled isExpended={isExpended}>
            <ChartsArea
                currency={metric?.currency}
                valueUnits={metric?.valueUnits}
                style={{
                    height: '100%',
                    borderRadius: '1.6rem',
                }}
                showYAxis={true}
                showXAxis={true}
                showSeriesNameInTooltip={!!metric?.graphLabel || isBreakdown}
                isFilled={true}
                backgroundColor={'rgba(253, 252, 254, 1)'}
                numberOfShownDataSests={
                    data.filter((el) => el.metric.supported && el.dataset.length > 0).length
                }
                borderColors={[
                    'rgba(0,168,168,1)',
                    'rgba(122,0,243,1)',
                    'rgba(255, 52, 137, 1)',
                    'rgba(196, 130, 31, 1)',
                    'rgba(0, 152, 212, 1)',
                ]}
                backgroundColors={[
                    'rgba(0,168,168,0.05)',
                    'rgba(122,0,243,0.5)',
                    'rgba(255, 52, 137, 0.5)',
                    'rgba(196, 130, 31, 0.5)',
                    'rgba(0, 152, 212, 0.5)',
                ]}
                showLegend={isBreakdown}
                chartsDataSets={chartsDataSets}
                labels={dataset?.map((e) => e[1])}
                timeSlice={+metric?.time_slice}
                isShopCurrency={metric?.isShopCurrency}
            />
        </MetricGraphStyled>
    )
}
