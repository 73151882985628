import { getIconColor } from './utils'

export function ObjectivesGuideStepIcon({
    active,
    customActiveColor = '#504B5A',
    customColor = '#504B5A',
}) {
    return (
        <svg
            width="77"
            height="77"
            viewBox="0 0 77 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_dd_902_3748)">
                <circle cx="40.8691" cy="40.245" r="30" fill="#F8F7FB" />
                <circle cx="40.8691" cy="40.245" r="28" stroke="white" stroke-width="4" />
            </g>
            <path
                d="M41.9756 41.0073L55.3163 27.6666"
                stroke="#504B5A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M51.4092 31.5725C49.0461 29.214 45.8838 27.8284 42.5479 27.6897C39.2121 27.5511 35.9457 28.6696 33.395 30.8239C30.8443 32.9782 29.1951 36.0114 28.7736 39.3234C28.3522 42.6354 29.1892 45.9849 31.119 48.7094C33.0489 51.4339 35.9311 53.3347 39.1953 54.036C42.4595 54.7372 45.8681 54.1878 48.7466 52.4963C51.6252 50.8048 53.764 48.0945 54.7399 44.9016C55.7159 41.7087 55.4579 38.2658 54.0171 35.254"
                stroke="#504B5A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M46.6923 36.2905C45.5895 35.1877 44.1352 34.5063 42.5821 34.3645C41.029 34.2228 39.4754 34.6297 38.1912 35.5146C36.907 36.3995 35.9735 37.7064 35.5528 39.2081C35.1322 40.7098 35.2511 42.3115 35.8888 43.7347C36.5266 45.1579 37.6428 46.3126 39.0436 46.9982C40.4443 47.6838 42.041 47.8569 43.5562 47.4874C45.0713 47.1179 46.409 46.2292 47.3369 44.9757C48.2648 43.7223 48.7242 42.1833 48.6351 40.6263"
                stroke="#504B5A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <circle opacity="0.3" cx="38.5" cy="41" r="5.5" fill="#FF3489" />
            <rect
                opacity="0.3"
                x="46.6367"
                y="36.0355"
                width="14"
                height="4"
                rx="2"
                transform="rotate(-45 46.6367 36.0355)"
                fill="#FF3489"
            />
            <defs>
                <filter
                    id="filter0_dd_902_3748"
                    x="0.869141"
                    y="0.244995"
                    width="76"
                    height="76"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.313726 0 0 0 0 0.294118 0 0 0 0 0.352941 0 0 0 0.16 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_902_3748"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_902_3748"
                        result="effect2_dropShadow_902_3748"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_902_3748"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}
