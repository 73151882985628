import React from 'react'
import { OctupColors } from '../../theme/colors/OctupColors'

export function CloseIconSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
        >
            <path
                d="M1 9L8.99999 1.00001"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 1L8.99999 8.99999"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
