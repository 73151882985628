import Tour from 'reactour'
import styled from 'styled-components'
import { Button } from '@mui/material'
import { OctupColors } from '../../shared/theme/colors/OctupColors'

//  TODO: re-check the component
//  TODO: remove last button
export const OctupTour = ({
    showTour,
    setShowTour,
    steps,
    nextStep,
    prevStep,
    lastStepNextButton,
    handleDismissIntro,
    ...props
}) => {
    return (
        <Tour
            disableInteraction={true}
            disablePrev={false}
            showButtons={false}
            showNumber={false}
            accentColor={OctupColors.primaryActionableLight}
            isOpen={showTour}
            steps={steps}
            maskClassName={'tour_mask_backdrop'}
            onRequestClose={handleDismissIntro}
            nextButton={
                nextStep ? (
                    <NextButton onClick={nextStep} width={'1rem'}>
                        <NextButtonText>Next</NextButtonText>
                    </NextButton>
                ) : (
                    <></>
                )
            }
            nextStep={nextStep}
            prevButton={
                prevStep ? (
                    <BackButton onClick={prevStep} width={'1rem'}>
                        <BackButtonText>Back</BackButtonText>
                    </BackButton>
                ) : (
                    <></>
                )
            }
            prevStep={prevStep}
            lastStepNextButton={
                lastStepNextButton ? (
                    <NextButton onClick={handleDismissIntro} width={'1rem'}>
                        <NextButtonText>Done</NextButtonText>
                    </NextButton>
                ) : prevStep ? (
                    <BackButton onClick={prevStep} width={'1rem'}>
                        <BackButtonText>Back</BackButtonText>
                    </BackButton>
                ) : (
                    <></>
                )
            }
            showNavigationNumber={false}
            showNavigation={false}
            rounded={16}
            {...props}
        />
    )
}

const NextButton = styled(Button)`
    background: linear-gradient(44.56deg, #007f82 -1.25%, #00a8a8 100.79%);
    width: 2vw;
    height: 2vh;
    margin-left: -7vw;
    border-radius: 6px !important;
`

const BackButton = styled(NextButton)`
    background: none;
`

const ButtonText = styled.span`
    width: 100%;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    color: ${OctupColors.primaryActionableDark};
`

const NextButtonText = styled(ButtonText)`
    color: white;
    text-transform: none;
`

const BackButtonText = styled(ButtonText)`
    color: ${OctupColors.primaryActionableLight};
    text-transform: none;
`
