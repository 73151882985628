import styled from 'styled-components'
import { useEffect, useState } from 'react'
export const OctupTextArea = ({
    name,
    id,
    placeholder,
    rows = 3,
    maxLength,
    minLength,
    disabled = false,
    hideDesign = false,
    onFocus,
    onBlur,
    onChange,
    onInput,
    onMouseEnter,
    onMouseLeave,
    errors = [],
    showErrors = false,
    isValid = true,
    showLengthCounter = false,
    value = '',
    customStyle = null,
    showClearField = false,
    enableAutoGrow = false,
    enableResize = false,
    min,
    max,
    width = '80%',
}) => {
    const [inputValue, setInputValue] = useState(value)

    const [textareaheight, setTextareaheight] = useState(rows)

    function handleChange(event) {
        const height = event.target.scrollHeight
        const rowHeight = 30
        const trows = Math.ceil(height / rowHeight) - 1
        if (trows > textareaheight) {
            setTextareaheight(trows)
        }
    }

    useEffect(() => {
        setInputValue(value)
    }, [value, name])

    const onChangeHandler = (e) => {
        enableAutoGrow && handleChange(e)
        setInputValue(e.target.value)
        onChange && onChange(e)
    }

    const onClearHandler = () => {
        setInputValue('')
    }

    const onBlurHandler = () => {
        onBlur && onBlur()
    }

    const onFocusHandler = () => {
        onFocus && onFocus()
    }

    const onInputHandler = () => {
        onInput && onInput()
    }

    const onMouseEnterHandler = () => {
        onMouseEnter && onMouseEnter()
    }

    const onMouseLeaveHandler = () => {
        onMouseLeave && onMouseLeave()
    }

    return (
        <OctupTextAreaContainer>
            <TextAreaStyled
                id={id}
                name={name}
                rows={textareaheight}
                style={customStyle}
                hasError={showErrors}
                onChange={onChangeHandler}
                onInput={onInputHandler}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                width={width}
                placeholder={placeholder}
                maxLength={maxLength}
                minLength={minLength}
                max={max}
                min={min}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                value={inputValue}
                disabled={disabled}
                hideDesign={hideDesign}
                showClearField={showClearField}
            />
            <ErrorsContainer>
                {showErrors && errors && errors.map((el) => <InputErrorMsg>{el}</InputErrorMsg>)}
            </ErrorsContainer>
            {showLengthCounter && (
                <InputWarningMsg isMaxValue={value.length === maxLength}>{`${
                    value.length && value.length
                }/${maxLength && maxLength} `}</InputWarningMsg>
            )}
        </OctupTextAreaContainer>
    )
}

const ErrorsContainer = styled.ul`
    bottom: -1rem;
    left: 0;
    height: 1rem;
    min-height: fit-content;
    position: absolute;
    white-space: nowrap;
`

const InputErrorMsg = styled.li`
    color: #db2059;
    font-size: 1.3rem;
    line-height: 1.8rem;
`

const OctupTextAreaContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
`

const TextAreaStyled = styled.textarea`
    max-width: 80%;
    min-width: 80%;
    background: ${(props) => (props.hideDesign ? 'inherit' : '#f6f5f9')};
    color: ${(props) => (props.hasError ? '#DB2059' : props.hideDesign ? 'inherit' : '')};
    border: none;
    box-shadow: ${(props) =>
        props.hideDesign ? 'none' : 'inset 0.2rem 0.2rem 0.8rem rgba(80, 75, 90, 0.32)'};
    border-radius: 0.4rem;
    padding: ${(props) => (props.leftIcon ? '1.6rem 3.5rem 1.6rem 4rem' : '1.6rem 0.8rem')};
    line-height: 3rem;
    font-size: 1.5rem;
    width: ${(props) => props.width};
    outline: ${(props) => (props.hasError ? '0.2rem solid #DB2059' : 'none')};
    resize: ${(props) => !props.enableResize && 'none'};
    &:focus {
        outline: ${(props) => (props.hasError ? '0.2rem solid #DB2059' : '0.2rem solid #007f82')};
    }
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'cursor')};
`

const InputWarningMsg = styled.div`
    color: ${(props) => (props.isMaxValue ? '#db2059' : 'inherit')};
    background-color: transparent;
    font-size: 1.3rem;
    line-height: 1.8rem;
    bottom: -2.2rem;
    right: 17.4rem;
    position: absolute;
`
