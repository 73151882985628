export function GoogleMarketingIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M13.537 17.0746C12.5607 16.0982 12.5607 14.5143 13.537 13.5371C11.5835 15.4907 8.41652 15.4907 6.46297 13.5371C7.43931 14.5134 7.43931 16.0974 6.46297 17.0746C5.48663 18.0509 3.90272 18.0509 2.9255 17.0746C6.82646 20.9755 13.1735 20.9755 17.0745 17.0746C16.0973 18.0509 14.5134 18.0509 13.537 17.0746Z"
                fill="#FBBC04"
            />
            <path
                d="M17.0745 2.92548C13.1735 -0.975485 6.82642 -0.975485 2.92546 2.92548C-0.975504 6.82644 -0.975504 13.1735 2.92546 17.0745C1.94912 16.0981 1.94912 14.5142 2.92546 13.537C3.9018 12.5607 5.48571 12.5607 6.46293 13.537C4.50937 11.5835 4.50937 8.4165 6.46293 6.46295C8.41648 4.50939 11.5834 4.50939 13.537 6.46295C14.5133 7.43928 16.0972 7.43928 17.0745 6.46295C18.0508 5.48573 18.0508 3.90269 17.0745 2.92548Z"
                fill="#4184F3"
            />
            <path
                d="M4.6938 17.8068C6.0753 17.8068 7.19523 16.6869 7.19523 15.3054C7.19523 13.9239 6.0753 12.804 4.6938 12.804C3.3123 12.804 2.19237 13.9239 2.19237 15.3054C2.19237 16.6869 3.3123 17.8068 4.6938 17.8068Z"
                fill="#34A853"
            />
            <path
                d="M17.0745 2.92554C18.0508 3.90188 18.0508 5.48579 17.0745 6.46301C16.0982 7.43934 14.5142 7.43934 13.537 6.46301C15.4906 8.41656 15.4906 11.5835 13.537 13.5371C12.5607 14.5134 12.5607 16.0973 13.537 17.0745C14.5134 18.0509 16.0973 18.0509 17.0745 17.0745C20.9746 13.1736 20.9746 6.8265 17.0745 2.92554Z"
                fill="#EA4335"
            />
        </svg>
    )
}
