import styled from 'styled-components'

export const NavItemIcon = styled.span`
    width: 2.8rem;
    text-align: center;
    justify-content: center;
    height: 100%;
    display: flex;
    align-items: center;
    /* margin-left:.4rem */
`

export const arrowIconStyle =
    ({ showSubMenu, isMainMenuCollapsed }) =>
    () => ({
        transform: `rotate(${showSubMenu ? 180 : 0}deg)`,
        ...(isMainMenuCollapsed && {
            position: 'absolute',
            right: 0,
            fontSize: '1.6rem',
        }),
    })

export const listItemStyle =
    ({ showSubMenu }) =>
    (theme) => ({
        cursor: 'pointer',
        fontWeight: 400,
        color: '#d9d9d9',
        height: theme.spacing(10),
        padding: theme.spacing(2.4, 2),
        '&.active': {
            color: theme.palette.primary.light,
            background: theme.palette.grey['900'],
        },
        '&:hover': {
            background: 'rgba(40, 40, 49, 0.5)',
        },
        '&.last-item': {
            borderRadius: '0 0 2.4rem 0',
        },
        '&[disabled]': {
            cursor: 'unset',
            color: theme.palette.action.disabled,
            background: 'none',
        },
        ...(showSubMenu && {
            background: theme.palette.grey['900'],
        }),
    })

export const iconStyle = (disabled) => (theme) => ({
    color: disabled ? theme.palette.text.secondary : theme.palette.common.white,
})
