import { Stack, TextField } from '@octup/core/ui-kit'
import { useFormContext } from 'react-hook-form'
import {
    FormFieldContainer,
    DeleteCostButtonContainer,
} from '@/new_containers/components/__common__'
import { CheckmarkSvg, CloseIconSvg } from '@/shared/assets/icons'
import { ButtonIcon } from '@/shared/ui/Buttons/ButtonIcon'
import { ProductCost } from '../ProductCost/ProductCost'
import { textFieldStyle } from './ProductCostForm.styles'

export const ProductCostForm = ({ cost, onCancel }) => {
    // TODO: move hook in container
    const {
        formState: { isSubmitting },
    } = useFormContext()

    return (
        <ProductCost
            title={
                <FormFieldContainer
                    as={TextField}
                    name="cost_name"
                    size="small"
                    placeholder="Name of cost"
                    disabled={isSubmitting}
                    hasInlineError
                    sx={textFieldStyle}
                />
            }
            value={
                <Stack direction="row" alignItems="center" spacing={1}>
                    <FormFieldContainer
                        as={TextField}
                        type="number"
                        name="value"
                        size="small"
                        disabled={isSubmitting}
                        hasInlineError
                        sx={textFieldStyle}
                    />
                    <ButtonIcon
                        type="submit"
                        tooltipContent="Save"
                        colorScheme="primary"
                        mainIcon={CheckmarkSvg}
                        isLoading={isSubmitting}
                    />
                </Stack>
            }
            actions={
                <Stack direction="row" alignItems="center" spacing={1}>
                    {cost.isEdit && (
                        <ButtonIcon
                            tooltipContent="Cancel"
                            mainIcon={CloseIconSvg}
                            onClick={onCancel}
                            disabled={isSubmitting}
                        />
                    )}
                    {cost.isNew && (
                        <DeleteCostButtonContainer cost={cost} disabled={isSubmitting} />
                    )}
                </Stack>
            }
        />
    )
}
