import { OTHER_SOURCE } from 'constants'
// TODO: REUSABLE, move into the right place
import { iconsMappingRaw } from 'components/UnitEconomics/ProductPNLBreakdown/iconsMapping'
import { getIntegrationTitle } from 'new_containers/components/Integrations/utils'
import { useMemo } from 'react'
import { SourceTitle } from 'new_components'

export const SourceTitleContainer = ({ source, campaignName, campaignsCount, ...props }) => {
    const Icon = useMemo(() => iconsMappingRaw[source], [source])
    const integrationTitle = useMemo(() => getIntegrationTitle(source), [source])
    const hasCount = useMemo(
        () => source !== OTHER_SOURCE && typeof campaignsCount === 'number',
        [campaignsCount, source]
    )

    return (
        <SourceTitle
            {...props}
            icon={Icon}
            hasCount={hasCount}
            campaignName={campaignName}
            campaignsCount={campaignsCount}
            integrationTitle={integrationTitle}
        />
    )
}
