import { useMemo } from 'react'
import { getTableCells } from './CampaignsTableRow.utils'
import { TableRow } from 'shared/ui/Tables'

export const CampaignsTableRowContainer = ({ isSubRow, isTotalRow, ...props }) => {
    const tableCells = useMemo(
        () => getTableCells({ isSubRow, isTotalRow }),
        [isSubRow, isTotalRow]
    )

    return (
        <TableRow {...props} tableCells={tableCells} isSubRow={isSubRow} isTotalRow={isTotalRow} />
    )
}
