import { Button, Dialog, DialogActions, DialogContent } from '@octup/core/ui-kit'
import { ContactText } from './SupportContactDialog.styles'

type SupportContactDialogPropsType = {
    onClose: () => void
}

export const SupportContactDialog = ({ onClose }: SupportContactDialogPropsType) => (
    <Dialog open fullWidth onClose={onClose}>
        <DialogContent>
            <ContactText>
                At the moment, connecting this integration requires the assistance
                <br />
                of an Octup support team member.
                <br />
                Please contact us at <a href="mailto:support@octup.com">support@octup.com</a> and
                we’ll get back to you ASAP.
            </ContactText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={onClose}>
                Okay, Got It
            </Button>
        </DialogActions>
    </Dialog>
)
