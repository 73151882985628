import axios from 'axios'
import { INSIGHT_API_URL } from './insightSlice'

const generateConfig = (token, contentType) => {
    return {
        headers: {
            Authorization: token ? token : null,
            'Content-Type': contentType ? contentType : 'application/json',
        },
    }
}

/**
 * creates new Octup insight
 * @token optional
 * @insightData mandatory
 */
export const createInsight = async (insightData, token) => {
    const config = generateConfig(token)
    const response = await axios.post(`${INSIGHT_API_URL}`, insightData, config)
    return response.data
}

/**
 * fetchs all Octup insight
 * @token optional
 */
export const getInsights = async (token) => {
    const config = generateConfig(token)
    const res = await axios.get(`${INSIGHT_API_URL}/all`, config)
    return res.data
}

export const updateInsightLike = async (data, token) => {
    const config = generateConfig(token)
    const response = await axios.put(
        `${INSIGHT_API_URL}/ul`,
        { like: true, insight_id: data.insightId },
        config
    )
    return response.data
}

/**
 * fetchs single Octup insight by id
 * @token optional
 * @insightId mandatory
 */
export const getInsight = async (insightId, token) => {
    const config = generateConfig(token)
    const response = await axios.get(`${INSIGHT_API_URL}/${insightId}`, config)
    return response
}

/**
 * updates single Octup insight by id
 * @token mandatory
 * @insightId mandatory
 */
export const updateInsight = async (insightId, token) => {
    const config = generateConfig(token)
    const response = await axios.patch(`${INSIGHT_API_URL}/${insightId}`, config)
    return response.data
}

/**
 * @token mandatory
 * @insightId mandatory
 */
export const updateInsightStatus = async (data, token) => {
    const config = generateConfig(token)
    const response = await axios.put(
        `${INSIGHT_API_URL}/us`,
        { status: data.status, insight_id: data.insightId },
        config
    )
    return response.data
}

/**
 * @token mandatory
 * @insightId mandatory
 * @dismissTo optional
 */
export const dismissInsight = async (data, token) => {
    const config = generateConfig(token)
    const response = await axios.put(
        `${INSIGHT_API_URL}/dismiss`,
        { insight_id: data.insightId, dismiss_to: data.dismissDate },
        config
    )
    return response.data
}

/**
 * delete single Octup insight by id
 * @token optional
 * @insightId mandatory
 */
export const deleteInsight = async (insightId, token) => {
    const config = generateConfig(token)
    const response = await axios.delete(`${INSIGHT_API_URL}/${insightId}`, config)
    return response.data
}

const insightService = {
    createInsight,
    getInsight,
    getInsights,
    updateInsight,
    updateInsightLike,
    deleteInsight,
    updateInsightStatus,
    dismissInsight,
}

export default insightService
