import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { ClientSettingsType } from 'models/settings'
import type { ThunkAPIConfigType } from 'services/utils'
import { getClientsSettings } from '@/services/settings'

type ClientsSettingsStateType = {
    data?: ClientSettingsType[]
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: ClientsSettingsStateType = {
    data: undefined,
    isLoading: false,
    error: undefined,
}

export const fetchClientsSettings = createAsyncThunk<
    ClientSettingsType[],
    undefined,
    ThunkAPIConfigType
>('fetchClientsSettings', (_, thunkAPI) => getClientsSettings(thunkAPI))

export const clientsSettingsSlice = createSlice({
    name: 'clientsSettings',
    initialState: INITIAL_STATE,
    reducers: {
        updateClientSettings: (state, action) => {
            const updatedClient = action.payload
            state.data = state.data?.map((client) =>
                client.id === updatedClient.id ? updatedClient : client
            )
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchClientsSettings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchClientsSettings.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchClientsSettings.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const { updateClientSettings } = clientsSettingsSlice.actions
export const clientsSettingsReducer = clientsSettingsSlice.reducer
