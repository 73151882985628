import { CardExpendable } from '../Card/CardExpendable'
import styled from 'styled-components'
import { Button } from '@octup/core/ui-kit'

export function DropableMenuOptions({
    showDropdown,
    setShowDropdown,
    children,
    dismissBtn,
    showDismissBtn,
    droplistPosition = 'right',
    optionsCustomStyle,
}) {
    return (
        showDropdown && (
            <DropdownOptionsContainer droplistPosition={droplistPosition}>
                <CardExpendable>
                    <DropdownContainer style={optionsCustomStyle}>
                        {children}
                        {showDismissBtn && (
                            <ButtonStyled>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                        dismissBtn.onClickCallback()
                                        setShowDropdown(false)
                                    }}
                                >
                                    save
                                </Button>
                            </ButtonStyled>
                        )}
                    </DropdownContainer>
                </CardExpendable>
            </DropdownOptionsContainer>
        )
    )
}

const DropdownOptionsContainer = styled.div`
    position: absolute;
    width: fit-content;
    bottom: 0;
    right: ${(props) => (props.droplistPosition === 'right' ? '0' : '')};
    left: ${(props) => (props.droplistPosition === 'left' ? '0' : '')};
    transform: translateY(100%);
    z-index: 50;

    & ::-webkit-scrollbar-thumb:hover {
        background-color: #ddd !important;
    }

    & ::-webkit-scrollbar {
        width: 1rem !important;
        scrollbar-width: thin !important;
    }

    & ::-webkit-scrollbar-track {
        background-color: transparent !important;
    }

    & ::-webkit-scrollbar-thumb {
        background-color: #d6dee1 !important;
        border-radius: 1rem !important;
        background-clip: content-box !important;
    }
`

const DropdownContainer = styled.div`
    height: 100%;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
`

const ButtonStyled = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
`
