export function ShopifyIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g clipPath="url(#clip0_146_2454)">
                <path
                    d="M18.6053 5.90125C18.5906 5.81281 18.5184 5.745 18.4291 5.73656C18.3587 5.725 16.8134 5.61906 16.8134 5.61906L15.6353 4.44094C15.5291 4.32344 15.2941 4.35781 15.2053 4.38219C15.1937 4.38219 14.9703 4.4525 14.6047 4.57125C14.2494 3.54312 13.6203 2.60063 12.5122 2.60063H12.4175C12.1 2.18906 11.7112 2 11.3831 2C8.80312 2.01156 7.57374 5.22906 7.18499 6.87313L5.38187 7.42625C4.82874 7.6025 4.80843 7.61531 4.73406 8.14281L3.21312 19.8672L14.6094 22L20.7853 20.6688C20.7853 20.6572 18.6166 6.01344 18.6053 5.90156V5.90125ZM13.9728 4.76875C13.6862 4.86344 13.3594 4.95781 13.0072 5.06406V4.85156C13.0176 4.3213 12.9382 3.7931 12.7722 3.28937C13.3497 3.37812 13.7378 4.02594 13.9731 4.76844L13.9728 4.76875ZM12.0766 3.42562C12.265 3.98125 12.3525 4.56625 12.3347 5.15281V5.25906L10.3425 5.8725C10.7325 4.41625 11.4519 3.69687 12.0769 3.42562H12.0766ZM11.3109 2.70906C11.4288 2.71157 11.5434 2.74838 11.6406 2.815C10.8094 3.20187 9.93062 4.17937 9.55374 6.13594L7.97718 6.61906C8.42187 5.13438 9.45937 2.70625 11.31 2.70625L11.3109 2.70906Z"
                    fill="#95BF47"
                />
                <path
                    d="M18.4291 5.73654C18.3588 5.72498 16.8134 5.61904 16.8134 5.61904L15.6353 4.44092C15.5916 4.39654 15.5322 4.37123 15.4706 4.37061L14.6106 22L20.7866 20.6687L18.6053 5.91311C18.5982 5.86891 18.5774 5.82807 18.5457 5.79643C18.5141 5.76478 18.4733 5.74393 18.4291 5.73686V5.73654Z"
                    fill="#5E8E3E"
                />
                <path
                    d="M12.5125 9.15309L11.7456 11.4162C11.2822 11.1918 10.7756 11.0712 10.2606 11.0621C9.05812 11.0621 8.99937 11.8159 8.99937 12.0053C8.99937 13.0359 11.6981 13.4315 11.6981 15.8593C11.6981 17.7687 10.4841 18.9937 8.85437 18.9937C6.89812 18.9937 5.89594 17.7796 5.89594 17.7796L6.41625 16.0453C6.41625 16.0453 7.44125 16.9296 8.31406 16.9296C8.4184 16.9336 8.52246 16.9164 8.61994 16.879C8.71743 16.8416 8.80631 16.7848 8.88121 16.7121C8.95612 16.6393 9.01549 16.5521 9.05572 16.4558C9.09596 16.3594 9.11623 16.2559 9.11531 16.1515C9.11531 14.7953 6.89969 14.7368 6.89969 12.5209C6.89969 10.6578 8.24281 8.84277 10.9416 8.84277C12.0022 8.8584 12.5097 9.15215 12.5097 9.15215L12.5125 9.15309Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_146_2454">
                    <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>
    )
}
