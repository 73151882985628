import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    Stack,
    Typography,
} from '@octup/core/ui-kit'
import { FormContainer } from '@/new_containers/components/__common__'
import { formFieldsDialogContainerStyle } from './ConnectDialog.styles'
import { ConnectStep, ModalFooter, ModalTitle } from '.'

export const ConnectDialog = ({
    modalContent,
    integration,
    integrationTitle,
    onClose,
    onSubmit,
    defaultValues,
    validationSchema,
}) => {
    const {
        helpLink,
        helpLinkText,
        maxWidth = 'md',
        guideSteps,
        guideStepsTitle,
        formFields,
    } = modalContent
    const hasFooter = helpLink && helpLinkText

    return (
        <Dialog
            open
            fullWidth
            maxWidth={maxWidth}
            onClose={onClose}
            PaperProps={{
                component: FormContainer,
                onSubmit,
                defaultValues,
                validationSchema,
            }}
            title={
                <ModalTitle
                    imageSrc={integration.imageSrc}
                    title={`Connect to ${integrationTitle}`}
                />
            }
        >
            {formFields && (
                <DialogContent sx={formFieldsDialogContainerStyle}>
                    <Stack spacing={4}>
                        {formFields?.map((field) => (
                            <ConnectStep
                                key={field.inputConfig?.name}
                                showStep={false}
                                {...field}
                            />
                        ))}
                    </Stack>
                </DialogContent>
            )}
            <DialogContent>
                <Stack spacing={4}>
                    {guideStepsTitle && <Typography fontWeight={600}>{guideStepsTitle}</Typography>}
                    {guideSteps?.map((guideStep) => (
                        <ConnectStep key={guideStep.step} {...guideStep} />
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Grid container alignItems="center" justifyContent="space-between">
                    {hasFooter && (
                        <Grid item>
                            <ModalFooter helpLink={helpLink} helpLinkText={helpLinkText} />
                        </Grid>
                    )}
                    <Grid
                        item
                        xs
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        wrap="nowrap"
                        gap={2}
                    >
                        <Grid item>
                            <Button variant="secondary" onClick={onClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button type="submit" variant="contained">
                                Connect
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
