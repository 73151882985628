import React from 'react'
import styled from 'styled-components'
import { OctupIcons } from '../../assets/OctupIcons'
import { onChangeWrapper, onfocus } from './inputUtils'
import { OctupInputFieldLabel } from './OctupInputFieldLabel'
import { OctupColors } from '../../theme/colors/OctupColors'
import { Row } from '../../styledComponents'
import { Button } from '@mui/material'
import { OctupButton } from '../Buttons/OctupButton'

const DEFAULT_PADDING = '3.4rem 10rem 2.5rem 2.5rem'

export const OctupInputField = ({
    labelBIId = 'default label id',
    value = '',
    onChange,
    color,
    placeholder = 'Email',
    type = 'email',
    onKeyDown = (e) => {},
    onBlur = () => {},
    width,
    style,
    readOnly = false,
    hideLabel = false,
    disableAnimation = false,
    transformX,
    transformY,
    iconLeft,
    Icon = () => <OctupIcons.SearchIcon customColor={'#C2C0C7'} />,
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    onFocusCallback = () => {},
    padding,
    theme,
}) => {
    const [isFocused, setIsFocused] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false)
    const [centerPlaceholder, setCenterPlaceholder] = React.useState(
        value.length === 0 && !isFocused
    )

    const handleShowPasswordClick = () => {
        setShowPassword(!showPassword)
    }

    return (
        <MainContainer width={width} style={{ ...style }}>
            {readOnly ? (
                type !== 'password' ? (
                    <ReadOnlyContainer>
                        <ColumContainer>
                            {!hideLabel && <ReadOnlyLabel type={type}>{placeholder}</ReadOnlyLabel>}
                        </ColumContainer>
                        <ReadOnlyText>{value}</ReadOnlyText>
                    </ReadOnlyContainer>
                ) : (
                    <StyledRow style={{ width }}>
                        <PasswordInputWrapper>
                            <PasswordInput
                                type="password"
                                value={value}
                                onChange={onChange}
                                placeholder="Password"
                                readOnly={true}
                            />
                        </PasswordInputWrapper>
                    </StyledRow>
                )
            ) : (
                <>
                    {!hideLabel && (
                        <OctupInputFieldLabel
                            iconLeft={iconLeft}
                            transformX={transformX}
                            transformY={transformY}
                            centerPlaceholder={centerPlaceholder}
                            disableAnimation={disableAnimation}
                            value={placeholder}
                            color={
                                value?.length > 0
                                    ? !isFocused
                                        ? color
                                        : OctupColors.primaryTextLight
                                    : ''
                            }
                            type={type}
                        />
                    )}
                    <StyledInput
                        id={labelBIId}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        autoComplete="new-password"
                        hideLabel={hideLabel}
                        disabled={readOnly}
                        width={width}
                        value={value}
                        padding={padding}
                        theme={theme}
                        color={
                            value?.length > 0
                                ? !isFocused
                                    ? color
                                    : OctupColors.primaryTextLight
                                : ''
                        }
                        focusColor={OctupColors.primaryTextLight}
                        onChange={(e) =>
                            onChangeWrapper(e, value, onChange, setCenterPlaceholder, isFocused)
                        }
                        onKeyDown={onKeyDown}
                        onFocus={() => {
                            onFocusCallback()
                            return onfocus(setIsFocused, setCenterPlaceholder)
                        }}
                        onBlur={() => {
                            setIsFocused(false)
                            setCenterPlaceholder(value.length === 0)
                            onBlur()
                        }}
                        type={!showPassword && type}
                        centerPlaceholder={centerPlaceholder}
                    />
                    {type === 'password' && (
                        <IconDiv onClick={handleShowPasswordClick}>
                            {showPassword ? (
                                <OctupIcons.HidePasswordIcon />
                            ) : (
                                <OctupIcons.ShowPasswordIcon />
                            )}
                        </IconDiv>
                    )}
                </>
            )}
        </MainContainer>
    )
}

const IconDiv = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    width: fit-content;
    transform: translateY(-150%);
    position: absolute;
    right: 1rem;
    cursor: pointer;
    z-index: 1000;
    height: 50%;
`

const ColumContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const MainContainer = styled.div`
    position: relative;
    width: ${(p) => (p.width ? p.width : '50rem')};
`

const ReadOnlyContainer = styled.div`
    //width: 90%;
    //overflow: hidden;
`

const ReadOnlyText = styled.span`
    font-weight: 400;
    font-size: 2rem;
    line-height: 6rem;
    color: #504b5a;
`

const ReadOnlyLabel = styled.span`
    font-size: 3.5rem;
    color: #7f7b87;
`

const StyledInput = styled.input`
    border-radius: 5px;
    border: 0.1rem solid transparent !important;
    border-color: ${(p) => p.color && `${p.color} !important`};
    position: relative;
    font-size: 1.8rem;

    &:focus {
        border-color: ${(p) => p.focusColor && `${p.focusColor} !important`};
    }

    ::-ms-reveal {
        display: none;
    }

    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: ${(p) => {
        if (p.theme === 'small') {
            return '1rem 0'
        } else if (p.padding) {
            return p.padding
        } else if (p.hideLabel) {
            return '2.5rem 0 2.5rem 1.6rem'
        } else if (p.type !== 'default') {
            return '3.8rem 2rem 3.2rem 2rem'
        } else {
            return DEFAULT_PADDING
        }
    }};

    width: ${(p) => (p.width ? p.width : '50rem')};
    height: 1rem;
    background: #f6f5f9;

    box-shadow:
        inset -0.2rem -0.2rem 0.4rem #ffffff,
        inset 0.2rem 0.2rem 0.8rem rgba(80, 75, 90, 0.32);

    outline: none;
`

const PasswordInputWrapper = styled.div`
    display: inline-block;
    position: relative;
    background-color: transparent;

    @media screen and (min-width: 1600px) {
        padding-top: 5px;
    }
`

const PasswordInput = styled.input`
    padding: 5px 10px;
    border: none;
    font-size: 14px;
    width: 100%;
    background-color: transparent;
    cursor: auto;

    &:focus {
        outline: none;
    }
`

const StyledRow = styled(Row)`
    width: 45rem;
`
