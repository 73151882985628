import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'
import { FormFieldContainer } from 'new_containers/components/__common__'
import { getResolvedTemplate } from 'new_containers/components/Integrations'
import { TextField } from '@octup/core/ui-kit'

export const KeyFormFieldContainer = ({ name, hint }) => {
    const { watch } = useFormContext()

    const currentValue = watch(name, '')

    const label = useMemo(() => {
        const data = { [name]: currentValue }
        return getResolvedTemplate(hint, data)
    }, [currentValue, hint, name])

    return <FormFieldContainer as={TextField} name={name} label={label} fullWidth />
}
