import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { fetchDashboardById } from 'state/features/dashboards'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { FormDashboardPageContainer } from '../FormDashboardPageContainer'

export const EditDashboardPageContainer = () => {
    const { dashboardId } = useParams()
    const dispatch = useAppDispatch()
    const { userInfo } = useAppSelector((state) => state.auth)
    const { dashboardsById } = useAppSelector((state) => state.dashboards)
    const dashboard = dashboardsById.data?.[dashboardId!]

    useEffect(() => {
        if (!dashboard) {
            dispatch(fetchDashboardById({ id: dashboardId!, clientId: userInfo.client.id }))
        }
    }, [dashboard, dashboardId, dispatch, userInfo.client.id])

    return (
        <FormDashboardPageContainer
            data={dashboard?.data}
            error={dashboard?.error}
            isLoading={dashboard?.isLoading}
        />
    )
}
