import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OctupDatePicker } from 'shared/ui/DatePicker/OctupDatePicker'
import { changeDatePicker, resetDatePicker } from 'state/features/metrics'

export const DatePickerContainer = () => {
    const dispatch = useDispatch()
    const { datePickerValues: defaultDatePicker } = useSelector((state) => state.datePicker)

    useEffect(() => {
        dispatch(changeDatePicker(defaultDatePicker))
        return () => dispatch(resetDatePicker())
    }, [defaultDatePicker, dispatch])

    const handleChange = useCallback(
        (newDateRange) => dispatch(changeDatePicker(newDateRange)),
        [dispatch]
    )

    return <OctupDatePicker onChange={handleChange} />
}
