import { WhatsNewModalContainer } from './WhatsNewModalContent.styles'
import { WhatsNewMain } from '../WhatsNewMain'
import { WhatsNewFooter } from '../WhatsNewFooter'

export const WhatsNewModalContent = () => {
    return (
        <WhatsNewModalContainer>
            <WhatsNewMain />
            <WhatsNewFooter />
        </WhatsNewModalContainer>
    )
}
