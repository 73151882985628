import { DICTIONARY_SECTIONS, getDictionaryValue } from 'shared/dictionary/dictionary'

const getTableName = (selectedMetric) =>
    `${getDictionaryValue(selectedMetric.metricTitle, DICTIONARY_SECTIONS.METRICS_TITLES)}_deepdive`

export const getCommonConfig = ({ selectedMetric }) => {
    const tableName = getTableName(selectedMetric)

    return {
        tableName,
        customRowStyle: { height: '5rem' },
        requestedFilters: [],
        showFilters: false,
        showSelectedRow: false,
        showDownloadCSV: true,
        showDatePicker: true,
    }
}
