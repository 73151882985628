import styled from 'styled-components'

export const PastUpdateToggle = styled.div`
    height: 6rem;
    display: flex;
    align-items: center;
    padding: 2rem;
    font-size: 13px;
    color: rgba(80, 75, 90, 1);
`
export const ShowPastUpdatesStyled = styled.div`
    height: fit-content;
    position: sticky;
    bottom: 0;
    cursor: pointer;
`
