import styled from 'styled-components'

export function DotIndicator({ type = 'alert', size = 'medium' }) {
    return <DotIndicatorContainer type={type} size={size}></DotIndicatorContainer>
}

const DotIndicatorContainer = styled.div`
    background: ${(props) =>
        props.type === 'alert'
            ? '#fc4f6d'
            : props.type === 'warning'
              ? '#FFCF72'
              : props.type === 'info'
                ? '#00A8A8'
                : '#0AAA59'};
    width: ${(props) => (props.size === 'medium' ? '0.8rem' : '1.2rem')};
    height: ${(props) => (props.size === 'medium' ? '0.8rem' : '1.2rem')};
    border-radius: 50%;
    margin-left: 0.5rem;
`
