import type { PaletteModeType } from '@octup/core/theme'
import { createTheme as createBaseTheme } from '@octup/core/theme'
import { OVERRIDES } from './overrides'
import { calculateSpacing } from './spacing'
import { TYPOGRAPHY } from './typography'

export const createTheme = (mode: PaletteModeType = 'light') =>
    createBaseTheme({
        mode,
        options: {
            typography: TYPOGRAPHY,
            components: OVERRIDES,
            spacing: calculateSpacing,
        },
    })
