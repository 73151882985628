import { Box, Stack, Typography } from '@mui/material'
import { UEMessageCard } from 'components/Modals/UnitEconomicsModals/UEMessageCard'
import NoDataSVG from 'shared/assets/images/EmptyUnitEconomics.svg'
import NoDataBottomArrowSVG from 'shared/assets/images/EmptyUnitEconomicsBottomArrow.svg'
import NoDataTopArrowSVG from 'shared/assets/images/EmptyUnitEconomicsTopArrow.svg'
import { addCostContainerStyle, containerStyle } from './NoSelectedProductFallback.styles'

export const NoSelectedProductFallback = () => (
    <Stack justifyContent="space-between" sx={containerStyle}>
        <Stack alignItems="flex-start">
            <Stack alignSelf="flex-end" sx={addCostContainerStyle}>
                <img src={NoDataTopArrowSVG} alt="add cost arrow" />
            </Stack>
            <Stack>
                <Typography fontWeight={600}>Add custom cost</Typography>
                <Typography variant="body2">
                    Create new costs for a specific product
                    <br /> or multiple products and modify costs manually.
                </Typography>
            </Stack>
        </Stack>
        <Stack alignItems="center">
            <img src={NoDataSVG} alt="No data" />
        </Stack>
        <Stack spacing={3}>
            <Stack alignItems="flex-start">
                <Box>
                    <img src={NoDataBottomArrowSVG} alt="products arrow" />
                </Box>
                <Typography fontWeight={600}>
                    Select a product to see its costs breakdown.
                </Typography>
                <Typography variant="body2">
                    Also, add or modify a cost for this specific product.
                </Typography>
            </Stack>
            <UEMessageCard isAccordion={false} />
        </Stack>
    </Stack>
)
