import styled from 'styled-components'

const Pad = styled.div`
    height: 100%;
`

const MetricsDetailsModalStyled = styled.div`
    font-size: inherit;
    height: 100%;
    width: 100%;
    line-height: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 0;
    min-width: 100rem;
    overflow: hidden;
`

const DownDropStyled = styled.div`
    position: absolute;
    min-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0.8rem;
    left: 1rem;
`

const MetricGraphStyled = styled.div`
    font-size: 2rem;
    width: ${(props) => (props.isExpended ? 'calc(100vw - 5rem)' : '100rem')};
    height: 30rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    border-radius: 14px;
    overflow: hidden;
    min-height: 20vh;
    max-height: 20vh;
`

const TargetContainer = styled.div`
    font-size: 1.5rem;
    line-height: 2rem;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 2rem;
`

const DropdownsStyled = styled.div`
    display: flex;
    gap: 2rem;
`

const TargetHeaderStyled = styled.div`
    color: #007f82;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    cursor: pointer;
`

const UpdateTargetStyled = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    gap: 1.5rem;
    right: -6rem;
    top: 5rem;
`

const TargetSettingsStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem;

    div {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2rem;
    }
`

const TargetInputStyled = styled.input`
    background: #f6f5f9;
    border: 0.05rem solid rgba(255, 255, 255, 0.4);
    box-shadow: inset 0.2rem 0.2rem 0.8rem rgba(80, 75, 90, 0.32);
    border-radius: 0.4rem;
    width: 10rem;
    height: 3.2rem;
    text-align: center;
    outline: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    padding-left: 1.5rem;
`

const TdChangePrecentageStyled = styled.div`
    color: ${(props) => (props.val >= 0 ? '#078344' : '#DB2059')};
    font-size: 1.3rem;
    line-height: 1.8rem;
`

const FormattedVal = styled.div`
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
`

const TableContainer = styled.div`
    width: 100%;
    min-height: 37vh;
`

const BreathingContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0rem;
    width: 100%;
    justify-content: space-between;
`

const TableAnchor = styled.a`
    font-weight: 600;
    color: #007f82;
`

export {
    TableContainer,
    TdChangePrecentageStyled,
    TargetInputStyled,
    TargetSettingsStyled,
    UpdateTargetStyled,
    TargetHeaderStyled,
    DropdownsStyled,
    TargetContainer,
    MetricGraphStyled,
    DownDropStyled,
    MetricsDetailsModalStyled,
    Pad,
    BreathingContainer,
    FormattedVal,
    TableAnchor,
}
