import { generateNumberArray } from '@octup/core/utils'
import { Circle, NewsFeedBanner, WhatsNewFooterStyled } from './WhatsNewFooter.styles'

export const WhatsNewFooter = () => {
    return (
        <WhatsNewFooterStyled>
            <NewsFeedBanner>
                {generateNumberArray(8).map((el, index) => (
                    <li key={index}>
                        <Circle />
                        This is a breaking news update
                    </li>
                ))}
            </NewsFeedBanner>
        </WhatsNewFooterStyled>
    )
}
