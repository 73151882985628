import { useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { OTHER_SOURCE, TOTAL } from '@/constants/attribution'
import { useOctupDispatch } from '@/hooks'
import { CampaignsTableOrderCell } from '@/new_components/Attribution'
import { getTableConfig } from './CampaignsTableOrderCellContainer.utils'

export const CampaignsTableOrderCellContainer = ({
    ordersCount,
    source,
    campaignId,
    campaignName,
    campaignsCount,
    isSummaryRow,
    hasCustomCampaignName,
}) => {
    const octupDispatch = useOctupDispatch()

    const { data, isLoading } = useSelector((state) => state.orders)
    const {
        datePickerValues: { startDate, endDate },
    } = useSelector((state) => state.datePicker)

    const [showOrdersModal, setShowOrdersModal] = useState(false)

    const tableConfig = useMemo(
        () => getTableConfig({ source, campaignName, data, isLoading }),
        [source, campaignName, data, isLoading]
    )

    const isClickable = useMemo(
        () => source && source !== OTHER_SOURCE && source !== TOTAL && ordersCount > 0,
        [ordersCount, source]
    )

    const handleToggleModal = useCallback((event) => {
        event.stopPropagation()
        setShowOrdersModal((prevState) => !prevState)
    }, [])

    const handleOpenModal = useCallback(
        (event) => {
            handleToggleModal(event)

            octupDispatch.orders.getOrders({
                startDate,
                endDate,
                campaignId,
                campaignName: hasCustomCampaignName ? '' : campaignName,
                ...(source !== OTHER_SOURCE && { source }),
                ...(isSummaryRow && { summaryLine: isSummaryRow }),
            })
        },
        [
            campaignId,
            campaignName,
            endDate,
            handleToggleModal,
            hasCustomCampaignName,
            isSummaryRow,
            octupDispatch.orders,
            source,
            startDate,
        ]
    )

    return (
        <CampaignsTableOrderCell
            tableConfig={tableConfig}
            ordersCount={ordersCount}
            showOrdersModal={showOrdersModal}
            onOpenModal={handleOpenModal}
            onCloseModal={handleToggleModal}
            source={source}
            campaignName={campaignName}
            campaignsCount={campaignsCount}
            ordersData={data}
            isClickable={isClickable}
        />
    )
}
