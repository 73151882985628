import React from 'react'
import { getIconColor } from './utils'
export function StarIconSvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M9.33931 14.3386L13.219 16.8949C13.7149 17.2217 14.3306 16.7357 14.1834 16.1337L13.0625 11.5479C13.031 11.4201 13.0347 11.2857 13.0733 11.1601C13.1119 11.0345 13.1838 10.9228 13.2808 10.8378L16.7598 7.82632C17.2169 7.43062 16.981 6.64161 16.3937 6.60197L11.8503 6.29531C11.7279 6.28622 11.6106 6.24116 11.5119 6.16538C11.4132 6.0896 11.3372 5.98621 11.2928 5.86726L9.5983 1.42942C9.55224 1.30317 9.47034 1.19448 9.36347 1.11778C9.25661 1.04108 9.12984 1 9 1C8.87016 1 8.74339 1.04108 8.63653 1.11778C8.52967 1.19448 8.44777 1.30317 8.40171 1.42942L6.70722 5.86726C6.66282 5.98621 6.58685 6.0896 6.48814 6.16538C6.38944 6.24116 6.27206 6.28622 6.14969 6.29531L1.60631 6.60197C1.019 6.64161 0.783054 7.43062 1.24018 7.82632L4.71921 10.8378C4.81616 10.9228 4.88808 11.0345 4.92669 11.1601C4.96531 11.2857 4.96904 11.4201 4.93748 11.5479L3.89796 15.8007C3.72137 16.5231 4.46023 17.1063 5.05533 16.7142L8.66069 14.3386C8.76209 14.2715 8.8798 14.2358 9 14.2358C9.1202 14.2358 9.23791 14.2715 9.33931 14.3386V14.3386Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
