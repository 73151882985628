import React from 'react'
import { getIconColor } from './utils'

export function TrashSvg({ active, customActiveColor = '#007F82', customColor = '#007F82' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M18 7.3335L6 7.3335"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.364 10.8335V15.5002"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.6367 10.8335V15.5002"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.27307 5H14.7276"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.9091 7.3335V18.4168C16.9091 18.5715 16.8517 18.7199 16.7494 18.8293C16.6471 18.9387 16.5083 19.0002 16.3637 19.0002H7.6364C7.49173 19.0002 7.35299 18.9387 7.2507 18.8293C7.14841 18.7199 7.09094 18.5715 7.09094 18.4168V7.3335"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
