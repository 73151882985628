import { createContext, useState } from 'react'

export const IntroContext = createContext()
export const IntroContextProvider = ({ children }) => {
    const [enableIntro, setEnableIntro] = useState(false)
    return (
        <IntroContext.Provider value={{ enableIntro, setEnableIntro }}>
            {children}
        </IntroContext.Provider>
    )
}
