import { ERROR_MESSAGES } from 'constants/validation'
import { useCallback, useEffect, useMemo } from 'react'
import type { PayloadAction } from '@reduxjs/toolkit'
import { useToast } from 'hooks'
import type { DashboardFormType, DashboardType } from 'models/dashboards'
import { FormDashboardPage } from 'new_pages'
import { useNavigate } from 'react-router-dom'
import {
    clearDashboard,
    fetchTemplatesList,
    fetchUpdateDashboard,
    updateDashboardData,
    updateDashboardList,
} from 'state/features/dashboards'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { VALIDATION_SCHEMA, getDefaultValues } from './utils'

type FormDashboardPageContainerPropsType = {
    isNew?: boolean
    data?: DashboardType
    error?: string
    isLoading?: boolean
}

export const FormDashboardPageContainer = ({
    isNew,
    data,
    error: dataError,
    isLoading: isDataLoading,
}: FormDashboardPageContainerPropsType) => {
    const toast = useToast()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { metricsSectionsList } = useAppSelector((state) => state.newMetrics)
    const dashboards = useAppSelector((state) => state.dashboards)
    const { templatesList, dashboard, updatedDashboard, deletedDashboard } = dashboards
    const currentTemplate = data?.template || templatesList.default

    const error = dataError || metricsSectionsList.error
    const isLoading =
        isDataLoading ||
        templatesList.isLoading ||
        metricsSectionsList.isLoading ||
        updatedDashboard.isLoading ||
        deletedDashboard.isLoading

    useEffect(() => {
        if (!templatesList.data) {
            dispatch(fetchTemplatesList())
        }
        return () => {
            dispatch(clearDashboard())
        }
    }, [dispatch, templatesList.data])

    const handleSubmit = useCallback(
        (data: DashboardFormType) => dispatch(fetchUpdateDashboard(data)),
        [dispatch]
    )

    const handleSuccess = useCallback(
        ({ payload: updatedDashboard }: PayloadAction<DashboardType>) => {
            const { id, name } = updatedDashboard
            dispatch(updateDashboardList({ id: data?.id, newData: { id, name } }))
            dispatch(updateDashboardData({ id: data?.id, newData: updatedDashboard }))
            navigate(`/Dashboards/${id}`)
        },
        [dispatch, navigate, data]
    )

    const handleFail = useCallback(() => {
        toast.error(ERROR_MESSAGES.SERVER_ERROR)
    }, [toast])

    const defaultValues = useMemo(
        () => getDefaultValues(data, currentTemplate),
        [currentTemplate, data]
    )

    return (
        <FormDashboardPage
            isNew={isNew}
            error={error}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
            onFail={handleFail}
            defaultValues={defaultValues}
            validationSchema={VALIDATION_SCHEMA}
            template={currentTemplate}
            showMetricsSectionsList={!!dashboard.selectedDashboardSection}
        />
    )
}
