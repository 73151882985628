import { OrdersTable } from 'pages/operations/OrdersTable'
import { OctupBaseTable } from 'shared/ui/Tables/OctupBaseTable'
import { getDefaultTableConfig } from './defaultMetricConfig'
import { getSlaTableConfig } from './slaMetricConfig'
import { getCommonConfig } from './commonConfig'

const TABLE_DATA_BY_TYPE = {
    default: {
        getConfig: getDefaultTableConfig,
        TableComponent: OctupBaseTable,
    },
    sla: {
        getConfig: getSlaTableConfig,
        TableComponent: OrdersTable,
    },
}

export const getTableConfig = ({ data, selectedMetric, comparisonMetric }) => {
    if (!data || !selectedMetric) return {}

    const mainMetricTitles = [
        selectedMetric.metricTitle,
        ...(comparisonMetric ? [comparisonMetric.metricTitle] : []),
    ]

    const type = data.metric ? 'sla' : 'default'
    const { getConfig, ...dataByType } = TABLE_DATA_BY_TYPE[type]

    return {
        ...dataByType,
        tableConfig: {
            ...getCommonConfig({ selectedMetric }),
            ...getConfig({ data, selectedMetric, mainMetricTitles }),
        },
    }
}
