import { InvoicesRequestText } from 'new_components/__common__'
import { InvoicesRequestContainer } from 'new_containers/components/__common__'
import { intercom } from 'utils'

export const InvoicesRequestContent = () => (
    <InvoicesRequestContainer
        showEmail
        fontSize="inherit"
        spacing={1}
        fallback={
            <InvoicesRequestText
                buttonText="Contact us"
                secondaryText="for invoice processing"
                onClick={intercom.showNewMessage}
            />
        }
        primaryText="To ensure that we receive and process your invoices automatically, please instruct your
        warehouse to cc or forward all invoice emails to us at"
    />
)
