import { useCallback, useEffect, useMemo } from 'react'
import { useToast } from 'hooks'
import { useDispatch } from 'react-redux'
import { ERROR_MESSAGES } from 'constants'
import {
    getIntegrationsExtension,
    updateIntegration,
} from 'features/integrations/integrationsSlice'
import { useSelector } from 'react-redux'
import {
    convertToApi,
    getAccounts,
    getActionButtons,
    getDefaultValues,
} from './SelectAccountsModalContainer.utils'
import { SelectAccountsModal } from 'new_components'
import { FormContainer } from 'new_containers/components/__common__'
import { connectIntegrationAccounts } from 'state/features/integrations'

export const SelectAccountsModalContainer = ({ integration, integrationTitle, onClose }) => {
    const toast = useToast()
    const dispatch = useDispatch()
    const { integrationsExtension, integrationsExtensionIsLoading } = useSelector(
        (state) => state.integrations.integrationsList
    )

    useEffect(() => {
        dispatch(getIntegrationsExtension({ source: integration }))
    }, [dispatch, integration])

    const handleClose = useCallback(() => {
        toast.error(`${integrationTitle || ''} integration been canceled`)
        onClose()
    }, [integrationTitle, onClose, toast])

    const accounts = useMemo(
        () => getAccounts(integrationsExtension?.data || [], integration) || [],
        [integration, integrationsExtension?.data]
    )

    const actionButtons = useMemo(() => getActionButtons({ onCancel: handleClose }), [handleClose])
    const defaultValues = useMemo(() => getDefaultValues(accounts), [accounts])

    const handleSubmit = useCallback(
        (data) =>
            dispatch(
                connectIntegrationAccounts({
                    selection: convertToApi(data),
                    source: integration,
                })
            ),
        [dispatch, integration]
    )

    const handleUpdateIntegration = useCallback(() => {
        dispatch(updateIntegration({ isConnected: true, integration }))
    }, [dispatch, integration])

    const handleSuccess = useCallback(() => {
        onClose()
        handleUpdateIntegration()
        toast.success(`Successfully connected ${integrationTitle ? 'to ' + integrationTitle : ''}`)
    }, [handleUpdateIntegration, integrationTitle, onClose, toast])

    const handleFail = useCallback(() => {
        onClose()
        toast.error(ERROR_MESSAGES.SERVER_ERROR)
    }, [onClose, toast])

    const renderContent = useCallback(
        ({ content }) => (
            <FormContainer
                onSubmit={handleSubmit}
                onFail={handleFail}
                onSuccess={handleSuccess}
                defaultValues={defaultValues}
            >
                {content}
            </FormContainer>
        ),
        [defaultValues, handleFail, handleSubmit, handleSuccess]
    )

    return (
        <SelectAccountsModal
            onDismissModal={handleClose}
            buttons={actionButtons}
            accounts={accounts}
            isLoading={integrationsExtensionIsLoading}
            integration={integration}
            integrationTitle={integrationTitle}
            {...(!integrationsExtensionIsLoading && { renderContent })}
        />
    )
}
