export const HOME_TABLES_TYPES = {
    INSIGHTS: 'insights',
    OBJECTIVES: 'objectives',
}

export const HOME_TABLES_HEADERS = {
    STATUS: 'status',
    IMPORTANCE: 'importance',
    SEGMENT: 'segment',
    OWNER: 'owner',
}

export const ACTIONABLE_ITEM_IMPORTANCE = {
    HIGH: 'high',
    MEDIUM_SHORT: 'med',
    MEDIUM_LONG: 'medium',
    LOW: 'low',
}

export const ACTIONABLE_ITEM_STATUS = {
    IN_PROGRESS: 'in progress',
    OVERDUE: 'overdue',
    COMPLETED: 'completed',
}

export const ACTIONABLE_ITEM_SEGMENTS = {
    MARKETING: 'Marketing',
    SHIPPING: 'Shipping',
    PRODUCT: 'Product',
}

export const ACTIONABLE_ITEM_ORIGIN = {
    MANUAL: 'Manual',
    SIMULATOR: 'Simulator',
    INSIGHT: 'Insight',
}

export const DATA_STATUS = {
    NO_DATA: 'no dataset',
}

export const DOT_INDICATOR_TYPES = {
    ALERT: 'alert',
    WARNING: 'warning',
    INFO: 'info',
}

export const SIMULATOR_OPTIONS = {
    GENERAL: 'general',
    UNIT_ECONOMICS: 'unit economics',
    MARKETING: 'marketing',
    OPERATIONS: 'operations',
}

export const compareStrings = (...args) => {
    if (args.length === 0 || args === undefined || typeof args[0] !== 'string') return
    const str = args[0] ? args[0]?.toLowerCase() : null
    for (const string of args) {
        if (string === undefined || string?.toLowerCase() !== str) return false
    }
    return true
}

export const add3Dots = (label = '', wantedLength = 40) => {
    if (!label) {
        return ''
    }
    if (label.length > wantedLength) {
        const newLabel = label.slice(0, wantedLength).concat('...')
        return newLabel
    }
    return label
}

export const isString = (element) => typeof element === 'string' || element instanceof String

export const formatNumberAsCurrency = (val) => {
    if (val !== 0 && !val) return val
    return val.replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const convertStringFormat = (inputString) => {
    // Replace underscores with spaces and convert to lowercase
    const lowercaseString = inputString.toLowerCase().replace(/_/g, ' ')

    // Capitalize the first letter of each word
    const formattedString = lowercaseString.replace(/(^|\s)\S/g, (match) => match.toUpperCase())

    return formattedString
}

export const containsUppercase = (str) => {
    return /[A-Z]/.test(str)
}

export const containsLowerCase = (str) => {
    return /[a-z]/.test(str)
}

export const containsNumber = (str) => {
    return /\d/.test(str)
}

export const containsSpecialChars = (str) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    return specialChars.test(str)
}

export const removeHashFromString = (str) => {
    if (typeof str !== 'string') {
        throw new Error('Input must be a string.')
    }

    if (str.startsWith('#')) {
        return str.substring(1)
    } else {
        return str
    }
}

export function extractNumberFromString(inputString) {
    const regex = /\d+(\.\d+)?/ // Regular expression to match numbers
    const match = inputString.match(regex)

    if (match) {
        return parseFloat(match[0])
    } else {
        return NaN // Return NaN if no number is found in the string
    }
}

export function convertToTitleCase(title) {
    // Split the title into words using underscores as the delimiter
    const words = title.split('_')

    // Capitalize the first letter of each word and join them with a space
    const titleCase = words
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1)
        })
        .join(' ')

    return titleCase
}

export function getCurrencySign(currencyCode) {
    const currencySigns = {
        AFN: '؋',
        EUR: '€',
        ALL: 'L',
        DZD: 'د.ج',
        USD: '$',
        AOA: 'Kz',
        XCD: '$',
        ARS: '$',
        AMD: '֏',
        AWG: 'ƒ',
        AUD: '$',
        AZN: '₼',
        BSD: '$',
        BHD: '.د.ب',
        BDT: '৳',
        BBD: '$',
        BYN: 'Br',
        BZD: 'BZ$',
        XOF: 'CFA',
        BMD: '$',
        BTN: 'Nu.',
        BOB: 'Bs.',
        BOV: 'BOV',
        BAM: 'KM',
        BWP: 'P',
        BRL: 'R$',
        BND: '$',
        BGN: 'лв',
        BIF: 'FBu',
        CVE: '$',
        KHR: '៛',
        XAF: 'CFA',
        CAD: '$',
        KYD: '$',
        CLP: '$',
        CLF: 'UF',
        CNY: '¥',
        COP: '$',
        COU: 'COU',
        KMF: 'CF',
        CDF: 'FC',
        NZD: '$',
        CRC: '₡',
        HRK: 'kn',
        CUP: '₱',
        CUC: '$',
        ANG: 'ƒ',
        CZK: 'Kč',
        DKK: 'kr',
        DJF: 'Fdj',
        DOP: 'RD$',
        EGP: '£',
        SVC: '$',
        ERN: 'Nfk',
        ETB: 'Br',
        FKP: '£',
        FJD: '$',
        GMD: 'D',
        GEL: '₾',
        GHS: 'GH₵',
        GIP: '£',
        GTQ: 'Q',
        GGP: '£',
        GNF: 'FG',
        GYD: '$',
        HTG: 'G',
        HNL: 'L',
        HKD: '$',
        HUF: 'Ft',
        ISK: 'kr',
        INR: '₹',
        IDR: 'Rp',
        XDR: 'SDR',
        IRR: '﷼',
        IQD: 'ع.د',
        IMP: '£',
        ILS: '₪',
        JMD: 'J$',
        JPY: '¥',
        JEP: '£',
        JOD: 'د.ا',
        KZT: '₸',
        KES: 'KSh',
        KPW: '₩',
        KRW: '₩',
        KWD: 'د.ك',
        KGS: 'лв',
        LAK: '₭',
        LBP: 'ل.ل',
        LSL: 'L',
        ZAR: 'R',
        LRD: '$',
        LYD: 'ل.د',
        CHF: 'CHF',
        MOP: 'P',
        MKD: 'ден',
        MGA: 'Ar',
        MWK: 'MK',
        MYR: 'RM',
        MVR: 'Rf',
        MRU: 'UM',
        MUR: '₨',
        XUA: 'XUA',
        MXN: '$',
        MXV: 'MXV',
        MDL: 'L',
        MNT: '₮',
        MAD: 'MAD',
        MZN: 'MT',
        MMK: 'K',
        NAD: '$',
        NPR: '₨',
        NIO: 'C$',
        NGN: '₦',
        OMR: 'ر.ع.',
        PKR: '₨',
        PAB: 'B/.',
        PGK: 'K',
        PYG: '₲',
        PEN: 'S/.',
        PHP: '₱',
        PLN: 'zł',
        QAR: 'ر.ق',
        RON: 'lei',
        RUB: '₽',
        RWF: 'RF',
        SHP: '£',
        WST: 'WS$',
        SAR: 'ر.س',
        RSD: 'дин.',
        SCR: '₨',
        SLL: 'Le',
        SGD: '$',
        SXM: '$',
        SBD: '$',
        SOS: 'S',
        SSP: '£',
        LKR: '₨',
        SDG: 'ج.س.',
        SRD: '$',
        SZL: 'E',
        SEK: 'kr',
        CHE: 'CHE',
        CHW: 'CHW',
        SYP: '£',
        TWD: 'NT$',
        TJS: 'SM',
        TZS: 'TSh',
        THB: '฿',
        TOP: 'T$',
        TTD: 'TT$',
        TND: 'د.ت',
        TRY: '₺',
        TMT: 'T',
        UGX: 'USh',
        UAH: '₴',
        AED: 'د.إ',
        GBP: '£',
        USN: 'USN',
        UYU: '$',
        UYI: 'UYI',
        UYW: 'UYW',
        UZS: 'лв',
        VUV: 'Vt',
        VES: 'Bs',
        VND: '₫',
        YER: '﷼',
        ZMW: 'ZK',
        ZWL: 'Z$',
        XTS: 'XTS',
        XXX: 'XXX',
        XAU: 'Gold',
        XPD: 'Palladium',
        XPT: 'Platinum',
        XAG: 'Silver',
    }

    return currencySigns[currencyCode] || ''
}
