import { Grid } from '@mui/material'
import { Text } from 'shared/styledComponents'
import { CardExpendable } from 'shared/ui/Card/CardExpendable'
import { cardContentStyle, containerStyle } from './ChartCard.styles'

export const ChartCard = ({ title, actions, chartComponent: Chart, chartConfig }) => (
    <Grid item container md={12} lg={6} sx={containerStyle}>
        <CardExpendable>
            <Grid container direction="column" wrap="nowrap" rowSpacing={2} sx={cardContentStyle}>
                <Grid
                    item
                    container
                    alignSelf="flex-start"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Text fontSize="1.8rem">{title}</Text>
                    </Grid>
                    {actions && <Grid item>{actions}</Grid>}
                </Grid>
                <Grid item xs={12} container>
                    <Chart {...chartConfig} />
                </Grid>
            </Grid>
        </CardExpendable>
    </Grid>
)
