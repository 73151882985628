// import { OctupColors } from "../../theme/colors/OctupColors";

export function MobileSignOut({ active, customActiveColor = '#DB2059', customColor = '#DB2059' }) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.6614 8.65918L19.0023 12.0001L15.6614 15.341"
                stroke="#F8F7FB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.0909 12H19"
                stroke="#F8F7FB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.3636 19H5.63636C5.46759 19 5.30573 18.933 5.18639 18.8136C5.06705 18.6943 5 18.5324 5 18.3636V5.63636C5 5.46759 5.06705 5.30573 5.18639 5.18639C5.30573 5.06705 5.46759 5 5.63636 5H11.3636"
                stroke="#F8F7FB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
