import { createSlice } from '@reduxjs/toolkit'
import {
    addDays,
    addMonths,
    addWeeks,
    addYears,
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    parseISO,
    startOfMonth,
    startOfWeek,
    startOfYear,
} from 'date-fns'

export const DATE_OPTIONS = {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    THIS_WEEK: 'This Week',
    LAST_WEEK: 'Last Week',
    THIS_MONTH: 'This Month',
    LAST_MONTH: 'Last Month',
    THIS_QUARTER: 'This Quarter',
    LAST_QUARTER: 'Last Quarter',
    THIS_YEAR: 'This Year',
    LAST_YEAR: 'Last Year',
    CUSTOM: 'Custom',
}

const UP_TO_DATE = true
export const getDefaultDatePickerObj = (obj) => {
    const label = obj?.label
    const today = new Date()
    switch (label?.toLowerCase()) {
        case DATE_OPTIONS.TODAY.toLowerCase():
            return {
                startDate: today,
                endDate: today,
            }
        case DATE_OPTIONS.YESTERDAY.toLowerCase():
            const yesterday = addDays(today, -1)
            return {
                startDate: yesterday,
                endDate: yesterday,
            }
        case DATE_OPTIONS.LAST_WEEK.toLowerCase():
            const lastWeek = addWeeks(today, -1)
            return {
                startDate: startOfWeek(lastWeek),
                endDate: endOfWeek(lastWeek),
            }
        case DATE_OPTIONS.THIS_WEEK.toLowerCase():
            return {
                startDate: startOfWeek(today),
                endDate: UP_TO_DATE ? endOfDay(today) : endOfWeek(today),
            }
        case DATE_OPTIONS.LAST_MONTH.toLowerCase():
            const lastMonth = addMonths(today, -1)
            return {
                startDate: startOfMonth(lastMonth),
                endDate: endOfMonth(lastMonth),
            }
        case DATE_OPTIONS.THIS_MONTH.toLowerCase():
            return {
                startDate: startOfMonth(today),
                endDate: UP_TO_DATE ? endOfDay(today) : endOfMonth(today),
            }
        case DATE_OPTIONS.LAST_YEAR.toLowerCase():
            const lastYear = addYears(today, -1)
            return {
                startDate: startOfYear(lastYear),
                endDate: endOfYear(lastYear),
            }
        case DATE_OPTIONS.THIS_YEAR.toLowerCase():
            return {
                startDate: startOfYear(today),
                endDate: UP_TO_DATE ? endOfDay(today) : endOfYear(lastYear),
            }
        default:
            let dates = JSON.parse(sessionStorage.getItem('datepicker'))
            if (dates) {
                return {
                    startDate: parseISO(dates?.startDate),
                    endDate: parseISO(dates?.endDate),
                }
            } else {
                const lastWeek = addWeeks(today, -1)
                return {
                    startDate: startOfWeek(lastWeek),
                    endDate: endOfWeek(lastWeek),
                }
            }
    }
}

export const formatDateWithDashes = (date) => format(new Date(date), 'yyyy-MM-dd')

let localStorageDatePickerValues = sessionStorage.getItem('datepicker')
    ? JSON.parse(sessionStorage.getItem('datepicker'))
    : null

if (!localStorageDatePickerValues) {
    const defaultLabel = DATE_OPTIONS.LAST_WEEK
    const defaultDatePicker = getDefaultDatePickerObj({ label: defaultLabel })

    localStorageDatePickerValues = {
        startDate: formatDateWithDashes(defaultDatePicker.startDate),
        endDate: formatDateWithDashes(defaultDatePicker.endDate),
        label: defaultLabel,
    }

    sessionStorage.setItem('datepicker', JSON.stringify(localStorageDatePickerValues))
}

const initialState = { datePickerValues: localStorageDatePickerValues }

export const datePickerSlice = createSlice({
    name: 'datePicker',
    initialState,
    reducers: {
        changeDates(state, action) {
            const newValues = action.payload
            state.datePickerValues = newValues
            sessionStorage.setItem('datepicker', JSON.stringify(newValues))
        },
    },
})

export const datePickerActions = datePickerSlice.actions
export const datePickerState = (state) => state.datePicker
export const datePickerSliceReducer = datePickerSlice.reducer
