import { differenceInDays, parseISO } from 'date-fns'
import { calculateAverage } from 'utils'
import { DATA_COLLECTION_YEARS } from '@/constants/integrations'
import type {
    CollectionDataPercentageType,
    IntegrationsStatusType,
    IntegrationMetricType,
    IntegrationNameType,
} from '@/models/integrations'

const DAYS_IN_YEAR = 365
const TOTAL_DAYS = DAYS_IN_YEAR * DATA_COLLECTION_YEARS

export const getCollectionDataPercentage = (
    data: IntegrationsStatusType
): CollectionDataPercentageType => {
    const currentDate = new Date()
    const integrationsEntries = Object.entries(data)

    const { integrations, integrationsPercentageSum } = integrationsEntries.reduce(
        (acc, integration) => {
            let metricsPercentageSum = 0
            const [name, metrics] = integration as [IntegrationNameType, IntegrationMetricType]
            const metricsData = Object.values(metrics)

            metricsData.forEach((metricData) => {
                const recentHarvestDate = parseISO(metricData.recent_harvest_date)
                const backlogHarvestDate = metricData.backlog_harvest_date
                    ? parseISO(metricData.backlog_harvest_date)
                    : currentDate

                const daysFromRecentHarvest = differenceInDays(currentDate, recentHarvestDate)
                const daysFromBacklogHarvest = differenceInDays(currentDate, backlogHarvestDate)

                if (daysFromBacklogHarvest > TOTAL_DAYS) {
                    const metricPercentage = 1
                    metricsPercentageSum += metricPercentage
                    return
                }

                const harvestTotal = TOTAL_DAYS - (daysFromBacklogHarvest + daysFromRecentHarvest)
                const metricPercentage = (harvestTotal / TOTAL_DAYS) * 100
                metricsPercentageSum += metricPercentage
            })

            const metricsAverage = calculateAverage(metricsPercentageSum, metricsData.length)
            acc.integrations[name] = metricsAverage
            acc.integrationsPercentageSum += metricsAverage
            return acc
        },
        {
            integrations: {} as CollectionDataPercentageType['integrations'],
            integrationsPercentageSum: 0,
        }
    )

    const average = calculateAverage(integrationsPercentageSum, integrationsEntries.length)

    return { integrations, average }
}
