import { Stack, Typography } from '@octup/core/ui-kit'
import type { AppBenefitType, AppSettingsType } from '@/models/appConfig'

type AppBenefitsInfoPropsType = {
    name: AppSettingsType['display_name']
    benefits: AppBenefitType[]
}

export const AppBenefitsInfo = ({ name, benefits }: AppBenefitsInfoPropsType) => (
    <Stack spacing={8}>
        <Typography variant="h2" fontWeight={400}>
            {name} is designed to help you be more productive and efficient, so you can focus on
            what matters most.
        </Typography>
        <Typography fontWeight={600}>
            We offer a wide range of tools and resources to help you manage your business,
            collaborate with your team, and achieve your goals.
        </Typography>
        {benefits.map(({ id, title, icon: Icon }) => (
            <Stack key={id} direction="row" alignItems="center" spacing={4}>
                <Icon />
                <Typography>{title}</Typography>
            </Stack>
        ))}
    </Stack>
)
