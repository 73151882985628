import { WhatsNewSlideStyled } from './WhatsNewSlide.styled'
import { useMemo } from 'react'

const WhatNewContentBG = [
    'linear-gradient(225deg, #FFCF72 0%, #FF3489 100%)',
    'linear-gradient(225.61deg, #FF3489 -2.19%, #7737FF 101.11%)',
    'linear-gradient(224.13deg, #7737FF -0.95%, #00A8A8 100%)',
    'linear-gradient(44.56deg, #007F82 -1.25%, #00A8A8 100.79%)',
]
export const WhatsNewSlide = ({ children, slide }) => {
    const whatsNewContentBG = useMemo(() => WhatNewContentBG[slide % 4 || 0], [slide])
    const className = useMemo(() => (slide % 4 === 0 ? 'dark-text' : ''), [slide])
    return (
        <WhatsNewSlideStyled WhatNewContentBG={whatsNewContentBG} className={className}>
            {children}
        </WhatsNewSlideStyled>
    )
}
