import type { Components, Theme } from '@mui/material'

type IconButtonOverridesType = Pick<Components<Theme>, 'MuiIconButton'>

export const ICON_BUTTON_OVERRIDES: IconButtonOverridesType = {
    MuiIconButton: {
        styleOverrides: {
            sizeMedium: ({ theme }) => ({
                padding: theme.spacing(1),
            }),
        },
    },
}
