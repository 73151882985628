import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { extractErrMsg } from '../../shared/utils/utils'
import { usersService } from './usersServices'
import { GLOBAL_CONFIG } from '../../config'

const API_URL = GLOBAL_CONFIG.DAL_BASE_URL + 'user'

const initialState = {
    users: [],
    usersMap: [],
    isLoading: false,
    isRejected: false,
}

export const getUsers = createAsyncThunk(`${API_URL}`, async (_, thunkAPI) => {
    try {
        const token = thunkAPI?.getState()?.auth?.userInfo?.token
        return await usersService.getUsers(token)
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        deleteUser(state, action) {
            state.users = [...state.users.filter((el) => el.id !== action.payload)]
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUsers.rejected, (state) => {
                state.isLoading = false
                state.isRejected = true
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.usersMap = action.payload.data || {}
                state.users =
                    (action.payload.data &&
                        Object.keys(action.payload.data).map((key) => {
                            return {
                                ...action.payload.data[key],
                                initials: action.payload.data[key].email.slice(0, 2).toUpperCase(),
                            }
                        })) ||
                    []
            })
    },
})

export const usersActions = usersSlice.actions
export const usersState = (state) => state.users
export const usersSliceReducer = usersSlice.reducer
