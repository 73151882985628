import { useCallback, useMemo } from 'react'
import { Breadcrumbs, Grid, Stack, SvgIcon } from '@mui/material'
import { InfoIcon } from '@octup/core/assets/icons'
import { PATH_BY_TITLE } from 'components/Sidebar/pageTitles'
import { useOctupDispatch } from 'hooks/useOctupDispatch'
import { useLocation } from 'react-router-dom'
import { ChevronRightSvg } from 'shared/assets/icons'
import { octupDictionary } from 'shared/dictionary/dictionary'
import { OctupColors } from 'shared/theme/colors/OctupColors'
import { OctupBadge } from 'shared/ui/Badges/OctupBadge'
import { OctupDatePicker } from 'shared/ui/DatePicker/OctupDatePicker'
import { OctupTooltip } from 'shared/ui/MUIWrappers/OctupTooltip'
import { Title } from './PageTitle.styles'
import { TitleBreadcrumb } from './TitleBreadcrumb'

const PageTitle = ({
    hasCustomTitle = false,
    customTitleStyled,
    showDatePicker = true,
    mainPanel,
    sidePanel,
    sidePanelTools,
    showSidePanel = true,
    infoTooltip,
    badgeConfig,
    customPathnames,
    datePickerConfig,
}) => {
    const location = useLocation()
    const octupDispatch = useOctupDispatch()

    const pathnames = useMemo(
        () => customPathnames || location.pathname.split('/').filter((name) => name),
        [customPathnames, location.pathname]
    )

    const handleLinkClick = useCallback(() => {
        octupDispatch.sidebar.setActiveSubMenuItem()
    }, [octupDispatch.sidebar])

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            gap="2rem"
            sx={{ margin: '5rem 0 2rem 0' }}
        >
            <Stack direction="row" alignItems="center" gap="1rem" flexWrap="wrap">
                {hasCustomTitle || customTitleStyled ? (
                    <Title>{customTitleStyled}</Title>
                ) : (
                    <Breadcrumbs
                        separator={<ChevronRightSvg customColor={OctupColors.primaryTextLight} />}
                    >
                        {pathnames.map((pathname, index) => (
                            <TitleBreadcrumb
                                key={pathname}
                                path={PATH_BY_TITLE[pathname]}
                                title={octupDictionary.pages[pathname]}
                                isMainPage={pathnames.length === 1}
                                isLastItem={index === pathnames.length - 1}
                                onClick={handleLinkClick}
                            />
                        ))}
                    </Breadcrumbs>
                )}
                {infoTooltip && (
                    <OctupTooltip title={infoTooltip} position="top">
                        <SvgIcon component={InfoIcon} color="text.primary" />
                    </OctupTooltip>
                )}
                {badgeConfig && (
                    <OctupTooltip isContainerized title={badgeConfig.tooltip}>
                        <OctupBadge title={badgeConfig.title} theme={badgeConfig.theme} />
                    </OctupTooltip>
                )}
                {mainPanel}
            </Stack>
            {showSidePanel && (
                <Grid item container xs gap="2rem" alignItems="center" justifyContent="flex-end">
                    {sidePanel && <Grid item>{sidePanel}</Grid>}
                    {showDatePicker && (
                        <Grid item>
                            <OctupDatePicker {...datePickerConfig} />
                        </Grid>
                    )}
                    {sidePanelTools && <Grid item>{sidePanelTools}</Grid>}
                </Grid>
            )}
        </Grid>
    )
}

export default PageTitle
