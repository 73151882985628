import { getIconColor } from './utils'
export function ActionsBtnMarketingIcon({
    active,
    customActiveColor = '#504B5A',
    customColor = '#504B5A',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M13.6 8.7182V18.7563C13.6 18.8632 13.626 18.9684 13.6757 19.0626C13.7255 19.1568 13.7973 19.2371 13.885 19.2964L14.7644 19.891C14.8497 19.9487 14.9475 19.9847 15.0494 19.9961C15.1514 20.0075 15.2545 19.9938 15.3502 19.9563C15.4458 19.9188 15.5312 19.8585 15.5991 19.7806C15.667 19.7026 15.7154 19.6092 15.7403 19.5083L16.8 15.2091"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.8 15.2092C17.6487 15.2092 18.4626 14.8672 19.0627 14.2586C19.6629 13.6499 20 12.8244 20 11.9637C20 11.1029 19.6629 10.2774 19.0627 9.6688C18.4626 9.06015 17.6487 8.71822 16.8 8.71822H13.6C13.6 8.71822 9.24386 8.71822 5.05192 5.15246C4.95869 5.07287 4.84499 5.02195 4.72419 5.00566C4.6034 4.98937 4.48053 5.00841 4.37004 5.06053C4.25955 5.11264 4.16603 5.19568 4.10047 5.29986C4.03492 5.40404 4.00006 5.52504 4 5.64863V18.2787C4.00006 18.4023 4.03492 18.5233 4.10047 18.6275C4.16603 18.7317 4.25955 18.8147 4.37004 18.8668C4.48053 18.919 4.6034 18.938 4.72419 18.9217C4.84499 18.9054 4.95869 18.8545 5.05192 18.7749C9.24386 15.2092 13.6 15.2092 13.6 15.2092H16.8Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
