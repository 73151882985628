import { Stack } from '@octup/core/ui-kit'
import {
    FormFieldArrayContainer,
    FormFieldContainer,
    SubmitFormButtonContainer,
} from '@/new_containers/components/__common__'
import { WeekdaySelect } from './WeekdaySelect'
import { WorkingWeekdaysField } from './WorkingWeekdaysField'

export const WorkingHoursSettingsForm = () => (
    <Stack spacing={5} justifyContent="space-between">
        <Stack spacing={8}>
            <FormFieldArrayContainer as={WorkingWeekdaysField} name="hours_mapping" />
            <FormFieldContainer
                name="first_day"
                as={WeekdaySelect}
                label="First Working Day"
                fullWidth
            />
        </Stack>
        <SubmitFormButtonContainer />
    </Stack>
)
