import styled from 'styled-components'
import { ButtonIcon } from '../../Buttons/ButtonIcon'
import { CardExpendable } from '../../Card/CardExpendable'
import { CloseIconSvg } from '../../../assets/icons/CloseIconSvg'
import { EditIconSvg } from '../../../assets/icons/EditIconSvg'
import { useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useOctupDispatch } from '../../../../hooks'
import { WindowEnlarge } from '../../../assets/icons/WindowEnlarge'
import { WindowRestore } from '../../../assets/icons/WindowRestore'
import { Button } from '@octup/core/ui-kit'

export function OctupModalOverlay({
    children,
    customStyle,
    onDismissModal,
    isEditMode,
    setIsEditMode,
    headerTitle = '',
    buttons = [],
    showButtons = true,
    showCloseBtn = true,
    isForm = false,
    cardBackground,
    topIconButtons,
    footer,
    isCloseable = false,
    disableCloseOnEsc = false,
    submitHandler = (e) => {
        e.preventDefault()
        alert('DEFAULT: submitted')
    },
    renderContent,
    isExpendable,
    sidePanel,
    ...props
}) {
    const octupDispatch = useOctupDispatch()
    // TODO:
    // 1. replace MetricsDetailsModal to DeepDiveModal
    // 2. delete MetricsDetailsModal
    // 3. control isExpended mode here instead of from metrics reducer
    const metrics = useSelector((state) => state.metrics)
    const isExpended = isExpendable && metrics.isExpended

    const handleExpand = useCallback(
        (value) => {
            if (isExpendable) {
                octupDispatch.metrics.setIsExpended(value)
            }
        },
        [isExpendable, octupDispatch.metrics]
    )

    const escFunction = useCallback(
        (event) => {
            if (event.key === 'Escape' && isCloseable && !disableCloseOnEsc) {
                handleExpand(false)
                onDismissModal(event)
            }
            event.stopPropagation()
        },
        [disableCloseOnEsc, handleExpand, isCloseable, onDismissModal]
    )

    useEffect(() => {
        document.addEventListener('keydown', escFunction, true)
        return () => {
            document.removeEventListener('keydown', escFunction, true)
        }
    }, [escFunction])

    const handleModalClick = useCallback((event) => {
        event.stopPropagation()
    }, [])

    const modalContent = (
        <>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>
                {footer}
                {showButtons && (
                    <ModalFooterButtonsContainer>
                        {buttons.map((el) => (
                            <Button key={el.title} {...el}>
                                {el.title}
                            </Button>
                        ))}
                    </ModalFooterButtonsContainer>
                )}
            </ModalFooter>
        </>
    )

    return (
        <ModalContainer style={customStyle} onClick={handleModalClick}>
            <CardExpendable background={cardBackground} hideShadow={true} customRadius={'2rem'}>
                <ModalStyled isExpended={isExpended} {...props}>
                    {(headerTitle || showCloseBtn) && (
                        <ModalHeader>
                            {headerTitle && <ModalHeaderTitle>{headerTitle}</ModalHeaderTitle>}
                            <HeaderModalBtnsContainer>
                                {sidePanel}
                                {isEditMode === false && (
                                    <ButtonIcon
                                        customIconColor={'#333'}
                                        tooltipContent={'Edit'}
                                        mainIcon={EditIconSvg}
                                        onClick={() => setIsEditMode(true)}
                                    />
                                )}
                                {isExpendable && (
                                    <ButtonIcon
                                        tooltipContent={isExpended ? 'Restore' : 'Expand'}
                                        mainIcon={isExpended ? WindowRestore : WindowEnlarge}
                                        onClick={() => handleExpand(!isExpended)}
                                    />
                                )}
                                {topIconButtons &&
                                    topIconButtons.map((el) => (
                                        <ButtonIcon
                                            mainIcon={el.mainIcon}
                                            tooltipContent={el.tooltipContent}
                                            onClick={() => el.onClick()}
                                        />
                                    ))}
                                {showCloseBtn && (
                                    <ButtonIcon
                                        mainIcon={CloseIconSvg}
                                        onClick={(event) => {
                                            handleExpand(false)
                                            onDismissModal(event)
                                        }}
                                    />
                                )}
                            </HeaderModalBtnsContainer>
                        </ModalHeader>
                    )}

                    {/* 
                        NOTE: Temporary solution to wrap the content into a new form structure. 
                        Will remove it after integrating to MUI and start using https://mui.com/material-ui/react-dialog/
                    */}
                    {renderContent ? (
                        renderContent({ content: modalContent })
                    ) : isForm ? (
                        <form onSubmit={submitHandler}>{modalContent}</form>
                    ) : (
                        modalContent
                    )}
                </ModalStyled>
            </CardExpendable>
        </ModalContainer>
    )
}

const ModalContainer = styled.div`
    position: fixed;
    z-index: 1500;
    overflow: visible;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
`

const ModalStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${({ isExpended, position = 'center' }) =>
        isExpended ? 'flex-start' : position};
    padding: 1.5rem;
    width: ${({ isExpended, width = 'fit-content' }) => (isExpended ? '100vw' : width)};
    height: ${({ isExpended, height }) => (isExpended ? '100vh' : height)};
    min-height: ${({ isExpended, minHeight = 'fit-content' }) =>
        isExpended ? '100vh' : minHeight};
    max-height: ${({ isExpended, maxHeight = '90vh' }) => (isExpended ? '100vh' : maxHeight)};
    position: relative;
`

const ModalHeader = styled.header`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
    color: #504b5a;
    width: 100%;
    height: fit-content;
    min-height: 4rem;
`

const ModalBody = styled.div`
    max-height: calc(100vh - 5rem);
    max-width: 100%;
    width: 100%;
    min-height: 10rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
`

const ModalFooter = styled.footer`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * {
        width: 100%;
    }
`

const ModalHeaderTitle = styled.div`
    font-size: 2rem;
    line-height: 2.3rem;
`

const ModalFooterButtonsContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
`

const HeaderModalBtnsContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    gap: 1.5rem;
    right: 0;
    z-index: 20;
`
