import React from 'react'

export function HamburgerWithLeftArrowSvg() {
    return (
        <svg
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.42857 15L6 12L9.42857 9"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M14 6H17" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M12 12H17" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M14 18H17" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    )
}
