import React from 'react'
import { OctupColors } from '../../theme/colors/OctupColors'

export function GoToWebsiteIconSvg({
    active,
    customActiveColor = OctupColors.primaryTextLight,
    customColor = OctupColors.primaryTextLight,
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M5.33333 16.4546H18.6667C19.403 16.4546 20 15.8847 20 15.1818V6.27274C20 5.56983 19.403 5.00001 18.6667 5.00001L5.33333 5.00001C4.59695 5.00001 4 5.56983 4 6.27274V15.1818C4 15.8847 4.59695 16.4546 5.33333 16.4546Z"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.6667 19H9.33337"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 13.2727H20"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 16.4546V19"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
