import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { parse } from 'date-fns'
import { useQueryString } from 'hooks'
import { CalendarHeader, CalendarLayout, InvoicesDatePicker } from 'new_components/__common__'

const SLOTS = { calendarHeader: CalendarHeader, layout: CalendarLayout }

export const InvoicesDatePickerContainer = () => {
    const { data, isLoading } = useSelector((state) => state.unitEconomics.invoicesDates)
    const { queryParams, setQueryParams } = useQueryString()
    const slotProps = useMemo(() => ({ layout: { availableMonths: data } }), [data])
    const handleChange = useCallback((date) => setQueryParams({ date }), [setQueryParams])

    useEffect(() => {
        if (!queryParams.date && !isLoading && data) {
            const date = data?.length
                ? parse(data[data.length - 1], 'yyyy-MM', new Date())
                : new Date()
            handleChange(date)
        }
    }, [data, handleChange, isLoading, queryParams.date])

    return (
        <InvoicesDatePicker
            defaultValue={queryParams.date}
            onAccept={handleChange}
            isLoading={isLoading}
            slotProps={slotProps}
            slots={SLOTS}
        />
    )
}
