import React from 'react'
import { getIconColor } from './utils'

export function SingleOrderCustomerAddressSvg({
    active,
    customActiveColor = '#007F82',
    customColor = '#007F82',
}) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.9497 19.3872V9.96894C19.9497 9.86444 19.9279 9.7611 19.8856 9.66553C19.8433 9.56997 19.7815 9.48429 19.7042 9.41399L12.2037 2.59508C12.0656 2.46956 11.8858 2.40002 11.6992 2.40002C11.5126 2.40003 11.3327 2.46959 11.1946 2.59511L3.69515 9.414C3.61784 9.48429 3.55608 9.56997 3.51381 9.66552C3.47154 9.76108 3.44971 9.86442 3.44971 9.96891V19.3872"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.9497 19.3871V13.3871H9.44971V19.3871"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.19971 20.5872H22.1997"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
