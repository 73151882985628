export function AdrollIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask id="path-1-inside-1_1571_13912" fill="white">
                <path d="M12.3333 20C13.7526 20 15.1462 19.6225 16.3714 18.9061C17.5965 18.1897 18.6091 17.1604 19.3051 15.9236C20.0012 14.6867 20.3557 13.287 20.3322 11.868C20.3088 10.449 19.9083 9.06174 19.1719 7.84856C18.4354 6.63539 17.3895 5.63998 16.1413 4.96443C14.8932 4.28889 13.4878 3.95753 12.0694 4.00436C10.6509 4.05118 9.27049 4.4745 8.06964 5.23089C6.86878 5.98728 5.89076 7.0495 5.2359 8.3086L9.66089 10.6101C9.90747 10.136 10.2757 9.73599 10.7279 9.45118C11.1801 9.16638 11.6999 9.00698 12.2339 8.98935C12.768 8.97172 13.2972 9.09649 13.7672 9.35085C14.2371 9.60522 14.631 9.98003 14.9083 10.4368C15.1856 10.8936 15.3364 11.416 15.3452 11.9503C15.354 12.4846 15.2206 13.0117 14.9585 13.4774C14.6964 13.9431 14.3151 14.3307 13.8538 14.6004C13.3925 14.8701 12.8677 15.0123 12.3333 15.0123L12.3333 20Z" />
            </mask>
            <path
                d="M12.3333 20C13.7526 20 15.1462 19.6225 16.3714 18.9061C17.5965 18.1897 18.6091 17.1604 19.3051 15.9236C20.0012 14.6867 20.3557 13.287 20.3322 11.868C20.3088 10.449 19.9083 9.06174 19.1719 7.84856C18.4354 6.63539 17.3895 5.63998 16.1413 4.96443C14.8932 4.28889 13.4878 3.95753 12.0694 4.00436C10.6509 4.05118 9.27049 4.4745 8.06964 5.23089C6.86878 5.98728 5.89076 7.0495 5.2359 8.3086L9.66089 10.6101C9.90747 10.136 10.2757 9.73599 10.7279 9.45118C11.1801 9.16638 11.6999 9.00698 12.2339 8.98935C12.768 8.97172 13.2972 9.09649 13.7672 9.35085C14.2371 9.60522 14.631 9.98003 14.9083 10.4368C15.1856 10.8936 15.3364 11.416 15.3452 11.9503C15.354 12.4846 15.2206 13.0117 14.9585 13.4774C14.6964 13.9431 14.3151 14.3307 13.8538 14.6004C13.3925 14.8701 12.8677 15.0123 12.3333 15.0123L12.3333 20Z"
                stroke="#0DBCFF"
                strokeWidth="32"
                mask="url(#path-1-inside-1_1571_13912)"
            />
            <path d="M3 19C3 16.7909 4.79086 15 7 15H12.3333V20H3V19Z" fill="#0DBCFF" />
            <path
                d="M5.33333 8.33325L10.3333 9.66659C9.68635 10.3136 8.90932 11.7723 9.29489 12.2888C9.36452 12.3621 9.38177 12.3817 9.33333 12.3333C9.31942 12.3193 9.30661 12.3045 9.29489 12.2888C8.88527 11.8576 6.66287 9.56782 5.33333 8.33325Z"
                fill="#0DBCFF"
            />
        </svg>
    )
}
