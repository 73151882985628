import React from 'react'

export function CreditCardIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
        >
            <path
                d="M21.5 5.25H3.5C3.08579 5.25 2.75 5.58579 2.75 6V18C2.75 18.4142 3.08579 18.75 3.5 18.75H21.5C21.9142 18.75 22.25 18.4142 22.25 18V6C22.25 5.58579 21.9142 5.25 21.5 5.25Z"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.2495 15.75H19.2495"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.7495 15.75H13.2495"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.74951 9.0799H22.2495"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
