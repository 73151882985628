import { Fragment } from 'react'
import { Divider, Stack, Typography, ListItemImage } from '@octup/core/ui-kit'
import { format } from 'date-fns'
import { DropableMenu } from 'shared/ui/DropableMenu/DropableMenu'
import { TableRow } from 'shared/ui/Tables'
import { getFormattedVal } from 'shared/utils/metrics-utils'
import { RowActionsContainer } from './RowActionsContainer'

const getProductsValue = ({ products }) =>
    products?.length ? products.map(({ product_title }) => product_title) : 'All'

const getVariantsValue = ({ variants }) =>
    variants?.length ? variants.map(({ variant_title }) => variant_title) : 'All'

const GET_VALUE_FUNCTION_BY_FIELD = {
    products: getProductsValue,
    variants: getVariantsValue,
}

export const getDataValue = ({ row, field }) => {
    const getValue = GET_VALUE_FUNCTION_BY_FIELD[field]
    return getValue?.(row) || row[field]
}

const TABLE_HEADERS = [
    'Cost Name',
    'Product',
    'Variant',
    'Updated',
    'Original',
    'Last Updated',
    'Source',
    '',
]

const SORT_HEADERS = {
    costname: 'cost_name',
    product: 'products',
    variant: 'variants',
    updated: 'updated_value',
    original: 'original_value',
    lastupdated: 'last_updated',
    source: 'source',
}

const SEARCH_FIELDS = {
    cost_name: 'cost_name',
    product: 'products',
    variant: 'variants',
}

const TABLE_CELLS = [
    {
        id: 'cost_name',
        field: 'cost_name',
        textTransform: 'none',
    },
    {
        id: 'products',
        field: 'products',
        renderCell: (row) => (
            <>
                {row.products?.length > 1 ? (
                    <DropableMenu
                        optionsCustomStyle={{ maxHeight: '30vh' }}
                        droplistPosition={'left'}
                        showChevron={true}
                        header={
                            <Typography variant="body2" fontWeight={600}>
                                {`${row.products.length} Products`}
                            </Typography>
                        }
                        options={row.products.map((product, index) => (
                            <Fragment key={product.product_id}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                    height="5rem"
                                    padding={2}
                                >
                                    <ListItemImage
                                        src={product?.image_url}
                                        alt={product?.product_title}
                                        size="small"
                                    />
                                    <Typography variant="body2">
                                        {product?.product_title || '-'}
                                    </Typography>
                                </Stack>
                                {index < row.products?.length - 1 && <Divider />}
                            </Fragment>
                        ))}
                    />
                ) : (
                    row.products[0]?.product_title || 'All'
                )}
            </>
        ),
    },
    {
        id: 'variant_title',
        field: 'variant_title',
        renderCell: (row) => row.products[0]?.variant_title || 'All',
    },
    {
        id: 'updated_value',
        field: 'updated_value',
        renderCell: (row) =>
            getFormattedVal({
                val: row.updated_value,
                valueType: 1,
                customNumOfDigits: null,
                isSupported: true,
                multiplyBy100: false,
                showSymbol: true,
                isShopCurrency: true,
            }),
    },
    {
        id: 'original_value',
        field: 'original_value',
        renderCell: (row) =>
            getFormattedVal({
                val: row.original_value,
                valueType: 1,
                customNumOfDigits: null,
                isSupported: true,
                multiplyBy100: false,
                showSymbol: true,
                isShopCurrency: true,
            }),
    },
    {
        id: 'last_updated',
        field: 'last_updated',
        renderCell: (row) => format(new Date(row.last_updated), 'MMM d, yyyy'),
    },
    {
        id: 'source',
        field: 'source',
    },
    {
        id: 'actions',
        field: '',
        renderCell: (row) => <RowActionsContainer row={row} />,
    },
]

const renderRows = (row) => <TableRow row={row} tableCells={TABLE_CELLS} />

const getTableData = (data) => ({
    headers: TABLE_HEADERS,
    rows: data?.map((row) => ({
        ...row,
        variants: row.products?.filter(({ variant_title }) => variant_title),
    })),
})

export const getTableConfig = (data) => ({
    renderRows,
    tableData: getTableData(data),
    customRowStyle: { height: '6rem' },
    searchFilterFields: SEARCH_FIELDS,
    sortingHeadersToDataMapping: SORT_HEADERS,
    requestedFilters: [],
})
