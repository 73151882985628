import { IntegrationStatus } from '@/new_components/__common__/Integrations'
import type { IntegrationStatusPropsType } from '@/new_components/__common__/Integrations'
import { useAppSelector } from '@/state/hooks'

const DESCRIPTION = 'Average data collection percentage across all integrations'

export type AverageIntegrationsStatusContainerPropsType = Omit<
    IntegrationStatusPropsType,
    'value' | 'description'
>

export const AverageIntegrationsStatusContainer = (
    props: AverageIntegrationsStatusContainerPropsType
) => {
    const { collectionDataPercentage } = useAppSelector((state) => state.integrations.status)
    const value = collectionDataPercentage?.average

    return <IntegrationStatus value={value} description={DESCRIPTION} {...props} />
}
