import React from 'react'
import { getIconColor } from './utils'

export function LikeSvg({ active, customActiveColor = '#007F82', customColor = '#007F82' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.8rem"
            height="1.8rem"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M3.4663 8.31519H6.26411V14.25H3.4663C3.34263 14.25 3.22402 14.2019 3.13658 14.1163C3.04913 14.0306 3 13.9145 3 13.7934V8.77171C3 8.65063 3.04913 8.53451 3.13658 8.4489C3.22402 8.36328 3.34263 8.31519 3.4663 8.31519Z"
                fill={active ? customActiveColor : '#F8F7FB'}
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.2641 8.31522L8.5956 3.75C8.84055 3.75 9.08309 3.79723 9.30939 3.889C9.53568 3.98077 9.7413 4.11528 9.9145 4.28485C10.0877 4.45442 10.2251 4.65572 10.3188 4.87727C10.4126 5.09882 10.4608 5.33628 10.4608 5.57609V6.94565H14.0674C14.1996 6.94565 14.3303 6.97318 14.4509 7.02642C14.5714 7.07966 14.679 7.15739 14.7665 7.25444C14.8541 7.3515 14.9195 7.46566 14.9585 7.58937C14.9975 7.71307 15.0092 7.84348 14.9928 7.97194L14.2933 13.4502C14.2651 13.671 14.1555 13.8742 13.9851 14.0214C13.8147 14.1687 13.5952 14.25 13.3679 14.25H6.2641"
                fill={active ? customActiveColor : '#F8F7FB'}
            />
            <path
                d="M6.2641 8.31522L8.5956 3.75C8.84055 3.75 9.08309 3.79723 9.30939 3.889C9.53568 3.98077 9.7413 4.11528 9.9145 4.28485C10.0877 4.45442 10.2251 4.65572 10.3188 4.87727C10.4126 5.09882 10.4608 5.33628 10.4608 5.57609V6.94565H14.0674C14.1996 6.94565 14.3303 6.97318 14.4509 7.02642C14.5714 7.07966 14.679 7.15739 14.7665 7.25444C14.8541 7.3515 14.9195 7.46566 14.9585 7.58937C14.9975 7.71307 15.0092 7.84348 14.9928 7.97194L14.2933 13.4502C14.2651 13.671 14.1555 13.8742 13.9851 14.0214C13.8147 14.1687 13.5952 14.25 13.3679 14.25H6.2641"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
