import styled from 'styled-components'
import { OctupIcons } from '../../../../shared/assets/OctupIcons'
import { OctupColors } from '../../../../shared/theme/colors/OctupColors'
import { getFormattedTrend } from '../../../../shared/utils/metrics-utils'
import { NoDataError } from '../../../NoDataError/NoDataError'

const MetricTrend = ({
    trendValue,
    trendUnits,
    isPositiveTrend,
    hasDataset = true,
    fontSize,
    isSupported,
    isLoading,
}) => {
    const { TrendArrowUp, TrendArrowDown } = OctupIcons
    const TrendArrow = trendValue > 0 ? TrendArrowUp : TrendArrowDown
    switch (true) {
        case isLoading:
            return null
        case !isSupported:
            return (
                <ErrorContainer>
                    <NoDataError />
                </ErrorContainer>
            )
        case !trendValue && trendValue !== 0 && isSupported:
            return (
                <MetricTrendStyle isPositiveTrend={false} trendValue={'-'} fontSize={fontSize}>
                    -
                </MetricTrendStyle>
            )
        case typeof trendValue === 'number':
            return (
                <MetricTrendStyle
                    fontSize={fontSize}
                    trendValue={trendValue}
                    trendUnits={trendUnits}
                    isPositiveTrend={isPositiveTrend}
                >
                    {trendValue !== 0 && (
                        <TrendArrow
                            customColor={
                                trendValue
                                    ? isPositiveTrend
                                        ? OctupColors.successLight
                                        : OctupColors.alertLight
                                    : 'gray'
                            }
                        />
                    )}
                    {getFormattedTrend(parseInt(trendValue), true, false)}
                </MetricTrendStyle>
            )
        case hasDataset:
            return null
        default:
            return false
    }
}

const MetricTrendStyle = styled.div`
    display: flex;
    align-items: center;
    height: 2rem;
    line-height: 2rem;
    white-space: nowrap;
    color: ${(props) =>
        props.trendValue
            ? props.isPositiveTrend
                ? OctupColors.successLight
                : OctupColors.alertLight
            : 'gray'};
    font-size: ${(p) => (p.fontSize ? p.fontSize : '1.6rem')};
    font-style: normal;
    font-weight: 400;
`

const ErrorContainer = styled.div`
    position: absolute;
    width: fit-content;
    bottom: 0;
`

export default MetricTrend
