import { getIconColor } from './utils'

export function ConnectionSvg({ active = false, customActiveColor, customColor }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.5rem"
            viewBox="0 0 24 25"
            fill="none"
        >
            <path
                d="M11.1962 19.8966C9.92696 21.1658 7.86918 21.1658 6.59997 19.8966L4.83221 18.1288C3.563 16.8596 3.563 14.8019 4.83221 13.5327L6.63356 11.7313C6.73119 11.6337 6.88949 11.6337 6.98712 11.7313L12.9975 17.7417C13.0952 17.8393 13.0952 17.9976 12.9975 18.0953L11.1962 19.8966Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
            />
            <path
                d="M13.2718 5.09298C14.5411 3.82378 16.5988 3.82378 17.868 5.09298L19.6358 6.86075C20.905 8.12995 20.905 10.1877 19.6358 11.4569L17.8345 13.2583C17.7368 13.3559 17.5785 13.3559 17.4809 13.2583L11.4705 7.24789C11.3729 7.15026 11.3729 6.99196 11.4705 6.89433L13.2718 5.09298Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
            />
            <path
                d="M5.36621 19.3852L4.27525 20.4762"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M19.1245 5.62692L20.2155 4.53596"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M10.1204 11.0956L8.87142 12.3445"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M13.6559 14.6311L12.4069 15.88"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    )
}
