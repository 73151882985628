import { useCallback, useEffect, useMemo, useState, forwardRef } from 'react'
import { TextField, InputAdornment, SvgIcon } from '@octup/core/ui-kit'
import type { TextFieldPropsType } from '@octup/core/ui-kit'
import { useDebounce } from '@/hooks'
import { ClearIcon, SearchIcon } from '@/new_assets/icons'

type SearchTextFieldContainerPropsType = Omit<TextFieldPropsType, 'value' | 'onChange'> & {
    value?: string
    onChange?: (value: string) => void
}

export const SearchTextFieldContainer = forwardRef<
    HTMLDivElement,
    SearchTextFieldContainerPropsType
>(({ value = '', onChange, InputProps, ...props }, ref) => {
    const [searchValue, setSearchValue] = useState(value)
    const debouncedSearchValue = useDebounce(searchValue)

    useEffect(() => {
        onChange?.(debouncedSearchValue)
    }, [debouncedSearchValue, onChange])

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
    }, [])

    const handleClear = useCallback(() => {
        onChange?.('')
        setSearchValue('')
    }, [onChange])

    const searchInputProps = useMemo(
        () => ({
            startAdornment: (
                <InputAdornment position="start">
                    <SvgIcon component={SearchIcon} />
                </InputAdornment>
            ),
            ...(searchValue && {
                endAdornment: (
                    <InputAdornment position="start">
                        <SvgIcon component={ClearIcon} onClick={handleClear} cursor="pointer" />
                    </InputAdornment>
                ),
            }),

            ...InputProps,
        }),
        [InputProps, handleClear, searchValue]
    )

    return (
        <TextField
            ref={ref}
            value={searchValue}
            onChange={handleChange}
            InputProps={searchInputProps}
            size="small"
            {...props}
        />
    )
})
