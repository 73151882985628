import React from 'react'
import styled from 'styled-components'

export const OctupInputFieldLabel = ({
    value,
    centerPlaceholder,
    transformX,
    transformY,
    color,
    type,
    iconLeft,
    iconRight,
    hideLabel,
    disableAnimation = false,
}) => {
    const LeftIcon = iconLeft?.icon
    const RightIcon = iconRight?.icon
    if (disableAnimation && !centerPlaceholder) return null
    switch (type) {
        case 'email':
            return (
                <LabelEmail
                    color={color}
                    centerPlaceholder={centerPlaceholder}
                    transformX={transformX}
                    transformY={transformY}
                >
                    {value}
                </LabelEmail>
            )
        case 'password':
            return (
                <PasswordLabel
                    color={color}
                    centerPlaceholder={centerPlaceholder}
                    transformX={transformX}
                    transformY={transformY}
                >
                    {value}
                </PasswordLabel>
            )
        case 'integrationUrl':
            return (
                <IntegrationUrlLabel
                    color={color}
                    centerPlaceholder={centerPlaceholder}
                    transformX={transformX}
                    transformY={transformY}
                >
                    {value}
                </IntegrationUrlLabel>
            )
        case 'account':
            return (
                <AccountlLabel
                    color={color}
                    centerPlaceholder={centerPlaceholder}
                    transformX={transformX}
                    transformY={transformY}
                >
                    {value}
                </AccountlLabel>
            )
        default:
            return (
                <>
                    <LabelEmail
                        color={color}
                        centerPlaceholder={centerPlaceholder}
                        transformX={transformX}
                        iconLeft={iconLeft}
                        transformY={transformY}
                    >
                        {LeftIcon && centerPlaceholder && (
                            <IconContainer>
                                <LeftIcon
                                    customColor={iconLeft.color || '#ccc'}
                                    scale={centerPlaceholder ? iconLeft.scale : 1}
                                />
                            </IconContainer>
                        )}
                        {value}
                        {RightIcon && centerPlaceholder && (
                            <IconContainer>
                                <RightIcon
                                    customColor={iconRight.color || '#ccc'}
                                    scale={iconRight.scale}
                                />
                            </IconContainer>
                        )}
                    </LabelEmail>
                </>
            )
    }
}

const LabelEmail = styled.label`
    color: ${(p) => p.color};
    z-index: 99;
    pointer-events: none;
    position: absolute;
    display: flex;
    align-items: center;
    left: ${(props) => (props.transformX ? props.transformX : '-2rem')};
    margin-top: ${(props) => (props.transformY ? props.transformY : '0')};
    //following if sets the label animation
    ${(props) =>
        !props.centerPlaceholder
            ? `font-size: 1.5rem;
  
  transform: translate(3rem, 0.5rem) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms`
            : `font-size: 2rem;
    top: 1.5rem;
    opacity: 0.5;

    transform: translate(3rem, 0.5rem) scale(0.9);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;`};
`

const PasswordLabel = styled(LabelEmail)`
    left: ${(props) => (props.transformX ? props.transformX : '-1.2rem')};
    top: ${(props) => (props.transformY ? props.transformY : '0')};
    //following if sets the label animation
    ${(props) =>
        !props.centerPlaceholder
            ? `font-size: 1.5rem;
  
  transform: translate(2.2rem, 0.5rem) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms`
            : `font-size: 2rem;
    top: 1.5rem;
  
  
    transform: translate(2.2rem, 0.5rem) scale(0.9);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;`}
`

const IntegrationUrlLabel = styled.label`
    color: ${(p) => p.color};
    pointer-events: none;
    position: absolute;
    left: ${(props) => (props.transformX ? props.transformX : '0')};
    top: ${(props) => (props.transformY ? props.transformY : '0')};
    //following if sets the label animation
    ${(props) =>
        !props.centerPlaceholder
            ? `font-size: 1.5rem;
  
  transform: translate(2.7rem, 7.5rem) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms`
            : `font-size: 2rem;
    top: 1.5rem;
    opacity: 0.5;

    transform: translate(3rem, 0.5rem) scale(0.9);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;`}
`

const AccountlLabel = styled.label`
    //color: ${(p) => p.color};
    pointer-events: none;
    position: absolute;
    left: 0;
    color: red;
`

const IconContainer = styled.div`
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
`
