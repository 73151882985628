import { List, Stack, Typography } from '@octup/core/ui-kit'
import type { MetricsSectionsCategoryType } from 'models/metrics'
import { CategoriesListItemContainer } from 'new_containers/components/Dashboards/DashboardForm/MetricsSectionsListPanelContainer'
import { listStyle } from './CategoriesList.styles'

type CategoriesListPropsType = {
    options: MetricsSectionsCategoryType[]
}

export const CategoriesList = ({ options }: CategoriesListPropsType) => {
    if (!options.length) {
        return (
            <Stack alignItems="center" pt={4}>
                <Typography color="text.secondary">No options found</Typography>
            </Stack>
        )
    }

    return (
        <List sx={listStyle}>
            {options.map((option) => (
                <CategoriesListItemContainer key={option.category} {...option} />
            ))}
        </List>
    )
}
