import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AttributedCosts } from 'new_components/UnitEconomics'
import { fetchProducts } from 'state/features/unitEconomics'

export const AttributedCostsContainer = () => {
    const dispatch = useDispatch()
    const { query, selectedProduct } = useSelector((state) => state.unitEconomics.products)
    const handleUpdateData = useCallback(() => dispatch(fetchProducts(query)), [dispatch, query])

    return (
        <AttributedCosts
            costs={selectedProduct.costs}
            totalCost={selectedProduct.total_cost}
            onUpdateData={handleUpdateData}
            selectedProduct={selectedProduct}
        />
    )
}
