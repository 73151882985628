import type { Components, ComponentsOverrides, Theme } from '@mui/material'
import type { OverridesStyleRules } from '@mui/material/styles/overrides'

declare module '@mui/material' {
    interface ButtonPropsVariantOverrides {
        secondary: true
    }
}

type MuiButtonOverridesType = Pick<Components<Theme>, 'MuiButton' | 'MuiButtonGroup'>

type OverridesType<T extends keyof MuiButtonOverridesType> = {
    [K in T]: MuiButtonOverridesType[K] & {
        styleOverrides?: Partial<
            ComponentsOverrides<Theme>[K] &
                OverridesStyleRules<'secondary', K, Theme> &
                OverridesStyleRules<'secondarySecondary', K, Theme> &
                OverridesStyleRules<'secondaryPrimary', K, Theme>
        >
    }
}

type ButtonOverridesType = OverridesType<'MuiButton'> & OverridesType<'MuiButtonGroup'>

export const BUTTON_OVERRIDES: ButtonOverridesType = {
    MuiButton: {
        styleOverrides: {
            root: ({ theme, ownerState }) => ({
                borderRadius: theme.spacing(2),
                ...((ownerState.variant === 'secondary' || ownerState.variant === 'outlined') && {
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                }),
            }),
            secondary: ({ theme }) => ({
                boxShadow: theme.shadows[1],
                '&.Mui-disabled': {
                    opacity: '0.5',
                },
            }),
            secondarySecondary: ({ theme }) => ({
                color: theme.palette.text.primary,
            }),
            secondaryPrimary: ({ theme }) => ({
                color: theme.palette.primary.main,
                '&.Mui-disabled': {
                    color: theme.palette.primary.main,
                },
                '& .MuiLoadingButton-loadingIndicator': {
                    color: theme.palette.primary.main,
                },
            }),
            contained: ({ theme }) => ({
                '&:hover': {
                    boxShadow: theme.shadows[3],
                },
                '&.Mui-disabled': {
                    background: theme.palette.action.disabledBackground,
                },
            }),
            containedPrimary: {
                // TODO: move gradients in palette
                background: 'linear-gradient(44.56deg, #007F82 -1.25%, #00A8A8 100.79%)',
                '&:hover': {
                    background: 'linear-gradient(44.56deg, #00A8A8 -1.25%, #00A8A8 100.79%)',
                },
            },
            text: {
                '&.MuiButton-text': {
                    paddingRight: 0,
                    paddingLeft: 0,
                },
                '&:hover': {
                    opacity: '0.7',
                    background: 'none',
                },
                '&:active': {
                    opacity: '1',
                },
                '& .MuiTouchRipple-root': {
                    display: 'none',
                },
            },
            sizeSmall: ({ theme }) => ({
                padding: theme.spacing(1, 4.5),
                '& .MuiButton-startIcon>*:nth-of-type(1)': {
                    fontSize: theme.spacing(4.5),
                },
            }),
            sizeMedium: ({ theme }) => ({
                padding: theme.spacing(1.5, 4),
                '& .MuiButton-startIcon>*:nth-of-type(1)': {
                    fontSize: theme.spacing(5),
                },
            }),
            sizeLarge: ({ theme }) => ({
                padding: theme.spacing(2, 6),
                '& .MuiButton-startIcon>*:nth-of-type(1)': {
                    fontSize: theme.spacing(5.8),
                },
            }),
        },
    },
    MuiButtonGroup: {
        styleOverrides: {
            root: ({ theme }) => ({
                '& .MuiButton-root': {
                    minWidth: 'fit-content',
                },
                '& .MuiButton-root.MuiButton-sizeMedium': {
                    padding: theme.spacing(0.4, 3),
                    '&[type="icon"]': {
                        padding: theme.spacing(0.4, 2),
                    },
                },
            }),
            contained: ({ theme }) => ({
                boxShadow: theme.shadows[0],
            }),
            secondary: ({ theme }) => ({
                boxShadow: theme.shadows[3],
                borderRadius: theme.spacing(2),
                '& .MuiButtonGroup-firstButton, & .MuiButtonGroup-middleButton': {
                    borderRight: `1px solid ${theme.palette.divider}`,
                },
                '& .MuiButtonBase-root.MuiButton-root': {
                    boxShadow: theme.shadows[0],
                },
            }),
        },
    },
}
