import React from 'react'
import { getIconColor } from './utils'

export function TrendArrowUp({ active, customActiveColor, customColor }) {
    return (
        <svg
            width="1.7rem"
            height="1.6rem"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.2 8L8.19995 5L4.19995 8"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.2 12L8.19995 9L4.19995 12"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
