import React from 'react'
import { getIconColor } from './utils'

export function HelpNSupportLogoSvg({ active, customColor, customActiveColor }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.81282 2.81281C6.22993 -0.60427 11.7701 -0.604272 15.1872 2.81281L14.6568 3.34315L15.1872 2.81282C18.6043 6.22993 18.6043 11.7701 15.1872 15.1872C11.7701 18.6043 6.22993 18.6043 2.81282 15.1872L3.34315 14.6568L2.81281 15.1872C-0.604272 11.7701 -0.60427 6.22993 2.81281 2.81282L2.81282 2.81281ZM11.8638 6.13621C10.2822 4.55459 7.71784 4.55459 6.13621 6.13621C4.55458 7.71784 4.55458 10.2822 6.13621 11.8638C7.71784 13.4454 10.2822 13.4454 11.8638 11.8638C13.4454 10.2822 13.4454 7.71784 11.8638 6.13621ZM16.5873 8.35725C16.5994 8.02083 16.5851 7.68583 16.5455 7.3551C16.4066 6.19638 15.9557 5.0901 15.2323 4.15853C14.5089 3.22696 13.5487 2.51616 12.4605 2.09464C12.1499 1.97433 11.8289 1.87758 11.4999 1.80599L10.9355 4.39938C11.8089 4.58946 12.5878 5.08039 13.136 5.78638C13.6843 6.49237 13.967 7.36855 13.9349 8.26184L16.5873 8.35725ZM3.55823 3.93659C3.3074 4.16112 3.07586 4.40364 2.86502 4.6615C2.1263 5.56497 1.64169 6.65689 1.47192 7.82408C1.30216 8.99127 1.45563 10.176 1.90636 11.2525C2.03501 11.5597 2.18787 11.8581 2.36435 12.1448L4.62453 10.7535C4.15595 9.9923 3.96972 9.09065 4.09838 8.2061C4.22704 7.32154 4.66241 6.51031 5.32842 5.91413L3.55823 3.93659ZM6.18152 16.4076C5.87753 16.2738 5.5819 16.118 5.2971 15.9404L5.29508 15.9392L6.84043 13.4619L6.8413 13.4624C7.68205 13.9866 8.68483 14.1869 9.66248 14.026C10.6405 13.8651 11.5264 13.3537 12.1549 12.5873L14.4126 14.4388C14.1993 14.6989 13.9687 14.9419 13.723 15.1665C12.7268 16.0772 11.4825 16.6856 10.1366 16.9071C8.7907 17.1286 7.41704 16.951 6.18152 16.4076Z"
                fill={getIconColor(active, customColor, customActiveColor)}
            />
        </svg>
    )
}
