import { useCallback } from 'react'
import Dropdown from '../../../../../shared/ui/Dropdown/Dropdown'
import { Text, ErrorMessage } from '../../../../../shared/styledComponents'
import { OctupInput } from '../../../../../shared/ui/Inputs/Input/OctupInput'
import { Grid } from '@mui/material'
import { useInput } from '../../../../../hooks/useInput'
import { ErrorContainer } from './DismissPeriod.styles'
import { useState } from 'react'
import { useUpdateEffect } from '../../../../../hooks/useUpdateEffect'
import {
    DISMISS_PERIOD_FIELD_NAME,
    DISMISS_PERIOD,
    DISMISS_PERIOD_DROPDOWN_OPTIONS,
} from './DismissPeriod.utils'

export const DismissPeriod = ({ dismissData, onDismissDataChange, errors, onErrorsChange }) => {
    const [period, setPeriod] = useState('')

    useUpdateEffect(() => {
        if (errors[DISMISS_PERIOD_FIELD_NAME]) {
            onErrorsChange((errors) => {
                delete errors[DISMISS_PERIOD_FIELD_NAME]
                return errors
            })
        }
    }, [dismissData, errors, onErrorsChange])

    const {
        value: customDate,
        isValid: customDateIsValid,
        isChanged: customDateIsChanged,
        errors: customDateErrors,
        showErrors: customDateShowErrors,
        onChangeHandler: onCustomDateChange,
        onBlurHandler: onCustomDateBlur,
        resetToInitialValue: resetCustomDate,
    } = useInput({
        inputType: 'date',
        min: { value: new Date().setDate(new Date().getDate() - 1) },
    })

    const handleSelectDismissPeriod = useCallback(
        (data) => {
            if (data.value) {
                const periodType = data.value.value
                setPeriod(periodType)
                onDismissDataChange({ type: periodType })

                if (customDate) {
                    resetCustomDate()
                }
            }
        },
        [customDate, onDismissDataChange, resetCustomDate]
    )

    const handleCustomDateChange = useCallback(
        (event) => {
            onCustomDateChange(event)
            onDismissDataChange({ type: DISMISS_PERIOD.CUSTOM, value: event.target.value })

            if (period) {
                setPeriod('')
            }
        },
        [onCustomDateChange, onDismissDataChange, period]
    )

    return (
        <>
            <Grid item container flexDirection="row" alignItems="center">
                <Grid item>
                    <Text>Dismiss for a period of</Text>
                </Grid>
                <Grid item>
                    <Dropdown
                        id="dismissPeriod"
                        title="time"
                        showClearFilters={false}
                        isMultiSelection={false}
                        onClickCallback={handleSelectDismissPeriod}
                        options={DISMISS_PERIOD_DROPDOWN_OPTIONS}
                        isFilter={false}
                        droplistPosition="bottom"
                        resetValue={!period}
                    />
                </Grid>
                <Grid item ml={1} mr={1.5}>
                    <Text>or</Text>
                </Grid>
                <Grid item>
                    <Text>until </Text>
                </Grid>
                <Grid item ml={1}>
                    <OctupInput
                        type="date"
                        id="custom_date"
                        name="custom_date"
                        width="100%"
                        errors={customDateErrors}
                        showErrors={
                            customDateShowErrors || (customDateErrors && customDateIsChanged)
                        }
                        value={customDate}
                        isValid={customDateIsValid}
                        onBlur={onCustomDateBlur}
                        onChange={handleCustomDateChange}
                    />
                </Grid>
            </Grid>
            {errors[DISMISS_PERIOD_FIELD_NAME] && (
                <ErrorContainer>
                    <ErrorMessage>{errors[DISMISS_PERIOD_FIELD_NAME]}</ErrorMessage>
                </ErrorContainer>
            )}
        </>
    )
}
