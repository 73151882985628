export const SELECT_ALL = 'SELECT_ALL'

const EXTRACT_FROM_BRACKETS_REGEX = /.*\(([^)]+)\).*/
const EXTRACT_BEFORE_BRACKETS_REGEX = /^(.*?)\s*\(.*/
const EXTRACT_NAME_REGEX_BY_INTEGRATION = {
    googleanalytics: EXTRACT_BEFORE_BRACKETS_REGEX,
    google: EXTRACT_FROM_BRACKETS_REGEX,
    googleads: EXTRACT_FROM_BRACKETS_REGEX,
    facebook: EXTRACT_FROM_BRACKETS_REGEX,
}

export const getAccounts = (data, integration) =>
    data.map((item) => {
        const { desc_name, desc_id } = item
        const extractNameRegex = EXTRACT_NAME_REGEX_BY_INTEGRATION[integration]
        const displayName = (desc_name || desc_id)?.replace(extractNameRegex, '$1')
        return { ...item, displayName }
    })

export const getActionButtons = ({ onCancel }) => [
    {
        title: 'Cancel',
        type: 'button',
        variant: 'secondary',
        disabled: false,
        onClick: onCancel,
    },
    {
        title: 'Approve',
        type: 'submit',
        variant: 'contained',
        disabled: false,
    },
]

export const getDefaultValues = (accounts = []) =>
    accounts?.reduce((acc, item) => ({ ...acc, [item.id]: false }), {
        [SELECT_ALL]: false,
    })

export const convertToApi = (data) =>
    Object.entries(data).reduce(
        (acc, [key, value]) => (key !== SELECT_ALL && value ? [...acc, key] : acc),
        []
    )
