import { getIconColor } from './utils'

export function SearchIcon({ active, customActiveColor, customColor, scale = 1 }) {
    const scaleTmp = `scale(${scale})`
    return (
        <svg
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            transform={scaleTmp}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.8 15.8C13.5615 15.8 15.8 13.5614 15.8 10.8C15.8 8.03858 13.5615 5.8 10.8 5.8C8.03863 5.8 5.80005 8.03858 5.80005 10.8C5.80005 13.5614 8.03863 15.8 10.8 15.8Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.5547 14.5562L18.1997 18.2013"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
