import { getIconColor } from './utils'
export function ActionsBtnShippingIcon({
    active,
    customActiveColor = '#504B5A',
    customColor = '#504B5A',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4457 11.4882C12.7215 11.4911 12.9422 11.717 12.9387 11.9928L12.8369 19.975C12.8334 20.2508 12.6069 20.4721 12.3312 20.4692C12.0554 20.4663 11.8346 20.2404 11.8381 19.9646L11.94 11.9824C11.9435 11.7065 12.1699 11.4853 12.4457 11.4882Z"
                fill={getIconColor(active, customColor, customActiveColor)}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7916 3.64097C11.9675 3.55182 12.1627 3.5064 12.3587 3.50619C12.5546 3.50599 12.7497 3.551 12.9254 3.63977L20.2072 7.31829C20.3878 7.40952 20.5451 7.54568 20.6576 7.71842C20.7704 7.89177 20.8327 8.09401 20.8324 8.30398L20.8231 15.6537C20.8228 15.8637 20.76 16.066 20.6467 16.2396C20.5338 16.4126 20.3762 16.5491 20.1954 16.6407L12.9042 20.3346C12.7282 20.4237 12.5331 20.4692 12.3371 20.4694C12.1412 20.4696 11.9461 20.4246 11.7704 20.3358L4.48856 16.6573C4.30795 16.566 4.15068 16.4299 4.03822 16.2571C3.92535 16.0838 3.86308 15.8816 3.86335 15.6716L3.87268 8.32186C3.87295 8.11189 3.93573 7.90952 4.04903 7.73593C4.16194 7.56296 4.31955 7.42647 4.50039 7.33485L11.7916 3.64097ZM12.3574 4.50502C12.314 4.50506 12.2738 4.51528 12.2413 4.53176L4.95014 8.22564C4.91688 8.24249 4.89628 8.26335 4.88508 8.28051C4.87428 8.29706 4.87152 8.31079 4.87151 8.32081L4.86217 15.6705C4.86216 15.6806 4.86488 15.6943 4.87564 15.7108C4.8868 15.7279 4.90735 15.7488 4.94057 15.7655L12.2224 19.4441C12.2549 19.4605 12.295 19.4706 12.3384 19.4705C12.3818 19.4705 12.4219 19.4603 12.4544 19.4438L19.7456 15.7499C19.7789 15.7331 19.7995 15.7122 19.8107 15.6951C19.8215 15.6785 19.8243 15.6648 19.8243 15.6548L19.8336 8.30503C19.8336 8.29501 19.8309 8.28129 19.8201 8.26476C19.809 8.24762 19.7884 8.22681 19.7552 8.21002L12.4734 4.53151C12.4409 4.51511 12.4008 4.50497 12.3574 4.50502Z"
                fill={getIconColor(active, customColor, customActiveColor)}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.02174 7.78438C4.14543 7.53756 4.44555 7.43728 4.69208 7.56039L12.4381 11.4286L20.0114 7.54719C20.2571 7.42127 20.5579 7.51813 20.6832 7.76352C20.8086 8.00892 20.711 8.30993 20.4653 8.43585L12.6669 12.4327C12.5254 12.5052 12.358 12.5061 12.216 12.4352L4.24417 8.4542C3.99764 8.33108 3.89806 8.03119 4.02174 7.78438Z"
                fill={getIconColor(active, customColor, customActiveColor)}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.12343 5.60957C8.24711 5.36276 8.54723 5.26248 8.79376 5.38559L16.8367 9.4021C17.0057 9.48653 17.1124 9.65933 17.1121 9.84847L17.1074 13.5752C17.1071 13.851 16.8832 14.0748 16.6074 14.0751C16.3315 14.0754 16.1082 13.852 16.1086 13.5762L16.1129 10.1581L8.34586 6.27939C8.09933 6.15628 7.99974 5.85639 8.12343 5.60957Z"
                fill={getIconColor(active, customColor, customActiveColor)}
            />
        </svg>
    )
}
