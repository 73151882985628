import { Fragment } from 'react'
import styled from 'styled-components'
import { INTEGRATION_TITLES_MAP } from '@/constants/integrations'
import { iconsMapping } from '../UnitEconomics/ProductPNLBreakdown/iconsMapping'
import { OctupTooltip } from '../../shared/ui/MUIWrappers/OctupTooltip'

export const MetricSources = ({ sources = [], maxIcons = 3, classes = '' }) => {
    const numOfIcons = sources.length > maxIcons ? maxIcons - 1 : maxIcons

    return (
        <OctupTooltip
            position={'top'}
            title={
                <SourceTooltipInfoContainer>
                    {Array.from(sources)
                        ?.sort()
                        .map((el) => (
                            <Fragment key={el.name || el}>
                                {el.info || (
                                    <SourceTooltipInfo>
                                        <IconContainer>
                                            {iconsMapping[`${(el.name || el).toLowerCase()}`] ||
                                                iconsMapping.default}{' '}
                                        </IconContainer>
                                        <SourceTooltipInfoTitle>
                                            {INTEGRATION_TITLES_MAP[
                                                (el.name || el).toLowerCase()
                                            ] || el}
                                        </SourceTooltipInfoTitle>
                                    </SourceTooltipInfo>
                                )}
                            </Fragment>
                        ))}
                </SourceTooltipInfoContainer>
            }
        >
            <IconContainer className={`${classes}`}>
                {Array.from(sources)
                    ?.sort()
                    .map(
                        (el, index) =>
                            index < numOfIcons && (
                                <Fragment key={el.name || el}>
                                    {iconsMapping[`${(el.name || el).toLowerCase()}`] ||
                                        iconsMapping.default}
                                </Fragment>
                            )
                    )}
                {sources?.length > numOfIcons && (
                    <BadgeContainer>{`+${+sources?.length - numOfIcons}`} </BadgeContainer>
                )}
            </IconContainer>
        </OctupTooltip>
    )
}

const SourceTooltipInfoContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    font-size: inherit;
`

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.2rem;
    width: fit-content;
    height: 100%;
    transform: translateY(-5%);
    max-height: 5rem;
`
const SourceTooltipInfoTitle = styled.div`
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.8px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: capitalize;
`
const BadgeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 2.4rem;
    min-height: 2.4rem;
    line-height: 1.8;
    font-size: 1.2rem;
    font-weight: 400;
    background: linear-gradient(0deg, #f8f7fb, #f8f7fb);
    box-shadow: 2px 2px 4px 0px rgba(80, 75, 90, 0.16);
    border: 0.1rem solid;
    border-image-source: linear-gradient(
        91.95deg,
        rgba(255, 255, 255, 0.4) -4.26%,
        rgba(255, 255, 255, 0) 107.52%
    );
    color: rgba(80, 75, 90, 1);
    letter-spacing: 0.2rem;
`
const SourceTooltipInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    height: 100%;
    width: 100%;
`
