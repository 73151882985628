import { useContext } from 'react'
import { Box, LinearProgress, Stack } from '@octup/core/ui-kit'
import { ErrorMessageFallback } from '@/new_components/__common__'
import { ModalContainer } from '@/new_containers/components/Metrics'
import PageTitle from './PageTitle'
import { ModalsContext } from '../../contexts/ModalsContext'

const PageLayout = ({
    children,
    customStyle,
    error,
    isLoading,
    hasFullScreen = true,
    hasFixedHeight = false,
    ...props
}) => {
    const { deepdiveModal } = useContext(ModalsContext)

    if (error) return <ErrorMessageFallback />

    return (
        <Stack sx={containerStyle} style={{ ...customStyle }}>
            {isLoading && (
                <Box data-testid="page-loader" sx={loadingStyle}>
                    <LinearProgress />
                </Box>
            )}
            <PageTitle {...props} />
            <Box sx={contentStyle(hasFullScreen, hasFixedHeight)}>{children}</Box>
            {deepdiveModal?.show && <ModalContainer />}
        </Stack>
    )
}

const containerStyle = (theme) => ({
    width: '100%',
    height: '100%',
    overflow: 'auto',
    position: 'relative',
    padding: theme.spacing(4.5, 5.5),
})

const contentStyle = (hasFullScreen, hasFixedHeight) => (theme) => {
    const fullScreenHeight = `calc(100% - ${theme.spacing(22)})`

    return {
        width: '100%',
        height: '100%',
        ...(hasFullScreen && {
            height: {
                xs: '100%',
                lg: fullScreenHeight,
            },
        }),
        ...(hasFixedHeight && {
            height: fullScreenHeight,
        }),
    }
}

const loadingStyle = (theme) => ({
    width: `calc(100% - ${theme.spacing(10.8)})`,
    position: 'absolute',
    top: theme.spacing(13),
})

export default PageLayout
