export function ShipNetworkIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <circle cx="12" cy="12" r="10" fill="#008BFA" />
            <path
                d="M8.55929 16C8.07839 16 7.64361 15.9184 7.25494 15.7552C6.87286 15.5921 6.56983 15.3571 6.34585 15.0503C6.12187 14.7436 6.00659 14.3814 6 13.9636H7.48221C7.50198 14.2443 7.60079 14.4662 7.77866 14.6294C7.96311 14.7925 8.21344 14.8741 8.52964 14.8741C8.85244 14.8741 9.10606 14.7991 9.29051 14.649C9.47497 14.4923 9.56719 14.29 9.56719 14.042C9.56719 13.8396 9.50461 13.6732 9.37945 13.5427C9.25428 13.4121 9.09618 13.311 8.90514 13.2392C8.72069 13.1608 8.46377 13.076 8.13439 12.9846C7.68643 12.8541 7.32082 12.7268 7.03755 12.6028C6.76087 12.4723 6.52042 12.2797 6.31621 12.0252C6.11858 11.7641 6.01976 11.4182 6.01976 10.9874C6.01976 10.5828 6.12187 10.2303 6.32609 9.93007C6.5303 9.62984 6.81686 9.4014 7.18577 9.24476C7.55468 9.08159 7.97628 9 8.45059 9C9.16206 9 9.73847 9.17296 10.1798 9.51888C10.6278 9.85827 10.8748 10.3347 10.9209 10.9483H9.39921C9.38603 10.7133 9.28393 10.5207 9.09289 10.3706C8.90843 10.214 8.6614 10.1357 8.35178 10.1357C8.08169 10.1357 7.8643 10.2042 7.6996 10.3413C7.5415 10.4783 7.46245 10.6774 7.46245 10.9385C7.46245 11.1212 7.52174 11.2746 7.64032 11.3986C7.76548 11.5161 7.917 11.614 8.09486 11.6923C8.27931 11.7641 8.53623 11.849 8.86561 11.9469C9.31357 12.0774 9.67918 12.2079 9.96245 12.3385C10.2457 12.469 10.4895 12.6648 10.6937 12.9259C10.8979 13.1869 11 13.5296 11 13.9538C11 14.3193 10.9045 14.6587 10.7134 14.972C10.5224 15.2853 10.2424 15.5366 9.87352 15.7259C9.50461 15.9086 9.06653 16 8.55929 16Z"
                fill="white"
            />
            <path
                d="M18 16H17.203L12.797 10.1116V16H12V9H12.797L17.203 14.8784V9H18V16Z"
                fill="white"
            />
        </svg>
    )
}
