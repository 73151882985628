export function AftershipIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2293 19.4664C18.003 18.4392 20.7778 14.9881 20.7778 10.8889C20.7778 5.97969 16.7981 2 11.8889 2C6.97969 2 3 5.97969 3 10.8889C3 14.9881 5.77476 18.4392 9.5485 19.4664L11.8889 22L14.2293 19.4664Z"
                fill="#F9A31A"
            />
            <path
                d="M12.5 11.3249C12.5 11.1273 12.6164 10.9482 12.7969 10.868L16.2969 9.31247C16.6276 9.16552 17 9.40755 17 9.76937V13.3417C17 13.5393 16.8836 13.7184 16.7031 13.7986L13.2031 15.3542C12.8724 15.5011 12.5 15.2591 12.5 14.8973V11.3249Z"
                fill="white"
            />
            <path
                d="M7 9.62902C7 9.26058 7.38495 9.01868 7.71691 9.17852L11.2169 10.8637C11.39 10.9471 11.5 11.1222 11.5 11.3142V14.871C11.5 15.2395 11.1151 15.4814 10.7831 15.3215L7.28309 13.6363C7.11003 13.553 7 13.3779 7 13.1858V9.62902Z"
                fill="white"
            />
            <path
                d="M12 10.25C11.5 10.25 7.5 8.5 7.5 8C7.5 7.5 11.1562 6.5 12 6.5C12.8438 6.5 16.5 7.75 16.5 8.25C16.5 8.75 12.5 10.25 12 10.25Z"
                fill="white"
            />
        </svg>
    )
}
