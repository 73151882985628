import { OctupBaseModal } from '../../../shared/ui/Modal/OctupBaseModal'
import styled from 'styled-components'
import {
    ACTIONABLE_ITEM_STATUS,
    compareStrings,
    HOME_TABLES_TYPES,
} from '../../../shared/utils/strings-util'
import { getInputDate, getShortTableDate } from '../../../shared/utils/dates-util'
import { OctupAvatar } from '../../../shared/ui/Avatar/OctupAvatar'
import { useEffect, useState } from 'react'
import { EditObjectiveForm } from './EditObjectiveForm'
import { useSelector } from 'react-redux'
import {
    OBJECTIVE_STATUS_KTV_STR,
    VTK_SEGMENAT,
    VTK_TYPE,
} from '../../../features/objectives/objectiveSlice'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'

export function DismissObjectiveModal({ dismissModalhandler }) {
    const { objective } = useSelector((state) => state.objectives)
    const octupDispatch = useOctupDispatch()

    const buttons = [
        {
            title: 'cancel',
            type: 'button',
            variant: 'secondary',
            onClick: () => {
                dismissModalhandler()
            },
        },
        {
            title: 'Delete',
            type: 'button',
            variant: 'contained',
            onClick: () => {
                dismissModalhandler()
                let apiData = {
                    id: objective.id,
                    insight: objective.insight.id || -1,
                    owner_user_id: objective.owner_user_id,
                    createdAt: objective.createdAt || null,
                    start_date: getInputDate(objective.startDate, 'objective'),
                    due_date: getInputDate(objective.dueDate, 'objective'),
                    title: objective.title,
                    segment: VTK_SEGMENAT[objective.data?.segment],
                    description: objective.description,
                    actions: objective.actions,
                    status: OBJECTIVE_STATUS_KTV_STR.dismissed,
                    type: VTK_TYPE[objective.type.toLowerCase()],
                    client_id: objective.client_id,
                }
                octupDispatch.objectives.deleteObjective(apiData)
            },
        },
    ]
    return (
        <OctupBaseModal
            onDismissModal={dismissModalhandler}
            buttons={buttons}
            title={'Delete objective'}
        >
            <DismissObjectiveModalContainerStyled>
                <ModalBodyContainer>
                    <p>Are you sure you wish to delete this objective?</p>
                    <p>At the moment, dismissing it cannot be undone.</p>
                </ModalBodyContainer>
            </DismissObjectiveModalContainerStyled>
        </OctupBaseModal>
    )
}

const ModalBodyContainer = styled.div`
    padding: 3rem 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    height: fit-content;
    width: fit-content;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.4rem;
    overflow: hidden;

    p {
        font-size: inherit;
        line-height: inherit;
    }
`

const DismissObjectiveModalContainerStyled = styled.div`
    width: 64rem;
    max-width: 64rem;
`

export function EditObjectiveModal({
    isEditState = false,
    data,
    dismissModalhandler,
    activeTable,
}) {
    const startDate = data && data.start_date && getShortTableDate(data.start_date)
    const dueDate = data && data.due_date && getShortTableDate(data.due_date)
    const isInsights = compareStrings(activeTable, HOME_TABLES_TYPES.INSIGHTS)
    const isObjective = compareStrings(activeTable, HOME_TABLES_TYPES.OBJECTIVES)
    const SelectedItemData = data && (data[activeTable] ? data[activeTable] : data)
    const [isEditMode, setIsEditMode] = useState(isEditState)

    const [objectiveStatus] = useState(isObjective ? SelectedItemData?.status : '')
    const [objectiveForm, setObjectiveForm] = useState({
        data: isObjective ? { ...SelectedItemData } : { id: null, ...SelectedItemData },
        isFormValid: false,
        isFormSubmitted: false,
    })
    const octupDispatch = useOctupDispatch()

    useEffect(() => {
        setIsEditMode(isEditState || isInsights)
    }, [isEditState, isInsights])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!objectiveForm.isFormValid) {
            setObjectiveForm((prevState) => {
                return { ...prevState, isFormSubmitted: true }
            })
            return
        }

        setObjectiveForm((prevState) => {
            return { ...prevState, isFormSubmitted: true }
        })

        if (objectiveForm.data.id) octupDispatch.objectives.updateObjective(objectiveForm.apiData)
        else octupDispatch.objectives.createObjective(objectiveForm.apiData)
        dismissModalhandler()
    }

    let modalsButtons = isEditMode
        ? [
              {
                  title: 'Discard',
                  type: 'button',
                  variant: 'secondary',
                  disabled: false,
                  onClick: () => {
                      setIsEditMode(false)
                      !objectiveForm.id && dismissModalhandler()
                  },
              },
              {
                  title: 'Save and Close',
                  type: 'submit',
                  variant: 'contained',
                  disabled: false,
                  onClick: () => {
                      if (objectiveForm.apiData.id)
                          octupDispatch.objectives.updateObjective(objectiveForm.apiData)
                      else octupDispatch.objectives.createObjective(objectiveForm.apiData)
                      dismissModalhandler()
                  },
              },
          ]
        : [
              (isObjective || !isInsights) &&
                  !isEditMode && {
                      title: 'Close',
                      type: 'button',
                      variant: 'secondary',
                      disabled: false,
                      onClick: () => {
                          setIsEditMode(false)
                          dismissModalhandler()
                      },
                  },
              // {
              //   title: "Edit",
              //   type: "button",
              //   colorScheme: "primary",
              //   disabled: true,
              //   onClick: () => {
              //     setIsEditMode(true);
              //   },
              // },
          ]

    return (
        <OctupBaseModal
            onDismissModal={dismissModalhandler}
            buttons={modalsButtons}
            title={isObjective ? 'Objective Details' : 'New Objective'}
            isForm={true}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            handleSubmit={handleSubmit}
        >
            {isEditMode && (
                <EditObjectiveForm
                    data={data}
                    activeTable={activeTable}
                    isObjective={isObjective}
                    setObjectiveForm={setObjectiveForm}
                    objectiveForm={objectiveForm}
                />
            )}
            {!isEditMode && isObjective && (
                <ObjectiveDataContainer>
                    <ObjectiveInfoTitle>Origin</ObjectiveInfoTitle>
                    <ObjectiveInfoDetails>
                        {SelectedItemData?.origin ? SelectedItemData?.origin : 'Manual'}
                    </ObjectiveInfoDetails>
                    <ObjectiveInfoTitle>Status</ObjectiveInfoTitle>
                    <ObjectiveInfoDetails
                        style={{
                            color: compareStrings(
                                SelectedItemData?.status,
                                ACTIONABLE_ITEM_STATUS.OVERDUE
                            )
                                ? '#DB2059'
                                : compareStrings(
                                        SelectedItemData?.status,
                                        ACTIONABLE_ITEM_STATUS.COMPLETED
                                    )
                                  ? '#078344'
                                  : 'inherit',
                        }}
                    >
                        {objectiveStatus}
                    </ObjectiveInfoDetails>

                    <ObjectiveInfoTitle>creator</ObjectiveInfoTitle>

                    <ObjectiveInfoDetails>
                        {SelectedItemData?.creator && SelectedItemData?.creator.username}
                    </ObjectiveInfoDetails>

                    <ObjectiveInfoTitle>title</ObjectiveInfoTitle>
                    <ObjectiveInfoDetails>{SelectedItemData?.title}</ObjectiveInfoDetails>

                    <ObjectiveInfoTitle>actions</ObjectiveInfoTitle>
                    <ObjectiveInfoDetails>
                        {SelectedItemData?.actions && SelectedItemData?.actions}
                    </ObjectiveInfoDetails>

                    <ObjectiveInfoTitle>Description</ObjectiveInfoTitle>
                    <ObjectiveInfoDetails>
                        {SelectedItemData && SelectedItemData?.description}
                    </ObjectiveInfoDetails>

                    <DatesContainer>
                        <ObjectiveInfoTitle>Start Date</ObjectiveInfoTitle>
                        <ObjectiveInfoDetails>
                            {startDate ? `${startDate.month_and_day}, ${startDate.year}` : 'none'}
                        </ObjectiveInfoDetails>

                        <ObjectiveInfoTitle>Due Date</ObjectiveInfoTitle>
                        <ObjectiveInfoDetails>
                            {!dueDate && 'None'}
                            {dueDate && `${dueDate.month_and_day}, ${dueDate.year}`}
                            {dueDate &&
                                compareStrings(data.status, ACTIONABLE_ITEM_STATUS.OVERDUE) && (
                                    <OverdueIndicator> {'(Overdue)'}</OverdueIndicator>
                                )}
                        </ObjectiveInfoDetails>
                    </DatesContainer>
                    <ObjectiveInfoTitle>Owner</ObjectiveInfoTitle>
                    <ObjectiveInfoDetails style={{ transform: 'translateY(-50%) !important' }}>
                        <UserAvatar>
                            <OctupAvatar
                                title={SelectedItemData?.owner.username}
                                imageSrc={SelectedItemData?.owner.imageSrc}
                                userInitials={SelectedItemData?.owner.initials}
                                size={'large'}
                            />
                            {SelectedItemData?.owner.username}
                        </UserAvatar>
                    </ObjectiveInfoDetails>
                </ObjectiveDataContainer>
            )}
        </OctupBaseModal>
    )
}

const ObjectiveDataContainer = styled.div`
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: 12rem 1fr;
    grid-row-gap: 5rem;
    height: 100%;
    width: 100rem;
    min-width: 100rem;
    padding: 1rem;
    max-height: 90vh;
    max-width: 90vw;
    :last-child {
        padding-bottom: 2rem;
    }
`

const DatesContainer = styled.div`
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 12rem 1fr 12rem 1fr;
    justify-content: space-between;
`

const ObjectiveInfoTitle = styled.div`
    color: #a4a0aa;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: left;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-transform: capitalize;
    /* border: 1px solid red; */
`

const ObjectiveInfoDetails = styled.div`
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-size: 1.5rem;
    line-height: 3rem;
    text-align: left;
    position: relative;
    padding-left: 0.5rem;
    /* border: 1px solid blue; */
`

const OverdueIndicator = styled.span`
    font-size: 1.5rem;
    margin-left: 0.5rem;
    line-height: 2rem;
    color: #db2059;
    font-weight: 500;
`

const UserAvatar = styled.span`
    display: flex;
    align-items: center;
    font-size: inherit;
    position: absolute;
    top: -1.5rem;
    gap: 1rem;
`
