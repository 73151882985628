import styled from 'styled-components'

export const DateStyled = styled.div`
    font-weight: 600;
    font-size: 13px;
    color: rgba(164, 160, 170, 1);
    padding: 0 2rem;
`

export const NavItems = styled.ul`
    display: flex;
    flex-direction: column;
`
