export function SignupSupercharged({
    active,
    customActiveColor = '#DB2059',
    customColor = '#DB2059',
}) {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.7532 28.4666C11.9525 29.2202 10.6991 28.3177 11.1598 27.3194L14.4688 20.15C14.7397 19.5631 14.3908 18.8772 13.7569 18.7504L6.96917 17.3929C6.15966 17.231 5.8839 16.2133 6.50093 15.6649L19.119 4.44879C19.942 3.71729 21.1702 4.6586 20.6778 5.64341L17.5156 11.9677C17.2435 12.512 17.5165 13.1712 18.0938 13.3636L24.4117 15.4696C25.1295 15.7089 25.3318 16.6279 24.7808 17.1465L12.7532 28.4666Z"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                opacity="0.3"
                d="M10.7918 24.4192C9.99639 25.1799 8.735 24.285 9.19015 23.283L11.0997 19.0789C11.3655 18.4937 11.0182 17.8134 10.3884 17.6854L5.94099 16.7816C5.13696 16.6182 4.86099 15.6094 5.46986 15.0595L13.8444 7.49541C14.6621 6.75679 15.8984 7.69029 15.4119 8.67902L13.6462 12.2674C13.3795 12.8094 13.6505 13.4623 14.2226 13.6561L18.4371 15.0835C19.1485 15.3245 19.3502 16.2343 18.8075 16.7534L10.7918 24.4192Z"
                fill="#FFCF72"
            />
        </svg>
    )
}
