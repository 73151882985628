import { useState, useEffect, useCallback } from 'react'
import { format, addDays } from 'date-fns'
import { useSelector } from 'react-redux'
import { NoDataError } from '../NoDataError/NoDataError'
import { GMBTable } from './components/GMBTable'
import {
    GMBTableContainer,
    GMBMetricItemAdditionalData,
    GMBMetricItemValue,
    GMBMetricItemTitle,
    GMBMetricItemContainer,
    GMBMetricItem,
    GMBMetrics,
    GMBTitle,
    GMBHeader,
    GMBBody,
    CloseGMBButtonContainer,
    GMBInnerContainer,
    GMBOutterContainer,
    GMBTitleContainer,
    GMBTitleCloseBtn,
} from './GoodMorningBoard.styles'
import { OpenBoardButton } from './OpenBoardButton'
import { VALUE_TYPE } from '../../features/metrics'
import { SEGMENTS } from '../../features/metrics/metricsSlice'
import { useOctupDispatch } from '../../hooks/useOctupDispatch'
import { ChevronUpSvg, CloseIconSvg, GoodMorningBoardCoffeIconSvg } from '../../shared/assets/icons'
import GMBTopBg from '../../shared/assets/images/GMBTopBG.svg'
import AutumnOcty from '../../shared/assets/images/Octy_Weather_Autumn.svg'
import SpringOcty from '../../shared/assets/images/Octy_Weather_Spring.svg'
import SummerOcty from '../../shared/assets/images/Octy_Weather_Summer.svg'
import WinterOcty from '../../shared/assets/images/Octy_Weather_Winter.svg'
import {
    octupDictionary,
    getDictionaryValue,
    DICTIONARY_SECTIONS,
} from '../../shared/dictionary/dictionary'
import { OctupBackdrop } from '../../shared/ui/Backdrop/OctupBackdrop'
import { ButtonIcon } from '../../shared/ui/Buttons/ButtonIcon'
import { getShortTableDate } from '../../shared/utils/dates-util'
import { getFormattedVal } from '../../shared/utils/metrics-utils'
import { add3Dots } from '../../shared/utils/strings-util'

const today = getShortTableDate(new Date().toISOString(), {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
})

const YESTERDAY_DATE = format(addDays(new Date(), -1), 'yyyy-MM-dd')
const TODAY_DATE = format(new Date(), 'yyyy-MM-dd')
const WEEK_BEFORE = format(addDays(new Date(), -7), 'yyyy-MM-dd')

const TODAY_DATES = {
    startDate: TODAY_DATE,
    endDate: TODAY_DATE,
}

const WEEK_DATES = {
    startDate: WEEK_BEFORE,
    endDate: YESTERDAY_DATE,
}

export const GoodMorningBoard = ({ showGMBoard, setShowGMBoard }) => {
    const octupDispatch = useOctupDispatch()
    const { metrics } = useSelector((state) => state.metrics)
    const [showGMB, setShowGMB] = useState(false)
    const [weatherMetric, setWeatherMetric] = useState(null)
    const toggleOpen = useCallback(() => setShowGMB((show) => !show), [])

    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            setShowGMB(false)
        }
        event.stopPropagation()
    }, [])

    useEffect(() => {
        if (!showGMB) return
        document.addEventListener('keydown', escFunction, true)
        return () => {
            document.removeEventListener('keydown', escFunction, true)
        }
    }, [escFunction, showGMB])

    useEffect(() => {
        octupDispatch.metrics.getMetricsBySegment([SEGMENTS.GMB_TOP], TODAY_DATES, 1)
        octupDispatch.metrics.getMetricsBySegment(
            [
                SEGMENTS.GMB_TABLE,
                SEGMENTS.GMB_AD_SPEND_SUBSECTIONS,
                SEGMENTS.GMB_INCOME_PPC_SUBSECTIONS,
            ],
            WEEK_DATES,
            1
        )

        const showWeather = async (position) => {
            const res = await fetch(
                // TO DO : Move api id to env!
                `https://api.openweathermap.org/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&units=metric&appid=3316be2d83e7084d656da1dae2075459`
            )
            if (!res.ok) {
                throw new Error('Unable to retrive weather')
            }
            const weather = await res.json()
            setWeatherMetric({
                temp: weather.main.temp,
                weather: weather.weather[0].description,
            })
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showWeather)
        } else {
            alert('Geolocation is not supported by this browser.')
        }
    }, [])

    useEffect(() => {
        if (showGMBoard) setShowGMB(showGMBoard)
    }, [showGMBoard])

    useEffect(() => {
        setShowGMBoard(showGMB)
    }, [showGMB])

    return (
        <>
            {showGMB && <OctupBackdrop isCloseable={true} onDismissModal={toggleOpen} />}
            <GMBOutterContainer showGMB={showGMB}>
                <GMBInnerContainer>
                    <GMBHeader
                        style={{
                            backgroundImage: `url(${GMBTopBg})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'right',
                        }}
                    >
                        <GMBTitleContainer>
                            <GMBTitle>
                                <GoodMorningBoardCoffeIconSvg />
                                {getDictionaryValue('gmb', DICTIONARY_SECTIONS.TITLES)}
                            </GMBTitle>
                            <GMBTitleCloseBtn>
                                <ButtonIcon mainIcon={CloseIconSvg} onClick={toggleOpen} />
                            </GMBTitleCloseBtn>
                        </GMBTitleContainer>
                        <GMBMetrics>
                            {weatherMetric && weatherMetric.temp && (
                                <GMBMetricItem>
                                    <img
                                        src={
                                            weatherMetric.temp < 10
                                                ? WinterOcty
                                                : weatherMetric.temp > 10 && weatherMetric.temp < 18
                                                  ? AutumnOcty
                                                  : weatherMetric.temp > 17 &&
                                                      weatherMetric.temp < 25
                                                    ? SpringOcty
                                                    : SummerOcty
                                        }
                                        height={'80%'}
                                        alt={'octy'}
                                    />
                                    <GMBMetricItemContainer showSeperator={true}>
                                        <GMBMetricItemTitle>
                                            {`${today.weekday},${today.month_and_day},${today.year}`}
                                        </GMBMetricItemTitle>
                                        <GMBMetricItemValue>
                                            {weatherMetric && `${Math.round(weatherMetric.temp)}`}
                                            {weatherMetric && <>&deg;C</>}
                                        </GMBMetricItemValue>
                                        <GMBMetricItemAdditionalData>
                                            {weatherMetric && weatherMetric.weather}
                                        </GMBMetricItemAdditionalData>
                                    </GMBMetricItemContainer>
                                </GMBMetricItem>
                            )}

                            {metrics[SEGMENTS.GMB_TOP] &&
                                metrics[SEGMENTS.GMB_TOP].map((el, index) => (
                                    <GMBMetricItem key={el?.metric?.metricTitle}>
                                        <GMBMetricItemContainer
                                            showSeperator={
                                                index - 1 < metrics[SEGMENTS.GMB_TOP].length
                                            }
                                        >
                                            <GMBMetricItemTitle>
                                                {add3Dots(
                                                    octupDictionary.titles.metricsTitles[
                                                        `${el?.metric?.metricTitle}_GMB`
                                                    ] || el?.metric?.metricTitle
                                                )}
                                            </GMBMetricItemTitle>
                                            <GMBMetricItemValue>
                                                {getFormattedVal({
                                                    val: el?.metric?.value || 0,
                                                    valueType: +el?.metric?.valueUnits,
                                                    customNumOfDigits: null,
                                                    currency: el?.metric?.currency,
                                                    isSupported: el?.metric?.supported,
                                                    multiplyBy100:
                                                        +el.metric?.valueUnits ===
                                                        VALUE_TYPE.PERCENTAGE,
                                                    showSymbol: true,
                                                })}
                                            </GMBMetricItemValue>
                                            <GMBMetricItemAdditionalData>
                                                {/* 1 Order returned today */}
                                                {!el?.metric?.supported && <NoDataError />}
                                            </GMBMetricItemAdditionalData>
                                        </GMBMetricItemContainer>
                                    </GMBMetricItem>
                                ))}
                        </GMBMetrics>
                    </GMBHeader>
                    <GMBBody>
                        {/* <ButtonIcon mainIcon={ShareSvg} /> */}
                        <GMBTableContainer id={'GMBody'}>
                            <GMBTable
                                customContainerId={'GMBody'}
                                tableRawData={metrics[SEGMENTS.GMB_TABLE]}
                            />
                        </GMBTableContainer>
                    </GMBBody>
                    <CloseGMBButtonContainer>
                        <ButtonIcon
                            onClick={toggleOpen}
                            mainIcon={ChevronUpSvg}
                            customIconColor={'#504B5A'}
                            customStyle={{
                                borderRadius: '50% !important',
                                padding: '1rem',
                                height: '3rem',
                                width: '3rem',
                                minHeight: '3rem',
                                minWidth: '3rem',
                            }}
                        />
                    </CloseGMBButtonContainer>
                </GMBInnerContainer>
            </GMBOutterContainer>
            <OpenBoardButton onClick={toggleOpen} />
        </>
    )
}
