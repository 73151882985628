import { getIconColor } from './utils'

export function ActionsBtnNoIconIcon({
    active,
    customActiveColor = '#504B5A',
    customColor = '#504B5A',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.8rem"
            height="1.8rem"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M9 16.75C13.2802 16.75 16.75 13.2802 16.75 9C16.75 4.71979 13.2802 1.25 9 1.25C4.71979 1.25 1.25 4.71979 1.25 9C1.25 13.2802 4.71979 16.75 9 16.75Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 11.2308V9.92308C9.59334 9.92308 10.1734 9.77872 10.6667 9.50824C11.1601 9.23776 11.5446 8.85332 11.7716 8.40353C11.9987 7.95375 12.0581 7.45881 11.9424 6.98132C11.8266 6.50383 11.5409 6.06522 11.1213 5.72097C10.7018 5.37672 10.1672 5.14228 9.58527 5.0473C9.00333 4.95232 8.40013 5.00107 7.85195 5.18737C7.30377 5.37368 6.83524 5.68919 6.50559 6.09398C6.17595 6.49878 6 6.9747 6 7.46154"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 14C9.41421 14 9.75 13.69 9.75 13.3077C9.75 12.9253 9.41421 12.6154 9 12.6154C8.58579 12.6154 8.25 12.9253 8.25 13.3077C8.25 13.69 8.58579 14 9 14Z"
                fill={getIconColor(active, customColor, customActiveColor)}
            />
        </svg>
    )
}
