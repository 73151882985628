import React from 'react'
import { getIconColor } from './utils'

export function OcTrackLogoSvg({ active, customActiveColor, customColor, disabled }) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.96325 15.1477C10.7179 15.4172 12.6477 15.7946 14.3295 15.1477"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M15.9467 8.03224C14.7269 8.16776 13.1438 8.51739 12.0657 8.51739C10.9876 8.51739 8.66948 8.19396 7.37582 8.03224C5.70051 7.8228 2.81308 8.96893 3.00953 11.9134C3.17137 14.3391 3.97988 16.6031 5.92039 16.6031C7.69931 16.6031 9.3703 15.525 9.96325 14.8243C10.0711 14.7164 10.4484 14.2097 11.0953 13.0454C11.7421 11.8811 12.5508 12.0751 13.036 12.8837C13.1977 13.0454 13.3593 13.6923 14.3295 14.8243C15.6482 16.3628 16.7553 16.7648 18.5341 16.6031C19.8326 16.4851 20.4747 15.6328 20.9598 11.9134C21.445 8.19395 17.4021 7.87054 15.9467 8.03224Z"
                fill={getIconColor(active, customColor, customActiveColor, disabled)}
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <ellipse cx="7" cy="12.5" rx="2" ry="2.5" fill="#282831" />
            <ellipse cx="18" cy="12.5" rx="2" ry="2.5" fill="#282831" />
            <ellipse
                cx="7.75"
                cy="12"
                rx="0.75"
                ry="1"
                fill={getIconColor(active, customColor, customActiveColor, disabled)}
            />
            <ellipse
                cx="18.75"
                cy="12"
                rx="0.75"
                ry="1"
                fill={getIconColor(active, customColor, customActiveColor, disabled)}
            />
        </svg>
    )
}
