import { useCallback, useEffect, useState } from 'react'
import { useQueryString } from 'hooks'
import { useSelector, useDispatch } from 'react-redux'
import Dropdown from 'shared/ui/Dropdown/Dropdown'
import { fetchWarehouseCountries } from 'state/features/logistics'
import { getInitialData, getNewContinents, getNewCountries } from './CountryFilterContainer.utils'

export const CountryFilterContainer = () => {
    const dispatch = useDispatch()
    const [countries, setCountries] = useState([])
    const [continents, setContinents] = useState([])
    const { queryParams, setQueryParams } = useQueryString()
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const { data } = useSelector((state) => state.warehouseCountries)

    useEffect(() => {
        dispatch(
            fetchWarehouseCountries({
                startDate: datePickerValues.startDate,
                endDate: datePickerValues.endDate,
                warehouses_names: queryParams?.warehouses_names,
            })
        )
    }, [
        dispatch,
        datePickerValues.startDate,
        datePickerValues.endDate,
        queryParams?.warehouses_names,
    ])

    useEffect(() => {
        if (data) {
            const { countries, continents } = getInitialData({ data, queryParams })
            setCountries(countries)
            setContinents(continents)
        }
    }, [data, queryParams])

    const handleCountryClose = useCallback(
        ({ value }) => {
            const selected = value.map(({ country_code }) => country_code)
            setQueryParams({ countries: selected })
            setContinents((prevItems) => getNewContinents(prevItems, selected))
        },
        [setQueryParams]
    )

    const handleContinentClose = useCallback(
        ({ value }) => {
            const selected = value.reduce((acc, item) => [...acc, ...item.countryInitials], [])
            setQueryParams({ countries: selected })
            setCountries((prevItems) => getNewCountries(prevItems, selected))
        },
        [setQueryParams]
    )

    return (
        <>
            <Dropdown
                title="Continent"
                options={continents}
                isFilter
                trimSpace
                showClearFilters={false}
                onClose={handleContinentClose}
                updateSelectedOptions
            />
            <Dropdown
                title="Country"
                options={countries}
                isFilter
                trimSpace
                showClearFilters={false}
                onClose={handleCountryClose}
                updateSelectedOptions
            />
        </>
    )
}
