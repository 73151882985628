// TODO : Extend reusablility

import { Typography } from '@mui/material'
import { OctupBaseModal } from '../../../shared/ui/Modal/OctupBaseModal'
import { ModalMessageContainer } from './DiscardChagnesModal.styles'

const customStyle = { zIndex: 1600 }

export const DiscardChangesModal = ({ dismissModalHandler, buttons, title, content }) => {
    return (
        <OctupBaseModal
            customStyle={customStyle}
            onDismissModal={dismissModalHandler}
            title={title}
            buttons={buttons}
        >
            <ModalMessageContainer>
                <Typography fontWeight={600}>{content.header}</Typography>
                <Typography>{content.paragarph}</Typography>
            </ModalMessageContainer>
        </OctupBaseModal>
    )
}
