import { AlternativeHomeCardItem } from './base-components/AlternativeHomeCardItem'
import { MarketingLogoSvg } from '../../../shared/assets/icons'
import { SEGMENTS } from '../../../features/metrics/metricsSlice'
import { DICTIONARY_SECTIONS, getDictionaryValue } from '../../../shared/dictionary/dictionary'
import { ColumnTopInnerContainer } from '../AlterativeHome.styles'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'

export function MarketingCard({ className, setShowDetailsModal }) {
    const octupDispatch = useOctupDispatch()
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const {
        alternativeMarketingMetrics,
        isLoading: isLoadingAlternativeMarketingMetrics,
        isRejected: isRejectedAlternativeMarketingMetrics,
    } = useSelector((state) => state.alternativeMarketingMetrics)

    useEffect(() => {
        octupDispatch.metrics.getAlternativeMarketingMetrics(
            datePickerValues.startDate,
            datePickerValues.endDate
        )
    }, [datePickerValues])

    return (
        <ColumnTopInnerContainer className={className}>
            <AlternativeHomeCardItem
                setShowDetailsModal={setShowDetailsModal}
                mainIcon={MarketingLogoSvg}
                isRejected={isRejectedAlternativeMarketingMetrics}
                isLoading={isLoadingAlternativeMarketingMetrics}
                segment={SEGMENTS.ALTERNATIVE_HOME_MARKETING}
                metrics={alternativeMarketingMetrics}
                title={getDictionaryValue('marketing_home', DICTIONARY_SECTIONS.TITLES)}
                position={'top'}
            />
        </ColumnTopInnerContainer>
    )
}
