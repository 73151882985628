import { EMAIL_FREQUENCY_UNITS } from 'models/settings'
import { EmailSettingsForm } from 'new_components/Account'
import { useFormContext } from 'react-hook-form'

const unitOptions = Object.values(EMAIL_FREQUENCY_UNITS)

export const EmailSettingsFormStateContainer = () => {
    const { watch } = useFormContext()
    const isEmailEnabled = watch('email_enabled')

    return <EmailSettingsForm isEmailEnabled={isEmailEnabled} unitOptions={unitOptions} />
}
