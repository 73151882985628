export function UPSIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M12.1559 21.2548C12.3215 21.172 16.6272 19.309 17.9934 18.1912C19.401 17.032 20.1462 15.3759 20.1462 13.3887V4.07357L20.022 4.03217C16.5858 2.16914 12.3215 2.29335 12.1145 2.29335C11.9489 2.29335 7.68466 2.16914 4.2484 4.03217L4.1656 4.07357V13.4301C4.1656 15.4173 4.91081 17.0734 6.31844 18.2326C7.68466 19.3504 11.9903 21.2134 12.1559 21.2548Z"
                fill="#341B14"
            />
            <path
                d="M12.1559 21.4618C12.1559 21.4618 7.60185 19.4746 6.19423 18.3567C4.70381 17.1147 4 15.4173 4 13.4715V3.78373C7.60185 1.8379 12.1559 2.0035 12.1559 2.0035C12.1559 2.0035 16.71 1.8379 20.3118 3.78373V13.4301C20.3118 15.3759 19.608 17.0733 18.1176 18.3153C16.71 19.4746 12.1559 21.4618 12.1559 21.4618ZM4.66241 13.4715C4.66241 15.2931 5.32482 16.7835 6.60824 17.8185C7.76746 18.7708 11.2037 20.3026 12.1559 20.7166C13.1081 20.3026 16.5858 18.7294 17.7036 17.8185C18.987 16.7835 19.6494 15.2517 19.6494 13.4715V3.94933C14.847 3.49393 9.13368 3.74233 4.66241 7.88239V13.4715Z"
                fill="#FFB406"
            />
            <path
                d="M16.7514 12.1052C17.3724 12.4778 17.6208 12.7263 17.6622 13.1817C17.6622 13.6785 17.331 13.9683 16.7928 13.9683C16.3374 13.9683 15.7992 13.7199 15.4266 13.3887V14.7135C15.882 14.9619 16.4202 15.1689 16.9998 15.1689C18.4074 15.1689 19.0698 14.1753 19.0698 13.2645C19.1112 12.4364 18.8628 11.774 17.6622 11.0702C17.124 10.739 16.71 10.532 16.71 10.0352C16.71 9.5384 17.1654 9.3314 17.538 9.3314C18.0348 9.3314 18.5316 9.6212 18.8214 9.91101V8.66899C18.573 8.46199 18.0348 8.17218 17.2482 8.21358C16.296 8.25498 15.3024 8.91739 15.3024 10.0766C15.3438 10.8632 15.5922 11.4428 16.7514 12.1052ZM11.8661 15.0861C11.9903 15.1275 12.1973 15.1689 12.5285 15.1689C14.1432 15.1689 15.054 13.7199 15.054 11.6498C15.054 9.5384 14.1018 8.25498 12.4043 8.25498C11.6177 8.25498 10.9967 8.42059 10.4585 8.75179V18.1083H11.8661V15.0861ZM11.8661 9.4556C11.9903 9.4142 12.1973 9.3314 12.3629 9.3314C13.1909 9.3314 13.5221 9.99381 13.5221 11.6084C13.5221 13.1817 13.1081 13.9269 12.2801 13.9269C12.0731 13.9269 11.9075 13.8855 11.8247 13.8441V9.4556H11.8661ZM7.51905 15.1689C8.38847 15.1689 9.13368 14.9619 9.67189 14.5893V8.33779H8.22286V13.7613C8.05726 13.8855 7.85026 13.9269 7.56046 13.9269C6.89805 13.9269 6.81524 13.3059 6.81524 12.9333V8.33779H5.36622V12.8505C5.36622 14.3823 6.11143 15.1689 7.51905 15.1689ZM17.1654 20.758V21.7102H17.331V21.3376H17.3724L17.6208 21.7102H17.8278C17.8278 21.7102 17.5794 21.3376 17.538 21.2962C17.6622 21.2548 17.745 21.172 17.745 21.0478C17.745 20.9236 17.6622 20.758 17.4138 20.758H17.1654ZM17.4138 20.8822C17.538 20.8822 17.5794 20.965 17.5794 21.0064C17.5794 21.0892 17.538 21.172 17.3724 21.172H17.331V20.8822H17.4138Z"
                fill="#FFB406"
            />
            <path
                d="M18.0762 21.2134C18.0762 21.5446 17.7864 21.8344 17.4552 21.8344C17.124 21.8344 16.8342 21.5446 16.8342 21.2134C16.8342 20.8822 17.124 20.5924 17.4552 20.5924C17.8278 20.5924 18.0762 20.8822 18.0762 21.2134ZM17.4552 20.4268C17.0412 20.4268 16.6686 20.758 16.6686 21.2134C16.6686 21.6274 16.9998 22 17.4552 22C17.8692 22 18.2418 21.6688 18.2418 21.2134C18.2418 20.7994 17.8692 20.4268 17.4552 20.4268Z"
                fill="#FFB406"
            />
        </svg>
    )
}
