// TODO: Re-write Date Picker

import { ClickAwayListener } from '@mui/base'
import styled from 'styled-components'
import { OctupDateRangePicker } from '../ReactDateRangeWrappers/OctupDateRangePicker/OctupDateRangePicker'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { getShortTableDate } from '../../utils/dates-util'
import { ChevronDownSvg } from '../../assets/icons/ChevronDownSvg'
import { ChevronUpSvg } from '../../assets/icons/ChevronUpSvg'
import {
    DATE_OPTIONS,
    datePickerActions,
    getDefaultDatePickerObj,
} from '../../../features/datePicker/datePickerSlice'
import { OctupColors } from '../../theme/colors/OctupColors'
import { useDispatch, useSelector } from 'react-redux'
import { CheckmarkSvg } from '../../assets/icons'
import { getFormattedDate } from '../ReactDateRangeWrappers/OctupDateRangePicker/utils'

export const OctupDatePicker = ({
    direction,
    month = 2,
    isMobile = false,
    hideStaticDates = false,
    showCalendar = true,
    customStaticRanges,
    ...props
}) => {
    const dispatch = useDispatch()
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [showMobilePresets, setShowMobilePresets] = useState(false)
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const [dateLabel, setDateLabel] = useState(datePickerValues.label)
    const [labelDates, setLabelDates] = useState(null)

    const eventOnClick = (e) => {
        const eventPath = e?.composedPath()
        if (
            showDatePicker &&
            !eventPath.includes(document.getElementById('date-picker-container'))
        ) {
            //fixme: this line prevents the date picker from showing on mobile, if needed wrap with proper if.
            // setShowDatePicker(false);
        }
    }

    useEffect(() => {
        window.addEventListener('click', eventOnClick)
        return () => window.removeEventListener('click', eventOnClick)
    })

    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            setShowDatePicker(false)
        }
        event.stopPropagation()
    }, [])

    useEffect(() => {
        if (!showDatePicker) return
        document.addEventListener('keydown', escFunction, true)
        return () => {
            document.removeEventListener('keydown', escFunction, true)
        }
    }, [escFunction, showDatePicker])

    const onChangeDates = (datepickerValues, mobilePreset) => {
        setDateLabel(mobilePreset.title)
        setLabelDates({
            ...datepickerValues,
            key: 'selection',
            color: '#007f82',
        })
        dispatch(
            datePickerActions.changeDates({
                ...getFormattedDate([datepickerValues]),
                key: 'selection',
                color: '#007f82',
                label: mobilePreset.title,
            })
        )
    }

    const mobilePresets = [
        {
            title: 'Custom',
            label: null,
            onClick: () => {
                setShowMobilePresets(false)
                setShowDatePicker(true)
            },
        },
        { title: 'Today', label: DATE_OPTIONS.TODAY },
        { title: 'Yesterday', label: DATE_OPTIONS.YESTERDAY },
        { title: 'This Week', label: DATE_OPTIONS.THIS_WEEK },
        { title: 'Last Week', label: DATE_OPTIONS.LAST_WEEK },
        { title: 'This Month', label: DATE_OPTIONS.THIS_MONTH },
        { title: 'Last Month', label: DATE_OPTIONS.LAST_MONTH },
        { title: 'Last Quarter', label: DATE_OPTIONS.LAST_QUARTER },
        { title: 'This Quarter', label: DATE_OPTIONS.THIS_QUARTER },
        { title: 'Last Year', label: DATE_OPTIONS.LAST_YEAR },
        { title: 'This Year', label: DATE_OPTIONS.THIS_YEAR },
    ]

    const onClick = (mobilePreset) => {
        if (mobilePreset.label) {
            onChangeDates(getDefaultDatePickerObj({ label: mobilePreset.label }), mobilePreset)
        } else if (mobilePreset.onClick) {
            mobilePreset.onClick()
        }
        setShowMobilePresets(false)
    }

    const handleClickAway = useCallback(() => {
        if (!isMobile && showDatePicker) {
            setShowDatePicker(false)
        }
    }, [isMobile, showDatePicker])

    const startFormattedDate = useMemo(
        () => labelDates && getShortTableDate(labelDates.startDate).primaryFormat,
        [labelDates]
    )

    const endFormattedDate = useMemo(
        () => labelDates && getShortTableDate(labelDates.endDate).primaryFormat,
        [labelDates]
    )

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <MainContainer id="date-picker-container">
                    <DatePickerTriggerContainer
                        id="date-picker-trigger"
                        onClick={() => {
                            if (isMobile) {
                                setShowMobilePresets(true)
                            } else if (!showDatePicker) {
                                setShowDatePicker(true)
                            }
                            if (showDatePicker || showMobilePresets) {
                                setShowDatePicker(false)
                                setShowMobilePresets(false)
                            }
                        }}
                    >
                        <DatePickerLabel>{dateLabel && dateLabel}</DatePickerLabel>
                        <DatePickerDateRangeStyled>
                            {labelDates && startFormattedDate === endFormattedDate
                                ? startFormattedDate
                                : `(${startFormattedDate}-${endFormattedDate})`}
                        </DatePickerDateRangeStyled>
                        {!showDatePicker && !showMobilePresets ? (
                            <ChevronDownSvg customColor={'#504B5A'} />
                        ) : (
                            <ChevronUpSvg customColor={'#504B5A'} />
                        )}
                    </DatePickerTriggerContainer>
                    <OctupDateRangePickerEl isMobile={isMobile} showCalendar={showCalendar}>
                        <OctupDateRangePicker
                            show={showDatePicker}
                            dateLabel={dateLabel}
                            setLabel={setDateLabel}
                            setLabelDates={setLabelDates}
                            setShowDatePicker={setShowDatePicker}
                            direction={direction}
                            month={month}
                            hideStaticDates={hideStaticDates}
                            showCalendar={showCalendar}
                            customStaticRanges={customStaticRanges}
                            {...props}
                        />
                    </OctupDateRangePickerEl>
                </MainContainer>
            </ClickAwayListener>
            {showMobilePresets && (
                <MobilePresetsContainer>
                    {mobilePresets.map((mobilePreset) => {
                        return (
                            <DatepickerButtonDiv
                                key={mobilePreset.title}
                                isSelected={dateLabel === mobilePreset.title}
                                onClick={() => onClick(mobilePreset)}
                            >
                                {mobilePreset.title}
                                {dateLabel === mobilePreset.title && (
                                    <CheckmarkContainer>
                                        <CheckmarkSvg
                                            customColor={OctupColors.primaryActionableLight}
                                        />
                                    </CheckmarkContainer>
                                )}
                            </DatepickerButtonDiv>
                        )
                    })}
                </MobilePresetsContainer>
            )}
        </>
    )
}

const MainContainer = styled.div`
    position: relative;
    z-index: 90;
`
const OctupDateRangePickerEl = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;

    margin-top: ${(props) => (!props.showCalendar ? '8rem' : '10rem')};
    margin-right: ${(props) =>
        !props.showCalendar ? '1.6rem' : props.isMobile ? '3.4rem' : '8.7rem'};
    scale: 1.2;

    border-radius: 8px;

    box-shadow:
        -0.2rem -0.2rem 0.8rem #ffffff,
        0.2rem 0.2rem 0.4rem rgba(80, 75, 90, 0.16);

    .rdrMonths {
        margin-left: 3rem;
    }

    .sc-dZeWys {
        margin-left: ${(props) => (props.isMobile ? '5rem' : '3rem')};
    }
`

const DatePickerTriggerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 3.4rem;
    width: fit-content;
    gap: 0.5rem;
    background: #f8f7fb;
    box-shadow:
        -0.2rem -0.2rem 0.8rem #ffffff,
        0.2rem 0.2rem 0.4rem rgba(80, 75, 90, 0.16);
    cursor: pointer;
    padding: 0 1.2rem 0 1.2rem;
    border-radius: 0.8rem;
    z-index: 3;
`

const DatePickerLabel = styled.div`
    white-space: nowrap;
    font-weight: 600;
    font-size: 1.3rem;
`

const DatePickerDateRangeStyled = styled.div`
    color: rgba(127, 123, 135, 1);
    white-space: nowrap;
    font-weight: 400;
    font-size: 1.3rem;
`

const MobilePresetsContainer = styled.div`
    background-color: ${OctupColors.headerIcons};
    position: absolute;
    margin-top: 3vh;
    width: 50vw;
    box-shadow: 2px 2px 4px 0 #504b5a29;
`

const DatepickerButtonDiv = styled.div`
    padding: 2rem;
    font-weight: ${(p) => (p.isSelected ? '600' : '400')};
    font-size: 2rem;
    line-height: 2rem;
    color: #504b5a;
    display: flex;
`

const CheckmarkContainer = styled.div`
    margin-left: 4rem;
`
