import { Stack, MenuList } from '@octup/core/ui-kit'
import type { MetricsSectionsListType } from 'models/metrics'
import { MetricsSectionsListItemContainer } from 'new_containers/components/Dashboards/DashboardForm/MetricsSectionsListPanelContainer'

type MetricsSectionsListPropsType = {
    sections: MetricsSectionsListType
}

export const MetricsSectionsList = ({ sections }: MetricsSectionsListPropsType) => (
    <MenuList component={Stack} gap={3}>
        {sections.map((item) => (
            <MetricsSectionsListItemContainer key={item.id} {...item} />
        ))}
    </MenuList>
)
