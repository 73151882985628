import type { Components, Theme } from '@mui/material'

type TypographyOverridesType = Pick<Components<Theme>, 'MuiTypography'>

export const TYPOGRAPHY_OVERRIDES: TypographyOverridesType = {
    MuiTypography: {
        styleOverrides: {
            root: ({ theme }) => ({
                color: theme.palette.text.primary,
                '& b': {
                    fontWeight: 600,
                },
            }),
        },
        variants: [
            {
                props: { color: 'error' },
                style: ({ theme }) => ({
                    color: theme.palette.error.main,
                }),
            },
        ],
    },
}
