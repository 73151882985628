import { useRef, useState, useEffect, useCallback } from 'react'
import type { TypographyProps, TooltipProps, SxProps, Theme } from '@mui/material'
import { Tooltip, Typography } from '@mui/material'
import { typographyStyle } from './styles'

export type OverflowTypographyPropsType = TypographyProps &
    Pick<TooltipProps, 'placement'> & {
        showTooltip?: boolean
        maxHeight?: number | string
        lineHeight?: number
    }

export const OverflowTypography = ({
    sx,
    children,
    showTooltip = true,
    maxHeight,
    lineHeight = 16,
    placement = 'top',
    ...props
}: OverflowTypographyPropsType) => {
    const typographyRef = useRef<HTMLSpanElement | null>(null)
    const [isOverflowed, setIsOverflow] = useState(false)
    const [calculatedMaxHeight, setCalculatedMaxHeight] = useState(0)

    const handleSizeCompare = useCallback(() => {
        const element = typographyRef.current

        if (element) {
            const parentElement = element.parentElement
            const maxHeightValue =
                typeof maxHeight === 'string' && maxHeight.endsWith('%')
                    ? (parseFloat(maxHeight) / 100) * (parentElement?.clientHeight || 0)
                    : Number(maxHeight)

            setCalculatedMaxHeight(maxHeightValue)

            const isOverflowingWidth = element.scrollWidth > element.clientWidth
            const isOverflowingHeight = element.scrollHeight > maxHeightValue
            setIsOverflow(isOverflowingWidth || isOverflowingHeight)
        }
    }, [maxHeight])

    useEffect(() => {
        handleSizeCompare()
        window.addEventListener('resize', handleSizeCompare)
        return () => window.removeEventListener('resize', handleSizeCompare)
    }, [handleSizeCompare, children])

    const typographySx = useCallback(
        (theme: Theme) => ({
            ...(typeof sx === 'function' ? sx(theme) : sx),
            ...typographyStyle({ maxHeight, calculatedMaxHeight, lineHeight }),
        }),
        [calculatedMaxHeight, lineHeight, maxHeight, sx]
    )

    return (
        <Tooltip
            placement={placement}
            title={children}
            disableHoverListener={!isOverflowed || !showTooltip}
        >
            <Typography ref={typographyRef} sx={typographySx as SxProps<Theme>} {...props}>
                {children}
            </Typography>
        </Tooltip>
    )
}
