// TODO: re-write the component

import React, { useEffect, useState } from 'react'
import { Button } from '@octup/core/ui-kit'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { TermsAndPrivacyCheckbox } from '@/new_components/__common__'
import { CompanyNameLogoContainer } from '@/new_containers/components/__common__'
import { evaluatePasswordRequirements, isPasswordMeetsRequirements } from './utils'
import { updateUserProfile } from '../../features/auth/authSlice'
import {
    SignupCentralized,
    SignupSupercharged,
    SignupOptimized,
    CheckmarkSvg,
    XmarkSvg,
} from '../../shared/assets/icons'
import Signup from '../../shared/assets/images/Signup.svg'
import { octupDictionary } from '../../shared/dictionary/dictionary'
import { ErrorDiv, ErrorMessage } from '../../shared/styledComponents'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { isNameValid } from '../../shared/ui/Inputs/inputUtils'
import { OctupInputField } from '../../shared/ui/Inputs/OctupInputField'
import { OctupTooltip } from '../../shared/ui/MUIWrappers/OctupTooltip'
import { isPasswordStrong } from '../../shared/utils/utils'

function InviteUserPage() {
    const dispatch = useDispatch()

    const userInfoJson = localStorage.getItem('userInfo')
    const userInfo = userInfoJson ? JSON.parse(userInfoJson) : {}

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isTermsAgreed, setIsTermsAgreed] = useState(false)
    const [showTermsAgreedError, setShowTermsAgreedError] = useState(false)
    const [currentStep] = useState(0)
    const [lastNameRelatedError, setLastNameRelatedError] = React.useState('')
    const [firstNameRelatedError, setFirstNameRelatedError] = React.useState('')
    const [passwordRelatedError, setPasswordRelatedError] = React.useState('')
    const [confirmPasswordRelatedError, setConfirmPasswordRelatedError] = React.useState('')

    const [showPasswordRequierments, setShowPasswordRequierments] = React.useState(false)

    const handleSubmit = async () => {
        if (!isTermsAgreed) {
            setShowTermsAgreedError(true)
        }
        if (firstName === '') {
            setFirstNameRelatedError('required')
        } else {
            if (!isNameValid(firstName)) {
                setFirstNameRelatedError('invalidName')
            }
        }
        if (lastName === '') {
            setLastNameRelatedError('required')
        } else {
            if (!isNameValid(lastName)) {
                setLastNameRelatedError('invalidName')
            }
        }
        if (password === '') {
            setPasswordRelatedError('required')
        }
        if (confirmPassword === '') {
            setConfirmPasswordRelatedError('required')
        } else {
            if (confirmPassword !== password) {
                setConfirmPasswordRelatedError('passwordsDontMatch')
            } else if (!isPasswordStrong(password)) {
                setPasswordRelatedError('weakPassword')
            } else {
                setConfirmPasswordRelatedError(null)
            }
        }

        if (
            !firstNameRelatedError &&
            !lastNameRelatedError &&
            !passwordRelatedError &&
            isPasswordStrong(confirmPassword) &&
            password === confirmPassword &&
            isTermsAgreed
        ) {
            const user = await dispatch(
                updateUserProfile({
                    fullName: firstName + ' ' + lastName,
                    isSignup: true,
                    token: userInfo?.token,
                    password,
                })
            )
            if (user) {
                userInfo.isActive = true
                userInfo.fullName = firstName + ' ' + lastName
                localStorage.setItem('userInfo', JSON.stringify(userInfo))
                window.location.replace('/')
            }
        }
    }

    const [passwordRequirements, setPasswordRequirements] = useState(evaluatePasswordRequirements())

    useEffect(() => {
        setPasswordRequirements(evaluatePasswordRequirements(password))
    }, [password])

    const steps = [
        {
            title: 'Create a new account',
            buttonText: 'Create Account',
            renderLeftCenter: () => {
                return (
                    <>
                        <OctupInputField
                            id="1"
                            labelBIId={'User Invite- firstName input field'}
                            width={'50rem'}
                            type={'email'}
                            value={firstName}
                            color={firstName && firstName.length > 1 ? 'green' : ''}
                            placeholder={'First Name'}
                            onChange={(e) => {
                                setFirstName(e.target.value)
                            }}
                        />
                        <ErrorDiv>
                            {firstNameRelatedError && (
                                <ErrorMessage>
                                    {octupDictionary.errors.messages[firstNameRelatedError]}
                                </ErrorMessage>
                            )}
                        </ErrorDiv>
                        <>
                            <OctupInputField
                                id="2"
                                labelBIId={'User Invite- Last Name input field'}
                                width={'50rem'}
                                transformX={'-2rem'}
                                type={'email'}
                                placeholder={'Last Name'}
                                value={lastName}
                                color={lastName && lastName.length > 2 ? 'green' : ''}
                                onChange={(e) => {
                                    setLastName(e.target.value)
                                }}
                            />
                            <ErrorDiv>
                                {firstNameRelatedError && (
                                    <ErrorMessage>
                                        {octupDictionary.errors.messages[firstNameRelatedError]}
                                    </ErrorMessage>
                                )}
                            </ErrorDiv>
                        </>
                        <OctupInputField
                            id="2"
                            labelBIId={'User Invite- password input field'}
                            width={'50rem'}
                            transformX={'-2rem'}
                            type={'password'}
                            placeholder={'Choose Password'}
                            onFocusCallback={() => setShowPasswordRequierments(true)}
                            onBlur={() => setShowPasswordRequierments(false)}
                            value={password}
                            color={isPasswordMeetsRequirements(password) ? 'green' : ''}
                            // onBlur={!isPasswordStrong(password) ? setPasswordRelatedError('weakPassword') : null}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                setPasswordRelatedError(null)
                            }}
                        />

                        {showPasswordRequierments && (
                            <PasswordRequirementsDiv>
                                {showPasswordRequierments &&
                                    passwordRequirements.map((req) => {
                                        return (
                                            <PasswordRequirementText
                                                color={req.condition ? 'green' : ''}
                                            >
                                                {req.condition ? (
                                                    <CheckmarkSvg customColor={'green'} />
                                                ) : (
                                                    <XmarkSvg customColor={'black'} />
                                                )}{' '}
                                                {req.message}
                                            </PasswordRequirementText>
                                        )
                                    })}
                            </PasswordRequirementsDiv>
                        )}

                        <ErrorDiv>
                            {passwordRelatedError && (
                                <>
                                    <OctupTooltip
                                        title={
                                            octupDictionary.errors.tooltips[passwordRelatedError]
                                        }
                                    >
                                        <ErrorMessage>
                                            {octupDictionary.errors.messages[passwordRelatedError]}
                                        </ErrorMessage>
                                    </OctupTooltip>
                                </>
                            )}
                        </ErrorDiv>
                        {password.length > 0 && (
                            <>
                                <OctupInputField
                                    id="2"
                                    labelBIId={'User Invite- confirm password input field'}
                                    width={'50rem'}
                                    transformX={'-2rem'}
                                    type={'password'}
                                    placeholder={'Confirm Password'}
                                    value={confirmPassword}
                                    color={
                                        confirmPassword === lastName &&
                                        isPasswordMeetsRequirements(confirmPassword)
                                            ? 'green'
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value)
                                        setConfirmPasswordRelatedError(null)
                                    }}
                                />
                                <ErrorDiv>
                                    {confirmPasswordRelatedError && (
                                        <OctupTooltip
                                            title={
                                                octupDictionary.errors.tooltips.passwordsDontMatch
                                            }
                                        >
                                            <ErrorMessage>
                                                {
                                                    octupDictionary.errors.messages[
                                                        confirmPasswordRelatedError
                                                    ]
                                                }
                                            </ErrorMessage>
                                        </OctupTooltip>
                                    )}
                                </ErrorDiv>
                            </>
                        )}
                    </>
                )
            },
            renderUnderButtonContainer: () => {
                return (
                    <>
                        <PrivacyPolicy>
                            <Column>
                                <TermsAndPrivacyCheckbox
                                    checked={isTermsAgreed}
                                    onClick={() => {
                                        setIsTermsAgreed(!isTermsAgreed)
                                        setShowTermsAgreedError(false)
                                    }}
                                />
                                {showTermsAgreedError && (
                                    <TermsAndConditionsError>
                                        In order to continue you first need to agree to this
                                    </TermsAndConditionsError>
                                )}
                            </Column>
                        </PrivacyPolicy>
                    </>
                )
            },
            renderRight: () => {
                return (
                    <MarginBottom>
                        <RightSideTitle>
                            {
                                'Octup is designed to help you be more productive and efficient, so you can focus on what matters most.'
                            }
                        </RightSideTitle>
                        <RightSideSubTitle>
                            We offer a wide range of tools and resources to help you manage your
                            business, collaborate with your team, and achieve your goals.
                        </RightSideSubTitle>
                        <div>
                            <Row>
                                <LogoContainer>
                                    <SignupCentralized />
                                    <LogoText>No credit card required</LogoText>
                                </LogoContainer>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <LogoContainer>
                                    <SignupSupercharged />
                                    <LogoText>Instant Set Up</LogoText>
                                </LogoContainer>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <LogoContainer>
                                    <SignupOptimized />
                                    <LogoText>24/7 Support</LogoText>
                                </LogoContainer>
                            </Row>
                        </div>
                    </MarginBottom>
                )
            },
            renderSvg: () => {
                return <RightSideSvgStep0 currStep={currentStep} src={Signup} />
            },
        },
    ]

    return (
        <StyledContainer>
            <LeftSideContainer>
                <PaddedOctupLogo>
                    <CompanyNameLogoContainer />
                </PaddedOctupLogo>
                <SignupContainer paddingTop={'15rem'} isExtanded={password.length > 0}>
                    <StyledTitle variant="h5">{steps[currentStep].title}</StyledTitle>
                    {steps[currentStep].renderLeftCenter()}
                    {steps[currentStep].renderLeftBottom && steps[currentStep].renderLeftBottom()}
                    <StyledButton
                        style={{ fontSize: '1.8rem', fontWeight: '600' }}
                        variant="contained"
                        color="success"
                        onClick={handleSubmit}
                    >
                        {steps[currentStep].buttonText}
                    </StyledButton>
                    {steps[currentStep].renderUnderButtonContainer &&
                        steps[currentStep].renderUnderButtonContainer()}
                </SignupContainer>
            </LeftSideContainer>
            <RightSideContainer>
                {steps[currentStep].renderSvg()}
                <RightSideTextContainer>{steps[currentStep].renderRight()}</RightSideTextContainer>
            </RightSideContainer>
        </StyledContainer>
    )
}

const StyledContainer = styled.div``

const Row = styled.div`
    display: flex;
    flex-direction: row;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const SignupContainer = styled.div`
    min-height: ${(props) => (props.isExtanded ? '80rem' : '70rem')};
    padding-top: ${(props) => props.paddingTop};
    width: fit-content;
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const LeftSideContainer = styled.div`
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
`

const StyledTitle = styled.h1`
    font-weight: 600;
    color: #504b5a;
    font-size: 20px;
    line-height: 27px;
`

const RightSideContainer = styled.div`
    position: absolute;
    border-radius: 50px;
    background-color: #f8f7fb;
    width: 62%;
    height: 100%;
    right: 0;
    top: 0;
`

const RightSideSvgStep0 = styled.img`
    position: absolute;
    right: 0;
    height: auto;
    width: 105rem;
`

const RightSideTextContainer = styled.div`
    position: absolute;
    top: 40%;
    left: 12rem;
`

const RightSideTitle = styled.div`
    font-weight: 400;
    font-size: 27px;
    line-height: 36px;
    width: 80rem;
    color: #504b5a;
    white-space: pre-wrap;
`

const RightSideSubTitle = styled.div`
    font-size: 17px;
    line-height: 22px;
    font-weight: 600;
    width: 70rem;
`

const PaddedOctupLogo = styled.div`
    padding: 5rem 0 0 5rem;
`

const StyledButton = styled(Button)`
    font-size: 2.2rem;
    width: 50rem;
    text-transform: none !important;
    height: 6rem;
    background: linear-gradient(
        44.56deg,
        ${OctupColors.primaryActionableDark} -1.25%,
        ${OctupColors.primaryActionableLight} 100.79%
    );
    box-shadow:
        -2px -2px 8px #ffffff,
        2px 2px 4px rgba(80, 75, 90, 0.16);
    border-radius: 0.8rem !important;
    :disabled {
        background: #c2c0c7;
        box-shadow:
            -2px -2px 8px #ffffff,
            2px 2px 4px rgba(80, 75, 90, 0.16);
    }
    :hover {
        background: #00a8a8 !important;
        transition: none !important;
    }
`

const LogoContainer = styled.div`
    padding-top: 4rem;
    padding-left: 4rem;
    margin-left: -4rem;
    align-items: center;
    display: flex;
`

const LogoText = styled.div`
    font-size: 17px;
    line-height: 23px;
    padding-left: 2rem;
`

const PrivacyPolicy = styled.div``

const PasswordRequirementsDiv = styled.div`
    min-width: 30rem;
    position: absolute;
    left: 60rem;
    top: 45rem;
    background-color: white;
    box-shadow: 8px 8px 24px 0 #504b5a14;
    white-space: pre-wrap;
    padding: 1rem;
    line-height: 3.4rem;
    text-align: left;
`

const PasswordRequirementText = styled.div`
    color: ${(props) => props.color};
    font-size: 1.6rem;
    font-weight: 400;
`

const TermsAndConditionsError = styled.div`
    position: absolute;
    background-color: ${OctupColors.redOnLight};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: white;
    margin-top: 7.5rem;
    width: 50rem;
`

const MarginBottom = styled.div`
    @media only screen and (max-height: 1200px) {
        margin-top: 20rem;
        margin-left: -8rem;
    }
    @media only screen and (min-aspect-ratio: 13 / 6) {
        position: absolute;
        bottom: -60rem;
    }
    @media only screen and (max-height: 600px) {
        position: absolute;
        margin-top: -40rem;
    }
`

export default InviteUserPage
