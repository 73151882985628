// TODO: check if it is a right place to put this slice (metrics folder)

import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    label: null,
    startDate: null,
    endDate: null,
}

export const datePickerSlice = createSlice({
    name: 'datePicker',
    initialState: INITIAL_STATE,
    reducers: {
        resetDatePicker() {
            return INITIAL_STATE
        },
        changeDatePicker(_, actions) {
            return actions.payload
        },
    },
})

export const { resetDatePicker, changeDatePicker } = datePickerSlice.actions
export const datePickerReducer = datePickerSlice.reducer
