import axios from 'axios'
import { GLOBAL_CONFIG } from 'config'
import { generateAPIConfig } from 'services/utils'

export const ORDERS_API_URL = GLOBAL_CONFIG.BASE_URL + 'metrics/orders'

/**
 * get orders by source or campaign
 * @source optional
 * @campaign_id optional
 * @campaign_name optional
 * @start_date mandatory
 * @end_date mandatory
 */

export const getOrders = async (data, thunkAPI) => {
    const { startDate, endDate, source, campaignId, campaignName, summaryLine } = data
    const config = generateAPIConfig({ thunkAPI })

    const response = await axios.post(
        ORDERS_API_URL,
        {
            start_date: startDate,
            end_date: endDate,
            source,
            campaign_id: campaignId,
            campaign_name: campaignName,
            summary_line: summaryLine,
        },
        config
    )

    return response.data
}
