import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import Locked from '../../shared/assets/images/Signup_locked.svg'
import MockedSystem from '../../shared/assets/images/Signup_system_mock.svg'
import { OctupInputField } from '../../shared/ui/Inputs/OctupInputField'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { useLocation } from 'react-router-dom'
import { octupDictionary } from '../../shared/dictionary/dictionary'
import { Greeting } from '../../components/Greeting/Greeting'
import { isEmailValid } from '../../shared/ui/Inputs/inputUtils'
import { useDispatch, useSelector } from 'react-redux'
import { isPasswordStrong } from '../../shared/utils/utils'
import { emailFieldOnBlur, handleSubmit } from './loginUtils'
import MailSent from '../../shared/assets/images/MailSent.svg'
import PasswordReset from '../../shared/assets/images/PasswordReset.svg'
import MobileTopBar from 'pages/home/mobile/MobileTopBar'

function MobileLoginPage() {
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const passwordResetToken = searchParams.get('pw_rs_tk')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setconformPassword] = useState('')
    const [firstName] = useState('')
    const [currStep, setCurrStep] = useState(passwordResetToken ? 3 : 0)
    const [invalidEmail, setInvalidEmail] = useState('')
    const [emailRelatedError, setEmailRelatedError] = React.useState('')
    const [passwordRelatedError, setPasswordRelatedError] = React.useState('')
    const { isLoading } = useSelector((state) => state.auth)
    const [confirmPasswordRelatedError, setConfirmPasswordRelatedError] = React.useState('')

    const handleEnter = (event) => {
        if (event.key === 'Enter' && !event.repeat && !isLoading) {
            handleSubmit({
                email,
                password,
                setPasswordRelatedError,
                setEmailRelatedError,
                setConfirmPasswordRelatedError: () => {},
                currStep,
                setCurrStep,
                confirmPassword,
                passwordResetToken,
                dispatch,
            })
        }
    }

    useEffect(() => {
        const inputElement = document
        inputElement.addEventListener('keydown', handleEnter)
        return () => {
            inputElement.removeEventListener('keydown', handleEnter)
        }
    })

    const steps = [
        {
            buttonText: 'Login',
            renderLeft: () => {
                return (
                    <InputsContainer>
                        <OctupInputField
                            id="1"
                            width={'40rem'}
                            type={'email'}
                            value={email}
                            color={
                                invalidEmail && !isEmailValid(email) ? OctupColors.alertLight : ''
                            }
                            onBlur={() =>
                                emailFieldOnBlur({
                                    email,
                                    setEmailRelatedError,
                                    setInvalidEmail,
                                    dispatch,
                                })
                            }
                            placeholder={'Work Email'}
                            inputId={'mobile-login-email-input'}
                            onChange={(e) => {
                                setEmail(e.target.value)
                                setPasswordRelatedError('')
                                if (isEmailValid(email)) {
                                    setEmailRelatedError(null)
                                }
                            }}
                        />
                        <ErrorDiv>
                            {emailRelatedError && <ErrorMessage>{emailRelatedError}</ErrorMessage>}
                        </ErrorDiv>
                        <OctupInputField
                            id="2"
                            width={'40rem'}
                            inputId={'mobile-login-password-input'}
                            type={'password'}
                            placeholder={'Password'}
                            value={password}
                            color={
                                // isPasswordStrong(password)
                                emailRelatedError ? '' : OctupColors.alertLight
                            }
                            onKeyDown={(e) => handleEnter(e)}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                setPasswordRelatedError('')
                                if (isPasswordStrong(password)) {
                                    setPasswordRelatedError(null)
                                }
                            }}
                            onBlur={() => {
                                setInvalidEmail(true)
                            }}
                        />
                        <ErrorDiv>
                            {passwordRelatedError && (
                                <ErrorMessage>{passwordRelatedError}</ErrorMessage>
                            )}
                        </ErrorDiv>
                    </InputsContainer>
                )
            },
        },
        {
            buttonText: 'Send a link to my email',
            renderLeft: () => {
                return (
                    <ForgotPasswordMessageContainer>
                        <MessageText>{octupDictionary.messages.forgotPasswordMobile}</MessageText>
                        <OctupInputField
                            id="1"
                            inputId={'mobile-login-forgotPassword-input'}
                            width={'80vw'}
                            type={'email'}
                            value={email}
                            placeholder={'Work Email'}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                    </ForgotPasswordMessageContainer>
                )
            },
            renderRight: () => {
                return <></>
            },
            renderSvg: () => {
                return <RightSideSvgStep1 currStep={currStep} src={Locked} />
            },
        },
        {
            renderLeft: () => {
                return (
                    <>
                        <EmailSentContainer>
                            <EmailSentImageContainer>
                                <img style={{ width: '80%' }} src={MailSent} alt="mail sent" />
                            </EmailSentImageContainer>
                            <StyledTitle variant="h5">
                                Check your email to reset your password
                            </StyledTitle>
                            <MessageText>
                                {octupDictionary.messages.forgotPasswordMobileEmailSent}
                            </MessageText>
                        </EmailSentContainer>
                        {/*<MessageText>*/}
                        {/*  {"Didn't receive anything?"}*/}
                        {/*</MessageText>*/}
                    </>
                )
            },
            renderRight: () => {
                return <></>
            },
            renderSvg: () => {
                return <RightSideSvgStep1 currStep={currStep} src={Locked} />
            },
        },
        {
            buttonText: 'Reset Password',
            renderLeft: () => {
                return (
                    <ForgotPasswordMessageContainer>
                        <MessageText>{octupDictionary.messages.newPasswordMobile}</MessageText>
                        <OctupInputField
                            id="5"
                            width={'80vw'}
                            type={'password'}
                            value={password}
                            placeholder={'Create Password'}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                setPasswordRelatedError('')
                            }}
                        />
                        <ErrorDiv>
                            {passwordRelatedError && (
                                <>
                                    <ErrorMessage>{passwordRelatedError}</ErrorMessage>
                                </>
                            )}
                        </ErrorDiv>
                        <OctupInputField
                            id="6"
                            width={'80vw'}
                            type={'password'}
                            placeholder={'Confirm Password'}
                            value={confirmPassword}
                            onChange={(e) => {
                                setconformPassword(e.target.value)
                                setConfirmPasswordRelatedError('')
                            }}
                        />
                        <ErrorDiv>
                            {confirmPasswordRelatedError && (
                                <>
                                    <ErrorMessage>{confirmPasswordRelatedError}</ErrorMessage>
                                </>
                            )}
                        </ErrorDiv>
                    </ForgotPasswordMessageContainer>
                )
            },
            renderRight: () => {
                return <Z>{<Greeting firstName={firstName} fontSize={'16px'} />}</Z>
            },
            renderSvg: () => {
                return <RightSideSvgStep2 currStep={currStep} src={MockedSystem} />
            },
        },
        {
            renderLeft: () => {
                return (
                    <>
                        <EmailSentContainer>
                            <PasswordResetImageContainer>
                                <img
                                    style={{ width: '100%' }}
                                    src={PasswordReset}
                                    alt="reset password"
                                />
                            </PasswordResetImageContainer>
                            <StyledTitle variant="h5">Password has been reset</StyledTitle>
                            <ClickableSpan onClick={() => setCurrStep(0)}>
                                Back to Login
                            </ClickableSpan>
                        </EmailSentContainer>
                        {/*<MessageText>*/}
                        {/*  {"Didn't receive anything?"}*/}
                        {/*</MessageText>*/}
                    </>
                )
            },
            renderRight: () => {
                return <></>
            },
            renderSvg: () => {
                return <RightSideSvgStep1 currStep={currStep} src={Locked} />
            },
        },
    ]

    return (
        <StyledContainer>
            <MobileTopBar />
            <LeftSideContainer>
                <LoginContainer>
                    {currStep !== 2 && (
                        <StyledTitle variant="h5">Log In to your account</StyledTitle>
                    )}
                    {steps[currStep].renderLeft()}
                    {steps[currStep].buttonText && (
                        <StyledButton
                            style={{ fontSize: '2rem' }}
                            variant="contained"
                            color="success"
                            onClick={() => {
                                handleSubmit({
                                    email,
                                    password,
                                    setPasswordRelatedError,
                                    setEmailRelatedError,
                                    setConfirmPasswordRelatedError,
                                    currStep,
                                    setCurrStep,
                                    confirmPassword,
                                    passwordResetToken,
                                    dispatch,
                                })
                            }}
                        >
                            {steps[currStep].buttonText}
                        </StyledButton>
                    )}
                    {currStep === 0 && (
                        <BottomContainer>
                            <ClickableSpan onClick={() => setCurrStep(1)}>
                                Forgot Password?
                            </ClickableSpan>
                            <Line>
                                <LoginText>{octupDictionary.messages.noAccountMobile}</LoginText>
                            </Line>
                        </BottomContainer>
                    )}
                    {currStep === 0 && (
                        <PrivacyPolicy>
                            <LinkText
                                onClick={() =>
                                    window.location.replace('https://octup.com/privacy.html')
                                }
                            >
                                Privacy Policy
                            </LinkText>
                        </PrivacyPolicy>
                    )}
                </LoginContainer>
            </LeftSideContainer>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
`

const LoginContainer = styled.div`
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
`

const LeftSideContainer = styled.div`
    height: 100%;
    background-color: white;
`

const StyledTitle = styled.h1`
    font-weight: 600;
    color: #504b5a;
    text-align: center;
    font-size: 2.8rem;
    line-height: 10rem;
`

const MessageText = styled.span`
    font-weight: 400;
    font-size: 2rem;
    line-height: 2rem;
    text-align: center;
    color: #504b5a;
    white-space: pre-wrap;
`

const ForgotPasswordMessageContainer = styled.div`
    width: 80vw;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 5vh;
`

const Line = styled.div`
    display: flex;
    flex-direction: row;
`

const LoginText = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #504b5a;
    white-space: pre-wrap;
    text-align: center;
`

const LinkText = styled(LoginText)`
    cursor: pointer;
    color: #007f82;
`

const EmailSentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 70%;
    min-height: 15vh;
    align-items: center;
`

const EmailSentImageContainer = styled.div`
    width: 20%;
    height: 23%;
`

const PasswordResetImageContainer = styled.div`
    width: 35%;
    height: 33%;
`

const RightSideSvgStep1 = styled.img`
    position: absolute;
    right: 15%;
    height: 100%;
    width: 105rem;
`

const RightSideSvgStep2 = styled.img`
    position: absolute;
`

const StyledButton = styled(Button)`
    font-size: 2.2rem;
    width: 80vw;
    text-transform: none !important;
    height: 6rem;
    background: linear-gradient(
        44.56deg,
        ${OctupColors.primaryActionableDark} -1.25%,
        ${OctupColors.primaryActionableLight} 100.79%
    );

    box-shadow:
        -2px -2px 8px #ffffff,
        2px 2px 4px rgba(80, 75, 90, 0.16);
    border-radius: 0.8rem !important;

    :disabled {
        background: #c2c0c7;
        box-shadow:
            -2px -2px 8px #ffffff,
            2px 2px 4px rgba(80, 75, 90, 0.16);
    }
`

const Z = styled.div`
    position: absolute;
    left: 190px;
    top: -32vh;
    width: 3000px;
`

const ErrorDiv = styled.div`
    height: 30%;
    width: 41rem;
`

const ErrorMessage = styled.h1`
    color: #db2059;
    font-size: 1.8rem;
    text-align: left;
    white-space: pre-wrap;
    position: absolute;
`

const ClickableSpan = styled.div`
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.8rem;
    display: flex;
    align-items: center;
    color: #007f82;
    cursor: pointer;
`

const InputsContainer = styled.div`
    height: 30%;
    display: flex;
    flex-direction: column;
`

const BottomContainer = styled.div`
    padding-top: 4rem;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const PrivacyPolicy = styled.div``

export default MobileLoginPage
