import styled from 'styled-components'
import { octupDictionary } from '../../dictionary/dictionary'
import { OctupColors } from '../../theme/colors/OctupColors'
import OctyGoneFishing from '../../assets/images/Octy_GoneFishing.svg'

export const OctupNoDataFound = ({ width = '20rem' }) => {
    return (
        <LoaderContainer>
            <LoaderInnerContainer>
                <ColumnContainer>
                    <RejectedImage width={width} src={OctyGoneFishing} />
                    <Span>{octupDictionary.messages.noData}</Span>
                </ColumnContainer>
            </LoaderInnerContainer>
        </LoaderContainer>
    )
}

const LoaderContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
`

const LoaderInnerContainer = styled.div`
    /* margin-top: -1.5rem; */
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`

const ColumnContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Span = styled.span`
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${OctupColors.textOverDarkBg};
`

const RejectedImage = styled.img`
    width: ${(props) => props.width};
`
