import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CostManagerPage } from '@/new_pages'
import { fetchCosts, removeCosts } from '@/state/features/costManager'

export const CostManagerPageContainer = () => {
    const dispatch = useDispatch()
    const [showCostModal, setShowCostModal] = useState(false)
    const toggleCostModal = useCallback(() => setShowCostModal((open) => !open), [])

    useEffect(() => {
        return () => dispatch(removeCosts())
    }, [dispatch])

    const handleUpdateData = useCallback(() => dispatch(fetchCosts()), [dispatch])

    useEffect(() => {
        handleUpdateData()
    }, [dispatch, handleUpdateData])

    return (
        <CostManagerPage
            showCostModal={showCostModal}
            onToggleCostModal={toggleCostModal}
            onUpdateData={handleUpdateData}
        />
    )
}
