// NOTE: This is a temporary solution until react-router-dom is updated to v6.

import { useCallback, useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

const useConfirmExit = (when = true, confirmExit: () => boolean, callback: () => void) => {
    const { navigator } = useContext(NavigationContext)

    useEffect(() => {
        if (!when) {
            return
        }

        const push = navigator.push

        navigator.push = (...args: Parameters<typeof push>) => {
            const result = confirmExit()
            if (result !== false) {
                push(...args)
                callback()
            }
        }

        return () => {
            navigator.push = push
        }
    }, [navigator, confirmExit, when, callback])
}

export const usePrompt = ({
    when = true,
    message = 'Changes you made may not be saved.',
    callback = () => {},
}) => {
    useEffect(() => {
        if (when) {
            window.onbeforeunload = () => message
        }

        return () => {
            window.onbeforeunload = null
        }
    }, [message, when])

    const confirmExit = useCallback(() => {
        const confirm = window.confirm(message)
        return confirm
    }, [message])

    useConfirmExit(when, confirmExit, callback)
}
