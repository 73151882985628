import { Button, Stack, TextField } from '@octup/core/ui-kit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { ObjectSchema } from 'yup'
import { SignupLayout } from '@/layouts'
import type { ClientUserType } from '@/models/clients'
import type { CreateUserFormType } from '@/models/signup'
import { TermsAndPrivacyCheckbox } from '@/new_components/__common__'
import { FormContainer, FormFieldContainer } from '@/new_containers/components/__common__'

export type CreateUserFormPropsType = {
    defaultValues: CreateUserFormType
    validationSchema: ObjectSchema<Partial<CreateUserFormType>>
    onSubmit: (data: CreateUserFormType) => void
    onSuccess: (data: PayloadAction<ClientUserType>) => void
    onFail: () => void
    isLoading: boolean
}

export const CreateUserForm = ({ isLoading, ...props }: CreateUserFormPropsType) => (
    <SignupLayout title="Create a new User">
        {/* @ts-expect-error: FormContainer is a JavaScript file */}
        <FormContainer {...props}>
            <Stack spacing={4}>
                <FormFieldContainer
                    as={TextField}
                    fullWidth
                    readOnly
                    name="shop"
                    disabled
                    label="Store Name"
                />
                <FormFieldContainer
                    as={TextField}
                    fullWidth
                    readOnly
                    name="fullName"
                    disabled
                    label="Full Name"
                />
                <FormFieldContainer
                    as={TextField}
                    fullWidth
                    readOnly
                    name="email"
                    disabled
                    label="Email"
                />
                <FormFieldContainer
                    as={TextField}
                    type="password"
                    fullWidth
                    name="password"
                    label="Password"
                />
                <FormFieldContainer
                    as={TextField}
                    type="password"
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                />
                <Button type="submit" variant="contained" size="large" loading={isLoading}>
                    Sign Up
                </Button>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <FormFieldContainer as={TermsAndPrivacyCheckbox} name="isTermAccepted" />
                </Stack>
            </Stack>
        </FormContainer>
    </SignupLayout>
)
