const baseURL = process.env.REACT_APP_URL || window.location.origin + '/'

export const GLOBAL_CONFIG = {
    API_URL: baseURL + 'api',
    DAL_BASE_URL: baseURL + '/dal',
    BASE_URL: baseURL,
    AUTH_MS_BASE_URL: baseURL,
    METRICS_SERVICE: baseURL,
    NARROW_SCREEN_WIDTH: '1460px',
    schemaPassword: 'octopus',
}

export const LOCAL_HOSTNAME = 'localhost'
