import type { Theme } from '@mui/material'

export type ListItemImageStyleType = {
    size: 'small' | 'medium' | number
}

const imageContainerStyle = (theme: Theme) => ({
    border: `${theme.spacing(0.6)} solid ${theme.palette.grey['A400']}`,
    borderRadius: theme.spacing(1.6),
    boxShadow: theme.shadows[3],

    '& img, & .MuiSvgIcon-root': {
        width: '100%',
        height: 'auto',
        borderRadius: theme.spacing(1.6),
    },
})

const smallSizeStyle = (theme: Theme) => ({
    ...imageContainerStyle(theme),
    height: theme.spacing(7),
    width: theme.spacing(7),
    minWidth: theme.spacing(7),
})

const mediumSizeStyle = (theme: Theme) => ({
    ...imageContainerStyle(theme),
    height: theme.spacing(10),
    width: theme.spacing(10),
    minWidth: theme.spacing(10),
})

export const getCustomSizeStyle = (size: number) => (theme: Theme) => ({
    ...imageContainerStyle(theme),
    height: theme.spacing(size),
    width: theme.spacing(size),
    minWidth: theme.spacing(size),
})

export const STYLE_BY_SIZE = {
    small: smallSizeStyle,
    medium: mediumSizeStyle,
}

export const getStyleBySize = (size: 'small' | 'medium' | number) => (theme: Theme) => {
    if (typeof size === 'number') {
        return getCustomSizeStyle(size)(theme)
    }

    const getStyleBySize = STYLE_BY_SIZE[size]
    return getStyleBySize?.(theme)
}
