import styled from 'styled-components'
import { VALUE_TYPE } from '../../../../features/metrics'
import { getCurrencySymbol, getFormattedVal } from '../../../../shared/utils/metrics-utils'

const MetricValue = ({
    title,
    value,
    currency,
    valueUnits,
    fontSize = '4rem',
    isSupported,
    isLoading,
}) => {
    const getMetricValueAsString = (value, valueUnits) => {
        //Handle unsupported metric
        if (isSupported === false) {
            return '-'
        }
        //Input validation
        if (!value && value !== 0) {
            return '0'
        }

        let shownValue = value
        if (+valueUnits === VALUE_TYPE.PERCENTAGE) {
            shownValue = value * 100
        }

        if (isNaN(+shownValue)) {
            //Verify return value is not NaN
            return 0
        } else if (!shownValue && shownValue !== 0 && 0) {
            //Handle 0
            return 0
        } else if (shownValue < 0) {
            //Handle negative values
            return parseFloat(shownValue)
                .toFixed(+valueUnits === VALUE_TYPE.PERCENTAGE ? 0 : 2)
                .toLocaleString()
        } else if (shownValue > 50) {
            //Handle large values
            return parseInt(shownValue).toLocaleString()
        } else {
            return parseFloat(
                parseFloat(shownValue).toFixed(+valueUnits === VALUE_TYPE.PERCENTAGE ? 0 : 2)
            ).toLocaleString()
        }
    }
    return (
        !isLoading && (
            <MetricValueStyle
                height={getMetricValueAsString(value, valueUnits) !== 0 ? '200%' : '100%'}
                fontSize={fontSize}
                lineHeight={getMetricValueAsString(value, valueUnits) !== 0 ? '10rem' : '8rem'}
            >
                <MetricIconStyle>
                    {isSupported && currency && getCurrencySymbol(currency)}
                </MetricIconStyle>
                {getFormattedVal({
                    val: value,
                    valueType: valueUnits,
                    customNumOfDigits: title && title.toLowerCase() === 'orders' ? 0 : null,
                    currency: currency,
                    isSupported: isSupported,
                    showSymbol: false,
                })}
            </MetricValueStyle>
        )
    )
}

const MetricIconStyle = styled.span`
    font-weight: 300;
    font-size: 2.4rem;
    line-height: 2.7rem;
    margin-bottom: -1.2rem;
`

const MetricValueStyle = styled.div`
    color: ${(p) => p.color};
    display: flex;
    align-items: center;
    width: fit-content;
    max-height: 6.5rem;
    min-height: 6.5rem;
    font-style: normal;
    font-weight: 275;
    font-size: ${(p) => p.fontSize};
`

export default MetricValue
