import React from 'react'
import { getIconColor } from './utils'

export function ObjectivesLogoSvg({ active, customActiveColor, customColor, disabled }) {
    return (
        <svg
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9853 12.0044L19.9897 4"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.6453 6.34351C16.2274 4.92841 14.3301 4.09704 12.3286 4.01386C10.3271 3.93068 8.36723 4.60177 6.83681 5.89435C5.3064 7.18692 4.31687 9.00684 4.06399 10.994C3.81111 12.9813 4.31331 14.991 5.47123 16.6257C6.62915 18.2603 8.35844 19.4009 10.317 19.8216C12.2755 20.2424 14.3207 19.9127 16.0478 18.8978C17.7749 17.8829 19.0582 16.2567 19.6438 14.341C20.2294 12.4253 20.0746 10.3595 19.21 8.55245"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.8153 9.17433C14.1536 8.51269 13.281 8.10381 12.3492 8.01877C11.4173 7.93372 10.4851 8.17788 9.71462 8.70881C8.94411 9.23974 8.38399 10.0239 8.13162 10.9249C7.87925 11.826 7.95058 12.7869 8.33322 13.6409C8.71585 14.4948 9.3856 15.1876 10.2261 15.599C11.0665 16.0103 12.0245 16.1142 12.9336 15.8925C13.8427 15.6708 14.6453 15.1376 15.2021 14.3855C15.7588 13.6334 16.0344 12.71 15.981 11.7758"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
