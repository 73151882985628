import styled from 'styled-components'
import SidebarSubMenuItem from '../SidebarSubMenu/SidebarSubMenuItem'

const SidebarSubMenu = ({ subMenu, onSubItemSelection }) => (
    <SidebarSubMenuContainer>
        {subMenu.map((el) => (
            <SidebarSubMenuItem
                subMenuItem={el}
                key={el.id || el.title}
                onSubItemSelection={onSubItemSelection}
            />
        ))}
    </SidebarSubMenuContainer>
)

const SidebarSubMenuContainer = styled.ul`
    width: 100%;
    overflow-y: auto;
    max-height: 25rem;
    height: fit-content;
    background-color: #282831;
    justify-content: space-between;
`

export default SidebarSubMenu
