import type { Shadows } from '@mui/material'

/**
 *  TODO: Fill the shadows array with values (instead of none),
 *  be aware of mui default values
 */

export const SHADOWS: Shadows = [
    'none',
    '2px 2px 4px 0px rgba(80, 75, 90, 0.16)',
    '2px 2px 8px 0px rgba(80, 75, 90, 0.32) inset',
    '2px 2px 4px 0px #504B5A29, -2px -2px 8px 0px #FFFFFF',
    '2px 2px 8px 0px #504B5A52 inset, -2px -2px 4px 0px #FFFFFF inset',
    '8px 8px 24px 0px #504B5A14',
    '8px 8px 24px 0px #504B5A14, 4px -4px 8px 0px #FFFFFFBF',
    '2px 2px 8px 0px #504B5A52 inset',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
]
