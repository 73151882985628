import React from 'react'
import { getIconColor } from './utils'

export function CustomizeMetricsSvg({
    active,
    customActiveColor = '#007F82',
    customColor = '#007F82',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <rect
                x="4.75"
                y="4.75"
                width="5.5"
                height="5.5"
                rx="1.25"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
            />
            <rect
                x="4.75"
                y="13.75"
                width="5.5"
                height="5.5"
                rx="1.25"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
            />
            <rect
                x="13.75"
                y="4.75"
                width="5.5"
                height="5.5"
                rx="1.25"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5 13.875C17.5 13.3918 16.9832 13 16.5 13C16.0168 13 15.5 13.3918 15.5 13.875V15.5H13.875C13.3918 15.5 13 16.0168 13 16.5C13 16.9832 13.3918 17.5 13.875 17.5H15.5V19.125C15.5 19.6082 16.0168 20 16.5 20C16.9832 20 17.5 19.6082 17.5 19.125V17.5H19.125C19.5 17.5 20 16.9832 20 16.5C20 16.0168 19.6082 15.5 19.125 15.5H17.5V13.875Z"
                fill={getIconColor(active, customColor, customActiveColor)}
            />
        </svg>
    )
}
