import { PlusIcon } from '@octup/core/assets/icons'
import { Box, SvgIcon, Button } from '@octup/core/ui-kit'
import PageLayout from 'components/PageLayout/PageLayout'
import { CostModalContainer } from 'new_containers/components/__common__'
import { CostsTableContainer } from 'new_containers/components/CostManager'
import { CardExpendable } from 'shared/ui/Card/CardExpendable'
import { addCostButtonStyle, tableContainerStyle } from './CostManagerPage.styles'

export const CostManagerPage = ({ showCostModal, onToggleCostModal, onUpdateData }) => (
    <PageLayout
        customStyle={{ overflowY: 'hidden' }}
        showDatePicker={false}
        infoTooltip="Add/Modify costs by entering it manually or by uploading a file holding relevant cost information."
        sidePanel={
            <Button
                variant="secondary"
                sx={addCostButtonStyle}
                onClick={onToggleCostModal}
                startIcon={<SvgIcon component={PlusIcon} />}
            >
                Add New Cost
            </Button>
        }
    >
        <CardExpendable>
            <Box sx={tableContainerStyle}>
                <CostsTableContainer />
            </Box>
        </CardExpendable>
        <CostModalContainer
            showCostModal={showCostModal}
            onClose={onToggleCostModal}
            isEditState={showCostModal.isEditState}
            onSuccess={onUpdateData}
        />
    </PageLayout>
)
