import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { extractErrMsg } from '../../shared/utils/utils'
import { usersService } from './usersServices'
import { GLOBAL_CONFIG } from '../../config'
import { usersActions } from './usersSlice'

const API_URL = GLOBAL_CONFIG.DAL_BASE_URL + 'user'
const INITIAL_STATE = {
    isLoading: false,
    isRejected: false,
}
export const deleteUser = createAsyncThunk(`${API_URL}/userId`, async (data, thunkAPI) => {
    try {
        const token = thunkAPI?.getState()?.auth?.userInfo?.token
        const response = await usersService.deleteUserById({ userId: data.userId, token })
        if (response.status === 200 || response.status === 204)
            thunkAPI.dispatch(usersActions.deleteUser(data.userId))
        return response
    } catch (error) {
        console.error(error)
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

export const deleteUserSlice = createSlice({
    name: 'deleteUser',
    initialState: INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(deleteUser.pending, (state) => {
                state.isLoading = true
                state.isRejected = false
            })
            .addCase(deleteUser.rejected, (state, action) => {
                const reqArgs = action.meta.arg
                state.isLoading = false
                state.isRejected = true
                reqArgs?.toasts?.error(action.payload)
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                const reqArgs = action.meta.arg
                state.isLoading = false
                state.isRejected = false
                reqArgs?.toasts?.success('Member Deleted Successfully')
            })
    },
})
export const deleteUserSliceReducer = deleteUserSlice.reducer
