import { INTEGRATION_TITLES_MAP } from 'constants'
import { APP_ROUTES } from 'models/appConfig'

const SIGN_CODES = {
    HEAVY_DIVISION: '\u2797', // ➗
}

export const DICTIONARY_SECTIONS = {
    TITLES: 'titles',
    METRICS_TITLES: 'metricsTitles',
    SUBTITLES: 'subtitles',
    MESSAGES: 'messages',
    TOOLTIPS: 'tooltips',
    INTEGRATIONS: 'integrations',
    TOOLTIPS_METRICS: ['tooltips']['metrics'],
    TOOLTIPS_UE_METRICS: 'unit_economics_metrics',
    ERRORS: 'error',
}

export const octupDictionary = {
    pages: {
        // TODO: rewrite PageTitle to support APP_ROUTES everywhere
        Marketing: 'Marketing Overview',
        Attribution: 'Octup Attribution Deep Dive',
        Warehousing: 'Warehousing',
        Insights: 'Insights',
        Objectives: 'Objectives',
        Integrations: 'Integrations',
        Account: 'Manage Account',
        Users: 'User Management',
        UnitEconomics: 'Unit Economics',
        CostManager: 'Cost Manager',
        [APP_ROUTES.OPERATIONS]: 'Operations',
        [APP_ROUTES.OPERATIONS_OVERVIEW]: 'Fulfillment SLA',
        [APP_ROUTES.WAREHOUSE]: 'Warehouse',
        [APP_ROUTES.CLIENTS]: 'Clients',
        [APP_ROUTES.CLIENTS_LOGISTICS]: 'Clients Logistics',
        [APP_ROUTES.CLIENTS_SLA]: 'Clients SLA',
        [APP_ROUTES.DASHBOARDS]: 'My Boards',
    },
    titles: {
        home: 'Home',
        operations: 'Operations Overview',
        marketing: 'Marketing Overview',
        operations_home: 'Operations',
        marketing_home: 'Marketing',
        uniteconomics: 'Unit Economics',
        customers: 'Customers',
        reports: 'Reports',
        insights: 'Insights',
        insightsRecived: 'Insights Received',
        insightsToObjectives: 'Turned into Objectives',
        objectives: 'Objectives',
        automationstudio: 'Automation Studio',
        gizmos: 'Octup Gizmos',
        settings: 'Settings',
        integrations: 'Integrations',
        'help&support': 'Help & Support',
        marketing1: 'Marketing 1',
        marketing2: 'Marketing 2',
        planner: 'Planner',
        simulator: 'Simulator',
        login: 'Log in to your account',
        forgetPassword: "Forgot your password? Don't worry!",
        resetPassword: 'Reset your password',
        resetPasswordDone: 'Password has been reset',
        roas: 'ROAS',
        gmb: 'My Good Morning Board',
        customer_success: 'Customer Success',
        fulfilled_orders_SLA_status: 'Fulfilled Orders SLA Status',
        ad_spend_by_channel: 'Ad Spend By Channel',
        operations_overview: 'Operations Overview',
        yesterdays_stats: 'Yesterday’s stats',
        total_ad_spend: 'Total Ad Spend',
        metricsTitles: {
            amazon_sales: 'Sales Amazon',
            amazon_orders: 'Orders Amazon',
            order_number_net: 'Net Number of Orders',
            order_number_gross: 'Orders',
            order_number_gross_shopify: 'Orders Shopify',
            orders_to_fulfill: 'Orders to fulfill',
            orders_to_fulfill_GMB: 'Orders to fulfill',
            order_number_gross_GMB: 'Orders',
            google_sessions_GMB: 'Total number of sessions',
            sales_currency_total: 'Sales',
            // sales_currency_total: "Total sales today",
            discount_currency_total: 'Total Discount',
            average_order_value_usd: 'Average Order Value',
            returns_percentage: 'Returns',
            metric_avg_order_value: 'AOV',
            metric_sold: 'Number of Sold Items',
            items_sold: 'Number of Sold Items',
            metric_sales: 'Number of Sales',
            metric_order_numbers: 'Metric Order',
            metric_discount_total: 'Total Discount',
            metric_order_numbers_net: 'Net Number of Orders',
            metric_order_numbers_gross: 'Gross Number of Orders',
            gross_profit: 'Gross Profit',
            under_2_days_fulfillment_sla: 'Under 2 Days',
            '2-3_days_fulfillment_sla': '2-3 Days',
            over_3_days_fulfillment_sla: 'Over 3 Days',
            cogs_gross: 'COGS',
            number_of_returns: 'Number of Returns',
            fulfillment_sla: 'Average Fulfillment SLA',
            fulfilment_sla: 'Average Fulfillment SLA',
            delivery_sla: 'Delivery SLA',
            avg_number_items_per_order: 'Average Items Per Order',
            returning_customers_percentage: 'Returning Customers',
            returning_customers_aov: 'Returning customer AOV',
            marketing_cost_per_ret_customer: 'Marketing cost for a returning customer',
            facebook_ad_spend: 'Facebook Ad Spend',
            google_ad_spend: 'Google Ad Spend',
            total_ad_spend: 'Total Ad Spend',
            facebook_ad_spend_mkt: 'Facebook',
            google_ad_spend_mkt: 'Google Ad',
            avg_shipment_cost: 'Average Shipment Cost',
            avg_logistic_cost: 'Average Logistic Cost',
            survey_answer_percentage: 'Survey Answer',
            satisfaction_survey_avg_score: 'Satisfaction Survey Average Score',
            number_of_delayed_orders: 'Number of Delayed Orders',
            new_customers_percentage: 'New Customers',
            sales: 'Gross Sales',
            sales_GMB: 'Total gross sales today',
            roas: 'Blended ROAS',
            roas_facebook: 'ROAS Facebook',
            roas_new_customers: 'ROAS New Customers',
            roas_returning_customers: 'ROAS Returning Customers',
            cpa: 'CPA',
            net_profit: 'Net Profit',
            logistics_cost: 'Logistics Costs',
            roas_google: 'ROAS Google',
            net_sales: 'Net Sales',
            cogs_net: 'COGS Net',
            marketing_avg_conversion_value: 'Marketing Average Conversion',
            marketing_cost_return_customer: 'Marketing cost per returning customer',
            average_gross_order_value_usd: 'AOV',
            subscribe_to_mail: 'Email Subscribers',
            unsubscribe_from_mail: 'Email unsubscribes',
            email_open_percentage: 'Email Open Rate',
            email_click_percentage: 'Email Click Rate',
            max_order_value: 'Maximum Order Value',
            storage_cost: 'Storage Cost',
            google_conversion: 'Google Conversions',
            facebook_conversion: 'Facebook Conversions',
            total_conversion_ppc_income: 'Income from PPC sources',
            refunds_shopify: 'Total Refunds',
            organic_sales: 'Organic Sales',
            total_sales_shopify: 'Total Sales Shopify',
            min_order_value: 'Order minimum value',
            total_taxes: 'total_taxes',
            roas_ppc: 'ROAS on ppc marketing',
            marketing_cost_ret_customer: 'Retention Cost',
            returning_customers_from_marketing: '# returning customers from marketing ',
            pct_returning_customers_marketing: '% of returning customers from marketing',
            order_number_value_above_0: 'Orders with value above 0',
            marketing_sales_value: 'Marketing Sales',
            new_customers_sales: 'New Customers total sales',
            new_customer_orders_pct: '% New Customer',
            returning_customer_orders_pct: 'Returning Customers (%)',
            avg_marketing_cost: 'AVG Marketing cost per Item',
            returning_customers_sales: 'Retention income',
            coming_soon: '',
            organic_avg_conversion_value: 'Organic Avg Conversion value',
            gorgias_opened_tickets_cs: 'Opened Tickets',
            gorgias_open_tickets_cs: 'Open Tickets',
            gorgias_closed_tickets_cs: 'Closed Tickets',
            gorgias_avg_tickets_per_agent_cs: 'Avg. Daily Tickets per Agent',
            gorgias_avg_response_rate_cs: 'Avg. Response Rate (Hours)',
            tickets_created_cs: 'Tickets Created',
            tickets_closed_cs: 'Tickets Closed',
            tickets_replied_cs: 'Tickets Replied',
            marketing_efficiency: 'Marketing Efficiency',
            marketing_order_gross: 'Marketing orders',
            fulfilled_order_num: 'Fulfilled Orders',
            poas: 'POAS',
            new_customers_avg_order_value: 'New Customers AVG order value',
            delayed_orders_pct: '% of delayed orders',
            delayed_orders_value: 'Delayed orders value',
            gorgias_avg_tickets_per_agent: 'Avg tickets per agent',
            gorgias_avg_response_rate: 'Average ticket response time',
            pick_pack_avg_cost_per_item: 'Average Pick&Pack cost',
            google_sessions: 'Total number of sessions',
            shopify_facebook_conversion: 'Sales Attributed to Facebook',
            shopify_klaviyo_conversion: 'Sales Attributed to Klaviyo',
            shopify_google_conversion: 'Sales Attributed to Google',
            google_users: 'Total unique visits',
            google_page_views: 'Total page views',
            google_session_duration: 'Average session duration',
            google_conversion_ratio: 'Google conversion ratio',
            facebook_conversion_ratio: 'Facebook conversion ratio',
            conversion_rate: 'Site conversion rate',
            opened_tickets_ratio: 'Opened Tickets ratio',
            marketing_min_conversion_value: 'Marketing minimum order value',
            marketing_max_conversion_value: 'Marketing maximum order value',
            email_marketing_avg_conversion: 'Klaviyo Avg Conversion value',
            facebook_orders: 'Number of Facebook orders',
            google_orders: 'Number of Google orders',
            klaviyo_orders: 'Number of Klaviyo orders',
            cogs_pct_out_of_sales: 'COGS % out of total sales',
            pct_of_orders_with_value: '% of order with a value',
            shopify_refunds_order_date: 'Order date Sum of refunds',
            number_of_returns_order_date: 'Order date number of refunds',
            returns_percentage_order_date: '% Order date refunds ',
            num_delayed_orders_to_delivery: 'Number of delayed orders',
            num_stuck_orders: 'Number of stuck orders',
            tickets_created: 'Number of tickets created',
            tickets_closed: 'Number of tickets closed',
            tickets_replied: 'Number of tickets replied',
            no_metric: 'Missing metric',
            shopify_discount_pct: 'Average discount % Shopify',
            avg_product_review_score: 'Average product review score',
            number_of_reviews: 'Number of order reviews',
            reviews_pct_out_of_orders: '% of orders with reviews',
            avg_time_for_review_submission: 'Average time for review submission',
            pct_of_verified_reviews: 'The % of verified reviews',
            amazon_aov: 'Amazon AOV',
            shopify_aov: 'Shopify AOV',
            gross_sales_shopify: 'Sales Shopify',
            number_of_orders_to_fulfill: 'Orders to fulfill',
            amazon_avg_number_of_items: 'Amazon average Items Per Order',
            other: 'Other Costs',
            orders: 'Orders',
            avg_daily_cost: 'Avg. daily cost',
            daily_order_cost: 'Total daily cost',
            shopify_items_sold: 'Number of items sold Shopify',
            shopify_avg_number_items_per_order: 'Average Items Per Order Shopify',
            amazon_items_sold: 'Number of items sold Amazon',
        },
        insightsSegments: {
            LAST_MILE: 'Last Mile',
        },
        attribution: {
            page: 'Octup Attribution Deep Dive',
            dataMissing: 'Attribution data missing',
            connectData: 'Connect Attribution Data',
            connectionRequest: 'Send a connection request',
            dataIsNotTracked: 'Your marketing attributed data is not tracked',
            lastClick: 'Last Click',
        },
        logistics: {
            cost: 'Cost',
            sales: 'Sales',
            noData: 'No data for the selected time range',
            noWarehouses: 'There are no warehouses just yet.',
            warehouseSetupRequest: 'Contact us for warehouse setup',
        },
    },
    subtitles: {
        actionable: 'Actionable',
        informative: 'Informative',
        insightsToObjectives: 'Insights turned to Objectives',
        objective_created: 'Objective Created',
        insights_received: 'Insights Received',
        attribution: {
            dataIsNotTracked:
                'Enabling this feature requires our assistance at the moment, click the button below and we’ll contact you ASAP.',
        },
    },
    messages: {
        noData: 'No data available',
        loadingData: 'Loading your dataset... please hold on',
        invalidEmail: 'Please enter a valid email address',
        emptyFeedback: "What's the point in feedback, without the feedback? :-)",
        accountExist: 'Already have an account? ',
        noAccount: "Don't have an account yet? ",
        noAccountMobile: 'At the moment, sign up is only available \nthrough our desktop site ',
        signupConfirmation:
            "We want your account and data to be as secure as possible,\nso we've just sent a verification code to your email.",
        enterShopifyShopName:
            'Allow octup to pull data related to your orders, customer,\nproduct, cart and much more. Without it, you won’t be able\nto get the most out of octup.',
        forgetPassword:
            "Enter the email address used to sign up for your octup account,\nwe'll send you a link to reset your password.",
        forgotPasswordMobile:
            'Enter the email address used to sign up for your octup account, we’ll send you a link to reset your password.\n',
        forgotPasswordMobileEmailSent:
            'Check your email and open\n' + 'the link we sent to continue.',
        newPasswordMobile: 'Please select a new password for your account\n',
        sentEmailMobile: 'Check your email and open\n' + 'the link we sent to continue.',
        invalidSession: {
            title: 'Oops... Your session has expired',
            text: "Your session has expired since you weren't active for some time. Nothing to worry about, simply login again.",
        },
        resetPassword: 'Please select a new password for your account',
        installOcTrackPt1: 'We’ll track and dive into your data to ',
        installOcTrackPt1Bold: 'better understand ',
        installOcTrackPt2:
            'your customers and store performance. \nCatch first party data on your orders, sales, product, customer journey and much more.',
        installOcTrackPt3:
            'Bottom line, we give you a more accurate data to make better decisions.',
        logistics: {
            preview: `Here's a preview of what you receive once your warehouses are all set up:`,
            noData: `Sorry, but we couldn't locate any data for the time period you selected, \ntry again with different dates.`,
            warehouseSetup: `Setting up your warehouses is like putting a pair of X-ray glasses for cost management. \nIt lets you break down expenses and compare the details between different storage spaces.`,
        },
    },
    tooltips: {
        amazon_sales: 'Total sales on Amazon',
        amazon_orders: 'Total number of orders on Amazon',

        // TODO: check where we use the tooltips below and move them into the metrics section
        gross_sales_shopify:
            'The total gross $ dollar sum of sales. (gross sales ➕ shipping cost ➕ tax ➖ discounts)',
        avg_product_review_score: 'The Average product review on product review platform',
        tickets_replied: 'The sum of all replied tickets',
        shopify_discount_pct: 'Total Discounts ➗ Total Gross Sales',
        //

        gmb: 'Good Morning Board',
        refunds_shopify: 'Sum of all refunds on the store platform',
        header_user_menu: 'Your Profile',
        header_notifications: 'Whats new?',
        header_storename: 'Store name',
        sidebar_collapse: 'Collapse Sidebar',
        sidebar_lock: 'Lock Sidebar',
        home_metricscard_operations: 'Go To Operations Page',
        home_metricscard_marketing: 'Go To Marketing Page',
        home_metricscard_insights: 'Go To Insights Page',
        home_metricscard_objectives: 'Go To Objectives Page',
        home_metricscard_stats: 'Open Good Morning Board',
        uniteconomics_addcost: 'Add New Cost',
        uniteconomics_button_simulator: "Go To this product's simulator",
        uniteconomics_button_edit: 'Edit Cost',
        total_conversion_ppc_income: 'Income from PPC sources',
        metrics: {
            marketing_cost_return_customer: 'Marketing cost per returning customer',
            order_number_gross: 'All orders',
            order_number_net: 'All orders minus refunds, cancels and returns.',
            sales: 'The total gross $ dollar sum of sales. (gross sales ➕ shipping cost ➕ tax ➖ discounts)',
            discount_currency_total: 'The $ dollar sum of discounts given.',
            average_gross_order_value_usd: 'Gross average order value (AOV)',
            average_order_value_usd: 'Gross average order value (AOV)',
            metric_avg_order_value: 'Gross average order value (AOV)',
            returns_percentage: 'Sum refunds ➗ Sum total sales.',
            avg_number_items_per_order: 'Average number of items in each order.',
            cogs_gross: 'cost per item ✖️ item sold. (based on Shopifys data)',
            returning_customers_percentage:
                'Number of returning customer (customers with previews orders) ➗ Number of total customers',
            number_of_returns: 'Number of orders returned based on order date.',
            gross_profit:
                'Gross Sales minus cost (cost per item ✖️ item sold); (based on Shopifys data)',
            subscribe_to_mail: 'Number of customers that subscribed to emails.',
            unsubscribe_from_mail: 'Number of customers that unsubscribed from emails.',
            email_open_percentage: 'open email ➗ email sent. (Calculated based on event time)',
            email_click_percentage: 'clicked email ➗ open email. (Calculated based on event time)',
            items_sold: 'The sum number of all items sold.',
            max_order_value: 'Maximum order value',
            marketing_avg_conversion_value:
                'Average order value (conversion) attributed to marketing.',
            new_customers_percentage:
                'Number of new customer (customers with no previews order)  ➗ Number of total customers',
            roas: 'Sum of Sales gross➗Marketing spend; only from direct marketing sources (google, Facebook etc.)',
            profit_on_ad_spent: 'The sum of sales attributed to direct marketing',
            cpa: 'total ad spend ➗ the number of orders with a cost (price > 0).',
            total_ad_spend: 'Sum of all direct ad spend',
            pick_pack_avg_cost_per_item: 'The sum of picking and packing cost ➗ number of orders',
            avg_shipment_cost: 'sum of shipment cost ➗ number of orders',
            avg_logistic_cost: 'sum all logistic cost ➗ number of orders',
            facebook_ad_spent: 'Sum of all Facebook ad spend',
            google_ad_spend: 'Sum of all google ad spend',
            google_conversion: 'Sum of all the conversions attributed to google',
            fulfilment_sla: 'The average time it takes an order to be fulfilled (restive a tag)',
            fulfillment_sla: 'The average time it takes an order to be fulfilled (restive a tag)',
            delivery_sla: 'The average time it takes an order to be delivered.',
            under_2_days_fulfillment_sla: 'Number of orders that were fulfilled under 2 days.',
            '2-3_days_fulfillment_sla': 'Number of orders that were fulfilled within 2-3 days.',
            over_3_days_fulfillment_sla: 'Number of orders that were fulfilled over 3 days.',
            satisfaction_survey_avg_score: 'The average score of customers satisfaction survey',
            survey_answer_percentage:
                'The percentage of customers that fill the satisfaction survey.',
            number_of_delayed_orders:
                'Number of orders that did not meet the delivery SLA promised to the customer.',
            facebook_conversion: 'Sum of all the conversions attributed to Facebook',
            logistics_cost: 'The sum of all logistics cost',
            net_sales: 'The total $ dollar sum of sales without cancelled, refunds and returns.',
            roas_facebook: 'Facebook Marketing conversion ➗ Facebook ad spend',
            roas_google: 'Google Marketing conversion ➗ Google ad spend',
            new_customers_from_marketing:
                'The number of new customer attributed to direct marketing.',
            gorgias_open_tickets: 'Sum of all open tickets by Gorgias',
            gorgias_closed_tickets: 'Sum of all closed tickets by Gorgias',
            gorgias_total_tickets: 'Sum of all tickets by Gorgias',
            refunds_shopify: 'Sum of all refunds on the store platform',
            shopify_gross_profit_usability: 'Sum of net sale per order on the store platform',
            total_sales_shopify:
                'The total gross $ dollar sum of sales. (line items total price ➕ shipping cost ➕ tax ➖ refunds ➖ discounts)',
            net_profit: 'Net sales  ➖ ( COGS ➕ Total ad spend ➕ Logistic cost)',
            total_conversion_ppc_income: 'Sum of all conversions based on ppc attribution sources',
            organic_sales: 'Sum of all sales that are not attributed to marketing',
            min_order_value: 'The minimum order value in set time frame',
            total_taxes: 'Sum of all taxes paid',
            roas_ppc: 'Sum of all PPC conversion ➗ sum marketing spend',
            marketing_cost_ret_customer:
                'The Sum of marketing spent on returning customers. (total ad spend X % of returning customers from marketing)',
            returning_customers_from_marketing:
                'The number of returning customers attributed to marketing',
            pct_returning_customers_marketing:
                'The percent of returning customers that were attributed to marketing',
            order_number_value_above_0:
                'The number of all order with a value above 0 after discount',
            marketing_sales_value: 'The Sum of sales marked as marketing by Shopify',
            new_customers_sales: 'The Sum of sales for first time customers',
            returning_customer_orders_pct: 'The percent of orders from returning customers.',
            new_customer_orders_pct: 'The percent of orders from new customers.',
            avg_marketing_cost:
                'Average cost of marketing for an item. (Sum of all direct ad spend➗number of all items sold)',
            returning_customers_sales:
                'The Sum of sales for returning customers, also considered the retention income.',
            marketing_efficiency: 'Total ad spend➗Total sales',
            gorgias_opened_tickets_cs: 'Sum of all tickets opened on Gorgias',
            gorgias_open_tickets_cs: 'Sum of all open tickets by Gorgias',
            gorgias_closed_tickets_cs: 'Sum of all closed tickets on Gorgias',
            tickets_created_cs: 'Tickets Created',
            tickets_replied_cs: 'Tickets Replied',
            tickets_closed_cs: 'Tickets Closed',
            marketing_order_gross: 'The number of orders attributed to marketing by Shopify',
            fulfilled_order_num: 'The number of orders that have been fulfilled/delivered. ',
            poas: 'Profit on add spend (Gross profit ➗ add spend)',
            new_customers_avg_order_value:
                'The Average value of all orders of first time customers',
            delayed_orders_pct: 'The Percent of delayed orders out of all orders.',
            delayed_orders_value: 'The sum of all delayed orders.',
            gorgias_avg_tickets_per_agent:
                'The average number of tickets that were handled by an agent in a day.',
            gorgias_avg_response_rate: 'The average time it takes an agent to respond to a ticket',
            pick_pack_avg_c_item: 'The average cost of picking and packing for an order.',
            orders_to_fulfill: 'The number of orders with out fulfilment data from Shopify',
            google_sessions:
                'The total number of sessions in google analytics for all linked sites and urls.',
            shopify_facebook_conversion: 'The sum of all sales attributed to Facebook on Shopify',
            shopify_klaviyo_conversion: 'The sum of all sales attributed to Klaviyo on Shopify',
            shopify_google_conversion: 'The sum of all sales attributed to Google on Shopify',
            google_users:
                'The total number of unique users in google analytics for all linked sites and urls.',
            google_page_views:
                'The total number of page views in google analytics for all linked sites urls.',
            google_session_duration:
                'The average session duration in google analytics for all linked urls.',
            google_conversion_ratio: 'Shopify attributed sales to google ➗ google conversion',
            facebook_conversion_ratio:
                'Shopify attributed sales to facebook ➗ facebook conversion',
            conversion_rate: 'Total Orders ➗ Total Sessions',
            opened_tickets_ratio: 'Opened Tickets ➗ Closed Tickets',
            marketing_min_conversion_value: 'The minimum order value attributed to marketing',
            marketing_max_conversion_value: 'The maximum order value attributed to marketing',
            roas_new_customers: 'New Customers sales ➗ New customers marketing cost',
            roas_returning_customers:
                'Returning Customers sales ➗ Returning customers marketing cost',
            num_stuck_orders:
                'The number of orders that are stuck in the warehouse without fulfillment past expected delivery date.',
            tickets_created: 'The sum of all created tickets',
            tickets_closed: 'The sum of all closed tickets',
            amazon_aov: `Amazon Sales ${SIGN_CODES.HEAVY_DIVISION} Amazon Orders`,
            shopify_aov: `Shopify Sales ${SIGN_CODES.HEAVY_DIVISION} Shopify Orders`,
            number_of_orders_to_fulfill:
                'The number of orders without fulfillment data from Shopify',
            amazon_avg_number_of_items: `Average number of items in each order on Amazon. (items ${SIGN_CODES.HEAVY_DIVISION} orders)`,
            num_delayed_orders_to_delivery: `The number of orders that are delayed in delivery to the customer`,
            shopify_items_sold: `The number of items sold on Shopify’s platform`,
            shopify_avg_number_items_per_order: `Average number of items in each order on Shopify (items sold ${SIGN_CODES.HEAVY_DIVISION} orders)`,
            amazon_items_sold: `Amazon The number of items sold on Amazon`,
        },
        attribution: {
            dataMissing:
                'Your marketing attributed data is not tracked. Enabling this feature requires our assistance, please send us a request to connect.',
            conversion_value_source: 'Total sales reported by source platform',
            conversion_value_octup: 'Total sales reported by Octup attribution platform',
            spend: 'Total amount spent on platform',
            sessions:
                'A continuous set of online activity where each visit is less than 180 minutes apart',
            added_to_cart: 'The count of times add to cart was used by the user',
            orders: 'Purchases by customers on Shopify/Octup. This number includes returns',
            attribution_purchase_count:
                'The number of purchases listed on Octups attribution modal',
            new_customer_count: 'Customers who have placed their first order',
            returning_customer_count: 'Customers who have placed repeat orders',
            ROAS_platform:
                'Return on Ad Spend reported by source platform. The amount of revenue earned for every dollar spent on this campaign',
            ROAS_attributed:
                'Return on Ad Spend calculated by Octup. The amount of revenue earned for every dollar spent on this campaign',
            AOV: `Average Order Value. Your sales relative to the number of orders placed. (Conversion value ${SIGN_CODES.HEAVY_DIVISION} Orders)`,
            CR: 'Conversion rate. The % of campaign click converted to purchases',
            lastClick:
                'The full sales value is attributed to the last source clicked within a 10-day window',
        },
        unit_economics: {
            price: 'Selling price as appears in store. Product price is the average of its variant prices.',
        },
    },
    unit_economics_metrics: {
        manufacturing_cost: 'Products manufacturing cost as listed on platform.',
        returns_cost: 'Sum cost of product returns on an item level.',
        taxes_cost: 'Taxes paid as part of the product sales.',
        marketing_cost: 'Average cost of marketing divided to a single item level.',
        pick_and_pack_cost: 'Cost of picking and packing per item.',
        shipping_cost: 'Average shipment cost per item',
        Marketing:
            'The average cost of marketing per unit, calculated by dividing total marketing expenses by the number of sold items (also called MCPU)',
        Manufacturing: 'The total expenses incurred in producing a unit.',
        'Transportation & Handling':
            'The expense associated with transporting goods and managing handling processes for each unit',
        Storage: 'The expense for storing items per unit, typically reported by the warehouse.',
        'Taxes / Fees': 'The average expense of taxes per unit.',
        'Pick & Pack':
            'The expense of the picking and packing items per unit, typically reported by the warehouse.',
        Shipping: 'The expense of shipping items per unit, typically reported by the warehouse.',
        Payment:
            'The additional expense for each unit related to payments, often associated with vendor clearing.',
        Returns: 'The expense for returning items per unit, typically reported by the warehouse.',
        Exchanges:
            'The expense for exchanging items per unit, typically reported by the warehouse.',
    },
    errors: {
        messages: {
            invalidEmailAddress: 'Hmmm... This is not a valid email address',
            'Email already exists': 'An account under this email already exists',
            emailNotFound: "We couldn't find an account under this email",
            weakPassword: "Password doesn't meet the requirements",
            passwordsDontMatch: "Passwords don't match",
            required: 'This is required',
            emailOtpDontMatch: 'Not the code we sent you... Please try again',
            invalidName: 'Must contain at least 2 characters, A-Z only',
        },
        tooltips: {
            weakPassword:
                'Minimum 8 characters required, including uppercase, lowercase, numbers, and special characters. Please choose a stronger password',
            passwordsDontMatch: "Passwords don't match",
        },
    },
    integrations: INTEGRATION_TITLES_MAP,
}

export const getDictionaryValue = (key, section) => {
    if (section && section === DICTIONARY_SECTIONS.TOOLTIPS_METRICS)
        return octupDictionary.TOOLTIPS_METRICS[`${key}`] || key
    else if (section && section === DICTIONARY_SECTIONS.METRICS_TITLES)
        return octupDictionary[`${DICTIONARY_SECTIONS.TITLES}`][`${section}`][`${key}`] || key
    else return octupDictionary[`${section}`]?.[`${key}`] || key
}
