import { useMemo } from 'react'
import { generateNumberArray } from '@octup/core/utils'
import { OctupBreathing } from 'shared/ui/Breathing/OctupBreathing'

export const LoadableContent = ({ isLoading, itemsCount = 1, children, ...props }) => {
    const items = useMemo(() => generateNumberArray(itemsCount), [itemsCount])

    if (!isLoading) return children

    return items.map((_, index) => <OctupBreathing key={index} {...props} />)
}
