import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getWarehouseCountries } from 'services'

const INITIAL_STATE = {
    data: null,
    isLoading: false,
    error: null,
}

export const fetchWarehouseCountries = createAsyncThunk(
    'fetchWarehouseCountries',
    async (data, thunkAPI) => getWarehouseCountries(data, thunkAPI)
)

export const warehouseCountriesSlice = createSlice({
    name: 'warehouseCost',
    initialState: INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(fetchWarehouseCountries.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchWarehouseCountries.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchWarehouseCountries.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const warehouseCountriesReducer = warehouseCountriesSlice.reducer
