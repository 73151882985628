import { Grid } from '@mui/material'
import { OctupLogo } from 'shared/assets/icons/OctupLogo'

export const OctupTableHeader = ({ title }) => (
    <Grid container alignItems="center" gap="0.5rem" wrap="nowrap" fontSize="inherit">
        <Grid item lineHeight={0}>
            <OctupLogo size="1.6rem" />
        </Grid>
        {title}
    </Grid>
)
