import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { extractErrMsg } from '../../shared/utils/utils'
import objectiveService from './objectiveService'
import { GLOBAL_CONFIG } from '../../config'

const API_URL = GLOBAL_CONFIG.BASE_URL + 'objectives'

export const KTV_SEGMENAT = {
    1: 'Last mile',
    2: 'Operation',
    3: 'Marketing',
    4: 'Manual',
    5: 'Unit Economics',
}

// export const KTV_ORIGIN = {
//   0: "insight",
//   1: "simulator",
//   2: "manual",
// };

// export const VTK_ORIGIN = {
//   "insight": 0,
//   "simulator": 1,
//   "manual": 2
// };

export const VTK_TYPE = {
    manual: 0,
    insight: 1,
    simulator: 2,
}

export const KTV_TYPE = {
    0: 'manual',
    1: 'insight',
    2: 'simulator',
}

export const VTK_SEGMENAT = {
    'Last Mile': 1,
    Operation: 2,
    Marketing: 3,
    Manual: 4,
    'Unit Economics': 5,
}

export const OBJECTIVE_STATUS_VTK = {
    inprogress: 0,
    completed: 1,
    overdue: 2,
    dismissed: 3,
}

export const OBJECTIVE_STATUS_KTV = {
    0: 'InProgress',
    1: 'Completed',
    2: 'Overdue',
    3: 'Dismissed',
}

export const OBJECTIVE_STATUS_KTV_STR = {
    inprogress: 'InProgress',
    completed: 'Completed',
    overdue: 'Overdue',
    dismissed: 'Dismissed',
}

const isValidDate = (date) => {
    let testVal = new Date(date)
    return Object.prototype.toString.call(testVal) === '[object Date]' && !isNaN(testVal)
}

const initialState = {
    objectives: [],
    objective: null,
    objectiveMetaData: {},
    users: {},
    isLoading: false,
    isRejected: false,
    updateObjectiveIsLoading: false,
    isCreateObjectiveLoading: false,
    isObjectivesUpdated: false,
}

//create Objective
export const createObjective = createAsyncThunk(`${API_URL}/create`, async (payload, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.userInfo.token
        return await objectiveService.createObjective(payload.data, token)
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

//get Objective
export const getObjective = createAsyncThunk(`${API_URL}/get`, async (objectiveId, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.userInfo.token
        return await objectiveService.getObjective(objectiveId, token)
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

//get objectives
export const getObjectives = createAsyncThunk(`${API_URL}/all`, async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.userInfo.token
        return await objectiveService.getObjectives(token)
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

//update Objective
export const updateObjective = createAsyncThunk(`${API_URL}/update`, async (payload, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.userInfo.token
        return await objectiveService.updateObjective(payload.data, token)
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

export const objectiveSlice = createSlice({
    name: 'objectives',
    initialState,
    reducers: {
        setSelectedObjective(state, action) {
            state.objective =
                action.payload !== null
                    ? state.objectives.find((el) => el.id === action.payload)
                    : null
        },
        addUsersData: (state, action) => {
            // adding users to application
            state.users = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getObjectives.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getObjectives.fulfilled, (state, action) => {
                state.objectiveMetaData = action?.payload?.objective_metadata
                const adjustedObjectives = action.payload?.objectives?.map((el) => {
                    const startDate =
                        el.start_date && isValidDate(el.start_date) ? el.start_date : null
                    const dueDate = el.due_date && isValidDate(el.due_date) ? el.due_date : null

                    return {
                        ...el,
                        id: el.id,
                        title: el.title,
                        subtitle: el.description,
                        actionableItem: {
                            message: el.title ? el.title : '', //TITLE
                            requiredAction: el.actions ? el.actions : '', //ACTIONS
                            summary: el.description ? el.description : '', //DESCRIPTION
                        },
                        status: el.status ? el.status : '-',
                        viewStatus: el.status.toLowerCase() === 'dismissed' ? 'deleted' : el.status,
                        segment: el.segment,
                        reportDate: el.createdAt ? el.createdAt : '',
                        startDate: startDate,
                        insight: el.insight,
                        dueDate: dueDate,
                        origin: el.type ? VTK_TYPE[el.type] : 'No Data',
                        owner: el.owner
                            ? el.owner
                            : {
                                  id: el.owner_user_id,
                                  fullName: 'Unknown',
                                  imageSrc: null,
                                  initials: '?',
                              },

                        creator: el.creator
                            ? el.creator
                            : {
                                  id: el.creator_user_id,
                                  fullName: 'Unknown',
                                  imageSrc: null,
                                  initials: '?',
                              },
                    }
                })
                state.objectives = adjustedObjectives || []
                state.isLoading = false
            })
            .addCase(getObjectives.rejected, (state) => {
                state.isRejected = true
                state.isLoading = false
            })
            .addCase(createObjective.pending, (state, action) => {
                const reqArgs = action.meta.arg
                state.isLoading = true
                state.isCreateObjectiveLoading = true
                state.isObjectivesUpdated = false
                const toast2 = reqArgs.toasts.process(`creating ${reqArgs.data.objective.title}`)
                action.meta.arg.processToast = toast2
            })
            .addCase(createObjective.fulfilled, (state, action) => {
                const reqArgs = action.meta.arg

                state.objectiveMetaData = action.payload?.objective_metadata
                state.objectives = action.payload?.objectives?.map((el, index) => {
                    const startDate =
                        el.start_date && isValidDate(el.start_date) ? new Date(el.start_date) : null
                    const dueDate =
                        el.due_date && isValidDate(el.due_date) ? new Date(el.due_date) : null
                    return {
                        ...el,
                        id: el.id,
                        title: el.title,
                        subtitle: el.description,
                        actionableItem: {
                            message: el.title ? el.title : '', //TITLE
                            requiredAction: el.actions ? el.actions : '', //ACTIONS
                            summary: el.description ? el.description : '', //DESCRIPTION
                        },
                        status: el.status ? el.status : '-',
                        viewStatus: el.status.toLowerCase() === 'dismissed' ? 'deleted' : el.status,
                        segment: el.segment,
                        reportDate: el.createdAt ? el.createdAt : '',
                        startDate: startDate,
                        insight: el.insight,
                        dueDate: dueDate,
                        origin: el.type ? VTK_TYPE[el.type] : 'No Data',
                        owner: el.owner
                            ? el.owner
                            : {
                                  id: el.owner_user_id,
                                  fullName: 'Unknown',
                                  imageSrc: null,
                                  initials: '?',
                              },

                        creator: el.creator
                            ? el.creator
                            : {
                                  id: el.creator_user_id,
                                  fullName: 'Unknown',
                                  imageSrc: null,
                                  initials: '?',
                              },
                    }
                })
                state.isLoading = false
                state.isCreateObjectiveLoading = false
                state.isObjectivesUpdated = true

                reqArgs?.toasts?.updateToast(reqArgs.processToast, {
                    text: `${reqArgs.data.objective.title} has been created successfully!`,
                    processCompleted: true,
                })
            })
            .addCase(createObjective.rejected, (state, action) => {
                const reqArgs = action.meta.arg
                state.isLoading = false
                state.isRejected = true
                state.isCreateObjectiveLoading = false
                state.isObjectivesUpdated = false

                reqArgs.toasts.error(`Unable to create "${reqArgs.data.objective.title}" objective`)
            })
            .addCase(updateObjective.pending, (state) => {
                state.updateObjectiveIsLoading = true
                state.isObjectivesUpdated = false
            })
            .addCase(updateObjective.fulfilled, (state, action) => {
                const reqArgs = action.meta.arg
                state.updateObjectiveIsLoading = false
                state.objectiveMetaData = action.payload?.objective_metadata
                state.objectives = action.payload?.objectives?.map((el, index) => {
                    const startDate =
                        el.start_date && isValidDate(el.start_date) ? new Date(el.start_date) : null
                    const dueDate =
                        el.due_date && isValidDate(el.due_date) ? new Date(el.due_date) : null
                    return {
                        ...el,
                        id: el.id,
                        title: el.title,
                        subtitle: el.description,
                        actionableItem: {
                            message: el.title ? el.title : '', //TITLE
                            requiredAction: el.actions ? el.actions : '', //ACTIONS
                            summary: el.description ? el.description : '', //DESCRIPTION
                        },
                        status: el.status ? el.status : '-',
                        viewStatus: el.status.toLowerCase() === 'dismissed' ? 'deleted' : el.status,
                        segment: el.segment,
                        reportDate: el.createdAt ? el.createdAt : '',
                        startDate: startDate,
                        insight: el.insight,
                        dueDate: dueDate,
                        origin: el.type ? VTK_TYPE[el.type] : 'No Data',
                        owner: el.owner
                            ? el.owner
                            : {
                                  id: el.owner_user_id,
                                  fullName: 'Unknown',
                                  imageSrc: null,
                                  initials: '?',
                              },

                        creator: el.creator
                            ? el.creator
                            : {
                                  id: el.creator_user_id,
                                  fullName: 'Unknown',
                                  imageSrc: null,
                                  initials: '?',
                              },
                    }
                })

                state.isLoading = false
                state.isObjectivesUpdated = true

                if (+reqArgs?.data?.objective?.status === OBJECTIVE_STATUS_VTK.dismissed)
                    reqArgs?.toasts?.delete(
                        `Objective "${reqArgs.data.objective.title}" has been deleted`
                    )
                else
                    reqArgs?.toasts?.success(
                        `Objective "${reqArgs.data.objective.title}" has been saved successfully`
                    )
            })
            .addCase(updateObjective.rejected, (state, action) => {
                const reqArgs = action.meta.arg
                state.updateObjectiveIsLoading = false
                state.isObjectivesUpdated = false
                state.isRejected = true
                if (+reqArgs?.data?.objective?.status === OBJECTIVE_STATUS_VTK.dismissed)
                    reqArgs?.toasts?.error(
                        `Objective "${reqArgs?.data?.objective?.title}" deletion has failed, please try again later`
                    )
                else
                    reqArgs?.toasts?.error(
                        `Objective "${reqArgs?.data?.objective?.title}" update has failed, please try again later`
                    )
            })
    },
})

export default objectiveSlice.reducer

export const objectivesActions = objectiveSlice.actions
export const objectivesState = (state) => state.objectives
export const objectivesSliceReducer = objectiveSlice.reducer
