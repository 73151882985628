import { useEffect } from 'react'
import { useAbilities } from '@/hooks'
import { APP_FEATURES } from '@/models/appConfig'
import { fetchAppSettings, fetchAppExcludedFeatures } from '@/state/features/appConfig'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { checkLinkValidity, intercom } from '@/utils'

const FALLBACK_FAVICON = './favicon.ico'

const changeFavicon = async (favicon: string) => {
    const isValidLink = await checkLinkValidity(favicon)
    const link = isValidLink ? favicon : FALLBACK_FAVICON
    const linkElement = document.querySelector("link[rel*='icon']") as HTMLLinkElement
    linkElement.href = link
}

export const useAppConfig = () => {
    const dispatch = useAppDispatch()
    const { settings } = useAppSelector((state) => state.appConfig)
    const { isFeatureAvailable } = useAbilities()

    useEffect(() => {
        dispatch(fetchAppSettings())
        dispatch(fetchAppExcludedFeatures())
    }, [dispatch])

    useEffect(() => {
        const favicon = settings.data.icons?.favicon

        if (favicon) {
            changeFavicon(favicon)
        }

        const isIntercomAvailable = isFeatureAvailable(APP_FEATURES.INTERCOM)
        isIntercomAvailable ? intercom.show() : intercom.hide()
    }, [settings.data.icons?.favicon, isFeatureAvailable])
}
