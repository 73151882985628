import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { OctupIcons } from '../../assets/OctupIcons'

const { CheckmarkSvg, LineCheckmarkSvg } = OctupIcons

const Checkbox = ({ isSelected = false, showSemiAll = false, name = '', label = '', id = '' }) => {
    useEffect(() => {
        setIsChecked(isSelected)
    }, [isSelected])

    const [isChecked, setIsChecked] = useState(isSelected ? isSelected : false)
    const handleChange = (e) => {
        setIsChecked(!isChecked)
    }
    return (
        <CheckboxWrapper>
            <label>
                <input
                    type="checkbox"
                    checked={isChecked}
                    name={name}
                    id={id}
                    onChange={handleChange}
                />
                <CheckboxContainer isChecked={isChecked} showSemiAll={showSemiAll}>
                    {isChecked ? <CheckmarkSvg /> : showSemiAll && <LineCheckmarkSvg />}
                </CheckboxContainer>
                {label}
            </label>
        </CheckboxWrapper>
    )
}

const CheckboxWrapper = styled.div`
    width: 100%;
    > label {
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;
        cursor: pointer;
        padding: 0 1rem;
    }

    > label > input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }
`

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 1.6rem;
    height: 1.6rem;
    background: ${(props) =>
        props.isChecked ? '#007F82' : props.showSemiAll ? '#7F7B87' : '#F6F5F9'};
    border: 0.1rem solid
        ${(props) =>
            props.isChecked ? '#007F82' : props.showSemiAll ? '#7F7B87' : 'rgba(52, 52, 52, 0.16)'};
    box-shadow: ${(props) =>
        props.isChecked
            ? 'inset -.2rem -.2rem .4rem rgba(80, 75, 90, 0.1), inset .2rem .2rem .4rem rgba(40, 40, 49, 0.32)'
            : props.showSemiAll
              ? 'inset -.2rem -.2rem .4rem rgba(80, 75, 90, 0.1), inset .2rem .2rem .4rem rgba(40, 40, 49, 0.32);'
              : 'inset -.2rem -.2rem .4rem #FFFFFF, inset .2rem .2rem .8rem rgba(80, 75, 90, 0.32);'};
    border-radius: 0.3rem;
    margin-right: 0.5rem;
    padding: 0.2rem;

    &:hover {
        opacity: 0.5;
    }
`

export default Checkbox
