import type { Components, Theme } from '@mui/material'

type DialogOverridesType = Pick<
    Components<Theme>,
    'MuiDialog' | 'MuiDialogTitle' | 'MuiDialogContent' | 'MuiDialogActions'
>

export const DIALOG_OVERRIDES: DialogOverridesType = {
    MuiDialog: {
        styleOverrides: {
            paper: ({ theme }) => ({
                boxShadow: theme.shadows[5],
                borderRadius: theme.spacing(4),

                '& > .MuiDialogContent-root:nth-of-type(1)': {
                    paddingTop: theme.spacing(8),
                },
            }),
        },
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: ({ theme }) => ({
                padding: theme.spacing(4),
            }),
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: ({ theme }) => ({
                padding: theme.spacing(4),
            }),
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: ({ theme }) => ({
                padding: theme.spacing(4),
                paddingTop: theme.spacing(2),
            }),
        },
    },
}
