import { useState, useCallback, useEffect } from 'react'
import type { MetricsSectionsCategoryType } from 'models/metrics'
import { CategoriesListItem } from 'new_components/Dashboards/DashboardForm/MetricsSectionsListPanel'
import { useAppSelector } from 'state/hooks'

type CategoriesListItemContainerPropsType = MetricsSectionsCategoryType

export const CategoriesListItemContainer = (props: CategoriesListItemContainerPropsType) => {
    const { metricsSectionsList } = useAppSelector((state) => state.newMetrics)
    const [isOpen, setIsOpen] = useState(false)
    const toggleOpen = useCallback(() => setIsOpen((prev) => !prev), [])

    useEffect(() => {
        setIsOpen(!!metricsSectionsList.searchValue)
    }, [metricsSectionsList.searchValue])

    return <CategoriesListItem isOpen={isOpen} onClick={toggleOpen} {...props} />
}
