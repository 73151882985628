import type { NavigateProps, RouteObject } from 'react-router-dom'

export enum DEFAULT_ROUTES {
    MAIN = '/',
    ALL = '/*',
}

export enum APP_ROUTES {
    LOGIN = 'Login',
    SIGN_UP = 'Signup',
    HOME = 'Home',
    MARKETING = 'Marketing',
    MARKETING_ATTRIBUTION = 'Marketing/Attribution',
    OPERATIONS = 'Operations',
    OPERATIONS_OVERVIEW = 'OperationsOverview',
    WAREHOUSING = 'Warehousing',
    UNIT_ECONOMICS = 'UnitEconomics',
    UNIT_ECONOMICS_COST_MANAGER = 'UnitEconomics/CostManager',
    INTEGRATIONS = 'Integrations',
    INSIGHTS = 'Insights',
    OBJECTIVES = 'Objectives',
    CLIENTS = 'Clients',
    DASHBOARDS = 'Dashboards',
    DASHBOARD = 'Dashboards/:dashboardId',
    DASHBOARD_EDIT = 'Dashboards/:dashboardId/edit',
    DASHBOARD_CREATE = 'Dashboards/:dashboardId/new',
    ACCOUNT = 'Account',
    USERS = 'Users',
    INVITE = 'Invite',
    TERMS_AND_CONDITIONS = 'TermsAndConditions',
    WAREHOUSE = 'Warehouse',
    SETTINGS = 'settings/:section?',
    SETTINGS_CLIENTS = 'settings/clients',
    SETTINGS_WORKING_HOURS = 'settings/working-hours',
    REGISTRATION = 'registration',
    SHOPIFY_REGISTRATION = 'registration/shopify',
    CLIENTS_LOGISTICS = 'clients-logistics',
    CLIENTS_SLA = 'clients-sla',
}

type ROUTES = DEFAULT_ROUTES | APP_ROUTES

export type NavigateRouteType = {
    element: (props: NavigateProps) => JSX.Element | null
    to: ROUTES
    isNavigate: true
    replace?: boolean
}

export type PageRouteType = {
    element: () => JSX.Element | null
}

export type AppRouteType = Omit<RouteObject, 'path' | 'element'> &
    (NavigateRouteType | PageRouteType) & {
        path: ROUTES
    }
