export function LastUpdatedIcon() {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.9545 7H7.04545C6.74421 7 6.5 7.22386 6.5 7.5V18.5C6.5 18.7761 6.74421 19 7.04545 19H17.9545C18.2558 19 18.5 18.7761 18.5 18.5V7.5C18.5 7.22386 18.2558 7 17.9545 7Z"
                stroke="#7F7B87"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.7727 5V8"
                stroke="#7F7B87"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.22729 5V8"
                stroke="#7F7B87"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5 10.0312V12.9844"
                stroke="#7F7B87"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.4881 16.9609C12.9023 16.9609 13.2381 16.6252 13.2381 16.2109C13.2381 15.7967 12.9023 15.4609 12.4881 15.4609C12.0739 15.4609 11.7381 15.7967 11.7381 16.2109C11.7381 16.6252 12.0739 16.9609 12.4881 16.9609Z"
                fill="#7F7B87"
            />
        </svg>
    )
}
