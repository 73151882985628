import styled from 'styled-components'

export const WhatsNewSlideStyled = styled.div`
    min-height: 100%;
    width: 100%;
    max-height: calc(100%);
    overflow-y: auto;
    flex: 3;
    flex-direction: column;
    padding: 4rem 2rem;
    display: flex;
    border-radius: 1.6rem;
    background: ${(props) => props.WhatNewContentBG};
    gap: 2rem;
    color: white;

    &.dark-text {
        color: rgba(40, 40, 49, 1);
    }
`
