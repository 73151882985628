import styled from 'styled-components'
import { CloseIconSvg } from '../../assets/icons/CloseIconSvg'
import { WarningIconSvg } from '../../assets/icons/WarningIconSvg'
import { CheckmarkSvg } from '../../assets/icons/CheckmarkSvg'
import { NotificationIcon } from '../../assets/icons/NotificationIcon'
import { TrashSvg } from '../../assets/icons/TrashSvg'
import { StarIconSvg } from '../../assets/icons/StarIconSvg'
import { useState, useEffect, useRef } from 'react'
import { LottieItem as Lottie } from '../../../components/Lottie/Lottie'
import successLottie from '../../assets/lotties/progress-to-done.json'
import { TOAST_TYPE as TOAST_TITLE } from '../../../features/toasts/toastsSlice'
import { useToastContext } from '../../../hooks/useToastContext'
import { useLocation } from 'react-router-dom'
import { useUpdateEffect } from 'hooks'

/*
// https://lottiereact.com/#onenterframe
*/

const TOAST_CLASS = {
    LEAVE: 'leave',
    ENTER: 'enter',
}

const TOAST_ANIMATION = {
    ROTATE: 'rotate',
}

export const TOAST_TYPES = {
    ERROR: {
        id: TOAST_TITLE.ERROR,
        color: '#FC4F6D',
        icon: WarningIconSvg,
        lottie: null,
        pauseOnHover: false,
        disableTimer: false,
        showProgressBar: false,
        defaultActionText: '',
        defaultTimer: 5000,
        background: '#282831',
    },
    SUCCESS: {
        id: TOAST_TITLE.SUCCESS,
        color: '#04CE72',
        icon: CheckmarkSvg,
        lottie: null,
        pauseOnHover: false,
        disableTimer: false,
        showProgressBar: false,
        defaultActionText: '',
        defaultTimer: 5000,
        background: '#282831',
    },
    INFO: {
        id: TOAST_TITLE.INFO,
        color: '#00A8A8',
        icon: NotificationIcon,
        lottie: null,
        pauseOnHover: false,
        disableTimer: false,
        showProgressBar: false,
        defaultActionText: '',
        defaultTimer: 5000,
        background: '#282831',
    },
    BASE: {
        id: TOAST_TITLE.BASE,
        color: '#F8F7FB',
        icon: WarningIconSvg,
        lottie: null,
        pauseOnHover: false,
        disableTimer: false,
        showProgressBar: false,
        defaultActionText: '',
        defaultTimer: 5000,
        background: '#282831',
    },
    UNICORN: {
        id: TOAST_TITLE.UNICORN,
        color: '#F8F7FB',
        icon: StarIconSvg,
        lottie: null,
        pauseOnHover: false,
        disableTimer: false,
        showProgressBar: false,
        defaultActionText: 'More',
        defaultTimer: 5000,
        background: 'linear-gradient(225.61deg, #FF3489 -2.19%, #7737FF 101.11%)',
    },
    PROMPT: {
        id: TOAST_TITLE.PROMPT,
        color: '#F8F7FB',
        icon: undefined,
        lottie: null,
        pauseOnHover: false,
        disableTimer: true,
        showProgressBar: false,
        defaultActionText: '',
        defaultTimer: 5000,
        background: '#282831',
    },
    PROCESS: {
        id: TOAST_TITLE.PROCESS,
        color: '#F8F7FB',
        icon: null,
        lottie: successLottie,
        pauseOnHover: false,
        disableTimer: true,
        showProgressBar: false,
        defaultActionText: '',
        defaultTimer: 5000,
        background: '#282831',
        processCompleted: false,
        lottieSegments: [0, 15],
        lottieOptions: {
            animationData: successLottie,
            speed: 0.01,
            loop: true,
            interactivity: {},
        },
    },
    DELETED: {
        id: TOAST_TITLE.DELETED,
        color: '#F8F7FB',
        icon: TrashSvg,
        lottie: null,
        pauseOnHover: true,
        disableTimer: false,
        showProgressBar: true,
        defaultActionText: '',
        defaultTimer: 2000,
        background: '#282831',
    },
    UNDO: {
        id: TOAST_TITLE.UNDO,
        color: '#F8F7FB',
        icon: TrashSvg,
        lottie: null,
        pauseOnHover: true,
        disableTimer: false,
        showProgressBar: true,
        defaultActionText: 'Undo',
        defaultTimer: 2000,
        background: '#282831',
    },
}

export const BaseOctupToast = ({
    type,
    customIcon = null,
    customButtons,
    customTimeout,
    customStyle,
    customDisableTimer,
    callback = () => {},
    defaultBtnClickHandler,
    text,
    id,
    actionText,
    processCompleted = false,
    pages = false,
}) => {
    const location = useLocation()
    const [activeClass, setActiveClass] = useState(TOAST_CLASS.ENTER)
    const [isPaused, setIsPaused] = useState(false)
    const [timeLeft, setTimeLeft] = useState(null)
    const [startTime, setStartTime] = useState(null)
    const [progressBarContainerWidth, setProgressBarContainerWidth] = useState()
    const [progressBarWidth, setProgressBarWidth] = useState(0)
    const [fraction, setFraction] = useState(0)
    const [isUndo, setIsUndo] = useState(false)
    const [progressBarContainerHeight, setProgressBarContainerHeight] = useState()
    const [isDone, setIsDone] = useState(processCompleted)
    const Icon = customIcon || TOAST_TYPES[type].icon
    const timeout = customTimeout || TOAST_TYPES[type].defaultTimer
    const disableTimer =
        customDisableTimer !== undefined ? customDisableTimer : TOAST_TYPES[type].disableTimer
    const containerRef = useRef()
    const { removeToast, setToast } = useToastContext()
    //@TO_DO : REMOVE TOASTS FROM LIST
    //@TO_DO : ADD UPDATE OPTION ON PROCESS END

    useEffect(() => {
        let interval
        if (!isPaused) {
            interval = setInterval(() => {
                setFraction((prevState) => prevState + 50)
            }, 50)
        } else {
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [isPaused])

    useEffect(() => {
        setIsDone(processCompleted)
    }, [processCompleted])

    useEffect(() => {
        if (
            !containerRef ||
            !containerRef.current ||
            !containerRef.current.offsetWidth ||
            fraction / timeout >= 1
        )
            return
        setProgressBarWidth((fraction / timeout) * containerRef.current.offsetWidth)
    }, [fraction])

    useEffect(() => {
        if (containerRef) {
            setProgressBarContainerWidth(containerRef.current.offsetWidth)
            setProgressBarContainerHeight(containerRef.current.clientHeight)
        }
    }, [containerRef])

    useEffect(() => {
        let t
        let t2
        if (!disableTimer || isDone) {
            if (isUndo) {
                setActiveClass(TOAST_CLASS.LEAVE)
                setTimeout(() => {
                    removeToast(id)
                }, 200)
                return
            }
            if (isPaused && TOAST_TYPES[type].pauseOnHover) {
                let time = timeout - (new Date().getTime() - startTime)
                setTimeLeft(time > 0 ? time : 0)
                clearTimeout(t)
                clearTimeout(t2)
            } else if (
                !isPaused &&
                (timeLeft || timeLeft === 0) &&
                TOAST_TYPES[type].pauseOnHover
            ) {
                t = setTimeout(() => {
                    setActiveClass(TOAST_CLASS.LEAVE)
                    t2 = setTimeout(() => {
                        !isUndo && callback !== null && callback()
                        removeToast(id)
                    }, 200)
                }, timeLeft)
            } else {
                setStartTime(new Date().getTime())
                t = setTimeout(() => {
                    setActiveClass(TOAST_CLASS.LEAVE)
                    !isUndo && callback !== null && callback()
                    t2 = setTimeout(() => {
                        removeToast(id)
                    }, 200)
                }, timeout)
            }
            return () => {
                if (t) clearTimeout(t)
                if (t2) clearTimeout(t2)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaused, isUndo, isDone])

    useUpdateEffect(() => {
        if (pages && !pages.includes(location.pathname)) {
            removeToast(id)
        }
    }, [location.pathname])

    return (
        <ToasterContainer
            onMouseEnter={() => {
                setToast(id)
                setIsPaused(true)
            }}
            onMouseLeave={() => {
                setToast(null)
                setIsPaused(false)
            }}
            className={activeClass}
            style={{
                color: TOAST_TYPES[type].color,
                background: TOAST_TYPES[type].background,
                ...customStyle,
            }}
            toastType={type}
            ref={containerRef}
        >
            {(customIcon || TOAST_TYPES[type].icon) && (
                <ToasterIcon className={type === TOAST_TITLE.PROCESS ? TOAST_ANIMATION.ROTATE : ''}>
                    <Icon
                        style={{ color: TOAST_TYPES[type].color }}
                        customColor={TOAST_TYPES[type].color}
                    />
                </ToasterIcon>
            )}
            {TOAST_TYPES[type].lottie && (
                <div
                    style={{
                        width: '2.3rem',
                        height: '2.3rem',
                    }}
                >
                    <Lottie
                        lottieSegments={TOAST_TYPES[type].lottieSegments}
                        options={TOAST_TYPES[type].lottieOptions}
                        isDone={isDone}
                    />
                </div>
            )}
            <ToasterText>{text}</ToasterText>
            {(actionText || TOAST_TYPES[type].defaultActionText) && (
                <ActionContainer
                    toastType={type}
                    onClick={() => {
                        TOAST_TYPES[type].id === TOAST_TYPES.UNDO.id && setIsUndo(true)
                        TOAST_TYPES[type].id !== TOAST_TYPES.UNDO.id && defaultBtnClickHandler()
                    }}
                >
                    {actionText || TOAST_TYPES[type].defaultActionText}
                </ActionContainer>
            )}
            {customButtons && customButtons}
            <CloseIconContainer
                onClick={() => {
                    callback && callback()
                    setActiveClass(TOAST_CLASS.LEAVE)
                    setTimeout(() => {
                        removeToast(id)
                    }, 240)
                }}
            >
                <CloseIconSvg customColor="#fff" />
            </CloseIconContainer>
            {false && (
                <ProgressBarContainer
                    width={progressBarContainerWidth}
                    height={progressBarContainerHeight}
                >
                    <ProgressBar width={progressBarWidth} />
                </ProgressBarContainer>
            )}
        </ToasterContainer>
    )
}

const ToasterContainer = styled.div`
    background: #282831;
    box-shadow: 0.8rem 0.8rem 2.4rem rgba(80, 75, 90, 0.08);
    border-radius: 0.8rem;
    padding: 1rem 1.2rem;
    font-family: 'Poppins';
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f8f7fb;
    width: fit-content;
    height: 4.5rem;
    gap: 1rem;
    animation-duration: 0.25s;
    animation-iteration-count: 1;
    position: relative;

    &.enter {
        animation-name: enter;
        animation-timing-function: linear;
    }

    &.leave {
        animation-name: leave;
        animation-timing-function: linear;
    }

    @keyframes enter {
        0% {
            transform: translateY(-4rem);
        }
        50% {
            transform: translateY(3rem);
        }
        75% {
            transform: translateY(1rem);
        }
        100% {
            transform: translateY(0);
        }
    }

    @keyframes leave {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(2rem);
        }
        75% {
            transform: translateY(-2rem);
        }
        100% {
            transform: translateY(-1000vh);
        }
    }
`

const ToasterIcon = styled.div`
    z-index: 3;

    &.rotate {
        display: flex;
        align-items: center;
        height: 5rem;
        animation: loading 1s linear infinite;
        @keyframes loading {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
`

const ToasterText = styled.div`
    font-weight: 600;
    letter-spacing: normal;
    font-size: 1.5rem;
    line-height: 2rem;
    width: 100%;
    white-space: nowrap;
    z-index: 3;
`

const CloseIconContainer = styled.div`
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    z-index: 3;
`

const ActionContainer = styled.button`
    color: #f8f7fb;
    font-weight: 400;
    letter-spacing: normal;
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;
    background: transparent;
    border: none;
    outline: none;
    margin-left: 1rem;
    /* text-decoration: underline; */
    z-index: 3;

    &:hover {
        cursor: pointer;
        color: ${(props) => (props.toastType === 'UNICORN' ? '#504b5a' : '#f8f7fb')};
        background: ${(props) => (props.toastType === 'UNICORN' ? '#f8f7fb' : '#504b5a')};
        opacity: ${(props) => (props.toastType === 'UNICORN' ? 0.5 : 1)};
        border-radius: 0.4rem;
    }
`

const ProgressBarContainer = styled.div`
    position: absolute;
    /* height: ${(props) => props.height + 'px'}; */
    height: 0.6rem;
    width: ${(props) => props.width + 'px'};
    bottom: 0;
    left: 0;
    z-index: 2;
    /* border-bottom-left-radius: 0.8rem;
border-bottom-right-radius: 0.8rem; */
    border-radius: 0.8rem;
`
const ProgressBar = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    //height:0.6rem;
    height: 100%;
    width: ${(props) => props.width + 'px'};
    // background:rgba(0,0,0,.9);
    //background: rgba(0, 0, 0, 1);
    background: #504b5a;
    /* border-bottom-left-radius: 0.8rem;
border-bottom-right-radius: 0.8rem; */
    border-radius: 0.8rem;
`
