export enum WEEKDAYS {
    MON = 'MON',
    TUE = 'TUE',
    WED = 'WED',
    THU = 'THU',
    FRI = 'FRI',
    SAT = 'SAT',
    SUN = 'SUN',
}

export enum WEEKDAYS_LABELS {
    MON = 'Monday',
    TUE = 'Tuesday',
    WED = 'Wednesday',
    THU = 'Thursday',
    FRI = 'Friday',
    SAT = 'Saturday',
    SUN = 'Sunday',
}

export type HoursMappingType = {
    day: WEEKDAYS
    cutoff: number | null
    time_frames: number[][]
}

export type WorkingHoursSettingsType = {
    first_day: WEEKDAYS
    hours_mapping: HoursMappingType[]
}

export type HoursMappingFormType = {
    day: { id: WEEKDAYS; label: WEEKDAYS_LABELS }
    cutoff: Date | null
    time_frames: Date[][]
    enabled: boolean
}

export type WorkingHoursSettingsFormType = {
    first_day: WEEKDAYS
    hours_mapping: HoursMappingFormType[]
}
