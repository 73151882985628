export const OctupColors = {
    primaryActionableLight: '#00A8A8',
    primaryActionableLightWithOpacity: '#38d6d6',
    primaryActionableDark: '#007F82',
    primaryTextLight: '#504B5A',
    loginbackground: '#504B5A',
    datepickerButtons: '#716587',
    primaryTextDark: '#282831',
    cardsBackground: '#F8F7FB',
    seperator: '#dddbe1',
    textOverDarkBg: '#A4A0AA',
    headerIcons: '#F8F7FB',
    tableRowExpanded: '#F1F0F5',
    onHover: '#EBEAEE',
    pageBackground: '#F6F5F7',
    accent1: '#7A00F3',
    accent2: '#FF3489',
    accent3: '#F9BD63',
    alertLight: '#db2059',
    alertDark: '#fc4f6d',
    redOnLight: '#DB2059',
    successLight: '#078344',
    successDark: '#0aaa59',
    chartsPositiveValue: '#04CE72',
    chartsNegativeValue: '#FC4F6D',
    sidebarNonActive: '#d9d9d9',
    inputLabels: '#7F7B87',
    transparent: 'transparent',
}
