import type { Components, Theme } from '@mui/material'

type PaperOverridesType = Pick<Components<Theme>, 'MuiPaper'>

export const PAPER_OVERRIDES: PaperOverridesType = {
    MuiPaper: {
        styleOverrides: {
            root: ({ theme }) => ({
                borderRadius: theme.spacing(1.6),
                boxShadow: theme.shadows[3],
            }),
        },
    },
}
