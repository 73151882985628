export const getHeaderTitle = (header) => header?.title || header

export const getHeaderField = ({ data, currentField }) =>
    currentField?.options
        ? currentField.options.find((option) => data?.[option] !== undefined)
        : currentField

export const flatData = ({ data, subRowField }) =>
    data.reduce((acc, item) => {
        const subData = item?.[subRowField]

        if (subData && !!subData?.length) {
            const flattenedData = flatData({ data: subData, subRowField })

            return [...acc, item, ...flattenedData]
        }

        return [...acc, item]
    }, [])

export const getNestedValue = (el, props) => {
    return props.reduce(
        (previousValue, currentValue, currentIndex) => previousValue[props[currentIndex]],
        el
    )
}

const isStrSearched = (str, searchValue) =>
    str.toString().trim().toLowerCase().includes(searchValue.toString().trim().toLowerCase())

export const filterRows = (rows = [], searchFields = [], searchValue, subRowField, getDataValue) =>
    rows.reduce((filtered, row) => {
        const subData = row?.[subRowField]

        const isRowSearched = searchFields.some((searchField) => {
            const fieldValue = Array.isArray(searchField)
                ? getNestedValue(row, searchField)
                : getDataValue?.({ row, field: searchField }) || row?.[searchField]

            return isStrSearched(`${fieldValue}`, searchValue)
        })

        const filteredSubRow = subData
            ? filterRows(subData, searchFields, searchValue, subRowField)
            : []

        const filteredRow = {
            ...row,
            [subRowField]: isRowSearched && !filteredSubRow.length ? subData : filteredSubRow,
        }

        if (isRowSearched || filteredRow[subRowField].length > 0) {
            filtered.push(filteredRow)
        }

        return filtered
    }, [])

const getElementValue = (element, dataMapping) => {
    const field = getHeaderField({ data: element, currentField: dataMapping })
    const value = Array.isArray(field) ? getNestedValue(element, field) : element[field]

    return typeof value === 'string' ? value.toLowerCase() : value
}

const isRowUnsorted = (data, unsortedRows) =>
    unsortedRows.some((row) => row.values.includes(data?.[row.field]))

const getRowsToSort = (rows, sortBy) =>
    rows.reduce(
        (acc, row) => {
            isRowUnsorted(row, sortBy.unsortedRows)
                ? acc.unsortedRows.push(row)
                : acc.remainingRows.push(row)

            return acc
        },
        { remainingRows: [], unsortedRows: [] }
    )

const getUnsortedValueIndex = (row, unsorted) => unsorted.values.indexOf(row[unsorted.field])

export const sortTableRows = ({ rows, sortBy, dataMapping, subRowField }) => {
    if (rows.length === 0) return rows

    const { remainingRows, unsortedRows } = getRowsToSort(rows, sortBy)

    return [
        ...remainingRows.sort((a, b) => {
            const aValue = getElementValue(a, dataMapping)
            const bValue = getElementValue(b, dataMapping)

            return aValue > bValue ? sortBy.direction : aValue === bValue ? 0 : -sortBy.direction
        }),
        ...unsortedRows.sort((a, b) =>
            sortBy.unsortedRows.reduce((result, unsorted) => {
                if (result !== 0) return result

                const aIndex = getUnsortedValueIndex(a, unsorted)
                const bIndex = getUnsortedValueIndex(b, unsorted)

                return aIndex - bIndex
            }, 0)
        ),
    ].map((row) => ({
        ...row,
        ...(row?.[subRowField] && {
            [subRowField]: sortTableRows({
                rows: row[subRowField],
                sortBy,
                dataMapping,
                subRowField,
            }),
        }),
    }))
}
