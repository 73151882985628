import { DARK_PALETTE, LIGHT_PALETTE } from '@octup/core/theme'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AppSettingsResponseType, AppSettingsType } from 'models/appConfig'
import { getAppSettings } from 'services/auth'
import type { ThunkAPIConfigType } from 'services/utils'

const OCTUP_COMPANY_NAME = 'octup'
const OCTUP_DISPLAY_NAME = 'Octup'

export const transformAppConfig = (data: AppSettingsResponseType): AppSettingsType => ({
    ...data,
    palette: data.palette_mode === 'dark' ? DARK_PALETTE : LIGHT_PALETTE,
    isDefault: data.company_name === OCTUP_COMPANY_NAME,
})

const DEFAULT_APP_CONFIG: AppSettingsResponseType = {
    company_name: OCTUP_COMPANY_NAME,
    display_name: OCTUP_DISPLAY_NAME,
    palette_mode: 'light',
    icons: {
        favicon: 'https://cdn.octup.com/companies/octup/favicon.ico',
        name_logo: 'https://cdn.octup.com/companies/octup/name-logo.svg',
    },
}

type AppConfigStateType = {
    data: AppSettingsType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: AppConfigStateType = {
    data: transformAppConfig(DEFAULT_APP_CONFIG),
    isLoading: true,
    error: undefined,
}

export const fetchAppSettings = createAsyncThunk<
    AppSettingsResponseType,
    undefined,
    ThunkAPIConfigType
>('fetchAppSettings', getAppSettings)

export const appSettingsSlice = createSlice({
    name: 'appConfig',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppSettings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchAppSettings.fulfilled, (state, action) => {
                state.data = transformAppConfig(action.payload)
                state.isLoading = false
            })
            .addCase(fetchAppSettings.rejected, (state, action) => {
                state.error = action.error.message
                state.data = transformAppConfig(DEFAULT_APP_CONFIG)
                state.isLoading = false
            })
    },
})

export const appSettingsReducer = appSettingsSlice.reducer
