import { Box, Stack, SvgIcon } from '@mui/material'
import { CloseIcon } from '@octup/core/assets/icons'
import type { MetricsSectionType } from '@octup/core/models'
import { OverflowTypography } from '@octup/core/ui-kit'
import { OctupTooltip } from '@/shared/ui/MUIWrappers/OctupTooltip'
import { containerStyle, deleteIconContainer } from './MetricsSectionDetails.styles'

type MetricsSectionDetailsPropsType = {
    data: MetricsSectionType
    onDelete: (event: React.MouseEvent<SVGElement>) => void
}

export const MetricsSectionDetails = ({ data, onDelete }: MetricsSectionDetailsPropsType) => (
    //  @ts-expect-error: OctupTooltip is a JavaScript file
    <OctupTooltip title={data.description} position="top">
        <Stack justifyContent="center" alignItems="center" sx={containerStyle}>
            <OverflowTypography
                variant="h6"
                maxHeight="100%"
                lineHeight={18}
                fontWeight={400}
                align="center"
            >
                {data.name}
            </OverflowTypography>
            <Box sx={deleteIconContainer}>
                <SvgIcon
                    data-testid="delete-icon-button"
                    component={CloseIcon}
                    onClick={onDelete}
                />
            </Box>
        </Stack>
    </OctupTooltip>
)
