// TODO: re-write App component
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from '@octup/core/ui-kit'
import axios from 'axios'
import { differenceInDays } from 'date-fns'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'
import Layout from '@/components/Layout/Layout'
import { OctupIntro } from '@/components/OctupTour/OctupIntro'
import { PaymentFlow } from '@/components/Payment/PaymentFlow'
import { GMBContextProvider } from '@/contexts/GMBContext'
import { IntroContextProvider } from '@/contexts/IntroContext'
import { ModalsContextProvider } from '@/contexts/ModalsContext'
import { useAbilities, useOctupDispatch, useToast } from '@/hooks'
import { useAppConfig } from '@/hooks/useAppConfig'
import { APP_FEATURES } from '@/models/appConfig'
import { LoadingView } from '@/new_components/__common__'
import { ExpiredModalContainer } from '@/new_containers/components/__common__'
import { WhatsNewContainer } from '@/new_containers/components/WhatsNew'
import {
    activeRoutes,
    inactiveRoutes,
    mobileActiveRoutes,
    mobileInactiveRoutes,
    useRenderRoutes,
} from '@/routes.config'
import { OctupBaseModal } from '@/shared/ui/Modal/OctupBaseModal'
import { BaseOctupToastsContainer } from '@/shared/ui/Toasts/BaseOctupToastsContainer'
import { connectAnalyticsService, identifyIntercomeUser } from '@/utils'

function App() {
    // Hook to get and set the app config
    // It's on a separate file to stop making the App component file too big and hard to understand
    // TODO: re-write the App component
    useAppConfig()

    const octupDispatch = useOctupDispatch()
    const queryString = window.location.href
    const showPaymentModal = queryString?.includes('payment=true')
    const userAgent = window.navigator.userAgent
    const [paymentModal, setPaymentModal] = useState(showPaymentModal)
    const [paymentCurrentStep, setPaymentCurrentStep] = useState(0)
    const { userInfo } = useSelector((state) => state.auth)
    const { isValid, isPaymentRequired } = useSelector((state) => state.session)
    const { users, usersMap } = useSelector((state) => state.users)
    const { intro } = useSelector((state) => state.userConfig)
    const { show } = useSelector((state) => state.whatsNew)
    const toast = useToast()
    const renderRoutes = useRenderRoutes()
    const { isFeatureAvailable } = useAbilities()
    const { excludedFeatures } = useSelector((state) => state.appConfig)

    axios.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            if (error.response.status === 440) {
                octupDispatch.auth.invalidateSession()
            } else if (error.response.status === 402) {
                octupDispatch.auth.paymentRequired()
            }
            throw error
        }
    )

    const userOs = useMemo(() => {
        let os = ''

        if (userAgent.indexOf('Win') !== -1) {
            os = 'Windows'
        } else if (userAgent.indexOf('iPhone') !== -1) {
            os = 'iPhone'
        } else if (userAgent.indexOf('Mac') !== -1) {
            os = 'MacOS'
        } else if (userAgent.indexOf('X11') !== -1) {
            os = 'UNIX'
        } else if (userAgent.indexOf('Android') !== -1) {
            os = 'Android'
        }

        return os
    }, [userAgent])

    const handleModal = () => {
        setPaymentModal(true)
    }

    // TODO: Move this implementation into a separate file
    const checkLCExp = useCallback(() => {
        if (userInfo?.client?.plan?.toLowerCase() !== 'trial') return
        const showLCCountdown = JSON.parse(localStorage.getItem('showLCCountdown'))
        if (showLCCountdown === false) return
        const lastDay = userInfo?.client?.expiredPaymentDate
        if (lastDay) {
            const daysGap = differenceInDays(new Date(lastDay), new Date())
            if (daysGap > 0 && daysGap < 8) {
                const button = (
                    <Button
                        size="small"
                        sx={(theme) => ({ color: theme.palette.common.white })}
                        onClick={() => {
                            setPaymentCurrentStep(1)
                            handleModal()
                        }}
                    >
                        Explore Plans
                    </Button>
                )
                toast.error(
                    `Your free-trial will end in ${daysGap} day${
                        daysGap > 1 ? 's' : ''
                    }! Go ahead, select a plan and set a payment method`,
                    null,
                    null,
                    button
                )

                // NOTE: setTimeout is a temporary solution
                // Need to remove auth//login/validate redirect after login
                setTimeout(() => {
                    localStorage.setItem('showLCCountdown', 'false')
                }, 5000)
            }
        }
    }, [toast, userInfo?.client?.expiredPaymentDate, userInfo?.client?.plan])

    connectAnalyticsService()
    identifyIntercomeUser()

    useState(() => {
        octupDispatch.insights.addUsersData(users)
        octupDispatch.objectives.addUsersData(users)
    }, [usersMap, users])

    useEffect(() => {
        if (userInfo?.intro === false) {
            octupDispatch.userConfig.setShowNewUserModal(true)
        }
    }, [userInfo])

    useEffect(() => {
        checkLCExp()
    }, [checkLCExp])

    const isMobileViewAvailable = useMemo(
        () => isFeatureAvailable(APP_FEATURES.MOBILE),
        [isFeatureAvailable]
    )

    if (!isMobileViewAvailable || userOs === 'Windows' || userOs === 'MacOS') {
        return (
            <>
                <Router>
                    {userInfo?.isActive ? (
                        <GMBContextProvider>
                            <IntroContextProvider>
                                <ModalsContextProvider>
                                    {!isValid && <ExpiredModalContainer />}

                                    {(isPaymentRequired || paymentModal) && (
                                        <OctupBaseModal
                                            showCloseBtn={paymentCurrentStep > 0}
                                            onDismissModal={() => {
                                                if (paymentCurrentStep === 2 || !isPaymentRequired)
                                                    setPaymentModal(false)
                                                else setPaymentCurrentStep(0)
                                            }}
                                        >
                                            <PaymentModalContainer>
                                                <PaymentFlow
                                                    isTrial={true}
                                                    // TODO: Replace with real plans
                                                    currentStep={paymentCurrentStep}
                                                    setCurrentStep={setPaymentCurrentStep}
                                                    closeModal={() => {
                                                        setPaymentModal(false)
                                                    }}
                                                />
                                            </PaymentModalContainer>
                                        </OctupBaseModal>
                                    )}

                                    <Layout isLoading={excludedFeatures.isLoading}>
                                        <ToastContainer
                                            theme="dark"
                                            position="top-center"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={true}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                        />
                                        <Routes>{renderRoutes(activeRoutes)}</Routes>
                                    </Layout>
                                    {(intro.showNewUserModal || intro.startTour) && <OctupIntro />}
                                    {show && <WhatsNewContainer />}
                                </ModalsContextProvider>
                            </IntroContextProvider>
                        </GMBContextProvider>
                    ) : (
                        <>
                            <GMBContextProvider>
                                <IntroContextProvider>
                                    <>
                                        {ReactDOM.createPortal(
                                            <BaseOctupToastsContainer />,
                                            document.getElementById('octup-toast-root')
                                        )}
                                        <ToastContainer
                                            theme="dark"
                                            position="top-center"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={true}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                        />
                                        {excludedFeatures.isLoading ? (
                                            <LoadingView />
                                        ) : (
                                            <Routes>{renderRoutes(inactiveRoutes)}</Routes>
                                        )}
                                    </>
                                </IntroContextProvider>
                            </GMBContextProvider>
                        </>
                    )}
                </Router>
            </>
        )
    }

    // TODO: delete mobile view, support it as responsive view

    if (excludedFeatures.isLoading) return <LoadingView />

    return userInfo?.isActive ? (
        <Router>
            <Routes>{renderRoutes(mobileActiveRoutes)}</Routes>
        </Router>
    ) : (
        <Router>
            <Routes>{renderRoutes(mobileInactiveRoutes)}</Routes>
        </Router>
    )
}

const PaymentModalContainer = styled.div`
    max-height: 78vh;
`

export default App
