import { DASHBOARD_SECTIONS_FIELD_NAME } from 'constants/dashboards'
import { ERROR_MESSAGES } from 'constants/validation'
import type { DashboardTemplateType } from '@octup/core/models'
import type { DashboardType, DashboardFormType } from 'models/dashboards'
import { object, string } from 'yup'

export const getDefaultValues = (
    dashboard?: DashboardType,
    template?: DashboardTemplateType
): DashboardFormType => ({
    ...(!dashboard?.isNew && { id: dashboard?.id }),
    name: dashboard?.name || '',
    templateId: template?.id,
    [DASHBOARD_SECTIONS_FIELD_NAME]: dashboard?.sections?.map((section) => section?.id) || [],
})

export const MAX_NAME_LENGTH = 40

export const VALIDATION_SCHEMA = object({
    name: string()
        .max(MAX_NAME_LENGTH, ERROR_MESSAGES.MAX_LENGTH)
        .required(ERROR_MESSAGES.REQUIRED),
})
