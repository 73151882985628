export const getContainerStyle = (isMainMenuCollapsed) => (theme) => ({
    position: 'fixed',
    zIndex: 110,
    top: 0,
    left: theme.spacing(isMainMenuCollapsed ? 20 : 53),
})

export const iconButtonStyle = (theme) => ({
    width: theme.spacing(11),
    height: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[5],
    border: `${theme.spacing(0.4)} solid ${theme.palette.grey[400]}`,
    borderRadius: '0px 0px 50% 50%',
})

export const iconStyle = (theme) => ({
    fontSize: theme.spacing(5.5),
    marginBottom: theme.spacing(1),
})
