import { useEffect, useState } from 'react'
import { useOctupDispatch } from '../../../../hooks'
import { useSelector } from 'react-redux'
import { WhatsNewNav } from '../../../../new_components/WhatsNew/WhatsNewNav/WhatsNewNav'

export const WhatsNewNavContainer = () => {
    const octupDispatch = useOctupDispatch()
    const [showPastUpdates, setShowPastUpdates] = useState(true)
    const { slidesData } = useSelector((state) => state.whatsNew)

    useEffect(() => {
        if (!showPastUpdates) octupDispatch.whatsNew.resetActiveItem()
    }, [octupDispatch.whatsNew, showPastUpdates])

    const handleShowPastUpdatesClick = () => {
        setShowPastUpdates((prev) => !prev)
    }
    const handleShowPastUpdatesChange = (checked) => {
        setShowPastUpdates(checked)
    }

    return (
        <WhatsNewNav
            slidesData={slidesData}
            showPastUpdates={showPastUpdates}
            handleShowPastUpdatesClick={handleShowPastUpdatesClick}
            handleShowPastUpdatesChange={handleShowPastUpdatesChange}
        />
    )
}
