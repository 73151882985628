import { ColumnBottomInnerContainer } from '../AlterativeHome.styles'
import { AlternativeHomeCardItem } from './base-components/AlternativeHomeCardItem'
import { InsightsLogoSvg } from '../../../shared/assets/icons'
import { DICTIONARY_SECTIONS, getDictionaryValue } from '../../../shared/dictionary/dictionary'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { generateGraphMeta } from './utils/alternative-home-graph-util'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'

export function InsightsCard({ className, setShowDetailsModal }) {
    const octupDispatch = useOctupDispatch()

    const {
        insights,
        isLoading: isLoadingInsights,
        isRejected: isRejectedInsights,
    } = useSelector((state) => state.insights)

    const [insightsGraphMetaData, setInsightsGraphMetaData] = useState([])

    useEffect(() => {
        octupDispatch.insights.getInsights()
    }, [octupDispatch.insights])

    useEffect(() => {
        setInsightsGraphMetaData(
            generateGraphMeta(
                insights,
                'status',
                [
                    {
                        field: 'new',
                        title: 'New',
                        color: '#504B5A',
                        textColor: 'inherit',
                    },
                    {
                        field: 'assigned',
                        title: 'At work',
                        color: '#7737FF',
                        textColor: 'inherit',
                    },
                    {
                        field: 'read',
                        title: 'Read',
                        color: 'rgba(0, 152, 212, 1)',
                        textColor: 'inherit',
                    },
                    {
                        field: 'resolved',
                        title: 'Resolved',
                        color: '#078344',
                        textColor: 'inherit',
                    },
                    {
                        field: 'dismissed',
                        title: 'Dismissed',
                        color: '#A4A0AA',
                        textColor: 'inherit',
                    },
                ],
                false
            )
        )
    }, [insights])

    const memoizedInsightsLabels = useMemo(
        () => insightsGraphMetaData && insightsGraphMetaData.map((el) => el.title),
        [insightsGraphMetaData]
    )

    return (
        <ColumnBottomInnerContainer className={className}>
            <>
                <AlternativeHomeCardItem
                    setShowDetailsModal={setShowDetailsModal}
                    mainIcon={InsightsLogoSvg}
                    isRejected={isRejectedInsights}
                    isLoading={isLoadingInsights}
                    segment={null}
                    metadata={insightsGraphMetaData}
                    title={getDictionaryValue('insights', DICTIONARY_SECTIONS.TITLES)}
                    value={insights ? insights.length : 0}
                    subtitle={getDictionaryValue(
                        'insights_received',
                        DICTIONARY_SECTIONS.SUBTITLES
                    )}
                    labels={memoizedInsightsLabels}
                    position={'top'}
                    isMetricsLayout={false}
                    colorSet={insightsGraphMetaData && insightsGraphMetaData.map((el) => el.color)}
                />
            </>
        </ColumnBottomInnerContainer>
    )
}
