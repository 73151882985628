import { OTHER_COST } from 'constants/warehouse'
import { Text } from 'shared/styledComponents'
import { VALUE_TYPE_INDEX, getFormattedVal } from 'shared/utils/metrics-utils'

const METRIC_TITLES = {
    AVERAGE_DAILY_COST: 'avg_daily_cost',
    TOTAL_DAILY_COST: 'daily_order_cost',
    ORDERS: 'orders',
}

const NO_DATA_MESSAGE = 'No Invoice Data'

const METRIC_DATA_BY_TITLE = {
    [METRIC_TITLES.AVERAGE_DAILY_COST]: {
        order: 0,
        valueUnits: VALUE_TYPE_INDEX.NUMBER,
        isShopCurrency: true,
        noDataMessage: NO_DATA_MESSAGE,
    },
    [METRIC_TITLES.TOTAL_DAILY_COST]: {
        order: 1,
        valueUnits: VALUE_TYPE_INDEX.NUMBER,
        isShopCurrency: true,
        noDataMessage: NO_DATA_MESSAGE,
    },
    [METRIC_TITLES.ORDERS]: {
        order: 2,
        valueUnits: VALUE_TYPE_INDEX.ABSOLUTE,
    },
}

const getCostGraphData = (costData) =>
    costData?.find?.((cost) => cost.metric.metricTitle === METRIC_TITLES.AVERAGE_DAILY_COST)
        .dataset || []

export const getData = ({ costs, costData }) =>
    costs?.map((cost) => ({
        dataset: getCostGraphData(costData),
        metric: {
            supported: true,
            metricTitle: cost.name,
            deep_dive: cost.name,
            graphLabel: METRIC_TITLES.AVERAGE_DAILY_COST,
            ...METRIC_DATA_BY_TITLE[METRIC_TITLES.AVERAGE_DAILY_COST],
            ...cost,
        },
    }))

const getOtherCostsTotal = (value) =>
    getFormattedVal({
        val: value,
        valueType: VALUE_TYPE_INDEX.NUMBER,
        showSymbol: true,
        isShopCurrency: true,
    })

const OTHER_TABLE_ROW_STYLE = { container: { width: '50%' }, row: { width: '100%' } }
const getOtherCostTableConfig = (data) => ({
    [OTHER_COST]: {
        headers: [
            {
                title: 'cost',
                field: 'name',
                width: '100%',
                sx: OTHER_TABLE_ROW_STYLE,
            },
            {
                title: 'amount',
                field: 'value',
                supported: true,
                valueUnits: 1,
                isShopCurrency: true,
                sx: OTHER_TABLE_ROW_STYLE,
            },
        ],
        rows: data.costs,
        rowKeyField: 'name',
        hiddenRowKey: 'name',
        showCounter: true,
        defaultSortBy: { title: 'amount', direction: -1 },
        sortingHeadersToDataMapping: { cost: 'name', amount: 'value' },
        customCounter: (
            <Text fontWeight="bold">{`Other costs total: ${getOtherCostsTotal(data.value)}`}</Text>
        ),
    },
})

const getMetricOrder = ({ metric }) => METRIC_DATA_BY_TITLE[metric.metricTitle].order
const getCostTableRows = ({ prevRows, fieldTitle, data }) => ({
    ...prevRows,
    ...data.reduce(
        (rows, [value, date]) => ({
            ...rows,
            [date]: { date, [fieldTitle]: value, ...prevRows[date] },
        }),
        {}
    ),
})

const getCostTableConfig = (name, data) => {
    const config = [...data]
        .sort((a, b) => getMetricOrder(a) - getMetricOrder(b))
        .reduce(
            (acc, cost) => {
                const title = cost.metric.metricTitle

                return {
                    ...acc,
                    headers: [
                        ...acc.headers,
                        { ...cost.metric, ...METRIC_DATA_BY_TITLE[title], title },
                    ],
                    rows: getCostTableRows({
                        prevRows: acc.rows,
                        fieldTitle: title,
                        data: cost.dataset,
                    }),
                }
            },
            { headers: [{ title: 'date' }], rows: {} }
        )

    return {
        [name]: {
            headers: config.headers,
            rows: Object.values(config.rows),
            rowKeyField: 'date',
        },
    }
}

export const getMetricDetails = ({ name, data, otherCost }) => {
    if (name === OTHER_COST) {
        return { showGraph: false, tableConfig: getOtherCostTableConfig(otherCost) }
    }

    if (!name || !data) {
        return { showGraph: true, tableConfig: {} }
    }

    return { showGraph: true, tableConfig: getCostTableConfig(name, data) }
}
