import React from 'react'
import ArrowUp from '../../../shared/assets/images/ArrowUp.svg'
import styled from 'styled-components'

export const MobileResetScroll = ({ containerRef }) => {
    return (
        <OnScrollImage
            src={ArrowUp}
            onClick={() => {
                containerRef?.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            }}
        />
    )
}

const OnScrollImage = styled.img`
    position: absolute;
    right: 1vw;
    bottom: 15vh;
    z-index: 100;
`
