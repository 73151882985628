import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageLayout from '../../components/PageLayout/PageLayout'
import { getMetricsBySegment, SEGMENTS } from '../../features/metrics/metricsSlice'
import { OctupNoDataFound } from '../../shared/components/loaders/OctupNoDataFound'
import { useLocation } from 'react-router-dom'
import facebook from '../../shared/assets/images/facebookIcon.svg'
import google from '../../shared/assets/images/GoogleAdsIcon.svg'

import { BottomContainer, TopSideContainer } from './Marketing.styles'
import { MarketingMetrics } from './marketing-components/MarketingMetrics'
import { AdSpendByChannelSection } from './marketing-components/AdSpendByChannelSection'
import { ROASSection } from './marketing-components/ROASSection/ROASSection'

function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
}

export const BtnStyle = {
    padding: '.93rem',
    width: '2.4rem !important',
    height: '2.4rem !important',
    minWidth: '2.4rem !important',
    minHeight: '2.4rem !important',
}

export const chartMapIcons = {
    facebook: facebook,
    googleAds: google,
    google: google,
}

const MarketingPage = () => {
    let query = useQuery()
    const dispatch = useDispatch()
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const { isLoading, isRejected } = useSelector((state) => state.metrics)

    useEffect(() => {
        if (query.get('long_lived_token')) {
            fetch('https://app.octup.com/approval', {
                method: 'POST',
                body: JSON.stringify({
                    long_lived_token: query.get('long_lived_token'),
                }),
            })
        }
    }, [query])

    useEffect(() => {
        if (!datePickerValues || isLoading) return
        dispatch(
            getMetricsBySegment({
                segments: [
                    SEGMENTS.MARKETING,
                    SEGMENTS.GRAPH,
                    SEGMENTS.MARKERING_AD_SPEND_BY_CHANNELS_GRAPH,
                    SEGMENTS.MARKERING_ROAS,
                ],
                datePickerValues,
                dataset: 1,
            })
        )
    }, [datePickerValues])

    return (
        <PageLayout>
            <TopSideContainer>
                {isRejected ? (
                    <OctupNoDataFound />
                ) : (
                    <MarketingMetrics BtnStyle={BtnStyle} chartMapIcons={chartMapIcons} />
                )}
            </TopSideContainer>
            <BottomContainer>
                <AdSpendByChannelSection chartMapIcons={chartMapIcons} />
                <ROASSection />
            </BottomContainer>
        </PageLayout>
    )
}

export default MarketingPage
