import type { ObjectSchema } from 'yup'
import { object, string, ref, boolean } from 'yup'
import { ERROR_MESSAGES } from '@/constants'
import type { CreateUserFormType } from '@/models/signup'
import { VALIDATION_RULES } from '@/utils'

export const DEFAULT_VALUES: CreateUserFormType = {
    shop: '',
    email: '',
    fullName: '',
    password: '',
    confirmPassword: '',
    isTermAccepted: false,
}

export const VALIDATION_SCHEMA: ObjectSchema<Partial<CreateUserFormType>> = object({
    shop: string(),
    email: string(),
    fullName: string(),
    password: VALIDATION_RULES.PASSWORD.required(ERROR_MESSAGES.REQUIRED),
    confirmPassword: string()
        .trim()
        .oneOf([ref('password')], ERROR_MESSAGES.PASSWORD_MATCH)
        .required(ERROR_MESSAGES.REQUIRED),
    isTermAccepted: boolean()
        .oneOf([true], ERROR_MESSAGES.TERMS_REQUIRED)
        .required(ERROR_MESSAGES.TERMS_REQUIRED),
})
