import { useContext } from 'react'
import { AlternativeHome } from '@/components/AlternativeHome/AlternativeHome'
import MetricsHome from '@/components/Metrics/MetricsHome'
import { GMBContext } from '@/contexts/GMBContext'

export function HomeAlternativeView() {
    const { showGMBoard, setShowGMBoard } = useContext(GMBContext)

    return (
        <>
            <MetricsHome />
            <AlternativeHome showGMBoard={showGMBoard} setShowGMBoard={setShowGMBoard} />
        </>
    )
}
