import { getInputDate } from '../../../../shared/utils/dates-util'
import { FormattedVal, TdChangePrecentageStyled } from '../MetricsDetailsModal.styles'
import { getFormattedVal } from '../../../../shared/utils/metrics-utils'
import {
    DICTIONARY_SECTIONS,
    getDictionaryValue,
    octupDictionary,
} from '../../../../shared/dictionary/dictionary'
import MetricTrend from '../../../Metrics/MetricItem/MetricItemComponents/MetricTrend'
import React from 'react'
import { Typography } from '@mui/material'
import { OctupTooltip } from 'shared/ui/MUIWrappers/OctupTooltip'

const renderPredefinedRow = (
    el,
    selectedMetricData,
    deepdives,
    sortingHeadersToDataMapping,
    setOrdersModal
) => {
    return deepdives[`${selectedMetricData?.metric?.deep_dive}`]?.headers
        ?.map((el) => el.title)
        .map((header, index) => (
            <td
                key={header}
                onClick={
                    index === 0
                        ? () => {
                              setOrdersModal({
                                  show: true,
                                  selectedItem: el,
                              })
                          }
                        : () => {}
                }
                style={
                    index === 0
                        ? {
                              color: '#007F82',
                              fontWeight: 600,
                              cursor: 'pointer',
                          }
                        : {}
                }
            >
                {header === 'date'
                    ? getInputDate(
                          getInputDate(
                              el[
                                  sortingHeadersToDataMapping[
                                      `${header?.replace(' ', '')?.toLowerCase()}`
                                  ]
                              ],
                              selectedMetricData?.metric?.time_slice
                          )
                      )
                    : header === 'value'
                      ? getFormattedVal({
                            val: +el[
                                sortingHeadersToDataMapping[
                                    `${header?.replace(' ', '')?.toLowerCase()}`
                                ]
                            ],
                            valueType: +selectedMetricData?.metric?.valueUnits,
                            customNumOfDigits: null,
                            isSupported: selectedMetricData?.metric?.supported,
                            multiplyBy100: null,
                            showSymbol: true,
                            isShopCurrency: true,
                        })
                      : el[
                            sortingHeadersToDataMapping[
                                `${header?.replace(' ', '')?.toLowerCase()}`
                            ]
                        ]}
            </td>
        ))
}
const renderDeepDiveRow = (el, selectedMetricData, deepdives) => {
    const { deep_dive: originalDeepDive } = selectedMetricData?.metric || {}
    const deep_dive = +originalDeepDive || originalDeepDive

    return (
        <>
            {deepdives[deep_dive].headers.map((element) => {
                const field = element.field || element.title

                if (element.title === 'date')
                    return (
                        <td key={element.title}>
                            {getInputDate(el.date, selectedMetricData?.metric?.time_slice)}
                        </td>
                    )
                else {
                    return (
                        <td key={element.title} style={element?.sx?.container}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '.5rem',
                                    textAlign: 'left',
                                    width: '12rem',
                                    ...element?.sx?.row,
                                }}
                            >
                                <FormattedVal>
                                    {(!el[field] && element?.noDataMessage && (
                                        <OctupTooltip
                                            title={element?.noDataMessage}
                                            position={'top'}
                                        >
                                            <Typography color="error" fontSize="inherit">
                                                -
                                            </Typography>
                                        </OctupTooltip>
                                    )) ||
                                        (element.valueUnits
                                            ? getFormattedVal({
                                                  val: +el[field] || element?.defaultValue,
                                                  valueType: +element.valueUnits,
                                                  customNumOfDigits:
                                                      octupDictionary?.titles?.metricsTitles[
                                                          element.title
                                                      ]?.toLowerCase() === 'orders' ||
                                                      octupDictionary?.titles?.metricsTitles[
                                                          element.title
                                                      ]?.toLowerCase() === 'number of returns'
                                                          ? 0
                                                          : null,
                                                  currency: element.currency,
                                                  isShopCurrency: element.isShopCurrency,
                                                  isSupported: element.supported,
                                                  multiplyBy100: null,
                                                  showSymbol: true,
                                              })
                                            : octupDictionary?.titles?.metricsTitles[el[field]] ||
                                              el[field] ||
                                              '-')}
                                </FormattedVal>
                                {element.title === selectedMetricData.metric.metricTitle && (
                                    <TdChangePrecentageStyled>
                                        <MetricTrend
                                            fontSize={'1.3rem'}
                                            trendValue={+el.change_precentage * 100}
                                            trendUnits={'percentage'}
                                            isSupported={true}
                                            isPositiveTrend={+el.change_precentage > 0}
                                        />
                                    </TdChangePrecentageStyled>
                                )}
                            </div>
                        </td>
                    )
                }
            })}
        </>
    )
}

const renderBreakdownRow = (el, selectedMetricData, breakdownItemsData) => {
    return breakdownItemsData?.table?.headers.map((element) => {
        if (element.title === 'date')
            return (
                <td key={element.title}>
                    {getInputDate(el.date, selectedMetricData?.metric?.time_slice)}
                </td>
            )
        else
            return (
                <td key={element.title}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: '.5rem',
                            textAlign: 'left',
                            width: '12rem',
                        }}
                    >
                        <FormattedVal>
                            {getFormattedVal({
                                val: +el[element.title],
                                valueType: +element.valueUnits,
                                customNumOfDigits:
                                    octupDictionary?.titles?.metricsTitles[
                                        element.title
                                    ]?.toLowerCase() === 'orders' ||
                                    octupDictionary?.titles?.metricsTitles[
                                        element.title
                                    ]?.toLowerCase() === 'number of returns'
                                        ? 0
                                        : null,
                                currency: element.currency,
                                isSupported: element.supported,
                                multiplyBy100: null,
                                showSymbol: true,
                            })}
                        </FormattedVal>
                        {getDictionaryValue(element.title, DICTIONARY_SECTIONS.METRICS_TITLES) ===
                            getDictionaryValue(
                                selectedMetricData.metric.metricTitle,
                                DICTIONARY_SECTIONS.METRICS_TITLES
                            ) && (
                            <TdChangePrecentageStyled>
                                <MetricTrend
                                    fontSize={'1.3rem'}
                                    trendValue={+el.change_precentage * 100}
                                    trendUnits={'percentage'}
                                    isSupported={true}
                                    isPositiveTrend={+el.change_precentage > 0}
                                />
                            </TdChangePrecentageStyled>
                        )}
                    </div>
                </td>
            )
    })
}

export { renderDeepDiveRow, renderBreakdownRow, renderPredefinedRow }
