import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { ThunkAPIConfigType } from 'services/utils'
import type { ClientUserType } from '@/models/clients'
import type { SignupBySourceType } from '@/models/signup'
import { signupBySource } from '@/services/auth'

type SignupStateType = {
    data?: ClientUserType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: SignupStateType = {
    isLoading: false,
}

export const fetchSignupBySource = createAsyncThunk<
    ClientUserType,
    SignupBySourceType,
    ThunkAPIConfigType
>('fetchSignupBySource', signupBySource)

export const signupSlice = createSlice({
    name: 'signup',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSignupBySource.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchSignupBySource.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchSignupBySource.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const signupReducer = signupSlice.reducer
