import axios from 'axios'
import { GLOBAL_CONFIG } from 'config'
import type {
    ClientSettingsType,
    EmailSettingsType,
    WorkingHoursSettingsType,
} from '@/models/settings'
import { generateAPIConfig } from '@/services/utils'
import type { ThunkAPIType } from '@/services/utils'

const SETTINGS_API_URL = GLOBAL_CONFIG.BASE_URL + '/auth/settings'
const EMAIL_SETTINGS_API_URL = SETTINGS_API_URL + '/email'
const CLIENTS_SETTINGS_API_URL = GLOBAL_CONFIG.API_URL + '/brands-settings'
const WORKING_HOURS_SETTINGS_API_URL = GLOBAL_CONFIG.API_URL + '/client/settings/working-hours'

export const getEmailSettings = async (thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(EMAIL_SETTINGS_API_URL, config)
    return response.data
}

export const editEmailSettings = async (data: EmailSettingsType, thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.put(EMAIL_SETTINGS_API_URL, data, config)
    return response.data
}

export const getClientsSettings = async (thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(CLIENTS_SETTINGS_API_URL, config)
    return response.data
}

export const editClientSettings = async (data: ClientSettingsType, thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI })
    const { id, display_name } = data
    const response = await axios.put(`${CLIENTS_SETTINGS_API_URL}/${id}`, { display_name }, config)
    return response.data
}

export const getWorkingHoursSettings = async (thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(WORKING_HOURS_SETTINGS_API_URL, config)
    return response.data
}

export const editWorkingHoursSettings = async (
    data: WorkingHoursSettingsType,
    thunkAPI: ThunkAPIType
) => {
    const config = generateAPIConfig({ thunkAPI })
    const settings = { working_hours: data }
    const response = await axios.put(WORKING_HOURS_SETTINGS_API_URL, settings, config)
    return response.data
}
