export const containerStyle = (theme) => ({
    width: theme.spacing(140),
    paddingTop: theme.spacing(3),
})

export const textContainerStyle = (theme) => ({
    padding: theme.spacing(0, 14),
})

export const buttonStyle = (theme) => ({
    padding: theme.spacing(2, 11),
})
