import type { MouseEvent } from 'react'
import { forwardRef } from 'react'
import type { MetricsSectionType } from '@octup/core/models'
import { CardContent, Stack, SvgIcon } from '@octup/core/ui-kit'
import { PlusCircleIcon } from '@/new_assets/icons'
import { DashboardCard } from '@/new_components/Dashboards'
import { MetricsSectionDetails } from './MetricsSectionDetails'
import { cardStyle, cardContentStyle, plusCircleIconStyle } from './styles'

type MetricsSectionFieldPropsType = {
    data?: MetricsSectionType
    onClick: () => void
    onDelete: (event: MouseEvent<SVGElement>) => void
    isSelected: boolean
    isDisabled: boolean
}

export const MetricsSectionField = forwardRef<HTMLDivElement, MetricsSectionFieldPropsType>(
    ({ data, onClick, onDelete, isSelected, isDisabled }, ref) => (
        <DashboardCard
            data-testid="metrics-section-field"
            ref={ref}
            isClickable
            sx={cardStyle}
            onClick={onClick}
            isSelected={isSelected}
            isDisabled={isDisabled}
        >
            <CardContent sx={cardContentStyle}>
                {data ? (
                    <MetricsSectionDetails data={data} onDelete={onDelete} />
                ) : (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                        <SvgIcon
                            data-testid="plus-circle-icon"
                            component={PlusCircleIcon}
                            color="text.secondary"
                            sx={plusCircleIconStyle}
                        />
                    </Stack>
                )}
            </CardContent>
        </DashboardCard>
    )
)
