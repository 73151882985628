export enum VALUE_UNIT_TYPES {
    FRACTION = 'fraction',
    PERCENTAGE = 'percentage',
    ABSOLUTE = 'absolute',
    STRING = 'string',
    DATE = 'date',
}

export type NumberValueUnitType =
    | VALUE_UNIT_TYPES.ABSOLUTE
    | VALUE_UNIT_TYPES.FRACTION
    | VALUE_UNIT_TYPES.PERCENTAGE
