import { DASHBOARD_SECTIONS_FIELD_NAME } from 'constants/dashboards'
import { useMemo } from 'react'
import type { DashboardSectionPropsType } from '@octup/core/components'
import { DashboardContainer } from '@octup/core/components'
import type { DashboardTemplateType } from '@octup/core/models'
import { Drawer, Stack } from '@octup/core/ui-kit'
import type { PayloadAction } from '@reduxjs/toolkit'
import PageLayout from 'components/PageLayout/PageLayout'
import type { DashboardFormType, DashboardMetadataType } from 'models/dashboards'
import { DashboardFormActions, DashboardNameField } from 'new_components/Dashboards/DashboardForm'
import {
    FormContainer,
    FormFieldArrayContainer,
    FormFieldContainer,
} from 'new_containers/components/__common__'
import {
    MetricsSectionFieldContainer,
    MetricsSectionsListPanelContainer,
} from 'new_containers/components/Dashboards/DashboardForm'
import type { Schema } from 'yup'
import { dashboardContainerStyle, drawerStyle, formStyle } from './FormDashboardPage.styles'

type FormDashboardPagePropsType = {
    isNew?: boolean
    error?: string
    isLoading: boolean
    onSubmit: (data: DashboardFormType) => void
    onSuccess: (data: PayloadAction<DashboardMetadataType>) => void
    onFail: () => void
    defaultValues: DashboardFormType
    validationSchema: Schema
    template?: DashboardTemplateType
    showMetricsSectionsList: boolean
}

const renderDashboardSection = ({ index, group }: DashboardSectionPropsType) => (
    <FormFieldContainer
        as={MetricsSectionFieldContainer}
        name={`${DASHBOARD_SECTIONS_FIELD_NAME}.${index}`}
        showError={false}
        group={group}
    />
)

export const FormDashboardPage = ({
    isNew,
    error,
    isLoading,
    template,
    showMetricsSectionsList,
    ...props
}: FormDashboardPagePropsType) => {
    const dashboardContainerSx = useMemo(
        () => dashboardContainerStyle(showMetricsSectionsList),
        [showMetricsSectionsList]
    )

    return (
        // @ts-expect-error: FormContainer is a JavaScript file
        <FormContainer sx={formStyle} {...props}>
            {/* @ts-expect-error: PageLayout is a JavaScript file */}
            <PageLayout
                error={error}
                isLoading={isLoading}
                showDatePicker={false}
                customTitleStyled={<DashboardNameField isDisabled={isLoading} />}
                sidePanel={<DashboardFormActions isNew={isNew} />}
            >
                <Stack sx={dashboardContainerSx}>
                    <FormFieldArrayContainer
                        as={DashboardContainer}
                        name={DASHBOARD_SECTIONS_FIELD_NAME}
                        template={template}
                        showGroupName
                        renderSection={renderDashboardSection}
                    />
                </Stack>
                <Drawer
                    open={showMetricsSectionsList}
                    anchor="right"
                    variant="persistent"
                    sx={drawerStyle}
                >
                    <MetricsSectionsListPanelContainer template={template} />
                </Drawer>
            </PageLayout>
        </FormContainer>
    )
}
