import type {
    Components,
    Theme,
    SvgIconClassKey,
    IconClassKey,
    CSSInterpolation,
} from '@mui/material'

declare module '@mui/material' {
    interface SvgIconPropsColorOverrides {
        'text.primary': true
        'text.secondary': true
    }

    interface IconClasses {
        fontSizeMedium: true
    }

    interface IconOwnProps {
        display: string
    }
}

type SvgIconOverridesType = Pick<Components<Theme>, 'MuiSvgIcon' | 'MuiIcon'>
type IconStyleOverridesType = Partial<
    Record<SvgIconClassKey | IconClassKey, ({ theme }: { theme: Theme }) => CSSInterpolation>
>

const ICON_FONT_SIZES: IconStyleOverridesType = {
    fontSizeSmall: ({ theme }) => ({
        fontSize: theme.spacing(4),
    }),
    fontSizeMedium: ({ theme }) => ({
        fontSize: theme.spacing(4.5),
    }),
    fontSizeLarge: ({ theme }) => ({
        fontSize: theme.spacing(5.5),
    }),
}

export const ICONS_OVERRIDES: SvgIconOverridesType = {
    MuiSvgIcon: {
        styleOverrides: ICON_FONT_SIZES,
        variants: [
            {
                props: { color: 'text.primary' },
                style: ({ theme }) => ({
                    color: theme.palette.text.primary,
                }),
            },
            {
                props: { color: 'text.secondary' },
                style: ({ theme }) => ({
                    color: theme.palette.text.secondary,
                }),
            },
        ],
    },
    MuiIcon: {
        styleOverrides: ICON_FONT_SIZES,
        variants: [
            {
                props: { display: 'flex' },
                style: { display: 'flex' },
            },
        ],
    },
}
