export const mapArrayToObject =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T extends Record<string, any>>(
        array: T[] | undefined,
        key: keyof T
    ): Record<string, T[]> | undefined =>
        array?.reduce(
            (acc: Record<string, T[]>, item: T) => ({
                ...acc,
                ...(item?.[key] && {
                    [item[key]]: [...(acc[item[key]] || []), item],
                }),
            }),
            {}
        )
