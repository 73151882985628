import styled from 'styled-components'
import { OctupBaseModal } from '../../../shared/ui/Modal/OctupBaseModal'
import { useCallback } from 'react'

export const RemoveCostModal = ({ removeProductHandler, costName, dismissModalHandler }) => {
    const handleClick = useCallback(async () => {
        removeProductHandler()
        dismissModalHandler()
    }, [dismissModalHandler, removeProductHandler])

    return (
        <OctupBaseModal
            onDismissModal={dismissModalHandler}
            title={'Remove Product Cost'}
            buttons={[
                {
                    id: 'remove_cost_modal-cancel-btn',
                    title: 'cancel',
                    variant: 'secondary',
                    onClick: dismissModalHandler,
                },
                {
                    id: 'remove_cost_modal-remove-btn',
                    title: 'remove cost',
                    variant: 'contained',
                    onClick: handleClick,
                },
            ]}
        >
            <ModalMessageContainer>
                <div>{`This cost will be deleted  '${costName}' for this specific product.`}</div>
                <div style={{ fontWeight: 600 }}>Are you sure you want to remove this cost?</div>
            </ModalMessageContainer>
        </OctupBaseModal>
    )
}

const ModalMessageContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 64rem;
    gap: 1rem;
    div {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`
