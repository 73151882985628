// TODO: RE-WRITE

import { useState, useRef, useEffect } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { LoadableContent } from 'new_components/__common__'
import { Bar } from 'react-chartjs-2'
import styled from 'styled-components'
import { NoDataError } from '../../components/NoDataError/NoDataError'
import { octupChartsOptions } from '../../shared/ui/Charts/octupChartsUtils'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

//Start of custom plugin

const imageItems = {
    id: 'imageItems',
    beforeDatasetsDraw(chart) {
        const {
            ctx,
            options,
            data,
            height,
            scales: { x },
        } = chart

        ctx.save()

        data?.datasets[0]?.image?.forEach((img, index) => {
            const logo = new Image()
            logo.src = `${img}`
            if (x) {
                ctx.drawImage(
                    logo,
                    x?.getPixelForValue(index) - options.layout.padding.bottom * 0.5 || 0,
                    height - options.layout.padding.bottom,
                    options.layout.padding.bottom,
                    options.layout.padding.bottom
                )
            }
        })
    },
}

ChartJS.register(imageItems)

//End of custom plugin

export function createGradient(ctx, area, colorStart, colorEnd, colorMid) {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)
    colorStart && gradient.addColorStop(0, colorStart)
    colorMid && gradient.addColorStop(0.5, colorMid)
    colorEnd && gradient.addColorStop(1, colorEnd)
    return gradient
}

export const BarChartWithImages = ({
    isLoading = false,
    isFilled = false,
    chartsDataSets,
    style,
    showLabels,
    roundChart = true,
    borderColors = null,
    backgroundColors = null,
    showXAxis,
    showYAxis,
    showLegend = false,
    numberOfShownDataSests = 1,
    backgroundColor = null,
    supported,
    valueUnits,
}) => {
    // const chartRef = useRef(null);
    const chartRef = useRef(null)

    const skipped = (ctx, value) => (ctx.p0.skip || ctx.p1.skip ? value : undefined)

    const [chartData, setChartData] = useState({
        datasets: [
            {
                label: 'Mock data',
                data: [1, 4],
                barThickness: 8,
                image: [
                    'https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745',
                    'https://yt3.ggpht.com/CvImhsYY5xARWaQI7YlrwjXJbp01C2QAGn09hQzHAyBqoPqcDRFsZFCO9ttq1gMXc6sKH2MOEQ=s88-c-k-c0x00ffffff-no-rj',
                ],
            },
        ],
    })
    useEffect(() => {
        const chart = chartRef.current

        if (!chart) {
            return
        }
        if (chartsDataSets)
            setChartData({
                labels: chartsDataSets.labels,
                datasets: chartsDataSets.datasets
                    .map((set, index) => {
                        return {
                            borderWidth: 1,
                            lineTension: roundChart ? 0.4 : 0,
                            fill: isFilled,
                            image: set.image,
                            barThickness: 8,
                            borderRadius: 10,
                            yAxisID: index === 0 ? 'y' : 'y1',
                            label: set.label,
                            data: set.data,
                            spanGaps: true,
                            segment: {
                                borderDash: (ctx) => skipped(ctx, [6, 6]),
                            },
                        }
                    })
                    .slice(0, numberOfShownDataSests)
                    .map((dataset, index) => {
                        return {
                            ...dataset,
                            //HERE
                            borderColor: !backgroundColor
                                ? createGradient(chart.ctx, chart.chartArea, '#00A8A8', '#7A00F3')
                                : (borderColors && borderColors[index]) ||
                                  createGradient(chart.ctx, chart.chartArea, '#00C8C0', '#00A8A8'),
                            // index === 0 ? OctupColors.primaryActionableLight : "indigo",
                            backgroundColor:
                                (backgroundColors &&
                                    createGradient(
                                        chart.ctx,
                                        chart.chartArea,
                                        'white',
                                        backgroundColors[index]
                                    )) ||
                                createGradient(chart.ctx, chart.chartArea, '#00A8A8', '#7A00F3'),
                        }
                    }),
            })
    }, [chartsDataSets])

    const chartOptions = {
        responsive: true,
        layout: {
            padding: {
                bottom: 22,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: { size: 10 },
                    display: true,
                },
            },
            y: {
                title: {
                    display: false,
                },
                grid: {
                    display: true,
                },
                ticks: {
                    font: { size: 10 },
                    suggestedMin: 0,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: '',
            },
            imageItems,
        },
    }

    return !supported ? (
        <div
            style={{
                width: '100%',
                height: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <NoDataError />
        </div>
    ) : (
        <LoadableContent isLoading={isLoading} height="100%" padding={0}>
            <Container style={{ ...style }}>
                <Bar
                    ref={chartRef}
                    options={{
                        ...octupChartsOptions({
                            showLegend,
                            showXAxis,
                            showYAxis,
                            showSecondYAxis: false,
                            showLabels,
                            // yTitle: 'testY',
                            // y1Title: 'testY1'
                        }),
                        scales: {
                            ...octupChartsOptions({
                                showLegend,
                                showXAxis,
                                showYAxis,
                                showSecondYAxis: false,
                                showLabels,
                                // yTitle: 'testY',
                                // y1Title: 'testY1'
                            }).scales,
                            ...chartOptions.scales,
                        },
                        layout: {
                            ...octupChartsOptions({
                                showLegend,
                                showXAxis,
                                showYAxis,
                                showSecondYAxis: false,
                                showLabels,
                                // yTitle: 'testY',
                                // y1Title: 'testY1'
                            }).layout,
                            ...chartOptions.layout,
                        },
                        plugins: {
                            ...octupChartsOptions({
                                showLegend,
                                showXAxis,
                                showYAxis,
                                showSecondYAxis: false,
                                showLabels,
                                valueUnits,
                                // yTitle: 'testY',
                                // y1Title: 'testY1'
                            }).plugins,
                            ...chartOptions.plugins,
                        },
                    }}
                    data={chartData}
                    // redraw={false}
                />
            </Container>
        </LoadableContent>
    )
}

const Container = styled.div`
    max-width: 100%;
    max-height: 30rem;
    min-height: 30rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
`
