import axios from 'axios'
import { GLOBAL_CONFIG } from 'config'
import { generateAPIConfig } from 'services/utils'

export const INTEGRATION_API_URL = GLOBAL_CONFIG.BASE_URL + 'auth'

/**
 * connect integration accounts
 * @selection mandatory (an array of ids)
 * @source mandatory (integration)
 */

export const setIntegrationAccounts = async (data, thunkAPI) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.post(`${INTEGRATION_API_URL}/extended_integration`, data, config)
    return response.data
}

/**
 * get integrations status
 */

export const getIntegrationsStatus = async (thunkAPI) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(`${INTEGRATION_API_URL}/integrations/status`, config)
    return response.data
}
