import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { OctupDatePicker } from '../../../shared/ui/DatePicker/OctupDatePicker'
import { renderMobileCardItem } from '../../../components/AlternativeHome/AlternativeHomeComponents/utils/mobile-home-utils'
import Tabs from '@mui/material/Tabs'
import MobileTopBar from './MobileTopBar'
import MobilePageLayout from '../../../components/PageLayout/MobilePageLayout'
import { OctupNoDataFound } from '../../../shared/components/loaders/OctupNoDataFound'
import { MobileMetricsTabIconSvg } from '../../../shared/assets/icons/MobileMetricsTabIconSvg'
import { OctupColors } from '../../../shared/theme/colors/OctupColors'
import { MobileInsightsTabIconSvg } from '../../../shared/assets/icons/MobileInsightsTabIconSvg'
import { MobileInsightCard } from '../../insight/MobileInsightsCard'
import { getInsights } from '../../../features/insights/insightSlice'
import { getUsers } from '../../../features/users/usersSlice'
import { MobileResetScroll } from './MobileResetScroll'
import { getMobileHomepageMetrics } from '../../../features/sections/mobileHomePageSectionsSlice'
import { MobileMetricLoader } from './MobileMetricLoader'
import { NoDataInsightsSvg } from '../../../shared/assets/icons'

const MobileHomePage = () => {
    const dispatch = useDispatch()

    const { userInfo } = useSelector((state) => state.auth)
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const { mobileHomepageMetrics, isLoading, isRejected } = useSelector(
        (state) => state.mobileHomepageMetrics
    )
    const { insights, isLoading: isLoadingInsights } = useSelector((state) => state.insights)

    const TabsValues = [
        {
            label: 'Metrics',
            disabled: false,
            icon: (active, disabled) => (
                <MobileMetricsTabIconSvg active={active} disabled={disabled} />
            ),
        },
        {
            label: 'Insights',
            disabled: false,
            icon: (active, disabled) => (
                <MobileInsightsTabIconSvg active={active} disabled={disabled} />
            ),
        },
    ]

    const [value, setValue] = React.useState(0)
    const [showError] = React.useState(false)
    const [showScrollReset, setShowScrollReset] = React.useState(false)
    const metricsContainerRef = useRef()
    const insightsContainerRef = useRef()

    useEffect(() => {
        dispatch(getInsights())
        dispatch(getUsers(userInfo?.token))
    }, [])

    useEffect(() => {
        if (!datePickerValues) {
            return
        }

        dispatch(
            getMobileHomepageMetrics({
                startDate: datePickerValues?.startDate,
                endDate: datePickerValues?.endDate,
            })
        )
    }, [datePickerValues])

    const handleChange = (event, newValue, i) => {
        !newValue.disabled && setValue(i)
    }

    const handleScroll = (containerRef) => {
        if (containerRef?.current?.scrollTop > 0) {
            setShowScrollReset(true)
        } else {
            setShowScrollReset(false)
        }
    }

    return (
        <MainContainer>
            <MobileTopBar showMenu />
            <MobilePageLayout>
                <MaskScrolledItemsContainer>
                    {value === 0 && (
                        <DropdownContainer>
                            <OctupDatePicker
                                isMobile={true}
                                direction={'vertical'}
                                month={2}
                                hideStaticDates={true}
                            />
                        </DropdownContainer>
                    )}
                </MaskScrolledItemsContainer>
                {value === 0 && (
                    <TabsContentContainer
                        ref={metricsContainerRef}
                        onScroll={() => {
                            handleScroll(metricsContainerRef)
                        }}
                    >
                        <MetricsContainer>
                            {showError ? (
                                <OctupNoDataFound />
                            ) : isLoading ? (
                                <>
                                    <MobileMetricLoader />
                                    <MobileMetricLoader />
                                    <MobileMetricLoader />
                                </>
                            ) : (
                                mobileHomepageMetrics.map((element, index) => {
                                    return renderMobileCardItem({
                                        element,
                                        isLoading,
                                        isRejected,
                                        index,
                                        showCard: false,
                                        isFilled: true,
                                    })
                                })
                            )}{' '}
                            {showScrollReset && (
                                <MobileResetScroll containerRef={metricsContainerRef} />
                            )}
                        </MetricsContainer>
                    </TabsContentContainer>
                )}
                {value === 1 && (
                    <TabsContentContainer
                        ref={insightsContainerRef}
                        onScroll={() => {
                            handleScroll(insightsContainerRef)
                        }}
                    >
                        <InsightsContainer>
                            {!isLoadingInsights && insights && insights.length > 0 ? (
                                insights.map((insight) => <MobileInsightCard insight={insight} />)
                            ) : (
                                <NoInsightsContainer>
                                    <NoDataInsightsSvg />
                                    There are no insights at the moment, We’ll have some meaningful
                                    insights for you soon.
                                </NoInsightsContainer>
                            )}
                            {showScrollReset && (
                                <MobileResetScroll containerRef={insightsContainerRef} />
                            )}
                        </InsightsContainer>
                    </TabsContentContainer>
                )}
                <TabsContainer>
                    <Tabs value={value}>
                        {TabsValues.map((tabValue, i) => {
                            return (
                                <TabInnerContainer
                                    key={tabValue.label}
                                    label={tabValue.label}
                                    active={value === tabValue.label}
                                    onClick={(e) => handleChange(e, tabValue, i)}
                                >
                                    {tabValue.icon(value === i, tabValue.disabled)}
                                    <TabLabel disabled={tabValue.disabled} active={value === i}>
                                        {tabValue.label}
                                    </TabLabel>
                                </TabInnerContainer>
                            )
                        })}
                    </Tabs>
                </TabsContainer>
            </MobilePageLayout>
        </MainContainer>
    )
}
const MainContainer = styled.div`
    width: 100%;
    .MuiTabs-indicator {
        background-color: ${OctupColors.primaryActionableDark};
    }
`
const TabsContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: scroll;
    scrollbar-width: none;
    margin-top: ${(props) => props.marginTop};

    ::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`

const DropdownContainer = styled.div`
    justify-content: flex-end;
    display: flex;
    position: absolute;
    background-color: ${OctupColors.pageBackground};
    width: 100%;
    height: 4vh;
    top: 2vh;
    right: 1rem;
    z-index: 101;
`

const MaskScrolledItemsContainer = styled.div`
    width: 100%;
    background-color: ${OctupColors.pageBackground};
    padding-bottom: 1vh;
    z-index: 100;
    position: absolute;
`

const MetricsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const InsightsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
`

const NoInsightsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    width: 80%;
    margin-left: 10%;
    margin-top: 40%;
`

const TabsContainer = styled.div`
    position: sticky;
    width: 100%;
    height: 10vh;
    background-color: #f8f7fb;
`

const TabInnerContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    border-bottom: 3px solid
        ${(p) => (p.active ? OctupColors.primaryActionableDark : OctupColors.cardsBackground)};
    border-top: 1px solid #ebeaee;
`

const TabLabel = styled.span`
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: ${(p) =>
        p.disabled
            ? 'gray'
            : p.active
              ? OctupColors.primaryActionableDark
              : OctupColors.primaryTextLight};
`

export default MobileHomePage
