import { useCallback } from 'react'
import { useOnMountEffect } from 'hooks'
import { useParams } from 'react-router-dom'
import { addNewDashboard, addNewDashboardData } from 'state/features/dashboards'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { FormDashboardPageContainer } from '../FormDashboardPageContainer'

export const CreateDashboardPageContainer = () => {
    const { dashboardId } = useParams()
    const dispatch = useAppDispatch()
    const { dashboardsById } = useAppSelector((state) => state.dashboards)
    const dashboard = dashboardsById.data?.[dashboardId!]

    const addNewDashboardById = useCallback(async () => {
        const { payload: newDashboard } = await dispatch(addNewDashboard({ id: dashboardId }))
        dispatch(addNewDashboardData(newDashboard))
    }, [dashboardId, dispatch])

    useOnMountEffect(() => {
        addNewDashboardById()
    })

    return (
        <FormDashboardPageContainer
            isNew
            data={dashboard?.data}
            error={dashboard?.error}
            isLoading={dashboard?.isLoading}
        />
    )
}
