import styled from 'styled-components'

export const ContentTextStyled = styled.div`
    font-size: 15px;
    line-height: 20px;

    > * {
        font-size: inherit;
    }

    > strong,
    em {
        font-weight: 600;
    }

    > em {
        font-style: italic;
    }

    a {
        text-decoration: underline;
    }
`

export const ContentAnchorStyled = styled.a`
    font-size: 15px;
    line-height: 20px;
    text-decoration: underline;
`

export const ContentListStyled = styled.ul`
    font-size: 15px;
    line-height: 20px;

    > li {
        font-size: inherit;
    }
`

export const ContentTableStyled = styled.table`
    * {
        font-size: 15px;
        line-height: 20px;
    }
`

export const ContentVideoContainerStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    margin: 1rem;

    video {
        border: 1px solid black;
        border-radius: 0.8rem;
        width: 100%;
        height: fit-content;
    }
`

export const ContentTitleStyled = styled.div`
    color: inherit;
    font-size: 17px;
    font-weight: 600;
`

export const ImageStyled = styled.div`
    max-width: 100%;
`
