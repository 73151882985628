export const ZendeskIcon = ({ size = '2.4rem' }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M11.5 9V19.5H3L11.5 9Z" fill="#00333B" />
            <path d="M13 15.5L13 5L21.5 5L13 15.5Z" fill="#00333B" />
            <path
                d="M3 5C3 5 4.90279 5 7.25 5C9.59721 5 11.5 5 11.5 5C11.5 7.48528 9.59721 9.5 7.25 9.5C4.90279 9.5 3 7.48528 3 5Z"
                fill="#00333B"
            />
            <path
                d="M21.5 19.5C21.5 19.5 19.5972 19.5 17.25 19.5C14.9028 19.5 13 19.5 13 19.5C13 17.0147 14.9028 15 17.25 15C19.5972 15 21.5 17.0147 21.5 19.5Z"
                fill="#00333B"
            />
        </svg>
    )
}
