import { Tab, Tabs, Grid } from '@octup/core/ui-kit'
import PageLayout from '@/components/PageLayout/PageLayout'
import type { APP_ROUTES } from '@/models/appConfig'
import type { SettingsRouteTab } from '@/models/settings'
import { RouterLink } from '@/new_components'

export type SettingsPagePropsType = {
    tabs: SettingsRouteTab[]
    selectedTab: APP_ROUTES
    children: React.ReactNode
}

export const SettingsPage = ({ tabs, selectedTab, children }: SettingsPagePropsType) => (
    //  @ts-expect-error: PageLayout is a JavaScript file
    <PageLayout customTitleStyled="Settings" showDatePicker={false}>
        <Grid container direction="column" height="100%" spacing={5} wrap="nowrap">
            <Grid item container>
                <Tabs value={selectedTab} variant="scrollable">
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.id}
                            value={tab.route}
                            label={tab.label}
                            component={RouterLink}
                            route={tab.route}
                            underline="none"
                        />
                    ))}
                </Tabs>
            </Grid>
            <Grid item container xs>
                {children}
            </Grid>
        </Grid>
    </PageLayout>
)
