import { Stack } from '@mui/material'
import { MetricValue } from './MetricValue'

export const MetricTableCell = ({ row, ...props }) => (
    <Stack spacing={1}>
        <MetricValue row={row} showTrend={!row.comparedData} {...props} />
        {row.comparedData && (
            <MetricValue
                row={row.comparedData}
                showTrend={false}
                variant="subtitle2"
                color="text.secondary"
                {...props}
            />
        )}
    </Stack>
)
