import styled from 'styled-components'

const PageTopSide = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`

const ActionableItemsTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: 100%;
    height: 100%;
`

const TableWrapper = styled.div`
    padding: 2rem;
    width: 100%;
    height: 100%;
`

const DropdownContainer = styled.div`
    width: 100%;
    position: absolute;
    right: 3rem;
    top: 6.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const MetricsPaneContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;
    width: 100%;
    height: 100%;
    overflow-x: auto;
`

export {
    PageTopSide,
    ActionableItemsTableContainer,
    TableWrapper,
    DropdownContainer,
    MetricsPaneContainer,
}
