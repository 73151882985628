import { useEffect, useState } from 'react'
import { addDays, format } from 'date-fns'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { SEGMENTS } from 'features/metrics/metricsSlice'
import { OctupBaseTable } from 'shared/ui/Tables/OctupBaseTable'
import { DICTIONARY_SECTIONS, getDictionaryValue } from 'shared/dictionary/dictionary'
import { getFormattedTrend, getFormattedVal } from 'shared/utils/metrics-utils'
import { NoDataTooltip } from 'new_components'
import { v4 as uuidv4 } from 'uuid'

export const YESTERDAY_DATE = addDays(new Date(), -1)
const YESTERDAY_FORMATTED_DATE = format(YESTERDAY_DATE, 'EEE. MMM dd, yyyy')

const TABLE_DATA = {
    headers: [
        '',
        'Yesterday',
        {
            title: 'Change',
            showInfoIcon: true,
            tooltip: `Change from previous day (${YESTERDAY_FORMATTED_DATE})`,
        },
    ],
    rows: [],
}

export const AlternativeHomeTable = () => {
    const { metrics, isLoading } = useSelector((state) => state.metrics)
    const [tableData, setTableData] = useState(TABLE_DATA)
    const data = metrics[SEGMENTS.ALTERNATIVE_HOME_YESTERDAY]

    useEffect(() => {
        if (!data) return
        setTableData((prevState) => {
            return {
                ...prevState,
                rows: data
                    .filter(
                        (el) =>
                            el?.metric?.metricTitle !== null &&
                            el?.metric?.metricTitle !== 'no_metric'
                    )
                    .map((el) => {
                        return {
                            id: uuidv4(),
                            title: getDictionaryValue(
                                `${el?.metric?.metricTitle}`,
                                DICTIONARY_SECTIONS.METRICS_TITLES
                            ),
                            value: el?.metric?.value,
                            units: el?.metric?.valueUnits,
                            currency: el?.metric?.currency,
                            change: el?.metric?.trendValue,
                            supported: el?.metric?.supported,
                        }
                    }),
            }
        })
    }, [data])

    return (
        <OctupBaseTable
            tableData={tableData}
            customRowStyle={{ height: '6rem' }}
            filtersPropsMapping={{}}
            showSearchFilter={false}
            showFilters={false}
            showCounter={false}
            showSelectedRow={false}
            isLoading={isLoading}
            renderRows={(el) => {
                const shownValue = getFormattedVal({
                    val: el?.value,
                    valueType: el?.units,
                    customNumOfDigits: null,
                    currency: el?.currency,
                    isSupported: el?.supported,
                    multiplyBy100: false,
                    showSymbol: true,
                })
                return (
                    <>
                        <TableDataStyled>{el.title}</TableDataStyled>
                        <TableDataStyled>
                            {shownValue ? shownValue : el?.supported ? 0 : <NoDataTooltip />}
                        </TableDataStyled>
                        <TableDataStyled changeValue={el.change} style={{ textAlign: 'left' }}>
                            {el?.change ? (
                                getFormattedTrend(el?.change, el?.supported, false)
                            ) : el?.supported ? (
                                0
                            ) : (
                                <div style={{ fontSize: 'inherit' }}>
                                    <span style={{ fontSize: 'inherit', color: '#DB2059' }}>
                                        No data
                                    </span>
                                </div>
                            )}
                        </TableDataStyled>
                    </>
                )
            }}
        />
    )
}

const TableDataStyled = styled.td`
    opacity: ${(props) => (props.isDismissed ? 0.4 : 1)};
    color: ${(props) =>
        props.changeValue
            ? props.changeValue > 0
                ? '#078344'
                : props.changeValue < 0
                  ? '#DB2059'
                  : 'inherit'
            : 'inherit'};
    font-weight: 400;
    height: 100%;
    text-transform: capitalize;
    white-space: pre-wrap;
`
