import { Grid } from '@mui/material'
import { MetricsDetailsMetricSelection } from 'components/Modals/MetricsModals/MetricsDetailsMetricSelection'
import { LoadableContent } from 'new_components/__common__'
import {
    ChartContainer,
    ComparisonDropdownContainer,
    TableContainer,
} from 'new_containers/components/Metrics'
import { OctupBaseModal } from 'shared/ui/Modal/OctupBaseModal'
import {
    comparisonContainerStyle,
    modalContentStyle,
    tableContainerStyle,
} from './DeepDiveModal.styles'

export const DeepDiveModal = ({
    onClose,
    selectedMetric,
    metricsGroup,
    deepdiveModal,
    setIsBreakdown,
    isBreakdown,
    isLoading,
    onMetricSelection,
    showGraph = true,
    data,
    comparisonMode,
    ...props
}) => {
    return (
        <OctupBaseModal
            isExpendable
            width="104rem"
            height="85rem"
            position="flex-start"
            onDismissModal={onClose}
            {...props}
        >
            <MetricsDetailsMetricSelection
                selectedMetric={selectedMetric}
                metricsGroup={metricsGroup}
                deepdiveModal={deepdiveModal}
                onMetricSelection={onMetricSelection}
                setIsBreakdown={setIsBreakdown}
                isBreakdown={isBreakdown}
            />
            <Grid container direction="column" wrap="nowrap" spacing={1} sx={modalContentStyle}>
                <Grid item container xs="auto" sx={comparisonContainerStyle}>
                    <ComparisonDropdownContainer
                        isBreakdown={isBreakdown}
                        metricsGroup={metricsGroup}
                        selectedMetric={selectedMetric}
                    />
                </Grid>
                <Grid item container height="26rem">
                    {showGraph && (
                        <LoadableContent isLoading={isLoading} height="24rem">
                            <ChartContainer
                                data={data}
                                isBreakdown={isBreakdown}
                                comparisonMode={comparisonMode}
                                selectedMetric={selectedMetric}
                            />
                        </LoadableContent>
                    )}
                </Grid>
                <Grid item container xs={9} sx={tableContainerStyle}>
                    <LoadableContent isLoading={isLoading} height="45rem">
                        <TableContainer data={data} selectedMetric={selectedMetric} />
                    </LoadableContent>
                </Grid>
            </Grid>
        </OctupBaseModal>
    )
}
