import Lottie, { useLottie, useLottieInteractivity } from 'lottie-react'
import { useEffect, useRef } from 'react'

const style = {
    //   height: 300,
}

export const LottieItem = (props) => {
    const options = props.options
    const playFrames = props.lottieSegments
    const lottieObj = useLottie(options, style)
    //TODO: add animation props config
    const Animation = useLottieInteractivity({
        lottieObj,
        mode: {},
        actions: [],
    })

    useEffect(() => {
        if (!playFrames) return
        lottieObj.stop()
        if (!props.isDone) lottieObj.playSegments(playFrames, true)
        else {
            options.loop = false
            lottieObj.playSegments([playFrames[1], 60], true)
        }
    }, [playFrames, props.isDone])

    return Animation
}
