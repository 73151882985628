import { Stack, SvgIcon } from '@mui/material'
import { InfoIcon } from '@octup/core/assets/icons'
import { OverflowTypography } from '@octup/core/ui-kit'
import { LastUpdatedIcon } from '../../../../shared/assets/icons/LastUpdatedIcon'
import { octupDictionary } from '../../../../shared/dictionary/dictionary'
import { ButtonIcon } from '../../../../shared/ui/Buttons/ButtonIcon'
import { OctupTooltip } from '../../../../shared/ui/MUIWrappers/OctupTooltip'
import { getInputDate } from '../../../../shared/utils/dates-util'

const MetricTitle = ({ title, variant = 'h6', isStale, lastUpdatedAt, description, ...props }) => {
    const tooltip = octupDictionary.tooltips.metrics[title] || description

    return (
        <Stack direction="row" alignItems="center" gap="0.5rem">
            <OverflowTypography variant={variant} fontWeight="normal" {...props}>
                {octupDictionary.titles.metricsTitles[title] || title}
            </OverflowTypography>
            {isStale && (
                <ButtonIcon
                    showButtonCard={false}
                    tooltipContent={<>Last updated: {getInputDate(lastUpdatedAt, 'objective')}</>}
                    mainIcon={LastUpdatedIcon}
                />
            )}
            {tooltip && (
                <OctupTooltip position="top" title={tooltip} className="metrics-details-btn">
                    <SvgIcon component={InfoIcon} color="text.primary" />
                </OctupTooltip>
            )}
        </Stack>
    )
}

export default MetricTitle
