import { SvgIcon, type Components, type Theme } from '@mui/material'
import { ArrowIcon } from '@octup/core/assets/icons'

type AccordionOverridesType = Pick<
    Components<Theme>,
    'MuiAccordion' | 'MuiAccordionSummary' | 'MuiAccordionDetails'
>

export const ACCORDION_OVERRIDES: AccordionOverridesType = {
    MuiAccordion: {
        defaultProps: {
            square: true,
            slotProps: { transition: { unmountOnExit: true } },
        },
    },
    MuiAccordionSummary: {
        defaultProps: {
            expandIcon: <SvgIcon component={ArrowIcon} fontSize="large" />,
        },
        styleOverrides: {
            root: {
                userSelect: 'auto',
            },
        },
    },
    MuiAccordionDetails: {
        styleOverrides: {
            root: ({ theme }) => ({
                '&:last-child': {
                    padding: theme.spacing(1, 2, 4, 2),
                },
            }),
        },
    },
}
