import type { MetricsSectionPropsType } from '@octup/core/models'
import { MetricOverview, MetricsSectionCard } from '../__common__'

export const CurrentMetricsSection = ({ data, isLoading, ...props }: MetricsSectionPropsType) => {
    const [metric] = data?.metrics || []

    return (
        <MetricsSectionCard sources={metric?.sources} isLoading={isLoading} height={24} {...props}>
            <MetricOverview metric={metric} isLoading={isLoading} showLastUpdatedDate />
        </MetricsSectionCard>
    )
}
