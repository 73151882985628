import { useCallback, useEffect, useState } from 'react'
import { useQueryString } from 'hooks'
import { useSelector, useDispatch } from 'react-redux'
import Dropdown from 'shared/ui/Dropdown/Dropdown'
import { fetchWarehouses } from 'state/features/logistics'

export const WarehouseFilterContainer = () => {
    const dispatch = useDispatch()
    const [options, setOptions] = useState([])
    const { queryParams, setQueryParams } = useQueryString()
    const { data } = useSelector((state) => state.warehouses)

    useEffect(() => {
        dispatch(fetchWarehouses())
    }, [dispatch])

    useEffect(() => {
        if (data) {
            setOptions(
                data.map((warehouse) => ({
                    ...warehouse,
                    isSelected: queryParams.warehouses_names?.includes(warehouse.name),
                }))
            )
        }
    }, [queryParams.warehouses_names, data])

    const handleClose = useCallback(
        ({ value }) => {
            const selectedNames = value.map(({ name }) => name)
            setQueryParams({ warehouses_names: selectedNames })
        },
        [setQueryParams]
    )

    return (
        <Dropdown
            title="Warehouse"
            options={options}
            isFilter
            trimSpace
            showClearFilters={false}
            onClose={handleClose}
            updateSelectedOptions
        />
    )
}
