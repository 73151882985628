// TODO: Rewrite approach for handling errors

import { Box, FormHelperText } from '@octup/core/ui-kit'
import { Controller, useFormContext } from 'react-hook-form'

const Container = ({ children, hasContainer, ...props }) => {
    if (!hasContainer) return children

    return <Box {...props}>{children}</Box>
}

export const FormFieldContainer = ({
    name,
    as: FormField,
    hasInlineError = false,
    showError = true,
    ...props
}) => {
    const { control, getFieldState } = useFormContext()
    const errorMessage = getFieldState(name)?.error?.message

    return (
        <Container
            width={props?.fullWidth ? '100%' : 'unset'}
            hasContainer={showError}
            position="relative"
        >
            <Controller
                control={control}
                name={name}
                render={({ field }) => <FormField error={!!errorMessage} {...field} {...props} />}
            />
            {errorMessage && (
                <FormHelperText error helperText={errorMessage} isInline={hasInlineError} />
            )}
        </Container>
    )
}
