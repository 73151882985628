import React from 'react'
import styled from 'styled-components'
import { Pagination } from '@mui/material'
import { OctupColors } from '../../shared/theme/colors/OctupColors'

export const OctupPagination = ({ page, setPage, count }) => {
    return (
        <StyledPagination
            count={count}
            page={page}
            onChange={(event, newPage) => {
                setPage(newPage)
            }}
        />
    )
}

const StyledPagination = styled(Pagination)`
    button {
        // color: ${OctupColors.primaryActionableLight};
        color: black;
        font-size: 1.8rem;
        background-color: ${OctupColors.transparent};
        &:hover {
            background-color: ${OctupColors.onHover};
        }
    }

    div {
        font-size: 2rem;
        padding-top: 30px;
        @media screen and (min-width: 1400px) {
            padding-top: 40px;
        }
    }

    //for local envo
    .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        color: #fff;
        background-color: ${OctupColors.primaryActionableDark};
        &:hover {
            background-color: ${OctupColors.primaryActionableLight};
        }
    }

    //for server envo
    .css-1xr9krm.Mui-selected {
        color: #fff;
        background-color: ${OctupColors.primaryActionableDark};
        &:hover {
            background-color: ${OctupColors.primaryActionableLight};
        }
    }

    .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon {
        font-size: 3rem;
    }
`
