import styled from 'styled-components'
import InitialsAvatar from 'react-initials-avatar'
import { OctupColors } from '../../theme/colors/OctupColors'

const SMALL_RADIUS = '2.8rem;'
const LARGE_RADIUS = '5.8rem;'
const MEDIUM_RADIUS = '4rem;'

export const OctupAvatar = ({
    onClick,
    onMouseOver,
    text,
    imageSrc,
    title,
    userInitials,
    //"small" | "large" |"medium",
    size = 'small',
    initialsSize = '4.7rem',
    customSize,
}) => {
    return (
        <MainContainer customSize={customSize} size={size} src={imageSrc}>
            {imageSrc ? (
                <ProfileImageStyled draggable="false" src={imageSrc} alt="image not available" />
            ) : (
                title && (
                    <UserInitialsContainer initialsSize={initialsSize}>
                        <InitialsAvatar name={title} />
                    </UserInitialsContainer>
                )
            )}
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ${(props) =>
        props.customSize
            ? props.customSize
            : props.size === 'small'
              ? SMALL_RADIUS
              : props.size === 'medium'
                ? MEDIUM_RADIUS
                : LARGE_RADIUS};
    max-width: ${(props) =>
        props.customSize
            ? props.customSize
            : props.size === 'small'
              ? SMALL_RADIUS
              : props.size === 'medium'
                ? MEDIUM_RADIUS
                : LARGE_RADIUS};
    min-height: ${(props) =>
        props.customSize
            ? props.customSize
            : props.size === 'small'
              ? SMALL_RADIUS
              : props.size === 'medium'
                ? MEDIUM_RADIUS
                : LARGE_RADIUS};
    max-height: ${(props) =>
        props.customSize
            ? props.customSize
            : props.size === 'small'
              ? SMALL_RADIUS
              : props.size === 'medium'
                ? MEDIUM_RADIUS
                : LARGE_RADIUS};

    border-radius: 50%;
    overflow: hidden;
    box-shadow:
        -0.2rem -0.2rem 0.8rem #ffffff,
        0.2rem 0.2rem 0.4rem rgba(80, 75, 90, 0.16);
    border: 0.2rem solid #f6f5f9;
    background-color: ${(props) => (props.src ? 'transparent' : OctupColors.cardsBackground)};
`

const ProfileImageStyled = styled.img`
    border-radius: 50%;
    width: 100%;
    height: 100%;
`

const UserInitialsContainer = styled.div`
    //margin-right: 4rem;
    //width: 20%;
    margin: 0 auto;

    .initials-avatar {
        min-width: ${(p) => p.initialsSize} !important;
        min-height: ${(p) => p.initialsSize} !important;
        max-width: ${(p) => p.initialsSize} !important;
        max-height: ${(p) => p.initialsSize} !important;
        /*cursor: pointer;*/
        border-radius: 50%;
        background-color: #504b5a;
        display: inline-block;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .initials-avatar div {
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: inherit;
        font-weight: inherit;
        font-size: 1.6rem;
    }
`
