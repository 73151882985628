import React, { useState } from 'react'
import styled from 'styled-components'
import { OctupInputField } from '../../shared/ui/Inputs/OctupInputField'
import { MobileUserAvatar } from '../../components/MobileUserAvatar/MobileUserAvatar'
import { useDispatch, useSelector } from 'react-redux'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { EditIconSvg } from '../../shared/assets/icons/EditIconSvg'
import { ChevronLeftSvg } from '../../shared/assets/icons/ChevronLeftSvg'
import { useNavigate } from 'react-router-dom'
import { updateUserProfile } from '../../features/auth/authSlice'

export const MobileAccountPage = () => {
    const dispatch = useDispatch()

    const { userInfo } = useSelector((state) => state.auth)
    const navigate = useNavigate()

    const [isEditMode, setIsEditMode] = useState(false)
    const [name, setName] = useState(userInfo?.fullName ? userInfo?.fullName : '')
    const [nameEditMode, setNameEditMode] = useState(userInfo?.fullName ? userInfo?.fullName : '')
    const [email, setEmail] = useState(userInfo?.email ? userInfo?.email : '')
    const [emailEditMode, setEmailEditMode] = useState(userInfo?.email ? userInfo?.email : '')
    const [phone, setPhone] = useState(userInfo?.phone_number ? userInfo?.phone_number : '')
    const [phoneEditMode, setPhoneEditMode] = useState('')
    const [storeName, setStoreName] = useState(userInfo?.client?.name ? userInfo?.client?.name : '')
    const [storeNameEditMode, setStoreNameEditMode] = useState(
        userInfo?.client?.name ? userInfo?.client?.name : ''
    )
    const [password, setPassword] = useState('*********')

    const handleEditButtonClick = () => {
        setIsEditMode(!isEditMode)
    }

    const handleCancelChanges = () => {
        // console.log('preChangeName', preChangeName)
        // setName(preChangeName)
        // setEmail(preChangeEmail)
        // setPhone(preChangePhone)
        // setStoreName(preChangeStoreName)
        setIsEditMode(false)
    }

    const handleSaveChanges = async () => {
        setName(nameEditMode)
        setEmail(emailEditMode)
        setPhone(phoneEditMode)
        setStoreName(storeNameEditMode)
        setIsEditMode(false)

        await dispatch(
            updateUserProfile({
                fullName: nameEditMode,
                email: emailEditMode,
                phone_number: phoneEditMode,
                token: userInfo.token,
            })
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // Perform any desired actions with the form data
        console.log({
            name,
            email,
            phone,
            storeName,
            password,
        })
    }

    return (
        <MainContainer>
            <TopSideRow>
                {!isEditMode ? (
                    <TopRowButton type="button" onClick={() => navigate(-1)} width={'6vw'}>
                        <EditButtonImageContainer>
                            <ChevronLeftSvg active={true} />
                        </EditButtonImageContainer>
                    </TopRowButton>
                ) : (
                    <CancelButton onClick={() => handleCancelChanges()}>
                        <ButtonText>Cancel</ButtonText>
                    </CancelButton>
                )}

                <TopSideTitle>{isEditMode ? 'Edit Details' : 'My Account'}</TopSideTitle>
                <TopRowButton
                    type="button"
                    onClick={() => {
                        if (isEditMode) {
                            handleSaveChanges()
                        } else {
                            setIsEditMode(true)
                        }
                    }}
                    width={'6vw'}
                >
                    {!isEditMode ? (
                        <EditButtonImageContainer>
                            {!isEditMode ? <EditIconSvg /> : 'Save'}
                        </EditButtonImageContainer>
                    ) : (
                        <SaveButtom width={'6rem'}>
                            <SaveButtonText>Save</SaveButtonText>
                        </SaveButtom>
                    )}
                </TopRowButton>
            </TopSideRow>
            <MobileUserAvatar
                isFancy={false}
                imgSrc={userInfo?.imageSrc}
                name={userInfo?.fullName || ''}
            />
            <Title>Mobile Web Page</Title>
            <Form onSubmit={handleSubmit}>
                <OctupInputField
                    disabled={!isEditMode}
                    type="name"
                    placeholder={'Name'}
                    width={'90vw'}
                    value={isEditMode ? nameEditMode : name}
                    onChange={(e) => setNameEditMode(e.target.value)}
                    readOnly={!isEditMode}
                />
                <Divider />
                <OctupInputField
                    disabled={!isEditMode}
                    type="email"
                    width={'90vw'}
                    value={isEditMode ? emailEditMode : email}
                    onChange={(e) => setEmailEditMode(e.target.value)}
                    readOnly={!isEditMode}
                />
                <Divider />
                <OctupInputField
                    disabled={!isEditMode}
                    type="number"
                    placeholder={'Phone'}
                    width={'90vw'}
                    value={isEditMode ? phoneEditMode : phone}
                    onChange={(e) => setPhoneEditMode(e.target.value)}
                    readOnly={!isEditMode}
                />
                <Divider />
                <OctupInputField
                    type="text"
                    width={'90vw'}
                    value={storeName}
                    placeholder={'Shop Name'}
                    onChange={(e) => setStoreName(e.target.value)}
                    readOnly={true}
                />
                <Divider />
            </Form>
        </MainContainer>
    )
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`

const TopSideRow = styled(Row)`
    height: 4vh;
    width: 95vw;
`

const TopSideTitle = styled.h1`
    font-weight: 600;
    font-size: 2rem;
    line-height: 2rem;
    margin-left: 35vw;
    margin-top: 1vh;
    //margin-left: auto;
    display: flex;
    align-items: center;
    color: ${OctupColors.primaryTextLight};
    position: absolute;
`

const EditButtonImageContainer = styled.div``

const Divider = styled.div`
    border-bottom: black;
    border-width: 10px;
    height: 1px;
    background-color: #ebeaee;

    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    z-index: 999;
`

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-family: Arial, sans-serif;
`

const Title = styled.h1`
    margin-bottom: 20px;
`

const Form = styled.form`
    width: 90%;
    //overflow: hidden;
`

const Button = styled.button`
    width: ${(p) => (p.width ? p.width : '40vw')};
    background: #f8f7fb;
    box-shadow:
        -2px -2px 8px #ffffff,
        3px 3px 4px rgba(80, 75, 90, 0.16);
    border-radius: 8px;
    border-width: 0;
    border-color: #f8f7fb;
    height: 10%;
`

const SaveButtom = styled(Button)`
    background: linear-gradient(44.56deg, #007f82 -1.25%, #00a8a8 100.79%);
    width: 14vw;
    height: 3vh;
    margin-left: -7vw;
`

const ButtonText = styled.span`
    width: 100%;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${OctupColors.primaryActionableDark};
`

const SaveButtonText = styled(ButtonText)`
    color: white;
`

const TopRowButton = styled(Button)`
    right: 0;
    height: 80%;
    margin-top: 0.8vh;
`

const CancelButton = styled.div`
    margin-top: 1vh;
`
