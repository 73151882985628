export function GorgiasIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M19.2553 21H20.3149C20.5574 21 20.7745 20.783 20.7872 20.5404V8.74468C20.7872 7.58298 19.8681 6.6383 18.7319 6.6383H17.366C17.1234 6.6383 16.9064 6.42128 16.9064 6.17873V5.10638C16.9064 3.94468 15.9872 3 14.8511 3H6.05532C4.91915 3 4 3.93191 4 5.10638V16.966C4 18.1149 4.91915 19.0596 6.05532 19.0723H16.2426C16.3319 19.0723 16.4213 19.0979 16.4979 19.1489L18.9872 20.9362C19.0638 20.9745 19.166 21 19.2553 21ZM16.7787 17.3745H6.08085C5.82553 17.3745 5.62128 17.1702 5.62128 16.9149V5.13191C5.62128 4.8766 5.82553 4.67234 6.08085 4.67234H14.8255C15.0681 4.67234 15.2851 4.88936 15.2851 5.13191V6.17873C15.2851 6.43404 15.0809 6.6383 14.8255 6.6383H8.31489C8.07234 6.6383 7.85532 6.85532 7.85532 7.09787V14.9617C7.85532 15.217 8.05957 15.4213 8.31489 15.4213H16.4468C16.6894 15.4213 16.9064 15.2043 16.9064 14.9617V8.77021C16.9064 8.51489 17.1106 8.31064 17.366 8.31064H18.6936C18.9362 8.31064 19.1532 8.52766 19.1532 8.77021V18.4085C19.1532 18.6511 18.8979 18.7787 18.7191 18.6511L17.0596 17.4638C16.9574 17.4 16.8681 17.3745 16.7787 17.3745ZM15.0426 13.7362H9.70638C9.59149 13.7362 9.48936 13.6468 9.48936 13.5064V8.51489C9.48936 8.4 9.57872 8.28511 9.70638 8.28511H15.0426C15.1574 8.28511 15.2596 8.37447 15.2596 8.51489V13.5064C15.2596 13.634 15.1702 13.7362 15.0426 13.7362Z"
                fill="black"
            />
        </svg>
    )
}
