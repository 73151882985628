import styled from 'styled-components'
import { OctupColors } from 'shared/theme/colors/OctupColors'
import { NavLink as RouterNavLink } from 'react-router-dom'

export const Title = styled.div`
    font-size: 2rem;
    text-transform: capitalize;
    font-weight: 500;
    color: ${OctupColors.primaryTextLight};
    font-weight: ${({ fontWeight = '500' }) => fontWeight};
`

export const NavLink = styled(RouterNavLink)`
    font-size: inherit;
    font-weight: 600;
    color: ${OctupColors.primaryActionableDark};
    cursor: pointer;
`
