import type { PreloadedState } from '@reduxjs/toolkit'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

// TODO: old reducers, move into the new folder structure
import { authenticationSliceReducer, sessionSliceReducer } from 'features/auth/authSlice'
import { datePickerSliceReducer } from 'features/datePicker/datePickerSlice'
import insightSliceReducer from 'features/insights/insightSlice'
import { integrationsSliceReducer } from 'features/integrations/integrationsSlice'
import { metricsSliceReducer } from 'features/metrics/metricsSlice'
import { notificationsSliceReducer } from 'features/notifications/notificationsSlice'
import objectiveSliceReducer from 'features/objectives/objectiveSlice'
import { alternativeMarketingMetricsSliceReducer } from 'features/sections/alternativeMarketingSlice'
import { alternativeOperationsMetricsSliceReducer } from 'features/sections/alternativeOperationsSlice'
import { deepdivesSectionsSliceReducer } from 'features/sections/deepdivesSectionsSlice'
import { homePageMetricsSliceReducer } from 'features/sections/homePageSectionsSlice'
import { mobileHomePageMetricsSliceReducer } from 'features/sections/mobileHomePageSectionsSlice'
import { slaSliceReducer } from 'features/sections/slaSectionSlice'
import { toastsSliceReducer } from 'features/toasts/toastsSlice'
import { usersSliceReducer } from 'features/users/usersSlice'
import navigationSliceReducer from 'store/slices/navigation-slice'
import shopSliceReducers from 'store/slices/shop-slice'
import { simulatorSliceReducer } from 'store/slices/simulator-slice'

// new reducers
import { deleteUserSliceReducer } from '../features/users/deleteUserSlice'
import * as appConfig from './features/appConfig'
import { attributionReducer } from './features/attribution'
import * as costManager from './features/costManager'
import * as dashboards from './features/dashboards'
import * as integrations from './features/integrations'
import {
    logisticsReducer,
    warehouseCostReducer,
    warehouseCostsReportReducer,
    warehouseCountriesReducer,
    warehousesReducer,
} from './features/logistics'
import * as metrics from './features/metrics'
import { ordersReducer } from './features/orders'
import * as pages from './features/pages'
import { paymentSessionSliceReducer, paymentsSliceReducer } from './features/payments'
import * as settings from './features/settings'
import { signupReducer } from './features/signup'
import * as unitEconomics from './features/unitEconomics'
import { userConfigReducer } from './features/user-configurations'
import { whatsNewReducer } from './features/whatsNew'

const rootReducer = combineReducers({
    // old reducers
    navigation: navigationSliceReducer,
    insights: insightSliceReducer,
    users: usersSliceReducer,
    deleteUser: deleteUserSliceReducer,
    objectives: objectiveSliceReducer,
    auth: authenticationSliceReducer,
    session: sessionSliceReducer,
    datePicker: datePickerSliceReducer,
    shop: shopSliceReducers,
    homePageMetricsSection: homePageMetricsSliceReducer,
    mobileHomepageMetrics: mobileHomePageMetricsSliceReducer,
    deepdivesSection: deepdivesSectionsSliceReducer,
    alternativeOperationsMetrics: alternativeOperationsMetricsSliceReducer,
    alternativeMarketingMetrics: alternativeMarketingMetricsSliceReducer,
    metrics: metricsSliceReducer,
    simulator: simulatorSliceReducer,
    notifications: notificationsSliceReducer,
    toasts: toastsSliceReducer,
    sla: slaSliceReducer,

    // new reducers
    // TODO: add combineReducers for logistics reducers
    signup: signupReducer,
    appConfig: combineReducers({
        settings: appConfig.appSettingsReducer,
        excludedFeatures: appConfig.appExcludedFeaturesReducer,
    }),
    attribution: attributionReducer,
    orders: ordersReducer,
    payments: paymentsSliceReducer,
    paymentSession: paymentSessionSliceReducer,
    logistics: logisticsReducer,
    warehouses: warehousesReducer,
    warehouseCost: warehouseCostReducer,
    warehouseCountries: warehouseCountriesReducer,
    warehouseCostsReport: warehouseCostsReportReducer,
    userConfig: userConfigReducer,
    whatsNew: whatsNewReducer,
    unitEconomics: combineReducers({
        invoicesDates: unitEconomics.invoicesDatesReducer,
        products: unitEconomics.productsReducer,
    }),
    costManager: combineReducers({
        costs: costManager.costsReducer,
        updatedCost: costManager.updatedCostReducer,
        products: costManager.productsReducer,
    }),
    // TODO delete the new prefix after moving metrics into a new folder structure
    newMetrics: combineReducers({
        datePicker: metrics.datePickerReducer,
        metricsSections: metrics.metricsSectionsReducer,
        metricsSectionsList: metrics.metricsSectionsListReducer,
    }),
    dashboards: combineReducers({
        dashboard: dashboards.dashboardReducer,
        dashboardsById: dashboards.dashboardsByIdReducer,
        dashboardsList: dashboards.dashboardsListReducer,
        updatedDashboard: dashboards.updateDashboardReducer,
        deletedDashboard: dashboards.deleteDashboardReducer,
        templatesList: dashboards.templatesListReducer,
    }),
    integrations: combineReducers({
        integrationsList: integrationsSliceReducer,
        connectedExtension: integrations.integrationExtensionReducer,
        status: integrations.integrationsStatusReducer,
    }),
    pages: combineReducers({
        settingsByName: pages.pageSettingsByNameReducer,
    }),
    settings: combineReducers({
        email: settings.emailSettingsReducer,
        editedEmail: settings.editEmailSettingsReducer,
        clients: settings.clientsSettingsReducer,
        editedClient: settings.editClientSettingsReducer,
        workingHours: settings.workingHoursSettingsReducer,
        editedWorkingHours: settings.editWorkingHoursSettingsReducer,
    }),
})

export const setupStore = (preloadedState?: PreloadedState<RootReducers>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    })
}

export type RootReducers = typeof rootReducer
export type RootState = ReturnType<RootReducers>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
