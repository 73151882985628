export enum EMAIL_FREQUENCY_UNITS {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
}

export type EmailSettingsType = {
    email_enabled: boolean
    frequency: number
    unit: EMAIL_FREQUENCY_UNITS
}
