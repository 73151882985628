import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PageSettingsByNameType, PageSettingsType } from 'models/pages'
import { getPageSettingsByName } from 'services/pages'
import type { ThunkAPIConfigType } from 'services/utils'

type PageSettingsStateType = {
    data?: PageSettingsType
    isLoading: boolean
    error?: string
}

export const fetchPageSettingsByName = createAsyncThunk<
    PageSettingsType,
    keyof PageSettingsByNameType,
    ThunkAPIConfigType
>('fetchPageSettingsByName', getPageSettingsByName)

export const pageSettingsByNameSlice = createSlice({
    name: 'pageSettingsByName',
    initialState: {} as Record<keyof PageSettingsByNameType, PageSettingsStateType>,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPageSettingsByName.pending, (state, action) => {
                const name = action.meta.arg
                const newState = { ...state?.[name], isLoading: true, error: undefined }
                return { ...state, [name]: newState }
            })
            .addCase(fetchPageSettingsByName.fulfilled, (state, action) => {
                const name = action.meta.arg
                const newState = { data: action.payload, isLoading: false, error: undefined }
                return { ...state, [name]: newState }
            })
            .addCase(fetchPageSettingsByName.rejected, (state, action) => {
                const name = action.meta.arg
                const error = action.error.message
                const newState = { ...state?.[name], isLoading: false, error }
                return { ...state, [name]: newState }
            })
    },
})

export const pageSettingsByNameReducer = pageSettingsByNameSlice.reducer
