import { Title, NavLink } from './PageTitle.styles'

export const TitleBreadcrumb = ({ title, path, isLastItem, isMainPage, onClick }) => (
    <Title fontWeight={isMainPage ? '500' : '400'}>
        {isLastItem ? (
            title
        ) : (
            <NavLink to={path} onClick={onClick}>
                {title}
            </NavLink>
        )}
    </Title>
)
