import { useEffect } from 'react'
import { ERROR_MESSAGES } from '@/constants'
import { useQueryString, useToast } from '@/hooks'
import type { SignupSourceConfigType } from '@/models/signup'
import { shopifyNameLogo } from '@/new_assets/images'
import { SignupPage } from '@/new_pages'

const SIGNUP_OPTIONS: SignupSourceConfigType[] = [
    {
        id: 1,
        source: 'shopify',
        nameLogo: shopifyNameLogo,
    },
]

export const SignupPageContainer = () => {
    const toast = useToast()
    const { queryParams, cleanQueryParams } = useQueryString()

    useEffect(() => {
        if (queryParams.error) {
            toast.error(ERROR_MESSAGES.SERVER_ERROR)
            cleanQueryParams()
        }
    }, [cleanQueryParams, queryParams, toast])

    return <SignupPage options={SIGNUP_OPTIONS} />
}
