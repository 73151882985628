import { ColumnTopInnerContainer } from '../AlterativeHome.styles'
import { AlternativeHomeCardItem } from './base-components/AlternativeHomeCardItem'
import { OperationsLogoSvg } from '../../../shared/assets/icons'
import { SEGMENTS } from '../../../features/metrics/metricsSlice'
import { createDummys } from '../../../shared/utils/utils'
import { DICTIONARY_SECTIONS, getDictionaryValue } from '../../../shared/dictionary/dictionary'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'

export function OperationsCard({ className, setShowDetailsModal }) {
    const octupDispatch = useOctupDispatch()
    const { datePickerValues } = useSelector((state) => state.datePicker)

    const {
        alternativeOperationsMetrics,
        isLoading: isLoadingAlternativeOperationsMetrics,
        isRejected: isRejectedAlternativeOperationsMetrics,
    } = useSelector((state) => state.alternativeOperationsMetrics)

    useEffect(() => {
        octupDispatch.metrics.getAlternativeOperationsMetrics(
            datePickerValues.startDate,
            datePickerValues.endDate
        )
    }, [datePickerValues])

    return (
        <ColumnTopInnerContainer className={className}>
            {
                <AlternativeHomeCardItem
                    setShowDetailsModal={setShowDetailsModal}
                    mainIcon={OperationsLogoSvg}
                    isRejected={isRejectedAlternativeOperationsMetrics}
                    isLoading={isLoadingAlternativeOperationsMetrics}
                    segment={SEGMENTS.ALTERNATIVE_HOME_OPERATIONS}
                    metrics={alternativeOperationsMetrics}
                    title={getDictionaryValue('operations_home', DICTIONARY_SECTIONS.TITLES)}
                    position={'top'}
                />
            }
        </ColumnTopInnerContainer>
    )
}
