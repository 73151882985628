import { SERVER_ERROR_FIELD, ERROR_MESSAGES } from 'constants'
import { useCallback, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'

export const FormContainer = ({
    children,
    onSubmit,
    onSuccess,
    onFail,
    onValidationFail,
    defaultValues,
    validationSchema,
    mode = 'all',
    ...props
}) => {
    const formContext = useForm({
        mode,
        reValidateMode: mode,
        defaultValues,
        resolver: validationSchema ? yupResolver(validationSchema) : null,
    })

    const {
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
        reset,
    } = formContext

    useEffect(() => {
        reset(defaultValues)
    }, [defaultValues, reset])

    const handleSubmission = useCallback(
        (formValues) =>
            Promise.resolve(onSubmit(formValues, formContext)).then((data) => {
                if (data?.error) {
                    return onFail?.(data, formContext)
                }

                return onSuccess?.(data, formContext)
            }),
        [formContext, onFail, onSubmit, onSuccess]
    )

    const handleValidationErrors = useCallback(
        (formErrors) => onValidationFail?.(formErrors, formContext),
        [formContext, onValidationFail]
    )

    const handleSubmissionErrors = useCallback(
        (serverError) => {
            if (serverError) {
                console.error(serverError)
                const message = serverError.message || ERROR_MESSAGES.SERVER_ERROR
                setError(SERVER_ERROR_FIELD, { ...serverError, type: SERVER_ERROR_FIELD, message })
            }
        },
        [setError]
    )

    const handleFormSubmit = useCallback(
        (event) => {
            event.preventDefault()

            return handleSubmit(handleSubmission, handleValidationErrors)().catch(
                handleSubmissionErrors
            )
        },
        [handleSubmission, handleSubmissionErrors, handleSubmit, handleValidationErrors]
    )

    const handleResetServerError = useCallback(
        () => errors[SERVER_ERROR_FIELD] && clearErrors(SERVER_ERROR_FIELD),
        [clearErrors, errors]
    )

    return (
        <FormProvider onSubmit={handleFormSubmit} {...formContext}>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleFormSubmit}
                onChange={handleResetServerError}
                width="100%"
                {...props}
            >
                {children}
            </Box>
        </FormProvider>
    )
}
