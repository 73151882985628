import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const NotifyTypes = {
    success: 'success',
    warning: 'warning',
    error: 'error',
    info: 'info',
}

export const octupNotify = ({ message, type }) => {
    if (!type) {
        toast(message)
    } else {
        switch (type.toLowerCase()) {
            // case NotifyTypes.success:
            //   return toast.success(message);
            // case NotifyTypes.warning:
            //   return toast.warn(message);
            // case NotifyTypes.error:
            //   return toast.error(message);
            // case NotifyTypes.info:
            //   return toast.info(message);
            default:
                // return toast(message);
                return
        }
    }
}
