import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { octupDictionary } from '../../../shared/dictionary/dictionary'
import { useDispatch, useSelector } from 'react-redux'
import { OctupColors } from '../../../shared/theme/colors/OctupColors'
import { OctupBaseTable } from '../../../shared/ui/Tables/OctupBaseTable'
import { getInputDate } from '../../../shared/utils/dates-util'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChartsArea } from '../../../shared/ui/Charts/ChartsArea'
import { getMetricsBySegment, SEGMENTS } from '../../../features/metrics/metricsSlice'
import { OctupIcons } from '../../../shared/assets/OctupIcons'

import { getCurrencySymbol } from '../../../shared/utils/metrics-utils'
import {
    DATE_OPTIONS,
    datePickerActions,
    getDefaultDatePickerObj,
} from '../../../features/datePicker/datePickerSlice'
import { ChevronLeftSvg, EnlargeGraph } from '../../../shared/assets/icons'

export function MobileMetricsDetailsModal() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const { metrics, isLoading, isRejected } = useSelector((state) => state.metrics)
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const { segment, selectedDDMetric: locationSelectedDDMetric } = location.state
    const [selectedDDMetric, setSelectedDDMetric] = useState(locationSelectedDDMetric)
    const [isVertical, setIsVertical] = useState(true)
    const [selectedMetricData, setSelectedMetricData] = useState(null)
    const [showSelectMetricsList, setShowSelectMetricsList] = useState(false)

    const { TrendArrowUp, TrendArrowDown } = OctupIcons

    useEffect(() => {
        if (!segment || isLoading) {
            return
        }
        dispatch(
            getMetricsBySegment({
                segments: [SEGMENTS.MOBILE_HOME, 18],
                datePickerValues,
                dataset: 1,
            })
        )
    }, [datePickerValues])

    const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
        [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
        onWindowResize = () => {
            clearTimeout(window.resizeLag)
            window.resizeLag = setTimeout(() => {
                delete window.resizeLag
                setOrientation(isLandscape() ? 'landscape' : 'portrait')
            }, 200)
        }

    useEffect(
        () => (
            onWindowResize(),
            window.addEventListener('resize', onWindowResize),
            () => window.removeEventListener('resize', onWindowResize)
        ),
        []
    )

    useEffect(() => {
        if (!metrics[segment]) {
            onMetricSelection()
        }
    }, [datePickerValues, selectedDDMetric, segment])

    const rearrangedMetricArray = []

    if (selectedMetricData?.dataset?.length > 0) {
        rearrangedMetricArray.push({
            labels: selectedMetricData.dataset[selectedMetricData?.dataset?.length - 1][1],
            data: selectedMetricData.dataset[selectedMetricData?.dataset?.length - 1][0],
            change: 'Last Value',
        })
        for (let i = 1; i < selectedMetricData?.dataset?.length; ++i) {
            rearrangedMetricArray.push({
                title: selectedMetricData.dataset[i][1],
                labels: selectedMetricData.dataset[i][1],
                data: selectedMetricData.dataset[i][0],
                change:
                    parseFloat(
                        ((selectedMetricData.dataset[i - 1][0] - selectedMetricData.dataset[i][0]) *
                            100) /
                            selectedMetricData.dataset[i][0]
                    ).toFixed(2) + '%',
            })
        }
    }

    const onMetricSelection = () => {
        setSelectedMetricData(
            metrics &&
                metrics[segment]?.find((el) => {
                    return el?.metric?.metricTitle === selectedDDMetric
                })
        )
    }

    const onChangeDates = (datepickerValues) => {
        dispatch(
            datePickerActions.changeDates([
                {
                    ...datepickerValues,
                    key: 'selection',
                    color: '#007f82',
                },
            ])
        )
    }

    useEffect(() => {
        onMetricSelection()
    }, [selectedDDMetric, datePickerValues, isLoading, metrics])

    if (orientation === 'landscape') {
        return (
            <Div>
                <VerticalTitleContainer>
                    <HorizontalRow>
                        <HorizontalTopSideTitle
                            onClick={() => setShowSelectMetricsList(!showSelectMetricsList)}
                        >
                            {octupDictionary.titles.metricsTitles[selectedDDMetric]}
                        </HorizontalTopSideTitle>
                        <DatepickerButtonDiv
                            onClick={() =>
                                onChangeDates(
                                    getDefaultDatePickerObj({ label: DATE_OPTIONS.YESTERDAY })
                                )
                            }
                        >
                            <HorizontalTopSideTitle>Yesterday</HorizontalTopSideTitle>
                        </DatepickerButtonDiv>
                        <DatepickerButtonDiv
                            onClick={() =>
                                onChangeDates(
                                    getDefaultDatePickerObj({ label: DATE_OPTIONS.LAST_WEEK })
                                )
                            }
                        >
                            <HorizontalTopSideTitle>Last Week</HorizontalTopSideTitle>
                        </DatepickerButtonDiv>
                        <DatepickerButtonDiv
                            onClick={() =>
                                onChangeDates(
                                    getDefaultDatePickerObj({ label: DATE_OPTIONS.LAST_MONTH })
                                )
                            }
                        >
                            <HorizontalTopSideTitle>Last Month</HorizontalTopSideTitle>
                        </DatepickerButtonDiv>
                        <DatepickerButtonDiv
                            onClick={() =>
                                onChangeDates(
                                    getDefaultDatePickerObj({ label: DATE_OPTIONS.LAST_YEAR })
                                )
                            }
                        >
                            <HorizontalTopSideTitle>Last Year</HorizontalTopSideTitle>
                        </DatepickerButtonDiv>
                        <CancleHorizontalDiv onClick={() => setIsVertical(true)}>
                            <HorizontalTopSideTitle>X</HorizontalTopSideTitle>
                        </CancleHorizontalDiv>
                    </HorizontalRow>
                </VerticalTitleContainer>
                <VerticalChartContainer>
                    <ChartsArea
                        showGradient
                        style={{ height: '60%' }}
                        isVertical={true}
                        showXAxis={true}
                        showYAxis={true}
                        isFilled={false}
                        timeSlice={selectedMetricData?.metric?.time_slice}
                        backgroundColor={OctupColors.primaryTextLight}
                        chartsDataSets={[
                            {
                                label: selectedMetricData?.metric?.metricTitle,
                                data: selectedMetricData?.dataset?.map((e) => e[0]),
                            },
                        ]}
                        labels={selectedMetricData?.dataset?.map((e) => e[1])}
                        showTarget={true}
                        showLegend={false}
                    />
                </VerticalChartContainer>
            </Div>
        )
    } else if (isVertical) {
        return (
            <FlexBox>
                <TopRowContainer>
                    <TopSideRow>
                        <ColumnContainer>
                            <TopSideTitleContainer>
                                <RowContainer>
                                    <TopRowButton
                                        type="button"
                                        onClick={() => navigate(-1)}
                                        width={'6vw'}
                                    >
                                        <EditButtonImageContainer>
                                            <ChevronLeftSvg active={true} />
                                        </EditButtonImageContainer>
                                    </TopRowButton>
                                    <TitleContainer>
                                        <TopSideTitle
                                            onClick={() =>
                                                setShowSelectMetricsList(!showSelectMetricsList)
                                            }
                                        >
                                            {octupDictionary.titles.metricsTitles[selectedDDMetric]}
                                        </TopSideTitle>
                                    </TitleContainer>
                                    <TopRowButton
                                        type="button"
                                        onClick={() => setIsVertical(false)}
                                        width={'6vw'}
                                    >
                                        <EditButtonImageContainer>
                                            <EnlargeGraph active={true} />
                                        </EditButtonImageContainer>
                                    </TopRowButton>
                                </RowContainer>
                                {showSelectMetricsList && (
                                    <MetricsSelectionList>
                                        {metrics &&
                                            metrics[segment]?.map((d, i) => {
                                                return (
                                                    <ClickableOption
                                                        onClick={() => {
                                                            setSelectedDDMetric(
                                                                d?.metric?.metricTitle
                                                            )
                                                            setShowSelectMetricsList(false)
                                                        }}
                                                        key={i + d?.metric?.metricTitle}
                                                    >
                                                        <MetricsSelectionItemText>
                                                            {
                                                                octupDictionary.titles
                                                                    .metricsTitles[
                                                                    d?.metric?.metricTitle
                                                                ]
                                                            }
                                                        </MetricsSelectionItemText>
                                                    </ClickableOption>
                                                )
                                            })}
                                    </MetricsSelectionList>
                                )}
                            </TopSideTitleContainer>
                            <MetricValueContainer>
                                <MetricValue>
                                    {selectedMetricData?.metric?.value?.toLocaleString()}
                                    <MetricCurr>
                                        {getCurrencySymbol(selectedMetricData?.metric?.currency)}
                                    </MetricCurr>
                                </MetricValue>
                                <MetricTrendContainer>
                                    {selectedMetricData?.metric?.trendValue > 0 ? (
                                        <TrendArrowUp customColor={'green'} />
                                    ) : (
                                        <TrendArrowDown customColor={'red'} />
                                    )}
                                    <MetricTrend
                                        color={
                                            selectedMetricData?.metric?.trendValue > 0
                                                ? 'green'
                                                : 'red'
                                        }
                                    >
                                        {selectedMetricData?.metric?.trendValue?.toFixed(2)}
                                    </MetricTrend>
                                </MetricTrendContainer>
                            </MetricValueContainer>
                        </ColumnContainer>
                    </TopSideRow>
                </TopRowContainer>
                <ChartContainer>
                    <ChartInnerContainer>
                        <ChartsArea
                            showGradient
                            style={{ height: '100%' }}
                            showXAxis={true}
                            showYAxis={true}
                            isFilled={false}
                            timeSlice={selectedMetricData?.metric?.time_slice}
                            backgroundColor={OctupColors.primaryTextLight}
                            chartsDataSets={[
                                {
                                    label: selectedMetricData?.metric?.metricTitle,
                                    data: selectedMetricData?.dataset?.map((e) => e[0]),
                                },
                            ]}
                            labels={selectedMetricData?.dataset?.map((e) => e[1])}
                            showTarget={true}
                            showLegend={false}
                        />
                    </ChartInnerContainer>
                </ChartContainer>
                <MetricDetailsContainer>
                    {selectedDDMetric &&
                        !metrics.deepdives[selectedMetricData?.metric?.metricTitle] && (
                            <TableContainer>
                                <OctupBaseTable
                                    hiddenRowKey={'title'}
                                    showSearchFilter={false}
                                    customRowStyle={{ height: '5rem' }}
                                    showFilters={false}
                                    showCounter={false}
                                    showSelectedRow={false}
                                    sortingHeadersToDataMapping={{
                                        date: 'date',
                                        [selectedDDMetric]: 'data',
                                        change: 'change',
                                    }}
                                    defaultSortBy={{ title: 'date' }}
                                    tableData={{
                                        headers: [
                                            'date',
                                            octupDictionary.titles.metricsTitles[
                                                selectedMetricData?.metric?.metricTitle
                                            ],
                                            'change',
                                        ],
                                        // rows: [{labels: new Date(), dataset: "333"}, {labels: new Date(), dataset: "343"}],
                                        rows: rearrangedMetricArray,
                                    }}
                                    renderRows={(el) => {
                                        return (
                                            <>
                                                <td>
                                                    {getInputDate(
                                                        el.labels,
                                                        selectedMetricData?.metric?.time_slice
                                                    )}
                                                </td>
                                                <td>{el.data}</td>
                                                <StyledTd
                                                    color={
                                                        el.change.includes('-') ? 'red' : 'green'
                                                    }
                                                >
                                                    {el.change}
                                                </StyledTd>
                                            </>
                                        )
                                    }}
                                />
                            </TableContainer>
                        )}
                    {selectedMetricData &&
                        metrics.deepdives[selectedMetricData?.metric?.metricTitle] && (
                            <TableContainer>
                                <OctupBaseTable
                                    hiddenRowKey={'date'}
                                    showSearchFilter={false}
                                    customRowStyle={{ height: '5rem' }}
                                    showFilters={false}
                                    showCounter={false}
                                    showSelectedRow={false}
                                    sortingHeadersToDataMapping={(() => {
                                        let tmp = [
                                            ...metrics.deepdives[
                                                selectedMetricData.metric?.metricTitle
                                            ].headers.map((el) => {
                                                if (el.title !== 'change' && el.title !== 'date')
                                                    return el.title
                                            }),
                                        ].reduce((a, v) => {
                                            let key =
                                                octupDictionary.titles.metricsTitles[v] &&
                                                octupDictionary.titles.metricsTitles[v]
                                                    .toLowerCase()
                                                    .toString()
                                                    .replace(/\s/g, '')
                                            if (!octupDictionary.titles.metricsTitles[v])
                                                return { ...a }
                                            return {
                                                ...a,
                                                [key]: v,
                                            }
                                        })
                                        let ans = {
                                            ...tmp,
                                            change: 'change_precentage',
                                            date: 'date',
                                        }
                                        return ans
                                    })()}
                                    defaultSortBy={{ title: 'date' }}
                                    tableData={{
                                        headers: [
                                            ...metrics.deepdives[
                                                selectedMetricData.metric?.metricTitle
                                            ].headers.map((el) => {
                                                return el.title !== 'date' && el.title !== 'change'
                                                    ? octupDictionary.titles.metricsTitles[el.title]
                                                    : el.title
                                            }),
                                        ],
                                        rows: metrics.deepdives[
                                            selectedMetricData?.metric?.metricTitle
                                        ].rows,
                                    }}
                                    renderRows={(el) => {
                                        return (
                                            <>
                                                {metrics.deepdives[
                                                    selectedMetricData?.metric?.metricTitle
                                                ].headers.map((element) => {
                                                    if (element.title === 'date')
                                                        return (
                                                            <td>
                                                                {getInputDate(
                                                                    el.date,
                                                                    selectedMetricData?.metric
                                                                        ?.time_slice
                                                                )}
                                                            </td>
                                                        )
                                                    else if (element.title === 'change') {
                                                        let change_precentage = el.change_precentage
                                                        return (
                                                            <TdChangePrecentageStyled
                                                                val={+change_precentage}
                                                            >
                                                                {(change_precentage ||
                                                                    +change_precentage === 0) &&
                                                                !isNaN(+el.change_precentage)
                                                                    ? change_precentage > 0
                                                                        ? '+' +
                                                                          change_precentage +
                                                                          '%'
                                                                        : +change_precentage === 0
                                                                          ? change_precentage + '%'
                                                                          : change_precentage + '%'
                                                                    : ''}
                                                            </TdChangePrecentageStyled>
                                                        )
                                                    } else {
                                                        return (
                                                            <td>
                                                                {element.valueUnites === 2
                                                                    ? Math.round(
                                                                          el[element.title] * 100
                                                                      ) + '%'
                                                                    : el[element.title]}
                                                            </td>
                                                        )
                                                    }
                                                })}
                                            </>
                                        )
                                    }}
                                />
                            </TableContainer>
                        )}
                </MetricDetailsContainer>
            </FlexBox>
        )
    } else {
        return (
            <HideScrollBox>
                <HorizontalContainer>
                    <HorizontalTitleContainer>
                        <HorizontalRow>
                            <HorizontalTopSideTitle
                                onClick={() => setShowSelectMetricsList(!showSelectMetricsList)}
                            >
                                {octupDictionary.titles.metricsTitles[selectedDDMetric]}
                            </HorizontalTopSideTitle>
                            <DatepickerButtonDiv
                                onClick={() =>
                                    onChangeDates(
                                        getDefaultDatePickerObj({ label: DATE_OPTIONS.YESTERDAY })
                                    )
                                }
                            >
                                <HorizontalTopSideTitle>Yesterday</HorizontalTopSideTitle>
                            </DatepickerButtonDiv>
                            <DatepickerButtonDiv
                                onClick={() =>
                                    onChangeDates(
                                        getDefaultDatePickerObj({ label: DATE_OPTIONS.LAST_WEEK })
                                    )
                                }
                            >
                                <HorizontalTopSideTitle>Last Week</HorizontalTopSideTitle>
                            </DatepickerButtonDiv>
                            <DatepickerButtonDiv
                                onClick={() =>
                                    onChangeDates(
                                        getDefaultDatePickerObj({ label: DATE_OPTIONS.LAST_MONTH })
                                    )
                                }
                            >
                                <HorizontalTopSideTitle>Last Month</HorizontalTopSideTitle>
                            </DatepickerButtonDiv>
                            <DatepickerButtonDiv
                                onClick={() =>
                                    onChangeDates(
                                        getDefaultDatePickerObj({ label: DATE_OPTIONS.LAST_YEAR })
                                    )
                                }
                            >
                                <HorizontalTopSideTitle>Last Year</HorizontalTopSideTitle>
                            </DatepickerButtonDiv>
                            <CancleHorizontalDiv onClick={() => setIsVertical(true)}>
                                <HorizontalTopSideTitle>X</HorizontalTopSideTitle>
                            </CancleHorizontalDiv>
                        </HorizontalRow>
                    </HorizontalTitleContainer>
                    {showSelectMetricsList && (
                        <HorizontalMetricsSelectionList>
                            {metrics &&
                                metrics[segment]?.map((d, i) => {
                                    return (
                                        <ClickableOption
                                            onClick={() => {
                                                setSelectedDDMetric(d?.metric?.metricTitle)
                                                setShowSelectMetricsList(false)
                                            }}
                                            key={i + d?.metric?.metricTitle}
                                        >
                                            <MetricsSelectionItemText>
                                                {
                                                    octupDictionary.titles.metricsTitles[
                                                        d?.metric?.metricTitle
                                                    ]
                                                }
                                            </MetricsSelectionItemText>
                                        </ClickableOption>
                                    )
                                })}
                        </HorizontalMetricsSelectionList>
                    )}
                    <HorizontalChartContainer>
                        <ChartsArea
                            showGradient
                            isVertical={false}
                            showXAxis={true}
                            showYAxis={true}
                            isFilled={false}
                            timeSlice={selectedMetricData?.metric?.time_slice}
                            backgroundColor={OctupColors.primaryTextLight}
                            chartsDataSets={[
                                {
                                    label: selectedMetricData?.metric?.metricTitle,
                                    data: selectedMetricData?.dataset?.map((e) => e[0]),
                                },
                            ]}
                            labels={selectedMetricData?.dataset?.map((e) => e[1])}
                            showTarget={true}
                            showLegend={false}
                        />
                    </HorizontalChartContainer>
                </HorizontalContainer>
            </HideScrollBox>
        )
    }
}

const Div = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${OctupColors.loginbackground};
`

const FlexBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const TopRowContainer = styled.div`
    height: 15%;
    width: 100%;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`

const TopSideRow = styled(Row)`
    width: 100%;
`

const Button = styled.button`
    width: ${(p) => (p.width ? p.width : '40vw')};
    background: #f8f7fb;
    box-shadow:
        -2px -2px 8px #ffffff,
        3px 3px 4px rgba(80, 75, 90, 0.16);
    border-radius: 8px;
    border-width: 0;
    border-color: #f8f7fb;
    height: 85%;
    margin: 1rem;
`

const TopRowButton = styled(Button)`
    right: 0;
    height: 3.4vh;
    width: 4vh;
`

const ChartContainer = styled.div`
    height: 35vh;
    padding-bottom: 4vh;
    width: 100%;
    background-color: ${OctupColors.primaryTextLight};
    position: sticky;
    overflow-x: auto;
    overflow-y: hidden;
`

const ChartInnerContainer = styled.div`
    height: 100%;
    width: 200%;
    overflow-x: scroll !important;
`

const MetricDetailsContainer = styled.div`
    font-size: inherit;
    position: absolute;
    height: 45vh;
    bottom: 0;
    width: 100%;
    margin-right: -1rem;
    line-height: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0;
    overflow: hidden;
    margin-bottom: 1rem;
    border-radius: 30px;
    background-color: ${OctupColors.pageBackground};
`

const TdChangePrecentageStyled = styled.td`
    color: ${(props) => (props.val >= 0 ? '#078344' : '#DB2059')};
`

const TableContainer = styled.div`
    width: 100%;
`

const EditButtonImageContainer = styled.div``

const TopSideTitle = styled.span`
    font-weight: 600;
    font-size: 2rem;
    line-height: 2rem;
    margin-left: -3rem;
`

const TopSideTitleContainer = styled.div`
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: center;
`

const MetricsSelectionList = styled.div`
    position: absolute;
    z-index: 100;
    background-color: ${OctupColors.cardsBackground};
    box-shadow: 8px 8px 24px rgba(80, 75, 90, 0.08);
    border-radius: 8px;
    top: 8%;
    //height: 40%;
    width: 70%;
    display: flex;
    flex-direction: column;
`

const ClickableOption = styled.div`
    padding: 2rem;
`

const MetricsSelectionItemText = styled.span`
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 18px;
    color: #504b5a;
`

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const MetricValueContainer = styled.div`
    width: 90%;
    margin-left: 5%;
    padding-top: 5%;
    display: flex;
    justify-content: space-between;
`

const MetricValue = styled.span`
    font-style: normal;
    font-weight: 300;
    font-size: 4.5rem;
    line-height: 6rem;
    color: #504b5a;
`

const MetricCurr = styled.span`
    font-style: normal;
    font-weight: 275;
    font-size: 3rem;
    line-height: 6rem;
    color: #504b5a;
`

const MetricTrendContainer = styled.div``

const MetricTrend = styled.span`
    color: ${(p) => p.color};
    font-size: 2rem;
    line-height: 6rem;
`

const StyledTd = styled.td`
    color: ${(props) => props.color};
`

const TitleContainer = styled.div`
    width: 100%;
    margin-top: 2rem;
    text-align: center;
`

const HideScrollBox = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
`

const HorizontalContainer = styled.div`
    height: 100%;
    width: 100%;
    z-index: 1000;
    overflow: hidden;
    background-color: darkcyan;
`

const HorizontalChartContainer = styled.div`
    height: 100%;
    background-color: ${OctupColors.loginbackground};
    overflow: hidden;
    z-index: 2000;
`

const VerticalChartContainer = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${OctupColors.loginbackground};
    overflow: hidden;
`

const HorizontalTitleContainer = styled.div`
    position: absolute;
    top: 28vh;
    left: 40vw;
    rotate: 90deg;
    z-index: 100;
    width: 100%;
`

const VerticalTitleContainer = styled.div`
    z-index: 200;
    padding-top: 3rem;
    padding-left: 6rem;
    padding-bottom: 3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${OctupColors.loginbackground};
`

const HorizontalMetricsSelectionList = styled.div`
    position: absolute;
    rotate: 90deg;
    z-index: 100;
    margin-top: -1vw;
    margin-left: 24vw;
    height: 70vw;
    background-color: white;
    box-shadow: 8px 8px 24px rgba(80, 75, 90, 0.08);
    border-radius: 8px;
    overflow: auto;
    display: flex;
    flex-direction: column;
`

const HorizontalTopSideTitle = styled.span`
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2rem;
    color: white;
    z-index: 1500;
`

const CancleHorizontalDiv = styled.div`
    //position: absolute;
    //rotate: 90deg;
`

const DatepickerButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 10rem;
    margin-top: -2vw;
    background: ${OctupColors.datepickerButtons};
    box-shadow: 8px 8px 24px rgba(80, 75, 90, 0.08);
    border-radius: 8px;
    flex: none;
`

const HorizontalRow = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 85vh;
    height: 20vw;
`
