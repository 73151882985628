import styled from 'styled-components'
import { OctupColors } from '@/shared/theme/colors/OctupColors'

export const NavUpperMenu = styled.div`
    width: 100%;
`

export const Nav = styled.nav`
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => (props.isMainMenuCollapsed ? '5rem' : '24.2rem')};
    z-index: 200;
    background: linear-gradient(44.56deg, #504b5a -1.25%, #282831 100.79%);
    border-radius: 0 0 2.4rem 0;
    color: #fff;
`

export const NavHeaderContainer = styled.div`
    ${({ palette, paletteMode, isMainMenuCollapsed }) => ({
        ...(isMainMenuCollapsed
            ? {
                  backgroundColor: 'inherit',
              }
            : {
                  backgroundColor: palette.background.default,
                  ...(paletteMode === 'light' && {
                      borderRight: `0.1rem solid ${palette.divider}`,
                  }),
              }),
    })}
    display: flex;
    flex: 1;
    min-height: 7rem;
    height: fit-content;
    align-items: center;
    justify-content: flex-start;
    padding: 0.7rem;
`

export const NavBreak = styled.div`
    width: 90%;
    height: 0;
    padding: 0;
    border: 0.1rem solid #504b5a;
    position: relative;
    margin: 0 auto;
`

export const NavSystemMenu = styled.div`
    margin-bottom: 0rem;
    width: 100%;
`

export const NavHamborger = styled.div`
    position: absolute;
    right: 0rem;
    width: 5rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(${(props) => (props.isMainMenuCollapsed ? '3.4rem' : '0')});
    padding: 0.5rem 0;
`

export const GotItButton = styled.div`
    color: ${OctupColors.primaryActionableDark};
    position: absolute;
    right: 10rem;
    top: 20rem;
    width: 5rem;
    height: 2rem;
    text-align: center;
    line-height: 1.8rem;
    scale: 2;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid ${OctupColors.primaryActionableDark};
    border-radius: 5px;
`
