export function KlaviyoIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path d="M3 6H21L16.2 12L21 18H3V6Z" fill="#232426" />
        </svg>
    )
}
