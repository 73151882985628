import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { INTEGRATION_API_URL, setIntegrationAccounts } from 'services'

const INTEGRATION_INITIAL_STATE = {
    isLoading: false,
    error: null,
}

export const connectIntegrationAccounts = createAsyncThunk(
    INTEGRATION_API_URL,
    async (data, thunkAPI) => setIntegrationAccounts(data, thunkAPI)
)

export const integrationExtensionSlice = createSlice({
    name: 'integrationExtension',
    initialState: INTEGRATION_INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(connectIntegrationAccounts.pending, (state) => {
                state.isLoading = true
            })
            .addCase(connectIntegrationAccounts.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const integrationExtensionReducer = integrationExtensionSlice.reducer
