import { Stack, SvgIcon, Typography } from '@octup/core/ui-kit'
import { NoLinkIcon } from 'shared/assets/icons'

type ErrorMessageFallbackPropsType = {
    title?: string
    description?: string
}

export const ErrorMessageFallback = ({
    title = 'Something went wrong...',
    description = 'Refresh the page or try again shortly.',
}: ErrorMessageFallbackPropsType) => (
    <Stack width="100%" height="100%" alignItems="center" justifyContent="center" spacing={2}>
        <SvgIcon component={NoLinkIcon} fontSize="large" color="text.secondary" />

        <Stack alignItems="center">
            <Typography>{title}</Typography>
            <Typography>{description}</Typography>
        </Stack>
    </Stack>
)
