import React from 'react'
import { getIconColor } from './utils'

export function CommerceLogoSvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M22 6.63409L20.3718 15.6341C20.3406 15.8069 20.2499 15.9632 20.1157 16.0758C19.9814 16.1883 19.8121 16.25 19.6372 16.25H7.51772C7.34292 16.25 7.17367 16.1884 7.03944 16.0759C6.90521 15.9635 6.81452 15.8073 6.78316 15.6346L4.4196 2.61545C4.38825 2.44273 4.29755 2.28652 4.16333 2.17406C4.0291 2.0616 3.85985 2 3.68505 2H2"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.97331 21.5C8.79805 21.5 9.46664 20.8284 9.46664 20C9.46664 19.1716 8.79805 18.5 7.97331 18.5C7.14857 18.5 6.47998 19.1716 6.47998 20C6.47998 20.8284 7.14857 21.5 7.97331 21.5Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
            />
            <path
                d="M19.1733 21.5C19.998 21.5 20.6666 20.8284 20.6666 20C20.6666 19.1716 19.998 18.5 19.1733 18.5C18.3485 18.5 17.6799 19.1716 17.6799 20C17.6799 20.8284 18.3485 21.5 19.1733 21.5Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
            />
            <path
                d="M17.5454 6.88137L16.8062 12.1408C16.7845 12.2958 16.726 12.4392 16.6437 12.5394C16.5615 12.6396 16.4621 12.6884 16.3675 12.6751L9.94869 11.773C9.85412 11.7597 9.77207 11.6854 9.72059 11.5664C9.66912 11.4474 9.65244 11.2935 9.67422 11.1385L10.4134 5.87903"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.9998 5.00001L18 6"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    )
}
