import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { SettingsType, WorkingHoursSettingsType } from 'models/settings'
import type { ThunkAPIConfigType } from 'services/utils'
import { getWorkingHoursSettings } from '@/services/settings'
import { getWorkingHoursData } from './utils'

type WorkingHoursSettingsStateType = {
    data?: WorkingHoursSettingsType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: WorkingHoursSettingsStateType = {
    data: undefined,
    isLoading: false,
    error: undefined,
}

export const fetchWorkingHoursSettings = createAsyncThunk<
    SettingsType,
    undefined,
    ThunkAPIConfigType
>('fetchWorkingHoursSettings', (_, thunkAPI) => getWorkingHoursSettings(thunkAPI))

export const workingHoursSettingsSlice = createSlice({
    name: 'workingHoursSettings',
    initialState: INITIAL_STATE,
    reducers: {
        updateWorkingHoursSettings: (state, action) => {
            state.data = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkingHoursSettings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchWorkingHoursSettings.fulfilled, (state, action) => {
                state.data = getWorkingHoursData(action.payload)
                state.isLoading = false
            })
            .addCase(fetchWorkingHoursSettings.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const { updateWorkingHoursSettings } = workingHoursSettingsSlice.actions
export const workingHoursSettingsReducer = workingHoursSettingsSlice.reducer
