const GOOGLE_ADS_TITLE = 'Google Ads'

export const INTEGRATION_TITLES_MAP = {
    rakuten: 'Rakuten (ShipNetworks)',
    googleanalytics: 'Google Analytics',
    google: GOOGLE_ADS_TITLE,
    googleads: GOOGLE_ADS_TITLE,
    after_ship: 'After Ship',
    returngo: 'Return Go',
    amazonads: 'Amazon Ads',
    pdq_logistics: 'PDQ Logistics',
}
