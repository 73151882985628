import styled from 'styled-components'
import { OctupColors } from '../../shared/theme/colors/OctupColors'

export const textFieldStyle = (palette) => () => ({
    width: '50rem',
    '& .MuiInputBase-root': {
        color: palette.text.primary,
    },
})

const PageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    ${({ palette, paletteMode }) => ({
        color: palette.text.primary,
        backgroundColor: paletteMode === 'dark' ? palette.background.default : palette.common.white,
    })}
`

const LeftPageContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    padding: 2rem;
    @media only screen and (max-width: 1100px) {
        justify-content: center;
    }
`

const RightPageContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100vh;
    border-top-left-radius: 2vw;
    border-bottom-left-radius: 2vw;
    position: relative;
    background: rgba(248, 247, 251, 1);
    flex: 1;

    @media only screen and (max-width: 1100px) {
        display: none !important;
    }

    @media only screen and (max-width: 1600px) {
        border-bottom-left-radius: ${(props) => (props.currentStep === 2 ? 0 : '2vw')};
    }
`
const LeftPageBody = styled.div`
    height: 100vh;
    width: 100%;
    flex: 1;
    @media only screen and (max-width: 1100px) {
        width: fit-content;
    }
`
const LeftPageFooter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    padding: 2rem;
    height: 100%;
`

const RightPageHeader = styled.div``
const RightPageBody = styled.div``
const RightPageFooter = styled.div``
const LoginContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 0rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    flex: 1;
    @media only screen and (max-width: 1100px) {
        align-items: flex-start;
        justify-content: flex-start;
    }
`

const StyledTitle = styled.h1`
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.7rem;
    color: inherit;
`

const MessageText = styled.span`
    font-weight: 400;
    font-size: 2rem;
    line-height: 23px;
    color: inherit;
    white-space: pre-wrap;
`

const Line = styled.div`
    display: flex;
    flex-direction: row;
`

const LoginText = styled.span`
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 23px;
    color: #504b5a;
    white-space: pre-wrap;
    color: inherit;
`

const LinkText = styled(LoginText)`
    cursor: pointer;
    color: #007f82;
    font-size: 1.6rem;
    font-weight: 600;

    :hover {
        text-decoration: underline;
    }
`

const RightSideSvgInner = styled.img`
    position: absolute;
    left: 0.5vw;
    max-width: 34.6rem;
`

const RightSideSvgStep0 = styled.img`
    position: absolute;
    right: 0;
    height: auto;
    width: 105rem;

    @media only screen and (max-width: 1100px) {
        display: none;
    }

    @media only screen and (max-width: 1300px) {
        right: -30vw !important;
        top: -10vw !important;
    }

    @media only screen and (max-width: 1536px) {
        right: -20vw !important;
        top: -8vw !important;
    }

    @media only screen and (max-width: 1800px) {
        right: -2vw !important;
        top: -8vw !important;
    }
`

const RightSideSvgStep2 = styled.img`
    width: 100%;
    height: 100%;
`

const RightSideTextContainer = styled.div`
    position: absolute;
    top: 40%;
    left: 12rem;
`

const PaddedOctupLogo = styled.div`
    align-self: flex-start;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 100px;
`

const StyledButton = styled.button`
    font-size: 2.2rem;
    width: 50rem;
    text-transform: none !important;
    height: 6rem !important;
    padding: 2rem;
    background: linear-gradient(
        44.56deg,
        ${OctupColors.primaryActionableDark} -1.25%,
        ${OctupColors.primaryActionableLight} 100.79%
    );

    box-shadow:
        -2px -2px 8px #ffffff,
        2px 2px 4px rgba(80, 75, 90, 0.16);
    border-radius: 0.8rem !important;

    :disabled {
        background: #c2c0c7;
        box-shadow:
            -2px -2px 8px #ffffff,
            2px 2px 4px rgba(80, 75, 90, 0.16);
    }

    :hover {
        background: #00a8a8 !important;
        transition: none !important;
    }
`

const SignupLingDiv = styled.div`
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.8rem;
    display: flex;
    align-items: center;
    color: #007f82;
    cursor: pointer;
`

const BackToLoginDiv = styled(SignupLingDiv)``

const TitleDiv = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
`

export {
    TitleDiv,
    StyledButton,
    PaddedOctupLogo,
    RightSideTextContainer,
    RightSideSvgStep2,
    RightSideSvgStep0,
    RightSideSvgInner,
    LinkText,
    LoginText,
    Line,
    SignupLingDiv,
    MessageText,
    StyledTitle,
    LoginContainer,
    RightPageFooter,
    RightPageBody,
    RightPageHeader,
    LeftPageContainer,
    BackToLoginDiv,
    LeftPageBody,
    LeftPageFooter,
    RightPageContainer,
    PageContainer,
}
