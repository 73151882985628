import { useCallback } from 'react'
import { PlusIcon } from '@octup/core/assets/icons'
import { ButtonGroup, SvgIcon, Button } from '@octup/core/ui-kit'
import { APP_ROUTES } from 'models/appConfig'
import { SlidersIcon } from 'new_assets/icons'
import { useNavigate } from 'react-router'

export const CostManagerButtonContainer = ({ onToggleCostModal }) => {
    const navigate = useNavigate()

    const handleLeftSideClick = useCallback(
        () => navigate(`/${APP_ROUTES.UNIT_ECONOMICS_COST_MANAGER}`),
        [navigate]
    )

    return (
        <ButtonGroup variant="secondary">
            <Button onClick={handleLeftSideClick} startIcon={<SvgIcon component={SlidersIcon} />}>
                cost manager
            </Button>
            <Button type="icon" onClick={onToggleCostModal}>
                <SvgIcon component={PlusIcon} />
            </Button>
        </ButtonGroup>
    )
}
