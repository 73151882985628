import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOctupDispatch } from '@/hooks'
import { DEFAULT_ROUTES } from '@/models/appConfig'
import { ExpiredModal } from '@/new_components/__common__'

export const ExpiredModalContainer = () => {
    const navigate = useNavigate()
    const octupDispatch = useOctupDispatch()

    const handleLogout = useCallback(() => {
        octupDispatch.auth.logout()
        navigate(DEFAULT_ROUTES.MAIN)
    }, [navigate, octupDispatch.auth])

    return <ExpiredModal onLogout={handleLogout} />
}
