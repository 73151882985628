import React from 'react'

export function EditIconSvg({ active, customActiveColor = '#007F82', customColor = '#007F82' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8751 5.33357C14.0887 5.11999 14.3783 5 14.6804 5C14.9824 5 15.2721 5.11999 15.4857 5.33357L18.6664 8.51431C18.88 8.72789 19 9.01756 19 9.31961C19 9.62165 18.88 9.91133 18.6664 10.1249L9.95813 18.8332C9.85134 18.94 9.7065 19 9.55548 19H6.13887C5.83682 19 5.54715 18.88 5.33357 18.6664C5.11999 18.4529 5 18.1632 5 17.8611V14.6804C5 14.5308 5.02946 14.3827 5.08669 14.2446C5.14392 14.1064 5.22781 13.9808 5.33357 13.8751L13.8751 5.33357ZM17.8611 9.31961L14.6804 6.13887L6.13887 14.6804L5.74074 14.2823L6.13887 14.6804L6.13887 17.8611H9.31961L17.8611 9.31961Z"
                fill={active ? customActiveColor : customColor ? customColor : '#007F82'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7.20878C12.2224 6.9864 12.583 6.9864 12.8053 7.20878L16.7914 11.1948C17.0138 11.4172 17.0138 11.7777 16.7914 12.0001C16.569 12.2225 16.2085 12.2225 15.9861 12.0001L12 8.01408C11.7777 7.7917 11.7777 7.43115 12 7.20878Z"
                fill={active ? customActiveColor : customColor ? customColor : '#007F82'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.20316 14.0779C5.42554 13.8555 5.78608 13.8555 6.00846 14.0779L9.95827 18.0277C10.1807 18.2501 10.1807 18.6107 9.95827 18.833C9.7359 19.0554 9.37535 19.0554 9.15297 18.833L5.20316 14.8832C4.98078 14.6608 4.98078 14.3003 5.20316 14.0779Z"
                fill={active ? customActiveColor : customColor ? customColor : '#007F82'}
            />
        </svg>
    )
}
