import React from 'react'

export function MobileFeedbackIcon({
    active,
    disabled = false,
    customActiveColor = '#007F82',
    customColor = '#007F82',
}) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.97959 15.5713C5.10952 14.1046 4.80482 12.3707 5.12269 10.6952C5.44057 9.01974 6.35915 7.51793 7.70596 6.47179C9.05277 5.42566 10.7351 4.90716 12.4372 5.01367C14.1392 5.12018 15.7438 5.84437 16.9497 7.05025C18.1556 8.25612 18.8798 9.86074 18.9863 11.5628C19.0928 13.2648 18.5744 14.9472 17.5282 16.294C16.4821 17.6408 14.9803 18.5594 13.3048 18.8773C11.6293 19.1952 9.89542 18.8905 8.4287 18.0204L8.42871 18.0204L6.01004 18.7114C5.90997 18.74 5.80408 18.7413 5.70333 18.7152C5.60259 18.6891 5.51065 18.6365 5.43706 18.5629C5.36347 18.4893 5.3109 18.3974 5.28479 18.2967C5.25869 18.1959 5.26 18.09 5.28859 17.99L5.97964 15.5713L5.97959 15.5713Z"
                stroke="#F8F7FB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.66864 10.8301H14.3373"
                stroke="#F8F7FB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.66858 14H12.3372"
                stroke="#F8F7FB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
