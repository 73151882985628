import { Grid } from '@mui/material'
import { CHART_BY_TYPE, CHART_TYPES } from 'constants/charts'
import { DoughnutChartCardContainer } from 'new_containers/components/Logistics'
import { ChartCard } from '../ChartCard'

export const DASHBOARD_CHART_BY_TYPE = {
    ...CHART_BY_TYPE,
    [CHART_TYPES.DOUGHNUT]: DoughnutChartCardContainer,
}

export const Dashboard = ({ charts }) => (
    <Grid container spacing="3rem">
        {charts.map((chart) => (
            <ChartCard
                key={chart.id}
                chartComponent={DASHBOARD_CHART_BY_TYPE[chart.chartConfig.type]}
                {...chart}
            />
        ))}
    </Grid>
)
