import { DateStyled, NavItems } from './WhatsNewNavItems.styles'
import { WhatsNewNavItemContainer } from '../../../new_containers/components/WhatsNew'

export const WhatsNewNavItems = ({ folder, content, version }) => {
    return (
        <div>
            <DateStyled>{`${folder}`}</DateStyled>
            <NavItems>
                {content.map((slide, index) => (
                    <WhatsNewNavItemContainer
                        folder={folder}
                        index={index}
                        slide={slide}
                        version={version}
                    />
                ))}
            </NavItems>
        </div>
    )
}
