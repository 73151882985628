import { useMemo } from 'react'
import type { AutocompleteOwnerState, AutocompleteRenderOptionState } from '@mui/material'
import { Stack, Checkbox, ListItemImage, OverflowTypography } from '@octup/core/ui-kit'
import { checkIsAllOption, checkIsAllSelected } from '../Autocomplete.utils'
import type {
    AutocompleteOptionsType,
    AutocompleteOptionType,
    AutocompleteValueType,
} from '../models'

const IMAGE_SIZE = 6.5

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ListItemAnyPropsType = React.HTMLAttributes<HTMLLIElement> & { key: any }

export type ListItemPropsType = ListItemAnyPropsType & {
    option: AutocompleteOptionType
    state: AutocompleteRenderOptionState
    ownerState: AutocompleteOwnerState<AutocompleteOptionType, boolean, boolean, boolean>
}

type OwnerStateSlotPropsType = {
    slotProps: {
        option: {
            getOptionImage?: (option: AutocompleteValueType) => string
        }
    }
}

export const ListItem = ({ option, state, ownerState, ...props }: ListItemPropsType) => {
    const { value, options, multiple, getOptionLabel } = ownerState

    const {
        slotProps: {
            option: { getOptionImage },
        },
    } = ownerState as unknown as OwnerStateSlotPropsType

    const label = useMemo(() => getOptionLabel(option), [getOptionLabel, option])
    const imgSrc = useMemo(() => getOptionImage?.(option), [getOptionImage, option])
    const isAllOption = useMemo(() => checkIsAllOption(option), [option])
    const isAllSelected = useMemo(
        () =>
            isAllOption &&
            checkIsAllSelected(value as AutocompleteValueType, options as AutocompleteOptionsType),
        [isAllOption, options, value]
    )

    return (
        <Stack
            {...props}
            direction="row"
            alignItems="center"
            spacing={2}
            component="li"
            aria-selected={props['aria-selected'] || isAllSelected}
        >
            {multiple && <Checkbox size="small" checked={state.selected || isAllSelected} />}
            {getOptionImage && (
                <>
                    {isAllOption ? (
                        <ListItemImage type="all" size={IMAGE_SIZE} />
                    ) : (
                        <ListItemImage src={imgSrc} alt={label} size={IMAGE_SIZE} />
                    )}
                </>
            )}
            <OverflowTypography variant="subtitle1">{label}</OverflowTypography>
        </Stack>
    )
}
