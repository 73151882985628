import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from '@/hooks'
import { CostModal } from '@/new_components/__common__'
import { fetchCostManageProducts, fetchUpdateCost } from '@/state/features/costManager'
import { DiscardChangesModalContainer } from '../DiscardChangesModalContainer'
import { FormContainer } from '../FormContainer'
import {
    VALIDATION_SCHEMA,
    convertToAPI,
    getActionButtons,
    getDefaultValues,
} from './CostModalContainer.utils'

export const CostModalContainer = ({
    showCostModal,
    selectedEl,
    isEditState,
    onClose,
    onSuccess,
}) => {
    const toast = useToast()
    const dispatch = useDispatch()
    const loadingToastRef = useRef()
    const { userInfo } = useSelector((state) => state.auth)
    const { data, isLoading } = useSelector((state) => state.costManager.products)
    const [showDiscardModal, setShowDiscardModal] = useState(false)
    const toggleDiscardModal = useCallback(() => setShowDiscardModal((open) => !open), [])
    const defaultValues = useMemo(() => getDefaultValues(selectedEl), [selectedEl])
    const products = useMemo(() => Object.values(data?.products || []), [data?.products])

    useEffect(() => {
        if (showCostModal && !products.length) {
            dispatch(fetchCostManageProducts())
        }
    }, [dispatch, products.length, showCostModal])

    const handleSubmit = useCallback(
        (data) => {
            onClose()
            const action = isEditState ? 'updating' : 'creating'
            const loadingMessage = `Hold on, we’re ${action} '${data.cost_name}' cost`
            loadingToastRef.current = toast.process(loadingMessage)
            return dispatch(fetchUpdateCost(convertToAPI({ data, products, selectedEl })))
        },
        [dispatch, onClose, isEditState, products, selectedEl, toast]
    )

    const handleSuccess = useCallback(
        async (data) => {
            await onSuccess()
            loadingToastRef.current && toast.removeToast(loadingToastRef.current)
            const { cost_name } = data.meta.arg
            const action = isEditState ? 'updated' : 'created'
            toast.success(`'${cost_name}' cost has been ${action} successfully`)
        },
        [isEditState, onSuccess, toast]
    )

    const actionButtons = useMemo(
        () => getActionButtons({ isEditState, onCancel: toggleDiscardModal }),
        [toggleDiscardModal, isEditState]
    )

    const renderContent = useCallback(
        ({ content }) => (
            <FormContainer
                onSubmit={handleSubmit}
                onSuccess={handleSuccess}
                defaultValues={defaultValues}
                validationSchema={VALIDATION_SCHEMA}
            >
                {content}
            </FormContainer>
        ),
        [defaultValues, handleSubmit, handleSuccess]
    )

    return (
        <>
            {showCostModal && (
                <>
                    <CostModal
                        isEditState={isEditState}
                        products={products}
                        shop_currency={userInfo?.client?.currency}
                        isLoading={isLoading}
                        renderContent={renderContent}
                        onDismissModal={toggleDiscardModal}
                        buttons={actionButtons}
                    />
                    <DiscardChangesModalContainer
                        showDiscardModal={showDiscardModal}
                        onDiscard={onClose}
                        onCancel={toggleDiscardModal}
                    />
                </>
            )}
        </>
    )
}
