import axios from 'axios'
import { generateConfig } from '@/shared/utils/utils'
import { AUTH_API_URL, USER_API_URL } from './authSlice'

let expiredSession = false

export const getExpiredSession = () => {
    return expiredSession
}

export const setExpiredSession = (newState) => {
    expiredSession = newState
    return expiredSession
}

//register new octup user
const register = async (userData) => {
    const response = await axios.post(`${AUTH_API_URL}`, userData)
    if (response.data) {
        localStorage.setItem('octupUser', JSON.stringify(response.data))
    }
    return response.data
}

//login to octup
const login = async (userData) => {
    const body = { email: userData.email, password: userData.password }

    const response = await axios.post(`${AUTH_API_URL}/login`, body, {
        withCredentials: false,
    })

    return response.data
}

const signup = async (userInfo) => {
    const { email, password, company_id, utmData } = userInfo.data || {}
    const body = {
        email,
        password,
        company_id,
        utm_source: utmData,
    }
    const response = await axios.post(`${AUTH_API_URL}/signup/register`, body)
    localStorage.setItem('userInfo', JSON.stringify(response.data))
    return response.data
}

const validateEmail = async (userinfo) => {
    const body = { email: userinfo.email }
    const response = await axios.post(`${AUTH_API_URL}/email/validate`, body)
    return response.data
}

const forgotPassword = async (userinfo) => {
    const body = { email: userinfo.email }
    const response = await axios.post(`${AUTH_API_URL}/forgot/password`, body)
    return response.data
}

const getUserProfile = async (token) => {
    const config = generateConfig(token)
    const response = await axios.get(`${USER_API_URL}/user/profile`, config)
    return response.data
}

const updateUI = async (token, userInfo) => {
    const config = generateConfig(token)
    const response = await axios.post(`${USER_API_URL}/user/update_ui`, userInfo, config)
    return response.data
}

const getClientInfo = async (token) => {
    const config = generateConfig(token)
    const response = await axios.get(`${USER_API_URL}/client`, config)

    return response.data
}

const resetPassword = async ({ passwordResetToken, password }) => {
    const params = {
        pw_rs_tk: passwordResetToken,
    }
    const response = await axios.post(`${AUTH_API_URL}/password_reset`, { password }, { params })
    return response.data
}

const logout = async (token) => {
    const config = generateConfig(token)
    localStorage.removeItem('userInfo')
    expiredSession = false
    const response = await axios.post(`${AUTH_API_URL}/logout`, {}, config)
    return response.data
}

const otpGenerate = async (token) => {
    const config = generateConfig(token)
    const response = await axios.post(`${AUTH_API_URL}/otp/generate`, {}, config)
    return response.data
}

const sendStoreName = async (userInfo) => {
    const config = generateConfig(userInfo.token)
    const response = await axios.get(`${AUTH_API_URL}/shopify`, {
        ...config,
        params: { shop: userInfo.storeName, email: userInfo.email },
    })
    return response.data
}

const sendInvites = async ({ emails, token }) => {
    const config = generateConfig(token)
    const response = await axios.post(
        `${USER_API_URL}/user/invite`,
        {
            members: emails,
        },
        { ...config }
    )
    return response.data
}

// same API {fullName, role, initials, imageSrc, email, phone_number, intro}
const updateUserProfile = (token, userInfo) => updateUI(token, userInfo)
const updateIntro = (token, userInfo) => updateUI(token, userInfo)

export const authService = {
    register,
    login,
    logout,
    getUserProfile,
    updateUserProfile,
    getClientInfo,
    resetPassword,
    signup,
    validateEmail,
    forgotPassword,
    otpGenerate,
    sendStoreName,
    updateIntro,
    updateUI,
    sendInvites,
}
