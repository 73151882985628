import styled from 'styled-components'
import { OctupButton } from '../../../shared/ui/Buttons/OctupButton'
import Dropdown from '../../../shared/ui/Dropdown/Dropdown'
import { OctupTextArea } from '../../../shared/ui/Inputs/OctupTextArea'
import { OctupBaseModal } from '../../../shared/ui/Modal/OctupBaseModal'
import { sendFeedback } from '../../../features/general/generalService'
import { useToast } from '../../../hooks/useToastContext'
import React from 'react'
import { NotifyTypes, octupNotify } from '../../../shared/notification/OctupToaster'
import { octupDictionary } from '../../../shared/dictionary/dictionary'
import { OctupColors } from '../../../shared/theme/colors/OctupColors'

export const SendFeedbackModal = ({ onDissmiss, setOpenModal, userToken }) => {
    const [feedback, setFeedback] = React.useState('')
    const [showEmptyFeedbackMessage, setShowEmptyFeedbackMessage] = React.useState(false)
    const toast = useToast()

    const buttons = [
        {
            title: 'cancel',
            type: 'button',
            variant: 'secondary',
            onClick: () => {
                onDissmiss(false)
            },
        },
        {
            title: 'send',
            type: 'button',
            variant: 'contained',
            onClick: () => {
                try {
                    if (feedback.length === 0) {
                        setShowEmptyFeedbackMessage(true)
                        return
                    }
                    sendFeedback(feedback, userToken)
                    toast.success(
                        `Your feedback has been sent successfully. \n
            Thanks for sharing ❤️`
                    )
                    onDissmiss(false)
                } catch (error) {
                    octupNotify({
                        message: error.message,
                        type: NotifyTypes.info,
                    })
                }
            },
        },
    ]

    return (
        <OctupBaseModal
            onDismissModal={() => onDissmiss(false)}
            buttons={buttons}
            isForm={true}
            title={'Send us your Feedback'}
        >
            <MainContainer>
                {/* <Dropdown
          titlePostfix=" Subject"
          title="Select"
          options={[]}></Dropdown> */}
                <InputContainer>
                    <OctupTextArea
                        placeholder="Your feedback goes here"
                        value={feedback}
                        onChange={(e) => {
                            setShowEmptyFeedbackMessage(false)
                            setFeedback(e.target.value)
                        }}
                        isErrored={showEmptyFeedbackMessage}
                        onFocus={() => setShowEmptyFeedbackMessage(false)}
                        width="98%"
                        rows={'5'}
                    />
                    {showEmptyFeedbackMessage && (
                        <EmptyFeedback>{octupDictionary.messages.emptyFeedback}</EmptyFeedback>
                    )}
                </InputContainer>
            </MainContainer>
        </OctupBaseModal>
    )
}

const MainContainer = styled.div`
    width: 80rem;
    /* height: 29rem; */
    padding-top: 2.4rem;
`

const InputContainer = styled.div`
    width: 100%;
    height: 18rem;
    /* padding-top: 3rem; */
    /* padding-left: 2rem; */
`

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    float: right;
    gap: 2rem;
    padding-right: 1.8rem;
`

const EmptyFeedback = styled.span`
    color: ${OctupColors.alertLight};
    font-size: 1.7rem;
    line-height: 2.4rem;
    font-weight: 400;
`
