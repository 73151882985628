import { Typography } from '@mui/material'
import { ShopCurrencyValueContainer } from 'new_containers/components/__common__'
import { DICTIONARY_SECTIONS, getDictionaryValue } from 'shared/dictionary/dictionary'
import { OctupColors } from 'shared/theme/colors/OctupColors'
import { getShortTableDate } from 'shared/utils/dates-util'

const TABLE_HEADERS = ['Order Name', 'Date', 'Customer', 'Destination', 'Value']

const SORT_HEADERS = {
    ordername: 'order_name',
    date: 'order_created_at',
    customer: 'customer_full_name',
    destination: 'country',
    value: 'total_price',
}

const SEARCH_FIELDS = {
    order_name: 'order_name',
    customer_full_name: 'customer_full_name',
    country: 'country',
}

const TABLE_CELLS = [
    {
        id: 'order_name',
        field: 'order_name',
        fontWeight: 600,
        color: OctupColors.primaryActionableDark,
    },
    {
        id: 'order_created_at',
        field: 'order_created_at',
        whiteSpace: 'nowrap',
        renderCell: ({ order_created_at }) => getShortTableDate(order_created_at).primaryFormat,
    },
    {
        id: 'customer_full_name',
        field: 'customer_full_name',
    },
    {
        id: 'country',
        field: 'country',
        renderCell: ({ country }) => country?.split(',').join(', '),
    },
    {
        id: 'total_price',
        field: 'total_price',
        whiteSpace: 'nowrap',
        renderCell: ({ total_price }) => <ShopCurrencyValueContainer value={total_price} />,
    },
]

export const getSlaTableConfig = ({ data, selectedMetric }) => {
    const count = data.metric?.length

    return {
        rowKeyField: 'order_id',
        hiddenRowKey: 'order_id',
        data: data.metric,
        tableCells: TABLE_CELLS,
        tableHeaders: TABLE_HEADERS,
        searchFields: SEARCH_FIELDS,
        sortHeaders: SORT_HEADERS,
        showCounter: true,
        customCounter: (
            <Typography fontWeight="600">
                {`Current ${getDictionaryValue(
                    selectedMetric.metricTitle,
                    DICTIONARY_SECTIONS.METRICS_TITLES
                )?.toLowerCase()}: ${count > 1000 ? '+1000' : count}`}
            </Typography>
        ),
    }
}
