import { Button } from '@mui/material'
import styled from 'styled-components'
import { OctupColors } from '../theme/colors/OctupColors'

export const Flex = styled.div`
    display: flex;
`

export const Row = styled(Flex)`
    flex-direction: row;
`

export const WideRow = styled(Row)`
    flex-direction: row;
    width: 100%;
`

export const Column = styled(Flex)`
    flex-direction: column;
`

export const SpaceBetween = styled(Flex)`
    justify-content: space-between;
    width: 100%;
`

export const ErrorDiv = styled.div`
    position: relative;
    bottom: 1rem;
    z-index: 100;
    width: 100%;
`

export const ErrorMessage = styled.div`
    width: 100%;
    color: #db2059;
    font-size: 1.5rem;
    line-height: 0;
    text-align: left;
    position: absolute;
`

export const StyledLargeButton = styled(Button)`
    width: ${(props) => (props.width ? props.width : '50rem')};
    text-transform: none !important;
    height: 6rem;
    background: linear-gradient(
        44.56deg,
        ${OctupColors.primaryActionableDark} -1.25%,
        ${OctupColors.primaryActionableLight} 100.79%
    );

    box-shadow:
        -2px -2px 8px #ffffff,
        2px 2px 4px rgba(80, 75, 90, 0.16);
    border-radius: 0.8rem !important;

    :disabled {
        background: #c2c0c7;
        box-shadow:
            -2px -2px 8px #ffffff,
            2px 2px 4px rgba(80, 75, 90, 0.16);
    }
`

export const StyledLargeButtonText = styled.span`
    font-size: 2.4rem;
    color: ${(props) => (props.color ? props.color : 'white')};
`

export const LightStyledButton = styled(Button)`
    width: ${(props) => (props.width ? props.width : '10rem')};
    background: #f8f7fb;
    box-shadow:
        -2px -2px 8px #ffffff,
        2px 2px 4px rgba(80, 75, 90, 0.16);
    border-radius: 8px;
`

export const StyledLightButtonText = styled.span`
    font-size: 1.4rem;
    color: ${(props) => (props.color ? props.color : 'white')};
`

export const Scrollable = styled.div`
    overflow: scroll;
    ::-webkit-scrollbar {
        width: 2rem;
        padding-left: 4rem;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 2rem;
        border: 0.6rem solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
    }
`

export const DotMenuOptionsContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.8rem;

    svg {
        width: 2.4rem;
        height: 2.4rem;
    }
`

// TODO: create a Typography component: https://mui.com/material-ui/react-typography/

const DEFAULT_TEXT_FONT_SIZE = '1.7rem'
const BOLD_FONT_WEIGHT = 600

export const Text = styled.p`
    white-space: ${({ whiteSpace = 'unset' }) => whiteSpace};
    text-align: ${({ textAlign = 'initial' }) => textAlign};
    text-transform: ${({ textTransform }) => textTransform};
    font-style: ${({ fontStyle }) => fontStyle};
    font-weight: ${({ fontWeight = 'inherit' }) =>
        fontWeight === 'bold' ? BOLD_FONT_WEIGHT : fontWeight};

    &,
    & * {
        font-size: ${({ fontSize = DEFAULT_TEXT_FONT_SIZE }) => fontSize};
        color: ${({ color = OctupColors.primaryTextLight }) => color};
    }
`
