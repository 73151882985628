import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { DashboardType } from 'models/dashboards'
import type { DashboardByIdRequestType } from 'services/dashboards'
import { getDashboardById } from 'services/dashboards'
import type { ThunkAPIConfigType } from 'services/utils'

type DashboardStateType = {
    data?: DashboardType
    isLoading: boolean
    error?: string
}

type DashboardsByIdStateType = {
    data?: Record<DashboardType['id'], DashboardStateType>
    isLoading: boolean
}

const INITIAL_STATE: DashboardsByIdStateType = {
    data: undefined,
    isLoading: false,
}

export const fetchDashboardById = createAsyncThunk<
    DashboardType,
    DashboardByIdRequestType,
    ThunkAPIConfigType
>('fetchDashboardById', getDashboardById)

let pendingRequests = 0

export const dashboardsByIdSlice = createSlice({
    name: 'dashboardsById',
    initialState: INITIAL_STATE,
    reducers: {
        addNewDashboardData(state, actions) {
            const data = actions.payload
            const newDataState = { data, isLoading: false }
            state.data = { ...state.data, [data.id]: newDataState }
        },
        updateDashboardData(state, actions) {
            const { id, newData } = actions.payload
            const newDataState = { ...state.data?.[id], data: newData }
            state.data = { ...state.data, [id]: newDataState }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardById.pending, (state, action) => {
                const id = action.meta.arg.id
                const newDataState = { ...state.data?.[id], isLoading: true, error: undefined }
                state.data = { ...state.data, [id]: newDataState }

                state.isLoading = true
                pendingRequests += 1
            })
            .addCase(fetchDashboardById.fulfilled, (state, action) => {
                const data = action.payload
                const id = action.meta.arg.id
                const newDataState = { data, isLoading: false, error: undefined }
                state.data = { ...state.data, [id]: newDataState }

                pendingRequests -= 1
                if (pendingRequests === 0) {
                    state.isLoading = false
                }
            })
            .addCase(fetchDashboardById.rejected, (state, action) => {
                const id = action.meta.arg.id
                const error = action.error.message
                const newDataState = { ...state.data?.[id], isLoading: false, error }
                state.data = { ...state.data, [id]: newDataState }

                pendingRequests -= 1
                if (pendingRequests === 0) {
                    state.isLoading = false
                }
            })
    },
})

export const { addNewDashboardData, updateDashboardData } = dashboardsByIdSlice.actions
export const dashboardsByIdReducer = dashboardsByIdSlice.reducer
