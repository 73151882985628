import { Stack, Typography } from '@octup/core/ui-kit'

type ModalTitlePropsType = {
    icon?: React.ReactNode
    imageSrc?: string
    title: string
}

export const ModalTitle = ({ icon, imageSrc, title }: ModalTitlePropsType) => (
    <Stack direction="row" alignItems="center" spacing={1}>
        {icon}
        {imageSrc && <img src={imageSrc} alt={title} />}
        <Typography variant="h6" fontWeight={400}>
            {title}
        </Typography>
    </Stack>
)
