import React from 'react'
import { getIconColor } from './utils'

export function InsightsLogoSvg({ active, customActiveColor, customColor, disabled }) {
    return (
        <svg
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.59959 17.5385H14.3996"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.1996 20H13.7996"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.39948 15.0765C7.39217 14.3015 6.64814 13.2211 6.27277 11.9882C5.89741 10.7554 5.90973 9.43275 6.308 8.20752C6.70627 6.98229 7.4703 5.91663 8.49186 5.16149C9.51343 4.40635 10.7408 4 12 4C13.2592 4 14.4866 4.40634 15.5081 5.16148C16.5297 5.91662 17.2937 6.98227 17.692 8.2075C18.0903 9.43273 18.1026 10.7554 17.7272 11.9882C17.3519 13.221 16.6078 14.3015 15.6005 15.0765"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
