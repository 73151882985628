import { useQueryString } from '@/hooks'
import type { UserFormDataType } from '@/models/signup'
import { SIGNUP_SOURCES } from '@/models/signup'
import { CreateUserFormContainer } from '@/new_containers/components/Signup'

export const ShopifySignupPageContainer = () => {
    const { queryParams } = useQueryString<UserFormDataType>()

    return <CreateUserFormContainer source={SIGNUP_SOURCES.SHOPIFY} userData={queryParams} />
}
