import { OverflowTypography, Stack } from '@octup/core/ui-kit'

export const TitleContainer = ({ title, sku, ...props }) => (
    <Stack>
        <OverflowTypography {...props}>{title}</OverflowTypography>
        {sku && (
            <OverflowTypography variant="subtitle2" color="text.secondary">
                SKU: {sku}
            </OverflowTypography>
        )}
    </Stack>
)
