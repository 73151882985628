export const getActionButtons = ({ onCancel, onDismiss }) => [
    {
        title: 'cancel',
        type: 'button',
        variant: 'secondary',
        onClick: onCancel,
    },
    {
        title: 'dismiss',
        type: 'button',
        variant: 'contained',
        onClick: onDismiss,
    },
]
