import { Box } from '@octup/core/ui-kit'
import { Breathing } from 'react-shimmer'

export const OctupBreathing = ({ width = '100%', height = '100%', ...props }) => (
    <Box
        width={width}
        height={height}
        marginTop="1rem"
        sx={{
            borderRadius: 10,
            overflow: 'hidden',
            opacity: 0.5,
        }}
        {...props}
    >
        <Breathing />
    </Box>
)
