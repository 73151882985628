import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, IconButton, SvgIcon, Typography } from '@mui/material'
import { OctupTooltip } from 'shared/ui/MUIWrappers/OctupTooltip'
import { CoffeeIcon } from 'new_assets/icons'
import { addHeapEvent } from 'utils'
import { getContainerStyle, iconButtonStyle, iconStyle } from './OpenBoardButton.styles'

export const OpenBoardButton = ({ onClick }) => {
    const { isMainMenuCollapsed } = useSelector((state) => state.navigation)

    const containerStyle = useMemo(
        () => getContainerStyle(isMainMenuCollapsed),
        [isMainMenuCollapsed]
    )

    const handleClick = useCallback(() => {
        onClick()
        addHeapEvent('Open Good Morning Board')
    }, [onClick])

    return (
        <Box sx={containerStyle}>
            <OctupTooltip
                title={
                    <>
                        <Typography variant="body2" fontWeight={600}>
                            Good Morning Board
                        </Typography>
                        <Typography variant="body2">
                            Daily report summing up all your important metrics.
                        </Typography>
                    </>
                }
            >
                <IconButton disableRipple onClick={handleClick} sx={iconButtonStyle}>
                    <SvgIcon viewBox="0 0 28 28" sx={iconStyle}>
                        <CoffeeIcon className="GMB_icon" />
                    </SvgIcon>
                </IconButton>
            </OctupTooltip>
        </Box>
    )
}
