import { octupDictionary } from 'shared/dictionary/dictionary'
import { capitalizeString } from 'utils'

export const getDoughnutChartData = (data = []) => ({
    ...data.reduce(
        (acc, cost) => {
            const label =
                octupDictionary.titles.metricsTitles[cost.name] || capitalizeString(cost.name)

            return {
                chartData: [...acc.chartData, cost.value],
                labels: [...acc.labels, label],
                legends: [...acc.legends, { ...cost, label }],
            }
        },
        { chartData: [], labels: [], legends: [] }
    ),
})

export const getLineChartData = (data = []) =>
    data.reduce(
        (acc, { name, data }) => ({
            labels: [...new Set([...acc.labels, ...data.map(({ date }) => date)])],
            chartsDataSets: [
                ...acc.chartsDataSets,
                {
                    label: octupDictionary.titles.logistics[name],
                    data: data.map(({ value }) => value),
                },
            ],
        }),
        { labels: [], chartsDataSets: [] }
    )
