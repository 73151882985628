import { useMemo } from 'react'
import type { MetricsSectionPropsType } from '@octup/core/models'
import { BarMetricsSection } from '@/new_components/__common__/MetricsSections'
import { getChartConfig } from './utils'

export const BarMetricsSectionContainer = ({ data, ...props }: MetricsSectionPropsType) => {
    const chartConfig = useMemo(() => getChartConfig(data?.metrics), [data?.metrics])

    return <BarMetricsSection chartConfig={chartConfig} {...props} />
}
