import { MarkdownWrapper } from '../../../../new_components/WhatsNew/MarkdownWrapper/MarkdownWrapper'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { WhatsNewSlide } from '../../../../new_components'

export const WhatsNewSlidesContainer = () => {
    const { activeItem } = useSelector((state) => state.whatsNew)
    const { slide, path } = activeItem
    // TO DO : move logic to slice(?)
    const [text, setText] = useState('')
    useEffect(() => {
        setText('')
        ;(async () => {
            let filePath = null
            try {
                filePath = require(`../news/${path}.md`)
                fetch(filePath)
                    .then((response) => response.text())
                    .then((md) => {
                        setText(md)
                    })
                    .catch((error) => console.error(error))
            } catch (err) {
                setText(
                    `<h1>Slide Not Found</h1>  <p>Please Check that the file name and root are correct</p>`
                )
            }
        })()
    }, [path])

    return (
        <WhatsNewSlide slide={slide}>
            <MarkdownWrapper text={text} />
        </WhatsNewSlide>
    )
}
