import { ColumnBottomInnerContainer } from '../AlterativeHome.styles'
import { AlternativeHomeCardItem } from './base-components/AlternativeHomeCardItem'
import { ObjectivesLogoSvg } from '../../../shared/assets/icons'
import { DICTIONARY_SECTIONS, getDictionaryValue } from '../../../shared/dictionary/dictionary'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { generateGraphMeta } from './utils/alternative-home-graph-util'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'
export function ObjectivesCard({ setShowDetailsModal, className }) {
    const octupDispatch = useOctupDispatch()
    const {
        objectives,
        isLoading: isLoadingObjectives,
        isRejected: isRejectedObjectives,
    } = useSelector((state) => state.objectives)
    const [objectivesGraphMetaData, setObjectivesGraphMetaData] = useState([])

    useEffect(() => {
        octupDispatch.objectives.getObjectives()
    }, [octupDispatch.objectives])

    useEffect(() => {
        setObjectivesGraphMetaData(
            generateGraphMeta(
                objectives,
                'status',
                [
                    {
                        field: 'inprogress',
                        title: 'In Progress',
                        color: 'rgba(0, 152, 212, 1)',
                        textColor: 'inherit',
                    },
                    {
                        field: 'overdue',
                        title: 'Overdue',
                        color: '#db2059',
                        textColor: '#db2059',
                    },
                    {
                        field: 'completed',
                        title: 'Completed',
                        color: '#078344',
                        textColor: 'inherit',
                    },
                    {
                        field: 'dismissed',
                        title: 'Deleted',
                        color: '#A4A0AA',
                        textColor: 'inherit',
                    },
                    // {field:"resolved",title:"Resolved", color:"", textColor:"inherit"},
                ],
                false
            )
        )
    }, [objectives])
    const memoizedObjectivesLabels = useMemo(
        () => objectivesGraphMetaData && objectivesGraphMetaData.map((el) => el.title),
        [objectivesGraphMetaData]
    )
    return (
        <ColumnBottomInnerContainer className={className}>
            <>
                <AlternativeHomeCardItem
                    setShowDetailsModal={setShowDetailsModal}
                    mainIcon={ObjectivesLogoSvg}
                    isRejected={isRejectedObjectives}
                    isLoading={isLoadingObjectives}
                    segment={null}
                    metadata={objectivesGraphMetaData}
                    title={getDictionaryValue('objectives', DICTIONARY_SECTIONS.TITLES)}
                    value={objectives ? objectives?.length : 0}
                    subtitle={getDictionaryValue(
                        'objective_created',
                        DICTIONARY_SECTIONS.SUBTITLES
                    )}
                    labels={memoizedObjectivesLabels}
                    position={'top'}
                    isMetricsLayout={false}
                    colorSet={
                        objectivesGraphMetaData && objectivesGraphMetaData.map((el) => el.color)
                    }
                />
            </>
        </ColumnBottomInnerContainer>
    )
}
