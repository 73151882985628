import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAbilities, useOctupDispatch } from '@/hooks'
import { APP_FEATURES, APP_ROUTES } from '@/models/appConfig'
import { AlternativeHomeMainContainer } from './AlterativeHome.styles'
import { InsightsCard } from './AlternativeHomeComponents/InsightsCard'
import { MarketingCard } from './AlternativeHomeComponents/MarketingCard'
import { ObjectivesCard } from './AlternativeHomeComponents/ObjectivesCard'
import { OperationsCard } from './AlternativeHomeComponents/OperationsCard'
import { YesterdayStatsSection } from './AlternativeHomeComponents/YesterdayStatsSection'

const NAVIGATION_SECTIONS = [
    {
        id: 1,
        Component: YesterdayStatsSection,
        className: 'yesterdays_stats',
        feature: APP_FEATURES.GOOD_MORNING_BOARD,
    },
    {
        id: 2,
        Component: OperationsCard,
        className: 'operations',
        route: APP_ROUTES.OPERATIONS,
    },
    {
        id: 3,
        Component: InsightsCard,
        className: 'insights',
        route: APP_ROUTES.INSIGHTS,
    },
    {
        id: 4,
        Component: ObjectivesCard,
        className: 'objectives',
        route: APP_ROUTES.OBJECTIVES,
    },
    {
        id: 5,
        Component: MarketingCard,
        className: 'marketing',
        route: APP_ROUTES.MARKETING,
    },
]

export const AlternativeHome = (props) => {
    const octupDispatch = useOctupDispatch()
    const { getAvailableList } = useAbilities()
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const sections = useMemo(() => getAvailableList(NAVIGATION_SECTIONS), [getAvailableList])

    useEffect(() => {
        octupDispatch.metrics.getHomepageMetrics(
            datePickerValues.startDate,
            datePickerValues.endDate
        )
    }, [datePickerValues, octupDispatch.metrics])

    return (
        <AlternativeHomeMainContainer>
            {sections.map(({ id, Component, className }) => (
                <Component key={id} className={className} {...props} />
            ))}
        </AlternativeHomeMainContainer>
    )
}
