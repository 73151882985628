import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CUSTOM_SEGMENTS } from 'constants/integrations'
import { integrationsService } from './integrationsService'
import { extractErrMsg } from '../../shared/utils/utils'
import { GLOBAL_CONFIG } from '../../config'

const API_URL = `${GLOBAL_CONFIG.BASE_URL}auth`

const initialState = {
    integrations: [],
    selectedSegmentIntegrations: [],
    integrationsBySegment: null,
    integrationsExtension: [],
    integrationsExtensionIsLoading: false,
    isLoading: false,
    error: null,
}

export const getIntegrationsExtension = createAsyncThunk(
    `${API_URL}/integrations/extension`,
    async (arg, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.userInfo.token
            const res = await integrationsService.getIntegrationsExtension({
                source: arg?.source,
                token,
            })
            return res
        } catch (e) {}
    }
)

export const postIntegrationsAddAccounts = createAsyncThunk(
    `${API_URL}/integrations/extension/selections`,
    async (arg, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.userInfo.token
            const res = await integrationsService.postIntegrationsAddAccounts({
                source: arg?.source,
                selection: arg?.selections,
                token,
            })
            return res
        } catch (e) {}
    }
)

//get Integrations
export const getIntegrations = createAsyncThunk(
    `${API_URL}/integrations/getAll`,
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.userInfo.token
            const clientId = thunkAPI.getState().auth.userInfo.clientId
            const res = await integrationsService.getIntegrations({
                token,
                clientId,
            })
            return res
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

const integrationSlice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {
        selectSegment(state, action) {
            const segment = action.payload?.title?.toLowerCase()
            state.selectedSegmentIntegrations = state.integrationsBySegment?.[segment]
        },
        updateIntegration(state, action) {
            const { integration: integrationTitle, isConnected } = action.payload

            state.integrations = state.integrations.map((integration) => ({
                ...integration,
                ...(integration.title === integrationTitle && { isConnected }),
            }))
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getIntegrations.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getIntegrations.fulfilled, (state, action) => {
                state.isLoading = false
                state.integrations = action.payload
                state.integrationsBySegment = [...action.payload].reduce(
                    (acc, integration) => ({
                        ...acc,
                        [integration.segment]: [...(acc[integration.segment] || []), integration],
                        [CUSTOM_SEGMENTS.MY_CONNECTIONS]: [
                            ...acc[CUSTOM_SEGMENTS.MY_CONNECTIONS],
                            ...(integration.isConnected ? [integration] : []),
                        ],
                    }),
                    { [CUSTOM_SEGMENTS.MY_CONNECTIONS]: [], [CUSTOM_SEGMENTS.ALL]: action.payload }
                )
            })
            .addCase(getIntegrations.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
            .addCase(getIntegrationsExtension.pending, (state) => {
                state.integrationsExtensionIsLoading = true
            })
            .addCase(getIntegrationsExtension.fulfilled, (state, action) => {
                state.integrationsExtensionIsLoading = false
                state.integrationsExtension = action.payload
            })
            .addCase(getIntegrationsExtension.rejected, (state) => {
                state.integrationsExtensionIsLoading = false
            })
    },
})

export const { selectSegment, updateIntegration } = integrationSlice.actions
export const integrationsState = (state) => state.integrations
export const integrationsSliceReducer = integrationSlice.reducer
