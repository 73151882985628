import { OTHER_COST } from 'constants/warehouse'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getLogistics } from 'services'
import { getLogisticsData } from './utils'

const INITIAL_STATE = {
    data: null,
    otherCost: { id: OTHER_COST, name: OTHER_COST, value: 0, costs: [] },
    query: null,
    isLoading: false,
    error: null,
}

export const fetchLogistics = createAsyncThunk('fetchLogistics', getLogistics)

export const logisticsSlice = createSlice({
    name: 'logistics',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLogistics.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchLogistics.fulfilled, (state, action) => {
                const { data, otherCost } = getLogisticsData({
                    data: action.payload,
                    otherCost: INITIAL_STATE.otherCost,
                })

                state.data = data
                state.otherCost = otherCost
                state.isLoading = false
            })
            .addCase(fetchLogistics.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const logisticsReducer = logisticsSlice.reducer
