import type { ButtonPropsType } from '@octup/core/ui-kit'
import { Button } from '@octup/core/ui-kit'
import { useFormContext } from 'react-hook-form'

export type SubmitFormButtonContainerPropsType = ButtonPropsType

export const SubmitFormButtonContainer = ({ children = 'Save', ...props }: ButtonPropsType) => {
    const { formState } = useFormContext()
    const { isSubmitting, dirtyFields } = formState
    const hasDirtyFields = !!Object.keys(dirtyFields).length

    return (
        <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={!hasDirtyFields}
            loading={isSubmitting}
            {...props}
        >
            {children}
        </Button>
    )
}
