// TODO : Extend re-usability, replace the component with ConfirmationModal
import { useCallback } from 'react'
import { DiscardChangesModal } from '../../../../new_components'

export const DiscardChangesModalContainer = ({
    onDiscard,
    onCancel,
    onSuccess,
    onFail,
    title,
    content,
    showDiscardModal,
    discardTitle = 'Discard',
    cancelTitle = 'Go Back',
}) => {
    const handleDiscard = useCallback(
        async (event) => {
            onCancel?.(event)
            const response = await onDiscard?.(event)
            if (response?.error) return onFail?.(response)
            return onSuccess?.(response)
        },
        [onCancel, onDiscard, onFail, onSuccess]
    )

    const buttons = [
        {
            title: cancelTitle,
            variant: 'secondary',
            onClick: onCancel,
        },
        {
            title: discardTitle,
            variant: 'contained',
            onClick: handleDiscard,
        },
    ]

    return (
        <>
            {showDiscardModal && (
                <DiscardChangesModal
                    callback={onCancel}
                    buttons={buttons}
                    title={title || 'Discard Changes'}
                    content={
                        content || {
                            header: 'If you choose to close, your data won’t be saved.',
                            paragarph: 'Are you sure you want to discard changes?',
                        }
                    }
                    dismissModalHandler={onCancel}
                />
            )}
        </>
    )
}
