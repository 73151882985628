import { createContext } from 'react'
import { useState } from 'react'

export const ModalsContext = createContext()

export const ModalsContextProvider = ({ children }) => {
    const [deepdiveModal, setDeepdiveModal] = useState({
        show: false,
        segments: [],
        selectedDDMetric: null,
    })

    const [introModal, setIntroModal] = useState({
        show: false,
    })

    return (
        <ModalsContext.Provider
            value={{ deepdiveModal, setDeepdiveModal, introModal, setIntroModal }}
        >
            {children}
        </ModalsContext.Provider>
    )
}
