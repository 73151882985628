import PageTitle from './PageTitle'
import styled from 'styled-components'
import { GLOBAL_CONFIG } from '../../config'

const MobilePageLayout = ({ children, isSubMenu, customTitleStyled }) => {
    return (
        <MainPageContainer>
            <PageTitle
                isSubMenu={isSubMenu}
                customTitleStyled={customTitleStyled}
                showDatePicker={false}
            />
            {children}
        </MainPageContainer>
    )
}

const MainPageContainer = styled.div`
    height: 83vh;
    margin-bottom: 2rem;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* @media screen and (max-width: ${GLOBAL_CONFIG.NARROW_SCREEN_WIDTH}) {
    overflow: auto;
  } */
`

export default MobilePageLayout
