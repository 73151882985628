import { useQueryString, useToast } from 'hooks'
import { getIntegrationTitle } from 'new_containers/components/Integrations/utils'
import { useEffect } from 'react'
import { ConnectionSvg } from 'shared/assets/icons/ConnectionSvg'

export const useIntegrationToast = ({ onDisconnect, onAccountsSelect }) => {
    const toast = useToast()
    const { queryParams, cleanQueryParams } = useQueryString()

    useEffect(() => {
        if (Object.keys(queryParams).length === 0) return

        const { error: isError, integration, extension, disconnect: isDisconnected } = queryParams

        cleanQueryParams()

        if (extension) {
            return onAccountsSelect(integration)
        }

        const integrationTitle = integration ? getIntegrationTitle(integration) : ''

        if (isDisconnected && integration) {
            if (isError) {
                const actionText = 'Try again'
                const action = () => onDisconnect(integration)

                toast.error(
                    `Something went wrong disconnecting ${integrationTitle}`,
                    null,
                    null,
                    null,
                    null,
                    null,
                    action,
                    null,
                    actionText
                )
                return
            }

            const disableTimer = false
            toast.prompt(
                `You have successfully disconnected ${integrationTitle}`,
                null,
                ConnectionSvg,
                null,
                null,
                null,
                null,
                null,
                null,
                disableTimer
            )
            return
        }

        if (isError) {
            toast.error(`${integrationTitle} integration has failed. Please try again later`)
            return
        }

        if (integration) {
            toast.success(`Successfully connected to ${integrationTitle}`)
            return
        }
    }, [cleanQueryParams, onAccountsSelect, onDisconnect, queryParams, toast])
}
