import { useEffect, useState } from 'react'
import styled from 'styled-components'

export const OctupBaseTableRow = ({
    el,
    rowIndex,
    renderRows,
    renderSubRow,
    showSelectedRow,
    customRowStyle,
    customSubRowStyle,
    setSelectedRowData,
    tableRowClickHandler,
    rowMenuActions,
    subRowMenuActions,
    isCollapsible,
    setSelectedItem,
    selectedItem,
    isRowSelectable = false,
    hiddenRowKey = 'title',
    enableSubRowSelection,
    rowOnClick,
    overrideRowKey,
    isRowClickable,
    isSubRowClickable,
    isRowDisabled,
    subRowField = 'subData',
    subRowKeyField = 'id',
    forceShowSubData = false,
}) => {
    const [showSubData, setShowSubData] = useState(false)
    const disabled = isRowDisabled?.(el)
    const hasSubRow = !!el?.[subRowField]?.length

    useEffect(() => {
        setShowSubData(forceShowSubData)
    }, [forceShowSubData])

    return (
        <>
            {renderRows && el && (el[hiddenRowKey] || overrideRowKey) && (
                <TableRowStyled
                    rowOnClick={!!rowOnClick}
                    isCollapsible={isCollapsible}
                    isShowSubData={showSubData}
                    style={customRowStyle && { ...customRowStyle }}
                    key={el?.id}
                    onClick={() => {
                        setSelectedItem && setSelectedItem(el?.id)
                        setSelectedRowData && setSelectedRowData(el)
                        //ADD DISPATCH TO GET COST BREAKDOWN
                        tableRowClickHandler && tableRowClickHandler(el)
                        hasSubRow && setShowSubData((prevState) => !prevState)
                        rowOnClick && rowOnClick(el)
                    }}
                    className={` ${hasSubRow && 'collapsible'} ${
                        isRowSelectable
                            ? 'selectable'
                            : isRowClickable && !disabled
                              ? 'clickable'
                              : ''
                    } ${showSelectedRow && selectedItem === el?.id ? 'selected' : ''}`}
                >
                    {showSelectedRow && selectedItem && selectedItem === el.id && (
                        <SelectedItemIndicator className="selected-indicator" />
                    )}
                    {renderRows(el, rowMenuActions)}
                </TableRowStyled>
            )}
            {hasSubRow &&
                el[subRowField].map((subRow) => (
                    <TableSubRowStyled
                        key={subRow?.[subRowKeyField]}
                        showSubData={showSubData}
                        isSubRowClickable={isSubRowClickable}
                        style={customSubRowStyle ? { ...customSubRowStyle } : { ...customRowStyle }}
                        onClick={() => {
                            if (enableSubRowSelection) {
                                setSelectedItem && setSelectedItem(subRow.id)
                                setSelectedRowData && setSelectedRowData(subRow)
                                //ADD DISPATCH TO GET COST BREAKDOWN
                                tableRowClickHandler && tableRowClickHandler(subRow)
                            }
                        }}
                        className={` ${subRow?.[subRowField] && 'collapsible'} ${
                            isRowSelectable ? 'selectable' : ''
                        } ${showSelectedRow && selectedItem === subRow.id ? 'selected' : ''}`}
                    >
                        {showSelectedRow && selectedItem && selectedItem === subRow.id && (
                            <SelectedItemIndicator className="selected-indicator" />
                        )}
                        {renderSubRow && renderSubRow(subRow, subRowMenuActions, el)}
                    </TableSubRowStyled>
                ))}
        </>
    )
}

// TODO: Remove copied-pasted ":not(.selected-indicator)"
// It's a temporary solution to avoid browser's error
// In the future we will re-write Table component and remove it
const TableRowStyled = styled.tr`
    ${(p) => p.rowOnClick && 'cursor: pointer;'};
    box-shadow:
        inset 0 0.1rem 0 0 rgba(80, 75, 90, 0.1),
        inset 0 0 0 rgba(40, 40, 49, 0.32);
    border: none;
    position: relative;
    width: 100%;
    height: 10.3rem;
    background: ${(p) => p.isShowSubData && 'rgba(256,256,256,0.5) !important'};

    :first-child {
        box-shadow:
            inset 0 0 0 0 rgba(80, 75, 90, 0.1),
            inset 0 0 0 rgba(40, 40, 49, 0.32);
    }

    :hover {
        background: #f1f0f5;
        border-radius: 0.8rem;

        .tbl-row-action-btns {
            opacity: 1 !important;
        }

        .tbl-row-action-text {
            width: calc(29vw - 2rem);
            /* width: calc(100% - 2rem); */
        }
    }

    &.clickable {
        cursor: pointer;
    }

    &.selected {
        border-radius: 0.8rem;
        background: #f6f5f9;
        box-shadow:
            inset -0.2rem -0.2rem 0.4rem rgba(80, 75, 90, 0.1),
            inset 0.2rem 0.2rem 0.4rem rgba(40, 40, 49, 0.32);
    }

    td:not(.selected-indicator) {
        font-size: 1.5rem;
        line-height: 2rem;
        text-transform: capitalize;
    }

    td:not(.selected-indicator):first-child {
        padding-left: ${(props) => (props.isCollapsible ? '5.5rem' : '1.5rem')};
        display: flex;
        align-items: center;
        gap: 3rem;
        height: 100%;

        div {
            font-size: inherit;
        }
    }

    &.selected {
        td:not(.selected-indicator):nth-child(2) {
            padding-left: 1.5rem;
            display: flex;
            align-items: center;
        }
    }

    &.collapsible {
        td:not(.selected-indicator):first-child {
            padding-left: 1.5rem;

            &:before {
                border-style: solid;
                border-width: 0.15em 0.15em 0 0;
                content: '';
                display: inline-block;
                height: 0.5em;
                left: 0.15em;
                position: relative;
                vertical-align: top;
                width: 0.5em;
                top: ${(props) => (props.isShowSubData ? '0.15em' : 0)};
                transform: ${(props) =>
                    props.isShowSubData ? 'rotate(-45deg)' : 'rotate(135deg)'};
            }
        }
    }

    &.selected.collapsible {
        td:not(.selected-indicator):nth-child(2) {
            padding-left: 1.5rem;

            &:before {
                border-style: solid;
                border-width: 0.15em 0.15em 0 0;
                content: '';
                display: inline-block;
                height: 0.5em;
                left: 0.15em;
                position: relative;
                vertical-align: top;
                width: 0.5em;
                top: ${(props) => (props.isShowSubData ? '0.15em' : 0)};
                transform: ${(props) =>
                    props.isShowSubData ? 'rotate(-45deg)' : 'rotate(135deg)'};
            }
        }
    }
`

const TableSubRowStyled = styled.tr`
    box-shadow:
        inset 0 0.1rem 0 0 rgba(80, 75, 90, 0.1),
        inset 0 0 0 rgba(40, 40, 49, 0.32);
    border: none;
    position: relative;
    display: ${(props) => !props.showSubData && 'none'};
    width: 100%;
    cursor: ${(props) => (props.isSubRowClickable ? 'pointer' : 'default')};
    height: 10.3rem;
    background: rgba(256, 256, 256, 0.5);

    :first-child {
        box-shadow:
            inset 0 0 0 0 rgba(80, 75, 90, 0.1),
            inset 0 0 0 rgba(40, 40, 49, 0.32);
    }

    :hover {
        background: #f1f0f5;
        border-radius: 0.8rem;

        .tbl-row-action-btns {
            opacity: 1 !important;
        }

        .tbl-row-action-text {
            width: calc(29vw - 2rem);
            /* width: calc(100% - 2rem); */
        }
    }

    &.selected {
        border-radius: 0.8rem;
        background: #f6f5f9;
        box-shadow:
            inset -0.2rem -0.2rem 0.4rem rgba(80, 75, 90, 0.1),
            inset 0.2rem 0.2rem 0.4rem rgba(40, 40, 49, 0.32);
    }

    td:not(.selected-indicator) {
        font-size: 1.5rem;
        line-height: 2rem;
        text-transform: capitalize;
    }

    td:not(.selected-indicator):first-child {
        padding-left: ${(props) => (props.showSubData ? '8rem' : '1.5rem')};
        display: flex;
        align-items: center;
        gap: 3rem;
        height: 100%;

        div {
            font-size: inherit;
        }
    }

    &.selected {
        td:not(.selected-indicator):nth-child(2) {
            padding-left: ${(props) => (props.showSubData ? '8rem' : '1.5rem')};
            display: flex;
            align-items: center;
        }
    }

    &.selected.collapsible {
        td:not(.selected-indicator):nth-child(2) {
            padding-left: 1.5rem;

            &:before {
                border-style: solid;
                border-width: 0.15em 0.15em 0 0;
                content: '';
                display: inline-block;
                height: 0.5em;
                left: 0.15em;
                position: relative;
                vertical-align: top;
                width: 0.5em;
                top: ${(props) => (props.isShowSubData ? '0.15em' : 0)};
                transform: ${(props) =>
                    props.isShowSubData ? 'rotate(-45deg)' : 'rotate(135deg)'};
            }
        }
    }
`

const SelectedItemIndicator = styled.td`
    position: absolute;
    height: 70%;
    top: 50%;
    left: 0.5rem;
    transform: translateY(-50%);
    border-left: 0.3rem solid #00a8a8;
`
