import { useState } from 'react'
import { TrialEnded } from './TrialEnded'
import { PlanChooser } from './PlaneChooser'
import { PurchaseConfirmed } from './PurchaseConfirmed'
import { useLocation } from 'react-router-dom'
import { useToast } from '../../hooks/useToastContext'

let showError = true

export const PaymentFlow = ({ currentStep, setCurrentStep, closeModal, isTrial = true }) => {
    const [planName, setPlanName] = useState('')
    const location = useLocation()
    const toast = useToast()
    const searchParams = new URLSearchParams(location.search)
    const isPaymentSuccessful = searchParams.get('pay_success') === 'true'
    const paymentPlan = searchParams.get('plan')

    if (isPaymentSuccessful) {
        planName !== paymentPlan && setPlanName(paymentPlan)
        setCurrentStep(2)
    }

    if (searchParams.get('pay_success') === 'false') {
        showError && toast.error('Payment failed')
        showError && currentStep !== 0 && setCurrentStep(0)
        showError = false
    }
    const steps = [
        {
            render: (
                <TrialEnded
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    isTrial={isTrial}
                />
            ),
        },
        {
            render: (
                <PlanChooser
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    setPlanName={setPlanName}
                />
            ),
        },
        {
            render: <PurchaseConfirmed closeModal={closeModal} planName={planName} />,
        },
    ]

    return <>{steps[currentStep].render}</>
}
