import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Stack,
    Typography,
} from '@octup/core/ui-kit'
import type { ClientSettingsType } from '@/models/settings'
import { ClientSettingsFormContainer } from '@/new_containers/components/Clients'

type ClientsSettingsPropsType = {
    data?: ClientSettingsType[]
}

export const ClientsSettings = ({ data }: ClientsSettingsPropsType) => (
    <Stack width="100%">
        {data?.map((client, index) => (
            <Accordion key={client.id} defaultExpanded={index === 0}>
                <AccordionSummary>
                    <Typography variant="h6">{client.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ClientSettingsFormContainer data={client} />
                </AccordionDetails>
            </Accordion>
        ))}
    </Stack>
)
