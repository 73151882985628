import { useCallback, useState, useEffect } from 'react'
import { CircularProgress, Stack, SvgIcon, Typography } from '@mui/material'
import { ArrowIcon, PlusIcon } from '@octup/core/assets/icons'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { useOctupDispatch } from '@/hooks'
import { OctupTooltip } from '@/shared/ui/MUIWrappers/OctupTooltip'
import SidebarSubMenu from '../SidebarItem/SidebarSubMenu/SidebarSubMenu'
import { ExternalMenu } from './ExternalMenu'
import { NavItemIcon, arrowIconStyle, iconStyle, listItemStyle } from './SidebarItem.styles'

const SidebarItem = ({
    navItem,
    icon: Icon = () => {},
    setShowEditObjectiveModal,
    isNav = true,
    customClickHandler,
    disabled = false,
    isIconSVG = false,
    externalMenu,
    isLast,
}) => {
    const {
        title,
        path,
        actionBtn,
        loading,
        subMenu: rawSubMenu,
        showSubMenuDefault,
        onClick,
    } = navItem

    const location = useLocation()
    const octupDispatch = useOctupDispatch()
    const navigationState = useSelector((state) => state.navigation)
    const { isMainMenuCollapsed } = navigationState
    const subMenu = rawSubMenu?.filter((el) => !el.disabled).length > 0 ? rawSubMenu : null
    const formattedTitle = title.replaceAll(/\s/g, '').toLowerCase()
    const [isActive, setIsActive] = useState(navigationState.activePage.includes(formattedTitle))
    const [showSubMenu, setShowSubMenu] = useState(showSubMenuDefault)

    const onShowSubMenu = useCallback(() => setShowSubMenu(true), [])
    const toggleShowSubMenu = useCallback(() => setShowSubMenu((prev) => !prev), [])

    // External Menu
    const [anchorEl, setAnchorEl] = useState(null)
    const isExternalMenu = externalMenu?.options?.length > 0
    const handleExternalMenuClick = (event) => setAnchorEl(event.currentTarget)
    const handleExternalMenuClose = () => setAnchorEl(null)

    useEffect(() => {
        isExternalMenu && setIsActive(!!anchorEl)
    }, [anchorEl, isExternalMenu])

    useEffect(() => {
        const isMatch = location.pathname.replace(/^\//, '') === path
        !isExternalMenu && setIsActive(isMatch)
    }, [location, path, isExternalMenu])

    const handleClick = useCallback(
        (e) => {
            if (!disabled && !loading && onClick) return onClick()

            if (!isNav || disabled || loading) {
                e.preventDefault()
                isExternalMenu ? handleExternalMenuClick(e) : !isNav && setIsActive((prev) => !prev)
                return
            }

            isExternalMenu && setIsActive(true)
            customClickHandler?.()
            toggleShowSubMenu()
            octupDispatch.sidebar.setActiveSubMenuItem(null)
        },
        [
            onClick,
            isNav,
            disabled,
            loading,
            isExternalMenu,
            customClickHandler,
            toggleShowSubMenu,
            octupDispatch.sidebar,
        ]
    )

    const handleSubItemSelection = useCallback(() => {
        disabled && octupDispatch.sidebar.setActivePage(title)
    }, [octupDispatch.sidebar, disabled, title])

    return (
        <>
            {/* TODO: use List and ListItem instead of Stack after overriding styles */}
            <Stack
                {...(path && { component: NavLink, to: path })}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={listItemStyle({ showSubMenu })}
                className={`${!isNav && isActive ? 'active' : ''} ${isLast && 'last-item'}`}
                onClick={handleClick}
                disabled={disabled}
                id="external-menu-trigger"
                aria-controls={anchorEl ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
            >
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <NavItemIcon>
                        {isIconSVG ? Icon : <Icon active={isActive} disabled={disabled} />}
                    </NavItemIcon>
                    {!isMainMenuCollapsed && (
                        <Typography color="inherit" variant="body2">
                            {title}
                        </Typography>
                    )}
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1}>
                    {actionBtn && !isMainMenuCollapsed && actionBtn.type === 'add' && (
                        <>
                            {actionBtn.loading ? (
                                <Stack sx={{ pr: 1 }}>
                                    <CircularProgress color="inherit" size={14} />
                                </Stack>
                            ) : (
                                <OctupTooltip position="top" title={actionBtn.info}>
                                    <SvgIcon
                                        fontSize="large"
                                        component={PlusIcon}
                                        sx={iconStyle(actionBtn.disabled)}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()

                                            if (actionBtn.disabled) return

                                            actionBtn.onClick({
                                                setShowEditObjectiveModal,
                                                onShowSubMenu,
                                            })
                                        }}
                                    />
                                </OctupTooltip>
                            )}
                        </>
                    )}

                    {subMenu && !disabled && !loading && (
                        <SvgIcon
                            fontSize="large"
                            component={ArrowIcon}
                            sx={arrowIconStyle({ showSubMenu, isMainMenuCollapsed })}
                        />
                    )}
                </Stack>
            </Stack>
            {subMenu && showSubMenu && !isMainMenuCollapsed && (
                <SidebarSubMenu subMenu={subMenu} onSubItemSelection={handleSubItemSelection} />
            )}
            {isExternalMenu && (
                <ExternalMenu
                    anchorEl={anchorEl}
                    onClose={handleExternalMenuClose}
                    config={externalMenu}
                />
            )}
        </>
    )
}

export default SidebarItem
