// TODO: temporary styles
// Will re-write it after integrating Material-UI

import type { Theme } from '@mui/material'

export const labelStyle = {
    '& .MuiFormControlLabel-label': {
        width: '100%',
    },
    '& .MuiCheckbox-root': {
        padding: '0 0.8rem 0 1.5rem',
    },
}

export const checkboxStyle = (theme: Theme) => ({
    padding: 0,
    '&.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        boxShadow: 'none',
        border: 'none',
    },
    '&.MuiCheckbox-sizeSmall .MuiSvgIcon-root': {
        fontSize: '2rem',
    },
    '& .MuiSvgIcon-root': {
        borderRadius: '0.5rem',
        color: 'transparent',
        border: '1px solid #DDDBE1',
        boxShadow: '2px 2px 8px 0px #504B5A52 inset, -2px -2px 4px 0px #FFFFFF inset',
    },
})
