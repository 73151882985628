import { useEffect, useCallback, useContext, useMemo } from 'react'
import { MetricsDashboardContainer } from '@octup/core/components'
import type { METRICS_SECTION_TYPE, MetricsSectionType, MetricType } from '@octup/core/models'
import { ModalsContext } from '@/contexts/ModalsContext'
import type { ClientType } from '@/models/clients'
import type { DashboardType } from '@/models/dashboards'
import { ErrorMessageFallback } from '@/new_components/__common__'
import { NoDataFallbackContainer } from '@/new_containers/components/__common__'
import { NoDataSvg } from '@/shared/assets/images/__common__'
import { fetchDashboardById } from '@/state/features/dashboards'
import { fetchMetricsSectionById } from '@/state/features/metrics'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { getDeepDiveMetricsBySectionType, METRICS_SECTION_CONFIG_BY_TYPE } from './utils'

type MetricsDashboardProviderPropsType = {
    isLoading?: boolean
    dashboardId?: DashboardType['id']
    clientId?: ClientType['id']
    noDataText?: string
}

export const MetricsDashboardProvider = ({
    isLoading,
    dashboardId,
    clientId,
    noDataText,
    ...props
}: MetricsDashboardProviderPropsType) => {
    const dispatch = useAppDispatch()
    const { setDeepdiveModal } = useContext(ModalsContext)
    const { userInfo } = useAppSelector((state) => state.auth)
    const { dashboardsById } = useAppSelector((state) => state.dashboards)
    const { datePickerValues } = useAppSelector((state) => state.datePicker)
    const { metricsSections } = useAppSelector((state) => state.newMetrics)
    const dashboard = dashboardId ? dashboardsById.data?.[dashboardId] : undefined
    const sectionsList = dashboard?.data?.sections
    const currentClientId = clientId || userInfo.clientId
    const sectionStateById = metricsSections?.data?.[currentClientId]
    const isDeepDiveDisabled = metricsSections.isLoading

    useEffect(() => {
        if (dashboardId && !dashboard?.data) {
            dispatch(fetchDashboardById({ id: dashboardId, clientId: currentClientId }))
        }
    }, [dashboard?.data, datePickerValues, dashboardId, dispatch, currentClientId])

    const getMetricsSectionDataById = useCallback(
        ({ id, type }: MetricsSectionType) => {
            const { responseDataType } = METRICS_SECTION_CONFIG_BY_TYPE[type]
            const prevDatePickerValues = sectionStateById?.[id]?.dateRange
            const isDateRangeChanged =
                prevDatePickerValues?.startDate !== datePickerValues.startDate ||
                prevDatePickerValues?.endDate !== datePickerValues.endDate

            if (isDateRangeChanged) {
                dispatch(
                    fetchMetricsSectionById({
                        id,
                        type,
                        responseDataType,
                        startDate: datePickerValues.startDate,
                        endDate: datePickerValues.endDate,
                        clientId: currentClientId,
                    })
                )
            }
        },
        [currentClientId, datePickerValues, dispatch, sectionStateById]
    )

    useEffect(() => {
        sectionsList?.forEach((section) => section && getMetricsSectionDataById(section))
    }, [getMetricsSectionDataById, sectionsList])

    const deepDiveMetricsBySectionType = useMemo(() => {
        if (isDeepDiveDisabled || !sectionStateById || !sectionsList) return
        return getDeepDiveMetricsBySectionType(sectionStateById, sectionsList)
    }, [sectionsList, sectionStateById, isDeepDiveDisabled])

    const handleDeepDiveShow = useCallback(
        (sectionType: METRICS_SECTION_TYPE, metricName: MetricType['name']) => {
            if (!sectionType || !metricName || !deepDiveMetricsBySectionType) return

            setDeepdiveModal({
                show: true,
                customMetrics: deepDiveMetricsBySectionType[sectionType],
                selectedDDMetric: metricName,
                clientId: currentClientId,
            })
        },
        [currentClientId, deepDiveMetricsBySectionType, setDeepdiveModal]
    )

    if (dashboard?.error) return <ErrorMessageFallback />
    if (!(isLoading || dashboard?.isLoading) && !sectionsList?.length) {
        return (
            //  @ts-expect-error: NoDataFallbackContainer is a JavaScript file
            <NoDataFallbackContainer
                icon={<NoDataSvg />}
                title="This board is empty at the moment."
                text={noDataText}
            />
        )
    }

    return (
        <MetricsDashboardContainer
            template={dashboard?.data?.template}
            sectionsList={sectionsList}
            sectionStateById={sectionStateById}
            sectionConfigByType={METRICS_SECTION_CONFIG_BY_TYPE}
            {...(!isDeepDiveDisabled && { onDeepDiveShow: handleDeepDiveShow })}
            {...props}
        />
    )
}
