import { Button, Stack } from '@octup/core/ui-kit'
import { IntegrationStatusContainer } from 'new_containers/components/Integrations'

export const CardMenuActionButtons = ({ isConnected, integrationName }) => {
    if (isConnected) {
        return <IntegrationStatusContainer integrationName={integrationName} />
    }

    return (
        <Stack direction="row" width="100%" justifyContent="flex-end">
            <Button sx={{ padding: '0.5rem 2.5rem' }} variant="contained">
                Connect
            </Button>
        </Stack>
    )
}
