import styled from 'styled-components'
import { ChevronDownSvg } from '../../assets/icons'
import { useCallback } from 'react'

export function DropableMenuHeader({
    children,
    setShowDropdown,
    showChevron,
    showDropdown,
    disabled,
}) {
    const handleClick = useCallback(() => {
        if (disabled) return

        setShowDropdown((prevState) => !prevState)
    }, [disabled, setShowDropdown])

    return (
        <DropableMenuHeaderContainer
            isOpen={showDropdown}
            onClick={handleClick}
            disabled={disabled}
        >
            {children}
            {showChevron && (
                <div className="chevron">
                    <ChevronDownSvg customColor="#504b5a" />
                </div>
            )}
        </DropableMenuHeaderContainer>
    )
}

const DropableMenuHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    .chevron {
        padding: 0 0.5rem;
        transform: ${(props) => (props.isOpen ? 'rotate(180deg) translateY(-10%)' : '')};
    }
`
