import { InfoIcon } from '@octup/core/assets/icons'
import type { StackPropsType } from '@octup/core/ui-kit'
import { Stack, SvgIcon, LabeledLinearProgress, Box } from '@octup/core/ui-kit'
import { OctupTooltip } from '@/shared/ui/MUIWrappers/OctupTooltip'

export type IntegrationStatusPropsType = StackPropsType & {
    value?: number
    description: string
}

export const IntegrationStatus = ({ value, description, ...props }: IntegrationStatusPropsType) => {
    if (typeof value !== 'number') return null

    return (
        <Stack width="100%" direction="row" alignItems="center" spacing={2} {...props}>
            <Box width="100%">
                <LabeledLinearProgress value={value} />
            </Box>
            {/* @ts-expect-error: OctupTooltip is a JavaScript file */}
            <OctupTooltip title={description} position="top">
                <SvgIcon component={InfoIcon} />
            </OctupTooltip>
        </Stack>
    )
}
