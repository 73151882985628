import { useCallback } from 'react'
import type { MetricsSectionType } from '@octup/core/models'
import { useFormContext } from 'react-hook-form'
import { DASHBOARD_SECTIONS_FIELD_NAME } from '@/constants/dashboards'
import { MetricsSectionsListItem } from '@/new_components/Dashboards/DashboardForm'
import { setSelectedDashboardSection } from '@/state/features/dashboards'
import { useAppDispatch, useAppSelector } from '@/state/hooks'

export const MetricsSectionsListItemContainer = (section: MetricsSectionType) => {
    const dispatch = useAppDispatch()
    const { dashboard } = useAppSelector((state) => state.dashboards)
    const { watch, setValue } = useFormContext()
    const { selectedDashboardSection } = dashboard
    const sectionIds = watch(DASHBOARD_SECTIONS_FIELD_NAME)
    const isDisabled = sectionIds?.includes(section.id)
    const isSelected = selectedDashboardSection?.id === section.id

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            if (!selectedDashboardSection) return
            const id = event.currentTarget.value
            setValue(selectedDashboardSection.name, id, { shouldDirty: true })
            dispatch(setSelectedDashboardSection({ id }))
        },
        [dispatch, selectedDashboardSection, setValue]
    )

    if (!selectedDashboardSection) return null

    return (
        <MetricsSectionsListItem
            {...section}
            onClick={handleClick}
            isDisabled={isDisabled}
            isSelected={isSelected}
        />
    )
}
