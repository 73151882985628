import axios from 'axios'
import { GLOBAL_CONFIG } from 'config'
import type { PageSettingsByNameType } from 'models/pages'
import type { ThunkAPIType } from 'services/utils'
import { generateAPIConfig } from 'services/utils'

const PAGE_API_URL = GLOBAL_CONFIG.API_URL + '/page'

export const getPageSettingsByName = async (
    name: keyof PageSettingsByNameType,
    thunkAPI: ThunkAPIType
) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(`${PAGE_API_URL}/${name}/settings`, config)
    return response.data
}
