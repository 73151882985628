import { useEffect, useState } from 'react'
import { isString } from '../shared/utils/strings-util'

export const useInput = ({
    initialValue = '',
    isRequired = false,
    inputType = '',
    max = { value: '', customErrorMsg: '' },
    min = { value: '', customErrorMsg: '' },
    regexStringValidator = { regexString: '', errorMsg: '' },
    additionalValidator = { validatorFunction: null, errorMsg: '' },
    validateOnChange = false,
    name = '',
}) => {
    const [enteredValue, setEnteredValue] = useState(initialValue)
    const [isChanged, setIsChanged] = useState(false)
    const [isTouched, setIsTouched] = useState(false)
    const [isValid, setIsValid] = useState('')
    const [errors, setErrors] = useState([])
    const [showErrors, setShowErrors] = useState(false)

    if (name?.includes('storage')) {
        // console.log("initialValue", initialValue.value);
    }

    const addErrorHandler = (newError) => {
        if (!errors.includes(newError)) {
            setErrors((prevState) => [...prevState, newError])
        }
    }

    useEffect(() => {
        const validateTimer = setTimeout(() => {
            if (isString(initialValue)) {
                setIsChanged(initialValue !== enteredValue)
            } else if (inputType.toLowerCase() === 'date') {
                setIsChanged(new Date(enteredValue) - new Date(initialValue) !== 0)
            } else {
                setIsChanged(
                    parseFloat(initialValue).toFixed(2) !== parseFloat(enteredValue).toFixed(2)
                )
            }

            if (isRequired) {
                if (enteredValue?.trim?.() === '') {
                    addErrorHandler('Required Field')
                }
            }
            if (enteredValue) {
                if (regexStringValidator.regexString !== '' && enteredValue !== '') {
                    if (!regexStringValidator?.regexString?.test(enteredValue))
                        addErrorHandler(
                            regexStringValidator?.errorMsg
                                ? regexStringValidator?.errorMsg
                                : 'Text pattern does not meet requirements'
                        )
                    // setErrors((prevState) => [
                    //   ...prevState,
                    //   regexStringValidator.errorMsg
                    //     ? regexStringValidator.errorMsg
                    //     : "Text pattren does not meet requirements",
                    // ]);
                }

                if (additionalValidator.validatorFunction !== null) {
                    if (!additionalValidator(enteredValue))
                        addErrorHandler(
                            additionalValidator.errorMsg
                                ? additionalValidator.errorMsg
                                : 'Invalid value'
                        )
                    // setErrors((prevState) => [
                    //   ...prevState,
                    //   additionalValidator.errorMsg
                    //     ? additionalValidator.errorMsg
                    //     : "Invalid value",
                    // ]);
                }

                if (max.value || min.value || min.value === 0 || max.value === 0) {
                    try {
                        if (
                            inputType.toLowerCase() !== 'date' &&
                            (isNaN(Number(max.value)) || isNaN(!Number(min.value)))
                        ) {
                            throw new Error('Max and/or Min values must be numbers')
                        } else if (!inputType) {
                            throw new Error('Input type should be provided!')
                        }
                        switch (inputType.toLowerCase()) {
                            case 'text':
                            case 'password':
                                if (
                                    (max.value || max.value === 0) &&
                                    enteredValue.length > max.value
                                ) {
                                    addErrorHandler(
                                        max.customErrorMsg
                                            ? max.customErrorMsg
                                            : `Length is more than ${max.value} characters`
                                    )
                                    // setErrors((prevState) => [
                                    //   ...prevState,
                                    // max.customErrorMsg
                                    //   ? max.customErrorMsg
                                    //   : `Length is more than ${max.value} characters`,
                                    // ]);
                                    setEnteredValue((prevState) =>
                                        prevState.substring(0, max.value)
                                    )
                                } else if (min && enteredValue && enteredValue.length < min.value) {
                                    addErrorHandler(
                                        min.customErrorMsg
                                            ? min.customErrorMsg
                                            : `Length must be at least ${min.value} characters`
                                    )
                                    // setErrors((prevState) => [
                                    //   ...prevState,
                                    // min.customErrorMsg
                                    //   ? min.customErrorMsg
                                    //   : `Length must be at least ${min.value} characters`,
                                    // ]);
                                }
                                break
                            case 'number':
                                if ((max.value || max.value === 0) && +enteredValue > max.value) {
                                    addErrorHandler(
                                        max?.customErrorMsgs
                                            ? max.customErrorMsg
                                            : `Value cannot be greater than ${max.value}`
                                    )
                                    // setErrors((prevState) => [
                                    //   ...prevState,
                                    //   max.customErrorMsgs
                                    //     ? max.customErrorMsg
                                    //     : `Value cannot be greater than ${max.value}`,
                                    // ]);
                                } else if (
                                    (min.value || min.value === 0) &&
                                    enteredValue &&
                                    +enteredValue < min.value
                                ) {
                                    addErrorHandler(
                                        min?.customErrorMsg
                                            ? min.customErrorMsg
                                            : `Value cannot be lower than ${min.value}`
                                    )
                                    // setErrors((prevState) => [
                                    //   ...prevState,
                                    //   min.customErrorMsg
                                    //     ? min.customErrorMsg
                                    //     : `Value cannot be lower than ${min.value}`,
                                    // ]);
                                }
                                break
                            case 'date':
                                if (new Date(enteredValue) < new Date(min.value))
                                    addErrorHandler(
                                        min.customErrorMsg
                                            ? min.customErrorMsg
                                            : `Date cannot be set to the past`
                                    )
                                if (new Date(enteredValue) > new Date(max.value))
                                    addErrorHandler(
                                        min.customErrorMsg
                                            ? min.customErrorMsg
                                            : `Date cannot exceed ${
                                                  new Date(max.value).toISOString().split('T')[0]
                                              }`
                                    )
                                break

                            default:
                        }
                    } catch (error) {
                        console.error(error)
                    }
                }
            }
            setIsValid(errors.length === 0)
        }, 500)
        return () => {
            clearTimeout(validateTimer)
        }
    }, [enteredValue])

    useEffect(() => {
        //Invalid messages should only kick in once the user touched the field and/or
        setIsValid(errors.length === 0)
        setShowErrors(isTouched && errors.length > 0)
    }, [isTouched, errors])

    const onChangeHandler = (event) => {
        validateOnChange && setIsTouched(true)
        resetValidation()
        setEnteredValue(event.target.value)
    }

    const onBlurHandler = () => {
        if (!isTouched) setIsTouched(true)
    }

    const onFocusHandler = () => {}

    const resetValidation = () => {
        setErrors('')
        setIsValid(false)
        setIsTouched(false)
    }

    const reset = () => {
        resetValidation()
        setIsChanged(false)
        setIsTouched(false)
    }

    const resetToInitialValue = () => {
        reset()
        setEnteredValue(initialValue)
    }

    return {
        value: enteredValue,
        initialValue,
        isValid,
        isTouched,
        isChanged,
        showErrors,
        errors,
        onChangeHandler,
        onBlurHandler,
        onFocusHandler,
        reset,
        resetToInitialValue,
        name,
    }
}
