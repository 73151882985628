import type { PaletteMode, ThemeOptions } from '@mui/material'
import { createTheme as muiCreateTheme } from '@mui/material/styles'
import { OVERRIDES } from './overrides'
import { LIGHT_PALETTE, DARK_PALETTE } from './palette'
import { SHADOWS } from './shadows'
import { TYPOGRAPHY } from './typography'

type CreateThemeType = {
    mode: PaletteMode
    options?: ThemeOptions
}

export const createTheme = ({ mode, options: customOptions }: CreateThemeType) => {
    const palette = mode === 'dark' ? DARK_PALETTE : LIGHT_PALETTE
    const { components: customComponents, ...restCustomOptions } = customOptions || {}

    return muiCreateTheme({
        palette,
        shadows: SHADOWS,
        components: { ...OVERRIDES, ...customComponents },
        spacing: (factor: number) => `${0.25 * factor}rem`,
        typography: TYPOGRAPHY,
        ...restCustomOptions,
    })
}
