import { APP_ROUTES } from 'models/appConfig'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useOctupDispatch } from '../../hooks/useOctupDispatch'
import { OctupColors } from '../../shared/theme/colors/OctupColors'

export const NoDataError = ({ text, anchorText }) => {
    const navigate = useNavigate()
    const octupDispatch = useOctupDispatch()
    const handleRedirect = (event) => {
        event.preventDefault()
        event.stopPropagation()
        octupDispatch.sidebar.setActivePage('Integrations')
        navigate(`/${APP_ROUTES.INTEGRATIONS}`)
    }

    return (
        <NoDataErrorStyled>
            <span>{text ? `${text} ` : `No data to display,`}</span>
            <a
                style={{ color: 'rgba(0, 127, 130, 1)' }}
                onClick={(e) => handleRedirect(e)}
                href="integrations"
            >
                {anchorText ? `${anchorText} ` : `connect an integration`}
            </a>
        </NoDataErrorStyled>
    )
}

export const NoDataErrorTooltipInfo = () => {
    const navigate = useNavigate()

    const handleRedirect = (event) => {
        event.preventDefault()
        event.stopPropagation()
        // alert('in_hover')
        // setActivePage({ activePage: "Integrations" });
        navigate(`/${APP_ROUTES.INTEGRATIONS}`)
    }

    return (
        <NoDataErrorStyled>
            <span>Data source missing, go to the</span>
            <p>
                <span
                    href="integrations"
                    style={{ color: 'rgba(0, 127, 130, 1)' }}
                    onClick={(e) => handleRedirect(e)}
                >
                    Intergrations screen
                </span>
                <span> to connect</span>
            </p>
        </NoDataErrorStyled>
    )
}

const NoDataErrorStyled = styled.div`
    font-size: 1.5rem;
    max-width: 100rem;
    color: ${OctupColors.primaryTextLight};

    a,
    span {
        font-size: 1.5rem;
    }

    a {
        cursor: pointer;
    }
`
