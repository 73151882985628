export const SEGMENTS = {
    SYS: 0,
    HOME: 1,
    MARKETING: 2,
    OPERATIONS: 3,
    GRAPH: 4,
    ALTERNATIVE_HOME_METRICS: 5,
    ALTERNATIVE_HOME_YESTERDAY: 6,
    ALTERNATIVE_HOME_OPERATIONS: 7,
    ALTERNATIVE_HOME_MARKETING: 8,
    GMB_TOP: 9,
    GMB_TABLE: 10,
    DEEPDIVE_AOV: 18,
    DEEPDIVE_SALES: 19,
    DEEPDIVE_LOGISTICS: 20,
    DEEPDIVE_RETURNS: 21,
    DEEPDIVE_AD_SPEND: 22,
    DEEPDIVE_ORDER_METRICS: 23,
    DEEPDIVE_AVG_ITEMS_PER_ORDER: 24,
    DEEPDIVE_RETURNING_CUSTOMERS: 25,
    DEEPDIVE_ROAS: 26,
    DEEPDIVE_INCOME_FROM_PPC_SOURCES: 30,
    MOBILE_HOME: 31,
    GMB_AD_SPEND_SUBSECTIONS: 32,
    GMB_INCOME_PPC_SUBSECTIONS: 33,
    MARKERING_AD_SPEND_BY_CHANNELS: 34,
    OPERATIONS_CUSTOMER_SUPPORT: 35,
    OPERATIONS_FULLFILLED_ORDERS: 36,
    MARKERING_ROAS: 37,
    MARKERING_AD_SPEND_BY_CHANNELS_GRAPH: 42,
}

export const METRICS_TO_DEEP_DIVE = {
    average_gross_order_value_usd: SEGMENTS.DEEPDIVE_AOV,
    sales: SEGMENTS.DEEPDIVE_SALES,
    logistics_cost: SEGMENTS.DEEPDIVE_LOGISTICS,
    refunds_shopify: SEGMENTS.DEEPDIVE_RETURNS,
    total_ad_spend: SEGMENTS.DEEPDIVE_AD_SPEND,
    order_number_gross: SEGMENTS.DEEPDIVE_ORDER_METRICS,
    avg_number_items_per_order: SEGMENTS.DEEPDIVE_AVG_ITEMS_PER_ORDER,
    returning_customers_percentage: SEGMENTS.DEEPDIVE_RETURNING_CUSTOMERS,
    // roas: SEGMENTS.DEEPDIVE_ROAS,
    // dd:SEGMENTS.DEEPDIVE_INCOME_FROM_PPC_SOURCES
}

export const DEEP_DIVE_TO_METRICS = {
    [SEGMENTS.DEEPDIVE_AOV]: 'average_gross_order_value_usd',
    [SEGMENTS.DEEPDIVE_SALES]: 'sales',
    [SEGMENTS.DEEPDIVE_LOGISTICS]: 'logistics_cost',
    [SEGMENTS.DEEPDIVE_RETURNS]: 'refunds_shopify',
    [SEGMENTS.DEEPDIVE_AD_SPEND]: 'total_ad_spend',
    [SEGMENTS.DEEPDIVE_ORDER_METRICS]: 'order_number_gross',
    [SEGMENTS.DEEPDIVE_AVG_ITEMS_PER_ORDER]: 'avg_number_items_per_order',
    [SEGMENTS.DEEPDIVE_RETURNING_CUSTOMERS]: 'returning_customers_percentage',
}

export const VALUE_TYPE = {
    FLOAT: 1,
    PERCENTAGE: 2,
    INT: 3,
}
