import type {
    METRICS_SECTION_TYPE,
    MetricsSectionStateByIdType,
    MetricsSectionType,
    MetricType,
} from '@octup/core/models'
import type { MetricsSectionsListType } from '@/models/metrics'
import { mapArrayToObject } from '@/utils'

type DeepDiveMetricType = Partial<MetricType> & {
    metric_id: MetricType['id']
    metricTitle: MetricType['name']
    isPositiveTrend: MetricType['is_positive_trend']
    valueUnits: MetricType['value_units']
    trendValue: MetricType['trend_value']
    trendUnits: MetricType['trend_units']
    blended_dive_in: MetricType['blended_deep_dive']
}

type DeepDiveMetricsListType = Record<'metric', DeepDiveMetricType>[]

// NOTE: map metrics to support the old format for deep dive
export const getDeepDiveMetrics = (metrics: MetricType[]) =>
    metrics.reduce((acc: DeepDiveMetricsListType, metric) => {
        if (!metric.deep_dive) return acc

        const {
            id,
            name,
            is_positive_trend,
            value_units,
            trend_value,
            trend_units,
            blended_deep_dive,
            ...metricParams
        } = metric

        return [
            ...acc,
            {
                metric: {
                    metric_id: id,
                    metricTitle: name,
                    isPositiveTrend: is_positive_trend,
                    valueUnits: value_units,
                    trendValue: trend_value,
                    trendUnits: trend_units,
                    blended_dive_in: blended_deep_dive,
                    ...metricParams,
                },
            },
        ]
    }, [])

export const getDeepDiveMetricsBySectionType = (
    sectionsStateById: MetricsSectionStateByIdType,
    sectionsList: MetricsSectionsListType
) => {
    const sectionsByType = mapArrayToObject<MetricsSectionType>(sectionsList, 'type')

    const metricsBySectionType = Object.entries(sectionsByType || {}).reduce(
        (acc, data) => {
            const [type, sections] = data as [METRICS_SECTION_TYPE, MetricsSectionType[]]
            const metrics = sections.flatMap((section) =>
                Object.values(sectionsStateById?.[section.id]?.data?.metrics || {})
            )

            return { ...acc, [type]: getDeepDiveMetrics(metrics) }
        },
        {} as Record<METRICS_SECTION_TYPE, DeepDiveMetricsListType>
    )

    if (Object.keys(metricsBySectionType).length === 0) return

    return metricsBySectionType
}
