import { Stack } from '@mui/material'
import PageLayout from 'components/PageLayout/PageLayout'
import { LogisticsFilters, PageContent } from 'new_components/Logistics'
import { containerStyle } from './LogisticsPage.styles'

export const LogisticsPage = ({ hasWarehouses, error, ...props }) => (
    <PageLayout error={error} showSidePanel={hasWarehouses}>
        <Stack gap={5} sx={containerStyle}>
            {hasWarehouses && <LogisticsFilters />}
            <PageContent hasWarehouses={hasWarehouses} {...props} />
        </Stack>
    </PageLayout>
)
