import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as services from 'services/unitEconomics'

const DELETED_COST_INITIAL_STATE = {
    data: null,
    isLoading: false,
    error: null,
}

export const fetchDeleteCost = createAsyncThunk('fetchDeleteCost', async (data, thunkAPI) =>
    services.deleteCost(data, thunkAPI)
)

export const deletedCostSlice = createSlice({
    name: 'deletedCost',
    initialState: DELETED_COST_INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeleteCost.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchDeleteCost.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchDeleteCost.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const deletedCostReducer = deletedCostSlice.reducer
