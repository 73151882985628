import {
    HorizontalDivider,
    ItemImageAndName,
    PaymentText,
    PaymentTotalContainer,
    PaymentTotalText,
} from './SingleOrderDetails.styles'
import { getItemsTotalCost } from './util'
import { getCurrencySign } from '../../../shared/utils/strings-util'

export function PaymentsData({ getItemsLength, items, singleOrder }) {
    return (
        <>
            <ItemImageAndName justifyContent={'space-between'}>
                <PaymentText>{`Subtotal      ${getItemsLength(items)} items`}</PaymentText>
                <PaymentText>
                    {getItemsTotalCost({
                        items,
                        currencyCode: singleOrder?.data?.order?.currencyCode,
                    })}
                </PaymentText>
            </ItemImageAndName>
            <HorizontalDivider />
            {singleOrder?.payments?.subtotal?.amount}
            <ItemImageAndName justifyContent={'space-between'}>
                <PaymentText>{`Discount      ${
                    singleOrder?.data?.order?.discountCode
                        ? singleOrder?.data?.order?.discountCode
                        : 'None'
                }`}</PaymentText>
                <PaymentText>
                    {singleOrder?.data?.order?.discountCode &&
                        '-' +
                            getCurrencySign((items, singleOrder?.data?.order?.currencyCode)) +
                            singleOrder?.data?.order?.cartDiscountAmountSet?.shopMoney?.amount}
                </PaymentText>
            </ItemImageAndName>
            <HorizontalDivider />
            <ItemImageAndName justifyContent={'space-between'}>
                <PaymentText>{`Shipping  ${singleOrder?.data?.order?.shippingLine?.code}`}</PaymentText>
                <PaymentText>
                    {'+' +
                        getCurrencySign((items, singleOrder?.data?.order?.currencyCode)) +
                        singleOrder?.data?.order?.shippingLine?.originalPriceSet?.presentmentMoney
                            ?.amount}
                </PaymentText>
            </ItemImageAndName>
            <PaymentTotalContainer>
                <PaymentTotalText>{`Total `}</PaymentTotalText>
                <PaymentTotalText>
                    {getCurrencySign((items, singleOrder?.data?.order?.currencyCode)) +
                        singleOrder?.data?.order?.totalPriceSet?.presentmentMoney?.amount}
                </PaymentTotalText>
            </PaymentTotalContainer>
        </>
    )
}
