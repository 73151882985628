import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { DashboardListType } from 'models/dashboards'
import { getDashboardsList } from 'services/dashboards'
import type { ThunkAPIConfigType } from 'services/utils'
import { v4 as uuidv4 } from 'uuid'

type DashboardsListStateType = {
    data: DashboardListType[]
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: DashboardsListStateType = {
    data: [],
    isLoading: false,
    error: undefined,
}

export const fetchDashboardsList = createAsyncThunk<
    DashboardListType[],
    undefined,
    ThunkAPIConfigType
>('fetchDashboardsList', (_, thunkAPI) => getDashboardsList(thunkAPI))

export const dashboardsListSlice = createSlice({
    name: 'dashboardsList',
    initialState: INITIAL_STATE,
    reducers: {
        addNewDashboard(state, action) {
            const newDashboard = { id: uuidv4(), name: 'New Board', isNew: true, ...action.payload }
            state.data = [...state.data, newDashboard]
            action.payload = newDashboard
        },
        updateDashboardList(state, action) {
            const { id: updatedId, newData } = action.payload
            state.data = state.data.map((dashboard) =>
                dashboard.id === updatedId ? { ...dashboard, ...newData } : dashboard
            )
        },
        removeDashboard(state, action) {
            state.data = state.data.filter((dashboard) => dashboard.id !== action.payload)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardsList.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchDashboardsList.fulfilled, (state, action) => {
                const newDashboard = state.data.find((dashboard) => dashboard.isNew)
                state.data = [...action.payload, ...(newDashboard ? [newDashboard] : [])]
                state.isLoading = false
            })
            .addCase(fetchDashboardsList.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const { addNewDashboard, updateDashboardList, removeDashboard } = dashboardsListSlice.actions
export const dashboardsListReducer = dashboardsListSlice.reducer
