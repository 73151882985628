import { Box, Stack } from '@mui/material'
import { Text } from 'shared/styledComponents'
import { OctupColors } from 'shared/theme/colors/OctupColors'
import { AccountRow } from './AccountRow'
import { containerStyle, titleStyle } from './SelectAccountsForm.styles'
import { SELECT_ALL } from 'new_containers/components/Integrations'

export const SelectAccountsForm = ({ accounts }) => (
    <Stack sx={containerStyle}>
        <Box sx={titleStyle}>
            <Text fontWeight="bold" fontSize="1.8rem">
                Select the accounts you wish to include in the integration:
            </Text>
        </Box>
        <AccountRow
            isHeader
            color={OctupColors.inputLabels}
            fieldName={SELECT_ALL}
            displayName="Select All"
            displayId="ID"
        />
        {accounts?.map(({ id, displayName }) => (
            <AccountRow key={id} fieldName={`${id}`} displayName={displayName} displayId={id} />
        ))}
    </Stack>
)
