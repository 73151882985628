import { ERROR_MESSAGES } from 'constants/validation'
import { SELECT_ALL_OPTION } from '@octup/core/ui-kit'
import { COST_FORM_VALIDATION_SCHEMA } from 'new_containers/components/UnitEconomics'
import { array } from 'yup'

export const getActionButtons = ({ onCancel, isEditState }) => [
    {
        title: 'cancel',
        variant: 'secondary',
        onClick: onCancel,
    },
    {
        title: isEditState ? 'save and close' : 'add cost',
        variant: 'contained',
        disabled: false,
        type: 'submit',
    },
]

export const getDefaultValues = (data) => ({
    cost_name: data?.cost_name || '',
    value: data?.updated_value || '',
    products: data?.products?.[0] === 'all' ? [SELECT_ALL_OPTION] : data?.products || [],
    is_divided: data?.is_divided || false,
})

export const VALIDATION_SCHEMA = COST_FORM_VALIDATION_SCHEMA.shape({
    products: array().min(1, ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
})

export const convertToAPI = ({ data, products, selectedEl }) => ({
    ...data,
    cost_id: selectedEl?.cost_id,
    is_variant: false,
    products:
        data.products.length === products.length
            ? 'all'
            : data.products.map(({ product_id }) => product_id),
})
