import {
    GrayCircle,
    JourneyDateContainer,
    JourneyElements,
    JourneyImage,
    JourneyItemContainer,
    JourneyItemsContainer,
    JourneyItemText,
    JourneyLine,
    SubGroupDate,
    TitleDiv,
} from './SingleOrderDetails.styles'
import { getInputDate, getTimeAgo } from '../../../shared/utils/dates-util'
import cheerio from 'cheerio'

const handleJourneyMessage = (message) => {
    let hrefAttribute = null
    const $ = cheerio.load(message)
    const $a = $('a')
    if ($a.length > 0) {
        hrefAttribute = $a.attr('href') // Get the value of the href attribute
    }
    const extractedText = $.text().trim()

    if (hrefAttribute && extractedText) {
        return (
            <a href={hrefAttribute}>
                <JourneyItemText>{extractedText}</JourneyItemText>
            </a>
        )
    } else {
        return message
    }
}

export function JourneyItem({ reversedNodes, subgroupDate, showSubgroupDate }) {
    return (
        <JourneyItemsContainer>
            <TitleDiv>Journey</TitleDiv>
            {reversedNodes?.map((node, index) => {
                if (subgroupDate !== getTimeAgo(node?.createdAt)) {
                    subgroupDate = getTimeAgo(node?.createdAt)
                    showSubgroupDate = true
                } else {
                    showSubgroupDate = false
                }
                return (
                    <div key={`${node?.createdAt}-${node?.message}`}>
                        {showSubgroupDate && <SubGroupDate>{subgroupDate}</SubGroupDate>}
                        <JourneyItemContainer>
                            <JourneyImage>
                                <GrayCircle />
                                {reversedNodes.length - 1 > index && <JourneyLine />}
                            </JourneyImage>
                            <JourneyElements>
                                <JourneyItemText>
                                    {handleJourneyMessage(node?.message)}
                                </JourneyItemText>
                            </JourneyElements>
                            <JourneyDateContainer>
                                {getInputDate(node?.createdAt, 'time')}
                            </JourneyDateContainer>
                        </JourneyItemContainer>
                    </div>
                )
            })}
        </JourneyItemsContainer>
    )
}
