import type { DashboardTemplateType, MetricsSectionType } from '@octup/core/models'
import type { MetricsSectionsListType, MetricsSectionsCategoryType } from '@/models/metrics'
import { mapArrayToObject } from '@/utils'

type MetricsSectionsByGroupType = Record<string, MetricsSectionsCategoryType[]>

export const getMetricsSectionsListsByGroup = (
    template?: DashboardTemplateType,
    metricsSectionsList?: MetricsSectionsListType
): MetricsSectionsByGroupType | undefined => {
    if (!metricsSectionsList || !template) return

    const metricsSectionsListByType = mapArrayToObject<MetricsSectionType>(
        metricsSectionsList,
        'type'
    )

    const metricsSectionsListByGroup = template.config?.reduce((acc, { group, icon, types }) => {
        const sections = types.reduce(
            (acc, type) => [...acc, ...(metricsSectionsListByType?.[type] || [])],
            [] as MetricsSectionsListType
        )

        const sectionsByCategory = mapArrayToObject<MetricsSectionType>(sections, 'category')
        const categorySections = Object.entries(sectionsByCategory || {}).reduce(
            (acc, [category, sections]) => [
                ...acc,
                { category, sections: sections.map((section) => ({ ...section, icon })) },
            ],
            [] as MetricsSectionsCategoryType[]
        )

        return { ...acc, [group]: categorySections }
    }, {})

    return metricsSectionsListByGroup
}
