import { Grid } from '@mui/material'

export const SourceTitle = ({
    campaignName,
    campaignsCount,
    fontSize = 'inherit',
    integrationTitle,
    hasCount,
    icon: Icon,
    ...props
}) => (
    <Grid container alignItems="center" spacing={1} wrap="nowrap">
        {Icon && (
            <Grid item lineHeight={0}>
                <Icon />
            </Grid>
        )}
        <Grid
            item
            fontSize={fontSize}
            lineHeight={0}
            textTransform="capitalize"
            component="p"
            whiteSpace="nowrap"
            {...props}
        >
            {campaignName || `${integrationTitle} ${hasCount ? `(${campaignsCount})` : ''}`}
        </Grid>
    </Grid>
)
