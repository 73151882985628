import { useCallback, useState } from 'react'
import { Stack } from '@octup/core/ui-kit'

export type FallbackImagePropsType = React.ImgHTMLAttributes<HTMLImageElement> & {
    fallbackSrc: string
    children?: React.ReactNode
}

export const FallbackImageContainer = ({
    src,
    alt,
    fallbackSrc,
    children,
    ...props
}: FallbackImagePropsType): JSX.Element => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)

    const handleLoad = useCallback(() => {
        setIsLoaded(true)
    }, [])

    const handleError = useCallback(
        ({ currentTarget }: React.SyntheticEvent<HTMLImageElement>) => {
            currentTarget.onerror = null
            currentTarget.src = fallbackSrc
            setIsError(true)
        },
        [fallbackSrc]
    )

    return (
        <Stack>
            <img src={src} alt={alt} onError={handleError} onLoad={handleLoad} {...props} />
            {isLoaded && !isError && children}
        </Stack>
    )
}
