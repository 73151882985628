import { createContext, useState } from 'react'
export const GMBContext = createContext()

export const GMBContextProvider = ({ children }) => {
    const [showGMBoard, setShowGMBoard] = useState(false)

    return (
        <GMBContext.Provider value={{ showGMBoard, setShowGMBoard }}>
            {children}
        </GMBContext.Provider>
    )
}
