import React from 'react'
import { OctupColors } from '../../theme/colors/OctupColors'

export function UserIconSvg({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M11.9998 14.7395C14.5503 14.7395 16.6178 12.5593 16.6178 9.86976C16.6178 7.18027 14.5503 5 11.9998 5C9.44939 5 7.38184 7.18027 7.38184 9.86976C7.38184 12.5593 9.44939 14.7395 11.9998 14.7395Z"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
            <path
                d="M5 18.9998C5.7097 17.7044 6.73017 16.6288 7.9589 15.881C9.18763 15.1332 10.5813 14.7395 12 14.7395C13.4187 14.7395 14.8124 15.1332 16.0412 15.8811C17.2699 16.6289 18.2903 17.7046 19 19"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
