export const popperStyle = (theme) => ({
    paddingTop: theme.spacing(1),
})

export const containerStyle = (theme) => ({
    padding: theme.spacing(4),
})

export const titleStyle = {
    fontWeight: 600,
}
