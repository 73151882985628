import React from 'react'

export function HamburgerWithRightArrowSvg() {
    return (
        <svg
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.5714 9L18 12L14.5714 15"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M10 18H7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M12 12H7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M10 6L7 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    )
}
