import { CUSTOM_SEGMENTS } from 'constants/integrations'

export const DEFAULT_ACTIVE_TAB = { title: CUSTOM_SEGMENTS.ALL }

export const getTabsData = (integrationsBySegment) => {
    if (!integrationsBySegment) return []

    return Object.entries(integrationsBySegment).map(([segment, integrations]) => ({
        title: segment,
        isActive: false,
        count: integrations.length,
    }))
}
