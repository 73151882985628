import { add3Dots } from '../../../../shared/utils/strings-util'
import { octupDictionary } from '../../../../shared/dictionary/dictionary'
import React from 'react'
import styled from 'styled-components'
import { MobileCardValueWithCharts } from '../../../../shared/components/CardValueWithChart/MobileCardValueWithChart'

export const renderMobileCardItem = ({
    element,
    isLoading,
    isRejected,
    index,
    showCard,
    isFilled = false,
}) => {
    return (
        <ElementDiv key={`${element?.metric?.metricTitle + index}`}>
            {(isLoading || element?.metric) && (
                <MobileCardValueWithCharts
                    title={add3Dots(
                        octupDictionary.titles.metricsTitles[`${element?.metric?.metricTitle}`] ||
                            `${element?.metric?.metricTitle}`
                    )}
                    metricTitleKey={element?.metric?.metricTitle}
                    currency={element?.metric?.currency}
                    type={element?.metric?.type}
                    showSecondYAxis={false}
                    isLoading={isLoading || !element?.metric}
                    isRejected={isRejected}
                    value={`${element?.metric?.value}`}
                    valueUnits={`${element?.metric?.valueUnits}`}
                    trend={element?.metric?.trendValue}
                    trendUnits={element?.metric?.trendUnits}
                    trendValue={element?.metric?.trendValue}
                    showChart={!!element?.dataset}
                    chartData={element?.dataset?.map((el) => el[0])}
                    chartLabels={element?.dataset?.map((el) => el[1])}
                    timeSlice={element?.metric?.time_slice}
                    isPositiveTrend={element?.metric?.isPositiveTrend}
                    showCard={true}
                    isFilled={isFilled}
                    isSupported={element?.metric?.supported}
                    isStale={element?.metric?.stale}
                    lastUpdatedAt={element?.metric?.last_updated_date}
                />
            )}
        </ElementDiv>
    )
}

const ElementDiv = styled.div`
    margin: 0;
    padding: 3%;
`
