export const downloadFile = (csvData: string, name: string) => {
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
}

export const getFileNameFromHeader = (header: string) => {
    const matchArray = header.match(/filename=((['"]).*?\2|[^;\n]*)/)
    if (!matchArray) return null
    const matchString = matchArray.shift()
    return matchString?.replace(/['"]|(filename=)/g, '')
}
