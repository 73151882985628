import { getCurrencySign } from '../../../shared/utils/strings-util'

const safeSingleOrderValue = (value) => {
    if (value) {
        return value
    }
    return '-'
}

function compareObjects(obj1, obj2) {
    if (!obj1 || !obj2) return false
    for (const key in obj1) {
        if (obj1.hasOwnProperty(key)) {
            if (!obj2.hasOwnProperty(key) || obj2[key] !== obj1[key]) {
                return false
            }
        }
    }
    return true
}

const getItemsTotalCost = ({ items, currencyCode, discount = 0, shipping = 0 }) => {
    if (items?.length === 0) {
        return 0
    } else {
        let res = items?.reduce((agg, item) => {
            return (
                +agg + +item?.originalUnitPriceSet?.presentmentMoney?.amount * item?.currentQuantity
            )
        }, 0)
        const calc = +(+res - +discount + +shipping)
        return getCurrencySign(currencyCode) + parseFloat(`${calc}`).toFixed(2)
    }
}

const getItemsLength = (items) => {
    if (items) {
        return items.reduce((agg, item) => {
            return (agg += +item?.currentQuantity)
        }, 0)
    }
}

export { safeSingleOrderValue, getItemsTotalCost, compareObjects, getItemsLength }
