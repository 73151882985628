import type { EffectCallback } from 'react'
import { useRef, useEffect } from 'react'

// NOTE: useEffect that only triggers on the first render

export const useOnMountEffect = (effect: EffectCallback) => {
    const initialized = useRef(false)

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            effect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}
