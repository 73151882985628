import { useState } from 'react'
import { AppBar, Toolbar, Grid, SvgIcon, Typography, IconButton } from '@octup/core/ui-kit'
import { useSelector } from 'react-redux'
import { HamburgerIcon } from '@/new_assets/icons'
import { CompanyNameLogoContainer } from '@/new_containers/components/__common__'
import MobileDrawer from './MobileDrawer'

export default function MobileTopBar({ showMenu = false }) {
    const { userInfo } = useSelector((state) => state.auth)
    const [toggleState, setToggleState] = useState(false)
    const { settings } = useSelector((state) => state.appConfig)
    const { palette, palette_mode } = settings.data

    return (
        <AppBar position="static" elevation={0} sx={appBarStyle(palette, palette_mode)}>
            <Toolbar>
                <Grid container justifyContent="space-between" wrap="nowrap" columnSpacing={5}>
                    <Grid item container justifyContent={showMenu ? 'flex-start' : 'center'}>
                        <CompanyNameLogoContainer />
                    </Grid>
                    {showMenu && (
                        <>
                            <Grid
                                item
                                container
                                justifyContent="space-between"
                                alignItems="center"
                                wrap="nowrap"
                            >
                                <Typography color="inherit">{userInfo?.client?.name}</Typography>
                                <IconButton
                                    size="small"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={setToggleState}
                                >
                                    <SvgIcon component={HamburgerIcon} />
                                </IconButton>
                            </Grid>
                            <MobileDrawer
                                toggleState={toggleState}
                                setToggleState={setToggleState}
                            />
                        </>
                    )}
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

const appBarStyle = (palette, paletteMode) => () => ({
    '&.MuiAppBar-root': {
        boxShadow: 'none',
        padding: '1.5rem 0',
        borderRadius: 0,
        color: palette.text.primary,
        backgroundColor:
            paletteMode === 'dark' ? palette.background.default : palette.background.paper,
    },
})
