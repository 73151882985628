import { useCallback, useMemo } from 'react'
import { useToast } from 'hooks'
import { getActionButtons, DEFAULT_VALUES, DISCONNECT_PATH } from './DisconnectModalContainer.utils'
import { DisconnectModal } from 'new_components'
import { FormContainer } from 'new_containers/components/__common__'

export const DisconnectModalContainer = ({ integration, integrationTitle, onClose }) => {
    const toast = useToast()
    const actionButtons = useMemo(() => getActionButtons({ onCancel: onClose }), [onClose])

    const handleDisconnect = useCallback(
        (data) => {
            onClose()
            toast.process(`Hold on, we’re disconnecting ${integrationTitle}`)
            window.location.href = `${DISCONNECT_PATH}?source=${integration}&keepData=${data.isKeepData}`
        },
        [integration, integrationTitle, onClose, toast]
    )

    const renderContent = useCallback(
        ({ content }) => (
            <FormContainer defaultValues={DEFAULT_VALUES} onSubmit={handleDisconnect}>
                {content}
            </FormContainer>
        ),
        [handleDisconnect]
    )

    return (
        <DisconnectModal
            actionButtons={actionButtons}
            integrationTitle={integrationTitle}
            onDismiss={onClose}
            renderContent={renderContent}
        />
    )
}
