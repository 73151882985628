import * as React from 'react'
import { useEffect } from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import styled from 'styled-components'
import { MobileUserAvatar } from '../../../components/MobileUserAvatar/MobileUserAvatar'
import { useDispatch, useSelector } from 'react-redux'
import { MobileUserIcon } from '../../../shared/assets/icons/MobileUserIcon'
import { MobileFeedbackIcon } from '../../../shared/assets/icons/MobileFeedbackIcon'
import { useNavigate } from 'react-router-dom'
import { MobileSignOut } from '../../../shared/assets/icons/MobileSignOut'
import { logout } from '../../../features/auth/authSlice'

const ListItems = [
    { title: 'My Account', icon: MobileUserIcon, navigation: 'Account' },
    // {title: 'Settings', icon: MobileSettingsIcon},
    { title: 'Send Feedback', icon: MobileFeedbackIcon, navigation: 'Feedback' },
    // {title: 'Share With a Friend', icon: MobileShareIcon}
]
export default function MobileDrawer({ toggleState, setToggleState }) {
    const { userInfo } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })

    useEffect(() => {
        setState({
            top: false,
            left: false,
            bottom: false,
            right: toggleState,
        })
    }, [toggleState])

    const list = () => (
        <StyledBox
            role="presentation"
            onClick={() => setToggleState(!toggleState)}
            onKeyDown={() => setToggleState(!toggleState)}
        >
            <MainContainer>
                <MobileUserAvatar name={userInfo?.fullName || ''} imgSrc={userInfo?.imageSrc} />
                <Title>{userInfo?.fullName}</Title>
                <Subtitle>
                    {'Operations Manager\n'}
                    {`@${userInfo?.client?.name}`}
                </Subtitle>
                <ListsContainer>
                    <SideMenuTopContainer>
                        {ListItems.map((item, index) => (
                            <ListItemInnerContainer
                                key={item.title}
                                onClick={() => {
                                    navigate('/' + item.navigation)
                                }}
                            >
                                <ButtonContainer>{item.icon({ active: true })}</ButtonContainer>
                                <StyledListItemText>{item.title}</StyledListItemText>
                            </ListItemInnerContainer>
                        ))}
                    </SideMenuTopContainer>
                    <SideMenuBottomContainer
                        onClick={() => {
                            dispatch(logout())
                        }}
                    >
                        <ListItemInnerContainer>
                            <ButtonContainer>
                                <MobileSignOut />
                            </ButtonContainer>
                            <StyledListItemText>Logout</StyledListItemText>
                        </ListItemInnerContainer>
                    </SideMenuBottomContainer>
                </ListsContainer>
            </MainContainer>
        </StyledBox>
    )

    return (
        <React.Fragment key={state.right}>
            <Background>
                <SwipeableDrawer
                    anchor={'right'}
                    open={state.right}
                    onClose={() => setToggleState(!toggleState)}
                    onOpen={() => setToggleState(!toggleState)}
                >
                    {list()}
                </SwipeableDrawer>
            </Background>
        </React.Fragment>
    )
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8vw;
    gap: 8px;
    height: 100vh;
    border-radius: 16px 0px 0px 16px;
`

const ListsContainer = styled.div`
    display: flex;
    width: 125%;
    height: 75%;
    flex-direction: column;
    justify-content: space-between;
`

const StyledBox = styled(Box)`
    width: 100%;
    background: linear-gradient(44.56deg, #504b5a -1.25%, #282831 100.79%);
    overflow: hidden;
`

const Background = styled.div`
    background: linear-gradient(44.56deg, #504b5a -1.25%, #282831 100.79%);
`

const Title = styled.span`
    font-weight: 600;
    font-size: 2rem;
    line-height: 20px;
    text-align: center;
    color: #f8f7fb;
`

const Subtitle = styled.span`
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    color: #c2c0c7;
    white-space: pre-wrap;
`

const SideMenuTopContainer = styled.div`
    height: 40%;
`

const ListItemInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    justify-content: space-around;
    height: 20%;
    padding-top: 1rem;
`

const StyledListItemText = styled.span`
    font-size: 1.8rem;
    line-height: 3rem;
    color: #f8f7fb;
    padding-left: 1rem;
    text-align: center;
`

const SideMenuBottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    bottom: 0;
    height: 15%;
    border-top: 2px solid #504b5a;
`

const ButtonContainer = styled.div``
