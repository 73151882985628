import { Grid, Stack, SvgIcon, Typography } from '@mui/material'
import { InfoIcon } from '@octup/core/assets/icons'
import { OverflowTypography } from '@octup/core/ui-kit'
import { MetricSources } from '@/components/MetricSources/MetricSources'
import { OctupTooltip } from '@/shared/ui/MUIWrappers/OctupTooltip'
import { VALUE_TYPE_INDEX, getFormattedVal } from '@/shared/utils/metrics-utils'
import { containerStyle, highlightTitleStyle, iconStyle } from './ProductCost.styles'

export const ProductCost = ({
    title,
    icon,
    info,
    value,
    percent,
    sources,
    actions,
    highlightTitle,
    showInfoOnHover,
    showActionsOnHover,
}) => {
    const textStyle = highlightTitle && highlightTitleStyle

    return (
        <Grid container alignItems="center" wrap="nowrap" columnSpacing={2} sx={containerStyle}>
            <Grid item xs={3.5} md={4.5} lg={6} xl={6}>
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <SvgIcon sx={iconStyle}>{icon}</SvgIcon>
                    {typeof title === 'string' ? (
                        <OverflowTypography variant="body2" sx={textStyle}>
                            {title}
                        </OverflowTypography>
                    ) : (
                        title
                    )}
                    {info && (
                        <OctupTooltip
                            title={info}
                            position="top"
                            {...(showInfoOnHover && { className: 'hover-display' })}
                        >
                            <SvgIcon component={InfoIcon} color="text.primary" fontSize="small" />
                        </OctupTooltip>
                    )}
                </Stack>
            </Grid>
            <Grid item container xs={8.5} md={7.5} lg={6} xl={6} alignItems="center" wrap="nowrap">
                {value !== undefined && (
                    <>
                        {!value || typeof value === 'number' ? (
                            <Grid
                                item
                                {...(sources || actions
                                    ? { xs: 6.2, md: 6.2, lg: 5.8 }
                                    : { xs: true })}
                            >
                                <Typography
                                    sx={textStyle}
                                    variant="body2"
                                    {...(percent !== undefined && {
                                        color: percent > 0 ? 'success.main' : 'error',
                                    })}
                                >
                                    {getFormattedVal({
                                        val: value,
                                        valueType: VALUE_TYPE_INDEX.NUMBER,
                                        isSupported: true,
                                        isShopCurrency: true,
                                        showSymbol: true,
                                        customNumOfDigits: 2,
                                    })}
                                    {percent &&
                                        ` (${getFormattedVal({
                                            val: percent,
                                            valueType: VALUE_TYPE_INDEX.PERCENTAGE,
                                            isSupported: true,
                                            multiplyBy100: false,
                                            showSymbol: true,
                                        })})`}
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid item xs={8}>
                                {value}
                            </Grid>
                        )}
                    </>
                )}

                {sources && (
                    <Grid item xs>
                        <MetricSources sources={sources} />
                    </Grid>
                )}

                {actions && (
                    <Grid
                        item
                        xs
                        container
                        justifyContent="flex-end"
                        {...(showActionsOnHover && { className: 'hover-display' })}
                    >
                        {actions}
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
