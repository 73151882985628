import { COMPARISON_MODES } from 'constants/metrics'
import { BREAKDOWN_PROPS_BY_COMPARISON_MODE } from './breakdownComparison'
import { isComparedTitle } from './common'
import { DEFAULT_PROPS_BY_COMPARISON_MODE } from './defaultComparison'
import { generateLegendsLabels } from './generateLegendsLabels'

const getLabels = (data) => data.dataset?.map(([, label]) => label)

const getMappedChartData = (data) =>
    Object.entries(data)
        .map(([title, dataset]) => [title, dataset.length ? dataset : [dataset]])
        .sort(([title]) => (isComparedTitle(title) ? 1 : -1))

const getMainDataById = ({ data, id }) => data.find(({ metric }) => +metric?.metric_id === +id)

const getMainSetData = ({ data, id, comparisonMode }) => {
    const dataByType = data.reduce(
        (acc, [title, dataset]) => ({
            ...acc,
            [!isComparedTitle(title) ? 'default' : 'compared']: dataset,
        }),
        {}
    )

    const mainData = getMainDataById({ data: dataByType.default, id })
    const isMetricComparison = comparisonMode === COMPARISON_MODES.METRIC

    if (!mainData || mainData?.metric.currency || !dataByType.compared || isMetricComparison) {
        return mainData
    }

    const comparedMainData = getMainDataById({ data: dataByType.compared, id })

    if (!comparedMainData) {
        return mainData
    }

    return { dataset: mainData.dataset, metric: comparedMainData.metric }
}

export const getChartConfig = ({
    data,
    isBreakdown,
    comparisonMode,
    selectedMetric,
    comparisonMetric,
}) => {
    if (!data || !selectedMetric) return {}

    const chartDataMapped = getMappedChartData(data)
    const mainSetData = getMainSetData({
        data: chartDataMapped,
        id: selectedMetric.metric_id,
        comparisonMode,
    })

    if (!mainSetData) return {}

    const mainMetric = mainSetData.metric
    const timeSlice = mainMetric.time_slice

    const PROPS_BY_COMPARISON_MODE = isBreakdown
        ? BREAKDOWN_PROPS_BY_COMPARISON_MODE
        : DEFAULT_PROPS_BY_COMPARISON_MODE

    const getComparisonProps = PROPS_BY_COMPARISON_MODE[comparisonMode]
    const { getChartDataSets, ...comparisonProps } =
        getComparisonProps?.({ mainMetric, comparisonMetric }) || {}

    const chartsDataSets = getChartDataSets({
        data: chartDataMapped,
        comparisonMode,
        timeSlice,
        mainMetric,
        comparisonMetric,
    })

    return {
        labels: getLabels(mainSetData),
        chartsDataSets,
        numberOfShownDataSests: chartsDataSets.length,
        timeSlice,
        isShopCurrency: mainMetric.isShopCurrency,
        currency: mainMetric.currency,
        valueUnits: mainMetric.valueUnits,
        showLegend: isBreakdown,
        showSeriesNameInTooltip: isBreakdown,
        generateLegendsLabels,
        ...comparisonProps,
    }
}
