import { useCallback, useMemo } from 'react'
import { parse, stringify } from 'qs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { parseBoolValue } from 'utils'

const PARSE_OPTIONS = {
    ignoreQueryPrefix: true,
    arrayFormat: 'indices',
    arrayLimit: 200,
}

const STRINGIFY_OPTIONS = {
    skipNulls: true,
}

const createPathObject = (newPathname?: string) => ({
    pathname: newPathname || window.location.pathname,
    replace: !newPathname,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useQueryString = <T extends Record<string, any>>() => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const queryParams = useMemo(() => {
        const params = [...searchParams].map(([key, value]) => [key, parseBoolValue(value)])
        return parse(Object.fromEntries([...params]), PARSE_OPTIONS) as T
    }, [searchParams])

    const setQueryParams = useCallback(
        (newParams: T, newPathname?: string) =>
            navigate({
                ...createPathObject(newPathname),
                search: stringify(
                    { ...parse(window.location.search, PARSE_OPTIONS), ...newParams },
                    STRINGIFY_OPTIONS
                ),
            }),
        [navigate]
    )

    const cleanQueryParams = useCallback(
        (newPathname?: string) => navigate(createPathObject(newPathname)),
        [navigate]
    )

    return useMemo(
        () => ({
            queryParams,
            setQueryParams,
            cleanQueryParams,
        }),
        [cleanQueryParams, queryParams, setQueryParams]
    )
}
