import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OctupColors } from '../../../shared/theme/colors/OctupColors'
import { useLocation, useNavigate } from 'react-router-dom'
import { OctupIcons } from '../../../shared/assets/OctupIcons'
import { datePickerActions } from '../../../features/datePicker/datePickerSlice'
import { getInsights } from '../../../features/insights/insightSlice'
import { getUsers } from '../../../features/users/usersSlice'

export function MobileInsightDetailsModal() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const { userInfo } = useSelector((state) => state.auth)
    const { insights, isLoading: isLoadingInsights } = useSelector((state) => state.insights)
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const { segment, selectedDDMetric: locationSelectedDDMetric } = location.state
    const [selectedDDMetric, setSelectedDDMetric] = useState(locationSelectedDDMetric)
    const [data, setData] = useState(insights)
    const [selectedMetricData, setSelectedMetricData] = useState(null)
    const [showSelectMetricsList, setShowSelectMetricsList] = useState(false)

    const { TrendArrowUp, TrendArrowDown } = OctupIcons

    useEffect(() => {
        dispatch(getInsights())
        dispatch(getUsers(userInfo?.token))
    }, [])

    const rearrangedMetricArray = []

    if (selectedMetricData?.dataset?.length > 0) {
        rearrangedMetricArray.push({
            labels: selectedMetricData.dataset[selectedMetricData?.dataset?.length - 1][1],
            data: selectedMetricData.dataset[selectedMetricData?.dataset?.length - 1][0],
            change: 'Last Value',
        })
        for (let i = 1; i < selectedMetricData?.dataset?.length; ++i) {
            rearrangedMetricArray.push({
                title: selectedMetricData.dataset[i][1],
                labels: selectedMetricData.dataset[i][1],
                data: selectedMetricData.dataset[i][0],
                change:
                    parseFloat(
                        ((selectedMetricData.dataset[i - 1][0] - selectedMetricData.dataset[i][0]) *
                            100) /
                            selectedMetricData.dataset[i][0]
                    ).toFixed(2) + '%',
            })
        }
        // console.log("rearrangedMetricArray", rearrangedMetricArray);
    }

    const onMetricSelection = () => {
        setSelectedMetricData(
            data?.find((el) => {
                return el?.metric?.metricTitle === selectedDDMetric
            })
        )
    }

    const onChangeDates = (datepickerValues) => {
        dispatch(
            datePickerActions.changeDates([
                {
                    ...datepickerValues,
                    key: 'selection',
                    color: '#007f82',
                },
            ])
        )
    }

    useEffect(() => {
        onMetricSelection()
    }, [selectedDDMetric, data, datePickerValues])

    return <Div></Div>
}

const Div = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${OctupColors.cardsBackground};
`
