import type { MetricsSectionPropsType } from '@octup/core/models'
import { CardContent, Grid } from '@octup/core/ui-kit'
import { ErrorMessageFallback, NoDataFallback } from '@/new_components'
import { cardContentStyle } from './styles'

type MetricsSectionCardContentPropsType = Pick<
    MetricsSectionPropsType,
    'hasData' | 'error' | 'isLoading'
> & { children: React.ReactNode }

export const MetricsSectionCardContent = ({
    children,
    hasData,
    error,
    isLoading,
}: MetricsSectionCardContentPropsType) => {
    if (error) return <ErrorMessageFallback />

    if (!hasData && !isLoading) {
        //  @ts-expect-error: NoDataFallback is a JavaScript file
        return <NoDataFallback title="No Data" text="There are no connected integrations." />
    }

    return (
        <CardContent component={Grid} container item xs sx={cardContentStyle}>
            {children}
        </CardContent>
    )
}
