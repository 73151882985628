import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AppExcludedFeaturesType } from 'models/appConfig'
import { APP_FEATURES, APP_ROUTES } from 'models/appConfig'
import { getAppExcludedFeatures } from 'services/auth'
import type { ThunkAPIConfigType } from 'services/utils'

const IGNORED_PATHS = [APP_ROUTES.LOGIN, APP_ROUTES.TERMS_AND_CONDITIONS]
const DEFAULT_EXCLUDED_PAGES = Object.values(APP_ROUTES).reduce((acc: APP_ROUTES[], path) => {
    const mainPath = path.split('/:')[0] as APP_ROUTES
    return acc.includes(mainPath) || IGNORED_PATHS.includes(mainPath) ? acc : [...acc, mainPath]
}, [])

export const DEFAULT_EXCLUDED_FEATURES = {
    names: Object.values(APP_FEATURES),
    pages: DEFAULT_EXCLUDED_PAGES,
}

type AppExcludedFeaturesStateType = {
    data: AppExcludedFeaturesType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: AppExcludedFeaturesStateType = {
    data: DEFAULT_EXCLUDED_FEATURES,
    isLoading: true,
    error: undefined,
}

export const fetchAppExcludedFeatures = createAsyncThunk<
    AppExcludedFeaturesType,
    undefined,
    ThunkAPIConfigType
>('fetchAppExcludedFeatures', getAppExcludedFeatures)

export const appExcludedFeaturesSlice = createSlice({
    name: 'appConfig',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppExcludedFeatures.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchAppExcludedFeatures.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchAppExcludedFeatures.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const appExcludedFeaturesReducer = appExcludedFeaturesSlice.reducer
