import { ERROR_MESSAGES } from 'constants/validation'
import { VALIDATION_RULES } from 'utils'
import { object, string } from 'yup'

export const getDefaultValues = (cost) => ({
    cost_name: cost.cost_name || '',
    value: cost.value || '',
})

export const COST_FORM_VALIDATION_SCHEMA = object({
    cost_name: string().required(ERROR_MESSAGES.REQUIRED),
    value: VALIDATION_RULES.NUMBER.positive(ERROR_MESSAGES.POSITIVE)
        .test('max-digitals', 'Max 6 digitals and 2 decimals', (value) => {
            if (value !== undefined) {
                return /^\d{1,6}(?:\.\d{1,2})?$/.test(value)
            }
            return true
        })
        .required(ERROR_MESSAGES.REQUIRED),
})
