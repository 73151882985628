import { ProfitMetricItem } from '../../../components/UnitEconomics/ProfitMetrics/ProfitMetricItem'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { useMemo } from 'react'

export const getMetricData = ({ lowestProfit = {}, highestProfit = {}, averageProfit } = {}) => [
    {
        order: 1,
        title: 'lowest',
        value: lowestProfit.value,
        alias: lowestProfit.name,
    },
    {
        order: 2,
        title: 'highest',
        value: highestProfit.value,
        alias: highestProfit.name,
    },
    {
        order: 3,
        title: 'average',
        value: averageProfit,
    },
]

// TODO: separate file and move to a new folder structure
export function ProductsStats() {
    const { invoicesDates, products } = useSelector((state) => state.unitEconomics)
    const isLoading = useMemo(
        () => invoicesDates.isLoading || !products.data || products.isLoading,
        [invoicesDates.isLoading, products.data, products.isLoading]
    )

    const metricData = useMemo(
        () => getMetricData(products.data?.metadata),
        [products.data?.metadata]
    )

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            padding={{ xs: 1, md: 2, lg: 3 }}
            sx={{ overflow: 'auto' }}
        >
            <Grid item flex={0.3}>
                <Typography fontWeight={500}>Product Profit</Typography>
            </Grid>
            <Grid item container flex={1} columnGap={3} wrap="nowrap">
                <ProfitMetricItem isLoading={isLoading} metricData={metricData} />
            </Grid>
        </Grid>
    )
}
