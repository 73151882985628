import { useContext, useCallback } from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import { MetricsSectionTitle } from '@/new_components/__common__/MetricsSections/MetricsSectionsTypes/__common__'
import MetricTrend from './MetricItemComponents/MetricTrend'
import MetricValue from './MetricItemComponents/MetricValue'
import { ModalsContext } from '../../../contexts/ModalsContext'
import { MetricsDetailsIconSvg } from '../../../shared/assets/icons/MetricsDetailsIconSvg'
import { OctupBreathing } from '../../../shared/ui/Breathing/OctupBreathing'
import { ButtonIcon } from '../../../shared/ui/Buttons/ButtonIcon'

export const MetricItemWithChart = ({
    id,
    title,
    value,
    currency,
    valueUnits,
    description,
    sources,
    trendUnits,
    isPositiveTrend,
    showSeperator,
    showDetailsBtn,
    isDensed,
    setSelectedMetric,
    setShowModalDetails,
    renderChart,
    isLoading,
    trendValue,
    hasDataset,
    segment,
    isSupported,
    hasChart = true,
    enableDeepdive = true,
    customStyle,
}) => {
    const { setDeepdiveModal } = useContext(ModalsContext)
    const isDisabled = !isSupported || !enableDeepdive

    const handleClick = useCallback(() => {
        if (!isDisabled)
            setDeepdiveModal({
                show: true,
                segments: [segment],
                selectedDDMetric: id,
            })
    }, [id, isDisabled, segment, setDeepdiveModal])

    return (
        <MetricItemContainer
            style={{ ...customStyle }}
            onClick={handleClick}
            isDensed={isDensed}
            hadChart={hasChart}
            showSeperator={showSeperator}
            isDisabled={isDisabled}
        >
            <Grid container>
                <MetricsSectionTitle
                    name={title}
                    description={description}
                    sources={sources}
                    isLoading={isLoading}
                />
            </Grid>
            <MetricItemInnerContainer>
                <DataContainer>
                    <MetricItemLeftContainer isDensed={isDensed}>
                        {isLoading ? (
                            <>
                                <OctupBreathingContainer>
                                    <OctupBreathing width="8rem" height="2rem"></OctupBreathing>
                                </OctupBreathingContainer>
                                <OctupBreathingContainer>
                                    <OctupBreathing width="8rem" height="2rem"></OctupBreathing>
                                </OctupBreathingContainer>
                            </>
                        ) : (
                            <>
                                <MetricValue
                                    title={title}
                                    value={value}
                                    currency={currency}
                                    valueUnits={valueUnits}
                                    isSupported={isSupported}
                                    isLoading={isLoading}
                                />
                                <MetricTrend
                                    isLoading={isLoading}
                                    isSupported={isSupported}
                                    isPositiveTrend={isPositiveTrend}
                                    trendUnits={trendUnits}
                                    trendValue={trendValue}
                                    hasDataset={hasDataset}
                                />
                            </>
                        )}
                    </MetricItemLeftContainer>
                    <MetricItemRightContainer></MetricItemRightContainer>
                    {showDetailsBtn && false && (
                        <MetricsDetailsButtonStyled className="metrics-details-btn">
                            <ButtonIcon
                                tooltipContent={
                                    <>
                                        Open{' '}
                                        <span style={{ fontSize: 'inherit', fontWeight: 600 }}>
                                            {title}
                                        </span>{' '}
                                        details
                                    </>
                                }
                                mainIcon={MetricsDetailsIconSvg}
                                onClick={() => {
                                    setSelectedMetric(id)
                                    setShowModalDetails(true)
                                }}
                            />
                        </MetricsDetailsButtonStyled>
                    )}
                </DataContainer>
                {renderChart && isLoading ? (
                    <OctupBreathing width="24rem" height="7rem"></OctupBreathing>
                ) : (
                    <ChartWrapper>{renderChart && renderChart()}</ChartWrapper>
                )}
            </MetricItemInnerContainer>
        </MetricItemContainer>
    )
}

const MetricItemContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    min-width: ${(p) => (!p.hasChart ? '' : 'calc(20vw - 4rem)')};
    padding: 0 1rem;
    border: 0.2rem solid transparent;

    ${({ isDisabled }) => !isDisabled && 'cursor: pointer;'};

    border-right: ${(props) => (props.showSeperator ? '.2rem solid #DDDBE1' : 'none')};

    @media screen and (max-width: 768px) {
        border-right: none;
        border-bottom: ${(props) => (props.showSeperator ? '.2rem solid #DDDBE1' : 'none')};
    }

    .metrics-details-btn,
    .show-on-hover {
        opacity: 0;
    }

    &:hover {
        .metrics-details-btn,
        .show-on-hover {
            opacity: 1;
            transition: 0.1s ease-in-out;
        }
    }
`

const MetricItemInnerContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    height: fit-content;
`

const MetricItemLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const MetricItemRightContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MetricsDetailsButtonStyled = styled.div`
    position: absolute;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
`

const ChartWrapper = styled.div`
    width: 50%;
    right: 0;
    position: absolute;
`
const DataContainer = styled.div`
    width: 30%;
`

const OctupBreathingContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    height: 100%;
    width: 100%;
    flex-direction: column;
`
