import { forwardRef, useCallback, useState } from 'react'
import { CardHeader, Card as MuiCard, Stack } from '@mui/material'
import type { CardHeaderProps, CardProps, GridProps } from '@mui/material'
import { ExpandIcon, CollapseIcon } from '@octup/core/assets/icons'
import { IconButton, SvgIcon, Dialog } from '@octup/core/ui-kit'

export type CardPropsType = Omit<Partial<GridProps> & CardProps, 'title'> &
    CardHeaderProps & {
        showFullScreenButton?: boolean
    }

export const Card = forwardRef<HTMLDivElement, CardPropsType>(
    ({ title, action, children, showFullScreenButton = false, ...props }, ref) => {
        const [isFullScreenOpen, setIsFullScreenOpen] = useState(false)

        const toggleFullScreen = useCallback((event: React.MouseEvent) => {
            event.stopPropagation()
            setIsFullScreenOpen((prev) => !prev)
        }, [])

        const fullScreenButton = showFullScreenButton && (
            <IconButton color="secondary" onClick={toggleFullScreen}>
                <SvgIcon component={isFullScreenOpen ? CollapseIcon : ExpandIcon} />
            </IconButton>
        )

        const hasAction = action || fullScreenButton

        return (
            <>
                <MuiCard ref={ref} {...props}>
                    {(title || hasAction) && (
                        <CardHeader
                            title={title}
                            action={
                                hasAction && (
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        {action}
                                        {fullScreenButton}
                                    </Stack>
                                )
                            }
                        />
                    )}
                    {children}
                </MuiCard>
                {fullScreenButton && (
                    <Dialog
                        fullScreen
                        title={title}
                        open={isFullScreenOpen}
                        action={fullScreenButton}
                        onClose={toggleFullScreen}
                    >
                        {children}
                    </Dialog>
                )}
            </>
        )
    }
)
