import { useMemo } from 'react'
import type { OverflowTypographyPropsType } from '@octup/core/ui-kit'
import { OverflowTypography } from '@octup/core/ui-kit'
import { getInputDate } from '@/shared/utils/dates-util'

export type DateContainerPropsType = OverflowTypographyPropsType & {
    date: string
}

export const DateLabelContainer = ({ date, ...props }: DateContainerPropsType) => {
    const inputDate = useMemo(() => getInputDate(date), [date])

    return <OverflowTypography {...props}>{inputDate}</OverflowTypography>
}
