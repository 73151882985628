import styled from 'styled-components'

export const Circle = styled.div`
    width: 1rem;
    height: 1rem;
    border: 0.08rem solid white;
    box-shadow: 2px 2px 4px 0px rgba(40, 40, 49, 0.32) inset;
    background: rgba(248, 247, 251, 1);
    border-radius: 100%;
`

export const NewsFeedBanner = styled.ul`
    display: flex;
    align-items: center;
    height: 100%;
    width: fit-content;
    overflow: hidden;
    gap: 2rem;
    animation: animate 10s -10s linear infinite;
    will-change: transform;

    & > li {
        color: rgba(255, 207, 114, 1);
        font-size: 1.3rem;
        line-height: 1.8rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 1.5rem;
        white-space: nowrap;
    }

    @keyframes animate {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-25%);
        }
    }
`

export const WhatsNewFooterStyled = styled.div`
    background: rgba(40, 40, 49, 1);
    position: fixed;
    width: 100%;
    overflow: hidden;
    bottom: 0;
    left: 0;
    height: 4rem;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
`
