import type { Components, Theme } from '@mui/material'

type CardOverridesType = Pick<Components<Theme>, 'MuiCard' | 'MuiCardHeader'>

export const CARD_OVERRIDES: CardOverridesType = {
    MuiCard: {
        styleOverrides: {
            root: ({ theme }) => ({
                boxShadow: theme.shadows[6],
                borderRadius: theme.spacing(3),
            }),
        },
    },
    MuiCardHeader: {
        styleOverrides: {
            content: {
                width: '100%',
            },
        },
    },
}
