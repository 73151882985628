import './index.css'
import React from 'react'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ErrorBoundaryContainer } from '@/new_containers/components/__common__'
import { setupStore } from '@/state'
import { createTheme } from '@/theme'
import App from './App'
import { ToastsContextProvider } from './contexts/ToastsContext'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <ThemeProvider theme={createTheme()}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ErrorBoundaryContainer>
                    <Provider store={setupStore()}>
                        <ToastsContextProvider>
                            <App />
                        </ToastsContextProvider>
                    </Provider>
                </ErrorBoundaryContainer>
            </LocalizationProvider>
        </ThemeProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
