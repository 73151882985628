import type { MetricType } from '@octup/core/models'
import { chartMapIcons } from '@/pages/marketing/MarketingPage'
import { getFormattedVal } from '@/shared/utils/metrics-utils'

export const getChartConfig = (metrics: MetricType[] = []) => {
    const chartConfig = metrics.reduce(
        // should return props of doughnut chart component
        // replace any type after re-writing the chart component
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, metric) => ({
            ...acc,
            valueType: acc.valueType || metric.value_units,
            currency: acc.currency || metric.currency,
            chartData: [...acc.chartData, metric.value],
            labels: [...acc.labels, metric.name],
            legends: [
                ...acc.legends,
                {
                    ...metric,
                    label: metric.name,
                    /* @ts-expect-error: chartMapIcons is a JavaScript file */
                    icon: chartMapIcons[metric.sources[0]],
                },
            ],
            total: acc.total + metric.value,
        }),
        { chartData: [], labels: [], legends: [], total: 0 }
    )

    /* @ts-expect-error: getFormattedVal is a JavaScript file */
    const title = getFormattedVal({
        val: chartConfig.total,
        valueType: chartConfig.valueType,
        currency: chartConfig.currency,
        showSymbol: true,
    })

    return { ...chartConfig, title }
}
