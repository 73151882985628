import styled from 'styled-components'
import { compareStrings, HOME_TABLES_TYPES } from '../../../shared/utils/strings-util'
import { getInputDate } from '../../../shared/utils/dates-util'
import Dropdown from '../../../shared/ui/Dropdown/Dropdown'
import { useSelector } from 'react-redux'
import { OctupInput } from '../../../shared/ui/Inputs/Input/OctupInput'
import { useEffect, useState, useMemo } from 'react'
import { useInput } from '../../../hooks/useInput'
import { OctupTextArea } from '../../../shared/ui/Inputs/TextArea/OctupTextArea'
import {
    OBJECTIVE_STATUS_KTV_STR,
    OBJECTIVE_STATUS_VTK,
    VTK_SEGMENAT,
    VTK_TYPE,
} from '../../../features/objectives/objectiveSlice'
import { addDays } from 'date-fns'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'
import { getEditFormattedDate } from './EditObjectiveForm.utils'

export function EditObjectiveForm({ data, activeTable, setObjectiveForm, objectiveForm }) {
    const { users, isLoading } = useSelector((state) => state.users)
    const { insight } = useSelector((state) => state.insights)
    const octupDispatch = useOctupDispatch()

    useEffect(() => {
        octupDispatch.users.getUsers()
    }, [octupDispatch.users])

    const usersData = useMemo(
        () =>
            [...users]
                .sort((a, b) => {
                    if (b.fullName > a.fullName) {
                        return -1
                    } else {
                        return 1
                    }
                })
                .map((el) => {
                    let imgObj = { src: el.imageSrc || undefined, alt: `user image` }
                    let name = el.fullName
                    let initials = el.initials
                    return { value: { ...el, imgObj }, name, initials }
                }),
        [users]
    )

    const { userInfo } = useSelector((state) => state.auth)
    const isInsights = compareStrings(activeTable, HOME_TABLES_TYPES.INSIGHTS)
    const isObjective =
        compareStrings(activeTable, HOME_TABLES_TYPES.OBJECTIVES) ||
        data?.origin === 'simulator' ||
        data?.origin?.toLowerCase() === 'manual'
    const SelectedItemData = data[activeTable] ? data[activeTable] : data
    const [isNew] = useState(SelectedItemData?.id === undefined || SelectedItemData?.id === null)

    const [status, setStatus] = useState(
        isObjective ? SelectedItemData && SelectedItemData?.status : 'In Progress'
    )

    const [creator] = useState(
        SelectedItemData && !isNew && SelectedItemData?.creator
            ? SelectedItemData?.creator
            : { ...userInfo, username: userInfo.fullName }
    )

    const [origin] = useState(
        isInsights ? 'Insight' : SelectedItemData?.origin ? SelectedItemData?.origin : 'manual'
    )

    const [ownerData, setOwnerData] = useState(usersData[0])

    const ownerOptions = useMemo(() => {
        const selectedOwnerId = objectiveForm?.data?.owner_user_id || creator.id
        return usersData.map((el) => ({ ...el, isSelected: +selectedOwnerId === el.value.id }))
    }, [creator.id, objectiveForm?.data?.owner_user_id, usersData])

    const {
        value: titleValue,
        isValid: titleIsValid,
        isChanged: titleIsChanged,
        errors: titleErrors,
        showErrors: titleShowErrors,
        onChangeHandler: titleChangeHandler,
        onBlurHandler: titleBlurHandler,
    } = useInput({
        initialValue:
            SelectedItemData?.actionableItem || SelectedItemData?.title
                ? SelectedItemData?.title
                : '',
        isRequired: true,
        inputType: 'text',
    })

    const {
        value: descriptionValue,
        isValid: descriptionIsValid,
        isChanged: descriptionIsChanged,
        errors: descriptionErrors,
        showErrors: descriptionShowErrors,
        onChangeHandler: descriptionChangeHandler,
        onBlurHandler: descriptionBlurHandler,
    } = useInput({
        initialValue:
            SelectedItemData && isObjective
                ? SelectedItemData?.description || ''
                : SelectedItemData?.actionableItem && SelectedItemData?.el?.subtitle
                  ? SelectedItemData?.el?.subtitle
                  : '',
        inputType: 'text',
    })

    const {
        value: actionsValue,
        isChanged: actionsIsChanged,
        errors: actionsErrors,
        showErrors: actionsShowErrors,
        onChangeHandler: actionsChangeHandler,
        onBlurHandler: actionsBlurHandler,
    } = useInput({
        initialValue:
            SelectedItemData && isObjective
                ? SelectedItemData?.actions || ''
                : SelectedItemData?.actionableItem && SelectedItemData?.el?.right_pane,
        inputType: 'text',
    })

    const {
        value: startDateValue,
        isValid: startDateIsValid,
        isChanged: startDateIsChanged,
        errors: startDateErrors,
        showErrors: startDateShowErrors,
        onChangeHandler: startDateChangeHandler,
        onBlurHandler: startDateBlurHandler,
    } = useInput({
        initialValue: SelectedItemData?.start_date
            ? getInputDate(SelectedItemData?.start_date, 'objective')
            : getInputDate(new Date(), 'objective'),
        inputType: 'date',
        min: { value: new Date().setDate(new Date().getDate() - 1) },
    })

    const {
        value: dueDateValue,
        isValid: dueDateIsValid,
        isChanged: dueDateIsChanged,
        errors: dueDateErrors,
        showErrors: dueDateShowErrors,
        onChangeHandler: dueDateChangeHandler,
        onBlurHandler: dueDateBlurHandler,
    } = useInput({
        initialValue: SelectedItemData?.due_date
            ? getInputDate(SelectedItemData?.due_date, 'objective')
            : getInputDate(addDays(new Date(), 7), 'objective'),
        inputType: 'date',
        min: {
            value: startDateValue,
            customErrorMsg: 'Due date cannot be set before start date',
        },
        // max: { value: new Date(new Date(startDateValue).getFullYear() + 1, new Date(startDateValue).getMonth(), new Date(startDateValue).getDay()).toISOString().split('T')[0] }
    })
    useEffect(() => {
        setObjectiveForm((prevState) => {
            const ownerID = `${ownerData?.value?.id || userInfo?.id}`
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    id: isObjective ? prevState?.data?.id : null,
                    segment: isInsights
                        ? insight.segment
                        : prevState?.data?.segment ||
                            VTK_TYPE[origin?.toString()?.replaceAll(' ', '').toLowerCase()] === 2
                          ? OBJECTIVE_STATUS_VTK['uniteconomics']
                          : OBJECTIVE_STATUS_VTK['manual'],
                    actionableItem: {
                        title: titleValue,
                        description: descriptionValue,
                        actions: actionsValue,
                    },
                    status: isInsights ? 'new' : prevState?.data?.status,
                    owner: {
                        ownerData,
                        fullName: ownerData?.name,
                    },
                    insight: isInsights
                        ? insight.id
                        : data?.insight === 0
                          ? 0
                          : data?.insight || -1,
                    creator,
                    origin,
                    due_date: dueDateValue,
                    start_Date: startDateValue,
                    isObjective: isObjective,
                },
                apiData: !prevState?.data?.id
                    ? {
                          id: isObjective ? prevState?.data?.id : null,
                          insight: isInsights
                              ? insight.id
                              : data?.insight === 0
                                ? 0
                                : data?.insight || -1,
                          owner_user_id: ownerID,
                          createdAt: prevState?.data?.createdAt || null,
                          start_date: startDateValue,
                          due_date: dueDateValue,
                          title: titleValue,
                          segment: isInsights
                              ? insight.segment
                              : prevState?.data?.segment ||
                                  VTK_TYPE[
                                      origin?.toString()?.replaceAll(' ', '').toLowerCase()
                                  ] === 2
                                ? VTK_SEGMENAT['Unit Economics']
                                : VTK_SEGMENAT['Manual'],
                          description: descriptionValue,
                          actions: actionsValue,
                          status: OBJECTIVE_STATUS_KTV_STR[`${status}`] || 'InProgress',
                          type: origin
                              ? VTK_TYPE[origin?.toString()?.replaceAll(' ', '').toLowerCase()]
                              : VTK_TYPE['manual'],
                          client_id: prevState?.data?.client_id || userInfo.clientId,
                      }
                    : {
                          // if already exists we only send editable values to the API, objective and Insight ids are mandatory
                          id: isObjective ? prevState?.data?.id : null,
                          insight: isInsights
                              ? insight.id
                              : data?.insight === 0
                                ? 0
                                : data?.insight || -1,
                          owner_user_id: ownerID,
                          start_date: startDateValue,
                          due_date: dueDateValue,
                          title: titleValue,
                          description: descriptionValue,
                          actions: actionsValue,
                          status: OBJECTIVE_STATUS_KTV_STR[`${status}`] || 'InProgress',
                      },
                isFormValid:
                    ((!titleIsChanged && titleValue.trim().length > 0) ||
                        (titleIsChanged && titleErrors.length === 0)) &&
                    (!descriptionIsChanged ||
                        (descriptionIsChanged && descriptionErrors.length === 0)) &&
                    (!actionsIsChanged || (actionsIsChanged && actionsErrors.length === 0)) &&
                    (!dueDateIsChanged || (dueDateIsChanged && dueDateErrors.length === 0)) &&
                    (!startDateIsChanged || (startDateIsChanged && startDateErrors.length === 0)),
            }
        })
    }, [
        titleValue,
        descriptionValue,
        actionsValue,
        status,
        ownerData,
        creator,
        dueDateValue,
        startDateValue,
        titleErrors,
        descriptionErrors,
        actionsErrors,
        dueDateErrors,
        startDateErrors,
        setObjectiveForm,
        origin,
        titleIsChanged,
        descriptionIsChanged,
        actionsIsChanged,
        dueDateIsChanged,
        startDateIsChanged,
    ])

    const startFormattedDate = useMemo(() => getEditFormattedDate(startDateValue), [startDateValue])
    const dueFormattedDate = useMemo(() => {
        const currentDueDateValue = dueDateValue || addDays(new Date(startDateValue), 30)
        return getEditFormattedDate(currentDueDateValue)
    }, [dueDateValue, startDateValue])

    return (
        <ObjectiveDataContainer>
            <ObjectiveInfoTitle htmlFor="origin">Origin</ObjectiveInfoTitle>
            <ObjectiveInfoDetails>
                <OctupInput
                    id={'origin'}
                    name={'origin'}
                    customStyle={{ textTransform: 'capitalize' }}
                    hideDesign={true}
                    disabled={true}
                    value={origin}
                />
            </ObjectiveInfoDetails>
            {isObjective && (
                <>
                    <ObjectiveInfoTitle htmlFor="status">status</ObjectiveInfoTitle>
                    <ObjectiveInfoDetails>
                        <Dropdown
                            id={'status'}
                            title={'status'}
                            isFilter={false}
                            isMultiSelection={false}
                            showClearFilters={false}
                            deafultInitialValue={{ name: status }}
                            options={[
                                {
                                    name: 'In Progress',
                                    value: 'InProgress',
                                    isSelected:
                                        (SelectedItemData?.status &&
                                            SelectedItemData?.status?.toLowerCase() ===
                                                'inprogress') ||
                                        SelectedItemData?.status?.toLowerCase() === 'overdue' ||
                                        (isObjective && !SelectedItemData?.status),
                                },
                                {
                                    name: 'Completed',
                                    value: 'completed',
                                    isSelected:
                                        SelectedItemData?.status &&
                                        SelectedItemData?.status?.toLowerCase() === 'completed',
                                },
                            ]}
                            onClickCallback={(data) => data.value && setStatus(data.value.value)}
                        />
                    </ObjectiveInfoDetails>
                </>
            )}

            <ObjectiveInfoTitle htmlFor="creator">creator</ObjectiveInfoTitle>
            <ObjectiveInfoDetails id="creator">
                <OctupInput
                    hideDesign={true}
                    disabled={true}
                    value={creator?.username}
                    customStyle={{ textTransform: 'capitalize' }}
                />
            </ObjectiveInfoDetails>
            <ObjectiveInfoTitle htmlFor="title">title</ObjectiveInfoTitle>
            <ObjectiveInfoDetails>
                <OctupInput
                    id="title"
                    name="title"
                    errors={titleErrors}
                    showErrors={
                        titleShowErrors ||
                        (objectiveForm?.isFormSubmitted && titleErrors.length > 0)
                    }
                    value={titleValue}
                    isValid={titleIsValid}
                    onBlur={titleBlurHandler}
                    onChange={titleChangeHandler}
                />
            </ObjectiveInfoDetails>
            <ObjectiveInfoTitle htmlFor="description">subtitle</ObjectiveInfoTitle>
            <ObjectiveInfoDetails>
                <OctupInput
                    id={'description'}
                    name={'description'}
                    errors={descriptionErrors}
                    showErrors={
                        descriptionShowErrors ||
                        (objectiveForm.isFormSubmitted && descriptionIsChanged)
                    }
                    value={descriptionValue}
                    isValid={descriptionIsValid}
                    onBlur={descriptionBlurHandler}
                    onChange={descriptionChangeHandler}
                />
            </ObjectiveInfoDetails>
            <ObjectiveInfoTitle htmlFor="actions">actions</ObjectiveInfoTitle>
            <ObjectiveInfoDetails>
                <OctupTextArea
                    id={'actions'}
                    name={'actions'}
                    errors={actionsErrors}
                    isChanged={actionsIsChanged}
                    isValid={actionsIsChanged}
                    showErrors={
                        actionsShowErrors || (objectiveForm.isFormSubmitted && actionsIsChanged)
                    }
                    value={actionsValue}
                    onBlur={actionsBlurHandler}
                    onChange={actionsChangeHandler}
                />
            </ObjectiveInfoDetails>

            <DatesContainer>
                <ObjectiveInfoTitle htmlFor="start_date">Start Date</ObjectiveInfoTitle>
                <ObjectiveInfoDetails>
                    <OctupInput
                        type={'date'}
                        id={'start_date'}
                        name={'start_date'}
                        errors={startDateErrors}
                        showErrors={
                            startDateShowErrors ||
                            (startDateErrors && objectiveForm.isFormSubmitted && startDateIsChanged)
                        }
                        value={startFormattedDate}
                        isValid={startDateIsValid}
                        onBlur={startDateBlurHandler}
                        onChange={startDateChangeHandler}
                    />
                </ObjectiveInfoDetails>
                <ObjectiveInfoTitle htmlFor="due_date">Due Date</ObjectiveInfoTitle>
                <OctupInput
                    id={'due_date'}
                    name={'due_date'}
                    type={'date'}
                    min={startDateValue}
                    // max={new Date(new Date(startDateValue).getFullYear() + 1, new Date(startDateValue).getMonth(), new Date(startDateValue).getDay()).toISOString().split('T')[0]}
                    errors={dueDateErrors}
                    showErrors={
                        dueDateShowErrors ||
                        (dueDateShowErrors && objectiveForm.isFormSubmitted && dueDateIsChanged)
                    }
                    value={dueFormattedDate}
                    isValid={dueDateIsValid}
                    onBlur={dueDateBlurHandler}
                    onChange={dueDateChangeHandler}
                    validateOnChange={true}
                />
            </DatesContainer>
            <ObjectiveInfoTitle htmlFor="owner">Owner</ObjectiveInfoTitle>
            <ObjectiveInfoDetails style={{ transform: 'translateY(-50%) !important' }}>
                <Dropdown
                    id={'owner'}
                    title={'user'}
                    showClearFilters={false}
                    isAvatarDropdown={true}
                    showImageInTitle={true}
                    showOptionsImages={true}
                    isMultiSelection={false}
                    onClickCallback={(data) => {
                        if (data.value) setOwnerData(data.value)
                    }}
                    options={ownerOptions}
                    isFilter={false}
                    showSelectAllOption={false}
                    droplistPosition="top"
                    updateSelectedOptions
                    isLoading={isLoading}
                />
            </ObjectiveInfoDetails>
        </ObjectiveDataContainer>
    )
}

const ObjectiveDataContainer = styled.div`
    // padding-top: 5rem;
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: 12rem 1fr;
    grid-row-gap: 3rem;
    width: 100%;
    height: 100%;
    width: 100rem;
    padding: 1rem;
    max-height: 90vh;
    max-width: 90vw;
`

const DatesContainer = styled.div`
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 12rem 1fr 12rem 1fr;
    justify-content: space-between;
    width: 89%;
`

const ObjectiveInfoTitle = styled.label`
    color: #a4a0aa;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: left;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-transform: capitalize;
    /* border: 1px solid red; */
`

const ObjectiveInfoDetails = styled.div`
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: left;
    position: relative;
    /* border: 1px solid blue; */
`
