import { Box, Typography } from '@mui/material'
import { KeyFormFieldContainer } from 'new_containers/components/Integrations'

// TODO: move the ConnectModalContainer.utils to TypeScript and reuse the Step type here
export type ConnectStepPropsType = {
    step?: number
    showStep?: boolean
    text?: string
    imgConfig?: { src: string }
    inputConfig?: { name: string; hint: string }
}

export const ConnectStep = ({
    step,
    showStep = true,
    text,
    imgConfig,
    inputConfig,
}: ConnectStepPropsType) => (
    <>
        {text && (
            <Typography>
                {showStep && `${step}. `}
                {text}
            </Typography>
        )}
        {imgConfig && (
            <Box>
                <img alt="connection step" width="100%" {...imgConfig} />
            </Box>
        )}
        {inputConfig && (
            <Box maxWidth="50rem">
                <KeyFormFieldContainer {...inputConfig} />
            </Box>
        )}
    </>
)
