import { useCallback, useMemo } from 'react'
import type { APP_FEATURES, APP_ROUTES } from 'models/appConfig'
import { useAppSelector } from 'state/hooks'

export type ListItem = {
    route?: APP_ROUTES
    feature?: APP_FEATURES
}

export const useAbilities = () => {
    const { excludedFeatures } = useAppSelector((state) => state.appConfig)

    const excludedFeaturesNames = useMemo(() => {
        const featuresNames = new Set()
        excludedFeatures.data?.names?.forEach((name) => featuresNames.add(name))
        return featuresNames
    }, [excludedFeatures.data?.names])

    const isFeatureAvailable = useCallback(
        (featureName: APP_FEATURES) => !excludedFeaturesNames.has(featureName),
        [excludedFeaturesNames]
    )

    const isPageAvailable = useCallback(
        (name?: APP_ROUTES) =>
            !excludedFeatures.data?.pages?.some((excludedPage) => name?.includes(excludedPage)),
        [excludedFeatures.data?.pages]
    )

    const getAvailableList = useCallback(
        <T extends ListItem>(list: T[]) =>
            list.filter((item) => {
                if (item.route) return isPageAvailable(item.route)
                if (item.feature) return isFeatureAvailable(item.feature)
                return true
            }),
        [isFeatureAvailable, isPageAvailable]
    )

    return {
        isPageAvailable,
        isFeatureAvailable,
        getAvailableList,
    }
}
