export function AmazonIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g clipPath="url(#clip0_2245_15031)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.695 19.6625C17.6269 21.1875 14.63 22 12.05 22C8.425 22 5.17375 20.6625 2.70938 18.4357C2.52188 18.2607 2.68938 18.0219 2.92188 18.1582C5.58125 19.7063 8.875 20.6375 12.2663 20.6375C14.7153 20.6263 17.138 20.1309 19.395 19.18C19.745 19.0313 20.0388 19.4088 19.695 19.6625ZM20.555 18.6788C20.2925 18.3413 18.8075 18.5194 18.1425 18.5975C17.9394 18.6225 17.9088 18.4457 18.0913 18.3188C19.2788 17.4875 21.2125 17.7269 21.4388 18.0063C21.665 18.2857 21.38 20.2313 20.27 21.1588C20.0994 21.3013 19.9369 21.2257 20.02 21.0363C20.27 20.4138 20.8288 19.0175 20.5638 18.6788"
                    fill="#FF9900"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.5187 10.665C13.5187 11.7225 13.5456 12.6044 13.0112 13.5431C12.5737 14.3056 11.8862 14.7763 11.1331 14.7763C10.0912 14.7763 9.48062 13.9825 9.48062 12.8106C9.48062 10.4981 11.5531 10.0775 13.5194 10.0775L13.5187 10.665ZM16.2569 17.28C16.0775 17.4406 15.8181 17.4519 15.6156 17.345C14.7156 16.5969 14.5506 16.2494 14.0581 15.5356C12.5694 17.0544 11.5119 17.5088 9.58437 17.5088C7.29812 17.5088 5.52312 16.1 5.52312 13.2794C5.52312 11.0769 6.71375 9.57688 8.41687 8.84375C9.89 8.195 11.9481 8.08125 13.52 7.90625V7.5625C13.52 6.9175 13.57 6.15375 13.1887 5.59688C12.8606 5.09688 12.2262 4.89063 11.6656 4.89063C10.6312 4.89063 9.71125 5.42188 9.48625 6.52063C9.44062 6.77063 9.26125 7.00563 9.01312 7.01688L6.375 6.72188C6.15375 6.67188 5.90625 6.49313 5.97062 6.15938C6.58 2.9625 9.46562 2 12.0462 2C13.3669 2 15.0925 2.35125 16.1338 3.35125C17.4544 4.58438 17.3287 6.22938 17.3287 8.02V12.25C17.3287 13.5213 17.8556 14.0781 18.3519 14.7656C18.5238 15.0156 18.5619 15.3031 18.3406 15.4869C17.6401 16.0792 16.9468 16.6799 16.2606 17.2888L16.2569 17.2813"
                    fill="#221F1F"
                />
            </g>
            <defs>
                <clipPath id="clip0_2245_15031">
                    <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>
    )
}
