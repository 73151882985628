import React from 'react'
import styled from 'styled-components'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { useSelector } from 'react-redux'
import { CardBase } from '../../shared/ui/Card/CardBase'
import { useNavigate } from 'react-router-dom'
import { convertStringFormat } from '../../shared/utils/strings-util'

function getSimplifiedDateString(dateInput) {
    const date = new Date(dateInput)
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]

    const month = months[date.getMonth()]
    const day = date.getDate()
    const year = date.getFullYear()

    return `${month} ${day}, ${year}`
}

export const distributeInsight = (insight) => {
    console.log('MobileInsightCard', insight)
    return {
        date: insight?.updated_at,
        title: insight?.title,
        subtitle: insight?.subtitle,
        importance: insight?.importance,
        segment: insight?.segment,
        userName: insight?.owner?.username,
        avatar: insight?.owner?.imageSrc,
    }
}

export const MobileInsightCard = ({ insight }) => {
    const navigate = useNavigate()
    const { users } = useSelector((state) => state.users)
    const user = users.filter((user) => user.id === insight.owner_user_id)[0]
    const {
        date = 'Date is Missing',
        title = 'Title is Missing',
        subtitle = 'Desc is Missing',
        userName = 'Username is Missing',
        importance = 'MED',
        avatar,
        segment,
    } = distributeInsight(insight, user)

    return (
        <CardContainer key={title}>
            <CardBase height={'100%'}>
                <InnerContainer>
                    <Row>
                        <DateText>
                            {segment
                                ? `${getSimplifiedDateString(date)} | ${convertStringFormat(
                                      segment
                                  )}`
                                : getSimplifiedDateString(date)}
                        </DateText>
                        <DateText>{convertStringFormat(importance)}</DateText>
                    </Row>
                    <Title>{title}</Title>
                    <Description>{subtitle}</Description>
                    <BottomContainer>
                        {avatar && <Avatar src={avatar} alt="User Avatar" />}
                        <UserName>{userName}</UserName>
                    </BottomContainer>
                </InnerContainer>
            </CardBase>
        </CardContainer>
    )
}

const CardContainer = styled.div`
    background-color: ${OctupColors.pageBackground};
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    display: flex;
    min-height: 25vh;
    flex-direction: column;
    align-items: center;
`

const InnerContainer = styled.div`
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const DateText = styled.span`
    font-size: 1.5rem;
    line-height: 3rem;
    color: ${OctupColors.textOverDarkBg};
`

const Title = styled.h2`
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 8px;

    font-weight: 600;
    line-height: 2rem;
    /* identical to box height, or 129% */

    display: flex;
    align-items: center;

    /* Middle */

    color: #504b5a;
`

const Description = styled.p`
    font-size: 1.5rem;
    text-align: left;
    width: 80%;
    //margin-left: 10%;
    margin-bottom: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    /* or 133% */

    /* Middle */

    color: #504b5a;
`

const BottomContainer = styled.div`
    display: flex;
    align-items: center;
`

const Avatar = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
`

const UserName = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.8rem;
    /* or 138% */

    display: flex;
    align-items: center;

    /* Middle */

    color: #504b5a;
`
