import styled from 'styled-components'
import { OctupColors } from 'shared/theme/colors/OctupColors'

export const TableCell = styled.td`
    padding-right: 2rem;
    color: ${({ color = OctupColors.primaryTextLight }) => color};
    font-weight: ${({ isTotalRow, fontWeight = 'initial' }) => (isTotalRow ? 600 : fontWeight)};
    gap: ${({ gap }) => gap};

    &.cell {
        word-break: break-all;
        white-space: ${({ whiteSpace = 'nowrap' }) => whiteSpace};
        font-size: ${({ isSubRow }) => (isSubRow ? '1.4rem' : '1.6rem')};
        ${({ textTransform }) => textTransform && `text-transform: ${textTransform} !important`}
    }
`
