import { useCallback, useEffect, useMemo } from 'react'
import { SvgIcon } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import {
    useAbilities,
    //  useOctupDispatch
} from '@/hooks'
import { APP_FEATURES, APP_ROUTES } from '@/models/appConfig'
import { DashboardIcon, OrdersIcon, UsersIcon, WarehouseIcon } from '@/new_assets/icons'
import { OctupIcons } from '@/shared/assets/OctupIcons'
import { octupDictionary } from '@/shared/dictionary/dictionary'
import { OctupColors } from '@/shared/theme/colors/OctupColors'
import { HOME_TABLES_TYPES } from '@/shared/utils/strings-util'
import { fetchDashboardsList } from '@/state/features/dashboards'
import { resolveRoutePath } from '@/utils'

const {
    HomeLogoSvg,
    OperationsLogoSvg,
    MarketingLogoSvg,
    UnitEconomicsLogoSvg,
    InsightsLogoSvg,
    ObjectivesLogoSvg,
    OutsourceSvg,
    KnowledgeIconSvg,
    ContactSvgIcon,
    // WhatsNewIconSvg,
    LightningIconSvg,
    HelpNSupportLogoSvg,
    IntegrationsIconSvg,
} = OctupIcons

export const useSideBarData = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    // const octupDispatch = useOctupDispatch()
    const navigate = useNavigate()
    const { isPageAvailable, isFeatureAvailable } = useAbilities()
    const { dashboardsList } = useSelector((state) => state.dashboards)

    const isDashboardsPageAvailable = useMemo(
        () => isPageAvailable(APP_ROUTES.DASHBOARDS),
        [isPageAvailable]
    )

    useEffect(() => {
        // NOTE: temporary solution to get dashboards list
        // after re-writing the sidebar, this request should be in the rendering of the sidebar item
        if (isDashboardsPageAvailable) {
            dispatch(fetchDashboardsList())
        }
    }, [dispatch, isDashboardsPageAvailable])

    const SIDEBAR_DATA = {
        Dashboard: [
            {
                type: 'Dashboard',
                title: 'Home',
                icon: HomeLogoSvg,
                path: APP_ROUTES.HOME,
                subMenu: null,
                actionBtn: null,
                disabled: false,
            },
            {
                type: 'Dashboard',
                title: 'Operations',
                icon: OperationsLogoSvg,
                path: APP_ROUTES.OPERATIONS,
                actionBtn: null,
                disabled: false,
            },
            {
                type: 'Dashboard',
                title: octupDictionary.pages[APP_ROUTES.OPERATIONS_OVERVIEW],
                isIconSVG: true,
                icon: <SvgIcon component={OrdersIcon} fontSize="large" />,
                path: APP_ROUTES.OPERATIONS_OVERVIEW,
            },
            {
                type: 'Dashboard',
                title: 'Warehousing',
                isIconSVG: true,
                icon: <SvgIcon component={WarehouseIcon} fontSize="large" />,
                path: APP_ROUTES.WAREHOUSING,
                actionBtn: null,
                disabled: false,
            },
            {
                type: 'Dashboard',
                title: 'Marketing',
                icon: MarketingLogoSvg,
                path: APP_ROUTES.MARKETING,
                subMenu: [{ title: 'Attribution', path: APP_ROUTES.MARKETING_ATTRIBUTION }],
                actionBtn: null,
                disabled: false,
            },
            {
                type: 'Dashboard',
                title: 'Unit Economics',
                icon: UnitEconomicsLogoSvg,
                path: APP_ROUTES.UNIT_ECONOMICS,
                subMenu: null,
                actionBtn: null,
            },
        ],
        Actionable: [
            {
                type: 'Actionable',
                title: 'Insights',
                icon: InsightsLogoSvg,
                path: APP_ROUTES.INSIGHTS,
                subMenu: null,
                actionBtn: null,
                disabled: false,
            },
            {
                type: 'Actionable',
                title: 'Objectives',
                icon: ObjectivesLogoSvg,
                path: APP_ROUTES.OBJECTIVES,
                actionBtn: {
                    type: 'add',
                    info: 'Create a new objective',
                    onClick: ({ setShowEditObjectiveModal }) => {
                        setShowEditObjectiveModal({
                            show: true,
                            isEditState: true,
                            activeTable: HOME_TABLES_TYPES.OBJECTIVES,
                        })
                    },
                },
                disabled: false,
            },
            {
                type: 'Actionable',
                title: octupDictionary.pages[APP_ROUTES.WAREHOUSE],
                isIconSVG: true,
                icon: <SvgIcon component={WarehouseIcon} fontSize="large" />,
                path: APP_ROUTES.WAREHOUSE,
            },
            {
                type: 'Actionable',
                title: octupDictionary.pages[APP_ROUTES.CLIENTS],
                isIconSVG: true,
                icon: <SvgIcon component={UsersIcon} fontSize="large" />,
                path: APP_ROUTES.CLIENTS,
            },
            {
                type: 'Actionable',
                title: octupDictionary.pages[APP_ROUTES.CLIENTS_LOGISTICS],
                icon: OperationsLogoSvg,
                path: APP_ROUTES.CLIENTS_LOGISTICS,
            },
            {
                type: 'Actionable',
                title: octupDictionary.pages[APP_ROUTES.CLIENTS_SLA],
                icon: UnitEconomicsLogoSvg,
                path: APP_ROUTES.CLIENTS_SLA,
            },
            {
                type: 'Actionable',
                title: octupDictionary.pages[APP_ROUTES.DASHBOARDS],
                icon: <SvgIcon component={DashboardIcon} fontSize="large" />,
                // NOTE: page field is a temporary solution to control the visibility of the sidebar item
                // won't be necessary once the sidebar and routes will be rewritten
                page: APP_ROUTES.DASHBOARDS,
                isIconSVG: true,
                loading: dashboardsList.isLoading,
                actionBtn: {
                    type: 'add',
                    info: 'Add new board',
                    loading: dashboardsList.isLoading,
                    // Disable adding a new board button when user is on edit dashboard page, because it breaks page exiting
                    disabled: /\/Dashboards\/[^/]+\/(edit|new)/.test(location.pathname),
                    onClick: async ({ onShowSubMenu }) => {
                        const id = uuidv4()
                        navigate(
                            resolveRoutePath(APP_ROUTES.DASHBOARD_CREATE, { ':dashboardId': id })
                        )
                        onShowSubMenu()
                    },
                },
                showSubMenuDefault: true,
                subMenu: dashboardsList.data?.map((dashboard) => ({
                    ...dashboard,
                    title: dashboard.name,
                    path: resolveRoutePath(APP_ROUTES.DASHBOARD, {
                        ':dashboardId': dashboard.id,
                    }),
                })),
            },
        ],
        System: [
            {
                type: 'System',
                title: 'Integrations',
                icon: IntegrationsIconSvg,
                path: APP_ROUTES.INTEGRATIONS,
                subMenu: null,
                actionBtn: true,
                disabled: false,
            },
            {
                type: 'System',
                title: 'Quick-Guide',
                icon: LightningIconSvg,
                feature: APP_FEATURES.QUICK_GUIDE,
                subMenu: null,
                actionBtn: true,
                onClick: () => {
                    document.getElementById('activate-tour').click()
                },
            },
            // NOTE: temporarily hidden until we decide what to do with it

            // {
            //     type: 'System',
            //     title: 'What’s new',
            //     icon: WhatsNewIconSvg,
            //     feature: APP_FEATURES.WHATS_NEW,
            //     subMenu: null,
            //     actionBtn: true,
            //     disabled: false,
            //     onClick: () => {
            //         octupDispatch.whatsNew.setShowModal(true)
            //     },
            // },
            {
                type: 'System',
                title: 'Help & Support',
                icon: HelpNSupportLogoSvg,
                subMenu: null,
                actionBtn: null,
                disabled: false,
                isNav: false,
                externalMenu: {
                    options: [
                        {
                            title: 'Knowledge Base',
                            feature: APP_FEATURES.INTERCOM,
                            leftIcon: (
                                <KnowledgeIconSvg customColor={OctupColors.primaryTextDark} />
                            ),
                            rightIcon: <OutsourceSvg customColor={OctupColors.primaryTextDark} />,
                            clickHandler: () => {
                                window.open('https://intercom.help/octup/en')
                            },
                        },
                        {
                            title: 'Contact Support',
                            leftIcon: <ContactSvgIcon customColor={OctupColors.primaryTextDark} />,
                            clickHandler: () => {
                                window.open(
                                    'mailto:support@octup.com?subject=Octup%20New%20Support%20Ticket&body=Please%20assist%20with%20the%20following%20:'
                                )
                            },
                        },
                    ],
                    menuStyle: {
                        borderRadius: '.8rem',
                        minWidth: '28rem',
                        boxShadow: '8px 8px 24px 0px rgba(80, 75, 90, 0.08)',
                    },
                    optionStyle: {
                        display: 'flex',
                        alignItem: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        gap: '.5rem',
                    },
                },
            },
        ],
    }

    const filterSidebarData = useCallback(
        (sidebarData) =>
            Object.entries(sidebarData).reduce((acc, [category, items]) => {
                acc[category] = items.filter((item) => {
                    const page = item.page || item.path
                    if (page && !isPageAvailable(page)) return false
                    if (item.feature && !isFeatureAvailable(item.feature)) return false

                    if (item.externalMenu) {
                        item.externalMenu.options = item.externalMenu?.options?.filter((subItem) =>
                            isFeatureAvailable(subItem.feature)
                        )
                    }

                    item.subMenu = item.subMenu?.filter(
                        (subItem) => subItem.path && isPageAvailable(subItem.path)
                    )
                    return true
                })

                return acc
            }, {}),
        [isFeatureAvailable, isPageAvailable]
    )

    return filterSidebarData(SIDEBAR_DATA)
}
