import { SvgIcon } from '@mui/material'
import { InfoIcon } from '@octup/core/assets/icons'
import { DoubleCheckMarks } from 'shared/assets/icons/DoubleCheckMarks'
import { OctupTooltip } from 'shared/ui/MUIWrappers/OctupTooltip'
import { getCurrencySign } from 'shared/utils/strings-util'
import {
    ItemContainer,
    ItemImage,
    ItemImageAndName,
    StyledPrice,
    TextDiv,
} from './SingleOrderDetails.styles'

export function OrderItems({ items, singleOrder, singleOrderItems, singleOrderTpItems }) {
    return (
        <>
            {singleOrderItems &&
                singleOrderItems.map((item, index) => {
                    const money = item?.originalUnitPriceSet?.presentmentMoney
                    return (
                        <ItemContainer key={item?.id + index}>
                            <ItemImageAndName>
                                <DoubleCheckMarks />
                                <ItemImage
                                    src={item?.product?.featuredImage?.url}
                                    alt={item?.name}
                                />
                                {item?.name}
                            </ItemImageAndName>
                            <StyledPrice>
                                {`${getCurrencySign(
                                    (items, singleOrder?.data?.order?.currencyCode)
                                )}${money?.amount}
                                                     x ${item?.currentQuantity}
                                                    `}
                            </StyledPrice>
                        </ItemContainer>
                    )
                })}
            {singleOrderTpItems?.length > 0 && (
                <OctupTooltip
                    customeDesign={{ maxWidth: '150rem' }}
                    title={singleOrderTpItems.map((item, index) => {
                        const money = item?.originalUnitPriceSet?.presentmentMoney
                        return (
                            <ItemContainer
                                key={item?.id + index}
                                showSeperator={true}
                                minWidth={'60rem'}
                            >
                                <ItemImageAndName>
                                    <DoubleCheckMarks />
                                    <ItemImage
                                        src={item?.product?.featuredImage?.url}
                                        alt={item?.name}
                                    />
                                    {item?.name}
                                </ItemImageAndName>
                                <StyledPrice>
                                    {money?.amount}
                                    {getCurrencySign(
                                        (items, singleOrder?.data?.order?.currencyCode)
                                    )}{' '}
                                    x {item?.currentQuantity}
                                </StyledPrice>
                            </ItemContainer>
                        )
                    })}
                    position={'top'}
                >
                    <TextDiv>
                        {singleOrderTpItems.length} more
                        <SvgIcon component={InfoIcon} color="text.primary" fontSize="small" />
                    </TextDiv>
                </OctupTooltip>
            )}
        </>
    )
}
