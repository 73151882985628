import { Tooltip } from '@mui/material'
import { Container } from './Tooltip.styles'

export const OctupTooltip = ({
    children,
    title,
    customeDesign,
    position = 'bottom',
    isContainerized,
    disableHoverListener,
    disableInteractive = true,
    className,
    ...props
}) => {
    if (!title) return children

    return (
        <Tooltip
            arrow
            className={className}
            placement={position}
            disableInteractive={disableInteractive}
            disableHoverListener={disableHoverListener}
            onOpen={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
            onMouseOver={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
            componentsProps={{
                tooltip: {
                    sx: {
                        margin: '1rem',
                        fontSize: '1.5rem',
                        maxWidth: `${customeDesign ? customeDesign.maxWidth : '45rem'}`,
                        maxHeight: '100%',
                        letterSpacing: '.04rem',
                        color: '#504B5A',
                        borderRadius: 1.6,
                        padding: '2rem',
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '2rem',
                        boxShadow: '.6rem .6rem 2.2rem rgba(80, 75, 90, 0.08)',
                        bgcolor: 'common.white',
                        '& .MuiTooltip-arrow': {
                            color: 'common.white',
                            fontSize: '2rem',
                        },
                    },
                },
            }}
            title={title ? title : customeDesign ? customeDesign : ''}
        >
            {isContainerized ? <Container {...props}>{children}</Container> : children}
        </Tooltip>
    )
}
