import { forwardRef, useCallback, useMemo } from 'react'
import type { DashboardTemplateGroupType, MetricsSectionType } from '@octup/core/models'
import { MetricsSectionField } from '@/new_components/Dashboards/DashboardForm'
import { setSelectedDashboardSection } from '@/state/features/dashboards'
import { useAppDispatch, useAppSelector } from '@/state/hooks'

export type MetricsSectionFieldContainerPropsType = Pick<DashboardTemplateGroupType, 'group'> & {
    name: string
    value?: MetricsSectionType['id']
    onChange: (value?: MetricsSectionType['id'] | null) => void
}

export const MetricsSectionFieldContainer = forwardRef<
    HTMLDivElement,
    MetricsSectionFieldContainerPropsType
>(({ group, name: fieldName, value: metricsSectionId, onChange }, ref) => {
    const dispatch = useAppDispatch()
    const { dashboard } = useAppSelector((state) => state.dashboards)
    const { metricsSectionsList } = useAppSelector((state) => state.newMetrics)
    const { selectedDashboardSection } = dashboard
    const isDisabled = metricsSectionsList.isLoading

    const isSelected = useMemo(
        () => selectedDashboardSection?.name === fieldName,
        [selectedDashboardSection, fieldName]
    )

    const metricsSectionsListById = useMemo(
        (): Record<MetricsSectionType['id'], MetricsSectionType> | undefined =>
            metricsSectionsList.data?.reduce(
                (acc, metric) => ({ ...acc, [metric.id]: metric }),
                {}
            ),
        [metricsSectionsList.data]
    )

    const metricsSectionData = useMemo(() => {
        if (!metricsSectionId) return
        return metricsSectionsListById?.[metricsSectionId]
    }, [metricsSectionsListById, metricsSectionId])

    const handleClick = useCallback(() => {
        if (isDisabled) return
        dispatch(setSelectedDashboardSection({ id: metricsSectionId, name: fieldName, group }))
    }, [isDisabled, metricsSectionId, dispatch, fieldName, group])

    const handleDelete = useCallback(
        (event: React.MouseEvent<SVGElement>) => {
            event.stopPropagation()
            onChange(null)
            if (selectedDashboardSection?.id === metricsSectionId) {
                dispatch(setSelectedDashboardSection({ id: null }))
            }
        },
        [dispatch, metricsSectionId, onChange, selectedDashboardSection?.id]
    )

    return (
        <MetricsSectionField
            ref={ref}
            data={metricsSectionData}
            onClick={handleClick}
            onDelete={handleDelete}
            isSelected={isSelected}
            isDisabled={isDisabled}
        />
    )
})
