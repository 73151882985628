import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Dropdown from '../../../shared/ui/Dropdown/Dropdown'
import { OctupColors } from '../../../shared/theme/colors/OctupColors'
import { OctupTextArea } from '../../../shared/ui/Inputs/OctupTextArea'
import { ChevronLeftSvg } from '../../../shared/assets/icons/ChevronLeftSvg'
import { useNavigate } from 'react-router-dom'
import { sendFeedback } from '../../../features/general/generalService'

export const MobileSendFeedbackPage = () => {
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.auth)
    const [feedback, setFeedback] = useState('')

    return (
        <MainContainer>
            <TopSideRow>
                <TopRowButton type="button" onClick={() => navigate(-1)} width={'6vw'}>
                    <EditButtonImageContainer>
                        <ChevronLeftSvg active={true} />
                    </EditButtonImageContainer>
                </TopRowButton>
                <TopSideTitle>Send Feedback</TopSideTitle>
            </TopSideRow>
            <DropdownConainer>
                <Dropdown
                    title={'a subject'}
                    isMultiSelection={false}
                    options={[
                        {
                            name: 'Request a metric',
                        },
                        {
                            name: 'Report a bug',
                        },
                        {
                            name: 'Request a feature',
                        },
                    ]}
                />
            </DropdownConainer>
            <TextAreaContainer>
                <OctupTextArea
                    value={feedback}
                    rows={15}
                    placeholder={"What's on your mind?"}
                    onChange={(e) => {
                        setFeedback(e?.target?.value)
                    }}
                    width={'100%'}
                ></OctupTextArea>
            </TextAreaContainer>
            <StyledButton
                style={{ fontSize: '2rem' }}
                variant="contained"
                color="success"
                onClick={() => {
                    sendFeedback(feedback, userInfo?.token)
                }}
            >
                {'Submit'}
            </StyledButton>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-family: Arial, sans-serif;
`

const DropdownConainer = styled.div`
    width: 100%;
`

const Button = styled.button`
    width: ${(p) => (p.width ? p.width : '40vw')};
    background: #f8f7fb;
    box-shadow:
        -2px -2px 8px #ffffff,
        3px 3px 4px rgba(80, 75, 90, 0.16);
    border-radius: 8px;
    border-width: 0;
    border-color: #f8f7fb;
    height: 10%;
    color: white;
`

const TopRowButton = styled(Button)`
    right: 0;
    height: 80%;
    margin-top: 0.8vh;
`

const StyledButton = styled(Button)`
    font-size: 22rem;
    width: 95%;
    margin: 1rem;
    text-transform: none !important;
    height: 6rem;
    background: linear-gradient(
        44.56deg,
        ${OctupColors.primaryActionableDark} -1.25%,
        ${OctupColors.primaryActionableLight} 100.79%
    );

    box-shadow:
        -2px -2px 8px #ffffff,
        2px 2px 4px rgba(80, 75, 90, 0.16);
    border-radius: 0.8rem !important;

    :disabled {
        background: #c2c0c7;
        box-shadow:
            -2px -2px 8px #ffffff,
            2px 2px 4px rgba(80, 75, 90, 0.16);
    }
`

const TextAreaContainer = styled.div`
    padding: 1rem;
    width: 100%;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`

const TopSideRow = styled(Row)`
    height: 4vh;
    width: 95vw;
`

const EditButtonImageContainer = styled.div``

const TopSideTitle = styled.h1`
    font-weight: 600;
    font-size: 2rem;
    line-height: 2rem;
    margin-left: 30vw;
    margin-top: 1vh;
    //margin-left: auto;
    display: flex;
    align-items: center;
    color: ${OctupColors.primaryTextLight};
    position: absolute;
`
