import { getIconColor } from './utils'

export function ActionsBtnProductIcon({
    active,
    customActiveColor = '#504B5A',
    customColor = '#504B5A',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M19.3333 8.63959H4.66667C4.29848 8.63959 4 8.95748 4 9.34961V12.1897C4 12.5819 4.29848 12.8998 4.66667 12.8998H19.3333C19.7015 12.8998 20 12.5819 20 12.1897V9.34961C20 8.95748 19.7015 8.63959 19.3333 8.63959Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.6667 12.8997V19.29C18.6667 19.4783 18.5964 19.6589 18.4714 19.792C18.3464 19.9252 18.1768 20 18 20H6.00001C5.8232 20 5.65363 19.9252 5.52861 19.792C5.40358 19.6589 5.33334 19.4783 5.33334 19.29V12.8997"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 8.63959V20"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.7712 7.63542C14.8284 8.63955 12 8.63955 12 8.63955C12 8.63955 12 5.62716 12.9428 4.62303C13.318 4.22394 13.8266 3.99984 14.3568 4C14.887 4.00016 15.3955 4.22457 15.7704 4.62389C16.1454 5.02321 16.3561 5.56475 16.3562 6.12948C16.3564 6.6942 16.146 7.23587 15.7712 7.63542V7.63542Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.22875 7.63542C9.17156 8.63955 12 8.63955 12 8.63955C12 8.63955 12 5.62716 11.0572 4.62303C10.682 4.22394 10.1734 3.99984 9.6432 4C9.11296 4.00016 8.60449 4.22457 8.22956 4.62389C7.85462 5.02321 7.64392 5.56475 7.64377 6.12948C7.64362 6.6942 7.85403 7.23587 8.22875 7.63542V7.63542Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
