import React from 'react'

export function MailSent({ active, customActiveColor, customColor }) {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21 13.125V5.75H3V18.5C3 18.6989 3.07902 18.8897 3.21967 19.0303C3.36032 19.171 3.55109 19.25 3.75 19.25H13"
                stroke="#00A8A8"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21 5.75L12 14L3 5.75"
                stroke="#00A8A8"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.8295 15.8501L18.4295 20.6501L16.2295 18.2502"
                stroke="#00A8A8"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
