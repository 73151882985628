import React from 'react'
import { getIconColor } from './utils'

export function SingleOrderCustomerNameSvg({
    active,
    customActiveColor = '#007F82',
    customColor = '#007F82',
}) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
            <path
                d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
            <path
                d="M5.9812 18.6913C6.54615 17.5806 7.40744 16.6478 8.46973 15.9963C9.53202 15.3448 10.7539 15 12 15C13.2462 15 14.468 15.3448 15.5303 15.9963C16.5926 16.6478 17.4539 17.5806 18.0189 18.6913"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
