import { LoadingStateGraphShimmer } from './MetricsDetailsGraphs/LoadingStateGraphShimmer'
import { MetricsGraph } from './MetricsDetailsGraphs/MetricsGraph'

export function MetricsDetailsGraph({
    isLoadingDeepdives,
    selectedMetricData,
    isBreakdown,
    breakdownItemsData,
    data,
}) {
    if (isLoadingDeepdives) {
        return <LoadingStateGraphShimmer />
    }

    return (
        <MetricsGraph
            selectedMetricData={selectedMetricData}
            isBreakdown={isBreakdown}
            breakdownItemsData={breakdownItemsData}
            data={data}
        />
    )
}
