export type FormHelperTextStyleType = {
    isInline: boolean
}

export const getStyle =
    ({ isInline }: FormHelperTextStyleType) =>
    () => ({
        ...(isInline && {
            whiteSpace: 'nowrap',
            position: 'absolute',
            bottom: -15,
        }),
    })
