import { Grid, Stack } from '@mui/material'
import { UEMessageCard } from 'components/Modals/UnitEconomicsModals/UEMessageCard'
import { CardExpendable } from 'shared/ui/Card/CardExpendable'
import { containerStyle, cardContainerStyle } from './ProductDetails.styles'
import { ProductCost } from '../ProductCost'
import { ProductMainInfo } from '../ProductMainInfo'

export const ProductDetails = ({ selectedProduct, detailsCards }) => (
    <Grid container direction="column" wrap="nowrap" spacing={3} sx={containerStyle}>
        <Grid item container>
            <ProductMainInfo selectedProduct={selectedProduct} />
        </Grid>
        <Grid item container>
            <UEMessageCard />
        </Grid>
        {detailsCards.map(({ id, sx, content, ...cost }) => (
            <Grid item key={id} sx={sx}>
                <CardExpendable hideShadow showBorder>
                    <Stack sx={cardContainerStyle} wrap="nowrap">
                        <ProductCost highlightTitle {...cost} />
                        {content}
                    </Stack>
                </CardExpendable>
            </Grid>
        ))}
    </Grid>
)
