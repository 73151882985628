import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { fetchPageSettingsByName } from 'state/features/pages'
import PageLayout from '@/components/PageLayout/PageLayout'
import type { APP_ROUTES } from '@/models/appConfig'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { PAGE_BY_VIEW } from './utils'

export const SystemPageContainer = () => {
    const location = useLocation()
    const dispatch = useAppDispatch()
    const pageName = location.pathname.split('/').pop() as APP_ROUTES
    const { dashboardsById } = useAppSelector((state) => state.dashboards)
    const { metricsSections } = useAppSelector((state) => state.newMetrics)
    const settings = useAppSelector((state) => state.pages.settingsByName?.[pageName])

    const { view, dashboards } = settings?.data || {}
    const Page = view && PAGE_BY_VIEW[view]
    const isLoading = settings?.isLoading || dashboardsById.isLoading || metricsSections.isLoading

    useEffect(() => {
        dispatch(fetchPageSettingsByName(pageName))
    }, [dispatch, pageName])

    return (
        //  @ts-expect-error: PageLayout is a JavaScript file
        <PageLayout error={settings?.error} isLoading={isLoading}>
            {Page && <Page data={dashboards} isLoading={isLoading} />}
        </PageLayout>
    )
}
