import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { DashboardType } from 'models/dashboards'
import { deleteDashboard } from 'services/dashboards'
import type { ThunkAPIConfigType } from 'services/utils'

type DeleteDashboardStateType = {
    data?: DashboardType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: DeleteDashboardStateType = {
    data: undefined,
    isLoading: false,
    error: undefined,
}

export const fetchDeleteDashboard = createAsyncThunk<DashboardType, string, ThunkAPIConfigType>(
    'fetchDeleteDashboard',
    async (dashboardId, thunkAPI) => deleteDashboard(dashboardId, thunkAPI)
)

export const deleteDashboardSlice = createSlice({
    name: 'deleteDashboardSlice',
    initialState: INITIAL_STATE,
    reducers: {
        clearDeletedDashboard() {
            return INITIAL_STATE
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeleteDashboard.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchDeleteDashboard.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchDeleteDashboard.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const { clearDeletedDashboard } = deleteDashboardSlice.actions
export const deleteDashboardReducer = deleteDashboardSlice.reducer
