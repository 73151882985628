import type { Components, Theme } from '@mui/material'

type LinkOverridesType = Pick<Components<Theme>, 'MuiLink'>

export const LINK_OVERRIDES: LinkOverridesType = {
    MuiLink: {
        defaultProps: {
            underline: 'hover',
        },
        styleOverrides: {
            root: {
                cursor: 'pointer',
                fontWeight: '600',
            },
        },
    },
}
