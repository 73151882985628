import { OctupBaseModal } from '../../shared/ui/Modal/OctupBaseModal'
import styled from 'styled-components'
import { MessageInABottle } from '../../shared/assets/icons/MessageInABottle'

export const CustomPlanModal = ({ onClose }) => (
    <OctupBaseModal onDismissModal={onClose}>
        <ModalInnerContainer>
            <div>
                <MessageInABottle />
            </div>
            <ContentContainer>
                <Title>{`Custom plan requires a more personal message :)`}</Title>
                <Section>
                    <Text>
                        Email us at
                        <Link href="mailto:support@octup.com">support@octup.com</Link>
                        or send us a message
                    </Text>
                    <Text>from the bottom-right corner.</Text>
                </Section>
                <Section>
                    <Text>We’ll get back to you ASAP, until then you’re account is on hold.</Text>
                    <Text>Don’t worry, we’re not deleting any of your data or integrations.</Text>
                </Section>
            </ContentContainer>
        </ModalInnerContainer>
    </OctupBaseModal>
)

const ModalInnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 72rem;
    height: 39rem;
    color: rgba(80, 75, 90, 1);
`
const ContentContainer = styled(ModalInnerContainer)`
    min-width: fit-content;
    gap: 1rem;
`
const Title = styled.div`
    font-weight: 600;
    font-size: 2rem;
`

const Text = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    gap: 0.5rem;
`

const Link = styled.a`
    font-size: inherit;
    font-weight: 600;
    color: rgba(0, 127, 130, 1);
`
const Section = styled.section``
