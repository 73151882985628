import styled from 'styled-components'
import TrialEndedSvg from '../../shared/assets/images/TrialEnded.svg'
import { Flex } from '../../shared/styledComponents'
import { useSelector } from 'react-redux'
import { useOctupDispatch } from 'hooks'
import { Button } from '@octup/core/ui-kit'

export const TrialEnded = ({ currentStep, setCurrentStep, isTrial }) => {
    const octupDispatch = useOctupDispatch()
    const { userInfo } = useSelector((state) => state.auth)

    return (
        <MainContainer>
            <StyledImage src={TrialEndedSvg} />
            <Title>
                {`${userInfo?.fullName}, your ${
                    isTrial ? '30-day trial voyage' : 'subscription'
                }  has
                reached its final tide`}
            </Title>
            <Text>Keep riding the waves of our platform's full functionalities.</Text>
            <Text>Chart your course by choosing a plan, and enjoy Octup treasures!</Text>
            <ButtonContainer>
                <Button variant="secondary" onClick={octupDispatch.auth.logout}>
                    Logout
                </Button>
                <Button variant="contained" onClick={() => setCurrentStep(++currentStep)}>
                    Explore Plans
                </Button>
            </ButtonContainer>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 500px;
    padding-top: 1rem;
`

const Title = styled.div`
    color: #db2059;
    font-weight: 600;
    font-size: 1.7rem;
    text-align: center;
    padding-bottom: 1rem;
`

const Text = styled.div`
    font-size: 1.5rem;
    text-align: center;
    width: 80%;
`

const StyledImage = styled.img`
    width: 37rem;
`

const ButtonContainer = styled(Flex)`
    padding-top: 2.2rem;
    width: 100%;
    justify-content: flex-end;
    gap: 2rem;
`
