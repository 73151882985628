// GUIDE: https://mui.com/material-ui/customization/theme-components/

/**
 *  NOTE: use variants for overriding styles when:
 *  1. have several props
 *  2. need to add new prop values
 *  3. no class name to apply
 */

import { ACCORDION_OVERRIDES } from './accordion'
import { AUTOCOMPLETE_OVERRIDES } from './autocomplete'
import { BUTTON_OVERRIDES } from './button'
import { CARD_OVERRIDES } from './card'
import { DATA_GRID_OVERRIDES } from './dataGrid'
import { DATE_PICKER_OVERRIDES } from './datePicker'
import { DIALOG_OVERRIDES } from './dialog'
import { ICON_BUTTON_OVERRIDES } from './iconButton'
import { ICONS_OVERRIDES } from './icons'
import { INPUT_OVERRIDES } from './input'
import { LINK_OVERRIDES } from './link'
import { LIST_OVERRIDES } from './list'
import { MENU_LIST_OVERRIDES } from './menuList'
import { PAPER_OVERRIDES } from './paper'
import { TOOLTIP_OVERRIDES } from './tooltip'
import { TYPOGRAPHY_OVERRIDES } from './typography'

export const OVERRIDES = {
    ...BUTTON_OVERRIDES,
    ...INPUT_OVERRIDES,
    ...TYPOGRAPHY_OVERRIDES,
    ...DATE_PICKER_OVERRIDES,
    ...AUTOCOMPLETE_OVERRIDES,
    ...PAPER_OVERRIDES,
    ...ICONS_OVERRIDES,
    ...CARD_OVERRIDES,
    ...MENU_LIST_OVERRIDES,
    ...DIALOG_OVERRIDES,
    ...ICON_BUTTON_OVERRIDES,
    ...LIST_OVERRIDES,
    ...TOOLTIP_OVERRIDES,
    ...ACCORDION_OVERRIDES,
    ...LINK_OVERRIDES,
    ...DATA_GRID_OVERRIDES,
}
