export function extractErrMsg(error) {
    return (
        error?.response?.data?.ERROR.toString() ||
        error?.response?.data?.message ||
        error?.message ||
        error?.toString()
    )
}

export const generateConfig = (token, contentType) => {
    return {
        // withCredentials: true,
        headers: {
            Authorization: token ? `${token}` : null,
            'Content-Type': contentType ? contentType : 'application/json',
        },
    }
}

export function isPasswordStrong(password) {
    // Regular expressions to test the password against
    const hasUpperCase = /[A-Z]/.test(password)
    const hasLowerCase = /[a-z]/.test(password)
    const hasNumbers = /\d/.test(password)
    const hasSymbols = /[^\w\s]/.test(password)

    // Check the length and complexity of the password
    const isLongEnough = password.length >= 8
    const isComplexEnough = hasUpperCase && hasLowerCase && hasNumbers && hasSymbols

    // Return true if the password is long and complex enough, false otherwise
    return isLongEnough && isComplexEnough
}

export function createDummys(dataArr, wantedLength, metricTitle = '') {
    const res = []

    for (let i = 0; i < wantedLength; ++i) {
        if (i < dataArr?.length) {
            res.push(dataArr[i])
        } else {
            res.push({
                metric: {
                    metricTitle,
                },
            })
        }
    }

    return res
}
