import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { EmailSettingsType } from 'models/settings'
import { editEmailSettings } from 'services/settings'
import type { ThunkAPIConfigType } from 'services/utils'

type EditEmailSettingsStateType = {
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: EditEmailSettingsStateType = {
    isLoading: false,
    error: undefined,
}

export const fetchEditEmailSettings = createAsyncThunk<
    undefined,
    EmailSettingsType,
    ThunkAPIConfigType
>('fetchEditEmailSettings', editEmailSettings)

export const editEmailSettingsSlice = createSlice({
    name: 'editEmailSettings',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEditEmailSettings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchEditEmailSettings.fulfilled, (state) => {
                state.isLoading = false
            })
            .addCase(fetchEditEmailSettings.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const editEmailSettingsReducer = editEmailSettingsSlice.reducer
