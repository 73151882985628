import { Divider, Grid } from '@mui/material'
import type { MetricsSectionPropsType, MetricType } from '@octup/core/models'
import { MetricOverview, MetricsSectionCard, MetricsSectionTitle } from '../__common__'

type TotalMetricsSectionPropsType = Omit<MetricsSectionPropsType, 'data'> & {
    metrics?: MetricType[]
}

export const TotalMetricsSection = ({
    metrics,
    isLoading,
    ...props
}: TotalMetricsSectionPropsType) => (
    <MetricsSectionCard height={40} isLoading={isLoading} {...props}>
        <Grid item container xs alignItems="center" wrap="nowrap" columnSpacing={4}>
            {metrics?.map((metric, index) => (
                <Grid
                    // NOTE: key is index in case of loading state and using PLACEHOLDER_METRICS
                    key={metric.id || index}
                    item
                    container
                    spacing={1}
                    wrap="nowrap"
                    xs={12 / metrics.length}
                    minHeight="70%"
                >
                    <Grid item container direction="column">
                        <Grid item xs={1} container>
                            <MetricsSectionTitle
                                name={metric.name}
                                description={metric.description}
                                sources={metric.sources}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs container>
                            <MetricOverview metric={metric} isLoading={isLoading} showTrend />
                        </Grid>
                    </Grid>
                    {index !== metrics.length - 1 && (
                        <Grid item>
                            <Divider orientation="vertical" />
                        </Grid>
                    )}
                </Grid>
            ))}
        </Grid>
    </MetricsSectionCard>
)
