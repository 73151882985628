import { TOTAL, ORGANIC, UNMATCHED_EVENTS } from 'constants'
import { OctupTableHeader } from 'new_components/Attribution/OctupTableHeader'
import { CampaignsTableRowContainer } from '../CampaignsTableRowContainer'

const SUB_ROW_FIELD = 'campaigns'
const ROW_KEY_FIELD = 'source'

const TABLE_HEADERS = [
    {
        title: 'Source / Campaign',
    },
    {
        title: 'Conversion Value (Source)',
        tooltip: 'conversion_value_source',
    },
    {
        title: 'Conversion Value (Octup)',
        tooltip: 'conversion_value_octup',
        renderHeader: () => <OctupTableHeader title="Conversion Value (Octup)" />,
    },
    {
        title: 'Spend',
        tooltip: 'spend',
    },
    {
        title: 'Sessions',
        tooltip: 'sessions',
    },
    {
        title: 'Added to cart',
        tooltip: 'added_to_cart',
    },
    {
        title: 'Orders',
        tooltip: 'orders',
    },
    {
        title: 'Orders (Octup)',
        tooltip: 'attribution_purchase_count',
        renderHeader: () => <OctupTableHeader title="Orders (Octup)" />,
    },
    {
        title: 'New Customers',
        tooltip: 'new_customer_count',
    },
    {
        title: 'Returning Customers',
        tooltip: 'returning_customer_count',
    },
    {
        title: 'ROAS (Source)',
        tooltip: 'ROAS_platform',
    },
    {
        title: 'ROAS (Octup)',
        tooltip: 'ROAS_attributed',
        renderHeader: () => <OctupTableHeader title="ROAS (Octup)" />,
    },
    {
        title: 'AOV',
        tooltip: 'AOV',
    },
    {
        title: 'CR',
        tooltip: 'CR',
    },
]

const SORT_HEADERS = {
    'source/campaign': {
        options: ['campaign_name', 'total', 'source'],
    },
    'conversionvalue(source)': 'conversion_value_source',
    'conversionvalue(octup)': 'conversion_value_octup',
    spend: 'spend',
    sessions: 'sessions',
    addedtocart: 'added_to_cart',
    orders: 'orders',
    'orders(octup)': 'attribution_purchase_count',
    newcustomers: 'new_customer_count',
    returningcustomers: 'returning_customer_count',
    'roas(source)': 'ROAS_platform',
    'roas(octup)': 'ROAS_attributed',
    aov: 'AOV',
    cr: 'CR',
}

const SEARCH_FIELDS = {
    source: 'source',
    campaign_name: 'campaign_name',
    conversion_value_source: 'conversion_value_source',
    conversion_value_octup: 'conversion_value_octup',
    spend: 'spend',
    sessions: 'sessions',
    added_to_cart: 'added_to_cart',
    orders: 'orders',
    attribution_purchase_count: 'attribution_purchase_count',
    new_customer_count: 'new_customer_count',
    returning_customer_count: 'returning_customer_count',
    ROAS_platform: 'ROAS_platform',
    ROAS_attributed: 'ROAS_attributed',
    AOV: 'AOV',
    CR: 'CR',
}

export const getCampaignsCountLabel = (data) => {
    const count = data?.reduce((total, source) => total + source[SUB_ROW_FIELD]?.length || 0, 0)
    const label = `${count} campaign${count > 1 ? 's' : ''}`

    return label
}

const getCustomCampaignName = (campaignId) =>
    (campaignId && `Campaign Id: ${campaignId}`) || ORGANIC

export const getProcessedCampaignsData = (data = []) =>
    data.map((itemData) => {
        if ('campaigns' in itemData) {
            return { ...itemData, campaigns: getProcessedCampaignsData(itemData.campaigns) }
        }

        if ('campaign_name' in itemData && !itemData.campaign_name) {
            return {
                ...itemData,
                hasCustomCampaignName: true,
                campaign_name: getCustomCampaignName(itemData.campaign_id),
            }
        }

        return itemData
    })

export const getTableData = ({ isLoading, campaignsData }) => ({
    rows: isLoading
        ? []
        : [
              ...getProcessedCampaignsData(campaignsData?.data),
              { ...campaignsData?.total, source: TOTAL },
          ],
    headers: TABLE_HEADERS,
})

const renderRows = (row) => (
    <CampaignsTableRowContainer row={row} isTotalRow={row.source === TOTAL} showNoData />
)

const renderSubRow = (row, _, parentRow) => (
    <CampaignsTableRowContainer row={row} parentRow={parentRow} isSubRow showNoData />
)

const DEFAULT_SORT_BY = {
    title: 'conversionvalue(source)',
    direction: -1,
    unsortedRows: [{ field: 'campaign_name', values: [ORGANIC, UNMATCHED_EVENTS] }],
}

export const CAMPAIGNS_TABLE_CONFIG = {
    renderRows,
    renderSubRow,
    rowKeyField: ROW_KEY_FIELD,
    subRowField: SUB_ROW_FIELD,
    searchFilterFields: SEARCH_FIELDS,
    sortingHeadersToDataMapping: SORT_HEADERS,
    defaultSortBy: DEFAULT_SORT_BY,
    unsortedRows: [{ field: 'source', values: [TOTAL] }],
}
