import styled from 'styled-components'

const TitleContainer = styled.div`
    width: 100%;
    height: 3rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
`

const Title = styled.div`
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
    color: #504b5a;
`

const Icon = styled.div``

const CardContentStyled = styled.div`
    padding: 1.5rem;
    //min-height: 25vh !important;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    /* padding-bottom: 2.5rem; */
`
const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1;
    height: 100%;
    width: 100%;
`

export { TitleContainer, Title, Icon, CardContentStyled, ChartContainer }
