import { useMemo } from 'react'
import { ThemeProvider } from '@octup/core/theme'
import { Grid } from '@octup/core/ui-kit'
import { CompanyNameLogoContainer } from '@/new_containers/components/__common__'
import { useAppSelector } from '@/state/hooks'
import { createTheme } from '@/theme'
import {
    containerStyle,
    contentContainerStyle,
    contentStyle,
    sideContentContainerStyle,
} from './styles'

type AuthLayoutPropsType = {
    children: React.ReactNode
    sideContent?: React.ReactNode
}

export const AuthLayout = ({ children, sideContent }: AuthLayoutPropsType) => {
    const { settings } = useAppSelector((state) => state.appConfig)

    // NOTE: Temporary solution, remove ThemeProvider from here after supporting dark mode everywhere
    const theme = useMemo(
        () => createTheme(settings.data.palette_mode),
        [settings.data.palette_mode]
    )

    return (
        <ThemeProvider theme={theme}>
            <Grid container sx={containerStyle}>
                <Grid
                    item
                    xs
                    container
                    wrap="nowrap"
                    direction="column"
                    gap={10}
                    sx={contentContainerStyle}
                >
                    <Grid item container>
                        <CompanyNameLogoContainer />
                    </Grid>
                    <Grid item container>
                        <Grid item container sx={contentStyle}>
                            {children}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs container alignItems="flex-end" sx={sideContentContainerStyle}>
                    <Grid item container sx={contentStyle}>
                        {sideContent}
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}
