import { ERROR_MESSAGES } from 'constants/validation'
import { useCallback, useState, useEffect } from 'react'
import { Button, SvgIcon } from '@octup/core/ui-kit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { useToast } from 'hooks'
import type { DashboardType } from 'models/dashboards'
import { DeleteIcon } from 'new_assets/icons'
import { DiscardChangesModalContainer } from 'new_containers/components/__common__'
import { useNavigateToDashboard } from 'new_containers/components/Dashboards/__common__'
import { useParams } from 'react-router-dom'
import {
    clearDeletedDashboard,
    fetchDeleteDashboard,
    removeDashboard,
} from 'state/features/dashboards'
import { useAppDispatch, useAppSelector } from 'state/hooks'

const MODAL_CONTENT = {
    header: 'Are you sure you wish to delete this board?',
}

export const DeleteDashboardContainer = () => {
    const { dashboardId } = useParams()
    const toast = useToast()
    const dispatch = useAppDispatch()
    const { dashboardsList } = useAppSelector((state) => state.dashboards)
    const navigateToDashboard = useNavigateToDashboard()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const toggleModalOpen = useCallback(() => setIsModalOpen((open) => !open), [])

    useEffect(() => {
        return () => {
            dispatch(clearDeletedDashboard())
        }
    }, [dispatch])

    const handleDelete = useCallback(() => {
        if (dashboardId) return dispatch(fetchDeleteDashboard(dashboardId))
    }, [dashboardId, dispatch])

    const handleSuccess = useCallback(
        (data: PayloadAction<DashboardType>) => {
            const dashboard = data.payload
            dispatch(removeDashboard(dashboard.id))
            navigateToDashboard({ dashboard, dashboardsList: dashboardsList.data })
        },
        [dashboardsList.data, dispatch, navigateToDashboard]
    )

    const handleFail = useCallback(() => {
        toast.error(ERROR_MESSAGES.SERVER_ERROR)
    }, [toast])

    return (
        <>
            <Button
                variant="secondary"
                size="small"
                startIcon={<SvgIcon component={DeleteIcon} />}
                onClick={toggleModalOpen}
            >
                Delete
            </Button>
            <DiscardChangesModalContainer
                showDiscardModal={isModalOpen}
                title="Delete this board"
                discardTitle="Delete Board"
                cancelTitle="Keep Board"
                onCancel={toggleModalOpen}
                onDiscard={handleDelete}
                onSuccess={handleSuccess}
                onFail={handleFail}
                content={MODAL_CONTENT}
            />
        </>
    )
}
