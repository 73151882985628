import { useEffect, useMemo } from 'react'
import { useOctupDispatch } from 'hooks'
import { useSelector } from 'react-redux'
import { AttributionPage } from 'new_pages'
import { useAttributionToast } from './hooks'

export const AttributionPageContainer = () => {
    const octupDispatch = useOctupDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const { data, error, isLoading } = useSelector((state) => state.attribution)

    useEffect(() => {
        octupDispatch.attribution.getAttribution(datePickerValues)
    }, [datePickerValues, octupDispatch.attribution])

    const isDataMissing = useMemo(
        () => !isLoading && !!data?.attribution && !userInfo?.client?.attribution,
        [isLoading, data?.attribution, userInfo?.client?.attribution]
    )

    useAttributionToast({ isDataMissing })

    return (
        <AttributionPage
            data={data?.attribution}
            isLoading={isLoading}
            error={error}
            isDataMissing={isDataMissing}
        />
    )
}
