import { Grid } from '@mui/material'
import type { MetricsSectionPropsType } from '@octup/core/models'
import { DoughnutChart } from '@/shared/ui/Charts/ChartDoughnut'
import { MetricsSectionCard } from '../__common__'

type DoughnutMetricsSectionPropsType = Omit<MetricsSectionPropsType, 'data'> & {
    // TODO: rewrite Doughnut and reuse type for chartConfig here
    chartConfig?: object
}

export const DoughnutMetricsSection = ({
    chartConfig,
    isLoading,
    ...props
}: DoughnutMetricsSectionPropsType) => (
    <MetricsSectionCard height={50} minHeight={40} isLoading={isLoading} {...props}>
        <Grid container direction="column" height="100%">
            {/* 
                the height is 0 to prevent the card from jumping
                TODO: remove after re-writing the doughnut component 
            */}
            <Grid item xs container height={0}>
                {/* TODO: make doughnut chart responsive */}
                {/* @ts-expect-error: DoughnutChart is a JavaScript file */}
                <DoughnutChart
                    {...chartConfig}
                    maxSize="40%"
                    titleFontSize="2rem"
                    isLoading={isLoading}
                />
            </Grid>
        </Grid>
    </MetricsSectionCard>
)
