import type { APP_ROUTES } from 'models/appConfig'

export const resolveRoutePath = (path: APP_ROUTES, mappings: Record<string, string>) => {
    let resolvedPath = path

    Object.entries(mappings).forEach(([pattern, value]) => {
        resolvedPath = path.replace(pattern, value) as APP_ROUTES
    })

    return resolvedPath
}
