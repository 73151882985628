import ReactDOM from 'react-dom'
import { OctupBackdrop } from '../Backdrop/OctupBackdrop'
import { OctupModalOverlay } from './ModalOverlay/OctupModalOverlay'

export function OctupBaseModal({
    title,
    onDismissModal,
    handleSubmit,
    customBackdropOpacity,
    isCloseable = true,
    disableBackdropClose = false,
    customStyle,
    ...props
}) {
    return (
        <>
            {ReactDOM.createPortal(
                <OctupBackdrop
                    customStyle={customStyle}
                    customBackdropOpacity={customBackdropOpacity}
                    onDismissModal={onDismissModal}
                    isCloseable={isCloseable && !disableBackdropClose}
                ></OctupBackdrop>,
                document.getElementById('octup-backdrop-root')
            )}
            {ReactDOM.createPortal(
                <OctupModalOverlay
                    customStyle={customStyle}
                    onDismissModal={onDismissModal}
                    headerTitle={title}
                    submitHandler={handleSubmit}
                    isCloseable={isCloseable}
                    disableBackdropClose={disableBackdropClose}
                    {...props}
                ></OctupModalOverlay>,
                document.getElementById('octup-overlay-root')
            )}
        </>
    )
}
