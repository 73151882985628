import { useMemo } from 'react'
import type { AutocompleteRenderGetTagProps } from '@mui/material'
import { Chip } from '@octup/core/ui-kit/Chip'
import type { AutocompleteOptionType, AutocompleteOptionsType } from '../models'

export type TagsPropsType = {
    value: AutocompleteOptionsType
    getTagProps: AutocompleteRenderGetTagProps
    ownerState: {
        getOptionLabel: (value: AutocompleteOptionType) => string
    }
}

export const Tags = ({ value, getTagProps, ownerState }: TagsPropsType) => {
    const tagProps = useMemo(() => getTagProps({ index: 0 }), [getTagProps])
    const label = useMemo(() => ownerState.getOptionLabel(value[0]), [ownerState, value])
    const countLabel = useMemo(() => (value.length > 1 ? `+${value.length - 1}` : ''), [value])

    return (
        <>
            <Chip {...tagProps} label={label} />
            {countLabel}
        </>
    )
}
