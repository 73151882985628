import { useMemo } from 'react'
import type { TextFieldPropsType } from '@octup/core/ui-kit'
import { TextField, CircularProgress } from '@octup/core/ui-kit'

export type InputPropsType = TextFieldPropsType & {
    loading?: boolean
}

export const Input = ({ loading, InputProps: CustomInputProps, ...props }: InputPropsType) => {
    const InputProps = useMemo(
        () => ({
            ...CustomInputProps,
            endAdornment: (
                <>
                    {loading && <CircularProgress color="inherit" size={14} />}
                    {CustomInputProps?.endAdornment}
                </>
            ),
        }),
        [CustomInputProps, loading]
    )

    return <TextField {...props} InputProps={InputProps} />
}
