import React from 'react'
import { getIconColor } from './utils'
import { ComingSoonLogoSvg } from './ComingSoonLogoSvg'

export function CustomersLogoSvg({ active, customActiveColor, customColor, disabled }) {
    return (
        <svg
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.38544 14.8269C10.9323 14.8269 12.9969 12.8514 12.9969 10.4144C12.9969 7.97748 10.9323 6.00195 8.38544 6.00195C5.8386 6.00195 3.77398 7.97748 3.77398 10.4144C3.77398 12.8514 5.8386 14.8269 8.38544 14.8269Z"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
            <path
                d="M14.3636 6.16537C14.9979 5.99438 15.6631 5.95543 16.3144 6.05114C16.9658 6.14685 17.5881 6.375 18.1395 6.72023C18.6909 7.06545 19.1586 7.51974 19.5111 8.05248C19.8636 8.58523 20.0927 9.18406 20.1829 9.80865C20.2731 10.4332 20.2224 11.0691 20.0342 11.6733C19.846 12.2776 19.5247 12.8363 19.0918 13.3117C18.659 13.7871 18.1247 14.1683 17.5249 14.4295C16.9252 14.6907 16.2739 14.8259 15.6149 14.8259"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 18C2.72022 17.0197 3.67635 16.2197 4.78767 15.6674C5.89899 15.1151 7.13288 14.8267 8.38516 14.8267C9.63745 14.8266 10.8714 15.1149 11.9827 15.6671C13.0941 16.2193 14.0503 17.0193 14.7706 17.9995"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.6149 14.8267C16.8673 14.8258 18.1014 15.1137 19.2129 15.666C20.3244 16.2183 21.2804 17.0187 22 17.9995"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
