import React from 'react'
import { getIconColor } from './utils'

export function PaidSvg({ active, customActiveColor = '#007F82', customColor = '#007F82' }) {
    return (
        <svg
            width="65"
            height="20"
            viewBox="0 0 65 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="65" height="20" rx="10" fill="#04CE72" fillOpacity="0.08" />
            <path
                d="M17.8502 14.6667H11.0656C10.7534 14.6667 10.5002 14.4055 10.5002 14.0833V4.75001C10.5002 4.42784 10.7534 4.16667 11.0656 4.16667H24.6349C24.9471 4.16667 25.2002 4.42784 25.2002 4.75001V9.41667"
                stroke="#078344"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.75 11.8666H15.05"
                stroke="#078344"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.9492 11.8666H18.1992"
                stroke="#078344"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5 7.35825H24.65"
                stroke="#078344"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.1912 11.9583L22.5245 15.9583L20.6912 13.9584"
                stroke="#078344"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M37.78 9.18C37.78 9.55333 37.69 9.90333 37.51 10.23C37.3367 10.5567 37.06 10.82 36.68 11.02C36.3067 11.22 35.8333 11.32 35.26 11.32H34.09V14H32.69V7.02H35.26C35.8 7.02 36.26 7.11333 36.64 7.3C37.02 7.48667 37.3033 7.74333 37.49 8.07C37.6833 8.39667 37.78 8.76667 37.78 9.18ZM35.2 10.19C35.5867 10.19 35.8733 10.1033 36.06 9.93C36.2467 9.75 36.34 9.5 36.34 9.18C36.34 8.5 35.96 8.16 35.2 8.16H34.09V10.19H35.2ZM43.554 12.67H40.774L40.314 14H38.844L41.354 7.01H42.984L45.494 14H44.014L43.554 12.67ZM43.174 11.55L42.164 8.63L41.154 11.55H43.174ZM48.3322 7.02V14H46.9322V7.02H48.3322ZM52.6554 7.02C53.3887 7.02 54.0321 7.16333 54.5854 7.45C55.1454 7.73667 55.5754 8.14667 55.8754 8.68C56.1821 9.20667 56.3354 9.82 56.3354 10.52C56.3354 11.22 56.1821 11.8333 55.8754 12.36C55.5754 12.88 55.1454 13.2833 54.5854 13.57C54.0321 13.8567 53.3887 14 52.6554 14H50.2154V7.02H52.6554ZM52.6054 12.81C53.3387 12.81 53.9054 12.61 54.3054 12.21C54.7054 11.81 54.9054 11.2467 54.9054 10.52C54.9054 9.79333 54.7054 9.22667 54.3054 8.82C53.9054 8.40667 53.3387 8.2 52.6054 8.2H51.6154V12.81H52.6054Z"
                fill="#078344"
            />
        </svg>
    )
}
