export function TikTokIcon({ size = '2.4rem' }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.1094 10.0781C10.0013 9.92519 8.87299 10.1147 7.87572 10.6213C6.87845 11.1279 6.05992 11.9273 5.52991 12.9123C4.9999 13.8973 4.78376 15.0209 4.91046 16.1322C5.03717 17.2436 5.50066 18.2896 6.23879 19.1301C6.97692 19.9705 7.95438 20.5652 9.04009 20.8343C10.1258 21.1034 11.2678 21.0341 12.313 20.6357C13.3582 20.2372 14.2566 19.5288 14.8877 18.6052C15.5188 17.6817 15.8524 16.5873 15.8438 15.4688V9.46875C17.0465 10.3292 18.4899 10.7884 19.9688 10.7812V7.82812C18.8747 7.82812 17.8255 7.39353 17.0519 6.61994C16.2783 5.84635 15.8438 4.79714 15.8438 3.70312H12.8438V15.5156C12.8463 16.0513 12.6788 16.574 12.3653 17.0085C12.0519 17.443 11.6087 17.7668 11.0996 17.9333C10.5904 18.0999 10.0415 18.1006 9.53192 17.9354C9.02232 17.7702 8.57827 17.4476 8.26368 17.014C7.94909 16.5804 7.78019 16.0581 7.78128 15.5224C7.78238 14.9867 7.95341 14.4651 8.26976 14.0328C8.58611 13.6005 9.03148 13.2796 9.54175 13.1165C10.052 12.9534 10.6009 12.9564 11.1094 13.125V10.0781Z"
                fill="#FF0055"
            />
            <path
                d="M10.2656 9.375C9.15757 9.22207 8.02924 9.4116 7.03197 9.91819C6.0347 10.4248 5.21617 11.2242 4.68616 12.2092C4.15615 13.1942 3.94001 14.3178 4.06671 15.4291C4.19342 16.5405 4.65691 17.5865 5.39504 18.427C6.13317 19.2674 7.11063 19.8621 8.19634 20.1312C9.28204 20.4003 10.4241 20.331 11.4693 19.9325C12.5145 19.5341 13.4129 18.8256 14.0439 17.9021C14.675 16.9786 15.0086 15.8842 15 14.7656V8.76562C16.2028 9.62603 17.6462 10.0853 19.125 10.0781V7.125C18.031 7.125 16.9818 6.6904 16.2082 5.91682C15.4346 5.14323 15 4.09402 15 3H12V14.8125C12.0025 15.3482 11.835 15.8709 11.5216 16.3054C11.2082 16.7398 10.765 17.0636 10.2558 17.2302C9.74666 17.3968 9.19778 17.3975 8.68817 17.2323C8.17857 17.0671 7.73452 16.7445 7.41993 16.3108C7.10534 15.8772 6.93644 15.355 6.93753 14.8193C6.93863 14.2836 7.10966 13.762 7.42601 13.3297C7.74236 12.8973 8.18773 12.5765 8.698 12.4134C9.20827 12.2503 9.75715 12.2532 10.2656 12.4219V9.375Z"
                fill="#00EEEE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2656 10.0274C9.43792 10.0416 8.6203 10.2431 7.87572 10.6213C6.87845 11.1279 6.05992 11.9273 5.52991 12.9123C4.9999 13.8973 4.78376 15.0209 4.91046 16.1322C5.03717 17.2436 5.50066 18.2896 6.23879 19.1301C6.30538 19.2059 6.37391 19.2797 6.44429 19.3515C6.97502 19.7102 7.56752 19.9753 8.19634 20.1312C9.28204 20.4003 10.4241 20.331 11.4693 19.9325C12.5145 19.5341 13.4129 18.8256 14.0439 17.9021C14.675 16.9786 15.0086 15.8842 15 14.7656V8.76562C16.2028 9.62603 17.6462 10.0853 19.125 10.0781V7.74093C18.3456 7.57804 17.6235 7.19152 17.0519 6.61994C17.0102 6.57818 16.9694 6.53563 16.9296 6.4923C16.6704 6.32935 16.4282 6.13679 16.2082 5.91682C15.6024 5.311 15.2045 4.53615 15.0603 3.70312H12.8438V15.5156C12.8463 16.0513 12.6788 16.574 12.3653 17.0085C12.0519 17.443 11.6087 17.7668 11.0996 17.9333C10.5904 18.0999 10.0415 18.1006 9.53192 17.9354C9.04621 17.778 8.62006 17.4775 8.30878 17.0742C7.95855 16.8937 7.65354 16.6329 7.41993 16.3108C7.10534 15.8772 6.93644 15.355 6.93753 14.8193C6.93863 14.2836 7.10966 13.762 7.42601 13.3297C7.74236 12.8973 8.18773 12.5765 8.698 12.4134C9.20827 12.2503 9.75715 12.2532 10.2656 12.4219V10.0274Z"
                fill="#504B5A"
            />
        </svg>
    )
}
