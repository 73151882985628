import React from 'react'

export function EnlargeGraph({ active, customActiveColor, customColor }) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M19.5 15.75V19.5H15.75" fill="#F8F7FB" />
            <path
                d="M19.5 15.75V19.5H15.75"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.5 8.25V4.5H8.25"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5084 8.4486C18.8129 8.72937 18.8322 9.20385 18.5514 9.50838L15.5939 12.7162C14.6072 13.7864 12.9574 13.9023 11.8308 12.9806L11.3254 12.5671C10.7869 12.1265 9.99241 12.2104 9.55779 12.7536L6.58566 16.4685C6.3269 16.792 5.85492 16.8444 5.53149 16.5856C5.20805 16.3269 5.15562 15.8549 5.41439 15.5315L8.38652 11.8166C9.34267 10.6215 11.0906 10.437 12.2752 11.4061L12.7806 11.8196C13.2927 12.2386 14.0426 12.1859 14.4911 11.6994L17.4486 8.49162C17.7294 8.18709 18.2039 8.16783 18.5084 8.4486Z"
                fill="#007F82"
            />
        </svg>
    )
}
