import { OctupColors } from '../../theme/colors/OctupColors'
import { getInputDate } from '../../utils/dates-util'
import {
    VALUE_TYPE_INDEX,
    getFormattedVal,
    getChangePercentageValue,
    getValueTypeIndex,
} from '../../utils/metrics-utils'

const FONT_FAMILY = 'Poppins'
export const LEGEND_CONTAINER_ID = 'legend-container'

export const DEFAULT_COLORS = [
    '#00A8A8',
    '#7737FF',
    '#FF3489',
    '#504B5A',
    '#C4821F',
    '#0098D4',
    '#899800',
    '#C963F1',
    '#078344',
    '#A4A0AA',
]

export const DEFAULT_BACKGROUND_COLORS = [
    'rgba(0, 168, 168, 0.16)',
    'rgba(119, 55, 255, 0.16)',
    'rgba(255, 52, 137, 0.16)',
    'rgba(80, 75, 90, 0.16)',
    'rgba(0, 152, 212, 0.16)',
    'rgba(164, 112, 33, 0.16)',
    'rgba(185, 0, 117, 0.16)',
    'rgba(137, 152, 0, 0.16)',
    'rgba(201, 99, 241, 0.16)',
    'rgba(227, 110, 0, 0.16)',
    'rgba(0, 76, 189, 0.16)',
    'rgba(147, 141, 160, 0.16)',
]

export const getValueLabel = ({
    value,
    currency,
    isShopCurrency,
    valueType = VALUE_TYPE_INDEX.ABSOLUTE,
    ...props
}) => {
    const isPercentage = getValueTypeIndex(valueType) === VALUE_TYPE_INDEX.PERCENTAGE

    return getFormattedVal({
        val: value,
        valueType,
        customNumOfDigits: isPercentage || !value ? 0 : null,
        currency,
        isSupported: true,
        multiplyBy100: isPercentage,
        showSymbol: !!currency || isShopCurrency,
        isShopCurrency,
        ...props,
    })
}

export const skipped = (ctx, value) => (ctx.p0.skip || ctx.p1.skip ? value : undefined)

export const DEFAULT_STYLES = {
    usePointStyle: true,
    legends: {
        fontSize: 10.5,
        paddingBottom: 2,
    },
}

const onLegendClickDefault = (event, legendItem) => {
    const index = legendItem.datasetIndex
    const chart = event.chart
    const meta = chart.getDatasetMeta(index)
    meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null
    chart.update()
}

export const octupChartsOptions = ({
    id,
    showLegend,
    showYAxis,
    showSecondYAxis,
    secondYAxisConfig,
    yTitle,
    y1Title,
    showLabels = true,
    currency = '',
    isShopCurrency,
    valueUnits,
    timeSlice,
    showSeriesNameInTooltip = true,
    getTooltipTitle,
    getTooltipLabel,
    generateLegendsLabels,
    onLegendClick = onLegendClickDefault,
    sx = DEFAULT_STYLES,
}) => {
    return {
        devicePixelRatio: window.devicePixelRatio + 1,
        maintainAspectRatio: false,
        responsive: true,
        pointRadius: 0,
        hover: {
            intersect: false,
        },
        clip: false,
        layout: {
            padding: { left: 5, right: 5, top: 5, bottom: 5 },
        },
        plugins: {
            // DOCS: https://www.chartjs.org/docs/latest/configuration/tooltip.html
            tooltip: {
                position: 'nearest',
                usePointStyle: sx.usePointStyle,
                callbacks: {
                    ...(!sx.usePointStyle && {
                        labelColor: function (ctx) {
                            return {
                                ...ctx.dataset,
                                backgroundColor: 'transparent',
                                ...(ctx.dataset.borderDash?.length && { borderDash: [3, 3] }),
                            }
                        },
                    }),
                    labelPointStyle: function () {
                        return {
                            pointStyle: sx.usePointStyle,
                        }
                    },
                    title: function (tooltipItem) {
                        return (
                            getTooltipTitle?.({ tooltipItem, timeSlice }) ||
                            getInputDate(tooltipItem[0]?.label, timeSlice)
                        )
                    },
                    label: function (tooltipItem) {
                        const getTooltipValueLabel = ({ value, ...params }) =>
                            getValueLabel({
                                value,
                                valueType: params.valueUnits || valueUnits,
                                currency: params.currency || currency,
                                isShopCurrency: params.isShopCurrency || isShopCurrency,
                            })

                        const { raw, dataIndex, dataset } = tooltipItem
                        const valueFormatted = getTooltipValueLabel({ value: raw, ...dataset })

                        const tooltipLabel =
                            showSeriesNameInTooltip &&
                            ((!dataset.hideTooltipLabel && dataset.tooltipLabels?.[dataIndex]) ||
                                dataset.label)

                        const defaultLabel =
                            `${tooltipLabel ? tooltipLabel + ':' : ''} ` + valueFormatted

                        if (getTooltipLabel) {
                            const customLabel = getTooltipLabel?.({
                                defaultLabel,
                                tooltipItem,
                                getValueLabel: getTooltipValueLabel,
                                hideTooltipLabel: dataset.hideTooltipLabel,
                            })

                            return customLabel
                        }

                        return defaultLabel
                    },
                    footer: function (tooltipFooterItem) {
                        if (tooltipFooterItem.length > 1) {
                            return
                        }

                        const [
                            {
                                raw,
                                dataIndex,
                                dataset: { data },
                            },
                        ] = tooltipFooterItem

                        const changePercentageData = getChangePercentageValue(
                            raw,
                            data[dataIndex - 1],
                            true,
                            true,
                            true
                        )

                        return changePercentageData
                    },
                },
                mode: 'index',
                labelPointStyle: 'circle',
                intersect: false,
                backgroundColor: 'white',
                titleColor: OctupColors.primaryTextDark,
                caretSize: 6,
                titleFont: {
                    family: FONT_FAMILY,
                    color: '#7F7B87',
                    weight: 400,
                    size: 12,
                },
                bodyFont: {
                    family: FONT_FAMILY,
                    weight: 500,
                    size: 12,
                },
                borderColor: 'black',
                footerFont: {
                    size: 12,
                    weight: 300,
                    family: FONT_FAMILY,
                    align: 'center',
                },
                // box - point styles
                boxPadding: 2,
                ...(sx.usePointStyle
                    ? { boxWidth: 10, boxHeight: 10 }
                    : { boxWidth: 17, boxHeight: 0 }),

                footerColor: function (ctx) {
                    function getColorFromNumber(str) {
                        if (str.startsWith('-')) {
                            return OctupColors.alertLight
                        }

                        if (str.startsWith('+')) {
                            return OctupColors.successLight
                        }

                        return 'gray'
                    }

                    return getColorFromNumber(ctx?.tooltip?.footer[0])
                },
                bodyColor: 'black',
            },
            hover: {
                mode: 'nearest',
                intersect: true,
            },
            //  DOCS: https://www.chartjs.org/docs/latest/configuration/legend.html#legend-label-configuration
            htmlLegend: {
                containerID: `${LEGEND_CONTAINER_ID}-${id}`,
            },
            legend: {
                position: 'top',
                align: sx.legends.align || 'start',
                display: showLegend,
                onHover: function (event, chartElement) {
                    const target = event.native ? event.native.target : event.target
                    target.style.cursor = chartElement[0] ? 'default' : 'pointer'
                },

                onLeave: function (event, chartElement) {
                    const target = event.native ? event.native.target : event.target
                    target.style.cursor = chartElement[0] ? 'pointer' : 'default'
                },
                onClick: onLegendClick,
                // DOCS: https://www.chartjs.org/docs/latest/configuration/legend.html#legend-label-configuration
                labels: {
                    font: {
                        size: sx.legends.fontSize,
                        family: FONT_FAMILY,
                    },

                    generateLabels: generateLegendsLabels,
                    color: OctupColors.primaryTextLight,
                    usePointStyle: true,
                    boxHeight: 7,
                },
            },
        },
        scales: {
            x: {
                display: showLabels,
                grid: {
                    display: false,
                    borderColor: 'rgba(241, 240, 245, 1)',
                },
                ticks: {
                    display: true,
                    color: OctupColors.textOverDarkBg,
                    maxTicksLimit: 4,
                    // LABELS TILT HERE
                    maxRotation: 0,
                    minRotation: 0,
                    font: {
                        size: 10,
                        family: FONT_FAMILY,
                    },
                },
            },
            y: {
                display: showYAxis,
                title: {
                    display: !!yTitle,
                    text: yTitle,
                    color: OctupColors.textOverDarkBg,
                },
                grid: {
                    display: true,
                    borderColor: 'rgba(241, 240, 245, 1)',
                    color: 'rgba(241, 240, 245, 1)',
                },
                ticks: {
                    font: {
                        size: 10,
                        family: FONT_FAMILY,
                    },
                    color: OctupColors.textOverDarkBg,
                    suggestedMin: 0,
                    maxTicksLimit: 5,
                    callback: (value) =>
                        getValueLabel({
                            value,
                            currency,
                            customNumOfDigits: 0,
                            valueType: valueUnits,
                            isShopCurrency,
                        }),
                },
            },
            y1: {
                type: 'linear',
                display: showSecondYAxis,
                position: 'right',
                grid: {
                    display: true,
                    borderColor: 'rgba(241, 240, 245, 1)',
                },
                title: {
                    display: !!y1Title,
                    text: y1Title,
                    color: OctupColors.textOverDarkBg,
                },
                ticks: {
                    color: OctupColors.textOverDarkBg,
                    font: {
                        size: 10,
                        family: FONT_FAMILY,
                    },
                    suggestedMin: 0,
                    maxTicksLimit: 5,
                    ...(secondYAxisConfig && {
                        callback: (value) =>
                            getValueLabel({
                                value,
                                currency: secondYAxisConfig.currency,
                                customNumOfDigits: 0,
                                valueType: secondYAxisConfig.valueUnits,
                                isShopCurrency: secondYAxisConfig.isShopCurrency,
                            }),
                    }),
                },
            },
        },
    }
}
