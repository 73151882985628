import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

const initialState = {
    toasts: [],
    selectedToast: null,
}

export const TOAST_TYPE = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    INFO: 'INFO',
    UNICORN: 'UNICORN',
    UNDO: 'UNDO',
    PROMPT: 'PROMPT',
    PROCESS: 'PROCESS',
    DELETED: 'DELETED',
}

class Toast {
    id
    constructor(
        type,
        text,
        callback,
        customIcon = null,
        customButtons = null,
        customTimeout = null,
        customStyle = null
    ) {
        this.id = uuidv4()
        this.type = type
        this.customIcon = customIcon
        this.customButtons = customButtons
        this.customTimeout = customTimeout
        this.customStyle = customStyle
        this.text = text
        this.callback = callback
    }
}

const toastslice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        addToast(state, action) {
            let toast = new Toast(action.payload.type, action.payload.text, action.payload.callback)
            state.toasts = [...state.toasts, toast]
        },
        updateToast(state, action) {
            // TO DO
        },
        removeToast(state, action) {
            alert(action.payload)
            state.toasts = state.toasts.filter((el) => el.id === action.payload)
        },
        setSelectToast(state, action) {
            if (action.payload !== state.selectedToast) state.selectedToast = action.payload
            else return
        },
    },
})

export default toastslice.reducer

export const toastsActions = toastslice.actions
export const toastsState = (state) => state.toasts
export const toastsSliceReducer = toastslice.reducer
