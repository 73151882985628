import { useCallback } from 'react'
import type { NavigateProps } from 'react-router-dom'
import { Navigate, Route } from 'react-router-dom'
import { useAbilities } from '@/hooks'
import type { AppRouteType, NavigateRouteType } from '@/models/appConfig'
import { APP_ROUTES, DEFAULT_ROUTES } from '@/models/appConfig'
import { ErrorBoundaryContainer } from '@/new_containers/components/__common__'
import {
    IntegrationsPageContainer,
    AttributionPageContainer,
    UnitEconomicsPageContainer,
    LogisticsPageContainer,
    CostManagerPageContainer,
    EditDashboardPageContainer,
    DashboardPageContainer,
    CreateDashboardPageContainer,
    SignupPageContainer,
    ShopifySignupPageContainer,
    SystemPageContainer,
    SettingsPageContainer,
} from '@/new_containers/pages'
import { AccountPage } from './pages/account/AccountPage'
import { MobileAccountPage } from './pages/account/MobileAccountPage'
import HomePage from './pages/home/HomePage'
import MobileHomePage from './pages/home/mobile/MobileHomePage'
import { MobileInsightDetailsModal } from './pages/home/mobile/MobileInsightDeepDivePage'
import { MobileMetricsDetailsModal } from './pages/home/mobile/MobileMetricDeepDivePage'
import { MobileSendFeedbackPage } from './pages/home/mobile/MobileSendFeedbackPage'
import InsightsPage from './pages/insight/InsightsPage'
import LoginPage from './pages/Login/LoginPage'
import MobileLoginPage from './pages/Login/MobileLoginPage'
import MarketingPage from './pages/marketing/MarketingPage'
import { NotFoundPage } from './pages/NotFoundPage'
import ObjectivesPage from './pages/objectives/ObjectivesPage'
import OperationsOverviewPage from './pages/operations/OperationOverviewPage'
import InviteUserPage from './pages/signup/InviteUserPage'
import UserManagementPage from './pages/userManagement/UserManagementPage'

export const activeRoutes: AppRouteType[] = [
    {
        path: APP_ROUTES.LOGIN,
        element: Navigate,
        to: DEFAULT_ROUTES.MAIN,
        isNavigate: true,
        replace: true,
    },
    {
        path: APP_ROUTES.SHOPIFY_REGISTRATION,
        element: Navigate,
        to: DEFAULT_ROUTES.MAIN,
        isNavigate: true,
        replace: true,
    },
    {
        path: APP_ROUTES.HOME,
        element: HomePage,
    },
    {
        path: APP_ROUTES.WAREHOUSE,
        element: SystemPageContainer,
    },
    {
        path: APP_ROUTES.MARKETING,
        element: MarketingPage,
    },
    {
        path: APP_ROUTES.MARKETING_ATTRIBUTION,
        element: AttributionPageContainer,
    },
    {
        path: APP_ROUTES.OPERATIONS,
        element: SystemPageContainer,
    },
    {
        path: APP_ROUTES.WAREHOUSING,
        element: LogisticsPageContainer,
    },
    {
        path: APP_ROUTES.OPERATIONS_OVERVIEW,
        element: OperationsOverviewPage,
    },
    {
        path: APP_ROUTES.UNIT_ECONOMICS,
        element: UnitEconomicsPageContainer,
    },
    {
        path: APP_ROUTES.UNIT_ECONOMICS_COST_MANAGER,
        element: CostManagerPageContainer,
    },
    {
        path: APP_ROUTES.INTEGRATIONS,
        element: IntegrationsPageContainer,
    },
    {
        path: APP_ROUTES.USERS,
        element: UserManagementPage,
    },
    {
        path: APP_ROUTES.INSIGHTS,
        element: InsightsPage,
    },
    {
        path: APP_ROUTES.OBJECTIVES,
        element: ObjectivesPage,
    },
    {
        path: APP_ROUTES.ACCOUNT,
        element: AccountPage,
    },
    {
        path: APP_ROUTES.CLIENTS,
        element: SystemPageContainer,
    },
    {
        path: APP_ROUTES.CLIENTS_LOGISTICS,
        element: SystemPageContainer,
    },
    {
        path: APP_ROUTES.CLIENTS_SLA,
        element: SystemPageContainer,
    },
    {
        path: APP_ROUTES.DASHBOARD,
        element: DashboardPageContainer,
    },
    {
        path: APP_ROUTES.DASHBOARD_EDIT,
        element: EditDashboardPageContainer,
    },
    {
        path: APP_ROUTES.DASHBOARD_CREATE,
        element: CreateDashboardPageContainer,
    },
    {
        path: APP_ROUTES.SETTINGS,
        element: SettingsPageContainer,
    },
    {
        path: DEFAULT_ROUTES.ALL,
        element: NotFoundPage,
    },
]

export const inactiveRoutes: AppRouteType[] = [
    {
        path: APP_ROUTES.LOGIN,
        element: LoginPage,
    },
    {
        path: APP_ROUTES.REGISTRATION,
        element: SignupPageContainer,
    },
    {
        path: APP_ROUTES.SHOPIFY_REGISTRATION,
        element: ShopifySignupPageContainer,
    },
    {
        path: APP_ROUTES.INVITE,
        element: InviteUserPage,
    },
    {
        path: DEFAULT_ROUTES.ALL,
        element: Navigate,
        to: APP_ROUTES.LOGIN,
        isNavigate: true,
        replace: true,
    },
]

// TODO: Delete mobile routes once the mobile will look the same as the desktop
export const mobileActiveRoutes = [
    {
        path: '/*',
        exact: true,
        element: Navigate,
        to: '/Home',
        isNavigate: true,
        replace: true,
    },
    {
        path: 'Home',
        element: MobileHomePage,
    },
    {
        path: 'Account',
        element: MobileAccountPage,
    },
    {
        path: 'Feedback',
        element: MobileSendFeedbackPage,
    },
    {
        path: 'MetricDeepdive',
        element: MobileMetricsDetailsModal,
    },
    {
        path: 'InsightDeepdive',
        element: MobileInsightDetailsModal,
    },
]

export const mobileInactiveRoutes = [
    {
        path: '/*',
        exact: true,
        element: Navigate,
        to: '/Login',
        isNavigate: true,
        replace: true,
    },
    {
        path: 'Login',
        element: MobileLoginPage,
    },
]

export const useRenderRoutes = () => {
    const { isPageAvailable } = useAbilities()

    const renderRoutes = useCallback(
        (routes: AppRouteType[]) => {
            const availableRoutes = routes.filter(({ path }) => isPageAvailable(path as APP_ROUTES))
            const defaultRoute = availableRoutes.find((route) => {
                const { path } = route as AppRouteType
                const { isNavigate } = route as NavigateRouteType
                return !isNavigate && path !== DEFAULT_ROUTES.ALL
            })

            return (
                <>
                    {defaultRoute && (
                        <Route
                            path={DEFAULT_ROUTES.MAIN}
                            element={<Navigate replace to={defaultRoute.path} />}
                        />
                    )}
                    {availableRoutes.map(({ path, element: Component, ...props }) => (
                        <Route
                            key={path}
                            path={path}
                            element={
                                <ErrorBoundaryContainer key={path}>
                                    <Component {...(props as NavigateProps)} />
                                </ErrorBoundaryContainer>
                            }
                        />
                    ))}
                </>
            )
        },
        [isPageAvailable]
    )

    return renderRoutes
}
