import { useSelector } from 'react-redux'
import styled from 'styled-components'

export const Greeting = ({ firstName, fontSize }) => {
    const myDate = new Date()
    const hours = myDate.getHours()
    let greet = 'Good '
    const { userInfo } = useSelector((state) => state.auth)

    if (hours < 12) greet += 'morning'
    else if (hours >= 12 && hours <= 17) greet += 'afternoon'
    else if (hours >= 17 && hours <= 24) greet += 'evening'

    const username = firstName ? firstName : userInfo?.fullName
    if (username && username.length > 0) greet += `, ${username}!`

    return <StyledGreeting fontSize={fontSize}>{greet}</StyledGreeting>
}

const StyledGreeting = styled.div`
    color: #504b5a;
    text-align: left;
    font-weight: 500;
    font-size: ${(props) => (props.fontSize ? props.fontSize : '2rem')};
    text-transform: capitalize;
`
