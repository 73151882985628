import type { CircularProgressProps } from '@mui/material'
import { CircularProgress, Stack } from '@octup/core/ui-kit'

export type LoadingViewPropsType = CircularProgressProps

export const LoadingView = (props: LoadingViewPropsType) => (
    <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
        <CircularProgress {...props} />
    </Stack>
)
