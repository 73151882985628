export function SignupOptimized({
    active,
    customActiveColor = '#DB2059',
    customColor = '#DB2059',
}) {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.1975 17.8885C20.6704 18.8003 19.9126 19.5573 19.0004 20.0836C18.0881 20.61 17.0535 20.887 16.0003 20.887C14.9471 20.8871 13.9124 20.61 13.0002 20.0837C12.0879 19.5574 11.3301 18.8003 10.803 17.8886"
                fill="white"
            />
            <path
                d="M21.1975 17.8885C20.6704 18.8003 19.9126 19.5573 19.0004 20.0836C18.0881 20.61 17.0535 20.887 16.0003 20.887C14.9471 20.8871 13.9124 20.61 13.0002 20.0837C12.0879 19.5574 11.3301 18.8003 10.803 17.8886"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                opacity="0.3"
                d="M22.5 20C27.1944 20 31 16.1944 31 11.5C31 6.80558 27.1944 3 22.5 3C17.8056 3 14 6.80558 14 11.5C14 16.1944 17.8056 20 22.5 20Z"
                fill="#7737FF"
            />
        </svg>
    )
}
