import { useCallback } from 'react'
import { APP_ROUTES } from 'models/appConfig'
import type { DashboardType } from 'models/dashboards'
import { useNavigate } from 'react-router-dom'
import { resolveRoutePath } from 'utils'

type NavigateToDashboardType = {
    dashboard?: DashboardType | null
    dashboardsList?: DashboardType[]
    skipDashboardActiveState?: boolean
}

export const useNavigateToDashboard = () => {
    const navigate = useNavigate()

    const handleNavigateToDashboard = useCallback(
        ({
            dashboard,
            dashboardsList,
            skipDashboardActiveState = true,
        }: NavigateToDashboardType) => {
            const dashboardId = dashboard?.id

            if (!skipDashboardActiveState && !dashboard?.isNew) {
                const path = resolveRoutePath(APP_ROUTES.DASHBOARD, {
                    ':dashboardId': dashboardId!,
                })
                return navigate(`/${path}`)
            }

            const navigateDashboardId = dashboardsList?.find(({ id }) => id !== dashboardId)?.id
            const path = navigateDashboardId
                ? resolveRoutePath(APP_ROUTES.DASHBOARD, { ':dashboardId': navigateDashboardId })
                : ''

            navigate(`/${path}`)
        },
        [navigate]
    )

    return handleNavigateToDashboard
}
