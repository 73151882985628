const MAX_COSTS_NUMBER = 9

export const getLogisticsData = ({ data, otherCost }) => {
    const hasData = !!Object.keys(data).length

    if (!hasData) return { data: null }

    const totalCostsData = data.total_costs.data
        .sort((a, b) => b.value - a.value)
        .reduce((acc, cost, index) => {
            if (index < MAX_COSTS_NUMBER) return [...acc, cost]

            otherCost = {
                ...otherCost,
                costs: [...otherCost.costs, cost],
                value: otherCost.value + cost.value,
            }

            return acc
        }, [])

    if (otherCost.value) {
        totalCostsData.push(otherCost)
    }

    return {
        otherCost,
        data: { ...data, total_costs: { ...data.total_costs, data: totalCostsData } },
    }
}
