import { useSelector } from 'react-redux'
import { COMPARISON_MODES } from 'constants/metrics'
import { RadioTabsSelectors } from 'shared/ui/RadioTabsSelector/RadioTabsSelectors'
import { MetricsDropdownContainer } from 'new_containers/components/Metrics'
import { DownDropStyled } from './MetricsDetailsModal.styles'
import { useMemo } from 'react'

export function MetricsDetailsMetricSelection({
    selectedMetric,
    metricsGroup,
    deepdiveModal,
    onMetricSelection,
    setIsBreakdown,
    isBreakdown,
}) {
    const { comparisonMode } = useSelector((state) => state.deepdivesSection)
    const isMetricComparisonMode = useMemo(
        () => comparisonMode === COMPARISON_MODES.METRIC,
        [comparisonMode]
    )

    return (
        <DownDropStyled>
            <MetricsDropdownContainer
                metricsGroup={metricsGroup}
                onSelect={onMetricSelection}
                defaultValue={deepdiveModal.selectedDDMetric}
            />
            {selectedMetric?.blended_dive_in && (
                <RadioTabsSelectors
                    customStyle={{ marginLeft: '1rem', width: '85rem' }}
                    tabs={[
                        {
                            id: 1,
                            title: 'Breakdown',
                            selected: isBreakdown,
                            disabled: isMetricComparisonMode,
                            onClick: () => {
                                setIsBreakdown(true)
                            },
                            ...(isMetricComparisonMode && {
                                tooltip: 'When comparing metrics, only blended data is available',
                            }),
                        },
                        {
                            id: 2,
                            title: 'Blended',
                            selected: !isBreakdown,
                            onClick: () => {
                                setIsBreakdown(false)
                            },
                        },
                    ]}
                />
            )}
        </DownDropStyled>
    )
}
