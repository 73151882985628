export const modalContentStyle = (theme) => ({
    height: '100%',
    paddingTop: theme.spacing(2),
})

export const comparisonContainerStyle = (theme) => ({
    paddingBottom: theme.spacing(2),
})

export const tableContainerStyle = {
    overflow: 'auto',
}
