import type { SxProps, Theme } from '@mui/material'

// TODO: check how to support these props in overrides
export const cardStyle =
    (isSelected?: boolean, isDisabled?: boolean, isClickable?: boolean, sx?: SxProps<Theme>) =>
    (theme: Theme) => ({
        width: '100%',
        height: '100%',
        ...(isSelected && {
            border: `0.1rem solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.grey['50'],
            boxShadow: theme.shadows[7],
        }),
        ...(isDisabled && {
            opacity: 0.4,
        }),
        ...(isClickable && {
            cursor: 'pointer',
        }),
        ...(typeof sx === 'function' && sx(theme)),

        '& .show-on-hover': {
            opacity: 0,
        },
        '&:hover .show-on-hover': {
            opacity: 1,
            transition: '0.1s ease-in-out',
        },
    })
