import React from 'react'
import { getIconColor } from './utils'

export function MessageInABottle() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="196"
            height="160"
            viewBox="0 0 196 160"
            fill="none"
        >
            <path
                d="M59.2026 112.522L102.464 52.1988C106.103 48.1049 114.193 47.8705 118.696 48.3381C119.435 48.4149 120.186 48.3357 120.846 47.9932C129.217 43.646 137.846 30.3133 139.037 31.7428C145.29 39.2472 156.807 43.5202 155.774 45.3798C152.675 50.9587 143.8 64.2089 145.236 67.6957C149.344 77.6722 147.34 82.2782 146.063 85.1953C145.925 85.5123 145.762 85.7925 145.548 86.0649C134.064 100.727 99.3398 144.383 97.5054 144.561C79.1122 146.34 60.1516 124.836 58.5607 114.837C58.4293 114.011 58.7152 113.201 59.2026 112.522Z"
                stroke="#7F7B87"
                stroke-width="1.5"
            />
            <path
                d="M138.726 28.2461L141.663 24.4695C142.142 23.8535 143.117 24.0026 143.43 24.7175C148.355 35.9746 156.772 38.7359 161.861 38.7224C162.682 38.7203 163.256 39.5669 162.806 40.2527C161.432 42.3431 159.168 45.3798 158.253 45.3798C144.692 45.3798 139.832 33.3617 138.555 29.1214C138.464 28.8184 138.532 28.4959 138.726 28.2461Z"
                fill="#F8F7FB"
                stroke="#7F7B87"
                stroke-width="1.5"
            />
            <path
                d="M162.909 39.9806C162.753 40.1775 162.392 40.3705 161.621 40.3464C160.867 40.3228 159.872 40.0913 158.689 39.6382C156.331 38.7353 153.388 37.0159 150.441 34.6808C147.493 32.3458 145.146 29.8743 143.728 27.7852C143.016 26.7368 142.563 25.8209 142.368 25.0926C142.168 24.3473 142.273 23.9515 142.429 23.7546C142.585 23.5578 142.947 23.3647 143.718 23.3888C144.472 23.4124 145.467 23.6439 146.65 24.097C149.008 25 151.951 26.7193 154.898 29.0544C157.846 31.3895 160.192 33.8609 161.611 35.95C162.323 36.9984 162.776 37.9143 162.971 38.6426C163.171 39.3879 163.065 39.7837 162.909 39.9806Z"
                fill="#F8F7FB"
                stroke="#7F7B87"
                stroke-width="1.5"
            />
            <path
                d="M99.582 143.018C98.7641 144.05 97.4678 144.701 95.7187 144.926C93.9649 145.152 91.8068 144.941 89.3727 144.292C84.5072 142.994 78.6827 139.985 73.0813 135.547C67.4799 131.109 63.2182 126.128 60.8422 121.688C59.6536 119.467 58.9544 117.414 58.7733 115.655C58.5927 113.901 58.9298 112.49 59.7476 111.458C60.5654 110.425 61.8618 109.775 63.6109 109.549C65.3647 109.323 67.5228 109.534 69.9568 110.184C74.8223 111.481 80.6469 114.49 86.2483 118.928C91.8497 123.366 96.1113 128.348 98.4874 132.788C99.676 135.009 100.375 137.061 100.556 138.82C100.737 140.575 100.4 141.986 99.582 143.018Z"
                stroke="#7F7B87"
                stroke-width="1.5"
            />
            <path
                d="M159.209 32.3157L169.49 22.6478C169.928 22.2359 169.906 21.5334 169.443 21.1496L158.603 12.1595C158.229 11.849 157.684 11.8512 157.361 12.2143C154.489 15.4365 150.478 24.6756 150.479 25.4844C150.479 30.3042 156.317 33.6042 158.971 32.4762C159.06 32.4385 159.139 32.3818 159.209 32.3157Z"
                fill="#F8F7FB"
                stroke="#7F7B87"
                stroke-width="1.5"
            />
            <path
                d="M170.437 22.3156C170.315 22.4696 170.071 22.6181 169.592 22.6547C169.112 22.6914 168.475 22.606 167.71 22.3715C166.183 21.9041 164.3 20.8916 162.448 19.4243C160.596 17.957 159.18 16.3552 158.375 14.9762C157.972 14.2845 157.743 13.6838 157.669 13.2083C157.595 12.7339 157.684 12.4626 157.806 12.3086C157.928 12.1546 158.172 12.0061 158.651 11.9695C159.13 11.9328 159.768 12.0182 160.533 12.2527C162.06 12.7201 163.943 13.7326 165.795 15.1999C167.647 16.6672 169.063 18.269 169.867 19.648C170.271 20.3397 170.5 20.9404 170.574 21.4159C170.647 21.8903 170.559 22.1615 170.437 22.3156Z"
                fill="#F8F7FB"
                stroke="#7F7B87"
                stroke-width="1.5"
            />
            <path
                opacity="0.3"
                d="M159.209 27.7443L169.791 17.7932C170.104 17.499 170.194 17.038 170.015 16.6477L169.805 16.1905C167.763 11.7396 163.796 8.46883 159.037 7.31359L158.045 7.0727C157.985 7.05823 157.925 7.04832 157.863 7.04889C154.315 7.08213 150.478 20.0541 150.479 20.913C150.479 25.7328 156.317 29.0329 158.971 27.9049C159.06 27.8671 159.139 27.8105 159.209 27.7443Z"
                fill="#C4821F"
            />
            <path
                d="M101.225 54.0581C103.911 63.5629 113.002 76.9936 136.558 84.4321"
                stroke="#7F7B87"
                stroke-width="1.5"
            />
            <path
                d="M110.736 84.1346L76.0251 94.7316C74.9687 95.0541 74.3738 96.172 74.6963 97.2284L82.3973 122.453C82.7198 123.509 83.8377 124.104 84.8941 123.782L119.605 113.185C120.661 112.862 121.256 111.745 120.933 110.688L113.232 85.4635C112.91 84.407 111.792 83.8121 110.736 84.1346Z"
                fill="#F8F7FB"
                stroke="#7F7B87"
                stroke-width="1.5"
            />
            <path
                d="M75.6055 95.7772L96.3098 104.327C97.1748 104.684 98.1718 104.394 98.7097 103.628L112.471 84.0395"
                stroke="#7F7B87"
                stroke-width="1.5"
            />
            <path
                d="M58.3809 9.36853C61.4285 10.9282 62.5713 14.2028 62.5713 21.2294C62.5713 23.4052 67.5237 14.0474 75.1428 15.3739"
                stroke="#7F7B87"
                stroke-linecap="round"
            />
            <path
                d="M73.4668 32.8246C75.582 33.0808 76.928 34.7244 78.3722 38.767C78.8194 40.0189 79.8123 33.5932 84.5716 32.7537"
                stroke="#7F7B87"
                stroke-linecap="round"
            />
            <path
                d="M29.0752 22.3151C30.2517 21.9876 31.3246 22.5635 32.9748 24.3887C33.4859 24.954 32.6048 21.3328 34.942 19.8459"
                stroke="#7F7B87"
                stroke-linecap="round"
            />
            <path
                d="M58.6071 103.29C39.4706 95.2763 24.9532 106.963 15.7148 112.807C50.6885 106.839 40.1304 113.308 63.8861 116.814C87.6419 120.32 118.656 104.793 102.819 108.299C86.982 111.805 74.7952 110.07 58.6071 103.29Z"
                stroke="#DDDBE1"
            />
            <path
                d="M124.117 130.497C116.331 134.549 107.739 128.133 103.98 125.179C119.016 129.488 117.673 125.432 127.339 123.659C137.004 121.886 148.549 129.737 142.105 127.964C135.662 126.192 130.703 127.069 124.117 130.497Z"
                stroke="#DDDBE1"
            />
            <path
                opacity="0.3"
                d="M115.524 84.5714L102.12 103.693"
                stroke="#FF3489"
                stroke-width="4"
                stroke-linecap="round"
            />
            <path
                opacity="0.3"
                d="M142.953 38.8571L138.382 30.4762C132.794 37.8413 120.705 51.8095 117.048 48.7619C113.391 45.7143 104.858 50.5397 101.048 52.5714C84.7944 78.7302 49.4762 123.933 58.3817 114.286C67.5245 104.381 116.286 57.1429 123.144 57.1429C128.629 57.1429 138.636 44.9524 142.953 38.8571Z"
                fill="#00A8A8"
            />
        </svg>
    )
}
