import styled from 'styled-components'
import { OctupColors } from '../../theme/colors/OctupColors'

export const CardBase = ({ children, isClickable = false, height = '90%', style }) => {
    return (
        <CardWrapper height={height} isClickable={isClickable} style={{ ...style }}>
            {children}
        </CardWrapper>
    )
}

const CardWrapper = styled.div`
    z-index: 2;
    background-color: ${OctupColors.headerIcons};
    box-shadow:
        -0.4rem -0.4rem 0.8rem rgba(255, 255, 255, 0.75),
        0.8rem 0.8rem 2.4rem rgba(80, 75, 90, 0.08);
    border-radius: 1.6rem;
    width: 100%;
    height: ${(props) => props.height};
    min-height: fit-content;
    cursor: ${(props) => props.isClickable && 'pointer'};
`
