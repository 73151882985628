import { Box, Stack, OverflowTypography } from '@octup/core/ui-kit'
import {
    CopyToClipboardButtonContainer,
    InvoicesRequestTextContainer,
} from 'new_containers/components/__common__'
import { emailStyle, smallSizeStyle, textButtonStyle } from './InvoiceRequest.styles'

const COPY_BUTTON_SMALL_SIZE = '2.5rem'
const PROPS_BY_SIZE = {
    small: {
        container: { sx: smallSizeStyle },
        copyButton: {
            customStyle: {
                minWidth: COPY_BUTTON_SMALL_SIZE,
                minHeight: COPY_BUTTON_SMALL_SIZE,
                width: COPY_BUTTON_SMALL_SIZE,
                height: COPY_BUTTON_SMALL_SIZE,
            },
        },
    },
}

export const InvoicesRequest = ({
    children,
    copyButtonProps,
    emailProps,
    email,
    showOverflowedTooltip,
    primaryText,
    showEmail,
    secondaryText,
    size = 'medium',
    fallback,
    ...props
}) => {
    const propsBySize = PROPS_BY_SIZE[size]

    if (!email) return fallback

    const copyButton = (
        <CopyToClipboardButtonContainer
            content={email}
            {...propsBySize?.copyButton}
            {...copyButtonProps}
        />
    )

    return (
        <>
            {children}
            <Stack width="100%" {...propsBySize?.container} {...props}>
                {primaryText && (
                    <InvoicesRequestTextContainer
                        email={email}
                        primaryText={primaryText}
                        secondaryText={secondaryText}
                        hasPeriod={!showEmail}
                    >
                        {!showEmail ? <Box sx={textButtonStyle}>{copyButton}</Box> : ':'}
                    </InvoicesRequestTextContainer>
                )}
                {showEmail && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        wrap="nowrap"
                        spacing="1rem"
                        fontSize="inherit"
                        justifyContent="inherit"
                    >
                        <OverflowTypography
                            showTooltip={showOverflowedTooltip}
                            sx={emailStyle}
                            {...emailProps}
                        >
                            {email}
                        </OverflowTypography>
                        {copyButton}
                    </Stack>
                )}
            </Stack>
        </>
    )
}
