import { OctupAvatar } from '../Avatar/OctupAvatar'
import { OctupIcons } from '../../assets/OctupIcons'
import styled from 'styled-components'
import AllItems from '../../assets/images/AllItems.svg'
import AllUsers from '../../assets/images/AllUsers.svg'

const { CheckmarkSvg, NoImageSvg } = OctupIcons
const DropdownLine = ({
    imgObj,
    isAvatarDropdown,
    isSelectAllOption,
    isSelected,
    isMultiSelection,
    onItemSelection,
    showOptionsImages,
    customLabelPostfix,
    customLabelPrefix,
    id,
    count,
    name,
    initials,
    showCounter,
    ...props
}) => {
    return (
        <DropdownLineContainer
            isSelected={isSelected}
            showOptionsImages={showOptionsImages}
            isAvatarDropdown={isAvatarDropdown}
        >
            {imgObj &&
                showOptionsImages &&
                (!isAvatarDropdown ? (
                    <ImgContainer>
                        {isSelectAllOption || (imgObj.src !== '' && imgObj.src !== null) ? (
                            <img
                                width={'fit-content'}
                                height={'100%'}
                                src={isSelectAllOption ? AllItems : imgObj.src && imgObj.src}
                                alt={isSelectAllOption ? 'all item icon' : imgObj.alt}
                            />
                        ) : (
                            <NoImageSvg customColor="#504B5A" />
                        )}
                    </ImgContainer>
                ) : (
                    isAvatarDropdown && (
                        <AvatarContainer>
                            <OctupAvatar
                                imageSrc={isSelectAllOption ? AllUsers : imgObj.src && imgObj.src}
                                customSize={'4rem'}
                                userInitials={initials}
                                title={name}
                                size={'small'}
                            />
                        </AvatarContainer>
                    )
                ))}
            <ItemContainerStyled>
                <TitleStyled isSelected={isSelected}>
                    <div>{!!customLabelPrefix && customLabelPrefix}</div>
                    {name ? name : 'empty'}
                    <div>{!!customLabelPostfix && customLabelPostfix}</div>
                    {showCounter && <FilterCounter>{count && ` (${count})`}</FilterCounter>}
                </TitleStyled>
                <SingleSelectionCheckmark>
                    {!isMultiSelection && isSelected && <CheckmarkSvg active={true} />}
                </SingleSelectionCheckmark>
            </ItemContainerStyled>
        </DropdownLineContainer>
    )
}

const DropdownLineContainer = styled.div`
    white-space: nowrap;
    width: 100%;
    min-width: fit-content;
    /* border-left: ${(props) =>
        props.isSelected ? '0.5rem solid #007f82' : '0.5rem solid transparent'}; */
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    padding: ${(props) =>
        !(props.showOptionsImages && props.isAvatarDropdown) ? '1.3rem' : '1rem'};
    line-height: 1.3;
    height: 4rem;
    background: ${(props) =>
        props.isSelected ? 'rgba(0,127,130,.04)' : '0.5rem solid transparent'};
    z-index: 999;
`

const FilterCounter = styled.span`
    color: #999;
    font-size: 1.2rem;
    line-height: 1.3;
    height: 4rem;
`

const ImgContainer = styled.div`
    overflow: hidden;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.6rem;
    margin-right: 0.5rem;
    /* background-color: inherit; */
    background: #dddbe1;
    padding: 0.1rem;
`

const AvatarContainer = styled.div`
    margin-right: 0.6rem;
`

const SingleSelectionCheckmark = styled.div`
    min-width: 4rem;
    margin-left: 1rem;
`

const TitleStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    font-size: ${(props) =>
        !(props.showOptionsImages && props.isAvatarDropdown) ? '1.3rem' : '1.2rem'};
    font-weight: ${(props) => (props.isSelected ? '600' : '400')};
    /* color: ${(props) => (props.isSelected ? '#007f82' : 'inherit')}; */
    // background-color:  ${(props) => (props.isSelected ? 'blue' : 'inherit')};
    & div {
        font-size: inherit;
        font-weight: inherit;
    }
`

const ItemContainerStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

export default DropdownLine
