import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { extractErrMsg } from '../../shared/utils/utils'
import { notificationsService } from './notificationService'
import { GLOBAL_CONFIG } from '../../config'

const API_URL = `${GLOBAL_CONFIG.BASE_URL}/admin/notification`

export const NOTIFICATION_TAGS = {
    OBJECTIVES: 'objective',
    INSIGHTS: 'insights',
    MGMT: 'managment',
    OCTUPNEWS: 'octup news',
    AUTOMATION: 'automation',
}

export const NOTIFICATION_TYPES = {
    1: NOTIFICATION_TAGS.OBJECTIVES,
    2: NOTIFICATION_TAGS.INSIGHTS,
    3: NOTIFICATION_TAGS.MGMT,
    4: NOTIFICATION_TAGS.OCTUPNEWS,
    5: NOTIFICATION_TAGS.AUTOMATION,
}

export const NOTIFICATION_BADGE_COLORS = {
    [NOTIFICATION_TAGS.OBJECTIVES]: 'blue',
    [NOTIFICATION_TAGS.INSIGHTS]: 'indigo',
    [NOTIFICATION_TAGS.MGMT]: 'darkgray',
    [NOTIFICATION_TAGS.OCTUPNEWS]: 'teal',
    [NOTIFICATION_TAGS.AUTOMATION]: 'orange',
}

export const NOTIFICATION_STATUS = {
    1: 'new',
    2: 'unread',
    3: 'read',
}

const initialState = {
    notifications: {
        metadata: {},
        data: [],
    },
    isLoading: false,
    isRejected: false,
}
export const getAllNotifications = createAsyncThunk(`${API_URL}`, async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.userInfo.token
        return await notificationsService.getAllNotifications(token)
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

export const getNotificationsByType = createAsyncThunk(
    `${API_URL}/all/type`,
    async (notificationType, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.userInfo.token
            return await notificationsService.getNotificationsByType(token, notificationType)
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

export const updateNotificationsStatus = createAsyncThunk(
    `${API_URL}/updateNotificationsStatus`,
    async (notificationsIdsArray, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.userInfo.token
            return await notificationsService.updateNotificationsStatusToUnread(
                token,
                notificationsIdsArray
            )
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllNotifications.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllNotifications.rejected, (state) => {
                state.isLoading = false
                state.isRejected = true
            })
            .addCase(getAllNotifications.fulfilled, (state, action) => {
                state.notifications = action.payload
                state.isLoading = false
                state.isRejected = false
            })
            .addCase(updateNotificationsStatus.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateNotificationsStatus.rejected, (state) => {
                state.isLoading = false
                state.isRejected = true
            })
            .addCase(updateNotificationsStatus.fulfilled, (state, action) => {
                state.isLoading = false
            })
    },
})

export const notificationsActions = notificationsSlice.actions
export const notificationsState = (state) => state.notifications
export const notificationsSliceReducer = notificationsSlice.reducer
