import {
    NavContentContainer,
    WhatNewNavContainerStyled,
    WhatNewNavInnerContainer,
} from './WhatsNewNav.styles'
import { WhatsNewNavContentItems } from '../WhatsNewNavContentItems/WhatsNewNavContentItems'
import { PastUpdateToggleBtn } from '../PastUpdateToggleBtn'

export const WhatsNewNav = ({
    slidesData,
    showPastUpdates,
    handleShowPastUpdatesClick,
    handleShowPastUpdatesChange,
}) => {
    return (
        <WhatNewNavContainerStyled>
            <WhatNewNavInnerContainer>
                <NavContentContainer>
                    <WhatsNewNavContentItems
                        showPastUpdates={showPastUpdates}
                        slidesData={slidesData}
                    />
                </NavContentContainer>
            </WhatNewNavInnerContainer>
            <PastUpdateToggleBtn
                showPastUpdates={showPastUpdates}
                handleShowPastUpdatesClick={handleShowPastUpdatesClick}
                handleShowPastUpdatesChange={handleShowPastUpdatesChange}
            />
        </WhatNewNavContainerStyled>
    )
}
