import { COMPARISON_MODES } from 'constants/metrics'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ModalsContext } from 'contexts/ModalsContext'
import {
    clearData,
    getBreakdownMetrics,
    getDeepdiveMetrics,
} from 'features/sections/deepdivesSectionsSlice'
import { DeepDiveModal } from 'new_components/Metrics'
import { useSelector, useDispatch } from 'react-redux'
import { DatePickerContainer } from '../DatePickerContainer'
import { useMetricsGroup } from './hooks'
import { getComparisonDates } from './ModalContainer.utils'

export const ModalContainer = () => {
    const dispatch = useDispatch()
    const { deepdiveModal, setDeepdiveModal } = useContext(ModalsContext)
    const metricsGroup = useMetricsGroup(deepdiveModal.segments?.[0], deepdiveModal.customMetrics)
    const { userInfo } = useSelector((state) => state.auth)
    const { datePicker } = useSelector((state) => state.newMetrics)
    const {
        deepDiveRawData,
        breakdownRawData,
        isLoading,
        isLoadingGetBreakdownMetrics,
        comparisonMode,
        comparisonMetric,
    } = useSelector((state) => state.deepdivesSection)
    const currentClientId = deepdiveModal.clientId || userInfo.client.id

    const [isBreakdown, setIsBreakdown] = useState(false)
    const [selectedMetricData, setSelectedMetricData] = useState(null)

    const comparisonDates = useMemo(
        () => getComparisonDates({ comparisonMode, datePicker }),
        [comparisonMode, datePicker]
    )

    const dataParams = useMemo(
        () => ({ datePickerValues: datePicker, comparisonDates, clientId: currentClientId }),
        [datePicker, comparisonDates, currentClientId]
    )

    // TODO: separate blended and breakdown requests
    useEffect(() => {
        const { deep_dive, blended_dive_in, metric_id } = selectedMetricData?.metric || {}

        if (
            !datePicker.label ||
            (comparisonMode === COMPARISON_MODES.METRIC && !comparisonMetric) ||
            +metric_id === +comparisonMetric?.metric_id
        )
            return

        if (deep_dive) {
            dispatch(getDeepdiveMetrics({ segments: [deep_dive], comparisonMetric, ...dataParams }))
        }

        if (blended_dive_in && !comparisonMetric) {
            dispatch(getBreakdownMetrics({ segments: [blended_dive_in], ...dataParams }))
        } else {
            setIsBreakdown(false)
        }
    }, [
        comparisonMetric,
        comparisonMode,
        dataParams,
        datePicker.label,
        dispatch,
        selectedMetricData?.metric,
    ])

    useEffect(() => {
        const { selectedDDMetric } = deepdiveModal
        const newData = metricsGroup.find(({ metric }) => metric?.metricTitle === selectedDDMetric)
        setSelectedMetricData(newData)
    }, [deepdiveModal, deepdiveModal.selectedDDMetric, metricsGroup])

    const onMetricSelection = useCallback(
        ({ value }) => {
            if (selectedMetricData?.metric.metricTitle !== value?.metric.metricTitle) {
                setSelectedMetricData(value)
            }
        },
        [selectedMetricData?.metric.metricTitle]
    )

    const handleClose = useCallback(() => {
        setDeepdiveModal({ show: false, segments: [], selectedDDMetric: null })
        dispatch(clearData())
    }, [dispatch, setDeepdiveModal])

    const data = useMemo(
        () => (isBreakdown ? breakdownRawData : deepDiveRawData),
        [breakdownRawData, deepDiveRawData, isBreakdown]
    )

    return (
        <DeepDiveModal
            data={data}
            metricsGroup={metricsGroup}
            deepdiveModal={deepdiveModal}
            comparisonMode={comparisonMode}
            selectedMetric={selectedMetricData?.metric}
            isLoading={isLoading || isLoadingGetBreakdownMetrics}
            isBreakdown={isBreakdown}
            setIsBreakdown={setIsBreakdown}
            onMetricSelection={onMetricSelection}
            onClose={handleClose}
            sidePanel={<DatePickerContainer />}
        />
    )
}
