import { getCountryData, continents as continentsList } from 'countries-list'

export const getInitialData = ({ data, queryParams }) => {
    const countries = data.map((country) => {
        const countryData = getCountryData(country.country_code)
        return {
            ...country,
            isSelected: !!queryParams.countries?.includes(country.country_code),
            name: countryData.name,
            continent: countryData.continent,
        }
    })

    const continentsMap = countries.reduce((acc, country) => {
        const continent = acc[country.continent]

        return {
            ...acc,
            [country.continent]: continent
                ? {
                      ...continent,
                      countryInitials: [...continent.countryInitials, country.country_code],
                      isSelected: continent.isSelected && country.isSelected,
                  }
                : {
                      continent: country.continent,
                      name: continentsList[country.continent],
                      isSelected: country.isSelected,
                      countryInitials: [country.country_code],
                  },
        }
    }, {})

    return {
        countries,
        continents: Object.values(continentsMap),
    }
}

export const getNewContinents = (premItems, selected) =>
    premItems.map((item) => ({
        ...item,
        isSelected: item.countryInitials.every((initial) => selected?.includes(initial)),
    }))

export const getNewCountries = (prevItems, selected) =>
    prevItems.map((item) => ({ ...item, isSelected: selected?.includes(item.country_code) }))
