import React from 'react'
import { getIconColor } from './utils'

export function DislikeSvg({ active, customActiveColor = '#007F82', customColor = '#007F82' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.8rem"
            height="1.8rem"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M3.4663 9.68481H6.26411V3.75003H3.4663C3.34263 3.75003 3.22402 3.79813 3.13658 3.88374C3.04913 3.96936 3 4.08548 3 4.20655V9.22829C3 9.34937 3.04913 9.46549 3.13658 9.5511C3.22402 9.63672 3.34263 9.68481 3.4663 9.68481Z"
                fill={active ? customActiveColor : '#F8F7FB'}
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.2641 9.68478L8.5956 14.25C8.84055 14.25 9.08309 14.2028 9.30939 14.111C9.53568 14.0192 9.7413 13.8847 9.9145 13.7152C10.0877 13.5456 10.2251 13.3443 10.3188 13.1227C10.4126 12.9012 10.4608 12.6637 10.4608 12.4239V11.0543H14.0674C14.1996 11.0543 14.3303 11.0268 14.4509 10.9736C14.5714 10.9203 14.679 10.8426 14.7665 10.7456C14.8541 10.6485 14.9195 10.5343 14.9585 10.4106C14.9975 10.2869 15.0092 10.1565 14.9928 10.0281L14.2933 4.54979C14.2651 4.32897 14.1555 4.12583 13.9851 3.97855C13.8147 3.83128 13.5952 3.75 13.3679 3.75H6.2641"
                fill={active ? customActiveColor : '#F8F7FB'}
            />
            <path
                d="M6.2641 9.68478L8.5956 14.25C8.84055 14.25 9.08309 14.2028 9.30939 14.111C9.53568 14.0192 9.7413 13.8847 9.9145 13.7152C10.0877 13.5456 10.2251 13.3443 10.3188 13.1227C10.4126 12.9012 10.4608 12.6637 10.4608 12.4239V11.0543H14.0674C14.1996 11.0543 14.3303 11.0268 14.4509 10.9736C14.5714 10.9203 14.679 10.8426 14.7665 10.7456C14.8541 10.6485 14.9195 10.5343 14.9585 10.4106C14.9975 10.2869 15.0092 10.1565 14.9928 10.0281L14.2933 4.54979C14.2651 4.32897 14.1555 4.12583 13.9851 3.97855C13.8147 3.83128 13.5952 3.75 13.3679 3.75H6.2641"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
