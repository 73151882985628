import styled from 'styled-components'

export const ModalMessageContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 64rem;
    gap: 0.5rem;

    div {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`
