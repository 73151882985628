export const resolveObjectPath = (obj, path) => {
    const properties = path.split('.')

    let result = obj
    for (let prop of properties) {
        result = result[prop]
    }

    return result
}
