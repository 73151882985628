import { Link, ExternalHelpLinkIcon } from './ModalFooter.styles'
import { Text } from 'shared/styledComponents'

export const ModalFooter = ({ helpLink, helpLinkText }) => (
    <Text>
        Need further help?{' '}
        <Link href={helpLink} target="_blank">
            <b>{helpLinkText}</b>
            <ExternalHelpLinkIcon />
        </Link>
    </Text>
)
