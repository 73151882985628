import { Grid } from '@octup/core/ui-kit'
import { WorkingTimeField } from '../WorkingTimeField'

export type WorkingHoursFieldPropsType = {
    name: string
}

export const WorkingHoursField = ({ name }: WorkingHoursFieldPropsType) => (
    <>
        <Grid container spacing={3}>
            <Grid item xs>
                <WorkingTimeField name={`${name}.time_frames.0.0`} label="From" />
            </Grid>
            <Grid item xs>
                <WorkingTimeField name={`${name}.time_frames.0.1`} label="To" />
            </Grid>
            <Grid item xs>
                <WorkingTimeField name={`${name}.cutoff`} label="Cutoff" />
            </Grid>
        </Grid>
    </>
)
