import { Typography, Button, Link } from '@octup/core/ui-kit'
import { GLOBAL_CONFIG } from '@/config'
import { SignupLayout } from '@/layouts'
import type { SignupSourceConfigType } from '@/models/signup'
import { buttonStyle } from './styles'

type SignupPagePropsType = {
    options: SignupSourceConfigType[]
}

const CONNECT_BY_SOURCE_URL = `${GLOBAL_CONFIG.BASE_URL}/api/signup/connect`

export const SignupPage = ({ options }: SignupPagePropsType) => (
    <SignupLayout title="Let’s connect your store">
        <Typography>
            Allow octup to pull data related to your orders, customer, product, cart and much more.
            Without it, you won’t be able to get the most out of octup.
        </Typography>
        {options.map(({ id, source, nameLogo }) => (
            <Button
                key={id}
                size="large"
                variant="outlined"
                sx={buttonStyle}
                component={Link}
                href={`${CONNECT_BY_SOURCE_URL}/${source}`}
            >
                <img src={nameLogo} alt={`${source} logo`} height="100%" />
            </Button>
        ))}
    </SignupLayout>
)
