// import { InsightGuideStepIcon } from 'shared/assets/icons/InsightGuideStepIcon'
import { IntegrationsGuideStepIcon } from 'shared/assets/icons/IntegrationsGuideStepIcon'
import { ObjectivesGuideStepIcon } from 'shared/assets/icons/ObjectivesGuideStepIcon'

export const TOUR_STEPS = [
    {
        path: '/Integrations',
        selector: '.facebook',
        content: {
            title: 'Connect integrations',
            textBeforeBold: 'This is the aquarium where all data sources swim.',
            boldText:
                'This is a quick cross-segment overview so you will have a good morning (and day) by getting all the info you need.',
            textAfterBold:
                'In case you’re missing an integration please reach out to us and we’ll be sure to add it ASAP.',
            icon: <IntegrationsGuideStepIcon />,
        },
    },
    {
        path: '/Home',
        selector: '.GMB_icon',
        content: {
            title: 'Meet Your Good Morning Board',
            textBeforeBold:
                'See the daily status of your store performance, aggregating the most important metrics into one place.',
            boldText:
                'This is a quick cross-segment overview so you will have a good morning (and day) by getting all the info you need.',
            icon: <ObjectivesGuideStepIcon />,
        },
    },
    // NOTE: Temporary removed

    // {
    //     path: '/Insights',
    //     selector: '.first',
    //     content: {
    //         title: 'Get actionable insights',
    //         textBeforeBold: 'Get real-time actionable insights based on your data..',
    //         boldText: 'Click an insight for further details and suggested actions.',
    //         textAfterBold:
    //             'You can even go one step further and create an objectives based on insights (more on objectives next ).',
    //         icon: <InsightGuideStepIcon />,
    //     },
    // },
    // {
    //     path: '/Objectives',
    //     selector: '.first',
    //     content: {
    //         title: 'Set & track objectives (our version for tasks)',
    //         textBeforeBold: 'Objectives are tasks created from insights (or from scratch).',
    //         boldText: 'They contain a description, actions, due date and an owner.',
    //         textAfterBold:
    //             'Create an objective to follow up on suggested actions and monitor your business improvement and growth.',
    //         icon: <ObjectivesGuideStepIcon />,
    //     },
    // },
]

export const TOTAL_STEPS = TOUR_STEPS.length
