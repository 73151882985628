export function RakutenIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <circle cx="12" cy="12" r="10" fill="#BF0000" />
            <path
                d="M10.9062 17V13.8915H12.2258L14.5425 16.9707H16.9179L14.1026 13.2463C14.9824 12.6305 15.5396 11.6041 15.5396 10.4311C15.5396 8.52493 13.9853 7 12.1085 7H9V16.9707H10.9062V17ZM10.9062 8.90616H12.1378C12.9883 8.90616 13.6921 9.60997 13.6921 10.4604C13.6921 11.3109 12.9883 12.0147 12.1378 12.0147H10.9062V8.90616Z"
                fill="white"
            />
        </svg>
    )
}
