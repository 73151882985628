import type { AppBenefitType } from '@/models/appConfig'
import { CreditCardIcon, LightningIcon, SmilingCircleIcon } from '@/new_assets/icons'
import { AppBenefitsInfo } from '@/new_components/Signup'
import { useAppSelector } from '@/state/hooks'

const APP_BENEFITS: AppBenefitType[] = [
    { id: 1, title: 'No credit card required', icon: CreditCardIcon },
    { id: 2, title: 'Instant Set Up', icon: LightningIcon },
    { id: 3, title: '24/7 Support', icon: SmilingCircleIcon },
]

export const AppBenefitsInfoContainer = () => {
    const { settings } = useAppSelector((state) => state.appConfig)

    return <AppBenefitsInfo name={settings.data.display_name} benefits={APP_BENEFITS} />
}
