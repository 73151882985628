import { UNMATCHED_EVENTS, ORGANIC } from '@/constants/attribution'
import { ShopCurrencyValueContainer } from '@/new_containers/components/__common__'
import {
    CampaignLabelContainer,
    SourceTitleContainer,
} from '@/new_containers/components/Attribution'
import { getFormattedVal, VALUE_TYPE_INDEX } from '@/shared/utils/metrics-utils'
import { CampaignsTableOrderCellContainer } from '../CampaignsTableOrderCellContainer'

const getFormattedROASValue = (value) => value && parseFloat(value).toFixed(2)

const COMMON_TABLE_CELLS = [
    {
        id: 'conversion_value_source',
        field: 'conversion_value_source',
        renderCell: (row) => <ShopCurrencyValueContainer value={row.conversion_value_source} />,
    },
    {
        id: 'conversion_value_octup',
        field: 'conversion_value_octup',
        fontWeight: 600,
        renderCell: (row) => <ShopCurrencyValueContainer value={row.conversion_value_octup} />,
    },
    {
        id: 'spend',
        field: 'spend',
        renderCell: (row) => <ShopCurrencyValueContainer value={row.spend} />,
    },
    {
        id: 'sessions',
        field: 'sessions',
        renderCell: ({ sessions }) =>
            getFormattedVal({
                val: sessions,
                customNumOfDigits: 0,
                valueType: VALUE_TYPE_INDEX.NUMBER,
                multiplyBy100: false,
            }),
    },
    {
        id: 'added_to_cart',
        field: 'added_to_cart',
    },
    {
        id: 'orders',
        field: 'orders',
        renderCell: (row, parentRow) => (
            <CampaignsTableOrderCellContainer
                source={row?.source || parentRow?.source}
                campaignId={row.campaign_id}
                campaignName={row.campaign_name}
                ordersCount={row.orders}
                campaignsCount={row?.campaigns?.length}
                isSummaryRow={!parentRow}
                hasCustomCampaignName={row?.hasCustomCampaignName}
            />
        ),
    },
    {
        id: 'attribution_purchase_count',
        field: 'attribution_purchase_count',
    },
    {
        id: 'new_customer_count',
        field: 'new_customer_count',
    },
    {
        id: 'returning_customer_count',
        field: 'returning_customer_count',
    },
    {
        id: 'ROAS_platform',
        field: 'ROAS_platform',
        renderCell: ({ ROAS_platform }) => getFormattedROASValue(ROAS_platform),
    },
    {
        id: 'ROAS_attributed',
        field: 'ROAS_attributed',
        fontWeight: 600,
        renderCell: ({ ROAS_attributed }) => getFormattedROASValue(ROAS_attributed),
    },
    {
        id: 'AOV',
        field: 'AOV',
        renderCell: (row) => <ShopCurrencyValueContainer value={row.AOV} />,
    },
    {
        id: 'CR',
        field: 'CR',
        renderCell: (row) =>
            getFormattedVal({
                val: row.CR,
                valueType: VALUE_TYPE_INDEX.PERCENTAGE,
                multiplyBy100: false,
                showSymbol: true,
            }),
    },
]

const TABLE_CELLS = [
    {
        id: 'source',
        field: 'source',
        renderCell: ({ source, campaigns }) => (
            <SourceTitleContainer
                source={source}
                campaignsCount={campaigns?.length || 0}
                fontWeight={600}
            />
        ),
    },
    ...COMMON_TABLE_CELLS,
]

const TABLE_SUB_CELLS = [
    {
        id: 'campaign_name',
        field: 'campaign_name',
        whiteSpace: 'wrap',
        renderCell: ({ source, campaign_name, spend }) => (
            <CampaignLabelContainer
                source={source}
                label={campaign_name}
                isUnmatched={
                    campaign_name !== ORGANIC &&
                    campaign_name !== UNMATCHED_EVENTS &&
                    typeof spend !== 'number'
                }
            />
        ),
    },
    ...COMMON_TABLE_CELLS,
]

const TOTAL_TABLE_CELLS = [
    {
        id: 'source',
        field: 'source',
    },
    ...COMMON_TABLE_CELLS,
]

export const getTableCells = ({ isSubRow, isTotalRow }) => {
    if (isSubRow) {
        return TABLE_SUB_CELLS
    }

    if (isTotalRow) {
        return TOTAL_TABLE_CELLS
    }

    return TABLE_CELLS
}
