import styled from 'styled-components'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { ACTIONABLE_ITEM_STATUS, compareStrings } from '../../shared/utils/strings-util'

const Row = styled.div`
    display: flex;
    flex-direction: row;
    height: 80%;
    width: 40%;
    align-items: center;
    justify-content: space-between;
`

const AvatarsContainer = styled.div`
    // background-color: purple;
    width: 25%;
    height: 85%;
    margin: auto;
`

const PageTopSide = styled.div`
    width: 100%;
    display: grid;
    flex-direction: row;
`

const ColumnFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const ProgressContainer = styled(ColumnFlexContainer)`
    padding-top: 1rem;
    width: 25%;
    height: 20rem;
    padding-right: 5rem;
`

const Title = styled.h1`
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 2.3rem;
    color: ${OctupColors.primaryTextLight};
`

const Subtitle = styled.h2`
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: ${OctupColors.primaryTextLight};
    padding-top: 1rem;
`

const Value = styled.span`
    font-weight: 275;
    font-size: 4rem;
    line-height: 6rem;
    color: ${OctupColors.primaryTextLight};
    padding-top: 1rem;
`

const BarArea = styled.div`
    height: 1rem;
    background: #f6f5f9;
    border: 0.1rem solid rgba(52, 52, 52, 0.16);

    box-shadow:
        inset -0.2rem -0.2rem 0.4rem #ffffff,
        inset 0.2rem 0.2rem 0.8rem rgba(80, 75, 90, 0.32);
    border-radius: 1.2rem;
    border: 0px;
    margin-left: 0.2rem;
`

const Bar = styled.div`
    background: ${OctupColors.primaryTextLight};
    border-radius: 0.8rem;
    height: 0.8rem;
    width: ${(props) => props.width};
`

const SeperatorContainer = styled.div`
    border-right: ${(p) => p.showSeperator && '0.15rem solid #dddbe1'};
    height: 92%;
    width: 105%;
    padding-right: 4rem;
`

const TableWrapper = styled.div`
    padding: 2rem;
    width: 100%;
`

const ActionableItemsTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 2rem;
    width: 100%;
    height: 100%;
`
const TableDataActionableItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 40rem;
    min-width: 40rem;
    width: 100%;
`

const TableDataActionableItemFirstLine = styled.div`
    color: #504b5a;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 3rem;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    text-overflow: ellipsis;
    text-transform: none;
`

const TableDataActionableItemSecondLine = styled.div`
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    text-overflow: ellipsis;
    text-transform: none;
`

const TableDataStyled = styled.td`
    opacity: ${(props) => (props.isDismissed ? 0.4 : 1)};
    font-weight: 400;
    height: 100%;
    text-transform: capitalize;
`
const OwnerContainer = styled.div`
    font-size: inherit;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 2rem;
`
const TableButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    position: relative;
`

const StatusStyled = styled.span`
    font-size: inherit;
    text-transform: capitalize;
    color: ${(props) =>
        compareStrings(props.status, ACTIONABLE_ITEM_STATUS.OVERDUE)
            ? '#DB2059'
            : compareStrings(props.status, ACTIONABLE_ITEM_STATUS.COMPLETED)
              ? '#078344'
              : '#504B5A'};
`

const DropdownContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const OriginStyled = styled.div`
    display: flex;
    align-items: center;
    font-size: inherit;
    gap: 1rem;
    color: ${(props) => props.isInsight && '#007F82'};
    font-weight: ${(props) => props.isInsight && 600};
`

const DotMenuOptionsContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.8rem;

    div {
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        line-height: 1.8rem;
        justify-content: flex-start;
        width: 100%;
        gap: 0.6rem;
        padding: 1rem 1.5rem;

        :hover {
            background: #f1f0f5;
            border-radius: 0.8rem;
        }
    }

    svg {
        width: 2.4rem;
        height: 2.4rem;
    }
`

const TopButton = styled.div`
    display: flex;
    width: 100%;
    height: 3rem;
    justify-content: flex-end;
    margin-bottom: 2rem;
`

const StyledPageTitle = styled.div`
    height: 4.8rem;
    width: 100%;
    color: #504b5a;
    text-align: left;
    font-weight: 500;
    font-size: 2rem;
    line-height: 4.8rem;
    text-transform: capitalize;
`

export {
    StyledPageTitle,
    TopButton,
    DotMenuOptionsContainer,
    OriginStyled,
    DropdownContainer,
    StatusStyled,
    TableButtonsContainer,
    OwnerContainer,
    TableDataStyled,
    TableDataActionableItemSecondLine,
    TableDataActionableItemFirstLine,
    TableDataActionableItemContainer,
    ActionableItemsTableContainer,
    TableWrapper,
    SeperatorContainer,
    Bar,
    BarArea,
    Value,
    Subtitle,
    Title,
    ProgressContainer,
    ColumnFlexContainer,
    PageTopSide,
    AvatarsContainer,
    Row,
}
