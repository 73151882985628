import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: '1',
    alias: 'Paper Street Soap Co.',
    isLoading: false,
}

const shopSlice = createSlice({
    name: 'selectedShop',
    initialState,
    reducers: {
        //@Todos : set selected shop, get selected shop dataset
    },
    extraReducers: (builder) => {},
})

export const shopActions = shopSlice.actions
export const shopState = (state) => state.authentication
export default shopSlice.reducer
