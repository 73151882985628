import React from 'react'
import { getIconColor } from './utils'

export function SnoozeSvg({ active, customActiveColor = '#007F82', customColor = '#007F82' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.8rem"
            height="1.8rem"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M9 14.1562C11.8477 14.1562 14.1562 11.8477 14.1562 9C14.1562 6.15228 11.8477 3.84375 9 3.84375C6.15228 3.84375 3.84375 6.15228 3.84375 9C3.84375 11.8477 6.15228 14.1562 9 14.1562Z"
                fill="#F8F7FB"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeMiterlimit="10"
            />
            <path d="M9 5.71875V9H12.2812" fill="#F8F7FB" />
            <path
                d="M9 5.71875V9H12.2812"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.9774 3.03442L14.9661 5.02316"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.03381 5.02316L5.02255 3.03442"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
