import styled from 'styled-components'
import { ClearFieldsSvg } from '../../../assets/icons'
import { useState } from 'react'
import { useEffect } from 'react'

export function OctupInput({
    type = 'text',
    name,
    id,
    placeholder,
    maxLength,
    minLength,
    disabled = false,
    hideDesign = false,
    max,
    min,
    step,
    onFocus,
    onBlur,
    onChange,
    onInput,
    onMouseEnter,
    onMouseLeave,
    errors = [],
    showErrors = false,
    isValid = true,
    leftIcon = null,
    showLengthCounter = false,
    value = '',
    width = '80%',
    customStyle = null,
    showClearField = false,
}) {
    const LeftIcon = leftIcon
    const inputWarningMsgCustomStyle = customStyle?.inputWarningMsg
    const [inputValue, setInputValue] = useState(value)

    useEffect(() => {
        setInputValue(value)
    }, [value, name])

    const onChangeHandler = (e) => {
        setInputValue(e.target.value)
        onChange && onChange(e)
    }

    const onClearHandler = () => {
        setInputValue('')
        onChange && onChange({ target: { value: '' } })
    }

    const onBlurHandler = () => {
        onBlur && onBlur()
    }

    const onFocusHandler = () => {
        onFocus && onFocus()
    }

    const onInputHandler = () => {
        onInput && onInput()
    }

    const onMouseEnterHandler = () => {
        onMouseEnter && onMouseEnter()
    }

    const onMouseLeaveHandler = () => {
        onMouseLeave && onMouseLeave()
    }

    return (
        <InputContainer>
            {leftIcon && (
                <InputLeftIcon>
                    <LeftIcon customColor={'#C2C0C7'} />
                </InputLeftIcon>
            )}
            {inputValue !== null && inputValue.length > 0 && showClearField && (
                <InputRightIcon onClick={onClearHandler}>
                    <ClearFieldsSvg />
                </InputRightIcon>
            )}
            <InputStyled
                leftIcon={leftIcon}
                style={customStyle}
                name={name}
                hasError={showErrors}
                id={id}
                onChange={onChangeHandler}
                onInput={onInputHandler}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                width={width}
                type={type}
                placeholder={placeholder}
                maxLength={maxLength}
                minLength={minLength}
                max={max}
                min={min}
                step={step}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                value={inputValue}
                disabled={disabled}
                hideDesign={hideDesign}
                showClearField={showClearField}
            />
            <ErrorsContainer>
                {showErrors && errors?.map?.((el) => <InputErrorMsg key={el}>{el}</InputErrorMsg>)}
            </ErrorsContainer>
            {showLengthCounter && (
                <InputWarningMsg
                    inputWarningMsgCustomStyle={{ ...inputWarningMsgCustomStyle }}
                    isMaxValue={value.length === maxLength}
                >{`${value.length && value.length}/${maxLength && maxLength} `}</InputWarningMsg>
            )}
        </InputContainer>
    )
}

const InputStyled = styled.input`
    background: ${(props) => (props.hideDesign ? 'inherit' : '#f6f5f9')};
    color: ${(props) => (props.hasError ? '#DB2059' : props.hideDesign ? 'inherit' : '')};
    border: none;
    /* border: ${(props) =>
        props.hideDesign
            ? 'none'
            : props.hasError
              ? '0.2rem solid #DB2059'
              : '0.2rem solid rgba(255, 255, 255, 0.4)'}; */
    box-shadow: ${(props) =>
        props.hideDesign ? 'none' : 'inset 0.2rem 0.2rem 0.8rem rgba(80, 75, 90, 0.32)'};
    border-radius: 0.4rem;
    padding: ${(props) => (props.leftIcon ? '1.6rem 3.5rem 1.6rem 4rem' : '1.6rem 0.8rem')};
    line-height: 2rem;
    font-size: 1.5rem;
    width: ${(props) => props.width};
    outline: ${(props) => (props.hasError ? '0.2rem solid #DB2059' : 'none')};

    &:focus {
        outline: ${(props) => (props.hasError ? '0.2rem solid #DB2059' : '0.2rem solid #007f82')};
    }

    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const ErrorsContainer = styled.ul`
    bottom: -1rem;
    left: 0;
    height: 1rem;
    min-height: fit-content;
    position: absolute;
    white-space: nowrap;
`

const InputErrorMsg = styled.li`
    color: #db2059;
    font-size: 1.3rem;
    line-height: 1.8rem;
`

const InputContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
`

const InputWarningMsg = styled.div`
    color: ${(props) => (props.isMaxValue ? '#db2059' : 'inherit')};
    background-color: transparent;
    font-size: 1.1rem;
    line-height: 1.6rem;
    bottom: -1.8rem;
    right: ${(props) =>
        props.inputWarningMsgCustomStyle?.right ? props.inputWarningMsgCustomStyle.right : '9%'};
    position: absolute;
`

const InputLeftIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-42%);
`

const InputRightIcon = styled.div`
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-38%);
    cursor: pointer;
`
