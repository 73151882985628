import { useEffect, useMemo, useCallback, useRef } from 'react'
import type { DashboardTemplateType, MetricsSectionType } from '@octup/core/models'
import type { MetricsSectionsCategoryType } from '@/models/metrics'
import { MetricsSectionsListPanel } from '@/new_components/Dashboards/DashboardForm'
import {
    fetchMetricsSectionsList,
    setSearchValue,
} from '@/state/features/metrics/metricsSectionsListSlice'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { getSearchedOptions } from '@/utils'
import { getMetricsSectionsListsByGroup } from './MetricsSectionsListPanelContainer.utils'

type MetricsSectionsListPanelContainerPropsType = {
    template?: DashboardTemplateType
}

export const MetricsSectionsListPanelContainer = ({
    template,
}: MetricsSectionsListPanelContainerPropsType) => {
    const dispatch = useAppDispatch()
    const searchInputRef = useRef<HTMLInputElement>(null)
    const { dashboard } = useAppSelector((state) => state.dashboards)
    const { metricsSectionsList } = useAppSelector((state) => state.newMetrics)
    const { selectedDashboardSection } = dashboard

    useEffect(() => {
        if (!metricsSectionsList.data) {
            dispatch(fetchMetricsSectionsList())
        }
        return () => {
            dispatch(setSearchValue(''))
        }
    }, [dispatch, metricsSectionsList.data])

    useEffect(() => {
        searchInputRef.current?.focus()
    }, [selectedDashboardSection?.name])

    const metricsSectionsListsByGroup = useMemo(
        () => getMetricsSectionsListsByGroup(template, metricsSectionsList.data),
        [metricsSectionsList.data, template]
    )

    const selectedMetricsSectionsList = useMemo(() => {
        if (!metricsSectionsListsByGroup || !selectedDashboardSection) return []
        return metricsSectionsListsByGroup[selectedDashboardSection.group]
    }, [metricsSectionsListsByGroup, selectedDashboardSection])

    const searchedMetricsSections = useMemo(
        () =>
            getSearchedOptions<MetricsSectionsCategoryType>({
                value: metricsSectionsList.searchValue,
                options: selectedMetricsSectionsList,
                subOptionsField: 'sections',
                getOptionLabel: (option: MetricsSectionType | MetricsSectionsCategoryType) =>
                    (option as MetricsSectionType)?.name ||
                    (option as MetricsSectionsCategoryType).category,
            }),
        [selectedMetricsSectionsList, metricsSectionsList.searchValue]
    )

    const handleSearch = useCallback(
        (value: string) => {
            dispatch(setSearchValue(value))
        },
        [dispatch]
    )

    if (!selectedDashboardSection) return null

    return (
        <MetricsSectionsListPanel
            options={searchedMetricsSections}
            selectedDashboardSection={selectedDashboardSection}
            searchValue={metricsSectionsList.searchValue}
            onSearch={handleSearch}
            searchInputRef={searchInputRef}
        />
    )
}
