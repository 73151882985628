import axios from 'axios'
import { GLOBAL_CONFIG } from 'config'
import { generateAPIConfig } from 'services/utils'

export const LOGISTICS_API_URL = GLOBAL_CONFIG.BASE_URL + 'logistics'

/**
 * GET logistics data
 * @start_date mandatory
 * @end_date mandatory
 * @warehouses_names optional
 * @countries optional ('IL,US:NY,CA:BC,VG')
 */

export const getLogistics = async (data, thunkAPI) => {
    const config = generateAPIConfig({
        thunkAPI,
        params: {
            start_date: data.startDate,
            end_date: data.endDate,
            warehouses_names: data.warehouses_names?.join(','),
            countries: data.countries?.join(','),
        },
    })

    const response = await axios.get(LOGISTICS_API_URL, config)
    return response.data
}

/**
 * GET warehouses
 */

export const getWarehouses = async (thunkAPI) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(`${LOGISTICS_API_URL}/warehouses`, config)
    return response.data
}

/**
 * GET countries
 * @start_date mandatory
 * @end_date mandatory
 * @warehouses_names optional
 */

export const getWarehouseCountries = async (data, thunkAPI) => {
    const config = generateAPIConfig({
        thunkAPI,
        params: {
            start_date: data.startDate,
            end_date: data.endDate,
            warehouses_names: data.warehouses_names?.join(','),
        },
    })

    const response = await axios.get(`${LOGISTICS_API_URL}/countries`, config)
    return response.data
}

/**
 * GET warehouse cost data
 * @cost_name mandatory
 * @start_date mandatory
 * @end_date mandatory
 * @warehouse_name mandatory
 * @countries optional ('IL,US:NY,CA:BC,VG')
 */

export const getWarehouseCost = async (data, thunkAPI) => {
    const config = generateAPIConfig({
        thunkAPI,
        params: {
            cost_name: data.costName,
            start_date: data.startDate,
            end_date: data.endDate,
            warehouse_name: data.warehouseName,
            countries: data.countries,
        },
    })

    const response = await axios.get(`${LOGISTICS_API_URL}/warehouses/costs`, config)
    return response.data
}

/**
 * GET order cost report file
 * @start_date mandatory
 * @end_date mandatory
 * @warehouse_name mandatory
 * @countries optional ('IL,US:NY,CA:BC,VG')
 */

export const getOrderCostsReport = async (data, thunkAPI) => {
    const config = generateAPIConfig({
        thunkAPI,
        responseType: 'blob',
        contentType: 'multipart/form-data',
        params: {
            start_date: data.startDate,
            end_date: data.endDate,
            warehouse_name: data.warehouseName,
            countries: data.countries,
        },
    })

    const response = await axios.get(`${LOGISTICS_API_URL}/warehouses/costs/report`, config)
    return response
}
