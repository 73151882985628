import { Link, Typography } from '@mui/material'
import { linkStyle } from './InvoicesRequestText.styles'

export const InvoicesRequestText = ({
    primaryText,
    secondaryText,
    link,
    onClick,
    buttonText = 'your dedicated inbox',
    hasPeriod = true,
    container,
    children,
}) => {
    const Container = container || Typography

    return (
        <Container {...(!container && { fontSize: 'inherit' })}>
            {primaryText}{' '}
            <Link onClick={onClick} underline="none" sx={linkStyle} href={link}>
                {buttonText}
            </Link>
            {secondaryText && ` ${secondaryText}`}
            {hasPeriod && '.'}
            {children}
        </Container>
    )
}
