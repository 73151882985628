import styled from 'styled-components'
import { OctupColors } from '../../theme/colors/OctupColors'

export function OctupTextArea({
    type,
    name,
    id,
    placeholder,
    maxLength,
    minLength,
    max,
    min,
    step,
    onFocus,
    onBlur,
    onChange,
    onInput,
    value,
    rows,
    width = '80%',
    isErrored = false,
}) {
    return (
        <InputStyled
            name={name}
            id={id}
            onChange={onChange}
            onInput={onInput}
            width={width}
            type={type}
            placeholder={placeholder}
            maxLength={maxLength}
            minLength={minLength}
            max={max}
            min={min}
            step={step}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
            rows={rows}
            isErrored={isErrored}
        />
    )
}
const InputStyled = styled.textarea`
    background: #f6f5f9;
    border: 0.05rem solid rgba(255, 255, 255, 0.4);
    box-shadow: inset 0.2rem 0.2rem 0.8rem rgba(80, 75, 90, 0.32);
    border-radius: 0.4rem;
    padding: 1.6rem 0.8rem;
    line-height: 2rem;
    font-size: 1.5rem;
    width: ${(props) => props.width};
    outline: ${(props) => (props.isErrored ? `.2rem solid ${OctupColors.alertLight}` : 'none')};
    resize: none;
`
