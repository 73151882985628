import {
    AUTH_API_URL,
    forgotPassword,
    getClientInfo,
    getUserProfile,
    login,
    resetPassword,
    validateEmail,
} from '../../features/auth/authSlice'
import { octupDictionary } from '../../shared/dictionary/dictionary'
import { NotifyTypes, octupNotify } from '../../shared/notification/OctupToaster'
import { isEmailValid } from '../../shared/ui/Inputs/inputUtils'
import { isPasswordStrong } from '../../shared/utils/utils'

export const resetPasswordViaEmail = async ({
    dispatch,
    email,
    setEmailRelatedError,
    setCurrStep,
    currStep,
}) => {
    const response = await dispatch(validateEmail({ email }))
    if (!response?.payload?.valid) {
        setEmailRelatedError(null)
        dispatch(forgotPassword({ email }))
        setCurrStep(currStep + 1)
    } else {
        setEmailRelatedError(octupDictionary.errors.messages.emailNotFound)
    }
}
export const sendData = async ({
    dispatch,
    email,
    password,
    setPasswordRelatedError,
    setEmailRelatedError,
}) => {
    const response = await dispatch(login({ email, password }))
    if (response.error) {
        setPasswordRelatedError('Incorrect password')

        const redirectDomain = response.payload?.domain
        if (redirectDomain) {
            window.location.replace(`https://${redirectDomain}`)
        }
    }
    if (response?.payload?.token) {
        const user = await dispatch(getUserProfile(response?.payload?.token))
        const client = await dispatch(getClientInfo(response?.payload?.token))
        if (user && client) {
            const userInfo = JSON.parse(localStorage.getItem('userInfo'))
            userInfo.isStoreConnected = true
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            window.location.replace(`${AUTH_API_URL}/login/validate`)
        }
    } else if (`${response.payload}`.includes('password')) {
        setPasswordRelatedError(response.payload)
    } else if (`${response.payload}`.includes('email')) {
        setEmailRelatedError(response.payload)
    } else if (`${response.payload}`.includes('User')) {
        setPasswordRelatedError(response.payload)
    } else if (response.error) {
        octupNotify({
            message: response.payload,
            type: NotifyTypes.error,
        })
    }
    return response
}

export const handleSubmit = async ({
    email: inputEmail,
    password,
    setPasswordRelatedError,
    setEmailRelatedError,
    setConfirmPasswordRelatedError,
    currStep,
    setCurrStep,
    confirmPassword,
    passwordResetToken,
    dispatch,
}) => {
    const email = inputEmail.trim()
    if (email && email.length && !isEmailValid(email)) {
        setEmailRelatedError(octupDictionary.errors.messages.invalidEmailAddress)
    } else if (password.length === 0) {
        setPasswordRelatedError('Please enter your password')
    }
    // eslint-disable-next-line default-case
    switch (currStep) {
        case 0:
            if (password.length === 0) {
                setPasswordRelatedError('Please enter your password')
            } else {
                sendData({
                    dispatch,
                    email,
                    password,
                    setPasswordRelatedError,
                    setEmailRelatedError,
                })
            }
            break
        case 1:
            await resetPasswordViaEmail({
                dispatch,
                email,
                setEmailRelatedError,
                setCurrStep,
                currStep,
            })
            break
        case 3:
            if (!password) {
                setPasswordRelatedError('This is required')
            } else if (!isPasswordStrong(password)) {
                setPasswordRelatedError("Password doesn't meet the requirements")
            } else if (password !== confirmPassword) {
                setConfirmPasswordRelatedError("Passwords don't match")
            } else {
                const resetPasswordRes = await dispatch(
                    resetPassword({ passwordResetToken, password })
                )
                if (resetPasswordRes) {
                    setCurrStep(currStep + 1)
                }
            }
    }
}

export const emailFieldOnBlur = async ({
    email: inputEmail,
    setEmailRelatedError,
    setInvalidEmail,
    dispatch,
}) => {
    const email = inputEmail.trim()
    if (!isEmailValid(email)) {
        setEmailRelatedError(octupDictionary.errors.messages.invalidEmailAddress)
    } else {
        const response = await dispatch(validateEmail({ email }))
        if (response) {
            setInvalidEmail(response?.payload?.valid)
            response?.payload?.valid
                ? setEmailRelatedError('We couldn’t find an account under this email')
                : setEmailRelatedError(null)
        }
    }
}
