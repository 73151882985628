import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { MetricItemWithChart } from './MetricItem/MetricItemWithChart'
import { SEGMENTS } from '../../features/metrics/metricsSlice'
import { useOctupDispatch } from '../../hooks/useOctupDispatch'
import { OctupNoDataFound } from '../../shared/components/loaders/OctupNoDataFound'
import { CardExpendable } from '../../shared/ui/Card/CardExpendable'

const NUMBER_OF_SHOWN_METRICS = 5

const MetricsHome = () => {
    const octupDispatch = useOctupDispatch()
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const { homepageMetrics, isLoading, isRejected } = useSelector(
        (state) => state.homePageMetricsSection
    )
    const { isMainMenuCollapsed } = useSelector((state) => state.navigation)

    useEffect(() => {
        octupDispatch.metrics.getHomepageMetrics(
            datePickerValues.startDate,
            datePickerValues.endDate
        )
    }, [])

    return (
        <>
            <CardExpendable customStyle={{ width: '100%' }}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        padding: '.8rem',
                        alignItems: 'center',
                        overflowX: 'auto',
                    }}
                >
                    {
                        <MetricsWrapper isMainMenuCollapse={isMainMenuCollapsed}>
                            {!isRejected ? (
                                [...homepageMetrics]
                                    .sort((a, b) => {
                                        if (!a.metric || !b.metric) {
                                            return 0
                                        }
                                        if (a.metric.order < b.metric.order) {
                                            return -1
                                        }
                                        if (a.metric.order > b.metric.order) {
                                            return 1
                                        }
                                        return 0
                                    })
                                    .slice(0, NUMBER_OF_SHOWN_METRICS)
                                    .map((el, index) => {
                                        return (
                                            <MetricItemWithChart
                                                key={el?.metric?.metricTitle || el}
                                                sources={el?.metric?.sources}
                                                segment={SEGMENTS.ALTERNATIVE_HOME_METRICS}
                                                isLoading={isLoading}
                                                isDensed={false}
                                                showDetailsBtn={!isLoading}
                                                id={el?.metric?.metricTitle}
                                                title={el?.metric?.metricTitle}
                                                description={el?.metric?.description}
                                                value={el?.metric?.value}
                                                currency={el?.metric?.currency}
                                                valueUnits={el?.metric?.valueUnits}
                                                el={el.metric}
                                                trendValue={el?.metric?.trendValue}
                                                trend={el?.metric?.trendValue}
                                                trendUnits={el?.metric?.trendUnits}
                                                isPositiveTrend={el?.metric?.isPositiveTrend}
                                                showGraph={false}
                                                showSeperator={
                                                    index !== homepageMetrics?.length - 1
                                                    // metricsList.filter((el) => el.order < 6).length - 1
                                                }
                                                isSupported={el?.metric?.supported}
                                                isStale={el?.metric?.stale}
                                                lastUpdatedAt={el?.metric?.last_updated_date}
                                                customStyle={{ minWidth: '250px' }}
                                            />
                                        )
                                    })
                            ) : (
                                <OctupNoDataFound width={'14rem'} />
                            )}
                        </MetricsWrapper>
                    }
                    <BluredItem />
                </div>
            </CardExpendable>
        </>
    )
}

const MetricsWrapper = styled.div`
    display: flex;
    /* gap: 1rem; */
    padding: 0;
    justify-content: flex-start;
    align-items: center;
    z-index: 3;
    height: 100%;
    min-height: 14rem;
    flex: 1;
    width: ${(props) => (props.isMainMenuCollapsed ? 'calc(100% - 5rem)' : 'calc(100% - 25rem)')};
    width: 100px;
    overflow-x: auto;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    ::-webkit-scrollbar {
        width: 1rem;
        height: 1rem;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 2rem;
        border: 0.6rem solid #d6dee1;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
    }
`

const CustomizeMetricsStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;
    padding: 0.5rem 0;
`

const BluredItem = styled.div`
    width: 40px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    display: none;
    background-image: linear-gradient(to right, rgba(256, 256, 256, 0), #d6dee1, #d6dee1);
`

export default MetricsHome
