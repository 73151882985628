import styled from 'styled-components'

export function UserMenuItem({
    info,
    icon,
    rightIcon,
    title,
    clickHandler,
    customColor,
    isDisabled = false,
}) {
    const Icon = icon
    const RightIcon = rightIcon
    return (
        <UserMenuItemContainer onClick={isDisabled ? null : clickHandler} isDisabled={isDisabled}>
            <IconContainer>{icon && <Icon customColor={customColor} />}</IconContainer>
            <OptionText>
                <Title>{title && title}</Title>
                <Info>{info && info}</Info>
            </OptionText>
            {rightIcon && (
                <RightIconContainer>
                    <IconContainer>
                        <RightIcon customColor={customColor} />
                    </IconContainer>
                </RightIconContainer>
            )}
        </UserMenuItemContainer>
    )
}

const UserMenuItemContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    :hover {
        background: ${(props) => !props.isDisabled && '#f1f0f5'};
        border-radius: 0.8rem;
    }

    opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
    cursor: ${(props) => props.isDisabled && 'not-allowed'};
`

const OptionText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.2rem;
`

const Title = styled.div`
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.8rem;
`

const Info = styled.div`
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: rgba(127, 123, 135, 1);
`
const IconContainer = styled.div`
    min-width: 2.4rem;
`

const RightIconContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
