import { getIconColor } from './utils'
export function ActionsBtnOrdersIcon({
    active,
    customActiveColor = '#504B5A',
    customColor = '#504B5A',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M7.66666 9.90002H16.3333"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.66666 13.7H14.3333"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 19V5.7C4 5.51435 4.07024 5.3363 4.19526 5.20503C4.32029 5.07375 4.48986 5 4.66667 5H19.3333C19.5101 5 19.6797 5.07375 19.8047 5.20503C19.9298 5.3363 20 5.51435 20 5.7V19L17.3333 17.6L14.6667 19L12 17.6L9.33333 19L6.66667 17.6L4 19Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
