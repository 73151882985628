export const containerStyle = {
    overflow: 'auto',
}

export const dividerContainerStyle = (theme) => ({
    width: '100%',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(2),
})

export const totalDividerStyle = (theme) => ({
    borderColor: theme.palette.grey[500],
})
