import { Fragment, useEffect, useState } from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import { OverflowTypography } from '@octup/core/ui-kit'
import { useSelector } from 'react-redux'
import { LoadableContent } from '@/new_components/__common__'
import { OctupInput } from '@/shared/ui/Inputs/Input/OctupInput'
import { VALUE_TYPE_INDEX, getFormattedVal } from '@/shared/utils/metrics-utils'

export const ProfitMetricItem = ({
    isLoading,
    setCurrentProductPrice,
    currentProductPrice,
    metricData,
}) => {
    const { selectedProduct } = useSelector((state) => state.simulator)
    const [currPrice, setCurrPrice] = useState(currentProductPrice)

    useEffect(() => {
        if (selectedProduct && selectedProduct.price) setCurrPrice(selectedProduct.price)
    }, [selectedProduct])

    useEffect(() => {
        setCurrentProductPrice?.(currPrice)
    }, [currPrice, setCurrentProductPrice])

    const handlePriceUpdate = (value) => {
        setCurrPrice(parseFloat(value))
    }

    return metricData
        .sort((a, b) => a.order - b.order)
        .map((el, index, arr) => (
            <Fragment key={el.title}>
                <Grid
                    item
                    xs={4}
                    container
                    direction="column"
                    wrap="nowrap"
                    sx={{ minWidth: '20rem' }}
                >
                    <Grid item xs={3.5}>
                        <Typography textTransform="capitalize">{el.title}</Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                        <LoadableContent isLoading={isLoading} height="3rem" paddingTop={1}>
                            {!el.isEditMode ? (
                                <Typography
                                    variant="h1"
                                    fontWeight={275}
                                    color={el.value > 0 ? 'success.main' : 'error'}
                                >
                                    {getFormattedVal({
                                        val: el.value,
                                        valueType: VALUE_TYPE_INDEX.PERCENTAGE,
                                        multiplyBy100: false,
                                        isSupported: typeof el.value === 'number',
                                    })}
                                </Typography>
                            ) : (
                                // TODO: check an editable mode and create a new component for this
                                el.isEditable && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: '3.9rem',
                                            fontWeight: 275,
                                            color: '#504B5A',
                                        }}
                                    >
                                        {el.prefix}
                                        <OctupInput
                                            min={0}
                                            width="100%"
                                            value={currentProductPrice}
                                            onChange={(e) => {
                                                handlePriceUpdate(e.target.value)
                                            }}
                                            type="number"
                                            step={0.01}
                                            customStyle={{
                                                width: '20rem',
                                                fontSize: '3.9rem',
                                                padding: '0 1rem',
                                                fontWeight:
                                                    parseFloat(el.value) !== parseFloat(currPrice)
                                                        ? 400
                                                        : 275,
                                                color:
                                                    parseFloat(el.value) !== parseFloat(currPrice)
                                                        ? '#007F82'
                                                        : 'inherit',
                                            }}
                                        />

                                        {el.postfix}
                                    </div>
                                )
                            )}
                        </LoadableContent>
                    </Grid>
                    <Grid item xs={3} container>
                        <LoadableContent isLoading={isLoading} height="1.5rem" paddingTop={1}>
                            <OverflowTypography variant="body2" color="textSecondary">
                                {el.alias}
                            </OverflowTypography>
                        </LoadableContent>
                    </Grid>
                </Grid>
                {arr.length - 1 !== index && (
                    <Grid item>
                        <Divider orientation="vertical" />
                    </Grid>
                )}
            </Fragment>
        ))
}
