import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { CardExpendable } from '../../../../shared/ui/Card/CardExpendable'
import { BottomCardInnerContainer, CardTitle } from '../../Marketing.styles'
import { DICTIONARY_SECTIONS, getDictionaryValue } from '../../../../shared/dictionary/dictionary'
import { ButtonIcon } from '../../../../shared/ui/Buttons/ButtonIcon'
import { ChevronRightSvg } from '../../../../shared/assets/icons'
import { getMetricsBySegment, SEGMENTS } from '../../../../features/metrics/metricsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { BtnStyle, chartMapIcons } from '../../MarketingPage'
import { ROASMetrics } from './ROASMetrics'
import { ROASChart } from './ROASChart'

const getRoasChartData = (roasMetrics) => {
    const chartsOptions = [
        { key: 'roas_facebook', label: 'Facebook', icon: chartMapIcons.facebook },
        { key: 'roas_google', label: 'Google Ad', icon: chartMapIcons.googleAds },
    ]
    //Removes non supported metrics
    let filteredArray = roasMetrics.filter(
        (el) =>
            !!el.metric.supported &&
            chartsOptions.map((el) => el.key).includes(el?.metric?.metricTitle)
    )
    //Adds the addtional data
    return filteredArray.map((el) => {
        const option = chartsOptions.find((option) => option.key === el.metric.metricTitle)
        return {
            supported: true,
            data: el?.metric?.value,
            image: option.icon,
            label: option.label,
        }
    })
}

export function ROASSection() {
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const { metrics, isLoading } = useSelector((state) => state.metrics)
    const dispatch = useDispatch()
    const [roasMetrics, setRoasMetrics] = useState([])
    const [roasChartData, setRoasChartData] = useState([])
    useEffect(() => {
        if (!datePickerValues || isLoading) return
        dispatch(
            getMetricsBySegment({
                segments: [SEGMENTS.MARKERING_ROAS],
                datePickerValues,
                dataset: 1,
            })
        )
    }, [datePickerValues])

    useEffect(() => {
        if (metrics[SEGMENTS.MARKERING_ROAS]) {
            setRoasMetrics(() =>
                [
                    ...metrics[SEGMENTS.MARKERING_ROAS].map((el) => ({
                        id: uuidv4(),
                        ...el.metric,
                    })),
                ].filter(
                    (el) => el?.metricTitle !== 'roas_facebook' && el?.metricTitle !== 'roas_google'
                )
            )
            setRoasChartData(getRoasChartData([...metrics[SEGMENTS.MARKERING_ROAS]]))
        }
    }, [metrics, datePickerValues])

    return (
        <CardExpendable>
            <BottomCardInnerContainer>
                <CardTitle>
                    {getDictionaryValue('roas', DICTIONARY_SECTIONS.TITLES)}
                    {false && (
                        <ButtonIcon
                            mainIcon={ChevronRightSvg}
                            customStyle={BtnStyle}
                            onClick={() => {
                                alert('clicked')
                            }}
                        />
                    )}
                </CardTitle>
                <ROASMetrics isLoading={isLoading} roasMetrics={roasMetrics} />
                <ROASChart isLoading={isLoading} roasChartData={roasChartData} />
            </BottomCardInnerContainer>
        </CardExpendable>
    )
}
