import { useCallback, useMemo } from 'react'
import { ButtonIcon } from 'shared/ui/Buttons/ButtonIcon'
import { ConnectionSvg } from 'shared/assets/icons/ConnectionSvg'
import { OctupColors } from 'shared/theme/colors/OctupColors'
import { UNDISCONNECTABLE_INTEGRATIONS } from './DisconnectButtonContainer.utils'

export const DisconnectButtonContainer = ({ integrationName, onClick, ...props }) => {
    const isDisabled = useMemo(
        () => UNDISCONNECTABLE_INTEGRATIONS.includes(integrationName),
        [integrationName]
    )

    const handleClick = useCallback(() => {
        onClick(integrationName)
    }, [integrationName, onClick])

    return (
        <ButtonIcon
            mainIcon={ConnectionSvg}
            customIconColor={OctupColors.redOnLight}
            onClick={handleClick}
            disabled={isDisabled}
            {...props}
        />
    )
}
