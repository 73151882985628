import { useMemo } from 'react'
import {
    getTableData,
    getCampaignsCountLabel,
    CAMPAIGNS_TABLE_CONFIG,
} from './CampaignsTableContainer.utils'
import { CampaignsTable } from 'new_components/Attribution'

export const CampaignsTableContainer = ({ campaignsData, isLoading }) => {
    const customCounter = useMemo(
        () => getCampaignsCountLabel(campaignsData?.data),
        [campaignsData?.data]
    )

    const tableData = useMemo(
        () => getTableData({ isLoading, campaignsData }),
        [campaignsData, isLoading]
    )

    return (
        <CampaignsTable
            tableData={tableData}
            isLoading={isLoading}
            customCounter={customCounter}
            {...CAMPAIGNS_TABLE_CONFIG}
        />
    )
}
