import { ArrowIcon } from '@octup/core/assets/icons'
import { Collapse, ListItemButton, ListItemText, ListItemIcon, SvgIcon } from '@octup/core/ui-kit'
import type { MetricsSectionsCategoryType } from 'models/metrics'
import { MetricsSectionsList } from 'new_components/Dashboards/DashboardForm/MetricsSectionsListPanel'
import { metricsSectionsListStyle } from './CategoriesListItem.styles'

type CategoriesListItemPropsType = MetricsSectionsCategoryType & {
    isOpen: boolean
    onClick: () => void
}

export const CategoriesListItem = ({
    isOpen,
    onClick,
    category,
    sections,
}: CategoriesListItemPropsType) => (
    <>
        <ListItemButton onClick={onClick}>
            <ListItemIcon>
                <SvgIcon component={ArrowIcon} rotate={isOpen ? 180 : 0} />
            </ListItemIcon>
            <ListItemText primary={`${category} (${sections.length}) `} />
        </ListItemButton>
        <Collapse in={isOpen} sx={metricsSectionsListStyle}>
            <MetricsSectionsList sections={sections} />
        </Collapse>
    </>
)
