import React from 'react'
import { OctupColors } from '../../theme/colors/OctupColors'

export function DownTriangleArrowSvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2rem"
            height="0.6rem"
            viewBox="0 0 12 6"
            fill="none"
        >
            <path
                d="M12 0H0L6.19355 6L12 0Z"
                fill={
                    active
                        ? customActiveColor
                            ? customActiveColor
                            : OctupColors.primaryActionableLight
                        : customColor
                          ? customColor
                          : OctupColors.cardsBackground
                }
            />
            <path
                d="M12 0H0L6.19355 6L12 0Z"
                stroke={
                    active
                        ? customActiveColor
                            ? customActiveColor
                            : OctupColors.primaryActionableLight
                        : customColor
                          ? customColor
                          : OctupColors.cardsBackground
                }
            />
        </svg>
    )
}
