export const containerStyle = {
    width: '100%',
    height: '100%',
    position: 'relative',
}

export const deleteIconContainer = {
    position: 'absolute',
    right: 0,
    top: 0,
}
