export const generateGraphMeta = (data, queryField, requestedLabels = [], includeZero) => {
    let labelsArray = []
    if (!data || requestedLabels.length === 0) return []
    for (const label of requestedLabels) {
        const { title, color, field, textColor } = label
        labelsArray.push({
            value: queryField
                ? data.filter((el) => el[`${queryField}`]?.toLowerCase?.() === field).length
                : data[field],
            title: title,
            color: color,
            textColor: textColor,
        })
    }
    return includeZero ? labelsArray : labelsArray.filter((el) => el.value !== 0)
}
