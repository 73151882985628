import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { extractErrMsg } from '../../shared/utils/utils'
import { metricsService } from '../metrics/metricService'
import { SEGMENTS } from '../metrics/metricsSlice'

export const getAlternativeOperationsMetrics = createAsyncThunk(
    'getAlternativeOperationsMetrics',
    async (props, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.userInfo.token
            return metricsService.getMetricsBySegment({
                startDate: props?.startDate,
                endDate: props?.endDate,
                userToken: token,
                segment: SEGMENTS.ALTERNATIVE_HOME_OPERATIONS,
            })
        } catch (e) {
            return thunkAPI.rejectWithValue(extractErrMsg(e))
        }
    }
)

const initialState = {
    alternativeOperationsMetrics: [1, 2, 3],
    isLoading: true,
    isRejected: false,
}

export const alternativeOperationsMetrics = createSlice({
    name: 'alternativeOperationsMetrics',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAlternativeOperationsMetrics.pending, (state) => {
                state.isLoading = true
                state.isRejected = false
            })
            .addCase(getAlternativeOperationsMetrics.fulfilled, (state, action) => {
                state.alternativeOperationsMetrics = action.payload
                state.isLoading = false
            })
            .addCase(getAlternativeOperationsMetrics.rejected, (state) => {
                state.isLoading = false
                state.isRejected = true
            })
    },
})

export const alternativeOperationsMetricsActions = alternativeOperationsMetrics.actions
export const alternativeOperationsMetricsState = (state) => state.alternativeOperationsMetrics
export const alternativeOperationsMetricsSliceReducer = alternativeOperationsMetrics.reducer
