import { getIconColor } from './utils'
export function ActionsBtnLastMileIcon({
    active,
    customActiveColor = '#504B5A',
    customColor = '#504B5A',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.2rem"
            height="1.6rem"
            viewBox="0 0 22 16"
            fill="none"
        >
            <path
                d="M21 6.00002H15.2857V2.42859H19.0878C19.2306 2.42859 19.3701 2.47136 19.4883 2.5514C19.6065 2.63143 19.698 2.74505 19.751 2.8776L21 6.00002Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 8.14288H15.2857"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.3571 14.5714C17.5406 14.5714 18.5 13.612 18.5 12.4286C18.5 11.2451 17.5406 10.2857 16.3571 10.2857C15.1737 10.2857 14.2143 11.2451 14.2143 12.4286C14.2143 13.612 15.1737 14.5714 16.3571 14.5714Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                stroke-miterlimit="10"
            />
            <path
                d="M5.64286 14.5714C6.82632 14.5714 7.78571 13.612 7.78571 12.4286C7.78571 11.2451 6.82632 10.2857 5.64286 10.2857C4.45939 10.2857 3.5 11.2451 3.5 12.4286C3.5 13.612 4.45939 14.5714 5.64286 14.5714Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                stroke-miterlimit="10"
            />
            <path
                d="M14.2143 12.4286H7.78572"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.5 12.4286H1.71429C1.52485 12.4286 1.34316 12.3533 1.20921 12.2194C1.07525 12.0854 1 11.9037 1 11.7143V1.71429C1 1.52485 1.07525 1.34316 1.20921 1.20921C1.34316 1.07526 1.52485 1 1.71429 1H15.2857V10.5728"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.2857 10.5728V6H21V11.7143C21 11.9037 20.9248 12.0854 20.7908 12.2194C20.6568 12.3533 20.4752 12.4286 20.2857 12.4286H18.5"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
