export function JIRAIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M21.1123 11.4403L12.5062 2.83421L11.672 2L5.1936 8.47835L2.23162 11.4403C2.0833 11.5888 2 11.7901 2 12C2 12.2099 2.0833 12.4112 2.23162 12.5597L8.15031 18.4784L11.672 22L18.1503 15.5216L18.2506 15.4213L21.1123 12.5597C21.2606 12.4112 21.3439 12.2099 21.3439 12C21.3439 11.7901 21.2606 11.5888 21.1123 11.4403ZM11.672 14.9567L8.71525 12L11.672 9.04329L14.6287 12L11.672 14.9567Z"
                fill="#2684FF"
            />
            <path
                d="M11.6719 9.04322C10.742 8.11318 10.2179 6.85299 10.2139 5.5378C10.2099 4.22262 10.7265 2.95929 11.6508 2.02368L5.1804 8.49147L8.70204 12.0131L11.6719 9.04322Z"
                fill="url(#paint0_linear_254_9710)"
            />
            <path
                d="M14.6366 11.9922L11.6719 14.9568C12.1346 15.4192 12.5016 15.9682 12.752 16.5724C13.0024 17.1767 13.1313 17.8244 13.1313 18.4785C13.1313 19.1325 13.0024 19.7802 12.752 20.3845C12.5016 20.9887 12.1346 21.5377 11.6719 22.0001L18.1582 15.5138L14.6366 11.9922Z"
                fill="url(#paint1_linear_254_9710)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_254_9710"
                    x1="11.144"
                    y1="6.05219"
                    x2="7.01514"
                    y2="10.181"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.18" stopColor="#0052CC" />
                    <stop offset="1" stopColor="#2684FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_254_9710"
                    x1="12.2369"
                    y1="17.9135"
                    x2="16.3578"
                    y2="13.7926"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.18" stopColor="#0052CC" />
                    <stop offset="1" stopColor="#2684FF" />
                </linearGradient>
            </defs>
        </svg>
    )
}
