import { Grid, TextField } from '@octup/core/ui-kit'
import {
    FormFieldContainer,
    SubmitFormButtonContainer,
} from '@/new_containers/components/__common__'
import { formActionsStyle, formStyle } from './styles'

export const ClientSettingsForm = () => (
    <Grid container alignItems="center" gap={5}>
        <Grid item container sx={formStyle}>
            <FormFieldContainer
                as={TextField}
                name="display_name"
                label="Display Name"
                fullWidth
                hasInlineError
            />
        </Grid>
        <Grid item container sx={formActionsStyle}>
            <SubmitFormButtonContainer />
        </Grid>
    </Grid>
)
