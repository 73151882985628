import { getIconColor } from './utils'

export function ContactUsIconSvg({ active, customActiveColor = '#007F82', customColor = null }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M4.73462 12.9277C4.08213 11.8277 3.85363 10.5275 4.09201 9.27098C4.33039 8.01449 5.01926 6.88825 6.02926 6.10373C7.03926 5.31921 8.30092 4.93038 9.57731 5.01025C10.8537 5.09013 12.0571 5.63321 12.9614 6.53753C13.8657 7.44184 14.4088 8.64517 14.4887 9.92157C14.5685 11.198 14.1797 12.4596 13.3952 13.4696C12.6107 14.4796 11.4844 15.1685 10.228 15.4069C8.97148 15.6453 7.67119 15.4168 6.57126 14.7643L6.57127 14.7643L4.75745 15.2825C4.68241 15.3039 4.60299 15.3049 4.52744 15.2853C4.45189 15.2658 4.38295 15.2263 4.32777 15.1711C4.27258 15.116 4.23315 15.047 4.21358 14.9715C4.194 14.8959 4.19498 14.8165 4.21642 14.7415L4.73466 12.9276L4.73462 12.9277Z"
                stroke={customColor ? customColor : 'white'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.96423 15.4911C9.2224 16.224 9.63989 16.8905 10.1866 17.4426C10.7334 17.9948 11.3958 18.4188 12.1261 18.6841C12.8564 18.9495 13.6365 19.0495 14.4102 18.9771C15.1838 18.9047 15.9318 18.6617 16.6002 18.2654L16.6002 18.2654L18.414 18.7836C18.4891 18.805 18.5685 18.806 18.644 18.7864C18.7196 18.7669 18.7885 18.7274 18.8437 18.6723C18.8989 18.6171 18.9383 18.5481 18.9579 18.4726C18.9775 18.397 18.9765 18.3176 18.9551 18.2426L18.4368 16.4287L18.4369 16.4288C18.8973 15.6522 19.1499 14.7701 19.1701 13.8675C19.1904 12.965 18.9777 12.0724 18.5526 11.2759C18.1275 10.4795 17.5043 9.80605 16.7431 9.32059C15.9819 8.83513 15.1085 8.55403 14.207 8.50443"
                stroke={customColor ? customColor : 'white'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
