import { Grid, Switch } from '@octup/core/ui-kit'
import type { HoursMappingFormType } from '@/models/settings'
import { FormFieldContainer } from '@/new_containers/components/__common__'
import { WorkingHoursFieldContainer } from '@/new_containers/components/Clients'

export type WorkingWeekdaysFieldPropsType = {
    name: string
    fields: HoursMappingFormType[]
}

export const WorkingWeekdaysField = ({ name, fields }: WorkingWeekdaysFieldPropsType) => (
    <>
        {fields.map(({ day }: HoursMappingFormType, index: number) => (
            <Grid key={day.id} container>
                <Grid item xs>
                    <FormFieldContainer
                        name={`${name}.${index}.enabled`}
                        label={day.label}
                        as={Switch}
                    />
                </Grid>
                <Grid item xs={12} md={9}>
                    <WorkingHoursFieldContainer name={`${name}.${index}`} />
                </Grid>
            </Grid>
        ))}
    </>
)
