import { useMemo } from 'react'
import type { DashboardTemplateType } from '@octup/core/models'
import type { DashboardPropsType } from './Dashboard'
import { Dashboard } from './Dashboard'

export type DashboardContainerPropsType = Omit<
    DashboardPropsType,
    'totalHeightUnits' | 'groups'
> & {
    template?: DashboardTemplateType
}

export const DashboardContainer = ({ template, ...props }: DashboardContainerPropsType) => {
    const totalHeightUnits = useMemo(
        () => template?.config?.reduce((acc, { heightUnits }) => acc + heightUnits, 0),
        [template?.config]
    )

    return <Dashboard groups={template?.config} totalHeightUnits={totalHeightUnits} {...props} />
}
