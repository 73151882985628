import { PageTopSide } from '../objectivesPage.styles'
import { CardExpendable } from '../../../shared/ui/Card/CardExpendable'
import {
    METRIC_ITEM_TYPE,
    ObjectivesAndInsightsTopMetricItem,
} from '../../../components/ObjectivesAndInsightsTopMetrics/ObjectivesAndInsightsTopMetricItem'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getStatusData, getObjectivesData, getOriginData } from './ObjectivesTopSection.utils'

export function ObjectivesTopSection() {
    const { objectives, objectiveMetaData, isLoading } = useSelector((state) => state.objectives)
    const statusData = useMemo(() => getStatusData(objectives), [objectives])
    const objectivesData = useMemo(() => getObjectivesData(objectiveMetaData), [objectiveMetaData])
    const originData = useMemo(() => getOriginData(objectiveMetaData), [objectiveMetaData])

    return (
        <PageTopSide>
            <CardExpendable customStyle={{ minHeight: '14rem', overflowX: 'auto' }}>
                <ObjectivesAndInsightsTopMetricItem
                    mainTitle={'Status'}
                    showSeperator={true}
                    type={METRIC_ITEM_TYPE.Doughnut}
                    isLoading={isLoading}
                    baseData={statusData}
                />
                <ObjectivesAndInsightsTopMetricItem
                    mainTitle={`${
                        objectives?.filter(
                            (el) => el.status === 'InProgress' || el.status === 'Overdue'
                        ).length || 0
                    } Active Objectives`}
                    showSeperator={true}
                    type={METRIC_ITEM_TYPE.Doughnut}
                    isLoading={isLoading}
                    baseData={objectivesData}
                />
                <ObjectivesAndInsightsTopMetricItem
                    mainTitle={'Origin'}
                    showSeperator={true}
                    type={METRIC_ITEM_TYPE.Doughnut}
                    isLoading={isLoading}
                    baseData={originData}
                />
            </CardExpendable>
        </PageTopSide>
    )
}
