import type { DashboardTemplateType } from '@octup/core/models'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getTemplatesList } from 'services/dashboards'
import type { ThunkAPIConfigType } from 'services/utils'

type TemplatesListStateType = {
    data?: DashboardTemplateType[]
    isLoading: boolean
    error?: string
    default?: DashboardTemplateType
}

const INITIAL_STATE: TemplatesListStateType = {
    data: undefined,
    isLoading: false,
    error: undefined,
    default: undefined,
}

export const fetchTemplatesList = createAsyncThunk<
    DashboardTemplateType[],
    undefined,
    ThunkAPIConfigType
>('fetchTemplatesList', (_, thunkAPI) => getTemplatesList(thunkAPI))

// NOTE: Temporary default template until we have the ability to choose template
const DEFAULT_TEMPLATE_ID = 'cf61adeb-5ec1-11ef-a2c5-1278c11d0f91'

export const templatesListSlice = createSlice({
    name: 'dashboardTemplatesList',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTemplatesList.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchTemplatesList.fulfilled, (state, action) => {
                state.data = action.payload
                state.default = action.payload.find(
                    (template) => template.id === DEFAULT_TEMPLATE_ID
                )
                state.isLoading = false
            })
            .addCase(fetchTemplatesList.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const templatesListReducer = templatesListSlice.reducer
