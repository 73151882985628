import { useMemo } from 'react'
import { CSVLink } from 'react-csv'
import { useSelector } from 'react-redux'
import { DownloadIconSvg } from 'shared/assets/icons/DownloadIconSvg'
import { ButtonIcon } from 'shared/ui/Buttons/ButtonIcon'
import { getDataToDownload } from './utils'

export const DownloadCostsButtonContainer = () => {
    const { selectedProduct } = useSelector((state) => state.unitEconomics.products)
    const { data, filename } = useMemo(() => getDataToDownload(selectedProduct), [selectedProduct])

    return (
        <CSVLink data={data} filename={filename}>
            <ButtonIcon
                tooltipContent="Download Cost Breakdown"
                customIconColor={'#007f82'}
                mainIcon={DownloadIconSvg}
            />
        </CSVLink>
    )
}
