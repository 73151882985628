export const getStatusData = (objectives) => ({
    data: objectives,
    queryField: 'status',
    includeZero: false,
    requestedLabels: [
        {
            field: 'inprogress',
            title: 'In Progress',
            color: 'rgba(0, 152, 212, 1)',
            textColor: 'inherit',
        },
        {
            field: 'overdue',
            title: 'Overdue',
            color: '#db2059',
            textColor: '#db2059',
        },
        {
            field: 'completed',
            title: 'Completed',
            color: '#078344',
            textColor: 'inherit',
        },
        {
            field: 'dismissed',
            title: 'Deleted',
            color: '#A4A0AA',
            textColor: 'inherit',
        },
    ],
})

export const getObjectivesData = (objectiveMetaData) => ({
    data: objectiveMetaData,
    includeZero: false,
    requestedLabels: [
        {
            field: 'due_in_three_days',
            title: 'Due in 3+ days',
            color: 'rgba(0, 152, 212, 1)',
            textColor: 'inherit',
        },
        {
            field: 'due_in_less_then_three',
            title: 'Due in Less then 3 days',
            color: 'rgba(196, 130, 31, 1)',
            textColor: 'inherit',
        },
        {
            field: 'overdue_objectives',
            title: 'Overdue',
            color: 'rgba(219, 32, 89, 1)',
            textColor: 'inherit',
        },
    ],
})

export const getOriginData = (objectiveMetaData) => ({
    data: objectiveMetaData,
    includeZero: false,
    requestedLabels: [
        {
            field: 'origin_insights',
            title: 'Insight',
            color: 'rgba(0, 168, 168, 1)',
            textColor: 'inherit',
        },
        {
            field: 'origin_simulator',
            title: 'Simulator',
            color: 'rgba(119, 55, 255, 1)',
            textColor: 'inherit',
        },
        {
            field: 'origin_manual',
            title: 'Manual',
            color: 'rgba(80, 75, 90, 1)',
            textColor: 'inherit',
        },
    ],
})
