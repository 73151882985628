import styled from 'styled-components'

export const WhatsNewContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 940px;
    max-width: 940px;
    max-height: 540px;
    min-height: 540px;
    gap: 2rem;
`
