import type { Theme } from '@mui/material'

export const cardStyle = (theme: Theme) => ({
    height: {
        xs: theme.spacing(35),
        lg: '100%',
    },
})

export const cardContentStyle = (theme: Theme) => ({
    height: '100%',
    '&, &:last-child': {
        padding: theme.spacing(2),
    },
})

export const plusCircleIconStyle = (theme: Theme) => ({
    fontSize: theme.spacing(10),
    color: theme.palette.grey['500'],
})
