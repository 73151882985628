export const containerStyle = {
    position: 'relative',
}

export const warningContainerStyle = (theme) => ({
    position: 'absolute',
    top: '50%',
    right: theme.spacing(2),
    marginTop: theme.spacing(1),
    transform: 'translate(-50%, -50%)',
})

export const emailStyle = {
    color: 'primary.main',
    fontWeight: 600,
}

export const warningIconStyle = (theme) => ({
    fontSize: theme.spacing(4),
})
