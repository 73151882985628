import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isMainMenuCollapsed: false,
    isMainMenuCollapsedBtnActive: false,
    activePage:
        window.location.pathname.split('/')[1] === ''
            ? 'home'
            : window.location.pathname.split('/')[1].toLowerCase(),
    activeSubMenu: null,
    activeSubMenuItem: null,
}

const navigationSlice = createSlice({
    name: 'navigation',
    initialState: initialState,
    reducers: {
        setIsMainMenuCollapsedBtnActive(state, action) {
            state.isMainMenuCollapsedBtnActive = !state.isMainMenuCollapsedBtnActive
            if (state.isMainMenuCollapsedBtnActive) state.isMainMenuCollapsed = true
        },
        setIsMainMenuCollapsed(state, action) {
            if (!state.isMainMenuCollapsedBtnActive) return
            if (action.payload !== undefined) {
                state.isMainMenuCollapsed = !state.isMainMenuCollapsed
            } else {
                state.isMainMenuCollapsed = action.payload.val
            }
        },
        setActivePage(state, action) {
            state.activePage = action.payload.activePage.replaceAll(/\s/g, '').toLowerCase()
        },
        setActiveSubMenu(state, action) {
            state.activeSubMenu = action.payload.activeSubMenu?.replaceAll(/\s/g, '')?.toLowerCase()
        },
        setActiveSubMenuItem(state, action) {
            state.activeSubMenuItem = action.payload.activeSubMenuItem
                ?.replaceAll(/\s/g, '')
                ?.toLowerCase()
        },
    },
})

export const navigationSliceActions = navigationSlice.actions
export const navigationState = (state) => state.navigation
export default navigationSlice.reducer
