import React from 'react'

export function MobileUserIcon({
    active,
    disabled = false,
    customActiveColor = '#007F82',
    customColor = '#007F82',
}) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9999 14.7395C14.5504 14.7395 16.6179 12.5593 16.6179 9.86976C16.6179 7.18027 14.5504 5 11.9999 5C9.44945 5 7.3819 7.18027 7.3819 9.86976C7.3819 12.5593 9.44945 14.7395 11.9999 14.7395Z"
                stroke="#F8F7FB"
                strokeWidth="1.5"
                stroke-miterlimit="10"
            />
            <path
                d="M5 19.0001C5.7097 17.7047 6.73017 16.6291 7.9589 15.8812C9.18763 15.1334 10.5813 14.7397 12 14.7397C13.4187 14.7398 14.8124 15.1335 16.0412 15.8813C17.2699 16.6292 18.2903 17.7048 19 19.0002"
                stroke="#F8F7FB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
