import type { MetricsSectionPropsType } from '@octup/core/models'
import { LoadableContent } from '@/new_components'
import { LineChartContainer } from '@/new_containers/components/__common__/MetricsSections'
import { MetricsSectionCard } from '../__common__'

export const LineMetricsSection = ({ data, isLoading, ...props }: MetricsSectionPropsType) => (
    <MetricsSectionCard height={60} minHeight={40} isLoading={isLoading} {...props}>
        <LoadableContent isLoading={isLoading} height="auto">
            <LineChartContainer id={data?.name} rawData={data} />
        </LoadableContent>
    </MetricsSectionCard>
)
