export const addCostButtonStyle = (theme) => ({
    padding: theme.spacing(0.4, 4),
})

export const tableContainerStyle = {
    width: '100%',

    '& .tableHeader': {
        padding: '1rem 1rem 0 0',
    },
}
