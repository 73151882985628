import styled from 'styled-components'
import { OctupAvatar } from '../../../shared/ui/Avatar/OctupAvatar'
import { useSelector } from 'react-redux'
import { ButtonIcon } from '../../../shared/ui/Buttons/ButtonIcon'
import { ChevronRightSvg } from '../../../shared/assets/icons'
import { objectivesActions } from '../../../features/objectives/objectiveSlice'
import { useDispatch } from 'react-redux'

export const ObjectiveDDItem = ({ objectiveId, setShowEditObjectiveModal, index, length }) => {
    const { objectives } = useSelector((state) => state.objectives)
    const dispatch = useDispatch()
    const objective = objectives?.find((objective) => objective.id === objectiveId)
    const STATUS_COLORS = {
        InProgress: {
            color: 'rgba(0, 152, 212, 1)',
            bgColor: 'rgba(0, 152, 212, 0.08)',
        },
        Overdue: {
            color: 'rgba(219, 32, 89, 1)',
            bgColor: 'rgba(252, 79, 109, 0.08)',
        },
        Completed: {
            color: 'rgba(7, 131, 68, 1)',
            bgColor: 'rgba(4, 206, 114, 0.08)',
        },
        Dismissed: {
            color: 'rgba(127, 123, 135, 1)',
            bgColor: 'rgba(235, 234, 238, 1)',
        },
    }
    const statusColors = STATUS_COLORS[objective?.status]

    return (
        <>
            <InnerContainer>
                <ObjectiveDDItemStyled>
                    <ObjectiveStatus color={statusColors?.color} bgColor={statusColors?.bgColor}>
                        {objective?.status && objective.status}
                    </ObjectiveStatus>
                    <ObjectiveTitle isDismmised={objective?.status === 'Dismissed'}>
                        {objective?.title && objective?.title}
                    </ObjectiveTitle>
                    <ObjectiveOwner>
                        {objective?.owner && (
                            <OctupAvatar
                                {...objective?.owner}
                                size={'medium'}
                                title={objective?.owner?.username}
                            />
                        )}
                        {objective?.owner?.username}
                    </ObjectiveOwner>
                </ObjectiveDDItemStyled>
                <ButtonIcon
                    mainIcon={ChevronRightSvg}
                    customStyle={BtnStyle}
                    onClick={() => {
                        dispatch(objectivesActions.setSelectedObjective(objectiveId))
                        setShowEditObjectiveModal({
                            show: true,
                            isEditState: false,
                        })
                    }}
                />
            </InnerContainer>
            {index < length - 1 && <Seperator />}
        </>
    )
}

const ObjectiveDDItemStyled = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
`

const ObjectiveStatus = styled.div`
    color: ${(props) => props.color};
    background-color: ${(props) => props.bgColor};
    border-radius: 1.6rem;
    padding: 0.1rem 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
`

const ObjectiveTitle = styled.div`
    color: ${(p) => (p.isDismmised ? 'rgba(127, 123, 135, 1)' : 'rgba(80, 75, 90, 1)')};
    font-size: 1.3rem;
    line-height: 1.8rem;
`

const ObjectiveOwner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    line-height: 1.8rem;
    gap: 1rem;
    color: rgba(127, 123, 135, 1);
`

const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    cursor: pointer;
    &:hover {
        /* background-color: rgba(241, 240, 245, 1); */
    }
`

const Seperator = styled.div`
    border-bottom: 0.01rem solid rgba(235, 234, 238, 1);
    align-self: center;
    width: 95%;
`

const BtnStyle = {
    padding: '.93rem',
    width: '2.4rem !important',
    height: '2.4rem !important',
    minWidth: '2.4rem !important',
    minHeight: '2.4rem !important',
}
