import { useCallback, useMemo } from 'react'
import { useAbilities, useInterval } from 'hooks'
import { LoadingView } from 'new_components/__common__'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import { fetchIntegrationsStatus } from 'state/features/integrations'
import { APP_ROUTES } from '@/models/appConfig'
import Sidebar from '../Sidebar/Sidebar'
import Header from './Header/Header'
import Main from './Main/Main'
import { BaseOctupToastsContainer } from '../../shared/ui/Toasts/BaseOctupToastsContainer'

const Layout = ({ children, isLoading }) => {
    const dispatch = useDispatch()
    const { isPageAvailable } = useAbilities()
    const isIntegrationsAvailable = useMemo(
        () => isPageAvailable(APP_ROUTES.INTEGRATIONS),
        [isPageAvailable]
    )

    /**
     * NOTE: the fetching integrations status is here as a temporary solution
     * TODO: move it after re-writing the App component
     */
    const getIntegrationsStatus = useCallback(() => {
        dispatch(fetchIntegrationsStatus())
    }, [dispatch])

    useInterval(getIntegrationsStatus, 30 * 60 * 1000, {
        hasInitialCall: true,
        disabled: !isIntegrationsAvailable,
    })

    return (
        <>
            {ReactDOM.createPortal(
                <BaseOctupToastsContainer />,
                document.getElementById('octup-toast-root')
            )}
            <Sidebar />
            {isLoading ? (
                <Main>
                    <LoadingView />
                </Main>
            ) : (
                <>
                    <Header />
                    <Main>{children}</Main>
                </>
            )}
        </>
    )
}

export default Layout
