import React from 'react'
import { getIconColor } from './utils'

export function ChevronLeftSvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            width="0.7rem"
            height="1.2rem"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 11L1 6L6 1"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
