import { getGroupIndexes } from './common'

export const generateLegendsLabels = (chart) =>
    chart.data.datasets.reduce((acc, dataset, index, datasets) => {
        if (dataset.hideLegend) return acc

        const hiddenIndexes = getGroupIndexes({ item: dataset, datasets })
        const isHidden = hiddenIndexes.every((index) => chart.getDatasetMeta(index).hidden)

        return [
            ...acc,
            {
                text: dataset.label,
                fillStyle: dataset.borderColor,
                hidden: isHidden,
                strokeStyle: dataset.borderColor,
                ...(dataset.pointType === 'line' && {
                    pointStyle: 'line',
                    lineWidth: 1,
                    lineDash: dataset.borderDash,
                }),
                rotation: 0,
                borderRadius: 0,
                datasetIndex: index,
            },
        ]
    }, [])
