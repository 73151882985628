import { setHours, setMinutes, setSeconds, getHours, getMinutes } from 'date-fns'
import type { ObjectSchema } from 'yup'
import { array, boolean, date, object, string } from 'yup'
import { ERROR_MESSAGES } from '@/constants'
import { WEEKDAYS, WEEKDAYS_LABELS } from '@/models/settings'
import type { WorkingHoursSettingsFormType, WorkingHoursSettingsType } from '@/models/settings'

const convertDateToNumber = (date: Date): number => {
    const hours = getHours(date)
    const minutes = getMinutes(date)
    const fractionalMinutes = minutes / 60
    return hours + fractionalMinutes
}

const convertNumberToDate = (time: number): Date => {
    const hours = Math.floor(time)
    const minutes = Math.round((time - hours) * 60)
    const baseDate = new Date()
    const dateWithHours = setHours(baseDate, hours)
    const dateWithMinutes = setMinutes(dateWithHours, minutes)
    const dateWithSeconds = setSeconds(dateWithMinutes, 0)
    return dateWithSeconds
}

export const convertToForm = (data?: WorkingHoursSettingsType): WorkingHoursSettingsFormType => ({
    first_day: data?.first_day || WEEKDAYS.MON,
    hours_mapping: (data?.hours_mapping || []).map(({ day, cutoff, time_frames }) => ({
        day: { id: day, label: WEEKDAYS_LABELS[day] },
        cutoff: typeof cutoff === 'number' ? convertNumberToDate(cutoff) : null,
        time_frames: time_frames.map((frame) => frame.map(convertNumberToDate)),
        enabled: !!time_frames.length,
    })),
})

export const convertToAPI = (data: WorkingHoursSettingsFormType): WorkingHoursSettingsType => ({
    first_day: data.first_day,
    hours_mapping: data.hours_mapping.map(({ day, cutoff, time_frames, enabled }) => ({
        day: day.id,
        cutoff: enabled && cutoff ? convertDateToNumber(cutoff) : null,
        time_frames: enabled ? time_frames.map((frame) => frame.map(convertDateToNumber)) : [],
    })),
})

export const DATE_VALIDATION_SCHEMA = date()
    .typeError(ERROR_MESSAGES.INVALID)
    .required(ERROR_MESSAGES.REQUIRED)

export const TIME_FRAME_VALIDATION_SCHEMA = array().of(array().of(DATE_VALIDATION_SCHEMA))

export const HOURS_VALIDATION_SCHEMA = object({
    day: object({
        id: string().oneOf(Object.values(WEEKDAYS)).required(ERROR_MESSAGES.REQUIRED),
        label: string().oneOf(Object.values(WEEKDAYS_LABELS)).required(ERROR_MESSAGES.REQUIRED),
    }),
    cutoff: date()
        .when('enabled', ([enabled]) => (enabled ? DATE_VALIDATION_SCHEMA : date().nullable()))
        .required(ERROR_MESSAGES.REQUIRED),
    time_frames: array()
        .when('enabled', ([enabled]) => (enabled ? TIME_FRAME_VALIDATION_SCHEMA : array()))
        .required(ERROR_MESSAGES.REQUIRED),
    enabled: boolean().required(ERROR_MESSAGES.REQUIRED),
})

export const VALIDATION_SCHEMA: ObjectSchema<WorkingHoursSettingsFormType> = object({
    first_day: string().oneOf(Object.values(WEEKDAYS)).required(ERROR_MESSAGES.REQUIRED),
    hours_mapping: array().of(HOURS_VALIDATION_SCHEMA).required(ERROR_MESSAGES.REQUIRED),
})
