import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCosts } from 'services'

const COSTS_INITIAL_STATE = {
    data: null,
    isLoading: false,
    error: null,
}

export const fetchCosts = createAsyncThunk('fetchCosts', async (_, thunkAPI) => getCosts(thunkAPI))

export const costsSlice = createSlice({
    name: 'costManagerCosts',
    initialState: COSTS_INITIAL_STATE,
    reducers: {
        removeCosts: (state) => {
            state.data = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCosts.pending, (state) => {
                if (!state.data) {
                    state.isLoading = true
                }
            })
            .addCase(fetchCosts.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchCosts.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const { removeCosts } = costsSlice.actions
export const costsReducer = costsSlice.reducer
