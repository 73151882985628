import type { Theme } from '@mui/material'

export const cardStyle = (defaultHeight: number, minHeight?: number) => (theme: Theme) => ({
    height: {
        xs: theme.spacing(defaultHeight),
        lg: '100%',
    },
    minHeight: theme.spacing(minHeight || defaultHeight),
    '& .MuiCardHeader-root': {
        padding: theme.spacing(2, 3, 0),
    },
})
