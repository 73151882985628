import axios from 'axios'
import { GLOBAL_CONFIG } from 'config'
import { generateAPIConfig } from 'services/utils'

export const PAYMENTS_API_URL = GLOBAL_CONFIG.BASE_URL + 'payments'

/**
 * get customer suggested plan data
 */

export const getPaymentPlans = async (thunkAPI) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(`${PAYMENTS_API_URL}/getPlan`, config)

    return response.data
}

/**
 * create payment session
 * @plan mandatory
 * @level mandatory
 * @isAnnual mandatory
 */

export const createPaymentSession = async (data, thunkAPI) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.post(`${PAYMENTS_API_URL}/create_checkout_session`, data, config)

    return response.data
}
