import { createContext, useState, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
// const initialState = {
//   toasts: [],
//   selectedToast: null,
// };

export const TOAST_TYPE = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    INFO: 'INFO',
    UNICORN: 'UNICORN',
    UNDO: 'UNDO',
    PROMPT: 'PROMPT',
    PROCESS: 'PROCESS',
    DELETED: 'DELETED',
}

class Toast {
    id
    constructor(
        type,
        text,
        callback,
        customIcon = null,
        customButtons = null,
        customTimeout = null,
        customStyle = null,
        defaultBtnClickHandler,
        processCompleted = false,
        actionText = null,
        customDisableTimer,
        pages
    ) {
        this.id = uuidv4()
        this.type = type
        this.customIcon = customIcon
        this.customButtons = customButtons
        this.customTimeout = customTimeout
        this.customStyle = customStyle
        this.text = text
        this.callback = callback
        this.defaultBtnClickHandler = defaultBtnClickHandler
        this.processCompleted = processCompleted
        this.processCompleted = processCompleted
        this.actionText = actionText
        this.customDisableTimer = customDisableTimer
        this.pages = pages
    }
}

export const ToastContext = createContext()

export const ToastsContextProvider = ({ children }) => {
    const [toasts, setToasts] = useState([])
    const toDelete = []
    const [selectedToast, setSelectedToast] = useState(null)

    const addToast = useCallback((toast) => {
        const newToast = new Toast(
            toast.type,
            toast.text,
            toast.callback,
            toast.customIcon,
            toast.customButtons,
            toast.customTimeout,
            toast.customStyle,
            toast.defaultBtnClickHandler,
            toast.processCompleted,
            toast.actionText,
            toast.customDisableTimer,
            toast.pages
        )
        setToasts((prevState) => [...prevState, newToast])
        return newToast.id
    }, [])

    const updateToast = useCallback((toastId, updatedFields) => {
        setToast(toastId, updatedFields)
    }, [])

    const setToast = useCallback((toastId, updatedFields) => {
        setToasts((prevState) => {
            prevState.forEach((el) => {
                if (el.id === toastId) {
                    if (updatedFields) {
                        el.processCompleted = updatedFields?.processCompleted
                        el.text = updatedFields?.text
                    }
                }
            })
            return prevState
        })
        setSelectedToast(toastId)
    }, [])

    const removeToast = useCallback((toastId) => {
        toDelete.push(toastId)
        setToasts((prevState) => prevState.filter((el) => el.id !== toastId))
    }, [])

    return (
        <ToastContext.Provider
            value={{
                addToast,
                setToast,
                removeToast,
                updateToast,
                toasts,
                selectedToast,
            }}
        >
            {children}
        </ToastContext.Provider>
    )
}
