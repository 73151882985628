import { UseFieldArrayReturn, useFieldArray, useFormContext } from 'react-hook-form'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormFieldArrayContainerPropsType<T = React.ComponentProps<any>> = T & {
    name: string
    as: React.ComponentType<UseFieldArrayReturn & { name: string } & T>
}

export const FormFieldArrayContainer = ({
    name,
    as: FormField,
    ...props
}: FormFieldArrayContainerPropsType) => {
    const { control } = useFormContext()
    const arrayProps = useFieldArray({ control, name })

    return <FormField name={name} {...props} {...arrayProps} />
}
