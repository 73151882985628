import { useMemo } from 'react'
import type { MetricsSectionPropsType, MetricType } from '@octup/core/models'
import { generateNumberArray } from '@octup/core/utils'
import { TotalMetricsSection } from '@/new_components/__common__/MetricsSections'

const PLACEHOLDER_METRICS = generateNumberArray(3) as unknown as MetricType[]

export const TotalMetricsSectionContainer = ({
    data,
    isLoading,
    ...props
}: MetricsSectionPropsType) => {
    const metrics = useMemo(
        () => (isLoading ? PLACEHOLDER_METRICS : data?.metrics),
        [data?.metrics, isLoading]
    )

    return <TotalMetricsSection metrics={metrics} isLoading={isLoading} {...props} />
}
