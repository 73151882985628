import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GLOBAL_CONFIG } from '../../config'
import { extractErrMsg } from '../../shared/utils/utils'
import { toast } from 'react-toastify'
import insightService from './insightService'
import {
    ActionsBtnLastMileIcon,
    ActionsBtnMarketingIcon,
    ActionsBtnOperationIcon,
    ActionsBtnUnitEconomicsIcon,
} from '../../shared/assets/icons'

export const INSIGHT_API_URL = GLOBAL_CONFIG.BASE_URL + 'insights'

export const INSIGHTS_SEGMENTS_ALIAS = {
    LAST_MILE: 'Last Mile',
    OPERATIONS: 'Operations',
    ONLINE_STORE: 'Online Store',
    MARKETING: 'Marketing',
    MANUAL: 'Manual',
    UNIT_ECONOMICS: 'Unit Economics',
}

export const INSIGHTS_SEGMENTS_ICONS = {
    LAST_MILE: ActionsBtnLastMileIcon,
    OPERATIONS: ActionsBtnOperationIcon,
    ONLINE_STORE: ActionsBtnOperationIcon,
    MARKETING: ActionsBtnMarketingIcon,
    MANUAL: null,
    UNIT_ECONOMICS: ActionsBtnUnitEconomicsIcon,
}

export const INSIGHTS_STATUS = {
    NEW: 0,
    DISMISSED: 1,
    READ: 2,
    ASSIGNED: 3,
    OBJECTIVE_CREATED: 3,
}

const initialState = {
    insights: [],
    users: {},
    insight: null,
    isLoading: false,
    isLoadingUpdateInsight: false,
    isRejected: false,
}

//create insight
export const createInsight = createAsyncThunk(
    `${INSIGHT_API_URL}/create`,
    async (insightData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.userInfo.token
            return await insightService.createInsight(insightData, token)
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

//get insights
export const getInsights = createAsyncThunk(`${INSIGHT_API_URL}/`, async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.userInfo.token
        return await insightService.getInsights(token)
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

export const getInsight = createAsyncThunk(`${INSIGHT_API_URL}/`, async (insightId, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.userInfo.token
        return await insightService.getInsight(insightId, token)
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

export const updateInsightLike = createAsyncThunk(
    `${INSIGHT_API_URL}/ul`,
    async (payload, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.userInfo.token
            return await insightService.updateInsightLike(payload.data, token)
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

//update insight
export const updateInsight = createAsyncThunk(
    `${INSIGHT_API_URL}/update`,
    async (insightId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.userInfo.token
            return await insightService.updateInsight(insightId, token)
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

//update insight
export const updateInsightStatus = createAsyncThunk(
    `${INSIGHT_API_URL}/us`,
    async (props, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.userInfo.token
            return await insightService.updateInsightStatus(props.data, token)
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

//dismiss insight
export const dismissInsight = createAsyncThunk(
    `${INSIGHT_API_URL}/dismiss`,
    async (props, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.userInfo.token
            return await insightService.dismissInsight(props.data, token)
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

export const insightSlice = createSlice({
    name: 'insights',
    initialState,
    reducers: {
        setSelectedInsight: (state, action) => {
            state.insight = state.insights.find((el) => el.id === action.payload)
        },
        addUsersData: (state, action) => {
            // adding users to application
            state.users = action.payload
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getInsights.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getInsights.fulfilled, (state, action) => {
                state.isLoading = false
                if (!action.payload) return
                state.insights = action.payload.insights.map((el) => {
                    return {
                        ...el,
                        type: el.is_actionable === 1 ? 'Actionable' : 'Informative',
                        segment: INSIGHTS_SEGMENTS_ALIAS[`${el.segment}`] || 'Unknown',
                        importance: el.importance?.toLowerCase(),
                    }
                })
            })
            .addCase(getInsights.rejected, (state) => {
                state.isRejected = true
                state.isLoading = false
            })
            .addCase(updateInsightLike.pending, (state, param) => {
                // state.isLoading = true;
            })
            .addCase(updateInsightLike.fulfilled, (state, param) => {
                const reqArgs = param.meta.arg
                state.isLoading = false
                if (state.insight?.id === reqArgs?.data?.insightId) {
                    state.insight.isLike = !state.insight.isLike
                }
                state.insights[
                    state.insights.findIndex((el) => el.id === reqArgs.data.insightId)
                ].isLike = !reqArgs.data.isLike
                // reqArgs.toasts.success("Insight updated");
            })
            .addCase(updateInsightLike.rejected, (state, action) => {
                const reqArgs = action.meta.arg
                reqArgs.toasts.error('Unable to update insight')
            })
            .addCase(updateInsightStatus.pending, (state) => {
                state.isLoadingUpdateInsight = true
            })
            .addCase(updateInsightStatus.fulfilled, (state, action) => {
                const reqArgs = action.meta.arg

                state.isLoadingUpdateInsight = false

                if (state.insight.id === reqArgs.data.insightId) {
                    state.insight.status = reqArgs.data.status
                }
                state.insights[
                    state.insights.findIndex((el) => el.id === reqArgs.data.insightId)
                ].status = reqArgs.data.status
                if (reqArgs.data.status === 2) {
                    return
                }
                if (reqArgs.data.status === 1) reqArgs.toasts.delete('Insight has been dismissed')
                else reqArgs.toasts.success('Insight has been updated')
            })
            .addCase(updateInsightStatus.rejected, (state, action) => {
                const reqArgs = action.meta.arg
                state.isLoadingUpdateInsight = false
                state.isRejected = true
                reqArgs.toasts.error('Unable to update insight status')
            })
            .addCase(dismissInsight.pending, (state) => {
                state.isLoadingUpdateInsight = true
            })
            .addCase(dismissInsight.fulfilled, (state, action) => {
                const reqArgs = action.meta.arg

                if (!action.payload) return

                state.insights = state.insights.map((currentInsight) => {
                    if (currentInsight.id === reqArgs.data.insightId) {
                        const updatedInsight = action.payload.find(
                            ({ id }) => id === currentInsight.id
                        )

                        return {
                            ...currentInsight,
                            status: updatedInsight.status,
                            vstatus: updatedInsight.vstatus,
                        }
                    }

                    return currentInsight
                })

                state.isLoadingUpdateInsight = false
                reqArgs.toasts.delete('Insight has been dismissed')
            })
            .addCase(dismissInsight.rejected, (state, action) => {
                const reqArgs = action.meta.arg
                state.isLoadingUpdateInsight = false
                state.isRejected = true
                reqArgs.toasts.error('Unable to dismiss insight')
            })
    },
})

export default insightSlice.reducer
export const insightsActions = insightSlice.actions
export const insightsState = (state) => state.insights
export const insightsSliceReducer = insightSlice.reducer
