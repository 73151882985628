export const profitCardContainerStyle = {
    height: '20%',
    paddingBottom: 5,
    '@media (max-height: 800px)': {
        height: '25%',
    },
}

export const tableCardContainerStyle = {
    height: '80%',
    '@media (max-height: 800px)': {
        height: '75%',
    },
    '& .tableHeader': {
        padding: 2,
        paddingBottom: 0,
    },
    '& th': {
        paddingRight: 2,
        '&:first-of-type': {
            width: '20%',
        },
    },
}
