export function UploadFileIconSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.8rem"
            height="1.9rem"
            viewBox="0 0 18 19"
            fill="none"
        >
            <path
                d="M10.05 14.2955H4.94998C4.89089 14.2955 4.83237 14.2842 4.77778 14.2622C4.72318 14.2402 4.67358 14.208 4.63179 14.1673C4.59001 14.1267 4.55686 14.0785 4.53425 14.0254C4.51164 13.9723 4.5 13.9154 4.5 13.858V6.85797L7.64985 3.79547H13.05C13.1694 3.79547 13.2838 3.84156 13.3682 3.92361C13.4526 4.00566 13.5 4.11694 13.5 4.23297V7.10797"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.25 3.79547V4.79547C8.25 5.90004 7.35457 6.79547 6.25 6.79547H5.25"
                fill="#F8F7FB"
            />
            <path
                d="M8.25 3.79547V4.79547C8.25 5.90004 7.35457 6.79547 6.25 6.79547H5.25"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5 9.79547L13.5 14.2955"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.25 12.0455L13.5 9.79547"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.75 12.0455L13.5 9.79547"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
