import { useToast } from 'hooks'
import { useCallback } from 'react'
import { CopySvg } from 'shared/assets/icons'
import { ButtonIcon } from 'shared/ui/Buttons/ButtonIcon'

export const CopyToClipboardButtonContainer = ({ content, ...props }) => {
    const toast = useToast()

    const handleClick = useCallback(() => {
        navigator.clipboard.writeText(content)
        toast.success(`'${content}' copied to clipboard`)
    }, [content, toast])

    return <ButtonIcon mainIcon={CopySvg} onClick={handleClick} {...props} />
}
