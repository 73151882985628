import type { CustomTableMetricsSectionType } from '@octup/core/models'
import { VALUE_UNIT_TYPES } from '@octup/core/models'
import type { DataGridPropsType, GridColDefType } from '@octup/core/ui-kit'
import { formatNumber } from '@octup/core/utils'
import { getInputDate } from '@/shared/utils/dates-util'

export const getTableConfig = (tableData?: CustomTableMetricsSectionType): DataGridPropsType => {
    const { config, data } = tableData || {}
    const { columns_config = [] } = config || {}
    const { data: rows = [], metadata: rowsMetadata } = data || {}

    const columns: GridColDefType<typeof rows>[] = columns_config.map((column) => ({
        field: column.name,
        headerName: column.display_name,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (value: number | string | null) => {
            if (column.value_units === VALUE_UNIT_TYPES.STRING) return value
            if (column.value_units === VALUE_UNIT_TYPES.DATE) return getInputDate(value)

            return formatNumber({
                value: value as number,
                valueUnits: column.value_units,
                currency: column.currency,
            })
        },
    }))

    return {
        columns: columns,
        rows: rows,
        hideFooter: true,
        hideFooterPagination: true,
        paginationMode: 'server',
        rowCount: rowsMetadata?.total_rows,
    }
}
