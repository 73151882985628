import { Grid } from '@mui/material'
import { CardsShimmer } from '../../../../components/CardShimmer/CardsShimmer'
import { MetricItemWithChart } from '../../../../components/Metrics/MetricItem/MetricItemWithChart'

export function ROASMetrics({ roasMetrics, isLoading }) {
    return (
        <Grid container>
            {!isLoading ? (
                roasMetrics?.map((el, index) => (
                    <Grid key={el.id} item container xs={4} minHeight="15rem" pt={3}>
                        <MetricItemWithChart
                            enableDeepdive={false}
                            hasChart={false}
                            title={el.metricTitle}
                            description={el.description}
                            value={el.value}
                            isPositiveTrend={el.isPositiveTrend}
                            valueUnits={el.valueUnits}
                            currency={el.currency}
                            trendValue={el.trendValue}
                            trendUnits={el.trendUnits}
                            sources={el.sources}
                            showSeperator={(index + 1) % 3 !== 0}
                            isSupported={el.supported}
                            isStale={el?.stale}
                            lastUpdatedAt={el?.last_updated_date}
                            customStyle={{ justifyContent: 'flex-start' }}
                        />
                    </Grid>
                ))
            ) : (
                <CardsShimmer quantity={3} width="30rem" height="8rem" />
            )}
        </Grid>
    )
}
