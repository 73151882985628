import { getIconColor } from './utils'
export function ActionsBtnPricingIcon({
    active,
    customActiveColor = '#504B5A',
    customColor = '#504B5A',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M12 15.4352C14.9148 15.4352 17.2777 13.0992 17.2777 10.2176C17.2777 7.336 14.9148 5 12 5C9.08515 5 6.72223 7.336 6.72223 10.2176C6.72223 13.0992 9.08515 15.4352 12 15.4352Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                stroke-miterlimit="10"
            />
            <path
                d="M4 19.9998C4.81109 18.6119 5.97734 17.4594 7.3816 16.6582C8.78587 15.857 10.3787 15.4352 12 15.4352C13.6214 15.4352 15.2142 15.857 16.6185 16.6583C18.0227 17.4596 19.1889 18.6121 20 20"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
