import { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OctupBaseTable } from '@/shared/ui/Tables/OctupBaseTable'
import { selectProduct } from '@/state/features/unitEconomics'
import { getTableConfig } from './utils'

export const ProductsTableContainer = () => {
    const dispatch = useDispatch()
    const { invoicesDates, products } = useSelector((state) => state.unitEconomics)
    const tableConfig = useMemo(() => getTableConfig(products.data), [products.data])

    const isLoading = useMemo(
        () => invoicesDates.isLoading || !products.data || products.isLoading,
        [invoicesDates.isLoading, products.data, products.isLoading]
    )

    const handleSelectRowData = useCallback(
        (rowData) => dispatch(selectProduct(rowData)),
        [dispatch]
    )

    return (
        <OctupBaseTable
            showDownloadCSV
            hiddenRowKey="id"
            tableName="Products"
            enableSubRowSelection={true}
            isRowsLoading={isLoading}
            customHeight="94%"
            isRowSelectable={true}
            setSelectedRowData={handleSelectRowData}
            showSearchFilter={true}
            isSubRowClickable
            tooltipPath="tooltips.unit_economics"
            subRowField="variants"
            {...tableConfig}
        />
    )
}
