import Dropdown from '../Dropdown/Dropdown'
import styled from 'styled-components'

const getNestedValue = (el, props) => {
    return props.reduce(
        (previousValue, currentValue, currentIndex) => previousValue[props[currentIndex]],
        el
    )
}

export const OctupTableFilters = ({
    tableRowsData = [],
    tableRawData = [],
    handleFilterSelection,
    activeFilter,
    activeFilters,
    requestedFilters = [
        {
            title: '',
            dataPropsMapping: [''],
            type: '', // "dropdown","search",
            dropdownConfig: {
                title: 'notitle',
                deafultInitialValue: null,
                titlePostfix: ': All',
                id: '',
                name: '',
                droplistPosition: 'bottom',
                enableTextCapitaliztion: true,
                isMultiSelection: true,
                showOptionsImages: false,
                isAvatarDropdown: false,
                showEmpty: true,
                showCounter: true,
                showClearFilters: false,
                isCustomDropdown: false,
                customTitle: null,
            },
        },
    ],
}) => {
    const filters = []
    requestedFilters &&
        requestedFilters.forEach((filterEl) => {
            let data = [...tableRawData]
            for (const key in activeFilters) {
                data = data.filter((el) =>
                    key !== filterEl.title &&
                    getNestedValue(el, activeFilters[key].dataMapping) &&
                    activeFilters[key].selectedOptions.length > 0
                        ? activeFilters[key].selectedOptions.includes(
                              getNestedValue(el, activeFilters[key].dataMapping).toLowerCase()
                          )
                        : getNestedValue(el, activeFilters[key].dataMapping)
                )
            }

            // const options = dataset.map((el) => {
            //   let tData = el;
            //   for (const prop of filterEl.dataPropsMapping) {
            //     tData = tData[prop];
            //     return tData;
            //   }
            // });

            // const opt =  Array.from(new Set(options)).map(el => { return { title: el }  })

            const options = data.map((el) => {
                let tData = el
                for (const prop of filterEl.dataPropsMapping) {
                    tData = tData[prop]
                }
                return tData ? tData : ''
            })

            filters.push(
                <Dropdown
                    key={filterEl.title}
                    isFilter={true}
                    title={filterEl.title}
                    requestedFiltersDataMapping={filterEl.dataPropsMapping}
                    {...filterEl.dropdownConfig}
                    options={Array.from(new Set(options)).map((el) => {
                        return { name: el, value: '' }
                    })}
                    onClickCallback={(data) => {
                        handleFilterSelection(data)
                    }}
                />
            )
        })

    return <FiltersContainer>{filters}</FiltersContainer>
}

const FiltersContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0rem;
    width: 100%;
    height: fit-content;
    padding: 2rem 0;
    flex-wrap: wrap;
`
