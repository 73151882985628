import { Stack, Typography } from '@mui/material'
import { FILTERS } from './LogisticsFilters.utils'

export const LogisticsFilters = () => (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 13 }}>
        {FILTERS.map(({ id, name, component: Component }) => (
            <Stack key={id} direction="row" alignItems="center" spacing={2}>
                <Typography>{name}:</Typography>
                <Component />
            </Stack>
        ))}
    </Stack>
)
