import { AUTH_API_URL, getClientInfo, updateUserProfile } from '../../features/auth/authSlice'
import { isNameValid } from '../../shared/ui/Inputs/inputUtils'
import { authService } from '../../features/auth/authService'
import {
    containsLowerCase,
    containsNumber,
    containsSpecialChars,
    containsUppercase,
} from '../../shared/utils/strings-util'
import { isPasswordStrong } from '../../shared/utils/utils'

export const InitialSignup = async ({
    isTermsAgreed,
    email,
    password,
    utmData,
    confirmPassword,
    setShowTermsAgreedError,
    setEmailRelatedError,
    setPasswordRelatedError,
    setConfirmPasswordRelatedError,
    emailRelatedError,
    passwordRelatedError,
    isEmailNew,
    setToken,
    setCurrentStep,
    dispatch,
}) => {
    if (!isTermsAgreed) {
        setShowTermsAgreedError(true)
    }
    if (email === '') {
        setEmailRelatedError('required')
    }
    if (password === '') {
        setPasswordRelatedError('required')
    }
    if (confirmPassword === '') {
        setConfirmPasswordRelatedError('required')
    } else {
        if (confirmPassword !== password) {
            setConfirmPasswordRelatedError('passwordsDontMatch')
        } else if (!isPasswordStrong(password)) {
            setPasswordRelatedError('weakPassword')
        } else {
            setConfirmPasswordRelatedError(null)
        }
    }
    if (
        !emailRelatedError &&
        !passwordRelatedError &&
        isEmailNew &&
        isPasswordStrong(confirmPassword) &&
        password === confirmPassword &&
        isTermsAgreed
    ) {
        const response = await dispatch.signup({ email, password, utmData })
        if (response?.payload?.oc_s) {
            setCurrentStep(response?.payload?.oc_s)
        } else if (response?.payload?.token) {
            setToken(response?.payload?.token)
            setCurrentStep(1)
        }
    }
}

export const setUserName = async ({
    firstName,
    lastName,
    setFirstNameRelatedError,
    setLastNameRelatedError,
    token,
    setCurrentStep,
    dispatch,
}) => {
    if (firstName === '') {
        setFirstNameRelatedError('required')
    } else {
        if (!isNameValid(firstName)) {
            setFirstNameRelatedError('invalidName')
        }
    }
    if (lastName === '') {
        setLastNameRelatedError('required')
    } else {
        if (!isNameValid(lastName)) {
            setLastNameRelatedError('invalidName')
        }
    }
    if (isNameValid(firstName) && isNameValid(lastName)) {
        const user = await dispatch(
            updateUserProfile({
                fullName: firstName + ' ' + lastName,
                token,
                isSignup: true,
            })
        )
        const client = await dispatch(getClientInfo(token))
        localStorage.setItem(
            'userInfo',
            JSON.stringify({
                ...user?.payload,
                token: token,
                isStoreConnected: true,
                client: client?.payload,
            })
        )
        setCurrentStep((prev) => prev + 1)
    }
}

export const connectToShopify = async ({ email, storeName, token }) => {
    const storeNameLowerCase = storeName ? storeName.toLowerCase() : storeName
    const storeLink = await authService.sendStoreName({
        storeName: storeNameLowerCase,
        email,
        token,
    })
    window.location.replace(storeLink?.link)
}

export const connectToPlatform = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    userInfo.isActive = true
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    window.location.replace(`${AUTH_API_URL}/login/validate`)
}

export const evaluatePasswordRequirements = (password) => {
    return [
        {
            key: 0,
            message: 'At lease 8 characters',
            condition: password?.length > 7,
        },
        {
            key: 1,
            message: 'At least one uppercase',
            condition: containsUppercase(password),
        },
        {
            key: 2,
            message: 'At least one lowercase',
            condition: containsLowerCase(password),
        },
        {
            key: 3,
            message: 'At lease one number',
            condition: containsNumber(password),
        },
        {
            key: 4,
            message: 'At least one special character',
            condition: containsSpecialChars(password),
        },
    ]
}

export const isPasswordMeetsRequirements = (password) => {
    const requirements = evaluatePasswordRequirements(password)
    let res = false
    if (requirements) {
        res = true
        requirements.forEach((requirement) => {
            if (!requirement.condition) {
                res = false
            }
        })
    }
    return res
}

export function checkUserSource() {
    const urlParams = new URLSearchParams(window.location.search)
    const utm_source = urlParams.get('utm_source') || ''
    const utm_medium = urlParams.get('utm_medium') || ''
    const utm_campaign = urlParams.get('utm_campaign') || ''
    const source = urlParams.get('source') || ''
    const referring_domain = urlParams.get('referring_domain') || ''
    const gclid = urlParams.get('gclid') || ''

    let hs_analytics_source = 'OFFLINE'

    if (
        (utm_medium === 'social' && ['facebook', 'twitter', 'instagram'].includes(utm_source)) ||
        (utm_medium === 'social' && referring_domain.includes('social'))
    ) {
        hs_analytics_source = 'SOCIAL_MEDIA'
    } else if (source.includes('adword') || source.includes('ppc') || source.includes('cpc')) {
        hs_analytics_source = 'PAID_SEARCH'
    } else if (gclid !== '') {
        hs_analytics_source = 'PAID_SEARCH'
    } else if (
        (utm_medium.includes('paid') || utm_medium.includes('ppc') || utm_medium.includes('cpc')) &&
        (['facebook', 'twitter', 'instagram'].includes(utm_source) ||
            referring_domain.includes('social') ||
            utm_medium.includes('paidsocial'))
    ) {
        hs_analytics_source = 'PAID_SOCIAL'
    } else if (
        (utm_source.includes('google') ||
            utm_medium.includes('google') ||
            utm_campaign.includes('google')) &&
        referring_domain === 'google.com'
    ) {
        hs_analytics_source = 'PAID_SEARCH'
    } else if (
        (utm_source.includes('adword') ||
            utm_medium.includes('adword') ||
            utm_campaign.includes('adword') ||
            source.includes('adword') ||
            utm_source.includes('ppc') ||
            utm_medium.includes('ppc') ||
            utm_campaign.includes('ppc') ||
            source.includes('ppc') ||
            utm_source.includes('cpc') ||
            utm_medium.includes('cpc') ||
            utm_campaign.includes('cpc')) &&
        !source.includes('email')
    ) {
        hs_analytics_source = 'PAID_SEARCH'
    } else if (
        utm_source.includes('email') ||
        utm_medium.includes('email') ||
        utm_campaign.includes('email') ||
        source.includes('email')
    ) {
        hs_analytics_source = 'EMAIL_MARKETING'
    } else if (
        referring_domain === '' &&
        !utm_source &&
        !utm_medium &&
        !utm_campaign &&
        !source &&
        !gclid
    ) {
        hs_analytics_source = 'DIRECT_TRAFFIC'
    } else if (
        referring_domain !== '' &&
        !['facebook.com', 'twitter.com', 'instagram.com', 'google.com'].some((domain) =>
            referring_domain.includes(domain)
        )
    ) {
        hs_analytics_source = 'REFERRALS'
    } else if (
        !(
            utm_source ||
            utm_medium ||
            utm_campaign ||
            source ||
            source.includes('email') ||
            source.includes('adword') ||
            source.includes('ppc') ||
            source.includes('cpc')
        )
    ) {
        hs_analytics_source = 'OTHER_CAMPAIGNS'
    }

    return hs_analytics_source
}
