// TODO: support small size

import type { Components, Theme } from '@mui/material'

type AutocompleteOverridesType = Pick<Components<Theme>, 'MuiAutocomplete'>

export const AUTOCOMPLETE_OVERRIDES: AutocompleteOverridesType = {
    MuiAutocomplete: {
        styleOverrides: {
            root: ({ theme }) => ({
                '& .MuiAutocomplete-tag': {
                    maxWidth: '50%',
                },
                '& .MuiChip-root': {
                    height: theme.spacing(7),
                    '& .MuiChip-label': {
                        padding: theme.spacing(0, 2),
                    },
                },
            }),
            listbox: ({ theme }) => ({
                '& .MuiAutocomplete-option': {
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                    '&[aria-selected="true"]': {
                        background: 'none',
                        '&.Mui-focused': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    },
                },
            }),
            paper: ({ theme }) => ({
                borderRadius: theme.spacing(2),
                boxShadow: theme.shadows[5],
            }),
            popper: {
                // Note: important here to override inline styles
                marginTop: '0.1rem !important',
                // TODO: remove zIndex after adding a Dialog from MUI
                zIndex: 1500,
            },
        },
    },
}
