import type { SyntheticEvent } from 'react'
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import type {
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    AutocompleteInputChangeReason,
} from '@mui/material'
import { Autocomplete as MuiAutocomplete, SvgIcon } from '@mui/material'
import { ArrowIcon, ClearIcon } from '@octup/core/assets/icons'
import {
    SELECT_ALL_OPTION,
    checkIsAllOption,
    checkIsAllSelected,
    getOptions,
    renderOption,
    renderTags,
} from './Autocomplete.utils'
import type { InputPropsType } from './Input'
import { Input } from './Input'
import type {
    AutocompleteOptionType,
    AutocompleteValueType,
    AutocompleteType,
    AutocompleteOptionsType,
} from './models'
import { clearIconStyle, popupIconStyle } from './styles'

type AutocompletePropsType = AutocompleteType & {
    value?: AutocompleteValueType
    options: AutocompleteOptionsType
    onChange: (value: AutocompleteValueType) => void
    selectAllLabel?: string
    getOptionImage?: (option: AutocompleteOptionType) => string
    inputProps?: Partial<InputPropsType>
    error: boolean
}

const DEFAULT_SLOT_PROPS = {
    clearIndicator: { disableRipple: true },
    popupIndicator: { disableRipple: true },
}

export const Autocomplete = forwardRef(
    (
        {
            value = [],
            onChange,
            loading,
            options = [],
            multiple,
            selectAllLabel = SELECT_ALL_OPTION.label,
            getOptionLabel: getCustomOptionLabel,
            getOptionKey: getCustomOptionKey,
            getOptionImage,
            inputProps,
            error,
            ...props
        }: AutocompletePropsType,
        ref: React.Ref<HTMLDivElement>
    ) => {
        const [inputValue, setInputValue] = useState('')
        const defaultValue = useMemo(
            () => (multiple ? [] : null) as AutocompleteValueType,
            [multiple]
        )
        const [selectedValue, setSelectedValue] = useState<AutocompleteValueType>(defaultValue)

        useEffect(() => {
            if (options.length && multiple) {
                const isAllOption = checkIsAllOption((value as AutocompleteOptionsType)?.[0])
                if (isAllOption) {
                    setSelectedValue(options)
                    onChange?.(options)
                } else {
                    setSelectedValue(value)
                }
            }
        }, [multiple, onChange, options, selectedValue, value])

        const handleInputChange = useCallback(
            (
                event: React.SyntheticEvent<Element, Event>,
                value: string,
                reason: AutocompleteInputChangeReason
            ) => {
                if (event?.type === 'blur') return setInputValue('')
                if (!multiple || reason !== 'reset') return setInputValue(value)
            },
            [multiple]
        )

        const handleChange = useCallback(
            (
                _: SyntheticEvent<Element, Event>,
                newValue:
                    | NonNullable<string | AutocompleteOptionType>
                    | (string | AutocompleteOptionType)[]
                    | null,
                __: AutocompleteChangeReason,
                details?: AutocompleteChangeDetails<AutocompleteValueType>
            ) => {
                let newSelectedValue = newValue as AutocompleteValueType
                const isAllOption =
                    details && checkIsAllOption(details.option as AutocompleteOptionType)

                if (isAllOption) {
                    const isAllSelected = checkIsAllSelected(
                        options,
                        newValue as AutocompleteOptionsType
                    )
                    newSelectedValue = isAllSelected ? [] : options
                }

                onChange?.(newSelectedValue)
                setSelectedValue(newSelectedValue)
            },
            [onChange, options]
        )

        const currentOptions = useMemo(
            () => getOptions({ multiple, loading, options, selectAllLabel }),
            [loading, multiple, options, selectAllLabel]
        )

        const getOptionKey = useCallback(
            (option: string | AutocompleteOptionType) =>
                getCustomOptionKey?.(option) || (option as AutocompleteOptionType).id,
            [getCustomOptionKey]
        )

        const getOptionLabel = useCallback(
            (option: string | AutocompleteOptionType) =>
                getCustomOptionLabel?.(option) || (option as AutocompleteOptionType).label || '',
            [getCustomOptionLabel]
        )

        const renderInput = useCallback(
            (params: Partial<InputPropsType>) => (
                <Input loading={loading} error={error} {...params} {...inputProps} />
            ),
            [error, inputProps, loading]
        )

        const slotProps = useMemo(
            () => ({ ...DEFAULT_SLOT_PROPS, option: { getOptionImage } }),
            [getOptionImage]
        )

        const isOptionEqualToValue = useCallback(
            (option: AutocompleteOptionType, value: AutocompleteOptionType) =>
                getOptionKey(option) === getOptionKey(value),
            [getOptionKey]
        )

        return (
            <MuiAutocomplete
                ref={ref}
                value={selectedValue}
                inputValue={inputValue}
                onChange={handleChange}
                onInputChange={handleInputChange}
                multiple={multiple}
                loading={loading}
                options={currentOptions}
                renderInput={renderInput}
                isOptionEqualToValue={isOptionEqualToValue}
                popupIcon={<SvgIcon component={ArrowIcon} color="secondary" sx={popupIconStyle} />}
                clearIcon={<SvgIcon component={ClearIcon} sx={clearIconStyle} />}
                getOptionKey={getOptionKey}
                getOptionLabel={getOptionLabel}
                renderTags={renderTags}
                renderOption={renderOption}
                slotProps={slotProps}
                {...props}
            />
        )
    }
)
