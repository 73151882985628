import type { ObjectSchema } from 'yup'
import { string, object, number } from 'yup'
import { ERROR_MESSAGES } from '@/constants'
import type { ClientSettingsType } from '@/models/settings'

export const getDefaultValues = (data: ClientSettingsType): ClientSettingsType => ({
    id: data.id,
    name: data.name,
    display_name: data.display_name || data.name,
})

export const VALIDATION_SCHEMA: ObjectSchema<Partial<ClientSettingsType>> = object({
    id: number(),
    name: string(),
    display_name: string().required(ERROR_MESSAGES.REQUIRED),
})
