import styled from 'styled-components'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { Row } from '../../shared/styledComponents'
import { PricingPlanCard } from './PricingPlanCard'
import React, { useCallback, useEffect, useState } from 'react'
import { MajesticSvg } from '../../shared/assets/icons/MajesticSvg'
import { BreezeSvg } from '../../shared/assets/icons/BreezeSvg'
import { RadioTabsSelectors } from '../../shared/ui/RadioTabsSelector/RadioTabsSelectors'
import { CustomPlanModal } from './CustomPlanModal'
import { useOctupDispatch } from '../../hooks'
import { useSelector } from 'react-redux'

const FIRST_SLIDER_LIMIT = 500000
const SECOND_SLIDER_LIMIT = 3000000
const THIRD_SLIDER_LIMIT = 10000000
const FOURTH_SLIDER_LIMIT = 30000000

export const getPricingKeyFromSliderValue = (value) => {
    if (value <= FIRST_SLIDER_LIMIT) {
        return '1'
    } else if (value <= SECOND_SLIDER_LIMIT) {
        return '2'
    } else if (value <= THIRD_SLIDER_LIMIT) {
        return '3'
    } else if (value <= FOURTH_SLIDER_LIMIT) {
        return '4'
    } else {
        return '5'
    }
}

//TODO: Refactor plans (restructure obj, move to util, improve component structure )
const plans = [
    {
        id: 1,
        title: 'Breeze',
        subtitle: 'Basic essential features only',
        annualPriceSet1: '$83',
        annualPriceSet2: '$330',
        annualPriceSet3: '$415',
        annualPriceSet4: '$499',
        annualPriceSet5: 'Custom',
        monthlyPriceSet1: '$99',
        monthlyPriceSet2: '$399',
        monthlyPriceSet3: '$499',
        monthlyPriceSet4: '$599',
        monthlyPriceSet5: 'Custom',
        monthPrice: '$99',
        crossedPrice: '$99',
        annualPrice: '$99',
        annualPriceSet1_id: 'price_1OQq7mJM7mNNpHqorZwJp0sa',
        annualPriceSet2_id: 'price_1OQqECJM7mNNpHqoCL2pbIDw',
        annualPriceSet3_id: 'price_1OQqOmJM7mNNpHqoGpo6kQxj',
        annualPriceSet4_id: 'price_1OQqTAJM7mNNpHqo2fexNRp9',
        monthlyPriceSet1_id: 'price_1OQq7mJM7mNNpHqorSEWoItE',
        monthlyPriceSet2_id: 'price_1OQqECJM7mNNpHqoH9MpE4ZI',
        monthlyPriceSet3_id: 'price_1OQqOPJM7mNNpHqoo1IyimPM',
        monthlyPriceSet4_id: 'price_1OQqTAJM7mNNpHqoMj2SzSfI',
        annualPriceId: 'price_1OJuQPJM7mNNpHqoXhiyl13l',
        monthlyPriceId: 'price_1OJuQnJM7mNNpHqoffaPulue',
        crossedAnnualPrice: '499$',
        buttonColor: OctupColors.primaryTextLight,
        icon: <BreezeSvg />,
        features: [
            'All available integrations',
            'Unlimited Users',
            'All dashboards',
            'Unit Economics',
            'Cost Manager',
            'Insights & Objectives',
            'Simulator',
            '24/7 Support',
            'GDPR Compliant',
        ],
    },
    {
        id: 2,
        tag: 'POPULAR!',
        title: 'Majestic',
        subtitle: 'Unleash the ultimate Octup powers!',
        annualPriceSet1: '$83',
        annualPriceSet2: '$499',
        annualPriceSet3: '$664',
        annualPriceSet4: '$745',
        annualPriceSet5: 'Custom',
        monthlyPriceSet1: '$99',
        monthlyPriceSet2: '$599',
        monthlyPriceSet3: '$799',
        monthlyPriceSet4: '$899',
        monthlyPriceSet5: 'Custom',
        monthPrice: '$799',
        crossedMonthPrice: '799',
        annualPrice: '$667',
        annualPriceSet1_id: 'price_1OQqBtJM7mNNpHqolY1fFd2d',
        annualPriceSet2_id: 'price_1OQqFtJM7mNNpHqoumDtylv7',
        annualPriceSet3_id: 'price_1OQqR6JM7mNNpHqoqcyDCjWJ',
        annualPriceSet4_id: 'price_1OQqUyJM7mNNpHqoR66Gnptb',
        monthlyPriceSet1_id: 'price_1OQqAeJM7mNNpHqoSQKxiXqN',
        monthlyPriceSet2_id: 'price_1OQqFGJM7mNNpHqoxRZ832sq',
        monthlyPriceSet3_id: 'price_1OQqR6JM7mNNpHqoQEKvIvbT',
        monthlyPriceSet4_id: 'price_1OQqUyJM7mNNpHqon9GVrLdq',
        annualPriceId: 'price_1OJuQhJM7mNNpHqoxgxiAcig',
        monthlyPriceId: 'price_1OJuQyJM7mNNpHqo3C9jfF7J',
        crossedAnnualPrice: '$799',
        buttonColor: '#7737FF',
        icon: <MajesticSvg />,
        featureTitle: 'Everything included in Breeze, plus:',
        features: [
            'Attribution (Marketing Tracker)',
            'Store Behavior Analysis (Store Tracker)',
            'Multistore & Marketplace support',
            'Planners',
        ],
    },
]
export const PlanChooser = ({ currentStep, setCurrentStep, setPlanName }) => {
    const [isAnnual, setIsAnnual] = React.useState(true)
    const [customPlanRequestModal, setCustomPlanModal] = useState({ show: false })
    const octupDispatch = useOctupDispatch()
    const { data: responsePlans } = useSelector((state) => state.payments)
    const { url: paymentUrl } = useSelector((state) => state.paymentSession)

    useEffect(() => {
        octupDispatch.payments.getSuggestedPlans()
    }, [octupDispatch.payments])

    useEffect(() => {
        // TODO: Check why it changes from null to an empty object
        if (typeof paymentUrl === 'string') {
            window.location.replace(paymentUrl)
        }
    }, [paymentUrl])

    const handleCloseCustomPlanModal = useCallback(() => {
        setCustomPlanModal({ show: false })
    }, [setCustomPlanModal])

    if (customPlanRequestModal.show) {
        return <CustomPlanModal onClose={handleCloseCustomPlanModal} />
    }

    return (
        <MainContainer>
            <StyledRow>
                <Title>Be the captain of your business destiny, here are our plans:</Title>
                <CenteredDiv>
                    <RadioTabsSelectors
                        tabsCustomStyle={{
                            fontSize: '13px',
                            padding: '1.2rem 1rem',
                            whiteSpace: 'nowrap',
                        }}
                        customStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: 'max-content',
                        }}
                        tabs={[
                            {
                                id: 1,
                                title: 'Annual (Save 20%)',
                                selected: true,
                                onClick: () => {
                                    setIsAnnual(true)
                                },
                            },
                            {
                                id: 2,
                                title: 'Monthly',
                                onClick: () => {
                                    setIsAnnual(false)
                                },
                            },
                        ]}
                    />
                </CenteredDiv>
            </StyledRow>
            <PlansContainer>
                {plans.map((plan) => {
                    plan.annualCost = responsePlans?.plan?.[plan.title.toLowerCase()]?.yearly
                    plan.monthlyCost = responsePlans?.plan?.[plan.title.toLowerCase()]?.monthly
                    return (
                        <PricingPlanCard
                            key={plan.id}
                            setCustomPlanModal={setCustomPlanModal}
                            plan={plan}
                            level={responsePlans?.plan?.level}
                            isCustom={responsePlans?.plan?.level === 5}
                            isAnnual={isAnnual}
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                            setPlanName={setPlanName}
                            annualCost={plan.annualCost}
                            monthlyCost={plan.monthlyCost}
                        />
                    )
                })}
            </PlansContainer>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const Title = styled.div`
    font-weight: 600;
    font-size: 2.25rem;
    text-align: center;
    padding-bottom: 1rem;
    color: rgba(80, 75, 90, 1);
`

const PlansContainer = styled(Row)`
    width: 100%;
    display: flex;
    height: 65rem;
    align-items: flex-start;
    justify-content: center;
    gap: 3rem;
    padding: 2rem 0.5rem 0 0.5rem;
`

const StyledRow = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`

const CenteredDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .MuiSlider-thumb {
        background: #ffffff;
        border: 0.5px solid rgba(255, 255, 255, 0.4);
        box-shadow:
            -2.5px -2.5px 10px rgba(255, 255, 255, 0.4),
            2px 2px 8px rgba(80, 75, 90, 0.4);
        border-radius: 15px;

        &:focus,
        &:hover,
        &.Mui-active {
            box-shadow:
                -2.5px -2.5px 10px rgba(255, 255, 255, 0.4),
                2px 2px 8px rgba(80, 75, 90, 0.4);
        }
    }

    .MuiSlider-rail {
        background: rgba(0, 168, 168, 1);
        border-radius: 8px;
        height: 0.8rem;
    }

    .MuiSlider-track {
        background: #00a8a8;
        border-radius: 8px;
    }

    .MuiSlider-track {
        border: none;
    }
`
