// TODO: Rewrite the component

import { Fragment, useState, useMemo, useCallback } from 'react'
import { SendFeedbackModal } from 'components/Modals/SendFeedbackModals/SendFeedbackModal'
import { useAbilities, useOctupDispatch } from 'hooks'
import { APP_ROUTES } from 'models/appConfig'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { resolveRoutePath } from '@/utils'
import { UserMenuItem } from './UserMenuItem'
import { OctupIcons } from '../../shared/assets/OctupIcons'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { OctupAvatar } from '../../shared/ui/Avatar/OctupAvatar'
import { DropableMenu } from '../../shared/ui/DropableMenu/DropableMenu'
import { OctupTooltip } from '../../shared/ui/MUIWrappers/OctupTooltip'

const SETTINGS_PAGE_CONFIG = {
    customColor: 'rgba(80, 75, 90, 1)',
    icon: OctupIcons.SettingsLogoSvg,
}

const MENU_PAGES = [
    {
        id: 1,
        title: 'Manage Account',
        info: 'Profile, Billing & Plans, Stores, Activity',
        icon: OctupIcons.UserIconSvg,
        route: APP_ROUTES.ACCOUNT,
    },
    {
        id: 2,
        title: 'User Management',
        route: APP_ROUTES.USERS,
        ...SETTINGS_PAGE_CONFIG,
    },
    {
        id: 3,
        title: 'Settings',
        route: resolveRoutePath(APP_ROUTES.SETTINGS, { ':section': '' }),
        ...SETTINGS_PAGE_CONFIG,
    },
]

export function UserMenu() {
    const navigate = useNavigate()
    const octupDispatch = useOctupDispatch()
    const { isPageAvailable } = useAbilities()
    const { userInfo } = useSelector((state) => state.auth)
    const [showDropdown, setShowDropdown] = useState(false)
    const [showSendFeedback, setShowSendFeedback] = useState(false)

    const handlePageClick = useCallback(
        (route) => {
            navigate(route)
            setShowDropdown(false)
        },
        [navigate]
    )

    const availablePages = useMemo(
        () => MENU_PAGES.filter((page) => isPageAvailable(page.route)),
        [isPageAvailable]
    )

    const actions = useMemo(
        () => [
            {
                id: 'send-feedback',
                title: 'Send Feedback',
                icon: OctupIcons.FeedbackIconSvg,
                clickHandler: () => setShowSendFeedback(true),
            },
            {
                id: 'privacy-policy',
                title: 'Privacy Policy',
                customColor: '#504B5A',
                icon: OctupIcons.HelpLogoSvg,
                clickHandler: () => window.open('https://octup.com/privacy.html'),
            },
            {
                id: 'logout',
                title: 'Logout',
                icon: OctupIcons.SignoutIconSvg,
                clickHandler: () => {
                    octupDispatch.auth.logout()
                    navigate(`/${APP_ROUTES.LOGIN}`)
                },
            },
        ],
        [navigate, octupDispatch.auth, setShowSendFeedback]
    )

    const header = (
        <>
            <UserNameStyled>{userInfo && userInfo.fullName}</UserNameStyled>
            <OctupTooltip title={'Your Profile'}>
                <div>
                    <OctupAvatar
                        size={'large'}
                        userInitials={userInfo && userInfo.initials}
                        title={userInfo && userInfo.fullName}
                        imageSrc={userInfo && userInfo.imageSrc}
                    />
                </div>
            </OctupTooltip>
        </>
    )

    const options = (
        <Div>
            <UserMetadata>
                <span>{userInfo && userInfo.fullName}</span>
                <span> @ {userInfo && userInfo.client?.name}</span>
            </UserMetadata>
            <UserMenuOptions>
                {availablePages.map((option, index) => (
                    <Fragment key={option.id}>
                        <UserMenuItem
                            clickHandler={() => handlePageClick(option.route)}
                            {...option}
                        />
                        {index === availablePages.length - 1 && <Divider />}
                    </Fragment>
                ))}
                {actions.map((option) => (
                    <UserMenuItem key={option.id} {...option} />
                ))}
            </UserMenuOptions>
        </Div>
    )

    return (
        <>
            <DropableMenu
                header={header}
                options={options}
                propsShowDropdown={showDropdown}
                propsSetShowDropdown={setShowDropdown}
            />
            {showSendFeedback && (
                <SendFeedbackModal
                    onDissmiss={setShowSendFeedback}
                    setOpenModal={setShowSendFeedback}
                    userToken={userInfo?.token}
                />
            )}
        </>
    )
}

const UserMetadata = styled.div`
    padding: 2.5rem 2.2rem;
    border-bottom: 0.1rem solid #dddbe1;

    span {
        color: #504b5a;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: center;
    }
`

const UserMenuOptions = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
`

const Divider = styled.div`
    border-top: 0.05rem solid #dddbe1;
    display: flex;
    align-self: center;
    width: 90%;
    height: 0;
`

const UserNameStyled = styled.div`
    color: #504b5a;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    padding-right: 1rem;
`

const Div = styled.div`
    background-color: ${OctupColors.pageBackground};
    z-index: 5;
`
