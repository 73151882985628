import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { ClientSettingsType } from 'models/settings'
import type { ThunkAPIConfigType } from 'services/utils'
import { editClientSettings } from '@/services/settings'

type EditClientSettingsStateType = {
    data?: Omit<ClientSettingsType, 'name'>
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: EditClientSettingsStateType = {
    data: undefined,
    isLoading: false,
    error: undefined,
}

export const fetchEditClientSettings = createAsyncThunk<
    EditClientSettingsStateType['data'],
    ClientSettingsType,
    ThunkAPIConfigType
>('fetchEditClientsSettings', editClientSettings)

export const editClientSettingsSlice = createSlice({
    name: 'editClientSettings',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEditClientSettings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchEditClientSettings.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchEditClientSettings.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const editClientSettingsReducer = editClientSettingsSlice.reducer
