import { useContext, useMemo } from 'react'
import { ToastContext } from '../contexts/ToastsContext'
import { TOAST_TYPE } from '../contexts/ToastsContext'

export function useToastContext() {
    return useContext(ToastContext)
}

// TODO: re-write Toast implementation
export function useToast() {
    const { addToast, removeToast, updateToast } = useContext(ToastContext)
    // @TODO:REFACTORE DUPLICATIONS, CHANGE CALL TO OBJECT {content:"", options:{}}
    const toast = useMemo(
        () => ({
            success: (
                text,
                callback,
                customIcon,
                customButtons,
                customTimeout,
                customStyle,
                defaultBtnClickHandler,
                processCompleted,
                actionText,
                customDisableTimer,
                pages
            ) =>
                addToast({
                    type: TOAST_TYPE.SUCCESS,
                    text,
                    callback,
                    customIcon,
                    customButtons,
                    customTimeout,
                    customStyle,
                    defaultBtnClickHandler,
                    processCompleted,
                    actionText,
                    customDisableTimer,
                    pages,
                }),
            info: (
                text,
                callback,
                customIcon,
                customButtons,
                customTimeout,
                customStyle,
                defaultBtnClickHandler,
                processCompleted,
                actionText,
                customDisableTimer,
                pages
            ) =>
                addToast({
                    type: TOAST_TYPE.INFO,
                    text,
                    callback,
                    customIcon,
                    customButtons,
                    customTimeout,
                    customStyle,
                    defaultBtnClickHandler,
                    processCompleted,
                    actionText,
                    customDisableTimer,
                    pages,
                }),
            error: (
                text,
                callback,
                customIcon,
                customButtons,
                customTimeout,
                customStyle,
                defaultBtnClickHandler,
                processCompleted,
                actionText,
                customDisableTimer,
                pages
            ) =>
                addToast({
                    type: TOAST_TYPE.ERROR,
                    text,
                    callback,
                    customIcon,
                    customButtons,
                    customTimeout,
                    customStyle,
                    defaultBtnClickHandler,
                    processCompleted,
                    actionText,
                    customDisableTimer,
                    pages,
                }),
            unicorn: (
                text,
                callback,
                customIcon,
                customButtons,
                customTimeout,
                customStyle,
                defaultBtnClickHandler,
                processCompleted,
                actionText,
                customDisableTimer,
                pages
            ) =>
                addToast({
                    type: TOAST_TYPE.UNICORN,
                    text,
                    callback,
                    customIcon,
                    customButtons,
                    customTimeout,
                    customStyle,
                    defaultBtnClickHandler,
                    processCompleted,
                    actionText,
                    customDisableTimer,
                    pages,
                }),
            undo: (
                text,
                callback,
                customIcon,
                customButtons,
                customTimeout,
                customStyle,
                defaultBtnClickHandler,
                processCompleted,
                actionText,
                customDisableTimer,
                pages
            ) =>
                addToast({
                    type: TOAST_TYPE.UNDO,
                    text,
                    callback,
                    customIcon,
                    customButtons,
                    customTimeout,
                    customStyle,
                    defaultBtnClickHandler,
                    processCompleted,
                    actionText,
                    customDisableTimer,
                    pages,
                }),
            prompt: (
                text,
                callback,
                customIcon,
                customButtons,
                customTimeout,
                customStyle,
                defaultBtnClickHandler,
                processCompleted,
                actionText,
                customDisableTimer,
                pages
            ) =>
                addToast({
                    type: TOAST_TYPE.PROMPT,
                    text,
                    callback,
                    customIcon,
                    customButtons,
                    customTimeout,
                    customStyle,
                    defaultBtnClickHandler,
                    processCompleted,
                    actionText,
                    customDisableTimer,
                    pages,
                }),
            process: (
                text,
                callback,
                customIcon,
                customButtons,
                customTimeout,
                customStyle,
                defaultBtnClickHandler,
                processCompleted,
                actionText,
                customDisableTimer,
                pages
            ) =>
                addToast({
                    type: TOAST_TYPE.PROCESS,
                    text,
                    callback,
                    customIcon,
                    customButtons,
                    customTimeout,
                    customStyle,
                    defaultBtnClickHandler,
                    processCompleted,
                    actionText,
                    customDisableTimer,
                    pages,
                }),
            removeToast: (id) => removeToast(id),
            updateToast: (id, updatedFields) => updateToast(id, updatedFields),
            delete: (
                text,
                callback,
                customIcon,
                customButtons,
                customTimeout,
                customStyle,
                defaultBtnClickHandler,
                processCompleted,
                actionText,
                customDisableTimer,
                pages
            ) =>
                addToast({
                    type: TOAST_TYPE.DELETED,
                    text,
                    callback,
                    customIcon,
                    customButtons,
                    customTimeout,
                    customStyle,
                    defaultBtnClickHandler,
                    processCompleted,
                    actionText,
                    customDisableTimer,
                    pages,
                }),
        }),
        [addToast, removeToast, updateToast]
    )

    return toast
}
