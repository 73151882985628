export function ShipstationIcon({ size = '2.4rem' }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="11.8889" cy="11.8889" r="8.88889" fill="#504B5A" />
            <path
                d="M11.6524 17.105C10.6024 17.105 9.73736 16.87 9.05736 16.4C8.38736 15.92 8.05236 15.25 8.05236 14.39C8.05236 14.3 8.06236 14.165 8.08236 13.985H10.2274C10.1874 14.405 10.2974 14.75 10.5574 15.02C10.8274 15.29 11.2174 15.425 11.7274 15.425C12.2374 15.425 12.6374 15.32 12.9274 15.11C13.2274 14.89 13.3774 14.58 13.3774 14.18C13.3774 13.81 13.2224 13.515 12.9124 13.295C12.6124 13.075 12.1374 12.83 11.4874 12.56C10.8874 12.31 10.3974 12.08 10.0174 11.87C9.64736 11.66 9.32736 11.37 9.05736 11C8.79736 10.62 8.66736 10.145 8.66736 9.575C8.66736 8.925 8.83236 8.36 9.16236 7.88C9.50236 7.4 9.96236 7.03 10.5424 6.77C11.1324 6.51 11.8024 6.38 12.5524 6.38C13.2924 6.38 13.9324 6.505 14.4724 6.755C15.0224 6.995 15.4324 7.34 15.7024 7.79C15.9824 8.24 16.1024 8.765 16.0624 9.365H13.8274L13.8424 9.2C13.8424 8.87 13.7174 8.6 13.4674 8.39C13.2274 8.18 12.8874 8.075 12.4474 8.075C11.9774 8.075 11.5974 8.185 11.3074 8.405C11.0174 8.625 10.8724 8.925 10.8724 9.305C10.8724 9.555 10.9524 9.775 11.1124 9.965C11.2824 10.145 11.4874 10.305 11.7274 10.445C11.9674 10.575 12.3124 10.745 12.7624 10.955C13.3524 11.215 13.8374 11.46 14.2174 11.69C14.5974 11.92 14.9224 12.225 15.1924 12.605C15.4624 12.985 15.5974 13.45 15.5974 14C15.5974 14.6 15.4324 15.135 15.1024 15.605C14.7824 16.075 14.3224 16.445 13.7224 16.715C13.1324 16.975 12.4424 17.105 11.6524 17.105Z"
                fill="white"
            />
        </svg>
    )
}
