import { useCallback } from 'react'
import { PlusIcon } from '@octup/core/assets/icons'
import { SvgIcon, Button } from '@octup/core/ui-kit'
import { useDispatch } from 'react-redux'
import { addNewCost } from 'state/features/unitEconomics'

export const AddCostButtonContainer = () => {
    const dispatch = useDispatch()
    const handleClick = useCallback(() => dispatch(addNewCost()), [dispatch])

    return (
        <Button
            size="small"
            onClick={handleClick}
            startIcon={<SvgIcon component={PlusIcon} fontSize="small" />}
        >
            Add Cost
        </Button>
    )
}
