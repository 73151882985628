import styled from 'styled-components'
import { OctupColors } from 'shared/theme/colors/OctupColors'
import { ExternalLinkIcon } from 'shared/assets/icons'

export const Link = styled.a`
    display: inline-flex;
    gap: 0.8rem;
    align-items: center;

    &,
    & > * {
        color: ${OctupColors.primaryActionableLight};
    }
`

export const ExternalHelpLinkIcon = styled(ExternalLinkIcon)`
    width: 1.5rem;
    height: 1.5rem;
`
