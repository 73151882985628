import { octupDictionary } from 'shared/dictionary/dictionary'

export const MISSING_DATA_BADGE = {
    title: octupDictionary.titles.attribution.dataMissing,
    tooltip: octupDictionary.tooltips.attribution.dataMissing,
    theme: 'error',
}

export const NO_DATA_REDIRECTION = {
    path: '/Integrations',
    activePage: 'Integrations',
}
