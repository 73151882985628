import {
    ConnectDialogContainer,
    DisconnectModalContainer,
    SelectAccountsModalContainer,
} from 'new_containers/components/Integrations'

export const MODAL_TYPES = {
    CONNECT: 'CONNECT',
    DISCONNECT: 'DISCONNECT',
    SELECT_ACCOUNTS: 'SELECT_ACCOUNTS',
}

export const MODAL_BY_TYPE = {
    [MODAL_TYPES.CONNECT]: ConnectDialogContainer,
    [MODAL_TYPES.DISCONNECT]: DisconnectModalContainer,
    [MODAL_TYPES.SELECT_ACCOUNTS]: SelectAccountsModalContainer,
}
