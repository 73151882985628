export function GoogleAnalyticsIcon({ size = '2.4rem' }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2471_15476)">
                <path
                    d="M15.537 19.0746C14.5607 18.0982 14.5607 16.5143 15.537 15.5371C13.5835 17.4907 10.4165 17.4907 8.46297 15.5371C9.43931 16.5134 9.43931 18.0974 8.46297 19.0746C7.48663 20.0509 5.90272 20.0509 4.9255 19.0746C8.82646 22.9755 15.1735 22.9755 19.0745 19.0746C18.0973 20.0509 16.5134 20.0509 15.537 19.0746Z"
                    fill="#FBBC04"
                />
                <path
                    d="M19.0745 4.92548C15.1735 1.02452 8.82642 1.02452 4.92546 4.92548C1.0245 8.82644 1.0245 15.1735 4.92546 19.0745C3.94912 18.0981 3.94912 16.5142 4.92546 15.537C5.9018 14.5607 7.48571 14.5607 8.46293 15.537C6.50937 13.5835 6.50937 10.4165 8.46293 8.46295C10.4165 6.50939 13.5834 6.50939 15.537 8.46295C16.5133 9.43928 18.0972 9.43928 19.0745 8.46295C20.0508 7.48573 20.0508 5.90269 19.0745 4.92548Z"
                    fill="#4184F3"
                />
                <path
                    d="M6.6938 19.8068C8.0753 19.8068 9.19523 18.6869 9.19523 17.3054C9.19523 15.9239 8.0753 14.804 6.6938 14.804C5.3123 14.804 4.19237 15.9239 4.19237 17.3054C4.19237 18.6869 5.3123 19.8068 6.6938 19.8068Z"
                    fill="#34A853"
                />
                <path
                    d="M19.0745 4.92554C20.0508 5.90188 20.0508 7.48579 19.0745 8.46301C18.0982 9.43934 16.5142 9.43934 15.537 8.46301C17.4906 10.4166 17.4906 13.5835 15.537 15.5371C14.5607 16.5134 14.5607 18.0973 15.537 19.0745C16.5134 20.0509 18.0973 20.0509 19.0745 19.0745C22.9746 15.1736 22.9746 8.8265 19.0745 4.92554Z"
                    fill="#EA4335"
                />
            </g>
            <defs>
                <clipPath id="clip0_2471_15476">
                    <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>
    )
}
