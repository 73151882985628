import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SEGMENTS } from '@/features/metrics'
import { metricsState } from '@/features/metrics/metricsSlice'
import { alternativeMarketingMetricsState } from '@/features/sections/alternativeMarketingSlice'
import { alternativeOperationsMetricsState } from '@/features/sections/alternativeOperationsSlice'
import { homePageMetricsState } from '@/features/sections/homePageSectionsSlice'

export const useMetricsGroup = (segment, customMetrics) => {
    const { metrics } = useSelector(metricsState)
    const { homepageMetrics } = useSelector(homePageMetricsState)
    const { alternativeOperationsMetrics } = useSelector(alternativeOperationsMetricsState)
    const { alternativeMarketingMetrics } = useSelector(alternativeMarketingMetricsState)

    const METRICS_BY_SEGMENT = useMemo(
        () => ({
            [SEGMENTS.ALTERNATIVE_HOME_METRICS]: homepageMetrics,
            [SEGMENTS.ALTERNATIVE_HOME_OPERATIONS]: alternativeOperationsMetrics,
            [SEGMENTS.ALTERNATIVE_HOME_MARKETING]: alternativeMarketingMetrics,
            [SEGMENTS.MARKETING]: metrics[SEGMENTS.MARKETING],
            [SEGMENTS.OPERATIONS]: metrics[SEGMENTS.OPERATIONS],
        }),
        [alternativeMarketingMetrics, alternativeOperationsMetrics, homepageMetrics, metrics]
    )

    const metricsGroup = useMemo(
        () => METRICS_BY_SEGMENT[segment]?.filter(({ metric }) => metric.deep_dive),
        // TODO: fix metrics, METRICS_BY_SEGMENT always changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [segment]
    )

    return customMetrics || metricsGroup || []
}
