import { useCallback, useState } from 'react'
import { Button } from '@octup/core/ui-kit'
import { usePrompt } from 'hooks'
import { DiscardChangesModalContainer } from 'new_containers/components/__common__'
import { useNavigateToDashboard } from 'new_containers/components/Dashboards/__common__'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { removeDashboard } from 'state/features/dashboards'
import { useAppDispatch, useAppSelector } from 'state/hooks'

const MODAL_CONTENT = {
    header: 'Are you sure you wish to discard board changes?',
    paragarph: 'Note that all modifications made to your board will not be saved.',
}

export const ExitDashboardContainer = () => {
    const { dashboardId } = useParams()
    const dispatch = useAppDispatch()
    const navigateToDashboard = useNavigateToDashboard()
    const { formState } = useFormContext()
    const { isSubmitting, dirtyFields } = formState
    const hasDirtyFields = !!Object.keys(dirtyFields).length
    const [isExit, setIsExit] = useState(false)
    const dashboards = useAppSelector((state) => state.dashboards)
    const { dashboardsById, dashboardsList, deletedDashboard } = dashboards
    const dashboardData = dashboardsById.data?.[dashboardId!]?.data
    const toggleExit = useCallback(() => setIsExit((open) => !open), [])

    const handleDiscardNewDashboard = useCallback(() => {
        if (dashboardData?.isNew) {
            dispatch(removeDashboard(dashboardData.id))
        }
    }, [dashboardData?.id, dashboardData?.isNew, dispatch])

    usePrompt({
        when: hasDirtyFields && !isSubmitting && !isExit && !deletedDashboard.data,
        callback: handleDiscardNewDashboard,
    })

    const handleDiscard = useCallback(() => {
        handleDiscardNewDashboard()

        return navigateToDashboard({
            dashboard: dashboardData,
            dashboardsList: dashboardsList.data,
            skipDashboardActiveState: false,
        })
    }, [handleDiscardNewDashboard, navigateToDashboard, dashboardData, dashboardsList.data])

    const handleClick = hasDirtyFields ? toggleExit : handleDiscard

    return (
        <>
            <Button size="small" onClick={handleClick}>
                Exit
            </Button>
            {/* @ts-expect-error: DiscardChangesModalContainer is a JavaScript file */}
            <DiscardChangesModalContainer
                showDiscardModal={isExit}
                title="Exit without Saving"
                discardTitle="Discard Changes"
                cancelTitle="Keep Editing"
                onCancel={toggleExit}
                onDiscard={handleDiscard}
                content={MODAL_CONTENT}
            />
        </>
    )
}
