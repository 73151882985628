import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { type WorkingHoursSettingsType, type SettingsType } from 'models/settings'
import type { ThunkAPIConfigType } from 'services/utils'
import { editWorkingHoursSettings } from '@/services/settings'
import { getWorkingHoursData } from './utils'

type EditWorkingHoursSettingsStateType = {
    data?: WorkingHoursSettingsType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: EditWorkingHoursSettingsStateType = {
    data: undefined,
    isLoading: false,
    error: undefined,
}

export const fetchEditWorkingHoursSettings = createAsyncThunk<
    SettingsType,
    WorkingHoursSettingsType,
    ThunkAPIConfigType
>('fetchEditWorkingHoursSettings', editWorkingHoursSettings)

export const editWorkingHoursSettingsSlice = createSlice({
    name: 'editWorkingHoursSettings',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEditWorkingHoursSettings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchEditWorkingHoursSettings.fulfilled, (state, action) => {
                state.data = getWorkingHoursData(action.payload)
                state.isLoading = false
            })
            .addCase(fetchEditWorkingHoursSettings.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const editWorkingHoursSettingsReducer = editWorkingHoursSettingsSlice.reducer
