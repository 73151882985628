import axios from 'axios'
import { GLOBAL_CONFIG } from '../../config'

const API_URL = `${GLOBAL_CONFIG.BASE_URL}user`

const generateConfig = (token, contentType) => {
    return {
        headers: {
            Authorization: token ? token : null,
            'Content-Type': contentType ? contentType : 'application/json',
        },
    }
}

/**
 * get all users
 * @token mandatory
 */
export const getUsers = async (token) => {
    const config = generateConfig(token)
    const response = await axios.get(`${API_URL}/associated_users`, config)
    return response.data
}

/**
 * updates single Octup Objective by id
 * @token mandatory
 * @objectiveId mandatory
 */
export const getUserbyId = async (userId, token) => {
    const config = generateConfig(token)
    const response = await axios.get(`${API_URL}/${userId}`, config)
    return response.data
}

export const deleteUserById = async ({ userId, token }) => {
    const config = generateConfig(token)
    return await axios.delete(`${API_URL}/delete_user/${userId}`, config)
}

export const usersService = {
    getUsers,
    getUserbyId,
    deleteUserById,
}
