import { format, subDays, differenceInDays, subYears } from 'date-fns'
import { COMPARISON_MODES } from 'constants/metrics'

const getDateByDifference = ({ date, difference }) =>
    format(subDays(new Date(date), difference + 1), 'yyyy-MM-dd')

const getPrevPeriodDates = ({ datePicker }) => {
    const { startDate, endDate } = datePicker
    const difference = differenceInDays(new Date(endDate), new Date(startDate))

    return {
        startDate: getDateByDifference({ date: startDate, difference }),
        endDate: getDateByDifference({ date: endDate, difference }),
    }
}

const getPrevYearDate = ({ date }) => format(subYears(new Date(date), 1), 'yyyy-MM-dd')

const getPrevYearDates = ({ datePicker }) => {
    const { startDate, endDate } = datePicker

    return {
        startDate: getPrevYearDate({ date: startDate }),
        endDate: getPrevYearDate({ date: endDate }),
    }
}

const GET_DATES_BY_COMPARISON_MODE = {
    [COMPARISON_MODES.PERIOD]: getPrevPeriodDates,
    [COMPARISON_MODES.YEAR]: getPrevYearDates,
}

export const getComparisonDates = ({ comparisonMode, datePicker }) => {
    const getDates = GET_DATES_BY_COMPARISON_MODE[comparisonMode]
    return getDates?.({ datePicker })
}
