import { useCallback, useMemo, useState, forwardRef } from 'react'
import { InputAdornment, TextField as MuiTextField } from '@mui/material'
import type { TextFieldProps as MuiTextFieldProps } from '@mui/material'
import { OpenedEyeIcon, ClosedEyeIcon } from '@octup/core/assets/icons'
import { IconButton, SvgIcon } from '@octup/core/ui-kit'

export type PasswordTextFieldPropsType = MuiTextFieldProps

export const PasswordTextField = forwardRef(
    ({ InputProps, ...props }: PasswordTextFieldPropsType, ref: React.Ref<HTMLDivElement>) => {
        const [showPassword, setShowPassword] = useState(false)
        const toggleShowPassword = useCallback(() => setShowPassword((show) => !show), [])
        const currentType = useMemo(() => (showPassword ? 'text' : 'password'), [showPassword])

        const inputProps = useMemo(
            () => ({
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton color="inherit" onClick={toggleShowPassword}>
                            <SvgIcon
                                fontSize="inherit"
                                component={showPassword ? ClosedEyeIcon : OpenedEyeIcon}
                            />
                        </IconButton>
                    </InputAdornment>
                ),
                ...InputProps,
            }),
            [InputProps, showPassword, toggleShowPassword]
        )

        return <MuiTextField ref={ref} type={currentType} InputProps={inputProps} {...props} />
    }
)
