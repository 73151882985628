import type {
    METRICS_SECTION_RESPONSE_DATA_TYPE,
    MetricsSectionDataType,
    MetricsSectionType,
} from '@octup/core/models'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import { GLOBAL_CONFIG } from '@/config'
import type { ClientType } from '@/models/clients'
import type { ThunkAPIType } from '@/services/utils'
import { generateAPIConfig } from '@/services/utils'

export const SECTIONS_API_URL = GLOBAL_CONFIG.API_URL + '/sections'

export const getMetricsSectionsList = async (thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(SECTIONS_API_URL, config)
    return response.data
}

export type MetricsSectionByIdRequestType = Pick<MetricsSectionType, 'id' | 'type'> & {
    startDate: string
    endDate: string
    responseDataType: METRICS_SECTION_RESPONSE_DATA_TYPE
    clientId: ClientType['id']
}

export type GetMetricsSectionType = (
    data: MetricsSectionByIdRequestType,
    thunkAPI: ThunkAPIType
) => Promise<Pick<AxiosResponse<MetricsSectionDataType>, 'data' | 'status'>>

export const getMetricsSectionById: GetMetricsSectionType = async (data, thunkAPI) => {
    const config = generateAPIConfig({
        thunkAPI,
        params: {
            start_date: data.startDate,
            end_date: data.endDate,
            response_data_type: data.responseDataType,
            client_id: data.clientId,
        },
    })

    const response = await axios.get(`${SECTIONS_API_URL}/${data.id}`, config)
    return { data: response.data, status: response.status }
}

export const getCustomTableConfig = async (
    id: MetricsSectionByIdRequestType['id'],
    thunkAPI: ThunkAPIType
) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(`${SECTIONS_API_URL}/${id}/custom-table/config`, config)
    return response.data
}

export const getCustomTableData = async (
    data: MetricsSectionByIdRequestType,
    thunkAPI: ThunkAPIType
) => {
    const params = { start_date: data.startDate, end_date: data.endDate, client_id: data.clientId }
    const config = generateAPIConfig({ thunkAPI, params })
    const response = await axios.get(`${SECTIONS_API_URL}/${data.id}/custom-table/data`, config)
    return response.data
}

export const getCustomTableMetricsSectionById: GetMetricsSectionType = async (data, thunkAPI) => {
    const sectionData = await getMetricsSectionById(data, thunkAPI)
    const tableConfig = await getCustomTableConfig(data.id, thunkAPI)
    const tableData = await getCustomTableData(data, thunkAPI)
    const table = { config: tableConfig, data: tableData }
    const customTableSectionData = { ...sectionData.data, table }
    return { data: customTableSectionData, status: sectionData.status }
}
