import type { Theme } from '@mui/material'

export const inputStyle = (theme: Theme) => ({
    width: theme.spacing(16),
})

export const selectStyle = (theme: Theme) => ({
    width: theme.spacing(24),
})

export const buttonStyle = (theme: Theme) => ({
    width: theme.spacing(30),
})
