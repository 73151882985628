import { useMemo } from 'react'
import { CardsMenu } from 'shared/ui/CardsMenu/OctupCardsMenu'
import { getCardsData } from './CardsContainer.utils'
import { useSelector } from 'react-redux'

export const CardsContainer = (props) => {
    const { selectedSegmentIntegrations } = useSelector(
        (state) => state.integrations.integrationsList
    )

    const cardsData = useMemo(
        () => getCardsData(selectedSegmentIntegrations),
        [selectedSegmentIntegrations]
    )

    return (
        <CardsMenu
            style={{ overflowY: 'auto' }}
            customCardStyle={(el) => ({
                outline: el.isConnected ? '.1rem solid rgba(7, 131, 68, 0.32)' : '',
            })}
            cardsData={cardsData}
            {...props}
        />
    )
}
