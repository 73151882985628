export const AmazonAdsIcon = ({ size = '2.4rem' }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5317 18.8945C13.6496 20.2823 10.9222 21.0218 8.57417 21.0218C5.27514 21.0218 2.31625 19.8045 0.0734715 17.7779C-0.0971682 17.6187 0.05527 17.4014 0.266863 17.5254C2.6871 18.9343 5.68467 19.7818 8.77098 19.7818C10.9998 19.7715 13.2047 19.3207 15.2587 18.4554C15.5772 18.32 15.8446 18.6635 15.5317 18.8945ZM16.3144 17.9992C16.0755 17.692 14.724 17.8541 14.1188 17.9252C13.934 17.948 13.9061 17.787 14.0722 17.6716C15.1529 16.915 16.9128 17.1329 17.1187 17.3872C17.3246 17.6414 17.0652 19.4121 16.055 20.2562C15.8997 20.3859 15.7518 20.317 15.8275 20.1447C16.055 19.5782 16.5635 18.3075 16.3224 17.9992"
                fill="#FF9900"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.91086 10.706C9.91086 11.6684 9.93532 12.471 9.449 13.3253C9.05084 14.0193 8.42516 14.4476 7.73976 14.4476C6.79157 14.4476 6.23585 13.7252 6.23585 12.6587C6.23585 10.5542 8.12199 10.1713 9.91143 10.1713L9.91086 10.706ZM12.4028 16.7262C12.2395 16.8724 12.0035 16.8826 11.8192 16.7853C11.0001 16.1045 10.8499 15.7882 10.4017 15.1387C9.04686 16.5209 8.08445 16.9344 6.33027 16.9344C4.24961 16.9344 2.63422 15.6523 2.63422 13.0853C2.63422 11.0809 3.71778 9.71574 5.26776 9.04854C6.60842 8.45813 8.48147 8.35461 9.912 8.19534V7.8825C9.912 7.2955 9.9575 6.60043 9.61054 6.09363C9.31192 5.63859 8.73459 5.45089 8.22437 5.45089C7.28301 5.45089 6.44574 5.93437 6.24097 6.93431C6.19945 7.16183 6.0362 7.3757 5.81039 7.38594L3.40949 7.11747C3.20813 7.07196 2.98289 6.90929 3.04148 6.60555C3.59606 3.69614 6.2222 2.82019 8.57077 2.82019C9.77264 2.82019 11.3431 3.13986 12.2907 4.04993C13.4926 5.17217 13.3783 6.66925 13.3783 8.29886V12.1485C13.3783 13.3054 13.8578 13.8122 14.3094 14.4379C14.4658 14.6654 14.5005 14.9271 14.2991 15.0943C13.6616 15.6334 13.0306 16.18 12.4062 16.7342L12.4028 16.7273"
                fill="#221F1F"
            />
            <path
                d="M20.2646 14.12C19.8646 14.12 19.5213 14.05 19.2346 13.91C18.9479 13.77 18.7279 13.5767 18.5746 13.33C18.4279 13.0767 18.3546 12.7833 18.3546 12.45C18.3546 12.4233 18.3546 12.3967 18.3546 12.37C18.3613 12.3433 18.3646 12.33 18.3646 12.33H19.5846V12.4C19.5913 12.68 19.6713 12.8733 19.8246 12.98C19.9779 13.0867 20.1379 13.14 20.3046 13.14C20.4646 13.14 20.6213 13.1 20.7746 13.02C20.9279 12.9333 21.0046 12.7833 21.0046 12.57C21.0046 12.3833 20.9413 12.2433 20.8146 12.15C20.6946 12.05 20.5346 11.9733 20.3346 11.92C20.1413 11.86 19.9346 11.8 19.7146 11.74C19.5013 11.68 19.2946 11.5967 19.0946 11.49C18.9013 11.3767 18.7413 11.2233 18.6146 11.03C18.4946 10.83 18.4346 10.56 18.4346 10.22C18.4346 9.86 18.5179 9.56333 18.6846 9.33C18.8513 9.09 19.0779 8.91333 19.3646 8.8C19.6513 8.68 19.9679 8.62 20.3146 8.62C20.6479 8.62 20.9479 8.67666 21.2146 8.79C21.4879 8.90333 21.7013 9.06666 21.8546 9.28C22.0146 9.49333 22.0946 9.75 22.0946 10.05C22.0946 10.1033 22.0913 10.1533 22.0846 10.2C22.0846 10.2467 22.0846 10.2733 22.0846 10.28H20.8646V10.26C20.8579 10.0267 20.8013 9.86 20.6946 9.76C20.5946 9.65333 20.4546 9.6 20.2746 9.6C20.1546 9.6 20.0479 9.62333 19.9546 9.67C19.8613 9.71 19.7879 9.77 19.7346 9.85C19.6813 9.92333 19.6546 10.0133 19.6546 10.12C19.6546 10.2867 19.7146 10.4133 19.8346 10.5C19.9613 10.5867 20.1213 10.6567 20.3146 10.71C20.5146 10.7633 20.7213 10.82 20.9346 10.88C21.1546 10.9333 21.3613 11.0167 21.5546 11.13C21.7546 11.2367 21.9146 11.3933 22.0346 11.6C22.1613 11.8 22.2246 12.0733 22.2246 12.42C22.2246 12.8467 22.1379 13.1833 21.9646 13.43C21.7979 13.6767 21.5646 13.8533 21.2646 13.96C20.9713 14.0667 20.6379 14.12 20.2646 14.12Z"
                fill="black"
            />
            <path
                d="M15.5148 14.12C14.9948 14.12 14.5815 13.9 14.2748 13.46C13.9748 13.0134 13.8248 12.3134 13.8248 11.36C13.8248 10.72 13.8948 10.2 14.0348 9.80002C14.1815 9.39335 14.3782 9.09669 14.6248 8.91002C14.8715 8.71669 15.1482 8.62002 15.4548 8.62002C15.7215 8.62002 15.9582 8.67669 16.1648 8.79002C16.3782 8.90335 16.5315 9.06669 16.6248 9.28002H16.6748V6.77002H17.8748V14H16.8748L16.7548 13.35H16.7048C16.5782 13.6034 16.4115 13.7967 16.2048 13.93C15.9982 14.0567 15.7682 14.12 15.5148 14.12ZM15.8748 13.03C16.1682 13.03 16.3748 12.9034 16.4948 12.65C16.6148 12.3967 16.6748 12.0334 16.6748 11.56V11.2C16.6748 10.7267 16.6148 10.36 16.4948 10.1C16.3748 9.84002 16.1682 9.71002 15.8748 9.71002C15.5882 9.71002 15.3815 9.83669 15.2548 10.09C15.1348 10.3434 15.0748 10.7067 15.0748 11.18V11.56C15.0748 12.0334 15.1348 12.3967 15.2548 12.65C15.3815 12.9034 15.5882 13.03 15.8748 13.03Z"
                fill="black"
            />
        </svg>
    )
}
