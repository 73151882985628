import { useMemo, useState } from 'react'
import { Button } from '@octup/core/ui-kit'
import { format } from 'date-fns'
import { APP_ROUTES } from 'models/appConfig'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { WarehouseInvoicesIngestionModal } from './WarehouseInvoicesIngestionModal'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'
import { MessageCard } from '../../UnitEconomics/MessageCards/MessageCard'

export function UEMessageCard({ isAccordion = true }) {
    const navigate = useNavigate()
    const octupDispatch = useOctupDispatch()
    const { invoicesDates, products } = useSelector((state) => state.unitEconomics)
    const selectedDate = products.query?.date
    const [showInvoicesIngestionModal, setShowInvoicesIngestionModal] = useState({
        show: false,
        isEditState: false,
    })

    const dismissInvoicesIngestionModal = () => {
        setShowInvoicesIngestionModal({ show: false, isEditState: false })
    }
    const presentInvoicesIngestionModal = () => {
        setShowInvoicesIngestionModal((prev) => {
            return { ...prev, show: true }
        })
    }

    const msgData = {
        type: 'danger',
        headerMsg: 'Partial costs due to missing invoices data',
        bodyMsg: (
            <>
                <div>
                    In order to see complete and accurate product costs, please provide us with
                    warehouse invoices so we can process its data.
                </div>
                <Button
                    variant="contained"
                    size="small"
                    onClick={presentInvoicesIngestionModal}
                    sx={{ textWrap: 'wrap' }}
                >
                    start invoices ingestion
                </Button>
                <div>
                    In addition, be sure to connect any relevant integration{' '}
                    <a
                        onClick={() => {
                            octupDispatch.sidebar.setActivePage('integrations')
                            navigate(`/${APP_ROUTES.INTEGRATIONS}`)
                        }}
                    >
                        here
                    </a>
                </div>
            </>
        ),
        isAccordion,
    }

    const hasInvoicesDate = useMemo(
        () =>
            selectedDate && invoicesDates.data?.includes(format(new Date(selectedDate), 'yyyy-MM')),
        [invoicesDates.data, selectedDate]
    )

    if (!selectedDate || invoicesDates.isLoading || hasInvoicesDate) return null

    return (
        <>
            <MessageCard {...msgData} />
            {showInvoicesIngestionModal.show && (
                <WarehouseInvoicesIngestionModal
                    dismissModalhandler={dismissInvoicesIngestionModal}
                />
            )}
        </>
    )
}
