import { forwardRef } from 'react'
import type { FormControlLabelProps, FormControlProps, SwitchProps } from '@mui/material'
import { Switch as MuiSwitch, FormControlLabel, FormControl } from '@mui/material'

export type SwitchPropsType = SwitchProps &
    FormControlProps &
    Pick<FormControlLabelProps, 'label'> & {
        value?: boolean
    }

export const Switch = forwardRef<HTMLButtonElement, SwitchPropsType>(
    ({ label, value, error, required, sx, ...props }, ref) => {
        if (!label) return <MuiSwitch ref={ref} sx={sx} {...props} />

        return (
            <FormControl error={error} required={required} sx={sx}>
                <FormControlLabel
                    control={<MuiSwitch checked={value} ref={ref} {...props} />}
                    label={label}
                />
            </FormControl>
        )
    }
)
