export function GoogleIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M15.9467 7.69108L17.9467 5.69108C12.4466 0.191149 5.44666 4.19104 3.94674 8.19094C3.92498 8.26716 6.41707 10.2601 6.44673 10.1909C8.84795 4.59146 13.78 6.19108 15.9467 7.69108Z"
                fill="#EA4335"
            />
            <path
                d="M6.44676 14.1911C5.94687 13.6911 5.94666 11.191 6.44676 10.1904C6.44676 10.1904 3.98503 8.11389 3.94677 8.19042C2.44666 11.191 2.94668 14.6911 3.94677 16.1904C3.94677 16.1904 6.94666 14.691 6.44676 14.1911Z"
                fill="#FBBC05"
            />
            <path
                d="M17 14C17 15.2188 15.4466 16.6911 15.4466 16.6911C15.4466 16.6911 18.3861 18.7516 18.4466 18.6911C19.9466 17.191 21 15 21 12.5C21 12.5 17 12.7813 17 14Z"
                fill="#4285F4"
            />
            <path
                d="M6.44663 14.191L3.94665 16.191C7.83583 22.927 15.4467 22.191 18.4466 18.691C17.9466 18.191 15.4894 16.6543 15.4466 16.691C11.9466 19.691 7.44665 17.191 6.44663 14.191Z"
                fill="#34A853"
            />
            <path
                d="M20.9467 10.691H11.9467V14.191H16.4467H20.7188C20.7188 14.191 21 13.5 20.9467 12.441C20.8933 11.3821 20.9467 10.691 20.9467 10.691Z"
                fill="#4285F4"
            />
        </svg>
    )
}
