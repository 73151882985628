import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useToast } from 'hooks'
import { ProductCostForm } from 'new_components/UnitEconomics'
import { FormContainer } from 'new_containers/components/__common__'
import { cancelEditCost, fetchProducts } from 'state/features/unitEconomics'
import { fetchUpdateCost } from 'state/features/costManager'
import { getDefaultValues, COST_FORM_VALIDATION_SCHEMA } from './ProductCostFormContainer.utils'

export const ProductCostFormContainer = ({ cost }) => {
    const toasts = useToast()
    const dispatch = useDispatch()
    const { query, selectedProduct } = useSelector((state) => state.unitEconomics.products)
    const defaultValues = useMemo(() => getDefaultValues(cost), [cost])
    const handleCancel = useCallback(() => dispatch(cancelEditCost(cost.id)), [cost.id, dispatch])

    const handleSubmit = useCallback(
        (data, formContext) => {
            if (Object.keys(formContext.formState.dirtyFields).length) {
                const isVariant = !selectedProduct.variants
                return dispatch(
                    fetchUpdateCost({
                        date: query.date,
                        productId: selectedProduct.product_id,
                        cost_id: cost.isEdit ? cost.id : null,
                        categories: 'all',
                        products: [selectedProduct.id],
                        is_divided: false,
                        is_variant: isVariant,
                        is_from_product: !isVariant,
                        is_from_variant: isVariant,
                        ...data,
                    })
                )
            }

            dispatch(cancelEditCost(cost.id))
        },
        [dispatch, cost, selectedProduct, query.date]
    )

    const handleSuccess = useCallback(
        async (data) => {
            const { date, cost_id, cost_name } = data.meta.arg
            const action = !cost_id ? 'created' : 'updated'
            await dispatch(fetchProducts({ date }))
            toasts.success(`'${cost_name}' cost has been ${action} successfully`)
        },
        [dispatch, toasts]
    )

    const handleFail = useCallback(
        (data) => {
            const { cost_id, cost_name } = data.meta.arg
            const action = !cost_id ? 'create' : 'update'
            dispatch(cancelEditCost(cost_id))
            toasts.error(
                data.payload.status === 403
                    ? 'This cost can be updated from the Cost Manager page only'
                    : `Failed to ${action} '${cost_name}' cost, please try again`
            )
        },
        [dispatch, toasts]
    )

    return (
        <FormContainer
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
            onFail={handleFail}
            defaultValues={defaultValues}
            validationSchema={COST_FORM_VALIDATION_SCHEMA}
        >
            <ProductCostForm cost={cost} onCancel={handleCancel} />
        </FormContainer>
    )
}
