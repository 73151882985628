import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@octup/core/ui-kit'
import type { DashboardsListType } from '@/models/dashboards'
import { MetricsDashboardProvider } from '@/new_containers/components/__common__'
import { dashboardStyle } from './styles'

type DashboardsListPropsType = {
    data?: DashboardsListType
}

export const DashboardsList = ({ data }: DashboardsListPropsType) => (
    <>
        {data?.map(({ id, name, client_id }, index) => (
            <Accordion key={`${id}-${client_id}`} defaultExpanded={index === 0}>
                <AccordionSummary>
                    <Typography variant="h6">{name}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={dashboardStyle}>
                    <MetricsDashboardProvider dashboardId={id} clientId={client_id} />
                </AccordionDetails>
            </Accordion>
        ))}
    </>
)
