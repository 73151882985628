import { useCallback } from 'react'
import { SvgIcon, Button } from '@octup/core/ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { DownloadIcon } from 'new_assets/icons'
import { fetchCostsReport } from 'state/features/logistics'

export const CostReportButtonContainer = (props) => {
    const dispatch = useDispatch()
    const { query } = useSelector((state) => state.logistics)
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const { isLoading } = useSelector((state) => state.warehouseCostsReport)

    const handleClick = useCallback(() => {
        dispatch(fetchCostsReport({ ...datePickerValues, ...query }))
    }, [datePickerValues, dispatch, query])

    return (
        <Button
            {...props}
            size="small"
            variant="secondary"
            loading={isLoading}
            onClick={handleClick}
            startIcon={
                <SvgIcon>
                    <DownloadIcon />
                </SvgIcon>
            }
        >
            Order Cost Report
        </Button>
    )
}
