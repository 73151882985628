import { useCallback, useMemo } from 'react'
import { OverflowTypography } from '@octup/core/ui-kit'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useOctupDispatch } from '@/hooks/useOctupDispatch'

const SidebarSubMenuItem = ({ subMenuItem, onSubItemSelection }) => {
    const location = useLocation()
    const octupDispatch = useOctupDispatch()
    const { title, path, onClick } = subMenuItem

    const handleClick = useCallback(
        (e) => {
            e.stopPropagation()
            e.preventDefault()

            onClick?.()
            onSubItemSelection()
            octupDispatch.sidebar.setActiveSubMenuItem(title)
        },
        [octupDispatch.sidebar, onClick, onSubItemSelection, title]
    )

    const navPath = useMemo(() => {
        if (path === location.pathname) {
            return `${path}${location.search}`
        }
        return path
    }, [location, path])

    return (
        <NavListItem key={title} onClick={handleClick}>
            <NavItem to={navPath}>
                <OverflowTypography position="right" variant="body2" color="inherit">
                    {title}
                </OverflowTypography>
            </NavItem>
        </NavListItem>
    )
}

const NavListItem = styled.li`
    :hover {
        background: rgba(0, 0, 0, 0.15);
    }
`

const NavItem = styled(NavLink)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #d9d9d9;
    font-weight: 400;
    line-height: 2rem;
    padding: 1.2rem 0.9rem;
    width: 100%;
    height: 4.8rem;
    padding-left: 4.6rem;

    /* gap: 0.8rem; */
    &.active {
        color: #00a8a8;
        background: rgba(0, 0, 0, 0.15);
    }
    &:hover {
        background: rgba(0, 0, 0, 0.15);
    }
`

export default SidebarSubMenuItem
