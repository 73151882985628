export function LogiwaIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path d="M6.65517 11.7345L2 2V22L6.65517 11.7345Z" fill="#4C85C6" />
            <path d="M9.93103 18.1063L6.65517 11.5576L2 22.0001L9.93103 18.1063Z" fill="#71A8DA" />
            <path d="M12 22L9.93103 18.1062L2 22H12Z" fill="#3E3780" />
            <path d="M12 22L9.93105 18.1062L22 22H12Z" fill="#31B476" />
            <path d="M10.1035 18.2833L22 11.9116V22.0001L10.1035 18.2833Z" fill="#9BCD7A" />
        </svg>
    )
}
