export function DoubleCheckMarks() {
    return (
        <svg
            width="20"
            height="11"
            viewBox="0 0 20 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13 1.66666L5 9.66666L1 5.66684"
                stroke="#078344"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.4795 1.66666L10.5368 9.66666L9.47949 8.66666"
                stroke="#078344"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
