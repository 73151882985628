import { useMemo } from 'react'
import type { MetricType } from '@octup/core/models'
import { Grid, OverflowTypography } from '@octup/core/ui-kit'
import { formatNumber } from '@octup/core/utils'
import MetricTrend from '@/components/Metrics/MetricItem/MetricItemComponents/MetricTrend'
import { LoadableContent } from '@/new_components/__common__/LoadableContent'
import { DateLabelContainer } from '@/new_containers/components/__common__'

type MetricOverviewPropsType = {
    metric?: MetricType
    children?: React.ReactNode
    isLoading?: boolean
    showTrend?: boolean
    showLastUpdatedDate?: boolean
}

export const MetricOverview = ({
    metric,
    children,
    isLoading,
    showTrend,
    showLastUpdatedDate,
}: MetricOverviewPropsType) => {
    const formattedValue = useMemo(
        () =>
            formatNumber({
                value: metric?.value,
                valueUnits: metric?.value_units,
                currency: metric?.currency,
                isSupported: metric?.supported,
            }),
        [metric?.currency, metric?.supported, metric?.value, metric?.value_units]
    )

    return (
        <Grid item xs container columnGap={2} wrap="nowrap">
            <Grid
                item
                xs={children ? 6 : 12}
                container
                direction="column"
                justifyContent="center"
                gap={1}
            >
                <LoadableContent isLoading={isLoading}>
                    <Grid item container>
                        <OverflowTypography variant="h1" fontWeight={275}>
                            {formattedValue}
                        </OverflowTypography>
                    </Grid>
                    {showTrend && (
                        //  @ts-expect-error: MetricTrend is a JavaScript file
                        <MetricTrend
                            isSupported
                            trendUnits={metric?.trend_units}
                            trendValue={metric?.trend_value}
                            isPositiveTrend={metric?.is_positive_trend}
                        />
                    )}
                    {showLastUpdatedDate && metric?.last_updated_date && (
                        <Grid item container>
                            <DateLabelContainer
                                variant="body2"
                                color="text.secondary"
                                date={metric.last_updated_date}
                            />
                        </Grid>
                    )}
                </LoadableContent>
            </Grid>
            {children && (
                <Grid item xs={6} container direction="column">
                    <LoadableContent isLoading={isLoading}>{children}</LoadableContent>
                </Grid>
            )}
        </Grid>
    )
}
