import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ATTRIBUTION_API_URL, getAttribution } from 'services'

const ATTRIBUTION_INITIAL_STATE = {
    data: null,
    isLoading: false,
    error: null,
}

export const fetchAttribution = createAsyncThunk(ATTRIBUTION_API_URL, async (data, thunkAPI) =>
    getAttribution(data, thunkAPI)
)

export const attributionSlice = createSlice({
    name: 'attribution',
    initialState: ATTRIBUTION_INITIAL_STATE,
    reducers: {
        sendDataConnectionRequest() {
            window.location.href =
                'mailto:support@octup.com?subject=Attribution Data Connection Request'
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAttribution.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchAttribution.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchAttribution.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const { sendDataConnectionRequest } = attributionSlice.actions
export const attributionReducer = attributionSlice.reducer
