import { ChartBarIcon, GiftIcon, StoreFrontIcon } from 'new_assets/icons'
import { AttributedCostsContainer } from 'new_containers/components/UnitEconomics'
import { AddCostButtonContainer } from '../AddCostButtonContainer'

export const getDetailsCards = ({ price, profit_percentage, gross_margin }) => [
    {
        id: 'averagePrice',
        title: 'Average Selling Price',
        info: 'The average price actually paid',
        icon: <StoreFrontIcon />,
        value: price,
    },
    {
        id: 'attributedCosts',
        title: 'Attributed Costs',
        // TODO: clarify the info here
        // info: 'The average price actually paid',
        icon: <GiftIcon />,
        content: <AttributedCostsContainer />,
        actions: <AddCostButtonContainer />,
        sx: { overflow: 'auto' },
    },
    {
        id: 'grossMargin',
        title: 'Gross Margin',
        info: 'Average selling price - Total costs',
        icon: <ChartBarIcon />,
        value: gross_margin,
        percent: profit_percentage,
    },
]
