import { COMPARISON_MODES } from 'constants/metrics'
import {
    getComparisonChartData,
    getDateRange,
    getDefaultChartData,
    getGroupIndexes,
    isComparedTitle,
} from './common'

const getTooltipTitle = ({ tooltipItem }) => {
    const isAllDashedLabel = tooltipItem.every(({ dataset }) => dataset.isDashed)
    const alternativeItem = tooltipItem.find((item) => item.dataset.tooltipLabels)
    const { label, dataset, dataIndex } = alternativeItem || {}

    if (alternativeItem) {
        const alternativeLabel = dataset.tooltipLabels[dataIndex]
        if (isAllDashedLabel) return alternativeLabel
        return `${label} (${dataset.tooltipLabels[dataIndex]})`
    }
}

const getTooltipLabel = ({ defaultLabel, tooltipItem, getValueLabel, hideTooltipLabel }) => {
    const { chart, dataIndex, dataset } = tooltipItem
    const currentLabel = dataset.label
    const labels = chart.tooltip.dataPoints
    const isAllDashedLabel = labels.every(({ dataset }) => dataset.isDashed)
    if (isAllDashedLabel) return defaultLabel
    if (hideTooltipLabel) return

    const alternativeItem = labels.find(
        ({ dataset }) => dataset.tooltipLabels && dataset.label === currentLabel
    )

    if (alternativeItem) {
        const alternativeLabel = getValueLabel({ value: alternativeItem.dataset.data[dataIndex] })
        return `${defaultLabel} (${alternativeLabel})`
    }

    return defaultLabel
}

const isGroupLegend = (item) => item.pointType === 'line' && !item.hideLegend

const getIndexesToExclude = ({ datasets, chart, legendItem }) =>
    datasets.reduce((acc, dataset, index) => {
        const meta = chart.getDatasetMeta(index)
        const isGroupLegendHidden = isGroupLegend(dataset) && meta.hidden
        const showBaseLegend = legendItem.pointStyle !== 'line' && legendItem.hidden

        if (isGroupLegendHidden && showBaseLegend) {
            const indexes = getGroupIndexes({ item: dataset, datasets })
            return [...acc, ...indexes]
        }

        return acc
    }, [])

// TODO: optimize the function
const handleLegendClick = (event, legendItem) => {
    const chart = event.chart
    const datasets = chart.data.datasets
    const { lineDash, text, pointStyle } = legendItem
    const item = { ...legendItem, borderDash: lineDash, label: text, pointType: pointStyle }
    const selectedIndexes = getGroupIndexes({ item, datasets })
    const indexesToExclude = getIndexesToExclude({ datasets, chart, legendItem })
    const isExcludeAll = indexesToExclude.length === datasets.length
    const showGroupLegend = legendItem.pointStyle === 'line' && legendItem.hidden
    const isAllSelected = !datasets.find((_, index) => !chart.getDatasetMeta(index).hidden)

    datasets.forEach((dataset, index) => {
        const meta = chart.getDatasetMeta(index)

        if (showGroupLegend && !isAllSelected) {
            meta.hidden = false
            return
        }

        const isExcludeIndex = isExcludeAll || !indexesToExclude.includes(index)
        const isSelectedIndex = selectedIndexes.includes(index) && isExcludeIndex
        const isHidden = isExcludeAll && isGroupLegend(dataset) ? false : meta.hidden
        meta.hidden = isSelectedIndex ? !legendItem.hidden : isHidden
    })

    chart.update()
}

const getColorIndex = ({ dataSet, data }) =>
    dataSet.findIndex((item) => item.metric.metricTitle === data.metric.metricTitle)

const getComparisonDataSet = ({ dataSet, timeSlice, isCompared, comparisonMode }) => {
    if (comparisonMode === COMPARISON_MODES.NONE) return []

    return [
        {
            isDashed: isCompared,
            hideTooltipLabel: true,
            pointType: 'line',
            hasDefaultColor: true,
            label: getDateRange({ data: dataSet[0], timeSlice }),
            data: [],
        },
    ]
}

const getChartDataSets = ({ data, comparisonMode, timeSlice }) =>
    data.reduce((acc, [title, dataSet]) => {
        const isCompared = isComparedTitle(title)
        return [
            ...acc,
            ...dataSet.map((item) => ({
                hideLegend: isCompared,
                hideTooltipLabel: isCompared,
                ...getDefaultChartData({ data: item }),
                ...(isCompared && {
                    colorIndex: getColorIndex({ dataSet, data: item }),
                    ...getComparisonChartData({ data: item, timeSlice, isCompared }),
                }),
            })),
            ...getComparisonDataSet({ dataSet, timeSlice, isCompared, comparisonMode }),
        ]
    }, [])

const getCommonProps = () => ({
    getChartDataSets,
})

const getBreakdownComparisonProps = () => ({
    getTooltipTitle,
    getTooltipLabel,
    onLegendClick: handleLegendClick,
    ...getCommonProps(),
})

export const BREAKDOWN_PROPS_BY_COMPARISON_MODE = {
    [COMPARISON_MODES.NONE]: getCommonProps,
    [COMPARISON_MODES.PERIOD]: getBreakdownComparisonProps,
    [COMPARISON_MODES.YEAR]: getBreakdownComparisonProps,
}
