import type { ListItemAnyPropsType, ListItemPropsType } from './ListItem'
import { ListItem } from './ListItem'
import type {
    AutocompleteOptionType,
    AutocompleteOptionsType,
    AutocompleteValueType,
} from './models'
import type { TagsPropsType } from './Tags'
import { Tags } from './Tags'

export const SELECT_ALL_OPTION = { id: 'all', label: 'Select All', value: 'select-all' }

export const checkIsAllOption = (option: AutocompleteOptionType) =>
    option?.id === SELECT_ALL_OPTION.id

export const checkIsAllSelected = (
    value: AutocompleteValueType,
    options: AutocompleteOptionsType
) => (value as AutocompleteOptionType[])?.length === options.length - 1

type GetOptionsType = {
    multiple?: boolean
    loading?: boolean
    options: AutocompleteOptionsType
    selectAllLabel?: string
}

export const getOptions = ({ multiple, loading, options, selectAllLabel }: GetOptionsType) => {
    if (loading) return []

    return [
        ...(multiple && options?.length ? [{ ...SELECT_ALL_OPTION, label: selectAllLabel }] : []),
        ...options,
    ] as AutocompleteOptionsType
}

export const renderOption = (
    props: ListItemAnyPropsType,
    option: ListItemPropsType['option'],
    state: ListItemPropsType['state'],
    ownerState: ListItemPropsType['ownerState']
) => <ListItem option={option} state={state} ownerState={ownerState} {...props} />

export const renderTags = (
    value: TagsPropsType['value'],
    getTagProps: TagsPropsType['getTagProps'],
    ownerState: TagsPropsType['ownerState']
) => <Tags value={value} getTagProps={getTagProps} ownerState={ownerState} />
