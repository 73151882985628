import { useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { getYear, isSameMonth, isSameYear, parse } from 'date-fns'
import { containerStyle } from './CalendarLayout.styles'

const MONTH_BUTTON_SELECTOR = '.MuiPickersMonth-monthButton'

export const CalendarLayout = ({ view, children, value, availableMonths }) => {
    const ref = useRef(null)

    useEffect(() => {
        const datePicker = ref.current
        if (!datePicker || view !== 'month') return

        const currentYear = getYear(value)
        const monthButtons = datePicker.querySelectorAll(MONTH_BUTTON_SELECTOR)

        monthButtons.forEach((monthButton) => {
            const monthName = monthButton.getAttribute('aria-label')
            const monthDate = parse(`${monthName} ${currentYear}`, 'MMMM yyyy', new Date())

            availableMonths.forEach((month) => {
                const availableDate = parse(month, 'yyyy-MM', new Date())

                if (isSameYear(monthDate, availableDate) && isSameMonth(monthDate, availableDate)) {
                    monthButton.classList.add('available-month')
                }
            })
        })
    }, [view, value, availableMonths])

    return (
        <Box ref={ref} sx={containerStyle}>
            {children}
        </Box>
    )
}
