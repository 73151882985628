import styled from 'styled-components'
import { OctupColors } from '../../theme/colors/OctupColors'

export const CardExpendable = ({
    children,
    className,
    hideShadow = false,
    customStyle,
    height = '100%',
    background,
    customRadius,
    ...props
}) => {
    return (
        <CardWrapper
            height={height}
            style={customStyle}
            hideShadow={hideShadow}
            className={className}
            background={background}
            customRadius={customRadius}
            {...props}
        >
            {children}
        </CardWrapper>
    )
}

const CardWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    position: relative;
    background-color: ${OctupColors.headerIcons};
    box-shadow: ${(props) =>
        !props.hideShadow
            ? '-0.4rem -0.4rem 0.8rem rgba(255, 255, 255, 0.75), 0.8rem 0.8rem 2.4rem rgba(80, 75, 90, 0.08)'
            : 'none'};
    border-radius: ${(props) => (props.customRadius ? props.customRadius : '0.8rem')};
    border: ${(props) => (props.showBorder ? '0.1rem solid #EBEAEE' : 'none')};
    width: 100%;
    background: ${(props) => props.background};
    height: ${(props) => props.height};
`
