import React from 'react'
import { getIconColor } from './utils'

export function MetricsDetailsIconSvg({
    active,
    customActiveColor = '#007F82',
    customColor = '#007F82',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2rem"
            height="1.1rem"
            viewBox="0 0 12 11"
            fill="none"
        >
            <line
                x1="0.875"
                y1="9.67847"
                x2="0.875"
                y2="5.02103"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M4.40552 9.85132L4.40552 6.38776"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M7.82764 9.85132L7.82764 1.00001"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M11.25 9.85132L11.25 4.8484"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    )
}
