import styled from 'styled-components'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DropableMenuOptions } from './DropableMenuOptions'
import { DropableMenuHeader } from './DropableMenuHeader'

export function DropableMenu({
    header,
    options,
    dismissBtn = {
        title: '',
        onClickCallback: () => {},
    },
    showDismissBtn,
    showChevron,
    droplistPosition,
    customCallback,
    optionsCustomStyle,
    propsShowDropdown,
    propsSetShowDropdown,
    disabled,
}) {
    const [localShowDropdown, setLocalShowDropdown] = useState(false)
    const showDropdown = propsShowDropdown ? propsShowDropdown : localShowDropdown
    const setShowDropdown = propsSetShowDropdown ? propsSetShowDropdown : setLocalShowDropdown
    const dropdownRef = useRef()

    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            setShowDropdown(false)
        }
        event.stopPropagation()
    }, [])

    useEffect(() => {
        if (!showDropdown) return
        document.addEventListener('keydown', escFunction, true)
        return () => {
            document.removeEventListener('keydown', escFunction, true)
        }
    }, [escFunction, showDropdown])

    useEffect(() => {
        //closes menu if mouse click outside of container
        const onMouseDownClick = (event) => {
            showDropdown &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                setShowDropdown(false)
        }
        customCallback && customCallback(showDropdown)
        //listen to mousedown
        document.addEventListener('mousedown', onMouseDownClick)
        //clean up
        return () => {
            document.removeEventListener('mousedown', onMouseDownClick)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDropdown])
    return (
        <DropableMenuContainer ref={dropdownRef}>
            <DropableMenuHeader
                setShowDropdown={setShowDropdown}
                showChevron={showChevron}
                showDropdown={showDropdown}
                disabled={disabled}
            >
                {header}
            </DropableMenuHeader>
            <DropableMenuOptions
                optionsCustomStyle={optionsCustomStyle}
                droplistPosition={droplistPosition}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                dismissBtn={dismissBtn}
                showDismissBtn={showDismissBtn}
            >
                {options}
            </DropableMenuOptions>
        </DropableMenuContainer>
    )
}

const DropableMenuContainer = styled.div`
    position: relative;
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
`
