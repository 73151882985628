import { getIconColor } from './utils'

export function IntegrationsGuideStepIcon({
    active,
    customActiveColor = '#504B5A',
    customColor = '#504B5A',
}) {
    return (
        <svg
            width="77"
            height="77"
            viewBox="0 0 77 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_dd_902_4335)">
                <circle cx="40.8691" cy="40.245" r="30" fill="#F8F7FB" />
                <circle cx="40.8691" cy="40.245" r="28" stroke="white" strokeWidth="4" />
            </g>
            <path
                d="M39.634 54.4226C38.3648 55.6918 36.307 55.6918 35.0378 54.4226L27.0416 46.4264C25.7724 45.1572 25.7724 43.0994 27.0416 41.8302L33.32 35.5518C33.4177 35.4541 33.576 35.4541 33.6736 35.5518L45.9125 47.7906C46.0101 47.8883 46.0101 48.0466 45.9125 48.1442L39.634 54.4226Z"
                stroke="#504B5A"
                strokeWidth="1.5"
            />
            <path
                d="M49.3256 44.1587L54.3262 38.9842C55.8429 37.4148 55.8216 34.9193 54.2783 33.3761L47.4438 26.5416C45.8628 24.9606 43.2928 24.9826 41.7391 26.5904L36.8335 31.6666"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M30.8367 50.2119L27.6667 53.3333"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M51.5032 30.34L54.6829 27.1605"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M41 35L37.6667 38.3333"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M46 40L41.8333 44.1667"
                stroke="#504B5A"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                opacity="0.3"
                d="M40.9912 24.648C42.5532 23.0859 45.0859 23.0859 46.648 24.648L52.8186 30.8186C54.3807 32.3807 54.3807 34.9133 52.8186 36.4754L47.7759 41.5181C47.3854 41.9086 46.7522 41.9086 46.3617 41.5181L35.9485 31.1049C35.558 30.7143 35.558 30.0812 35.9485 29.6907L40.9912 24.648Z"
                fill="#7737FF"
            />
            <rect
                opacity="0.3"
                x="38.1406"
                y="44.7344"
                width="7.80896"
                height="4.26474"
                rx="2.13237"
                transform="rotate(-45 38.1406 44.7344)"
                fill="#7737FF"
            />
            <defs>
                <filter
                    id="filter0_dd_902_4335"
                    x="0.869141"
                    y="0.244995"
                    width="76"
                    height="76"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.313726 0 0 0 0 0.294118 0 0 0 0 0.352941 0 0 0 0.16 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_902_4335"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_902_4335"
                        result="effect2_dropShadow_902_4335"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_902_4335"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}
