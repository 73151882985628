import { Components, Theme } from '@mui/material'

type PopperOverridesType = Pick<Components<Theme>, 'MuiPopper'>

export const POPPER_OVERRIDES: PopperOverridesType = {
    MuiPopper: {
        styleOverrides: {
            root: {
                // Remove zIndex after replacing CardExpendable with MUI Card
                zIndex: 2,
            },
        },
    },
}
