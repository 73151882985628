export const showOnHoverStyle = {
    '& .hover-display': {
        visibility: 'hidden',
    },
    '&:hover': {
        '& .hover-display': {
            visibility: 'visible',
        },
    },
}

export const containerStyle = (theme) => ({
    maxHeight: theme.spacing(13),
    padding: theme.spacing(3.5, 1.5),
    paddingRight: theme.spacing(4),
    ...showOnHoverStyle,
})

export const highlightTitleStyle = {
    fontWeight: 600,
}

export const iconStyle = (theme) => ({
    color: theme.palette.grey[600],
})
