import styled from 'styled-components'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { OctupTextSizes } from '../../shared/theme/texts/OctupTexts'

const TableContainer = styled.div`
    width: 50%;
    height: 95%;
    display: flex;
`

const CardTitle = styled.span`
    color: ${OctupColors.primaryTextLight};
    font-size: ${OctupTextSizes.cardTitle};
    line-height: 4rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

const FlexRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: auto;
    padding-bottom: 2rem;
`

const TopSideContainer = styled.div`
    display: grid;
    width: 100%;
    grid-auto-flow: row;
    justify-items: stretch;
`

const OuterDoughnutChartContainer = styled.div`
    width: 24%;
    margin-right: 2rem;
`

const DougnutChartContainer = styled.div`
    canvas {
        min-width: 320px;
        min-height: 320px;
        max-width: 320px;
        max-height: 320px;

        @media screen and (max-width: 1600px) {
            min-width: 280px;
            min-height: 280px;
            max-width: 280px;
            max-height: 280px;
        }

        @media screen and (max-width: 768px) {
            min-width: 180px;
            min-height: 180px;
            max-width: 180px;
            max-height: 180px;
        }
    }
`

const BottomContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 1rem));
    grid-template-rows: 100%;
    grid-gap: 2rem;
    width: 100%;
    margin-top: 2rem;

    @media screen and (max-width: 1280px) {
        grid-template-columns: 100%;
        grid-template-rows: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 100%;
        grid-template-rows: repeat(2, 1fr);
    }
`

const InnerAreaChartContainer = styled.div`
    height: 100%;
    padding: 1rem;
    padding-bottom: 0;
`

const DropdownContainer = styled.div`
    justify-content: flex-end;
    display: flex;
    position: absolute;
    width: 100%;
    top: 8rem;
    right: 3rem;
`

const BottomCardInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    padding: 1rem;
    padding-bottom: 2rem;

    @media screen and (max-width: 768px) {
        padding: 1rem;
    }
`

const BottomBottomGraphContainer = styled.div`
    width: 100%;
    flex-direction: column;
`

const DougnutMapContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    gap: 2.9rem;
`

const DougnutOutterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 5rem;
    margin-bottom: 10rem;
`

const DougnutMapItem = styled.div`
    div {
        font-size: 1.7rem;

        min-width: 6rem;
        max-width: fit-content;
        white-space: nowrap;
        text-align: left;
        color: rgba(80, 75, 90, 1);
    }

    text-align: left;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3rem;
`
const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 2rem;
    width: 100%;
    height: 100%;
    grid-auto-flow: row;
    justify-items: stretch;

    @media screen and (max-width: 1280px) {
        grid-template-columns: repeat(2, calc(50% - 1rem));
        grid-template-rows: repeat(4, 1fr);
        grid-auto-flow: column;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 100%;
        grid-template-rows: repeat(8, 1fr);
        grid-auto-flow: column;
    }
`

export {
    TableContainer,
    CardTitle,
    FlexRowContainer,
    TopSideContainer,
    OuterDoughnutChartContainer,
    DougnutMapItem,
    DougnutOutterContainer,
    DougnutMapContainer,
    BottomBottomGraphContainer,
    BottomCardInnerContainer,
    DropdownContainer,
    BottomContainer,
    DougnutChartContainer,
    InnerAreaChartContainer,
    CardsContainer,
}
