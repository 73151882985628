export function FacebookIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M22 12C22 6.47714 17.5228 2 12 2C6.47714 2 2 6.47714 2 12C2 16.9913 5.65685 21.1283 10.4375 21.8785V14.8906H7.89844V12H10.4375V9.79687C10.4375 7.29062 11.9304 5.90625 14.2146 5.90625C15.3087 5.90625 16.4531 6.10156 16.4531 6.10156V8.56249H15.1921C13.9499 8.56249 13.5625 9.33334 13.5625 10.1242V12H16.3359L15.8926 14.8906H13.5625V21.8785C18.3431 21.1283 22 16.9913 22 12Z"
                fill="#1877F2"
            />
            <path
                d="M15.8926 14.8906L16.3359 12H13.5625V10.1241C13.5625 9.33334 13.9499 8.56249 15.1921 8.56249H16.4531V6.10156C16.4531 6.10156 15.3087 5.90625 14.2146 5.90625C11.9304 5.90625 10.4375 7.29061 10.4375 9.79687V12H7.89844V14.8906H10.4375V21.8785C10.9544 21.9595 11.4768 22.0001 12 22C12.5316 22 13.0534 21.9584 13.5625 21.8785V14.8906H15.8926Z"
                fill="white"
            />
        </svg>
    )
}
