import React from 'react'

export function SingleOrderFirstSessionInfoSvg() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="7.75" stroke="#A4A0AA" strokeWidth="1.5" />
            <path
                d="M11.3335 11.3334H12.0002V16H12.6668"
                stroke="#A4A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 9.33337C12.5523 9.33337 13 8.88566 13 8.33337C13 7.78109 12.5523 7.33337 12 7.33337C11.4477 7.33337 11 7.78109 11 8.33337C11 8.88566 11.4477 9.33337 12 9.33337Z"
                fill="#A4A0AA"
            />
        </svg>
    )
}
