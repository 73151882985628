import { useEffect } from 'react'
import { ErrorMessageFallback, LoadingView } from '@/new_components/__common__'
import { ClientsSettings } from '@/new_components/Clients'
import { fetchClientsSettings } from '@/state/features/settings'
import { useAppDispatch, useAppSelector } from '@/state/hooks'

export const ClientsSettingsContainer = () => {
    const dispatch = useAppDispatch()
    const { data, error, isLoading } = useAppSelector((state) => state.settings.clients)

    useEffect(() => {
        if (!data) {
            dispatch(fetchClientsSettings())
        }
    }, [data, dispatch])

    if (isLoading) return <LoadingView />
    if (error) return <ErrorMessageFallback />

    return <ClientsSettings data={data} />
}
