import { useMemo, useCallback } from 'react'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { UseFormReturn } from 'react-hook-form'
import { ERROR_MESSAGES } from '@/constants'
import { useToast } from '@/hooks'
import type { ClientSettingsType } from '@/models/settings'
import { ClientSettingsForm } from '@/new_components/Clients'
import { FormContainer } from '@/new_containers/components/__common__'
import { fetchEditClientSettings, updateClientSettings } from '@/state/features/settings'
import { useAppDispatch } from '@/state/hooks'
import { getDefaultValues, VALIDATION_SCHEMA } from './utils'

type ClientSettingsFormContainerPropsType = {
    data: ClientSettingsType
}

export const ClientSettingsFormContainer = ({ data }: ClientSettingsFormContainerPropsType) => {
    const toast = useToast()
    const dispatch = useAppDispatch()
    const defaultValues = useMemo(() => getDefaultValues(data), [data])

    const handleSubmit = useCallback(
        (data: ClientSettingsType) => dispatch(fetchEditClientSettings(data)),
        [dispatch]
    )

    const handleSuccess = useCallback(
        (
            data: PayloadAction<ClientSettingsType, string, { arg: ClientSettingsType }>,
            formContext: UseFormReturn
        ) => {
            const updatedClient = { ...data.payload, name: data.meta.arg.name }
            formContext.reset(updatedClient)
            dispatch(updateClientSettings(updatedClient))
            toast.success(`${updatedClient.name} settings have been updated successfully`)
        },
        [dispatch, toast]
    )

    const handleFail = useCallback(() => toast.error(ERROR_MESSAGES.SERVER_ERROR), [toast])

    return (
        //  @ts-expect-error: PageLayout is a JavaScript file
        <FormContainer
            defaultValues={defaultValues}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
            onFail={handleFail}
        >
            <ClientSettingsForm />
        </FormContainer>
    )
}
