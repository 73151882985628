import { ERROR_MESSAGES } from 'constants/validation'
import {
    gorgiasConnectionImg,
    klaviyoSettingsImg,
    klaviyoCreatePrivateAPIKeyImg,
    okendoConnectionImg,
    zendeskConnectionImg,
    returngoConnectionImg,
    aftershipConnectionImg,
    shipnetworkConnectionImg,
} from 'shared/assets/images/integrations'
import { object, string } from 'yup'

const AFTER_SHIP_FIELDS = {
    apiKey: {
        text: ['Copy and paste your ', <b key="apiKey">API Key</b>, ' here:'],
    },
}

const RAKUTEN_FIELDS = {
    ApiUserIdentifier: {
        order: 1,
        text: [
            'Copy and paste your ',
            <b key="ApiUserIdentifier">API User Identifier</b>,
            ' here:',
        ],
    },
    ApiUserSecret: {
        order: 2,
        text: ['Copy and paste your ', <b key="ApiSecretKey">API Secret Key</b>, ' here:'],
    },
    ClientId: {
        order: 3,
        text: [
            'To obtain your ',
            <b key="ClientId">Client ID</b>,
            ' you will need to contact ShipNetwork directly. Once your have it, paste you Client ID here:',
        ],
    },
}

const OKENDO_FIELDS = {
    userId: {
        order: 1,
        text: ['Copy and paste your ', <b key="userId">User ID</b>, ' here:'],
    },
    apiKey: {
        order: 2,
        text: ['Copy and paste your ', <b key="apiKey">API Key</b>, ' here:'],
    },
}

const ZENDESK_FIELDS = {
    subdomain: {
        text: 'Type in your subdomain here, without the dot:',
    },
}

const GORGIAS_FIELDS = {
    name: {
        text: 'Type in your subdomain here, without the dot:',
    },
}

const KLAVIYO_FIELDS = {
    apiKey: {
        text: 'Copy API Key and paste it here',
    },
}

const RETURNGO_FIELDS = {
    apiKey: {
        text: ['Copy and paste your ', <b key="apiKey">API Key</b>, ' here:'],
    },
}

const MODAL_CONFIG_BY_TITLE = {
    after_ship: {
        helpLink: 'https://www.aftership.com/docs/shipping/quickstart/api-quick-start',
        helpLinkText: 'Visit AfterShip API Quick Start',
        guideSteps: [
            {
                text: 'Log in to your AfterShip Shipping Admin account and go to settings. Click the API Keys option to generate your API key.',
                imgConfig: {
                    src: aftershipConnectionImg,
                    alt: 'after ship connection',
                },
            },
            {
                showFieldSteps: true,
                config: AFTER_SHIP_FIELDS,
            },
        ],
    },
    rakuten: {
        guideSteps: [
            {
                text: [
                    'Log in to your Smartfill platform and go to Hi, YourName ',
                    <span key="arrow">&gt;</span>,
                    ' Client Account at the top.',
                    <br key="br" />,
                    'Scroll down and see your User Identifier and User Secret keys are located under the API access section.',
                ],
                imgConfig: {
                    src: shipnetworkConnectionImg,
                    alt: 'rakuten connection',
                    width: '80%',
                },
            },
            {
                showFieldSteps: true,
                config: RAKUTEN_FIELDS,
            },
        ],
    },
    okendo: {
        helpLink: 'https://docs.okendo.io/',
        helpLinkText: 'Visit Okendo Technical Reference Guide',
        guideSteps: [
            {
                text: 'On your Okendo account, navigate to Settings > Integrations',
                imgConfig: { src: okendoConnectionImg, alt: 'okendo connection' },
            },
            {
                showFieldSteps: true,
                config: OKENDO_FIELDS,
            },
            {
                showStep: false,
                text: 'In case you don’t have an API Key you will need to generate one.',
            },
        ],
    },
    zendesk: {
        maxWidth: 'sm',
        guideSteps: [
            {
                text: 'Open your Zendesk account in the browser',
            },
            {
                text: 'Your subdomain is the name appearing before .zendesk.com',
                imgConfig: { src: zendeskConnectionImg, alt: 'zendesk connection', width: '80%' },
            },
            {
                showFieldSteps: true,
                config: ZENDESK_FIELDS,
            },
        ],
    },
    gorgias: {
        maxWidth: 'sm',
        guideSteps: [
            {
                text: 'Open your Gorgias account in the browser',
            },
            {
                text: 'Your subdomain is the name appearing before .gorgias.com',
                imgConfig: { src: gorgiasConnectionImg, alt: 'gorgias connection', width: '80%' },
            },
            {
                showFieldSteps: true,
                config: GORGIAS_FIELDS,
            },
        ],
    },
    klaviyo: {
        helpLink: 'https://help.klaviyo.com/hc/en-us/articles/115005062267#find-your-api-keys2',
        helpLinkText: 'Visit Klaviyo Help Center',
        formFields: KLAVIYO_FIELDS,
        guideStepsTitle: 'Where do I find this API Key?',
        guideSteps: [
            {
                text: 'On your Klaviyo account, click your organization name in the lower left',
            },
            {
                text: 'Navigate to Settings > API Keys (Under Account)',
            },
            {
                text: ['Click on ', <b key="create">“Create Private API key”</b>],
                imgConfig: { src: klaviyoSettingsImg },
            },
            {
                text: ['In the “Private API Key Name” field, enter ', <b key="octup">Octup</b>],
            },
            {
                text: ['Select Access Level as ', <b key="read">“Read-only Key”</b>],
                imgConfig: { src: klaviyoCreatePrivateAPIKeyImg },
            },
            {
                text: ['Click on ', <b key="create">“Create”</b>],
            },
            {
                text: 'Paste the API Key in the field at the top of this modal',
            },
        ],
    },
    returngo: {
        guideSteps: [
            {
                text: 'On your ReturnGo account, navigate to Settings',
                imgConfig: { src: returngoConnectionImg, alt: 'return go connection' },
            },
            {
                showFieldSteps: true,
                config: RETURNGO_FIELDS,
            },
        ],
    },
}

const getSortedFields = (fields = [], config) =>
    [...fields].sort((a, b) => {
        const orderA = config[a.name]?.order
        const orderB = config[b.name]?.order

        return orderA - orderB
    })

const KEY_FIELD_VALIDATION_SCHEMA = string().required(ERROR_MESSAGES.REQUIRED)

const getValidationSchema = (fields = []) =>
    object(
        fields.reduce((hash, { name }) => ({ ...hash, [name]: KEY_FIELD_VALIDATION_SCHEMA }), {})
    )

const getDefaultValues = (fields = []) =>
    fields.reduce((acc, { name }) => ({ ...acc, [name]: '' }), {})

export const getModalContent = (integration) => {
    const modalConfig = MODAL_CONFIG_BY_TITLE[integration.title]
    const validationSchema = getValidationSchema(integration?.input)
    const defaultValues = getDefaultValues(integration?.input)

    const formFields =
        modalConfig.formFields &&
        getSortedFields(integration?.input, modalConfig.formFields).map((field) => ({
            inputConfig: field,
            text: modalConfig.formFields[field.name].text,
        }))

    const guideSteps = modalConfig.guideSteps.reduce((acc, stepConfig) => {
        const currentStep = (acc[acc.length - 1]?.step || 0) + 1
        let currentSteps = [{ step: currentStep, ...stepConfig }]

        if (stepConfig.showFieldSteps) {
            const { config } = stepConfig

            currentSteps = getSortedFields(integration?.input, config).map((field, fieldIndex) => ({
                step: currentStep + fieldIndex,
                inputConfig: field,
                text: config[field.name].text,
            }))
        }

        return [...acc, ...currentSteps]
    }, [])

    return { ...modalConfig, defaultValues, validationSchema, guideSteps, formFields }
}

const FIELD_REGEX = /\{([^{}]+)\}/g

export const getResolvedTemplate = (template = '', data) =>
    template.replace(FIELD_REGEX, (match, fieldName) => {
        if (fieldName in data) {
            return data[fieldName]
        }

        return match
    })
