import { useCallback, useState } from 'react'
import { FieldValues, useFormContext, UseFormReturn } from 'react-hook-form'
import { Button } from '@octup/core/ui-kit'
import { EditDashboardType } from 'models/dashboards'
import { DiscardChangesModalContainer } from 'new_containers/components/__common__'
import { DASHBOARD_SECTIONS_FIELD_NAME } from 'constants/dashboards'

// TODO: re-write the FormContainer to use TypeScript and extend return FormContextPropsType there
type FormContextPropsType<T extends FieldValues> = UseFormReturn<T> & {
    onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const MODAL_CONTENT = {
    header: 'Are you sure you wish to save an empty board?',
}

export const SaveDashboardContainer = () => {
    const { getValues, onSubmit } = useFormContext() as FormContextPropsType<EditDashboardType>
    const [isModalOpen, setIsModalOpen] = useState(false)
    const toggleModalOpen = useCallback(() => setIsModalOpen((open) => !open), [])

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            const sectionIds = getValues(DASHBOARD_SECTIONS_FIELD_NAME)
            const hasData = sectionIds.some(Boolean)
            return hasData ? onSubmit(event) : toggleModalOpen()
        },
        [getValues, onSubmit, toggleModalOpen]
    )

    return (
        <>
            <Button variant="contained" size="small" onClick={handleClick}>
                Save Board
            </Button>
            {/* @ts-expect-error: DiscardChangesModalContainer is a JavaScript file */}
            <DiscardChangesModalContainer
                showDiscardModal={isModalOpen}
                title="Your board is empty"
                discardTitle="Save Anyway"
                onCancel={toggleModalOpen}
                onDiscard={onSubmit}
                content={MODAL_CONTENT}
            />
        </>
    )
}
