// TODO:
// 1. recheck the Chart configuration, remove unnecessary settings
// 2. re-write Chart components
// 3. separate and move into a new folder structure

import { useMemo } from 'react'
import { Box, Grid, List, ListItem, ListItemButton, OverflowTypography } from '@octup/core/ui-kit'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { LoadableContent } from '@/new_components/__common__'
import { Text } from '@/shared/styledComponents'
import { DEFAULT_COLORS, getValueLabel } from './octupChartsUtils'
import { OctupColors } from '../../theme/colors/OctupColors'
import { OctupTextSizes } from '../../theme/texts/OctupTexts'

ChartJS.register(ArcElement, Tooltip, Legend)

export const DoughnutChart = ({
    title,
    subtitle,
    maxSize = '35rem',
    titleFontSize = '3.2rem',
    subtitleFontSize = OctupTextSizes.cardTitle,
    isLoading,
    currency,
    isShopCurrency,
    valueType,
    labels,
    breakSubTitle,
    chartData,
    colorSet = [
        'rgba(80, 85, 90, 1)',
        'rgba(7, 131, 68, 1)',
        'rgba(219, 32, 89, 1)',
        'rgba(0,168,168,1)',
    ],
    alternateColorSet = false,
    legends,
    onLegendClick,
}) => {
    const colors = useMemo(
        () => (alternateColorSet ? colorSet : DEFAULT_COLORS),
        [alternateColorSet, colorSet]
    )

    const data = useMemo(
        () => ({
            labels: [],
            datasets: [
                {
                    label: labels,
                    data: chartData,
                    borderColor: colors,
                    borderWidth: 3.5,
                    spacing:
                        chartData?.filter((el) => el !== undefined && +el !== 0)?.length === 1
                            ? 0
                            : 5,
                    weight: 0,
                },
            ],
        }),
        [chartData, colors, labels]
    )

    const options = useMemo(
        () => ({
            plugins: {
                tooltip: {
                    usePointStyle: true,
                    callbacks: {
                        labelPointStyle: function () {
                            return {
                                pointStyle: true,
                            }
                        },
                        title: function (tooltipItem) {
                            if (tooltipItem.length > 1) return

                            return tooltipItem[0]?.dataset?.label[tooltipItem[0].dataIndex]
                        },
                        label: function (tooltipItem) {
                            return `${getValueLabel({
                                value: tooltipItem?.raw,
                                currency,
                                valueType,
                                isShopCurrency,
                            })}`
                        },
                        labelColor: function (tooltipItem) {
                            return {
                                backgroundColor:
                                    tooltipItem?.dataset?.borderColor[tooltipItem?.dataIndex],
                            }
                        },
                        beforeFooter: function (tooltipBodyItem) {
                            if (tooltipBodyItem.length > 1) {
                                return
                            }
                        },
                    },
                    labelPointStyle: 'circle',
                    intersect: false,
                    backgroundColor: 'white',
                    titleColor: OctupColors.primaryTextDark,
                    titleFontSize: 30,
                    caretSize: 6,
                    titleFont: { family: 'Poppins', color: '#7F7B87', weight: 300 },
                    bodyFont: { family: 'Poppins', weight: 500, size: 12 },
                    borderColor: 'black',
                    footerFont: {
                        size: 2,
                        weight: 300,
                        family: 'Poppins',
                        align: 'center',
                    },
                    footerColor: function (ctx) {
                        function getColorFromNumber(str) {
                            if (str.includes('-')) {
                                return OctupColors.alertLight
                            } else {
                                return OctupColors.successLight
                            }
                        }

                        return getColorFromNumber(ctx?.tooltip?.footer[0])
                    },
                    bodyColor: 'black',
                },
                imageItems: false,
            },
        }),
        [currency, isShopCurrency, valueType]
    )

    const plugins = useMemo(
        () => [
            {
                id: 'textCenter',
                afterDatasetsDraw(chart) {
                    if (title === undefined) return

                    const { ctx } = chart

                    ctx.save()

                    const textColor = OctupColors.primaryTextLight
                    const fontFamily = 'Poppins'
                    const fontWeight = 250
                    const { x: centerX, y: centerY } = chart.getDatasetMeta(0).data[0] || {}
                    const lineHeight = subtitle ? 16 : 0

                    // draw title
                    ctx.font = `${fontWeight} ${titleFontSize} ${fontFamily}`
                    ctx.fillStyle = textColor
                    ctx.textAlign = 'center'
                    ctx.textBaseline = 'middle'
                    ctx.fillText(title, centerX, centerY - lineHeight)

                    // draw subtitle
                    if (subtitle) {
                        const padding = 5
                        const fontWeight = 400
                        const subtitles = breakSubTitle ? subtitle.split(' ') : [subtitle]

                        subtitles.forEach((subtitleItem, index) => {
                            ctx.font = `${fontWeight} ${subtitleFontSize} ${fontFamily}`
                            ctx.fillText(
                                subtitleItem,
                                centerX,
                                centerY + (index + 1) * lineHeight + index * padding
                            )
                        })
                    }

                    ctx.restore()
                },
            },
        ],
        [breakSubTitle, subtitle, subtitleFontSize, title, titleFontSize]
    )

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            gap={2}
            wrap="nowrap"
            height="100%"
        >
            <LoadableContent isLoading={isLoading}>
                <Grid
                    item
                    container
                    justifyContent="center"
                    xs
                    {...(legends && { sm: 5, lg: 6, xl: 5 })}
                >
                    <Box width="100%" maxWidth={maxSize}>
                        <Doughnut
                            key={`${title} ${subtitle || ''}`}
                            options={options}
                            data={data}
                            plugins={plugins}
                        />
                    </Box>
                </Grid>
            </LoadableContent>
            {legends && (
                <Grid
                    item
                    sm={7}
                    lg={6}
                    xl={7}
                    container
                    height="100%"
                    overflow="auto"
                    alignItems="center"
                >
                    <List sx={{ width: '100%' }}>
                        <LoadableContent isLoading={isLoading} itemsCount={3} height="3rem">
                            {legends
                                .map((legend, index) => ({ ...legend, originalIndex: index }))
                                .sort((a, b) => b.value - a.value)
                                .map((legend) => (
                                    <ListItem key={legend.label} disablePadding>
                                        <Grid
                                            container
                                            sx={{ padding: '0.5rem' }}
                                            {...(onLegendClick && {
                                                component: ListItemButton,
                                                onClick: () => onLegendClick(legend),
                                            })}
                                        >
                                            <Grid item xs>
                                                <Text
                                                    color={colors[legend.originalIndex]}
                                                    fontWeight={500}
                                                >
                                                    {getValueLabel({
                                                        value: legend.value,
                                                        valueType,
                                                        currency,
                                                        isShopCurrency,
                                                    })}
                                                </Text>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                gap={2}
                                                alignItems="center"
                                                wrap="nowrap"
                                                sm={9}
                                                lg={8}
                                                xl={9.5}
                                            >
                                                {legend.icon && (
                                                    <img
                                                        src={legend.icon}
                                                        alt={legend.label}
                                                        width={'20rem'}
                                                    />
                                                )}
                                                <OverflowTypography>
                                                    {legend.label}
                                                </OverflowTypography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                ))}
                        </LoadableContent>
                    </List>
                </Grid>
            )}
        </Grid>
    )
}
