import styled from 'styled-components'
import { CardValueWithCharts } from '../../../shared/components/CardValueWithChart/CardValueWithChart'
import { OctupColors } from '../../../shared/theme/colors/OctupColors'

export const renderStyledCardElement = (element, isLoading, index, segment) => {
    return (
        <ElementDiv key={element?.metric?.metricTitle || index}>
            <CardValueWithCharts
                title={element?.metric?.metricTitle}
                description={element?.metric?.description}
                isFilled={true}
                trendValue={element?.metric?.trendValue}
                trendUnits={element?.metric?.trendUnits}
                isPositiveTrend={element?.metric?.isPositiveTrend}
                metricTitleKey={element?.metric?.metricTitle}
                value={element?.metric?.value && element?.metric?.value?.toFixed(2)}
                valueUnits={element?.metric?.valueUnits}
                trend={element?.metric?.trend}
                currency={element?.metric?.currency}
                showChart={true}
                chartData={element?.dataset?.map((el) => el[0])}
                chartLabels={element?.dataset?.map((el) => el[1])}
                timeSlice={element?.metric?.time_slice}
                isLoading={isLoading}
                isDensed={false}
                segment={segment}
                isSupported={element?.metric?.supported}
                isStale={element?.metric?.stale}
                lastUpdatedAt={element?.metric?.last_updated_date}
                sources={element?.metric?.sources}
            />
        </ElementDiv>
    )
}

export const ColoredTitleWithSub = (title, titleColor, sub, subColor) => {
    return (
        <FlexColumnContainer showText={!!title}>
            <ColoredTitleSpan titleColor={titleColor}>{title}</ColoredTitleSpan>
            <ColoredSubtitleSpan subtitleColor={subColor}>{sub}</ColoredSubtitleSpan>
        </FlexColumnContainer>
    )
}

export const colorsToIndexArray = [
    OctupColors.primaryActionableDark,
    OctupColors.accent1,
    OctupColors.alertLight,
    OctupColors.accent2,
    OctupColors.accent3,
]

const ElementDiv = styled.div`
    min-width: 200px;
`

const FlexColumnContainer = styled.div`
    display: ${(props) => (props.showText ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 1rem;
`

const ColoredTitleSpan = styled.span`
    color: ${(props) => props.titleColor};
    font-weight: 275;

    font-size: 4rem;
`

const ColoredSubtitleSpan = styled.span`
    color: ${(props) => props.subtitleColor};
    font-size: 1.5rem;
    line-height: 1.8rem;
`
