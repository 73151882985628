import {
    addDays,
    addMonths,
    addQuarters,
    addWeeks,
    addYears,
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    endOfYear,
    format,
    isSameDay,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
    endOfDay,
} from 'date-fns'
import { DATE_OPTIONS, getDefaultDatePickerObj } from 'features/datePicker/datePickerSlice'
import { defaultStaticRanges } from 'react-date-range'

/**
 *  0: week starts on Sunday
 *  1: week start on Monday
 * */
export const WEEK_START = 0
const UP_TO_DATE = true
const DEFAULT_STATIC_RANGES_BY_LABEL = defaultStaticRanges.reduce(
    (acc, range) => ({ ...acc, [range.label]: range }),
    {}
)

export const STATIC_RANGES = {
    ...DEFAULT_STATIC_RANGES_BY_LABEL,
    [DATE_OPTIONS.THIS_WEEK]: {
        label: DATE_OPTIONS.THIS_WEEK,
        range: () => ({
            startDate: startOfWeek(new Date(), { weekStartsOn: WEEK_START }),
            endDate: UP_TO_DATE
                ? endOfDay(new Date())
                : endOfWeek(new Date(), { weekStartsOn: WEEK_START }),
        }),
        isSelected(range) {
            const definedRange = this.range()
            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            )
        },
    },
    [DATE_OPTIONS.LAST_WEEK]: {
        label: DATE_OPTIONS.LAST_WEEK,
        range: () => ({
            startDate: startOfWeek(addWeeks(new Date(), -1), { weekStartsOn: WEEK_START }),
            endDate: endOfWeek(addWeeks(new Date(), -1), { weekStartsOn: WEEK_START }),
        }),
        isSelected(range) {
            const definedRange = this.range()
            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            )
        },
    },
    [DATE_OPTIONS.THIS_MONTH]: {
        label: DATE_OPTIONS.THIS_MONTH,
        hasCustomRendering: true,
        range: () => ({
            startDate: startOfMonth(new Date()),
            endDate: UP_TO_DATE ? endOfDay(new Date()) : endOfMonth(new Date()),
        }),
        isSelected(range) {
            const definedRange = this.range()
            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            )
        },
    },
    [DATE_OPTIONS.THIS_QUARTER]: {
        label: DATE_OPTIONS.THIS_QUARTER,
        range: () => ({
            startDate: startOfQuarter(new Date()),
            endDate: UP_TO_DATE ? endOfDay(new Date()) : endOfQuarter(new Date()),
        }),
        isSelected(range) {
            const definedRange = this.range()
            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            )
        },
    },
    [DATE_OPTIONS.LAST_QUARTER]: {
        label: DATE_OPTIONS.LAST_QUARTER,
        range: () => ({
            startDate: startOfQuarter(addQuarters(new Date(), -1)),
            endDate: endOfQuarter(addQuarters(new Date(), -1)),
        }),
        isSelected(range) {
            const definedRange = this.range()
            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            )
        },
    },
    [DATE_OPTIONS.THIS_YEAR]: {
        label: DATE_OPTIONS.THIS_YEAR,
        range: () => ({
            startDate: startOfYear(new Date()),
            endDate: UP_TO_DATE ? endOfDay(new Date()) : endOfYear(new Date()),
        }),
        isSelected(range) {
            const definedRange = this.range()

            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            )
        },
    },
    [DATE_OPTIONS.LAST_YEAR]: {
        label: DATE_OPTIONS.LAST_YEAR,
        range: () => ({
            startDate: startOfYear(addYears(new Date(), -1)),
            endDate: endOfYear(addYears(new Date(), -1)),
        }),
        isSelected(range) {
            const definedRange = this.range()
            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            )
        },
    },
}

export const getStaticRanges = ({ hideStaticDates, customStaticRanges }) => {
    if (hideStaticDates) return []

    const currentStaticRanges = customStaticRanges || Object.values(STATIC_RANGES)

    const staticRanges = currentStaticRanges.map((range) => ({
        ...(customStaticRanges ? STATIC_RANGES[range] : range),
        hasCustomRendering: true,
    }))

    return staticRanges
}

export const getDateLabel = (startDate, endDate) => {
    switch (true) {
        case isSameDay(startDate, endDate) &&
            isSameDay(startDate, new Date()) &&
            isSameDay(endDate, new Date()):
            return DATE_OPTIONS.TODAY
        case isSameDay(startDate, endDate) &&
            isSameDay(startDate, addDays(new Date(), -1)) &&
            isSameDay(endDate, addDays(new Date(), -1)):
            return DATE_OPTIONS.YESTERDAY
        case !isSameDay(startDate, endDate) &&
        isSameDay(
            startDate,
            startOfWeek(new Date(), {
                weekStartsOn: WEEK_START,
            })
        ) &&
        UP_TO_DATE
            ? isSameDay(endDate, endOfDay(new Date()))
            : isSameDay(
                  endDate,
                  endOfWeek(new Date(), {
                      weekStartsOn: WEEK_START,
                  })
              ):
            return DATE_OPTIONS.THIS_WEEK
        case !isSameDay(startDate, endDate) &&
            isSameDay(
                startDate,
                startOfWeek(addWeeks(new Date(), -1), {
                    weekStartsOn: WEEK_START,
                })
            ) &&
            isSameDay(
                endDate,
                endOfWeek(addWeeks(new Date(), -1), {
                    weekStartsOn: WEEK_START,
                })
            ):
            return DATE_OPTIONS.LAST_WEEK
        case !isSameDay(startDate, endDate) &&
        isSameDay(startDate, startOfMonth(new Date())) &&
        UP_TO_DATE
            ? isSameDay(endDate, endOfDay(new Date()))
            : isSameDay(endDate, endOfMonth(new Date())):
            return DATE_OPTIONS.THIS_MONTH
        case !isSameDay(startDate, endDate) &&
            isSameDay(startDate, startOfMonth(addMonths(new Date(), -1))) &&
            isSameDay(endDate, endOfMonth(addMonths(new Date(), -1))):
            return DATE_OPTIONS.LAST_MONTH
        case !isSameDay(startDate, endDate) &&
        isSameDay(startDate, startOfQuarter(new Date())) &&
        UP_TO_DATE
            ? isSameDay(endDate, endOfDay(new Date()))
            : isSameDay(endDate, endOfQuarter(new Date())):
            return DATE_OPTIONS.THIS_QUARTER
        case !isSameDay(startDate, endDate) &&
            isSameDay(startDate, startOfQuarter(addQuarters(new Date(), -1))) &&
            isSameDay(endDate, endOfQuarter(addQuarters(new Date(), -1))):
            return DATE_OPTIONS.LAST_QUARTER
        case !isSameDay(startDate, endDate) &&
        isSameDay(startDate, startOfYear(new Date())) &&
        UP_TO_DATE
            ? isSameDay(endOfDay(endDate), endOfDay(new Date()))
            : isSameDay(endDate, endOfYear(new Date())):
            return DATE_OPTIONS.THIS_YEAR
        case !isSameDay(startDate, endDate) &&
            isSameDay(startDate, startOfYear(addYears(new Date(), -1))) &&
            isSameDay(endDate, endOfYear(addYears(new Date(), -1))):
            return DATE_OPTIONS.LAST_YEAR
        default:
            return DATE_OPTIONS.CUSTOM
    }
}

export const getFormattedDate = (datesRange) => ({
    label: getDateLabel(datesRange[0]?.startDate, datesRange[0]?.endDate),
    startDate: format(new Date(datesRange[0]?.startDate), 'yyyy-MM-dd'),
    endDate: format(new Date(datesRange[0]?.endDate), 'yyyy-MM-dd'),
})

export const getDateRange = (data) => [
    {
        ...getDefaultDatePickerObj(data),
        key: 'selection',
        color: '#007f82',
    },
]
