import React from 'react'
import { getIconColor } from './utils'

export function HomeLogoSvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.1813 19.293V15.0544C14.1813 14.8671 14.1047 14.6874 13.9683 14.5549C13.8319 14.4224 13.6469 14.348 13.454 14.348H10.5449C10.352 14.348 10.1671 14.4224 10.0307 14.5549C9.89427 14.6874 9.81765 14.8671 9.81765 15.0544V19.293C9.81765 19.4804 9.74104 19.66 9.60467 19.7925C9.4683 19.925 9.28334 19.9994 9.09047 19.9994L4.72736 20C4.63185 20 4.53727 19.9817 4.44902 19.9462C4.36077 19.9108 4.28059 19.8587 4.21305 19.7931C4.1455 19.7275 4.09192 19.6496 4.05537 19.5639C4.01881 19.4782 4 19.3864 4 19.2936V11.1291C4 11.0307 4.02117 10.9333 4.06216 10.8433C4.10315 10.7533 4.16304 10.6726 4.23801 10.6064L11.5102 4.18375C11.6441 4.06552 11.8185 4.00001 11.9995 4C12.1804 3.99999 12.3549 4.0655 12.4887 4.18372L19.762 10.6064C19.8369 10.6726 19.8968 10.7533 19.9378 10.8433C19.9788 10.9333 20 11.0307 20 11.1291V19.2936C20 19.3864 19.9812 19.4782 19.9446 19.5639C19.9081 19.6496 19.8545 19.7275 19.787 19.7931C19.7194 19.8587 19.6392 19.9108 19.551 19.9463C19.4627 19.9817 19.3682 20 19.2726 20L14.9085 19.9994C14.7156 19.9994 14.5306 19.925 14.3943 19.7925C14.2579 19.66 14.1813 19.4804 14.1813 19.293V19.293Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
