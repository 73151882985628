import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getProducts } from 'services'

const PRODUCTS_INITIAL_STATE = {
    data: null,
    isLoading: false,
    error: null,
}

export const fetchCostManageProducts = createAsyncThunk(
    'fetchCostManageProducts',
    async (data, thunkAPI) => getProducts(data, thunkAPI)
)

export const productsSlice = createSlice({
    name: 'costManageProducts',
    initialState: PRODUCTS_INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(fetchCostManageProducts.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchCostManageProducts.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchCostManageProducts.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const productsReducer = productsSlice.reducer
