// TODO: re-write
// 1. move into a new folder structure
// 2. add styles file
// 3. re-write PageLayout and Table components to avoid fixed height numbers

import PageLayout from '../../components/PageLayout/PageLayout'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { OctupBaseTable } from '../../shared/ui/Tables/OctupBaseTable'
import { getShortTableDate } from '../../shared/utils/dates-util'
import styled from 'styled-components'
import { OctupBaseModal } from '../../shared/ui/Modal/OctupBaseModal'
import { useOctupDispatch } from '../../hooks/useOctupDispatch'
import { OctupAvatar } from '../../shared/ui/Avatar/OctupAvatar'
import { InviteUserModal } from './InviteUserModal'
import { addHeapEvent } from 'utils'
import { Box, Button, Grid, Typography } from '@mui/material'
import { UserExtraOptions } from './UserExtraOptions'
import { sendInvites, validateEmail } from '../../features/auth/authSlice'
import { useToast } from '../../hooks/useToastContext'
import { PlusIconSvg } from '../../shared/assets/icons/PlusIconSvg'
import { isEmailValid } from '../../shared/ui/Inputs/inputUtils'
import { InviteSentBadge } from '../../shared/assets/icons/InviteSentBadge'
import { CardExpendable } from 'shared/ui/Card/CardExpendable'

const HEADERS = ['Full Name', 'Email', 'Role', 'Member Since', '']

const UserManagementPage = () => {
    const dispatch = useDispatch()
    const octupDispatch = useOctupDispatch()
    const toast = useToast()

    const { userInfo } = useSelector((state) => state.auth)
    const { users, isLoading } = useSelector((state) => state.users)

    const [usersState, setUsersState] = useState({
        headers: HEADERS,
        rows: [],
    })
    const [showModal, setShowModal] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')

    const renderRows = (el) => {
        return (
            <>
                <StyledTd>
                    <OctupAvatar
                        title={el?.fullName ? el?.fullName : el.email}
                        imageSrc={el?.imageSrc}
                        size={'large'}
                        initialsSize={'5.2rem'}
                    />
                    <Span marginLeft={'-2rem'}>{el.fullName}</Span>
                </StyledTd>
                <StyledTd>
                    <Span>{el.email}</Span>
                </StyledTd>
                <StyledTd>
                    <Span color="#504B5A">{el?.role}</Span>
                </StyledTd>
                <StyledTd>
                    <Span color="#504B5A">{getShortTableDate(el.created_at).primaryFormat}</Span>
                </StyledTd>
                <StyledTd>
                    <RelativeContainer>
                        <InviteSentContainer>
                            {!el?.isActive && <InviteSentBadge />}
                        </InviteSentContainer>
                        <UserExtraOptions userId={el?.id} />
                    </RelativeContainer>
                </StyledTd>
            </>
        )
    }

    useEffect(() => {
        octupDispatch.users.getUsers()
    }, [octupDispatch.users])

    useEffect(() => {
        if (!users) return
        setUsersState((prevState) => {
            return { ...prevState, rows: users }
        })
    }, [users])

    return (
        <PageLayout showDatePicker={false}>
            {showModal && (
                <OctupBaseModal
                    title="Add a member to your team"
                    buttons={[
                        {
                            title: 'cancel',
                            variant: 'secondary',
                            onClick: () => {
                                setShowModal(false)
                            },
                        },
                        {
                            title: 'Invite',
                            variant: 'contained',
                            onClick: async () => {
                                if (isEmailValid(email)) {
                                    const validateEmailRes = await dispatch(
                                        validateEmail({ email })
                                    )
                                    if (validateEmailRes?.payload?.valid) {
                                        setShowModal(false)
                                        const response = await dispatch(
                                            sendInvites({ emails: [email], token: userInfo?.token })
                                        )
                                        if (response?.meta?.requestStatus === 'fulfilled') {
                                            toast.success(`Invite has been sent to ${email}`)
                                        }
                                        setEmail('')
                                        octupDispatch.users.getUsers()
                                    } else {
                                        setEmailError(validateEmailRes?.payload?.message)
                                    }
                                } else {
                                    setEmailError('Please enter a valid email address\n' + '\n')
                                }
                            },
                        },
                    ]}
                    onDismissModal={() => {
                        setEmail('')
                        setShowModal(false)
                    }}
                >
                    <InviteUserModal
                        email={email}
                        setEmail={setEmail}
                        onCancel={() => setShowModal(false)}
                        emailError={emailError}
                        setEmailError={setEmailError}
                    />
                </OctupBaseModal>
            )}
            <Box maxHeight="calc(100% - 10rem)" width="100%">
                <CardExpendable>
                    <Grid container direction="column" wrap="nowrap" height="100%">
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            padding={2.5}
                            paddingBottom={0}
                        >
                            <Grid item>
                                <Typography variant="h5" fontWeight={500}>
                                    {usersState?.rows?.length} Members
                                </Typography>
                            </Grid>
                            <Grid item>
                                <StyledButton
                                    onClick={() => {
                                        addHeapEvent('Open User Invite Modal')
                                        setShowModal(true)
                                    }}
                                >
                                    <PlusIconSvg />
                                    <StyledButtonText>Add New Member</StyledButtonText>
                                </StyledButton>
                            </Grid>
                        </Grid>
                        <Grid item container justifySelf="flex-end" maxHeight="calc(100% - 5.3rem)">
                            <OctupBaseTable
                                hiddenRowKey={'id'}
                                isRowsLoading={isLoading}
                                showSelectedRow={false}
                                showSearchFilter={false}
                                showFilters={false}
                                showCounter={false}
                                showDotMenu={false}
                                tableData={usersState}
                                renderRows={renderRows}
                                defaultSortBy={'Full Name'}
                                sortingHeadersToDataMapping={{
                                    fullName: 'Full Name',
                                    email: 'email',
                                    role: 'Role',
                                    memberSince: 'memberSince',
                                }}
                                customRowStyle={{ height: '9.4rem' }}
                                searchFilterPlaceholder={'Search...'}
                                searchFilterFields={{
                                    fullName: 'Full Name',
                                    email: 'email',
                                    role: 'Role',
                                    memberSince: 'Member Since',
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardExpendable>
            </Box>
        </PageLayout>
    )
}

const Span = styled.div`
    font-weight: 400;
    font-size: 14px !important;
    line-height: 14px;
    color: ${(p) => p.color};
    margin-left: ${(p) => p.marginLeft};
`

const StyledTd = styled.td`
    height: 75px;
    text-transform: none !important;
`

const StyledButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px 12px 8px;
    gap: 8px;
    width: 192px;
    height: 32px;
    background: #f8f7fb;
    box-shadow:
        -2px -2px 8px #ffffff,
        2px 2px 4px rgba(80, 75, 90, 0.16);
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 2rem;
`

const StyledButtonText = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #007f82;
    text-transform: none !important;
    white-space: nowrap;
`

const InviteSentContainer = styled.div`
    min-width: 15rem;
`

const RelativeContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-around;
`

export default UserManagementPage
