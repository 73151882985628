import { useMemo } from 'react'
import { Stack } from '@octup/core/ui-kit'
import { OctupBadge } from '../../Badges/OctupBadge'
import { CardExpendable } from '../../Card/CardExpendable'
import styled from 'styled-components'
import { ConnectionSvg } from '../../../assets/icons/ConnectionSvg'
import { MarketingLogoSvg } from '../../../assets/icons/MarketingLogoSvg'
import { OctupTooltip } from '../../MUIWrappers/OctupTooltip'
import { CardMenuActionButtons } from '../CardMenuActionButtons'
import { getIntegrationTitle } from 'new_containers/components/Integrations/utils'
import { DisconnectButtonContainer } from 'new_containers/components/Integrations'

export const CardMenuItem = ({
    title: integrationName = '',
    description = '',
    icon = ConnectionSvg,
    isActive = false,
    isConnected = false,
    label = { title: 'No Title', icon: MarketingLogoSvg },
    customCardStyle,
    showTooltip = true,
    badgeObj = {
        theme: 'orange',
        title: 'coming soon',
        icon: null,
    },
    onDisconnectClick,
}) => {
    const LabelIcon = label.icon
    const integrationTitle = useMemo(() => getIntegrationTitle(integrationName), [integrationName])

    return (
        <CardMenuContainer>
            <CardExpendable className={'integrations-cards'} customStyle={customCardStyle}>
                <CardItemDataContainer className={integrationName} isActive={isActive}>
                    <Stack
                        width="100%"
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {icon && <StyledIcon src={icon} />}
                        <Stack
                            width="100%"
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            justifyContent="flex-end"
                        >
                            {badgeObj && <OctupBadge {...badgeObj} />}
                            {isConnected && (
                                <DisconnectButtonContainer
                                    integrationName={integrationName}
                                    integrationTitle={integrationTitle}
                                    onClick={onDisconnectClick}
                                />
                            )}
                        </Stack>
                    </Stack>

                    <CardLabelContainer>
                        {label.icon && <LabelIcon customColor={'#7f7b87'} />}{' '}
                        {label.title && label.title}{' '}
                    </CardLabelContainer>
                    <CardDetailsContainer>
                        <CardDetailsTitle>{integrationTitle}</CardDetailsTitle>
                        <OctupTooltip title={showTooltip && description} position="top">
                            <CardDetailsDesc>{description}</CardDetailsDesc>
                        </OctupTooltip>
                    </CardDetailsContainer>
                    <CardFooter>
                        <CardMenuActionButtons
                            isConnected={isConnected}
                            integrationName={integrationName}
                        />
                    </CardFooter>
                </CardItemDataContainer>
            </CardExpendable>
        </CardMenuContainer>
    )
}

const CardMenuContainer = styled.div`
    width: 100%;
    height: fit-content;
    max-width: 100%;
`

const CardItemDataContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 32rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 2.5rem;
    opacity: ${(props) => (props.isActive ? '1' : '.4')};
    :hover {
        cursor: ${(props) => (props.isActive ? 'pointer' : 'not-allowed')};
    }
`

const CardLabelContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: capitalize;
    gap: 1rem;
    height: 5rem;
    margin: 1rem 0;
    color: #7f7b87;
    font-size: 1.4rem;
    svg {
        color: #7f7b87;
    }
`

const CardDetailsContainer = styled.div`
    width: 100%;
    min-height: 13rem;
    height: 13rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
`

const CardDetailsTitle = styled.div`
    height: 4rem;
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
    font-size: 1.7rem;
    line-height: 2.2rem;
    font-weight: 600;
`

const CardDetailsDesc = styled.div`
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    height: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: pre-wrap; /* let the text wrap preserving spaces */
    -webkit-line-clamp: 4; /* start showing ellipsis when 4rd line is reached */
`

const CardFooter = styled.div`
    height: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const StyledIcon = styled.img`
    max-width: 4.5rem;
    max-height: 4.5rem;
`
