import styled from 'styled-components'
import { CardBase } from '../../ui/Card/CardBase'
import { ChartsArea } from '../../ui/Charts/ChartsArea'
import { MobileMetricItemWithChart } from '../../../components/Metrics/MetricItem/MobileMetricItemWithChart'

const renderChart = (showChart, chartData, chartLabels, isFilled = false, timeSlice) => {
    if (showChart) {
        return (
            <ChartContainer>
                <Wrapper width={window.innerWidth / 52}>
                    <ChartsArea
                        showGradient
                        showXAxis={true}
                        showYAxis={false}
                        showSecondYAxis={false}
                        isFilled={isFilled}
                        chartsDataSets={[{ data: chartData, label: '' }]}
                        labels={chartLabels}
                        showLabels={false}
                        showTarget={true}
                        showLegend={false}
                        timeSlice={timeSlice}
                    />
                </Wrapper>
            </ChartContainer>
        )
    }
}

export const MobileCardValueWithCharts = ({
    title,
    value,
    trend,
    showChart,
    chartData,
    chartLabels,
    timeSlice,
    isLoading,
    isRejected,
    currency,
    valueUnits,
    direction,
    isDensed,
    showCard = true,
    isFilled = false,
    metricTitleKey,
    trendUnits,
    trendValue,
    isSupported,
    isPositiveTrend,
    isStale,
    lastUpdatedAt,
}) => {
    const renderMainContainerContent = () => {
        return (
            <MainContainer>
                {!isRejected && (
                    <MobileMetricItemWithChart
                        isStale={isStale}
                        lastUpdatedAt={lastUpdatedAt}
                        trendUnits={trendUnits}
                        trendValue={trendValue}
                        hasDataset={chartData && chartData && chartData.length > 0}
                        isPositiveTrend={isPositiveTrend}
                        isLoading={isLoading}
                        key={title}
                        title={title}
                        value={value}
                        trend={trend}
                        currency={currency}
                        valueUnits={valueUnits}
                        direction={direction}
                        showSeperator={false}
                        isDensed={false}
                        metricTitleKey={metricTitleKey}
                        isSupported={isSupported}
                        renderChart={() =>
                            chartData &&
                            chartData.length > 0 &&
                            renderChart(showChart, chartData, chartLabels, isFilled, timeSlice)
                        }
                    />
                )}
            </MainContainer>
        )
    }

    return (
        <>
            {showCard ? (
                <CardBase height={'100%'}>{renderMainContainerContent()}</CardBase>
            ) : (
                <>{renderMainContainerContent()}</>
            )}
        </>
    )
}

const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 2vh;
`

const ChartContainer = styled.div`
    width: 100%;
`

const Wrapper = styled.div`
    padding-right: 2rem;
`
