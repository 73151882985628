import type { SettingsType, WorkingHoursSettingsType } from '@/models/settings'
import { WEEKDAYS } from '@/models/settings'

const WEEKDAYS_LIST = Object.values(WEEKDAYS)

export const getWorkingHoursData = ({ working_hours }: SettingsType): WorkingHoursSettingsType => ({
    ...working_hours,
    hours_mapping: working_hours.hours_mapping.sort(
        (a, b) => WEEKDAYS_LIST.indexOf(a.day) - WEEKDAYS_LIST.indexOf(b.day)
    ),
})
