import * as React from 'react'
import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

const OctupSlider = styled(Slider)({
    color: 'rgba(0, 168, 168, 1)',
    height: 3,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    '& .MuiSlider-valueLabelLabel': {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        fontFamily: 'Poppins',
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: '2.5rem',
        width: '2.5rem',
        backgroundColor: 'rgba(248, 247, 251, 1)',
        border: '.5px solid rgba(255, 255, 255, 0.4)',
        boxShadow: '2px 2px 4px 0px rgba(80, 75, 90, 0.2)',

        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: '2px 2px 4px 0px rgba(80, 75, 90, 0.2)',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.5,
        background: 'unset',
        padding: 0,
        position: 'absolute',
        transform: 'translateY(60%) !important',
        color: 'rgba(0, 127, 130, 1)',
        fontFamily: 'Poppins',
    },
})

export default function CustomizedOctupSlider({ min, max, setRange }) {
    function valuetext(value) {
        return `${value}`
    }

    const minDistance = 1
    const [value2, setValue2] = React.useState([min, max])

    React.useEffect(() => {
        setRange({ min: value2[0], max: value2[1] })
    }, [value2])

    const handleChange2 = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return
        }

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 15 - minDistance)
                setValue2([clamped, clamped + minDistance])
            } else {
                const clamped = Math.max(newValue[1], minDistance)
                setValue2([clamped - minDistance, clamped])
            }
        } else {
            setValue2(newValue)
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', lineHeight: 1, gap: '1rem' }}>
            <div
                style={{ color: 'rgba(164, 160, 170, 1)', fontSize: '1.5rem', minWidth: '2.5rem' }}
            >
                {value2[0] + ' < '}
            </div>
            <Box sx={{ width: 100 }}>
                <OctupSlider
                    getAriaLabel={() => ''}
                    value={value2}
                    onChange={handleChange2}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    disableSwap
                    min={1}
                    max={15}
                />
            </Box>
            <div
                style={{ color: 'rgba(164, 160, 170, 1)', fontSize: '1.5rem', minWidth: '2.5rem' }}
            >
                {value2[1] + '+'}
            </div>
        </div>
    )
}
