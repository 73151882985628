export const VALUE_TYPE_INDEX = {
    NUMBER: 1,
    PERCENTAGE: 2,
    ABSOLUTE: 3,
}

const VALUE_TYPE_TO_INDEX_MAP = {
    fraction: VALUE_TYPE_INDEX.NUMBER,
    percentage: VALUE_TYPE_INDEX.PERCENTAGE,
    absolute: VALUE_TYPE_INDEX.ABSOLUTE,
}

export const getValueTypeIndex = (valueType) => VALUE_TYPE_TO_INDEX_MAP[valueType] || +valueType

const userInfo = JSON.parse(localStorage.getItem('userInfo'))
const shop_currency = (userInfo?.client?.currency && `${userInfo?.client?.currency}`) || ''
export const getCurrencySymbol = (currency) => {
    if (!currency) return ''
    return (0)
        .toLocaleString('en-US', {
            style: 'currency',
            currency: shop_currency || currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim()
}

export const getFormattedVal = ({
    val,
    valueType,
    customNumOfDigits,
    currency,
    isSupported,
    multiplyBy100,
    showSymbol,
    isShopCurrency,
}) => {
    const valueTypeIndex = getValueTypeIndex(valueType)

    let ans
    val = +val
    const numOfDigits =
        customNumOfDigits === 0 || +valueTypeIndex === VALUE_TYPE_INDEX.ABSOLUTE
            ? 0
            : customNumOfDigits ||
              (val > 1000 && +valueTypeIndex !== VALUE_TYPE_INDEX.PERCENTAGE
                  ? 0
                  : val < 10 || +valueTypeIndex === VALUE_TYPE_INDEX.PERCENTAGE
                    ? 2
                    : 1)
    if (isNaN(val) || !isFinite(val) || (!isSupported && !val)) {
        ans = isSupported ? 0 : '-'
    } else if (+val !== 0 && !val && isSupported) ans = 0
    else if (+valueTypeIndex === VALUE_TYPE_INDEX.PERCENTAGE) {
        ans =
            (multiplyBy100 === false ? val : val * 100)?.toFixed(
                customNumOfDigits !== undefined ? +customNumOfDigits : 2
            ) + '%'
    } else if (
        +valueTypeIndex === VALUE_TYPE_INDEX.ABSOLUTE ||
        +valueTypeIndex === VALUE_TYPE_INDEX.NUMBER
    ) {
        const currentCurrency = currency || (isShopCurrency && shop_currency)

        if (currentCurrency && showSymbol) {
            ans = val?.toLocaleString('en-US', {
                style: 'currency',
                currency: currentCurrency,
                minimumFractionDigits: numOfDigits,
                maximumFractionDigits: numOfDigits,
            })
        } else
            ans = val?.toLocaleString('en-US', {
                minimumFractionDigits: numOfDigits,
                maximumFractionDigits: numOfDigits,
            })
    } else ans = isSupported ? val : '-'

    return ans
}

export const getFormattedTrend = (val, isSupported, multiplyBy100, customNumOfDigits) => {
    const ans = getFormattedVal({
        val,
        valueType: VALUE_TYPE_INDEX.PERCENTAGE,
        customNumOfDigits: customNumOfDigits || 0,
        currency: null,
        isSupported: isSupported,
        multiplyBy100: multiplyBy100,
        showSymbol: false,
    })
    return +ans === 0 ? 0 : +ans?.replace(/%/g, '') > 0 ? `+${ans}` : ans
}

export const getFinitePercentageValue = (percentage) =>
    isFinite(percentage)
        ? percentage === 0
            ? 0
            : percentage
        : percentage > 0
          ? 1
          : percentage < 0
            ? -1
            : 0

export const getChangePercentageValue = (
    currVal,
    prevVal,
    isSupported = true,
    multiplyBy100,
    isTrend
) => {
    //infinite
    let ans

    if (!prevVal && prevVal !== 0) return

    if (!isSupported) ans = '-'
    else {
        if (!currVal && isSupported) currVal = 0

        let change_precentage = parseFloat((currVal - prevVal) / prevVal)
        change_precentage = getFinitePercentageValue(change_precentage)

        ans = isTrend
            ? getFormattedTrend(change_precentage, isSupported, multiplyBy100)
            : getFormattedVal({
                  val: (currVal - prevVal) / prevVal,
                  valueType: VALUE_TYPE_INDEX.PERCENTAGE,
                  customNumOfDigits: null,
                  currency: null,
                  isSupported: isSupported,
                  multiplyBy100: multiplyBy100,
                  showSymbol: false,
              })
    }
    return ans
}
