import { CheckmarkSvg } from 'shared/assets/icons'
import { OctupIcons } from 'shared/assets/OctupIcons'

const DATA_SEGMENTS = [
    'marketing',
    'operation',
    'onlinestore',
    'shipping',
    'product',
    'lastmile',
    'Manual',
    'unitEconomics',
    'commerce',
]

export const getIconJsx = (types) => {
    const stringArray =
        types && types.split(' ').map((el) => el.charAt(0).toUpperCase() + el.slice(1))
    const IconType =
        !types || !DATA_SEGMENTS.includes(types.replace(/\s/g, '').toLowerCase())
            ? 'NoIcon'
            : stringArray.join('')
    return OctupIcons[`ActionsBtn${IconType}Icon`]
}

export const getCardsData = (integrations) =>
    integrations?.map((el) => ({
        ...el,
        icon: el?.imageSrc,
        label: { title: el?.segment, icon: getIconJsx(el?.segment) },
        badgeObj: el.isConnected
            ? {
                  theme: 'green',
                  title: 'connected',
                  icon: CheckmarkSvg,
              }
            : null,
    }))
