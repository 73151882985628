import React from 'react'

export function DownloadIconSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path d="M8 11.0356L12 15L16 11.0356" fill="#F8F7FB" />
            <path
                d="M8 11.0356L12 15L16 11.0356"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.031 6L12.031 14.5528"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="5.75"
                y1="18.25"
                x2="18.25"
                y2="18.25"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    )
}
