import { addWeeks, addMonths, format } from 'date-fns'

export const DISMISS_PERIOD_FIELD_NAME = 'DISMISS_PERIOD_FIELD'

export const DISMISS_PERIOD = {
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    FOREVER: 'FOREVER',
    CUSTOM: 'CUSTOM',
}

export const DISMISS_PERIOD_DROPDOWN_OPTIONS = [
    { name: 'One week', value: DISMISS_PERIOD.WEEK },
    { name: 'One month', value: DISMISS_PERIOD.MONTH },
    { name: 'Forever', value: DISMISS_PERIOD.FOREVER },
]

export const DISMISS_DATE_BY_PERIOD = {
    [DISMISS_PERIOD.WEEK]: addWeeks(new Date(), 1),
    [DISMISS_PERIOD.MONTH]: addMonths(new Date(), 1),
}

export const getDismissDate = (dismissData) => {
    if (dismissData.type === DISMISS_PERIOD.FOREVER) {
        return
    }

    if (dismissData.type === DISMISS_PERIOD.CUSTOM) {
        return dismissData.value
    }

    return format(DISMISS_DATE_BY_PERIOD[dismissData.type], 'yyyy-MM-dd')
}

export const getDismissPeriodValidation = (dismissData) => {
    if (!dismissData) {
        return {
            [DISMISS_PERIOD_FIELD_NAME]: 'Please select a period or a specific day',
        }
    }
}
