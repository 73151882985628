import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ORDERS_API_URL, getOrders } from 'services'

const ORDERS_INITIAL_STATE = {
    data: null,
    isLoading: false,
    error: null,
}

export const fetchOrders = createAsyncThunk(`${ORDERS_API_URL}`, async (data, thunkAPI) =>
    getOrders(data, thunkAPI)
)

export const ordersSlice = createSlice({
    name: 'orders',
    initialState: ORDERS_INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrders.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchOrders.rejected, (state, action) => {
                state.error = 'Failed to fetch data from server: ' + action.error.message
                state.isLoading = false
            })
    },
})

export const ordersReducer = ordersSlice.reducer
