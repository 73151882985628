import { Checkbox, Grid } from '@octup/core/ui-kit'
import { FormFieldContainer } from '@/new_containers/components/__common__'
import { Text } from '@/shared/styledComponents'
import { OctupBaseModal } from '@/shared/ui/Modal/OctupBaseModal'
import { modalContainerStyle } from './DisconnectModal.styles'

export const DisconnectModal = ({ actionButtons, integrationTitle, onDismiss, ...props }) => (
    <OctupBaseModal
        title={`Disconnect ${integrationTitle}`}
        onDismissModal={onDismiss}
        buttons={actionButtons}
        {...props}
    >
        <Grid container flexDirection="column" spacing="1.2rem" sx={modalContainerStyle}>
            <Grid item>
                <Text fontWeight="bold">
                    Do you wish to disconnect the integration with {integrationTitle}?
                </Text>
            </Grid>
            <Grid item>
                <FormFieldContainer
                    as={Checkbox}
                    name="isKeepData"
                    label={<Text fontSize="1.6rem">Keep historical data</Text>}
                />
            </Grid>
        </Grid>
    </OctupBaseModal>
)
