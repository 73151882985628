import React from 'react'
import { OctupColors } from '../../theme/colors/OctupColors'

export function OctupLogo({ size = '4.8rem', active, customActiveColor, customColor }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.125 45C35.8946 45 44.625 36.2696 44.625 25.5C44.625 14.7304 35.8946 6 25.125 6C14.3554 6 5.625 14.7304 5.625 25.5C5.625 36.2696 14.3554 45 25.125 45ZM25.125 43.125C34.859 43.125 42.75 35.234 42.75 25.5C42.75 15.766 34.859 7.875 25.125 7.875C15.391 7.875 7.5 15.766 7.5 25.5C7.5 35.234 15.391 43.125 25.125 43.125Z"
                fill="url(#paint0_linear_792_2033)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.25 45.75C38.2622 45.75 48 36.0122 48 24C48 11.9878 38.2622 2.25 26.25 2.25C14.2378 2.25 4.5 11.9878 4.5 24C4.5 36.0122 14.2378 45.75 26.25 45.75ZM26.25 43.4196C36.9752 43.4196 45.6696 34.7252 45.6696 24C45.6696 13.2748 36.9752 4.58036 26.25 4.58036C15.5248 4.58036 6.83036 13.2748 6.83036 24C6.83036 34.7252 15.5248 43.4196 26.25 43.4196Z"
                fill="url(#paint1_linear_792_2033)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.375 45.375C36.8014 45.375 46.875 35.3014 46.875 22.875C46.875 10.4486 36.8014 0.375 24.375 0.375C11.9486 0.375 1.87496 10.4486 1.87496 22.875C1.87496 35.3014 11.9486 45.375 24.375 45.375ZM24.3749 43.875C35.9729 43.875 45.3749 34.473 45.3749 22.875C45.3749 11.277 35.9729 1.875 24.3749 1.875C12.777 1.875 3.37493 11.277 3.37493 22.875C3.37493 34.473 12.777 43.875 24.3749 43.875Z"
                fill="url(#paint2_linear_792_2033)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM28.7117 45.9107C27.1411 45.8732 25.5711 45 24 45C12.402 45 3 35.598 3 24C3 12.402 12.402 3 24 3C35.598 3 45 12.402 45 24C45 25.8217 44.768 27.5893 44.332 29.2748C44.2182 29.7145 44.3615 30.1894 44.3615 30.6437C44.3615 30.9823 44.213 31.2859 43.978 31.4922C43.7362 31.7043 43.4624 31.9091 43.3366 32.2051C43.1458 32.6541 43.3209 33.1736 43.5025 33.6263C43.572 33.7998 43.6103 33.9893 43.6103 34.1879C43.6103 35.0163 42.9436 35.6879 42.1211 35.6879C41.7702 35.6879 41.391 35.7798 41.1891 36.0669C41.1287 36.1527 41.0677 36.2381 41.0061 36.323C40.7157 36.723 40.7409 37.2653 40.8336 37.7508C40.8556 37.8661 40.8672 37.9852 40.8672 38.107C40.8672 39.1426 40.0336 39.982 39.0054 39.982C38.8355 39.982 38.6709 39.9591 38.5146 39.9162C38.1788 39.824 37.7986 39.8346 37.5324 40.0591C37.2968 40.2578 37.2061 40.5752 37.2283 40.8826C37.2326 40.942 37.2348 41.002 37.2348 41.0625C37.2348 42.4087 36.1511 43.5 34.8142 43.5C34.5222 43.5 34.2423 43.4479 33.9831 43.3526C33.463 43.1611 32.8796 43.044 32.3714 43.2651C31.9534 43.447 31.6584 43.8238 31.4584 44.2334C30.9732 45.2274 29.9577 45.9115 28.7836 45.9115C28.7595 45.9115 28.7356 45.9113 28.7117 45.9107Z"
                fill="url(#paint3_linear_792_2033)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.9179 31.0187C43.3293 31.0187 43.6627 30.6829 43.6627 30.2687C43.6627 29.8544 43.3293 29.5187 42.9179 29.5187C42.5066 29.5187 42.1731 29.8544 42.1731 30.2687C42.1731 30.6829 42.5066 31.0187 42.9179 31.0187ZM42.5513 33.8129C42.5513 34.4342 42.0511 34.9379 41.4341 34.9379C40.8171 34.9379 40.3169 34.4342 40.3169 33.8129C40.3169 33.1915 40.8171 32.6879 41.4341 32.6879C42.0511 32.6879 42.5513 33.1915 42.5513 33.8129ZM38.6279 39C39.3477 39 39.9313 38.4124 39.9313 37.6875C39.9313 36.9626 39.3477 36.375 38.6279 36.375C37.908 36.375 37.3245 36.9626 37.3245 37.6875C37.3245 38.4124 37.908 39 38.6279 39ZM36.3013 40.7306C36.3013 41.6626 35.551 42.4181 34.6254 42.4181C33.6999 42.4181 32.9496 41.6626 32.9496 40.7306C32.9496 39.7986 33.6999 39.0431 34.6254 39.0431C35.551 39.0431 36.3013 39.7986 36.3013 40.7306ZM30.9123 42.5625C30.9123 43.7016 29.9953 44.625 28.8641 44.625C27.7329 44.625 26.8158 43.7016 26.8158 42.5625C26.8158 41.4234 27.7329 40.5 28.8641 40.5C29.9953 40.5 30.9123 41.4234 30.9123 42.5625Z"
                fill="url(#paint4_linear_792_2033)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_792_2033"
                    x1="17.2397"
                    y1="7.85643"
                    x2="33.3174"
                    y2="35.655"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7737FF" />
                    <stop offset="1" stopColor="#00A0A0" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_792_2033"
                    x1="16.4412"
                    y1="3.95588"
                    x2="34.3341"
                    y2="36.3186"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF3489" />
                    <stop offset="1" stopColor="#8627FF" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_792_2033"
                    x1="13.7867"
                    y1="3.40021"
                    x2="33.4507"
                    y2="37.6228"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFCF72" />
                    <stop offset="1" stopColor="#FF3489" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_792_2033"
                    x1="23.2696"
                    y1="40.875"
                    x2="29.8752"
                    y2="17.2675"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00C8C0" />
                    <stop offset="1" stopColor="#00A0A0" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_792_2033"
                    x1="35.751"
                    y1="38.625"
                    x2="43.5803"
                    y2="17.422"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00C8C0" />
                    <stop offset="1" stopColor="#00A0A0" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    )
}
