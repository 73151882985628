// TODO: re-write Sidebar

import { useEffect, useState } from 'react'
import { Grid, Stack, SvgIcon } from '@octup/core/ui-kit'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { EditObjectiveModal } from '@/components/Modals/ObjectivesModals/ObjectivesModal'
import { useOctupDispatch } from '@/hooks'
import { APP_ROUTES, DEFAULT_ROUTES } from '@/models/appConfig'
import { HamburgerLeftIcon, HamburgerRightIcon } from '@/new_assets/icons'
import { LoadableContent } from '@/new_components/__common__'
import { CompanyNameLogoContainer } from '@/new_containers/components/__common__'
import { OctupTooltip } from '@/shared/ui/MUIWrappers/OctupTooltip'
import {
    NavBreak,
    Nav,
    NavSystemMenu,
    NavUpperMenu,
    NavHeaderContainer,
    NavHamborger,
} from './Sidebar.styles'
import SidebarItem from './SidebarItem/SidebarItem'
import { useSideBarData } from './useSidebarData'

const Sidebar = () => {
    const octupDispatch = useOctupDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const navigationState = useSelector((state) => state.navigation)
    const { isMainMenuCollapsed, isMainMenuCollapsedBtnActive } = navigationState
    const [objectiveData, setObjectiveData] = useState(null)
    const [showEditObjectiveModal, setShowEditObjectiveModal] = useState({
        show: false,
        isEditState: false,
    })
    const sidebarData = useSideBarData()
    const { settings, excludedFeatures } = useSelector((state) => state.appConfig)
    const { palette, palette_mode } = settings.data

    useEffect(() => {
        octupDispatch.sidebar.setActivePage(location.pathname.replace('/', '').split('/')?.[0])
    }, [location, octupDispatch.sidebar])

    const dismissEditObjectiveModal = () => {
        setShowEditObjectiveModal({ show: false, isEditState: false })
    }
    useEffect(() => {
        setObjectiveData({
            title: 'new',
            origin: 'Manual',
            segment: 'Manual',
            actionableItem: {
                message: '',
                requiredAction: '',
            },
        })
    }, [])

    const renderSidebarItem = (el, isLast = false) => (
        <SidebarItem
            icon={el.icon}
            disabled={el.disabled}
            navItem={el}
            key={el.title}
            setShowEditObjectiveModal={setShowEditObjectiveModal}
            isNav={el.isNav}
            customClickHandler={el.customClickHandler}
            isIconSVG={el.isIconSVG}
            isLast={isLast}
            externalMenu={el.externalMenu}
        />
    )

    return (
        <Nav
            isMainMenuCollapsed={isMainMenuCollapsed}
            onMouseEnter={() => {
                if (isMainMenuCollapsed && isMainMenuCollapsedBtnActive)
                    octupDispatch.sidebar.setIsMainMenuCollapsed(false)
            }}
            onMouseLeave={() => {
                if (!isMainMenuCollapsed && isMainMenuCollapsedBtnActive)
                    octupDispatch.sidebar.setIsMainMenuCollapsed(false)
            }}
        >
            <Grid container direction="column" height="100%" wrap="nowrap">
                <Grid item container>
                    <NavHeaderContainer
                        isMainMenuCollapsed={isMainMenuCollapsed}
                        palette={palette}
                        paletteMode={palette_mode}
                    >
                        <Stack
                            height="100%"
                            justifyContent="center"
                            sx={(theme) => ({ paddingTop: theme.spacing(0.5) })}
                            onClick={() => {
                                octupDispatch.sidebar.setActivePage('home')
                                navigate(DEFAULT_ROUTES.MAIN)
                            }}
                        >
                            <Stack sx={{ visibility: isMainMenuCollapsed ? 'hidden' : 'visible' }}>
                                <CompanyNameLogoContainer width="120px" />
                            </Stack>
                        </Stack>
                        <OctupTooltip
                            title={
                                isMainMenuCollapsedBtnActive ? 'Lock sidebar' : 'Collpase sidebar'
                            }
                        >
                            <NavHamborger
                                id="ou-nav-hamborger"
                                isMainMenuCollapsed={isMainMenuCollapsed}
                                onClick={() => {
                                    octupDispatch.sidebar.setIsMainMenuCollapsedBtnActive()
                                }}
                            >
                                <SvgIcon
                                    fontSize="large"
                                    component={HamburgerRightIcon}
                                    {...(!isMainMenuCollapsedBtnActive && {
                                        component: HamburgerLeftIcon,
                                    })}
                                    {...(!isMainMenuCollapsed && {
                                        sx: { color: palette.text.primary },
                                    })}
                                />
                            </NavHamborger>
                        </OctupTooltip>
                    </NavHeaderContainer>
                </Grid>
                <LoadableContent
                    isLoading={excludedFeatures.isLoading}
                    itemsCount={8}
                    height="4rem"
                    margin="1rem"
                    width="auto"
                    maxWidth="100%"
                >
                    <Grid item xs container justifyContent="space-between" overflow="auto">
                        <Grid item container>
                            <NavUpperMenu>
                                <ul>
                                    {sidebarData.Dashboard.map((el) =>
                                        renderSidebarItem(el, false)
                                    )}
                                </ul>
                                <NavBreak />
                                <ul>
                                    {sidebarData.Actionable.map((el) =>
                                        renderSidebarItem(el, false)
                                    )}
                                </ul>
                                <NavBreak />
                            </NavUpperMenu>
                        </Grid>
                        <Grid item container alignItems="flex-end">
                            <NavSystemMenu>
                                <NavBreak />
                                <ul>
                                    {sidebarData.System.map((el, index) =>
                                        renderSidebarItem(
                                            el,
                                            index === sidebarData.System.length - 1
                                        )
                                    )}
                                </ul>
                                <NavBreak />
                            </NavSystemMenu>
                        </Grid>
                    </Grid>
                </LoadableContent>
            </Grid>

            {showEditObjectiveModal.show && (
                <EditObjectiveModal
                    isEditState={showEditObjectiveModal.isEditState}
                    dismissModalhandler={dismissEditObjectiveModal}
                    data={objectiveData}
                />
            )}
            <button
                style={{ visibility: 'hidden' }}
                id={'activate-tour'}
                onClick={() => {
                    navigate(`/${APP_ROUTES.INTEGRATIONS}`)
                    octupDispatch.userConfig.setStartTour(true)
                }}
            ></button>
        </Nav>
    )
}

export default Sidebar
