import { useCallback } from 'react'
import type { BaseSingleInputFieldProps, FieldSection } from '@mui/x-date-pickers'
import { ArrowIcon } from '@octup/core/assets/icons'
import { SvgIcon, Button } from '@octup/core/ui-kit'
import { buttonStyle } from './styles'

type ButtonFieldPropsType = BaseSingleInputFieldProps<Date, Date, FieldSection, string> & {
    isOpen: boolean
    onOpen: () => void
    ownerState: {
        loading: boolean
    }
}

export const ButtonField = ({
    id,
    label,
    disabled,
    isOpen,
    onOpen,
    InputProps,
    inputProps,
    ownerState,
}: ButtonFieldPropsType) => {
    const handleClick = useCallback(() => {
        if (!isOpen) {
            onOpen()
        }
    }, [isOpen, onOpen])

    return (
        <Button
            id={id}
            ref={InputProps?.ref}
            size="small"
            variant="secondary"
            color="secondary"
            disabled={disabled}
            loading={ownerState.loading}
            aria-label={inputProps?.['aria-label']}
            onClick={handleClick}
            className={isOpen ? 'opened' : ''}
            sx={buttonStyle}
            endIcon={<SvgIcon component={ArrowIcon} />}
        >
            {label}
        </Button>
    )
}
