import { getIconColor } from './utils'
export function WarningIconSvg({ active, customActiveColor = '#DB2059', customColor = '#DB2059' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M10.1515 4.31994L2.53645 17.5372C2.34902 17.8625 2.25031 18.2314 2.25 18.6069C2.24969 18.9823 2.34779 19.3514 2.53469 19.677C2.7216 20.0026 2.99084 20.2735 3.31565 20.4621C3.6405 20.6506 4.0093 20.75 4.38494 20.75H19.6151C19.9907 20.75 20.3595 20.6506 20.6843 20.4621C21.0092 20.2735 21.2784 20.0027 21.4653 19.677C21.6522 19.3514 21.7503 18.9823 21.75 18.6069C21.7497 18.2314 21.651 17.8625 21.4635 17.5372L13.8485 4.31994C13.6613 3.99511 13.3922 3.725 13.0677 3.53705C12.7433 3.34908 12.375 3.25 12 3.25C11.625 3.25 11.2567 3.34908 10.9322 3.53705C10.6078 3.725 10.3387 3.99511 10.1515 4.31994L10.8014 4.69435L10.1515 4.31994Z"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 9V13.5"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 17.75C12.5523 17.75 13 17.3023 13 16.75C13 16.1977 12.5523 15.75 12 15.75C11.4477 15.75 11 16.1977 11 16.75C11 17.3023 11.4477 17.75 12 17.75Z"
                fill={getIconColor(active, customColor, customActiveColor)}
            />
        </svg>
    )
}
