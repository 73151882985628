import React from 'react'
import { OctupColors } from '../../theme/colors/OctupColors'

export function SignoutIconSvg({ active, customActiveColor = '#504B5A', customColor = '#504B5A' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M15.6614 8.65909L19.0023 12L15.6614 15.3409"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.0909 12H19"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.3636 19H5.63636C5.46759 19 5.30573 18.933 5.18639 18.8136C5.06705 18.6943 5 18.5324 5 18.3636V5.63636C5 5.46759 5.06705 5.30573 5.18639 5.18639C5.30573 5.06705 5.46759 5 5.63636 5H11.3636"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
