import type { MetricsSectionStateByIdType } from '@octup/core/models'
import { METRICS_SECTION_TYPE } from '@octup/core/models'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { ClientType } from '@/models/clients'
import { HTTP_STATUS_CODES } from '@/models/network'
import type { GetMetricsSectionType, MetricsSectionByIdRequestType } from '@/services/metrics'
import { getCustomTableMetricsSectionById, getMetricsSectionById } from '@/services/metrics'
import type { ThunkAPIConfigType } from '@/services/utils'

type MetricsSectionsStateType = {
    data?: Record<ClientType['id'], MetricsSectionStateByIdType>
    isLoading: boolean
}

const INITIAL_STATE: MetricsSectionsStateType = {
    data: undefined,
    isLoading: false,
}

const FETCHER_BY_SECTION_TYPE: Partial<Record<METRICS_SECTION_TYPE, GetMetricsSectionType>> &
    Record<'default', GetMetricsSectionType> = {
    [METRICS_SECTION_TYPE.CUSTOM_TABLE]: getCustomTableMetricsSectionById,
    default: getMetricsSectionById,
}

export const fetchMetricsSectionById = createAsyncThunk<
    Awaited<ReturnType<GetMetricsSectionType>>,
    MetricsSectionByIdRequestType,
    ThunkAPIConfigType
>('fetchMetricsSectionById', (data, thunkAPI) => {
    const fetchData = FETCHER_BY_SECTION_TYPE[data.type] || FETCHER_BY_SECTION_TYPE.default
    return fetchData(data, thunkAPI)
})

let pendingRequests = 0

export const metricsSectionsSlice = createSlice({
    name: 'metricsSections',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMetricsSectionById.pending, (state, action) => {
                const { id, clientId, startDate, endDate } = action.meta.arg
                const dateRange = { startDate, endDate }
                const clientData = state.data?.[clientId]
                const sectionData = clientData?.[id] || {}
                const newSectionState = {
                    ...sectionData,
                    dateRange,
                    isLoading: true,
                    error: undefined,
                }
                state.data = { ...state.data, [clientId]: { ...clientData, [id]: newSectionState } }

                state.isLoading = true
                pendingRequests += 1
            })
            .addCase(fetchMetricsSectionById.fulfilled, (state, action) => {
                const { data, status } = action.payload
                const { id, clientId } = action.meta.arg
                const clientData = state.data?.[clientId]
                const sectionData = clientData?.[id]
                const hasData = status !== HTTP_STATUS_CODES.NO_CONTENT
                const newSectionState = {
                    ...sectionData,
                    data,
                    hasData,
                    isLoading: false,
                    error: undefined,
                }

                state.data = { ...state.data, [clientId]: { ...clientData, [id]: newSectionState } }

                pendingRequests -= 1
                if (pendingRequests === 0) {
                    state.isLoading = false
                }
            })
            .addCase(fetchMetricsSectionById.rejected, (state, action) => {
                const error = action.error.message
                const { id, clientId } = action.meta.arg
                const clientData = state.data?.[clientId]
                const sectionData = clientData?.[id]
                const newSectionState = { ...sectionData, isLoading: false, error }
                state.data = { ...state.data, [clientId]: { ...clientData, [id]: newSectionState } }

                pendingRequests -= 1
                if (pendingRequests === 0) {
                    state.isLoading = false
                }
            })
    },
})

export const metricsSectionsReducer = metricsSectionsSlice.reducer
