import { Theme } from '@mui/material'

export const fieldNameStyle = (theme: Theme) => ({
    width: theme.spacing(70),
    paddingTop: theme.spacing(1),

    '&.MuiTextField-root .MuiInputBase-input': {
        padding: theme.spacing(2),
    },
})
