import React from 'react'

export function OctupNoTextSmallLogo() {
    return (
        <svg
            width="3.7rem"
            height="3.6rem"
            viewBox="0 0 37 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.8455 33.75C26.9234 33.75 33.4718 27.2022 33.4718 19.125C33.4718 11.0478 26.9234 4.5 18.8455 4.5C10.7676 4.5 4.21914 11.0478 4.21914 19.125C4.21914 27.2022 10.7676 33.75 18.8455 33.75ZM18.8455 32.3438C26.1467 32.3438 32.0655 26.4255 32.0655 19.125C32.0655 11.8245 26.1467 5.90625 18.8455 5.90625C11.5443 5.90625 5.62552 11.8245 5.62552 19.125C5.62552 26.4255 11.5443 32.3438 18.8455 32.3438Z"
                fill="url(#paint0_linear_319_11819)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.6893 34.3125C28.6993 34.3125 36.0033 27.0091 36.0033 18C36.0033 8.99086 28.6993 1.6875 19.6893 1.6875C10.6793 1.6875 3.37531 8.99086 3.37531 18C3.37531 27.0091 10.6793 34.3125 19.6893 34.3125ZM19.6893 32.5647C27.7339 32.5647 34.2554 26.0439 34.2554 18C34.2554 9.95612 27.7339 3.43527 19.6893 3.43527C11.6447 3.43527 5.12324 9.95612 5.12324 18C5.12324 26.0439 11.6447 32.5647 19.6893 32.5647Z"
                fill="url(#paint1_linear_319_11819)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.2829 34.0312C27.6036 34.0312 35.1595 26.4761 35.1595 17.1562C35.1595 7.83644 27.6036 0.28125 18.2829 0.28125C8.96224 0.28125 1.40635 7.83644 1.40635 17.1562C1.40635 26.4761 8.96224 34.0312 18.2829 34.0312ZM18.2829 32.9062C26.9822 32.9062 34.0343 25.8547 34.0343 17.1562C34.0343 8.45777 26.9822 1.40625 18.2829 1.40625C9.5836 1.40625 2.53143 8.45777 2.53143 17.1562C2.53143 25.8547 9.5836 32.9062 18.2829 32.9062Z"
                fill="url(#paint2_linear_319_11819)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.0033 18C36.0033 27.9411 27.9437 36 18.0017 36C8.05962 36 0 27.9411 0 18C0 8.05887 8.05962 0 18.0017 0C27.9437 0 36.0033 8.05887 36.0033 18ZM21.5358 34.433C20.3577 34.4049 19.1801 33.75 18.0017 33.75V33.75C9.30237 33.75 2.25021 26.6985 2.25021 18C2.25021 9.30152 9.30237 2.25 18.0017 2.25C26.7009 2.25 33.7531 9.30152 33.7531 18C33.7531 19.3663 33.5791 20.692 33.2521 21.9561C33.1667 22.2859 33.2742 22.642 33.2742 22.9827V22.9827C33.2742 23.2367 33.1628 23.4645 32.9865 23.6192C32.8052 23.7783 32.5998 23.9318 32.5054 24.1538V24.1538C32.3624 24.4905 32.4937 24.8802 32.6299 25.2198C32.682 25.3498 32.7108 25.492 32.7108 25.6409C32.7108 26.2622 32.2107 26.7659 31.5937 26.7659V26.7659C31.3305 26.7659 31.0461 26.8349 30.8947 27.0502C30.8494 27.1145 30.8036 27.1786 30.7574 27.2422C30.5396 27.5423 30.5585 27.949 30.628 28.3132C30.6446 28.3996 30.6532 28.4889 30.6532 28.5803C30.6532 29.3569 30.028 29.9865 29.2567 29.9865C29.1293 29.9865 29.0059 29.9693 28.8886 29.9371C28.6367 29.868 28.3515 29.8759 28.1519 30.0443V30.0443C27.9752 30.1934 27.9071 30.4314 27.9238 30.662C27.9271 30.7066 27.9287 30.7515 27.9287 30.7969C27.9287 31.8065 27.1158 32.625 26.1131 32.625C25.894 32.625 25.6841 32.586 25.4897 32.5144C25.0996 32.3708 24.662 32.283 24.2808 32.4488V32.4488C23.9672 32.5852 23.746 32.8679 23.596 33.1752C23.232 33.9206 22.4703 34.4337 21.5897 34.4337C21.5717 34.4337 21.5537 34.4334 21.5358 34.433Z"
                fill="url(#paint3_linear_319_11819)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.1914 23.264C32.4999 23.264 32.7501 23.0121 32.7501 22.7015C32.7501 22.3908 32.4999 22.139 32.1914 22.139C31.8828 22.139 31.6327 22.3908 31.6327 22.7015C31.6327 23.0121 31.8828 23.264 32.1914 23.264ZM31.9164 25.3596C31.9164 25.8256 31.5412 26.2034 31.0784 26.2034C30.6156 26.2034 30.2404 25.8256 30.2404 25.3596C30.2404 24.8936 30.6156 24.5159 31.0784 24.5159C31.5412 24.5159 31.9164 24.8936 31.9164 25.3596ZM28.9736 29.25C29.5135 29.25 29.9512 28.8093 29.9512 28.2656C29.9512 27.722 29.5135 27.2812 28.9736 27.2812C28.4336 27.2812 27.9959 27.722 27.9959 28.2656C27.9959 28.8093 28.4336 29.25 28.9736 29.25ZM27.2285 30.5479C27.2285 31.2469 26.6657 31.8136 25.9715 31.8136C25.2773 31.8136 24.7145 31.2469 24.7145 30.5479C24.7145 29.849 25.2773 29.2823 25.9715 29.2823C26.6657 29.2823 27.2285 29.849 27.2285 30.5479ZM23.1864 31.9219C23.1864 32.7762 22.4985 33.4687 21.6501 33.4687C20.8016 33.4687 20.1137 32.7762 20.1137 31.9219C20.1137 31.0675 20.8016 30.375 21.6501 30.375C22.4985 30.375 23.1864 31.0675 23.1864 31.9219Z"
                fill="url(#paint4_linear_319_11819)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_319_11819"
                    x1="12.9309"
                    y1="5.89233"
                    x2="24.9887"
                    y2="26.7422"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7737FF" />
                    <stop offset="1" stopColor="#00A0A0" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_319_11819"
                    x1="12.332"
                    y1="2.96691"
                    x2="25.751"
                    y2="27.24"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF3489" />
                    <stop offset="1" stopColor="#8627FF" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_319_11819"
                    x1="10.341"
                    y1="2.55016"
                    x2="25.0883"
                    y2="28.2183"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFCF72" />
                    <stop offset="1" stopColor="#FF3489" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_319_11819"
                    x1="17.4538"
                    y1="30.6563"
                    x2="22.4076"
                    y2="12.9504"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00C8C0" />
                    <stop offset="1" stopColor="#00A0A0" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_319_11819"
                    x1="26.8157"
                    y1="28.9687"
                    x2="32.6873"
                    y2="13.0661"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00C8C0" />
                    <stop offset="1" stopColor="#00A0A0" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    )
}
