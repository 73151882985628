export const emailStyle = {
    fontWeight: 500,
    fontStyle: 'italic',
    fontSize: 'inherit',
}

export const smallSizeStyle = (theme) => ({
    fontSize: theme.spacing(3),
})

export const textButtonStyle = (theme) => ({
    display: 'inline-flex',
    position: 'absolute',
    paddingLeft: theme.spacing(2),
})
