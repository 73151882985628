import { createSlice } from '@reduxjs/toolkit'
import { slidesData } from '../../../new_containers/components/WhatsNew/news/markdown.config'

const WHATS_NEW_INITIAL = {
    slidesData,
    show: false,
    isLoading: false,
    activeItem: {
        folder: Object.keys(slidesData)[0],
        slide: 0,
        version: 1,
        path: `${Object.keys(slidesData)[0]}/${1}/${
            slidesData?.[Object.keys(slidesData)[0]].filter((el) => el.version === 1)[0].content[0]
        }`,
    },
}

export const whatNewSlice = createSlice({
    name: 'whatsNew',
    initialState: WHATS_NEW_INITIAL,
    reducers: {
        setShow(state, action) {
            if (!action.payload) state.activeItem = WHATS_NEW_INITIAL.activeItem
            state.show = action.payload
        },
        setActiveItem(state, action) {
            const { folder, version, slide } = action.payload
            const path = `${folder}/${version}/${
                slidesData?.[folder].filter((el) => el.version === version)[0].content[slide]
            }`
            state.activeItem = { ...action.payload, path }
        },
        resetActiveItem(state) {
            state.activeItem = WHATS_NEW_INITIAL.activeItem
        },
    },
})
export const whatsNewReducer = whatNewSlice.reducer
export const whatsNewSlideActions = whatNewSlice.actions
