import styled from 'styled-components'
import { useCallback } from 'react'
import { OctupTooltip } from '../MUIWrappers/OctupTooltip'

export const RadioTabsSelectorItem = ({
    tab,
    selectedItem,
    onClick,
    customStyle,
    disabled,
    tooltip,
}) => {
    const handleClick = useCallback(
        (event) => {
            if (disabled) return
            onClick(event)
        },
        [disabled, onClick]
    )

    return (
        <OctupTooltip title={tooltip} disableHoverListener={!tooltip}>
            <RadioTabsSelectorItemStyled
                style={customStyle}
                onClick={handleClick}
                value={tab?.value}
                disabled={disabled}
                className={tab?.title === selectedItem?.title ? 'selected' : ''}
            >
                {tab?.title}
            </RadioTabsSelectorItemStyled>
        </OctupTooltip>
    )
}

const RadioTabsSelectorItemStyled = styled.div`
    border-radius: 0.8rem;
    height: 100%;
    width: 100%;
    min-height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: inherit;
    line-height: 1.8rem;
    cursor: pointer;
    color: rgba(80, 75, 90, 1);
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    text-transform: capitalize;

    &[disabled] {
        opacity: 0.5;
    }

    &.selected {
        color: rgba(0, 127, 130, 1);
        font-weight: 600;
        border: 0.1rem solid rgba(221, 219, 225, 1);
        box-shadow: inset 0.2rem 0.2rem 0.8rem rgba(80, 75, 90, 0.32);
    }
`
