import { Stack, SvgIcon, Typography } from '@mui/material'
import { InfoIcon } from '@octup/core/assets/icons'
import { octupDictionary } from 'shared/dictionary/dictionary'
import { OctupTooltip } from 'shared/ui/MUIWrappers/OctupTooltip'

export const ControlPanel = () => (
    <Stack direction="row" alignItems="center" wrap="nowrap" spacing={1.5}>
        <Typography variant="body2">{octupDictionary.titles.attribution.lastClick}</Typography>
        <OctupTooltip title={octupDictionary.tooltips.attribution.lastClick} position="top">
            <SvgIcon component={InfoIcon} color="text.primary" fontSize="small" />
        </OctupTooltip>
    </Stack>
)
