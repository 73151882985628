import { useCallback } from 'react'
import type {
    DashboardSectionPropsType,
    DashboardContainerPropsType,
    MetricsSectionContainerPropsType,
} from '@octup/core/components'
import { DashboardContainer, MetricsSectionContainer } from '@octup/core/components'
import type {
    MetricsSectionConfigByType,
    MetricsSectionStateByIdType,
    MetricsSectionType,
} from '@octup/core/models'

export type MetricsDashboardContainerPropsType = Pick<
    MetricsSectionContainerPropsType,
    'onDeepDiveShow'
> &
    Omit<DashboardContainerPropsType, 'renderSection'> & {
        sectionsList?: MetricsSectionType[]
        sectionStateById?: MetricsSectionStateByIdType
        sectionConfigByType: MetricsSectionConfigByType
    }

export const MetricsDashboardContainer = ({
    sectionsList = [],
    sectionStateById,
    sectionConfigByType,
    onDeepDiveShow,
    ...props
}: MetricsDashboardContainerPropsType) => {
    const renderDashboardSection = useCallback(
        ({ index }: DashboardSectionPropsType) => {
            const section = sectionsList[index]
            if (!section) return null

            const config = sectionConfigByType[section.type]
            const { data, hasData, error, isLoading } = sectionStateById?.[section.id] || {}

            return (
                <MetricsSectionContainer
                    data={data}
                    config={config}
                    hasData={hasData}
                    error={error}
                    isLoading={isLoading}
                    onDeepDiveShow={onDeepDiveShow}
                />
            )
        },
        [sectionsList, sectionStateById, sectionConfigByType, onDeepDiveShow]
    )

    return <DashboardContainer renderSection={renderDashboardSection} {...props} />
}
