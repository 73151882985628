import React, { forwardRef } from 'react'
import { Checkbox as MuiCheckbox, FormControlLabel, FormControl, FormLabel } from '@mui/material'
import type { CheckboxProps as MuiCheckboxProps } from '@mui/material'
import { checkboxStyle, labelStyle } from './styles'

export type CheckboxPropsType = MuiCheckboxProps & {
    label?: React.ReactNode
    legend?: string
    value?: boolean
    error?: boolean
    required?: boolean
}

export const Checkbox = forwardRef(
    (
        { label, legend, value, error, required, ...props }: CheckboxPropsType,
        ref: React.Ref<HTMLButtonElement>
    ) => {
        const checkboxComponent = (
            <MuiCheckbox ref={ref} checked={value} sx={checkboxStyle} disableRipple {...props} />
        )

        if (!label) return checkboxComponent

        return (
            <FormControl required={required} error={error} component="fieldset">
                {legend && <FormLabel component="legend">{legend}</FormLabel>}
                <FormControlLabel label={label} sx={labelStyle} control={checkboxComponent} />
            </FormControl>
        )
    }
)
