import type { CheckboxPropsType } from '@octup/core/ui-kit'
import { Checkbox, Link, Typography } from '@octup/core/ui-kit'
import { APP_STATIC_PAGES } from '@/models/appConfig'

export const TermsAndPrivacyCheckbox = (props: CheckboxPropsType) => (
    <Checkbox
        {...props}
        label={
            <Typography variant="body2">
                {'I agree to the '}
                <Link href={APP_STATIC_PAGES.TERMS_AND_CONDITIONS} target="_blank">
                    Terms of Service
                </Link>
                {' and '}
                <Link href={APP_STATIC_PAGES.PRIVACY_POLICY} target="_blank">
                    Privacy Policy
                </Link>
            </Typography>
        }
    />
)
