import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PAYMENTS_API_URL, getPaymentPlans } from 'services'

const INITIAL_STATE = {
    data: {
        plan: {
            level: 2,
            breeze: { monthly: 399, yearly: 330 },
            majestic: { monthly: 599, yearly: 499 },
        },
    },
    isLoading: false,
    error: null,
}

export const fetchPaymentsPlans = createAsyncThunk(`${PAYMENTS_API_URL}`, async (_, thunkAPI) =>
    getPaymentPlans(thunkAPI)
)

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState: INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentsPlans.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchPaymentsPlans.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchPaymentsPlans.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const paymentsSliceReducer = paymentsSlice.reducer
