import axios from 'axios'
import { GLOBAL_CONFIG } from '../../config'
const API_URL = `${GLOBAL_CONFIG.BASE_URL}auth`

const generateConfig = (token, contentType) => {
    return {
        headers: {
            Authorization: token ? `${token}` : null,
            'Content-Type': contentType ? contentType : 'application/json',
        },
    }
}

/**
 * fetchs all Octup insight
 * @token optional
 */
export const getIntegrations = async (data) => {
    const config = generateConfig(data.token)
    const res = await axios.get(`${API_URL}/integrations`, config)
    // const res = await axios.get(`${API_URL}/auth/integrations?company_name=${dataset.shopName}`, config);
    return res.data
}

export const getIntegrationsExtension = async (data) => {
    const config = generateConfig(data.token)
    const res = await axios.get(`${API_URL}/integrations/${data.source}`, config)
    return res.data
}

export const postIntegrationsAddAccounts = async (data) => {
    const config = generateConfig(data.token)
    const res = await axios.post(
        `${API_URL}/extended_integration`,
        { selection: data.selection, source: data.source },
        config
    )
    return res.data
}

export const integrationsService = {
    getIntegrations,
    getIntegrationsExtension,
    postIntegrationsAddAccounts,
}
