import styled from 'styled-components'
import Checkbox from '../Checkbox/Checkbox'
import DropdownLine from './DropdownLine'
import { OctupTooltip } from '../MUIWrappers/OctupTooltip'

const DropdownItem = ({
    id,
    name,
    disabled,
    tooltip,
    customLabelPostfix,
    customLabelPrefix,
    count,
    imgObj = { src: '', alt: '' },
    avatarObj = null,
    isSelectAllOption = false,
    isAllItemsSelected = false,
    onItemSelection,
    isMultiSelection = false,
    isAvatarDropdown,
    showOptionsImages,
    showEmpty,
    initials,
    showCounter,
    showSemiAll,
    isSelected = false,
}) => {
    return (
        (showEmpty ? true : count > 0) && (
            <OctupTooltip title={tooltip} position="right">
                <DropdownItemStyled
                    key={name}
                    disabled={disabled}
                    isAvatarDropdown={isAvatarDropdown}
                    onClick={() => !disabled && onItemSelection(id)}
                >
                    {isMultiSelection ? (
                        <Checkbox
                            onItemSelection={onItemSelection}
                            showSemiAll={showSemiAll}
                            isSelected={isSelected || isAllItemsSelected}
                            label={
                                <DropdownLine
                                    id={id}
                                    name={name}
                                    customLabelPostfix={customLabelPostfix}
                                    customLabelPrefix={customLabelPrefix}
                                    initials={initials}
                                    count={count}
                                    imgObj={imgObj}
                                    isSelectAllOption={isSelectAllOption}
                                    isSelected={isSelected || isAllItemsSelected}
                                    avatarObj={avatarObj}
                                    onItemSelection={onItemSelection}
                                    isMultiSelection={isMultiSelection}
                                    isAvatarDropdown={isAvatarDropdown}
                                    showOptionsImages={showOptionsImages}
                                    showCounter={showCounter}
                                />
                            }
                        />
                    ) : (
                        <DropdownLine
                            id={id}
                            name={name}
                            customLabelPostfix={customLabelPostfix}
                            customLabelPrefix={customLabelPrefix}
                            count={count}
                            imgObj={imgObj}
                            initials={initials}
                            isSelected={isSelected}
                            isSelectAllOption={isSelectAllOption}
                            avatarObj={avatarObj}
                            onItemSelection={onItemSelection}
                            isMultiSelection={isMultiSelection}
                            isAvatarDropdown={isAvatarDropdown}
                            showOptionsImages={showOptionsImages}
                            showCounter={showCounter}
                        />
                    )}
                </DropdownItemStyled>
            </OctupTooltip>
        )
    )
}

const DropdownItemStyled = styled.div`
    white-space: nowrap;
    width: 100%;
    min-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.2rem;
    line-height: 1.3;
    height: 100%;
    z-index: 3;
    padding: ${(props) => (props.isAvatarDropdown ? '1rem 0' : '0')};
    opacity: ${(props) => (props.disabled ? '0.4' : '1')};
    background: transparent;
    &:hover {
        background-color: #f1f0f5;
        :first-child {
            border-top-right-radius: 0.6rem;
            border-top-left-radius: 0.6rem;
        }
        :last-child {
            border-bottom-right-radius: 0.6rem;
            border-bottom-left-radius: 0.6rem;
        }
    }
`

export default DropdownItem
