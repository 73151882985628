import { Stack } from '@mui/material'
import {
    ExitDashboardContainer,
    DeleteDashboardContainer,
    SaveDashboardContainer,
} from 'new_containers/components/Dashboards/DashboardForm'

type DashboardFormActionsPropsType = {
    isNew?: boolean
}

export const DashboardFormActions = ({ isNew }: DashboardFormActionsPropsType) => (
    <Stack direction="row" spacing={4}>
        <ExitDashboardContainer />
        <SaveDashboardContainer />
        {!isNew && <DeleteDashboardContainer />}
    </Stack>
)
