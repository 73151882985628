import React from 'react'
import { getIconColor } from './utils'

export function UnitEconomicsLogoSvg({ active, customActiveColor, customColor, disabled }) {
    return (
        <svg
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.8418 13.3652L8.85343 4.37675C8.6625 4.18582 8.3589 4.17986 8.17531 4.36344L3.20399 9.19604C3.02041 9.37962 3.02637 9.68322 3.2173 9.87415L12.2057 18.8626C12.3966 19.0535 12.7002 19.0595 12.8838 18.8759L17.8551 14.0433C18.0387 13.8597 18.0328 13.5561 17.8418 13.3652Z"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.8993 18.1281L20.9659 14.0615C21.1263 13.9011 21.1211 13.6358 20.9543 13.469L12.4162 4.93088C12.2493 4.76404 11.984 4.75883 11.8236 4.91925"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.7712 12.8163C10.3318 13.377 11.2106 13.4072 11.7339 12.8838C12.2573 12.3605 12.227 11.4818 11.6664 10.9211C11.1058 10.3605 10.227 10.3303 9.70369 10.8536C9.18035 11.377 9.21057 12.2557 9.7712 12.8163Z"
                stroke={getIconColor(active, customColor, customActiveColor, disabled)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
