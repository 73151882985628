import { OctupBaseTable } from 'shared/ui/Tables/OctupBaseTable'
import { TableContainer } from './CampaignsTable.styles'

const customRowStyle = { height: '6rem' }

export const CampaignsTable = ({ tableData, ...props }) => (
    <TableContainer colsCount={tableData.headers.length}>
        <OctupBaseTable
            {...props}
            tableName="Attribution"
            customRowStyle={customRowStyle}
            tableData={tableData}
            showDownloadCSV
            showSearchFilter
            showFilters={false}
            isCollapsible={false}
            showSelectedRow={false}
            overrideRowKey="source"
            tooltipPath="tooltips.attribution"
        />
    </TableContainer>
)
