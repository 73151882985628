export function SimulateIconSvg({
    active,
    customActiveColor = '#007F82',
    customColor = '#007F82',
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.4rem"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M18 19.3333V4.66667C18 4.29848 17.7314 4 17.4 4L6.6 4C6.26863 4 6 4.29848 6 4.66667V19.3333C6 19.7015 6.26863 20 6.6 20H17.4C17.7314 20 18 19.7015 18 19.3333Z"
                fill="#F8F7FB"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5999 6.66602H8.3999V10.666H15.5999V6.66602Z"
                stroke="#007F82"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.1001 14.666C9.65238 14.666 10.1001 14.2183 10.1001 13.666C10.1001 13.1137 9.65238 12.666 9.1001 12.666C8.54781 12.666 8.1001 13.1137 8.1001 13.666C8.1001 14.2183 8.54781 14.666 9.1001 14.666Z"
                fill="#007F82"
            />
            <path
                d="M9.1001 17.9994C9.65238 17.9994 10.1001 17.5516 10.1001 16.9994C10.1001 16.4471 9.65238 15.9994 9.1001 15.9994C8.54781 15.9994 8.1001 16.4471 8.1001 16.9994C8.1001 17.5516 8.54781 17.9994 9.1001 17.9994Z"
                fill="#007F82"
            />
            <path
                d="M12.1001 14.666C12.6524 14.666 13.1001 14.2183 13.1001 13.666C13.1001 13.1137 12.6524 12.666 12.1001 12.666C11.5478 12.666 11.1001 13.1137 11.1001 13.666C11.1001 14.2183 11.5478 14.666 12.1001 14.666Z"
                fill="#007F82"
            />
            <path
                d="M12.1001 17.9994C12.6524 17.9994 13.1001 17.5516 13.1001 16.9994C13.1001 16.4471 12.6524 15.9994 12.1001 15.9994C11.5478 15.9994 11.1001 16.4471 11.1001 16.9994C11.1001 17.5516 11.5478 17.9994 12.1001 17.9994Z"
                fill="#007F82"
            />
            <path
                d="M15.1001 14.666C15.6524 14.666 16.1001 14.2183 16.1001 13.666C16.1001 13.1137 15.6524 12.666 15.1001 12.666C14.5478 12.666 14.1001 13.1137 14.1001 13.666C14.1001 14.2183 14.5478 14.666 15.1001 14.666Z"
                fill="#007F82"
            />
            <path
                d="M15.1001 17.9994C15.6524 17.9994 16.1001 17.5516 16.1001 16.9994C16.1001 16.4471 15.6524 15.9994 15.1001 15.9994C14.5478 15.9994 14.1001 16.4471 14.1001 16.9994C14.1001 17.5516 14.5478 17.9994 15.1001 17.9994Z"
                fill="#007F82"
            />
        </svg>
    )
}
