import React from 'react'
import { getIconColor } from './utils'

export function XmarkSvg({ active, customActiveColor, customColor, scale = 1 }) {
    const transform = `scale(${scale})`
    return (
        <svg
            width="1.2rem"
            height="1.2rem"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform={transform}
        >
            <path
                d="M1 9L8.99999 1.00001"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 1L8.99999 8.99999"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
