import { Button } from '@octup/core/ui-kit'
import { APP_ROUTES } from 'models/appConfig'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import OctyTour from '../../shared/assets/images/OctyTour.svg'
import { Flex } from '../../shared/styledComponents'
import { OctupColors } from '../../shared/theme/colors/OctupColors'

export const OctupTourInitialModal = ({ handleDismissIntro, startTour }) => {
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.auth)

    return (
        <MainContainer>
            <StyledImage src={OctyTour} />
            <Title>{userInfo?.fullName}, welcome aboard your Octup adventure!</Title>
            <Text>
                We know new platforms can sometimes be hard to learn, so we’re here to lend a hand
                (or eight) 🐙. This quick-guide will help you dive in and explore some of the
                wonders of Octup.
            </Text>
            <ButtonContainer>
                <Button variant="secondary" onClick={handleDismissIntro}>
                    Skip for now
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        handleDismissIntro()
                        navigate(`/${APP_ROUTES.INTEGRATIONS}`)
                        startTour()
                    }}
                >
                    Set sail on the tour
                </Button>
            </ButtonContainer>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90rem;

    @media screen and (max-width: 1600px) {
        width: 100rem;
    }
`

const Title = styled.div`
    color: ${OctupColors.primaryActionableLight};
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
`

const Text = styled.div`
    font-size: 13px;
    text-align: center;
    width: 80%;
`

const StyledImage = styled.img`
    width: 100%;
`

const ButtonContainer = styled(Flex)`
    padding: 1rem;
    gap: 2.5rem;
    width: 100%;
    justify-content: flex-end;
`
