import PageLayout from '@/components/PageLayout/PageLayout'
import { ControlPanel } from '@/new_components/Attribution'
import { NoDataFallbackContainer } from '@/new_containers/components/__common__'
import { CampaignsTableContainer } from '@/new_containers/components/Attribution'
import { CardExpendable } from '@/shared/ui/Card/CardExpendable'
import { CardContainer } from './AttributionPage.styles'
import { MISSING_DATA_BADGE, NO_DATA_REDIRECTION } from './AttributionPage.utils'

export const AttributionPage = ({ data, isLoading, error, isDataMissing }) => (
    <PageLayout
        error={error}
        showDatePicker={!!data}
        showSidePanel={!!data}
        sidePanel={<ControlPanel />}
        badgeConfig={isDataMissing && MISSING_DATA_BADGE}
    >
        {data || isLoading ? (
            <CardContainer>
                <CardExpendable>
                    <CampaignsTableContainer isLoading={isLoading} campaignsData={data} />
                </CardExpendable>
            </CardContainer>
        ) : (
            <NoDataFallbackContainer
                text="You haven't connected any marketing integration yet"
                buttonTitle="Connect Integrations"
                redirection={NO_DATA_REDIRECTION}
            />
        )}
    </PageLayout>
)
