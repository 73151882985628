import { Grid, ListItemImage } from '@octup/core/ui-kit'
import {
    DownloadCostsButtonContainer,
    TitleContainer,
} from '@/new_containers/components/UnitEconomics'

export const ProductMainInfo = ({ selectedProduct }) => (
    <Grid item container alignItems="center" wrap="nowrap" columnSpacing={2}>
        <Grid item>
            <ListItemImage src={selectedProduct.product_image} alt="product" />
        </Grid>

        <Grid item xs={8} md={8.5} lg={9} xl={9.5}>
            <TitleContainer
                title={selectedProduct.title}
                sku={selectedProduct.sku}
                fontWeight={600}
            />
        </Grid>
        <Grid item container justifyContent="flex-end">
            <DownloadCostsButtonContainer />
        </Grid>
    </Grid>
)
