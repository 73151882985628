import styled from 'styled-components'

export const NavItem = styled.li`
    color: rgba(80, 75, 90, 1);
    font-size: 15px;
    cursor: pointer;
    padding: 0.8rem 2rem;
    text-transform: capitalize;

    &.active,
    &:hover {
        color: rgba(0, 127, 130, 1);
    }

    &.active {
        font-weight: 600;
    }
`
