import { editCost } from 'state/features/unitEconomics'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { EditIconSvg } from 'shared/assets/icons'
import { ButtonIcon } from 'shared/ui/Buttons/ButtonIcon'

export const EditCostButtonContainer = ({ id }) => {
    const dispatch = useDispatch()
    const handleClick = useCallback(() => dispatch(editCost(id)), [dispatch, id])

    return <ButtonIcon tooltipContent="Edit" mainIcon={EditIconSvg} onClick={handleClick} />
}
