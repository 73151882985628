import { OctupColors } from '../../../theme/colors/OctupColors'

export function GeneralIcon({ size = '2.4rem' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M12.2414 12L12.1588 20"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.3176 15.6831V8.31693C19.3176 8.20669 19.2883 8.09843 19.2328 8.00322C19.1772 7.908 19.0974 7.82925 19.0014 7.775L12.4651 4.08058C12.3717 4.02776 12.2661 4 12.1588 4C12.0515 4 11.9459 4.02776 11.8525 4.08058L5.3162 7.775C5.22023 7.82925 5.14038 7.908 5.08483 8.00322C5.02927 8.09843 5 8.20669 5 8.31693V15.6831C5 15.7933 5.02927 15.9016 5.08483 15.9968C5.14038 16.092 5.22023 16.1708 5.3162 16.225L11.8525 19.9194C11.9459 19.9722 12.0515 20 12.1588 20C12.2661 20 12.3717 19.9722 12.4651 19.9194L19.0014 16.225C19.0974 16.1708 19.1772 16.092 19.2328 15.9968C19.2883 15.9016 19.3176 15.7933 19.3176 15.6831V15.6831Z"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.2323 8.00257L12.2414 12.0001L5.08583 8.00171"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
