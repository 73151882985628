import { Divider, Grid, Stack, Checkbox, OverflowTypography } from '@octup/core/ui-kit'
import { FormFieldContainer } from '@/new_containers/components/__common__'
import { accountDividerStyle, containerStyle, dividerStyle } from './AccountRow.styles'

export const AccountRow = ({ fieldName, displayName, displayId, color, isHeader }) => (
    <>
        <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            columnSpacing="4rem"
            sx={containerStyle}
        >
            <Grid item xs={6}>
                <Stack direction="row" alignItems="center">
                    <FormFieldContainer
                        as={Checkbox}
                        name={fieldName}
                        label={
                            <OverflowTypography color={color}>
                                {displayName || '—'}
                            </OverflowTypography>
                        }
                    />
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <OverflowTypography color={color}>{displayId}</OverflowTypography>
            </Grid>
        </Grid>
        <Divider sx={isHeader ? dividerStyle : accountDividerStyle} />
    </>
)
