import {
    HeaderMsg,
    IconContainer,
    MessageCardBody,
    MessageCardContainer,
    MessageCardHeader,
} from './MessageCard.styled'
import { ChevronDownSvg, ChevronUpSvg, WarningIconSvg } from '../../../shared/assets/icons'
import { useState } from 'react'

export const MessageCard = ({
    type = 'danger',
    headerMsg = '',
    bodyMsg = '',
    isAccordion = true,
}) => {
    // @todo: add additional types and set styles accordingly
    const typeIcons = {
        danger: <WarningIconSvg />,
    }
    const [showContent, setShowContent] = useState(!isAccordion)
    const Icon = showContent ? ChevronUpSvg : ChevronDownSvg
    const handleHeaderClick = () => {
        isAccordion && setShowContent((prev) => !prev)
    }
    return (
        <MessageCardContainer>
            <MessageCardHeader onClick={handleHeaderClick}>
                <IconContainer>{typeIcons[type]}</IconContainer>
                <HeaderMsg>{headerMsg}</HeaderMsg>
                {isAccordion && (
                    <IconContainer>
                        <Icon customColor={'rgba(80, 75, 90, 1)'} />
                    </IconContainer>
                )}
            </MessageCardHeader>
            <MessageCardBody>
                {showContent && <MessageCardBody>{bodyMsg}</MessageCardBody>}
            </MessageCardBody>
        </MessageCardContainer>
    )
}
