import type { MetricType } from '@octup/core/models'
import { chartMapIcons } from '@/pages/marketing/MarketingPage'

export const getChartConfig = (metrics: MetricType[] = []) => {
    const { value_units, currency } = metrics[0] || {}

    const chartConfig = metrics.reduce(
        // should return props of bar chart component
        // replace any type after re-writing the chart component
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, metric) => ({
            ...acc,
            chartsDataSets: {
                ...acc.chartsDataSets,
                labels: [...acc.chartsDataSets.labels, metric.name],
                datasets: [
                    {
                        image: [
                            ...(acc.chartsDataSets.datasets[0]?.image || []),
                            /* @ts-expect-error: chartMapIcons is a JavaScript file */
                            chartMapIcons[metric.sources[0]],
                        ],
                        data: [...(acc.chartsDataSets.datasets[0]?.data || []), metric.value],
                    },
                ],
            },
        }),
        {
            chartsDataSets: { labels: [], datasets: [] },
            legends: [],
            valueUnits: value_units,
            currency,
            supported: true,
        }
    )

    return chartConfig
}
