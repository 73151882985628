import { TextField } from '@octup/core/ui-kit'
import { FormFieldContainer } from 'new_containers/components/__common__'
import { fieldNameStyle } from './DashboardNameField.styles'

type DashboardNameFieldPropsType = {
    isDisabled: boolean
}

export const DashboardNameField = ({ isDisabled }: DashboardNameFieldPropsType) => (
    <FormFieldContainer
        name="name"
        as={TextField}
        size="small"
        sx={fieldNameStyle}
        placeholder="Board Name"
        disabled={isDisabled}
        hasInlineError
    />
)
