export const containerStyle = {
    padding: '1.5rem 0',
}

export const dividerStyle = {
    color: 'rgba(52, 52, 52, 0.16)',
}

export const accountDividerStyle = {
    ...dividerStyle,
    opacity: '0.3',
}
