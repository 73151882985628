import { forwardRef } from 'react'
import type { ChipProps } from '@mui/material'
import { Chip as MuiChip } from '@mui/material'
import { ClearIcon } from '@octup/core/assets/icons'
import { OverflowTypography, SvgIcon } from '@octup/core/ui-kit'

export type ChipPropsType = ChipProps

export const Chip = forwardRef(
    ({ label, ...props }: ChipPropsType, ref: React.Ref<HTMLDivElement>) => (
        <MuiChip
            ref={ref}
            deleteIcon={<SvgIcon component={ClearIcon} />}
            label={<OverflowTypography fontSize="inherit">{label}</OverflowTypography>}
            {...props}
        />
    )
)
