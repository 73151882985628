// TODO: delete component after migrating everything using DeepDiveModal Component

import { OctupBaseModal } from '../../../shared/ui/Modal/OctupBaseModal'
import { MetricsDetailsModalStyled } from './MetricsDetailsModal.styles'
import { MetricsDetailsMetricSelection } from './MetricsDetailsMetricSelection'
import { MetricsDetailsGraph } from './MetricsDetailsGraph'
import { MetricsDetailsTable } from './MetricsDetailsTable'

export const MetricsDetailsModal = ({
    onClose,
    selectedMetricData,
    data,
    deepdiveModal,
    setIsBreakdown,
    isBreakdown,
    isLoadingDeepdives,
    breakdownItemsData,
    rearrangedMetricArray,
    deepdives,
    onMetricSelection,
    showGraph = true,
    originalReferenceType,
    ...props
}) => {
    return (
        <OctupBaseModal onDismissModal={onClose} isExpendable={true} maxHeight="80rem" {...props}>
            <MetricsDetailsModalStyled>
                <MetricsDetailsMetricSelection
                    selectedMetric={selectedMetricData?.metric}
                    metricsGroup={data}
                    deepdiveModal={deepdiveModal}
                    onMetricSelection={onMetricSelection}
                    setIsBreakdown={setIsBreakdown}
                    isBreakdown={isBreakdown}
                />
                {showGraph && (
                    <MetricsDetailsGraph
                        isLoadingDeepdives={isLoadingDeepdives}
                        selectedMetricData={selectedMetricData}
                        isBreakdown={isBreakdown}
                        breakdownItemsData={breakdownItemsData}
                        data={data}
                    />
                )}
                <MetricsDetailsTable
                    selectedMetricData={selectedMetricData}
                    isLoadingDeepdives={isLoadingDeepdives}
                    deepdives={deepdives}
                    rearrangedMetricArray={rearrangedMetricArray}
                    isBreakdown={isBreakdown}
                    breakdownItemsData={breakdownItemsData}
                    originalReferenceType={originalReferenceType}
                />
            </MetricsDetailsModalStyled>
        </OctupBaseModal>
    )
}
