import { SvgIcon } from '@mui/material'
import type { SvgIconProps as MuiSvgIconPropsType } from '@mui/material'
import type { DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers'
import { ArrowIcon } from '@octup/core/assets/icons'
import { addYears, format } from 'date-fns'
import { ButtonField } from './ButtonField'

export enum DATE_PICKER_TYPES {
    BUTTON = 'BUTTON',
    TEXT_FIELD = 'TEXT_FIELD',
}

export type DatePickerType = MuiDatePickerProps<Date> & {
    type: DATE_PICKER_TYPES
}

type DatePickerViewsType = NonNullable<DatePickerType['views']>
type DatePickerConfigType = DatePickerType & {
    isOpen: boolean
    toggleOpen: () => void
    views: DatePickerViewsType
}

const DEFAULT_SLOTS = {
    switchViewIcon: (props: MuiSvgIconPropsType) => <SvgIcon component={ArrowIcon} {...props} />,
}

const DEFAULT_SLOT_PROPS: DatePickerType['slotProps'] = {
    switchViewButton: { disableRipple: true, color: 'inherit' },
    desktopPaper: { elevation: 5 },
}

const getFormatByView = (views: DatePickerViewsType) => {
    if (views.includes('day')) {
        return 'EEE. MMM dd, yyyy'
    }

    if (views.includes('month')) {
        return 'MMM. yyyy'
    }

    return 'yyyy'
}

type DatePickerConfigByTypeParamsType = Omit<DatePickerConfigType, 'type' | 'slots' | 'slotProps'>
type DatePickerConfigByTypeReturnType = Record<
    DATE_PICKER_TYPES,
    {
        slots?: DatePickerType['slots']
        slotProps?: DatePickerType['slotProps']
        label?: string
    }
>

const getConfigByType = ({
    value,
    isOpen,
    toggleOpen,
    views,
}: DatePickerConfigByTypeParamsType): DatePickerConfigByTypeReturnType => {
    const formatByView = getFormatByView(views)

    return {
        [DATE_PICKER_TYPES.BUTTON]: {
            slots: { field: ButtonField } as DatePickerType['slots'],
            slotProps: { field: { isOpen, onOpen: toggleOpen } } as DatePickerType['slotProps'],
            label: value ? format(value, formatByView) : '',
        },
        // TODO: support text field
        [DATE_PICKER_TYPES.TEXT_FIELD]: {},
    }
}

export const getConfig = ({ type, slots, slotProps, ...params }: DatePickerConfigType) => {
    const configByType = getConfigByType(params)
    const config = configByType[type]

    return {
        ...config,
        slots: { ...DEFAULT_SLOTS, ...config?.slots, ...slots },
        slotProps: { ...DEFAULT_SLOT_PROPS, ...config?.slotProps, ...slotProps },
        minDate: new Date(2020, 0),
        maxDate: addYears(new Date(), 1),
    }
}
