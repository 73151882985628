import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getFormattedVal, VALUE_TYPE_INDEX } from 'shared/utils/metrics-utils'

export const ShopCurrencyValueContainer = ({ value }) => {
    const { userInfo } = useSelector((state) => state.auth)

    const formattedValue = useMemo(
        () =>
            getFormattedVal({
                val: value || 0,
                valueType: VALUE_TYPE_INDEX.NUMBER,
                currency: userInfo?.client?.currency,
                isSupported: true,
                multiplyBy100: false,
                showSymbol: true,
            }),
        [userInfo?.client.currency, value]
    )

    return formattedValue
}
