import PageLayout from '@/components/PageLayout/PageLayout'
import type { DashboardType } from '@/models/dashboards'
import { MetricsDashboardProvider } from '@/new_containers/components/__common__'

type DashboardPagePropsType = {
    isLoading: boolean
    dashboardId?: DashboardType['id']
    sidePanelTools?: React.ReactNode
    hasCustomTitle?: boolean
    customTitleStyled?: string
    noDataText?: string
}

export const DashboardPage = ({
    isLoading,
    dashboardId,
    noDataText,
    ...props
}: DashboardPagePropsType) => (
    //  @ts-expect-error: PageLayout is a JavaScript file
    <PageLayout isLoading={isLoading} {...props}>
        <MetricsDashboardProvider
            dashboardId={dashboardId}
            noDataText={noDataText}
            isLoading={isLoading}
        />
    </PageLayout>
)
