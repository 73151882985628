import { Typography } from '@mui/material'

export const getTabs = ({ isDivided, onChange }) => [
    {
        value: 1,
        title: 'all items',
        info: (
            <Typography variant="subtitle1">
                Value above will be{' '}
                <Typography fontSize="inherit" fontWeight={600} component="span">
                    divided equally
                </Typography>{' '}
                across selected products
            </Typography>
        ),
        selected: isDivided,
        onClick: onChange,
    },
    {
        value: 0,
        title: 'per item',
        selected: !isDivided,
        info: (
            <Typography variant="subtitle1">
                Value above will be added{' '}
                <Typography fontSize="inherit" fontWeight={600} component="span">
                    to each item
                </Typography>
            </Typography>
        ),
        onClick: onChange,
    },
]
