import React from 'react'
import { getIconColor } from './utils'

export function DownArrowSvg({ active, customActiveColor, customColor = '#A4A0AA' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.4rem"
            height="2.5rem"
            viewBox="0 0 24 25"
            fill="none"
        >
            <path
                d="M8 13.5356L12 17.5001L16 13.5356"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.031 7.5L12.031 17.0528"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
