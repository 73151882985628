const heap = window?.heap
const hotjar = window.hj
const userInfo = JSON.parse(localStorage.getItem('userInfo'))
const isOctupUser = userInfo?.email?.includes('octup')

export const connectAnalyticsService = () => {
    heap?.addUserProperties?.({
        userName: userInfo?.fullName,
        email: userInfo?.email,
        client: userInfo?.client?.name,
    })
    heap?.identify?.(userInfo?.fullName || userInfo?.email)
}

export const addHeapEvent = (text, properties) => {
    heap?.track?.(text, properties)
}

export const identifyIntercomeUser = () => {
    const intercome = window.intercomSettings
    if (intercome) {
        intercome.name = userInfo?.fullName
        intercome.email = userInfo?.email
    }
}

export const identifyHotjarUser = () => {
    if (!isOctupUser) {
        hotjar('identify', userInfo?.fullName)
    }
}
