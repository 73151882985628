import { COMMON_PALETTE } from '@octup/core/theme'

const getOrCreateLegendList = (_, id) => {
    const legendContainer = document.getElementById(id)

    if (!legendContainer) return

    let listContainer = legendContainer.querySelector('div')

    if (!listContainer) {
        listContainer = document.createElement('div')
        listContainer.style.width = '100%'
        listContainer.style.display = 'flex'
        listContainer.style.flexWrap = 'wrap'
        listContainer.style.flexDirection = 'row'
        listContainer.style.justifyContent = 'space-between'
        legendContainer.appendChild(listContainer)
    }

    return listContainer
}

const createList = () => {
    const ul = document.createElement('ul')
    ul.style.display = 'flex'
    ul.style.flexDirection = 'row'
    ul.style.flexWrap = 'wrap'

    return ul
}

export const renderLegends = (chart, _, options) => {
    const div = getOrCreateLegendList(chart, options.containerID)

    if (!div) return

    while (div.firstChild) {
        div.firstChild.remove()
    }

    const { labels, onClick } = chart.options.plugins.legend
    const items = labels.generateLabels(chart)
    const circleList = createList()
    const lineList = createList()
    div.appendChild(circleList)
    div.appendChild(lineList)

    items.forEach((item) => {
        const li = document.createElement('li')
        const hasLineStyle = item.pointStyle === 'line'
        li.style.alignItems = 'center'
        li.style.cursor = 'pointer'
        li.style.display = 'flex'
        li.style.flexDirection = 'row'
        li.style.marginLeft = '10px'
        li.onclick = () => onClick({ chart }, item)

        // Color box/line
        const boxSpan = document.createElement('span')
        boxSpan.style.background = item.fillStyle
        boxSpan.style.borderColor = item.strokeStyle
        boxSpan.style.borderWidth = item.lineWidth + 'px'
        boxSpan.style.borderRadius = '50%'
        boxSpan.style.width = '10px'
        boxSpan.style.height = '10px'
        boxSpan.style.marginRight = '5px'

        if (hasLineStyle) {
            boxSpan.style.width = '16px'
            boxSpan.style.border = 'none'
            boxSpan.style.borderRadius = '0%'
            boxSpan.style.height = item.lineWidth + 'px'

            if (item.lineDash.length) {
                boxSpan.style.backgroundColor = 'transparent'
                boxSpan.style.backgroundImage = `linear-gradient(to right, ${item.strokeStyle} 50%, ${COMMON_PALETTE.common.white} 0%)`
                boxSpan.style.backgroundSize = item.lineDash.map((value) => value + 'px').join(' ')
            }
        }

        // Text
        const textContainer = document.createElement('p')
        textContainer.style.fontSize = labels.font.size + 'px'
        textContainer.style.color = labels.color
        textContainer.style.margin = 0
        textContainer.style.padding = 0
        textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

        const text = document.createTextNode(item.text)
        textContainer.appendChild(text)

        li.appendChild(boxSpan)
        li.appendChild(textContainer)
        const ul = hasLineStyle ? lineList : circleList
        ul.appendChild(li)
    })
}
