import { DropableMenu } from '../DropableMenu/DropableMenu'
import { ButtonIcon } from '../Buttons/ButtonIcon'
import { DotsIconSvg } from '../../assets/icons/DotsIconSvg'
import styled from 'styled-components'
//setSelectedRowData(el);
export const OctupTableRowDotMenu = ({ options, droplistPosition }) => {
    return (
        <DropableMenu
            droplistPosition={droplistPosition ? droplistPosition : 'right'}
            header={<ButtonIcon mainIcon={DotsIconSvg} type={'button'} />}
            options={options}
        />
    )
}
