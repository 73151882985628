import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import MetricTitle from './MetricItemComponents/MetricTitle'
import MetricTrend from './MetricItemComponents/MetricTrend'
import MetricValue from './MetricItemComponents/MetricValue'
import { MetricsDetailsIconSvg } from '../../../shared/assets/icons/MetricsDetailsIconSvg'
import { OctupBreathing } from '../../../shared/ui/Breathing/OctupBreathing'
import { ButtonIcon } from '../../../shared/ui/Buttons/ButtonIcon'
import { add3Dots } from '../../../shared/utils/strings-util'

export const MobileMetricItemWithChart = ({
    id,
    title,
    value,
    currency,
    valueUnits,
    trendUnits,
    isPositiveTrend,
    showSeperator,
    showDetailsBtn,
    isDensed,
    isEditMode,
    setSelectedMetric,
    setShowModalDetails,
    renderChart,
    isLoading,
    trendValue,
    metricTitleKey,
    hasDataset,
    isSupported,
    isStale,
    lastUpdatedAt,
}) => {
    const navigate = useNavigate()

    return (
        <MetricItemContainer
            onClick={() => {
                navigate('/MetricDeepdive', {
                    state: {
                        segment: 31,
                        selectedDDMetric: metricTitleKey,
                    },
                })
            }}
            isDensed={isDensed}
            isEditMode={isEditMode}
            showSeperator={showSeperator}
        >
            {isLoading ? (
                <OctupBreathing width="24rem" height="3rem" />
            ) : (
                <RowContainer>
                    <TitleAndUpdatedContainer>
                        <MetricTitle
                            fontSize={'2.2rem'}
                            title={add3Dots(metricTitleKey, 20)}
                            isStale={isStale}
                            lastUpdatedAt={lastUpdatedAt}
                        />
                    </TitleAndUpdatedContainer>
                    <TrendContainer>
                        {isSupported && (
                            <MetricTrend
                                isPositiveTrend={isPositiveTrend}
                                trendUnits={trendUnits}
                                trendValue={trendValue}
                                hasDataset={hasDataset}
                                isSupported={true}
                                fontSize={'2rem'}
                            />
                        )}
                    </TrendContainer>
                </RowContainer>
            )}
            <MetricItemInnerContainer>
                <DataContainer>
                    <MetricItemLeftContainer isDensed={isDensed}>
                        {isLoading ? (
                            <>
                                <OctupBreathingContainer>
                                    <OctupBreathing width="8rem" height="3rem"></OctupBreathing>
                                </OctupBreathingContainer>
                                <OctupBreathingContainer>
                                    <OctupBreathing width="8rem" height="3rem"></OctupBreathing>
                                </OctupBreathingContainer>
                            </>
                        ) : (
                            <Zindex>
                                <MetricValue
                                    isSupported={isSupported}
                                    value={value}
                                    currency={currency}
                                    valueUnits={valueUnits}
                                    fontSize={'4.7rem'}
                                />
                            </Zindex>
                        )}
                    </MetricItemLeftContainer>
                    {showDetailsBtn && (
                        <MetricsDetailsButtonStyled className="metrics-details-btn">
                            <ButtonIcon
                                tooltipContent={
                                    <>
                                        Open{' '}
                                        <span style={{ fontSize: 'inherit', fontWeight: 600 }}>
                                            {title}
                                        </span>{' '}
                                        details
                                    </>
                                }
                                mainIcon={MetricsDetailsIconSvg}
                                onClick={() => {
                                    setSelectedMetric(id)
                                    setShowModalDetails(true)
                                }}
                            />
                        </MetricsDetailsButtonStyled>
                    )}
                </DataContainer>
                {renderChart && isLoading ? (
                    <OctupBreathing width="24rem" height="6rem"></OctupBreathing>
                ) : (
                    <>
                        {isSupported ? (
                            renderChart && renderChart()
                        ) : (
                            <NoDataContainer>
                                <NoDataText>
                                    {'No data to display due to missing integration.\n' +
                                        'At the moment, connecting an integration is available in the web version only.'}
                                </NoDataText>
                            </NoDataContainer>
                        )}
                    </>
                )}
            </MetricItemInnerContainer>
        </MetricItemContainer>
    )
}

const MetricItemContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 24vh;
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    min-width: calc(18.8vw - 4rem);
    padding-left: 2rem;
    cursor: ${(props) => props.isEditMode && 'grab'};
    border-right: ${(props) => (props.showSeperator ? '.2rem solid #DDDBE1' : 'none')};

    .metrics-details-btn {
        opacity: 0;
    }

    &:hover {
        .metrics-details-btn {
            opacity: 1;
            transition: 0.1s ease-in-out;
        }
    }
`

const MetricItemInnerContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
`

const MetricItemLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const MetricsDetailsButtonStyled = styled.div`
    //position: absolute;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
`

const DataContainer = styled.div`
    width: 30%;
    padding-top: 3rem;
    height: 12.5vh;
`

const OctupBreathingContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    height: 100%;
    width: 100%;
    flex-direction: column;
`

const Zindex = styled.div`
    z-index: 10;
    margin-top: 1rem;
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-between;
    //height: 5rem;
    position: absolute;
`

const TrendContainer = styled.div`
    padding-top: 1.5rem;
    padding-right: 1rem;
`

const NoDataContainer = styled.div`
    background-color: red;
    //height: 10%;
    width: 90%;
`

const NoDataText = styled.span`
    font-size: 1.8rem;
    position: absolute;
    width: 90%;
    bottom: 0;
`

const TitleAndUpdatedContainer = styled.div`
    display: flex;
`
