import type { ThemeType } from '@octup/core/theme'

export const buttonStyle = (theme: ThemeType) => ({
    height: theme.spacing(11),
    ...(theme.palette.mode === 'dark' && {
        backgroundColor: theme.palette.grey[400],
        '&, &:hover': {
            border: 'none',
        },
    }),
})
