import { format } from 'date-fns'
import { COST_TITLES_BY_NAME } from '@/constants'
import { VALUE_TYPE_INDEX, getFormattedVal } from '@/shared/utils/metrics-utils'

const getValue = ({ value, type = VALUE_TYPE_INDEX.NUMBER }) =>
    getFormattedVal({
        val: value,
        valueType: type,
        isSupported: true,
        isShopCurrency: true,
        showSymbol: true,
        ...(type === VALUE_TYPE_INDEX.PERCENTAGE && { multiplyBy100: false }),
    })

const getCostsData = (data) => {
    const price = getValue({ value: data.price })
    const totalCost = getValue({ value: data.total_cost })
    const grossMargin = getValue({ value: data.gross_margin })
    const profit = getValue({ value: data.profit_percentage, type: VALUE_TYPE_INDEX.PERCENTAGE })

    return data.costs.map((cost) => ({
        'Product Name': data.title,
        ...(data.variant_title && {
            'Variant Name': data.title,
        }),
        'Average Selling Price': price,
        'Gross Margin': `${grossMargin}(${profit})`,
        'Total Costs': totalCost,
        'Cost Name': COST_TITLES_BY_NAME[cost.cost_name] || cost.cost_name,
        'Cost Value': getValue({ value: cost.value }),
        'Last Updated': cost.last_updated,
        Providers: cost.providers || 'Manual',
    }))
}

const getFileName = (selectedProduct) =>
    selectedProduct.title.replace(/\./g, '') + ' Cost Breakdown ' + format(new Date(), 'yyyy-MM-dd')

export const getDataToDownload = (selectedProduct) => ({
    data: getCostsData(selectedProduct),
    filename: getFileName(selectedProduct),
})
