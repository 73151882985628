import { useCallback, useMemo } from 'react'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ERROR_MESSAGES } from '@/constants'
import { setUserInfo } from '@/features/auth/authSlice'
import { useToast } from '@/hooks'
import type { ClientUserType } from '@/models/clients'
import type { CreateUserFormType, UserFormDataType, SIGNUP_SOURCES } from '@/models/signup'
import { CreateUserForm } from '@/new_components/Signup'
import { fetchSignupBySource } from '@/state/features/signup'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { DEFAULT_VALUES, VALIDATION_SCHEMA } from './utils'

type CreateUserFormContainerPropsType = {
    source: SIGNUP_SOURCES
    userData: UserFormDataType
}

export const CreateUserFormContainer = ({ source, userData }: CreateUserFormContainerPropsType) => {
    const toast = useToast()
    const dispatch = useAppDispatch()
    const { isLoading } = useAppSelector((state) => state.signup)
    const defaultValues = useMemo(() => ({ ...DEFAULT_VALUES, ...userData }), [userData])

    const handleSubmit = useCallback(
        ({ password }: CreateUserFormType) =>
            dispatch(fetchSignupBySource({ source, data: { ...userData, password } })),
        [dispatch, userData, source]
    )

    const handleSuccess = useCallback(
        (data: PayloadAction<ClientUserType>) => dispatch(setUserInfo(data.payload)),
        [dispatch]
    )

    const handleFail = useCallback(() => toast.error(ERROR_MESSAGES.SERVER_ERROR), [toast])

    return (
        <CreateUserForm
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
            onFail={handleFail}
            defaultValues={defaultValues}
            validationSchema={VALIDATION_SCHEMA}
            isLoading={isLoading}
        />
    )
}
