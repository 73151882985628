export const ReturnGoIcon = ({ size = '2.4rem' }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="3" y="3" width="18" height="18" rx="7" fill="url(#paint0_linear_2510_15501)" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.3846 7.42391C10.6603 7.42391 9.22396 8.86554 9.22396 10.6921V17.438C9.22396 17.9141 8.83804 18.3 8.36198 18.3C7.88592 18.3 7.5 17.9141 7.5 17.438V10.6921V10.5845C7.5 10.5592 7.50109 10.5342 7.50323 10.5094C7.59706 7.85686 9.72635 5.69995 12.3846 5.69995C15.1035 5.69995 17.2691 7.95659 17.2691 10.6921C17.2691 11.4557 17.1872 12.1548 16.7784 12.9486C16.4182 13.6478 15.8268 14.3758 14.9261 15.2939L15.9852 16.284C16.6792 16.9328 16.2387 18.0967 15.2891 18.1234L12.1087 18.2129C11.51 18.2297 11.0156 17.749 11.0156 17.1501L11.0156 14.0875C11.0156 13.1575 12.1254 12.6757 12.8048 13.3109L13.6663 14.1162C14.5481 13.2198 15.0015 12.6334 15.2458 12.1592C15.4834 11.6978 15.5452 11.3014 15.5452 10.6921C15.5452 8.86554 14.1088 7.42391 12.3846 7.42391Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2510_15501"
                    x1="12"
                    y1="3"
                    x2="12"
                    y2="21"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#2AB6C7" />
                    <stop offset="1" stop-color="#4783EF" />
                </linearGradient>
            </defs>
        </svg>
    )
}
