import { useFormContext } from 'react-hook-form'
import { WorkingHoursField } from '@/new_components/Clients'

export type WorkingHoursFieldContainerPropsType = {
    name: string
}

export const WorkingHoursFieldContainer = ({ name }: WorkingHoursFieldContainerPropsType) => {
    const { watch } = useFormContext()
    const isEnabled = watch(`${name}.enabled`)

    if (!isEnabled) return null

    return <WorkingHoursField name={name} />
}
