import type {
    MetricsSectionDeepDivePropsType,
    MetricsSectionDeepDiveType,
} from '@octup/core/models'

export const useDeepDiveProps = ({
    data,
    onDeepDiveShow,
    isEnabled = false,
}: MetricsSectionDeepDiveType): Partial<MetricsSectionDeepDivePropsType> => {
    if (!isEnabled || !data) return {}

    const [metric] = data.metrics || []
    const isClickable = !!metric?.deep_dive && typeof onDeepDiveShow === 'function'

    const handleClick = () => {
        if (!isClickable) return
        onDeepDiveShow(data.type, metric.name)
    }

    return { isClickable, onClick: handleClick }
}
