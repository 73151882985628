import type { LinearProgressProps } from '@mui/material'
import { Stack, Box, LinearProgress, Typography } from '..'

type LabeledLinearProgressPropsType = LinearProgressProps & { value: number }

export const LabeledLinearProgress = (props: LabeledLinearProgressPropsType) => (
    <Stack direction="row" alignItems="center" spacing={2}>
        <Box width="100%">
            <LinearProgress variant="determinate" {...props} />
        </Box>
        <Typography variant="body2" color="text.secondary">
            {`${Math.round(props.value)}%`}
        </Typography>
    </Stack>
)
