import { OctupBaseModal } from '../../../../shared/ui/Modal/OctupBaseModal'
import { useOctupDispatch } from '../../../../hooks'
import { WhatsNewTitle } from '../../../../new_components'
import { WhatsNewModalContent } from '../../../../new_components'

export const WhatsNewContainer = () => {
    const octupDispatch = useOctupDispatch()
    const handleCloseModal = () => octupDispatch.whatsNew.setShowModal(false)
    return (
        <OctupBaseModal
            cardBackground={'rgba(248, 247, 251, 1)'}
            onDismissModal={handleCloseModal}
            title={<WhatsNewTitle />}
        >
            <WhatsNewModalContent />
        </OctupBaseModal>
    )
}
