import React from 'react'
import { getIconColor } from './utils'

export function LineCheckmarkSvg({ active, customActiveColor, customColor }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="0.8rem"
            height="0.2rem"
            viewBox="0 0 8 2"
            fill="none"
        >
            <path
                d="M0.75 1.25H7.25"
                stroke={getIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    )
}
