import { TitleDiv, VerticalDivider } from './SingleOrderDetails.styles'

export function TopContainerItems({ container, title, content, showVerticalDivider }) {
    const Container = container
    const Icon = title?.icon
    return (
        <>
            <Container>
                <TitleDiv>
                    {title?.icon && <Icon />}
                    {title.text}
                </TitleDiv>
                {content}
            </Container>
            {showVerticalDivider && <VerticalDivider />}
        </>
    )
}
