import styled from 'styled-components'
import { CardValueWithCharts } from '../../../../shared/components/CardValueWithChart/CardValueWithChart'

export const wrapElementWithStyle = (
    element,
    isLoading,
    isRejected,
    showCard,
    showSeperator = false,
    isFilled = false,
    segment
) => {
    return (
        <ElementDiv>
            {(isLoading || element?.metric) && (
                <CardValueWithCharts
                    title={element?.metric?.metricTitle}
                    metricTitleKey={element?.metric?.metricTitle}
                    enableDeepdive={!!element?.metric?.deep_dive}
                    description={element?.metric?.description}
                    currency={element?.metric?.currency}
                    type={element?.metric?.type}
                    showSecondYAxis={false}
                    isLoading={isLoading || !element?.metric}
                    isRejected={isRejected}
                    value={`${element?.metric?.value}`}
                    trend={element?.metric?.trendValue}
                    trendUnits={element?.metric?.trendUnits}
                    trendValue={element?.metric?.trendValue}
                    valueUnits={element?.metric?.valueUnits}
                    showChart={!!element?.dataset}
                    chartData={element?.dataset?.map((el) => el[0])}
                    chartLabels={element?.dataset?.map((el) => el[1])}
                    timeSlice={element?.metric?.time_slice}
                    isPositiveTrend={element?.metric?.isPositiveTrend}
                    showCard={showCard}
                    isFilled={isFilled}
                    showSeperator={showSeperator}
                    segment={segment}
                    isSupported={element?.metric?.supported}
                    isStale={element?.metric?.stale}
                    lastUpdatedAt={element?.metric?.last_updated_date}
                    sources={element?.metric?.sources}
                />
            )}
        </ElementDiv>
    )
}

const ElementDiv = styled.div`
    margin: 0;
    padding: 0;
`
