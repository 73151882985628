import type { MetricsSectionDataType } from '@octup/core/models'
import type { DataGridPropsType, GridColDefType } from '@octup/core/ui-kit'
import { formatNumber } from '@octup/core/utils'
import { getInputDate } from '@/shared/utils/dates-util'

type TableRowType = Record<string, string | number | undefined> & {
    id: string
    date: string
}

export const getTableConfig = (data?: MetricsSectionDataType): DataGridPropsType => {
    const metrics = data?.metrics || []
    const { data_by_dates, time_slice } = metrics[0] || {}
    const metricsDates = Object.keys(data_by_dates || {})

    const rows = metricsDates.reduce((acc: TableRowType[], date) => {
        const metricsData = metrics.reduce(
            (acc, metric) => ({ ...acc, [metric.name]: metric.data_by_dates?.[date] }),
            {}
        )
        return [...acc, { id: date, date, ...metricsData }]
    }, [])

    const columns: GridColDefType<typeof rows>[] = [
        {
            field: 'date',
            headerName: 'Date',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 130,
            valueGetter: (value: string) => getInputDate(value, time_slice),
        },
        ...metrics.map((metric) => ({
            field: metric.name,
            headerName: metric.name,
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 100,
            valueGetter: (value: number) =>
                formatNumber({
                    value,
                    valueUnits: metric.value_units,
                    currency: metric.currency,
                    isSupported: metric.supported,
                }),
        })),
    ]

    return {
        columns: columns,
        rows: rows,
        hideFooter: true,
        hideFooterPagination: true,
        paginationMode: 'server',
        rowCount: rows.length,
    }
}
