import { useParams } from 'react-router-dom'
import { DashboardActions } from '@/new_components/Dashboards'
import { DashboardPage } from '@/new_pages'
import { useAppSelector } from '@/state/hooks'

export const DashboardPageContainer = () => {
    const { dashboardId } = useParams()
    const { dashboardsById } = useAppSelector((state) => state.dashboards)
    const { metricsSections } = useAppSelector((state) => state.newMetrics)
    const dashboard = dashboardsById.data?.[dashboardId!]
    const isLoading = dashboard?.isLoading || metricsSections.isLoading

    return (
        <DashboardPage
            isLoading={isLoading}
            dashboardId={dashboardId!}
            sidePanelTools={<DashboardActions />}
            hasCustomTitle
            customTitleStyled={dashboard?.data?.name}
            noDataText="Click 'Edit' at the top to enter edit mode and select metrics."
        />
    )
}
