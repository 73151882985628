import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getInvoicesDates } from 'services'

const INVOICES_DATES_INITIAL_STATE = {
    data: null,
    isLoading: false,
    error: null,
}

export const fetchInvoicesDates = createAsyncThunk('fetchInvoicesDates', async (_, thunkAPI) =>
    getInvoicesDates(thunkAPI)
)

export const invoicesDatesSlice = createSlice({
    name: 'invoicesDates',
    initialState: INVOICES_DATES_INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(fetchInvoicesDates.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchInvoicesDates.fulfilled, (state, action) => {
                const dates = action.payload
                const sortedDates = dates.sort((a, b) => a.localeCompare(b))
                state.data = sortedDates
                state.isLoading = false
            })
            .addCase(fetchInvoicesDates.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const invoicesDatesReducer = invoicesDatesSlice.reducer
