import styled from 'styled-components'

import PurchaseConfirmedSvg from '../../shared/assets/images/PurchaseConfirmedSvg.svg'
import { Flex } from '../../shared/styledComponents'
import { useSelector } from 'react-redux'
import { Button } from '@octup/core/ui-kit'

export const PurchaseConfirmed = ({ closeModal, planName }) => {
    const { userInfo } = useSelector((state) => state.auth)

    return (
        <MainContainer>
            <Title>
                {userInfo?.fullName}, You are now a {planName} member!
            </Title>
            <Text>Thank you for trusting Octup, we’re happy you chose us.</Text>
            <Text>Now, let’s make waves in our journey together.</Text>
            <StyledImage src={PurchaseConfirmedSvg} />
            <ButtonContainer>
                <Button variant="contained" onClick={closeModal}>
                    Dive Right In
                </Button>
            </ButtonContainer>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100rem;

    @media screen and (max-width: 1600px) {
        width: 120rem;
    }
    color: white;
`

const Title = styled.div`
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: rgba(119, 55, 255, 1);
    padding: 1.8rem 0 0.4rem 0;
`

const Text = styled.div`
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    color: rgba(80, 75, 90, 1);
    line-height: 1.4;
`

const StyledImage = styled.img`
    margin-top: 5rem;
    height: auto;
    width: 80%;
`

const ButtonContainer = styled(Flex)`
    padding: 1rem;
    padding-top: 4rem;
    width: 100%;
    justify-content: flex-end;
`
