import styled from 'styled-components'
import { ButtonIcon } from '../../shared/ui/Buttons/ButtonIcon'
import { OctupIcons } from '../../shared/assets/OctupIcons'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateInsightLike } from '../../features/insights/insightSlice'
import { HOME_TABLES_TYPES } from '../../shared/utils/strings-util'
import { useOctupDispatch } from '../../hooks/useOctupDispatch'

export function HomeActionableItems({
    activeTable,
    setShowSnoozeInsightModal,
    setShowDismissInsightModal,
    setShowDismissObjectiveModal,
    setShowDislikeInsightModal,
    setShowShareInsightModal,
    setShowEditObjectiveModal,
    dismissModalhandler,
    setIsLike,
    isLike,
    data,
}) {
    const { SnoozeSvg, LikeSvg, DislikeSvg, ShareSvg, TrashSvg, EditIconSvg } = OctupIcons
    const octupDispatch = useOctupDispatch()
    const ActionBtns = {
        insights: (
            <>
                {false && (
                    <ButtonIcon
                        onClick={() => {
                            dismissModalhandler && dismissModalhandler()
                            setShowSnoozeInsightModal({ show: true, isEditState: false })
                        }}
                        mainIcon={SnoozeSvg}
                    />
                )}
                <ButtonIcon
                    tooltipContent="This insight is relevant"
                    onClick={() => {
                        setIsLike((prev) => !prev)
                        octupDispatch.insights.updateInsightLike(data.id, data.isLike)
                    }}
                    active={isLike}
                    mainIcon={LikeSvg}
                />
                {false && (
                    <ButtonIcon
                        onClick={() => {
                            dismissModalhandler && dismissModalhandler()
                            setShowDislikeInsightModal({ show: true, isEditState: false })
                        }}
                        mainIcon={DislikeSvg}
                    />
                )}
                {false && (
                    <ButtonIcon
                        onClick={() => {
                            dismissModalhandler && dismissModalhandler()
                            setShowShareInsightModal({ show: true, isEditState: false })
                        }}
                        mainIcon={ShareSvg}
                    />
                )}
                <ButtonIcon
                    tooltipContent="Dismiss insight"
                    onClick={() => {
                        dismissModalhandler && dismissModalhandler()
                        setShowDismissInsightModal({ show: true, isEditState: false })
                    }}
                    mainIcon={TrashSvg}
                />
            </>
        ),
        objectives: (
            <>
                <ButtonIcon
                    onClick={() => {
                        setShowEditObjectiveModal({ show: true, isEditState: true })
                    }}
                    tooltipContent={'Edit objective'}
                    mainIcon={EditIconSvg}
                />
                <ButtonIcon
                    onClick={() => {
                        if (activeTable === HOME_TABLES_TYPES.INSIGHTS)
                            setShowDismissInsightModal({ show: true, isEditState: false })
                        if (activeTable === HOME_TABLES_TYPES.OBJECTIVES)
                            setShowDismissObjectiveModal({ show: true, isEditState: false })
                    }}
                    tooltipContent={`Dismiss ${activeTable}`}
                    mainIcon={TrashSvg}
                />
            </>
        ),
    }

    return <ActionButtons className="tbl-row-action-btns">{ActionBtns[activeTable]}</ActionButtons>
}

const ActionButtons = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    padding-right: 0.2rem;
    padding-left: 2rem;
    /* background-color: #f1f0f5; */
    opacity: 1;
    /* transition: opacity 0.2s ease-in-out; */
`
