type TypographyStyleParams = {
    maxHeight?: number | string
    calculatedMaxHeight: number
    lineHeight: number
}

export const typographyStyle = ({
    maxHeight,
    calculatedMaxHeight,
    lineHeight,
}: TypographyStyleParams) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    ...(maxHeight
        ? {
              maxHeight: `${calculatedMaxHeight}px`,
              lineHeight: `${lineHeight}px`,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: Math.floor(calculatedMaxHeight / lineHeight),
          }
        : {
              whiteSpace: 'nowrap',
          }),
})
