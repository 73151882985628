import { COST_TITLES_BY_NAME } from 'constants'

export const getProductsData = (data) => ({
    ...data,
    products: Object.values(data.products).map((product) => ({
        ...product,
        title: product.product_title,
        variants: Object.values(product.variants).map((variant) => ({
            ...variant,
            title: `${variant.product_title} ${variant.variant_title}`,
        })),
    })),
})

export const getSelectedProduct = (product) => ({
    ...product,
    costs: product.costs
        .map((cost) => ({ ...cost, isCustom: !COST_TITLES_BY_NAME[cost.cost_name] }))
        .sort((a, b) => a.isCustom && b.isCustom && b.last_updated?.localeCompare(a.last_updated)),
})
