import { useCallback } from 'react'
import { useOctupDispatch } from 'hooks/useOctupDispatch'
import { NoDataFallback } from 'new_components/__common__'
import { useNavigate } from 'react-router-dom'

export const NoDataFallbackContainer = ({ redirection, ...props }) => {
    const navigate = useNavigate()
    const octupDispatch = useOctupDispatch()

    const handleRedirect = useCallback(() => {
        // TODO: re-write sidebar and then delete manual settings
        octupDispatch.sidebar.setActivePage(redirection.activePage)
        octupDispatch.sidebar.setActiveSubMenu(redirection.subMenu)
        octupDispatch.sidebar.setActiveSubMenuItem(redirection.subMenuItem)

        navigate(redirection.path)
    }, [navigate, octupDispatch.sidebar, redirection])

    return <NoDataFallback onClick={handleRedirect} {...props} />
}
