import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getWarehouseCost } from 'services'

const INITIAL_STATE = {
    data: null,
    isLoading: false,
    error: null,
}

export const fetchWarehouseCost = createAsyncThunk('fetchWarehouseCost', async (data, thunkAPI) =>
    getWarehouseCost(data, thunkAPI)
)

export const warehouseCostSlice = createSlice({
    name: 'warehouseCost',
    initialState: INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(fetchWarehouseCost.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchWarehouseCost.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchWarehouseCost.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const warehouseCostReducer = warehouseCostSlice.reducer
