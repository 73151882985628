import styled from 'styled-components'
import { Text } from 'shared/styledComponents'
import { OctupColors } from 'shared/theme/colors/OctupColors'

export const ContactText = styled(Text)`
    text-align: center;
    & a {
        color: ${OctupColors.primaryActionableLight};
        font-weight: bold;
    }
`
