import { COMPARISON_MODES } from 'constants/metrics'
import { octupDictionary } from 'shared/dictionary/dictionary'
import { DEFAULT_STYLES } from 'shared/ui/Charts/octupChartsUtils'
import {
    getDateRange,
    getDefaultChartData,
    getComparisonChartData,
    isComparedTitle,
} from './common'
import { getTooltipLabel } from './metricComparison'

const getDatasets = ({
    data,
    mainMetric,
    comparisonMetric,
    comparisonMode,
    timeSlice,
    isCompared,
}) =>
    data.reduce((acc, item) => {
        const id = +item.metric.metric_id
        const isComparisonMetric = id === +comparisonMetric?.metric_id
        const isPeriodComparison =
            comparisonMode === COMPARISON_MODES.PERIOD || comparisonMode === COMPARISON_MODES.YEAR

        if (id !== +mainMetric.metric_id && !isComparisonMetric) return acc

        return [
            ...acc,
            {
                colorIndex: 0,
                ...getDefaultChartData({ data: item }),
                ...(isPeriodComparison && {
                    label: getDateRange({ data: item, timeSlice }),
                    ...getComparisonChartData({ data: item, timeSlice, isCompared }),
                }),
                ...(isComparisonMetric && {
                    colorIndex: 1,
                    comparisonConfig: {
                        currency: comparisonMetric.currency,
                        valueUnits: comparisonMetric.valueUnits,
                    },
                }),
            },
        ]
    }, [])

const getChartDataSets = ({ data, comparisonMode, timeSlice, mainMetric, comparisonMetric }) =>
    data.reduce((acc, [title, dataSet]) => {
        const isCompared = isComparedTitle(title)
        return [
            ...acc,
            ...getDatasets({
                data: dataSet,
                mainMetric,
                comparisonMetric,
                comparisonMode,
                timeSlice,
                isCompared,
            }),
        ]
    }, [])

const getDefaultProps = () => ({
    showSeriesNameInTooltip: false,
    getChartDataSets,
})

const getMetricComparisonProps = ({ comparisonMetric }) => ({
    ...getDefaultProps(),
    ...(comparisonMetric && {
        showSeriesNameInTooltip: true,
        showLegend: true,
        showSecondYAxis: true,
        secondYAxisConfig: {
            currency: comparisonMetric.currency,
            valueUnits: comparisonMetric.valueUnits,
        },
        getTooltipLabel: getTooltipLabel,
    }),
})

const getPeriodComparisonProps = ({ mainMetric }) => ({
    ...getDefaultProps(),
    showSeriesNameInTooltip: true,
    showLegend: true,
    getTooltipTitle: () => octupDictionary.titles.metricsTitles[mainMetric.metricTitle],
    sx: {
        usePointStyle: false,
        legends: { ...DEFAULT_STYLES.legends, align: 'end' },
    },
})

export const DEFAULT_PROPS_BY_COMPARISON_MODE = {
    [COMPARISON_MODES.NONE]: getDefaultProps,
    [COMPARISON_MODES.PERIOD]: getPeriodComparisonProps,
    [COMPARISON_MODES.YEAR]: getPeriodComparisonProps,
    [COMPARISON_MODES.METRIC]: getMetricComparisonProps,
}
