import { OctupLogoWithText } from './icons/OctupLogoLightWithText.js'
import { HomeLogoSvg } from './icons/HomeLogoSvg.js'
import { OperationsLogoSvg } from './icons/OperationsLogoSvg.js'
import { MarketingLogoSvg } from './icons/MarketingLogoSvg.js'
import {
    UnitEconomicsLogoSvg,
    UnitEconomicsLogoSvg as ActionsBtnUnitEconomicsIcon,
} from './icons/UnitEconomicsLogoSvg.js'
import { CustomersLogoSvg } from './icons/CustomersLogoSvg.js'
import { ReportsLogoSvg } from './icons/ReportsLogoSvg.js'
import { InsightsLogoSvg } from './icons/InsightsLogoSvg.js'
import { AutoStudioLogoSvg } from './icons/AutoStudioLogoSvg.js'
import { OctupSmallLogoSvg } from './icons/OctupSmallLogoSvg.js'
import { SettingsLogoSvg } from './icons/SettingsLogoSvg.js'
import { HelpLogoSvg } from './icons/HelpLogoSvg.js'
import { OctupNoTextSmallLogo } from './icons/OctupNoTextSmallLogo.js'
import { HamburgerWithLeftArrowSvg } from './icons/HamburgerWithLeftArrowSvg.js'
import { HamburgerWithRightArrowSvg } from './icons/HamburgerWithRightArrowSvg.js'
import { PlusLogoSvg } from './icons/PlusLogoSvg.js'
import { ChevronRightSvg } from './icons/ChevronRightSvg.js'
import { ChevronLeftSvg } from './icons/ChevronLeftSvg.js'
import { ChevronUpSvg } from './icons/ChevronUpSvg.js'
import { ChevronDownSvg } from './icons/ChevronDownSvg.js'
import { NotificationIcon } from './icons/NotificationIcon.js'
import { SearchIcon } from './icons/SearchIcon.js'
import { TrendArrowUp } from './icons/TrendArrowUp.js'
import { TrendArrowDown } from './icons/TrendArrowDown.js'
import { CheckmarkSvg } from './icons/CheckmarkSvg'
import { NoImageSvg } from './icons/NoImageSvg'
import { ClearFieldsSvg } from './icons/ClearFieldsSvg'
import { LineCheckmarkSvg } from './icons/LineCheckmarkSvg'
import { DownTriangleArrowSvg } from './icons/DownTriangleArrowSvg'
import { DownArrowSvg } from './icons/DownArrowSvg'
import { SnoozeSvg } from './icons/SnoozeSvg'
import { ShareSvg } from './icons/ShareSvg'
import { LikeSvg } from './icons/LikeSvg'
import { DislikeSvg } from './icons/DislikeSvg'
import { TrashSvg } from './icons/TrashSvg'
import { ActionsBtnShippingIcon } from './icons/ActionsBtnShippingIcon'
import { ActionsBtnInventoryIcon } from './icons/ActionsBtnInventoryIcon'
import { ActionsBtnMarketingIcon } from './icons/ActionsBtnMarketingIcon'
import { ActionsBtnProductIcon } from './icons/ActionsBtnProductIcon'
import { ActionsBtnOrdersIcon } from './icons/ActionsBtnOrdersIcon'
import { ActionsBtnCustomersIcon } from './icons/ActionsBtnCustomersIcon'
import { ActionsBtnOnlineStoreIcon } from './icons/ActionsBtnOnlineStoreIcon.js'
import { ActionsBtnPricingIcon } from './icons/ActionsBtnPricingIcon'
import { ActionsBtnNoIconIcon } from './icons/ActionsBtnNoIconIcon.js'
import { ActionsBtnOperationIcon } from './icons/ActionsBtnOperationIcon.js'
import { ActionsBtnLastMileIcon } from './icons/ActionsBtnLastMileIcon.js'
import { CustomizeMetricsSvg } from './icons/CustomizeMetricsSvg'
import { CloseIconSvg } from './icons/CloseIconSvg'
import { MetricsDetailsIconSvg } from './icons/MetricsDetailsIconSvg'
import { WarningIconSvg } from './icons/WarningIconSvg'
import { UserIconSvg } from './icons/UserIconSvg'
import { FeedbackIconSvg } from './icons/FeedbackIconSvg'
import { GoToWebsiteIconSvg } from './icons/GoToWebsiteIconSvg'
import { SignoutIconSvg } from './icons/SignoutIconSvg'
import { EditIconSvg } from './icons/EditIconSvg'
import { SimulateIconSvg } from './icons/SimulateIconSvg'
import { ShopifyIcon } from './images/third-party-icons/ShopiftIcon.js'
import { FacebookIcon } from './images/third-party-icons/FacebookIcon.js'
import { GoogleAdsIcon } from './images/third-party-icons/GoogleAdsIcon.js'
import { RakutenIcon } from './images/third-party-icons/RakutenIcon.js'
import { ShipNetworkIcon } from './images/third-party-icons/ShipNetworkIcon.js'
import { TikTokIcon } from './images/third-party-icons/TikTokIcon.js'
import { SlidersIconSvg } from './icons/SlidersIconSvg.js'
import { DotsIconSvg } from './icons/DotsIconSvg.js'
import { ShowPasswordIcon } from './icons/ShowPasswordIcon.js'
import { HidePasswordIcon } from './icons/HidePasswordIcon.js'
import { UploadFileIconSvg } from './icons/UploadFileIconSvg.js'
import { LinkIconSvg } from './icons/LinkIconSvg.js'
import { IntegrationsIconSvg } from './icons/InterationsIconSvg.js'
import { ContactUsIconSvg } from './icons/ContactUsIconSvg.js'
import { SignupCentralized } from './icons/SignupCentralized'
import { SignupSupercharged } from './icons/SignupSupercharged'
import { SignupOptimized } from './icons/SignupOptimized'
import { ObjectivesLogoSvg } from './icons/ObjectivesLogoSvg.js'
import { CommerceLogoSvg } from './icons/CommerceLogoSvg.js'
import { GreenCheckmarkSvg } from './icons/GreenCheckmarkSvg'
import { MailSent } from './icons/MailSent'
import { OcTrackLogoSvg } from './icons/OcTrackLogoSvg'
import { EnlargeGraph } from './icons/EnlargeGraph'
import { DoubleCheckMarks } from './icons/DoubleCheckMarks'
import { CartIcon } from './icons/CartIcon'
import { CreditCardIcon } from './icons/CreditCardIcon'
import { OutsourceSvg } from './icons/OutsourceSvg'
import { KnowledgeIconSvg } from './icons/KnowledgeIconSvg'
import { LightningIconSvg } from './icons/LightningIconSvg'
import { ContactSvgIcon } from './icons/ContactSvgIcon'
import { WhatsNewIconSvg } from './icons/WhatsNewIconSvg'
import { HelpNSupportLogoSvg } from './icons/HelpNSupportLogoSvg'

export const OctupIcons = {
    TrendArrowUp,
    TrendArrowDown,
    OctupLogoWithText,
    OctupSmallLogoSvg,
    OctupNoTextSmallLogo,
    AutoStudioLogoSvg,
    ObjectivesLogoSvg,
    InsightsLogoSvg,
    ActionsBtnCommerceIcon: CommerceLogoSvg,
    ReportsLogoSvg,
    OcTrackLogoSvg,
    CustomersLogoSvg,
    UnitEconomicsLogoSvg,
    MarketingLogoSvg,
    OperationsLogoSvg,
    HomeLogoSvg,
    SettingsLogoSvg,
    HelpLogoSvg,
    HamburgerWithLeftArrowSvg,
    HamburgerWithRightArrowSvg,
    PlusLogoSvg,
    ChevronUpSvg,
    ChevronDownSvg,
    ChevronLeftSvg,
    EnlargeGraph,
    ChevronRightSvg,
    NotificationIcon,
    SearchIcon,
    CheckmarkSvg,
    GreenCheckmarkSvg,
    MailSent,
    NoImageSvg,
    ClearFieldsSvg,
    LineCheckmarkSvg,
    DownTriangleArrowSvg,
    DownArrowSvg,
    SnoozeSvg,
    LikeSvg,
    DislikeSvg,
    ShareSvg,
    TrashSvg,
    ActionsBtnShippingIcon,
    ActionsBtnInventoryIcon,
    ActionsBtnMarketingIcon,
    ActionsBtnProductIcon,
    ActionsBtnOrdersIcon,
    ActionsBtnCustomersIcon,
    ActionsBtnPricingIcon,
    ActionsBtnUnitEconomicsIcon,
    ActionsBtnOperationIcon,
    ActionsBtnOnlineStoreIcon,
    ActionsBtnLastMileIcon,
    ActionsBtnNoIconIcon,
    CustomizeMetricsSvg,
    CloseIconSvg,
    MetricsDetailsIconSvg,
    WarningIconSvg,
    UserIconSvg,
    FeedbackIconSvg,
    GoToWebsiteIconSvg,
    SignoutIconSvg,
    EditIconSvg,
    SimulateIconSvg,
    ShopifyIcon,
    FacebookIcon,
    RakutenIcon,
    GoogleAdsIcon,
    TikTokIcon,
    SlidersIconSvg,
    DotsIconSvg,
    ShowPasswordIcon,
    HidePasswordIcon,
    UploadFileIconSvg,
    LinkIconSvg,
    IntegrationsIconSvg,
    ContactUsIconSvg,
    SignupCentralized,
    SignupSupercharged,
    SignupOptimized,
    ShipNetworkIcon,
    CartIcon,
    CreditCardIcon,
    OutsourceSvg,
    DubleCheckMarks: DoubleCheckMarks,
    KnowledgeIconSvg,
    LightningIconSvg,
    ContactSvgIcon,
    WhatsNewIconSvg,
    HelpNSupportLogoSvg,
}
