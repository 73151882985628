import axios from 'axios'
import { GLOBAL_CONFIG } from 'config'
import { generateAPIConfig } from 'services/utils'

export const LOGGER_API_URL = GLOBAL_CONFIG.BASE_URL + 'file/logs'

/** log message into the AWS CloudWatch
 * @message mandatory
 */

export const logMessage = async (data) => {
    try {
        const config = generateAPIConfig()
        await axios.post(LOGGER_API_URL, data, config)
    } catch (error) {
        console.error('Failed to log message: ', error)
    }
}
