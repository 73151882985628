import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { InvoicesRequest } from 'new_components/__common__'

export const InvoicesRequestContainer = (props) => {
    const { userInfo } = useSelector((state) => state.auth)
    const email = useMemo(() => userInfo?.client?.alias, [userInfo?.client?.alias])

    return <InvoicesRequest email={email} {...props} />
}
