import { OctupColors } from '../../theme/colors/OctupColors'

export function GoodMorningBoardCoffeIconSvg({
    active,
    customActiveColor = '#504B5A',
    customColor = '#007F82',
}) {
    return (
        <svg
            width="2.5rem"
            height="2.5rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 21.25H19.5"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.66332 21.2504C6.27149 20.5915 5.09225 19.5346 4.26563 18.205C3.43902 16.8755 2.99975 15.3293 3 13.75V9.25H19V13.75C19.0002 15.3293 18.561 16.8756 17.7343 18.2051C16.9077 19.5346 15.7285 20.5915 14.3366 21.2504"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                opacity="0.5"
                d="M18.2004 10C16.0005 15.5 10.0001 16.5 4.50006 17C4.50006 17 5.50006 19.5 8.00006 20.5H14.0004C14.6474 20.5 17.0004 19 18.0004 16C18.2484 15.256 18.2004 12.5 18.2004 10Z"
                fill="url(#paint0_linear_3425_20279)"
            />
            <path
                d="M19.5003 9.25C20.296 9.25 21.059 9.56607 21.6216 10.1287C22.1843 10.6913 22.5003 11.4544 22.5003 12.25V13C22.5003 13.7956 22.1843 14.5587 21.6216 15.1213C21.059 15.6839 20.296 16 19.5003 16H19.1836"
                stroke={OctupColors.primaryTextLight}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.75 6.75C8.03571 5.18751 8.78571 4.49997 9.75 4.5C10.6713 4.50003 11.5714 5.50001 12.5357 5.50001C13.5 5.50001 14.25 4.5 15.75 3"
                stroke="url(#paint1_linear_3425_20279)"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3425_20279"
                    x1="18.2042"
                    y1="10"
                    x2="8.06583"
                    y2="23.2321"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFCF72" />
                    <stop offset="1" stopColor="#FF3489" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_3425_20279"
                    x1="15.75"
                    y1="3"
                    x2="13.0873"
                    y2="9.39054"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFCF72" />
                    <stop offset="1" stopColor="#FF3489" />
                </linearGradient>
            </defs>
        </svg>
    )
}
