import React from 'react'
import { getMobileIconColor } from './utils'
import { OctupColors } from '../../theme/colors/OctupColors'

export function MobileMetricsTabIconSvg({
    active,
    customActiveColor = OctupColors.primaryActionableDark,
    customColor = OctupColors.primaryTextDark,
}) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19 17.8511L5 17.8511"
                stroke={getMobileIconColor(active, customColor, customActiveColor)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="6.625"
                y1="14.8511"
                x2="6.625"
                y2="10.6936"
                stroke={getMobileIconColor(active, customColor, customActiveColor)}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M10.4056 14.8511L10.4056 11.3875"
                stroke={getMobileIconColor(active, customColor, customActiveColor)}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M13.8278 14.8511L13.8278 5.99976"
                stroke={getMobileIconColor(active, customColor, customActiveColor)}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M17.25 14.8511L17.25 9.84816"
                stroke={getMobileIconColor(active, customColor, customActiveColor)}
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}
