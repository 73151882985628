import { forwardRef, useMemo } from 'react'
import type { ThemeType } from '@octup/core/theme'
import type { CardPropsType, SxPropsType } from '@octup/core/ui-kit'
import { Card } from '@octup/core/ui-kit'
import { cardStyle } from './DashboardCard.styles'

export type DashboardCardPropsType = CardPropsType & {
    children: React.ReactNode
    isSelected?: boolean
    isDisabled?: boolean
    isClickable?: boolean
    sx?: SxPropsType<ThemeType>
}

export const DashboardCard = forwardRef<HTMLDivElement, DashboardCardPropsType>(
    ({ children, isSelected, isDisabled, isClickable, sx, ...props }, ref) => {
        const cardSx = useMemo(
            () => cardStyle(isSelected, isDisabled, isClickable, sx),
            [isSelected, isDisabled, isClickable, sx]
        )

        return (
            <Card ref={ref} sx={cardSx} {...props}>
                {children}
            </Card>
        )
    }
)
