import { forwardRef } from 'react'
import type { LinkPropsType } from '@octup/core/ui-kit'
import { Link as UiLink } from '@octup/core/ui-kit'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import type { APP_ROUTES } from '@/models/appConfig'

export type RouterLinkPropsType = Partial<LinkProps> &
    LinkPropsType & {
        route?: APP_ROUTES
    }

export const RouterLink = forwardRef<RouterLinkPropsType['ref'], RouterLinkPropsType>(
    ({ route, ...props }, ref) => <UiLink ref={ref} component={Link} to={`/${route}`} {...props} />
)
