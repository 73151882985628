import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SvgIcon, Button } from '@octup/core/ui-kit'
import { ObjectiveIcon } from 'new_assets/icons'
import PageLayout from '../../components/PageLayout/PageLayout'
import { objectivesActions } from '../../features/objectives/objectiveSlice'
import { ObjectivesTopSection } from './components/ObjectivesTopSection'
import { ObjectivesDetailedTable } from './components/ObjectivesDetailedTable'
import { useOctupDispatch } from '../../hooks/useOctupDispatch'

const ObjectivesPage = () => {
    const dispatch = useDispatch()
    const octupDispatch = useOctupDispatch()

    const [showEditObjectiveModal, setShowEditObjectiveModal] = useState({
        show: false,
        isEditState: false,
    })

    useEffect(() => {
        octupDispatch.objectives.getObjectives()
        octupDispatch.insights.getInsights()
    }, [octupDispatch.insights, octupDispatch.objectives])

    return (
        <PageLayout
            customStyle={{ overflowY: 'hidden' }}
            showDatePicker={false}
            sidePanel={
                <Button
                    variant="secondary"
                    sx={{ padding: '0.2rem 2rem' }}
                    onClick={() => {
                        dispatch(objectivesActions.setSelectedObjective(null))
                        setShowEditObjectiveModal({ show: true, isEditState: true })
                    }}
                    startIcon={<SvgIcon component={ObjectiveIcon} />}
                >
                    New Objective
                </Button>
            }
        >
            <ObjectivesTopSection />
            <ObjectivesDetailedTable
                setShowEditObjectiveModal={setShowEditObjectiveModal}
                showEditObjectiveModal={showEditObjectiveModal}
            />
        </PageLayout>
    )
}

export default ObjectivesPage
