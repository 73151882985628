import styled from 'styled-components'

const CustomerDetailsStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1rem;
    width: 100%;
`

const CustomerDetailsItem = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
`
const CustomerDetailsItemIcon = styled.div`
    display: flex;
    align-items: flex-start;
    height: 100%;
    width: 4rem;
`
const CustomerDetailsItemContent = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-transform: capitalize;
    width: 100%;
    flex: 1;
    font-size: 1.3rem;
    gap: 1rem;
    color: rgba(80, 75, 90, 1);
`

const CustomerDetailsItemButtonWrapper = styled.div`
    width: 4rem;
`
const HorizontalDivider = styled.div`
    border-bottom: 0.1px solid rgba(221, 219, 225, 1);
    width: 100%;
    height: 0;
    align-self: center;
    justify-self: center;
`
const AddressDetails = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    div:first-child {
        font-weight: 600;
    }

    div {
        font-size: 1.3rem;
    }
`

export {
    CustomerDetailsStyled,
    CustomerDetailsItem,
    CustomerDetailsItemIcon,
    CustomerDetailsItemContent,
    CustomerDetailsItemButtonWrapper,
    HorizontalDivider,
    AddressDetails,
}
