import React from 'react'
import styled from 'styled-components'
import { CheckMark } from '../../shared/assets/icons/CheckMark'
import { Column, Flex, Row, WideRow } from '../../shared/styledComponents'
import { OctupColors } from '../../shared/theme/colors/OctupColors'
import { useOctupDispatch } from 'hooks'
import { Button } from '@octup/core/ui-kit'

//TODO: Refactor plansChooser+PricingPlanCard comp. (restructure obj, move to util, improve component structure )
export const PricingPlanCard = ({
    plan,
    isAnnual,
    level,
    isCustom,
    setPlanName,
    setCustomPlanModal,
    annualCost,
    monthlyCost,
}) => {
    const octupDispatch = useOctupDispatch()
    const { title, subtitle, features, buttonColor, tag, icon, featureTitle } = plan

    const handleClick = async () => {
        setPlanName(plan?.title)

        octupDispatch.payments.createPaymentSession({
            plan: title?.toLowerCase(),
            level,
            isAnnual,
        })
    }

    const handleContactUs = () => {
        window.open('mailto:support@octup.com?subject=New%20Custom%20Plan%20Request')
        setCustomPlanModal((prev) => {
            return { ...prev, show: true }
        })
    }

    return (
        <MainContainer>
            <HeaderContainer>
                <TagContainer>{tag && <Tag>{tag}</Tag>}</TagContainer>
                <TopSideContainer>
                    <WideRow>
                        <IconContainer>{icon}</IconContainer>
                        <Column>
                            <Title>{title}</Title>
                            <SubTitle>{subtitle}</SubTitle>
                        </Column>
                    </WideRow>
                </TopSideContainer>
            </HeaderContainer>
            <HorizontalSeperator />
            <PaymentContainer>
                {!isCustom ? (
                    <div>
                        <PriceRow>
                            <PriceContainer>
                                <span>$</span>
                                {isAnnual ? annualCost : monthlyCost}
                            </PriceContainer>

                            <MonthText>/Month</MonthText>
                            {isAnnual && (
                                <CrossedPriceContainer>
                                    {isAnnual && !isCustom && '$' + plan.monthlyCost}
                                </CrossedPriceContainer>
                            )}
                        </PriceRow>
                        <PaymentInfo>Pay Monthly</PaymentInfo>
                    </div>
                ) : (
                    <CustomPaymentContainer>
                        <PriceContainer>Custom</PriceContainer>
                    </CustomPaymentContainer>
                )}

                <Button
                    variant="contained"
                    sx={{
                        background: buttonColor,
                        width: '100%',
                        '&:hover': {
                            opacity: 0.9,
                            background: buttonColor,
                        },
                    }}
                    onClick={isCustom ? handleContactUs : handleClick}
                >
                    {isCustom ? 'contact us' : `Go ${title}`}
                </Button>
            </PaymentContainer>
            <BodyContainer>
                {featureTitle && <FeaturesTitle>{featureTitle}</FeaturesTitle>}
                <ItemsContainer>
                    {features.map((feature) => (
                        <ItemRow key={feature}>
                            <CheckListImageContainer>
                                <CheckMark />
                            </CheckListImageContainer>
                            <StyledListItem>{feature}</StyledListItem>
                        </ItemRow>
                    ))}
                </ItemsContainer>
            </BodyContainer>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    width: 100%;
    min-width: 52rem;
    height: 100%;
    flex: 1;
    background-color: white;
    padding: 3rem;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    color: ${OctupColors.primaryTextLight};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

const TagContainer = styled.div`
    min-height: 5rem;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 1rem;
`

const Tag = styled(Flex)`
    border-radius: 8px;
    background: #ffcf7229;
    width: 100px;
    height: 20px;
    font-size: 12px;
    font-weight: 600;
    color: #c4821f;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
`

const TopSideContainer = styled.div`
    min-height: 8rem;
`

const BodyContainer = styled.div`
  padding-top: 2rem;
  align-self: flex-start;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex: 1;

  ::-webkit-scrollbar {
    width: 2rem;
    padding-left: 4rem;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 2rem;
    border: 0.6rem solid transparent;
    background -clip:
      content - box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`

const IconContainer = styled.div`
    padding: 1rem;
    padding-right: 2rem;
`

const Title = styled.div`
    font-size: 2.25rem;
    font-weight: 600;
`

const SubTitle = styled.div`
    font-size: 1.8rem;
`

const StyledListItem = styled.li`
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    padding: 0.5rem;
    line-height: 1;
`

const PriceContainer = styled.div`
    font-size: 3rem;
    font-weight: 600;

    span {
        font-size: 2.2rem;
        font-weight: inherit;
    }
`

const MonthText = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    padding-top: 0.8rem;
    font-family: 'Poppins', sans-serif;
`

const PriceRow = styled(WideRow)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
`

const CrossedPriceContainer = styled.div`
    font-size: 1.5rem;
    font-weight: 400;
    text-decoration: line-through;
    padding-bottom: 0.5rem;
    padding-left: 0.3rem;
`

const ItemRow = styled(Row)``

const CheckListImageContainer = styled.div`
    max-width: 20rem;
`

const FeaturesTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
`

const ItemsContainer = styled.ul``
const PaymentInfo = styled.div`
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: -0.8rem;
`
const HeaderContainer = styled.div`
    height: 7rem;
`
const PaymentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
`
const HorizontalSeperator = styled.div`
    border-bottom: 0.1rem solid #c3c3c3;
    height: 0.5rem;
    margin: 1rem 0;
`

const CustomPaymentContainer = styled.div`
    padding: 0.6rem;
`
