import axios from 'axios'
import { GLOBAL_CONFIG } from '../../config'
import { generateConfig } from '../../shared/utils/utils'

const API_URL = `${GLOBAL_CONFIG.BASE_URL}admin/notifications/`
/**
 * get all notification
 * @token mandatory
 */

const getAllNotifications = async (token) => {
    const config = generateConfig(token)
    const response = await axios.get(`${API_URL}`, config)
    return response.data
}

/**
 * get all notifications by type
 * @token mandatory
 * @notificationType mandatory
 */
const getNotificationsByType = async (token, notificationType) => {
    const response = await axios.get(`${API_URL}/dashboardnotifications/${notificationType}`)
    return response.data
}

/**
 * update mark single notification as read
 * @token mandatory
 * @notificationId mandatory
 */
const updateNotificationStatusToRead = async (token, notificationId) => {
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.post(`${API_URL}`)
    return response.data
}

/**
 * update single notification
 * @token mandatory
 * @notificationId mandatory
 */
const updateNotificationById = async (token, notificationId) => {
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.post(`${API_URL}`)
    return response.data
}

/**
 * update all notifications to unreadse
 * @token mandatory
 * @notificationId mandatory
 */
const updateNotificationsStatusToUnread = async (token, notificationsIdsArray) => {
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.post(`${API_URL}`)
    return response.data
}

export const notificationsService = {
    getAllNotifications,
    getNotificationsByType,
    updateNotificationStatusToRead,
    updateNotificationById,
    updateNotificationsStatusToUnread,
}
