import { useCallback } from 'react'
import { Button, SvgIcon } from '@octup/core/ui-kit'
import { APP_ROUTES } from 'models/appConfig'
import { EditIcon } from 'new_assets/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { resolveRoutePath } from 'utils'

export const EditDashboardContainer = () => {
    const { dashboardId } = useParams()
    const navigate = useNavigate()

    const handleClick = useCallback(() => {
        navigate(
            `/${resolveRoutePath(APP_ROUTES.DASHBOARD_EDIT, {
                ':dashboardId': dashboardId!,
            })}`
        )
    }, [dashboardId, navigate])

    return (
        <Button
            type="submit"
            variant="secondary"
            size="small"
            startIcon={<SvgIcon component={EditIcon} />}
            onClick={handleClick}
        >
            Edit
        </Button>
    )
}
