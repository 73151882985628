export enum METRICS_SECTION_TYPE {
    OVERVIEW = 'overview',
    CURRENT = 'current',
    DOUGHNUT = 'doughnut',
    BAR = 'bar',
    LINE = 'line',
    TOTAL = 'total',
    TABLE = 'table',
    CUSTOM_TABLE = 'custom-table',
}
